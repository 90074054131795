export const validateForm = formEl => {
  const validateSelfEvent = new CustomEvent('validateSelf')
  Array.prototype.forEach.call(Array.from(formEl), input => {
    input.dispatchEvent(validateSelfEvent)
  })
}

export default (container = 'body') => {
  const formElements = [
    ...document.querySelectorAll(
      `${container} [data-clientside-hook~="formValidation"]`
    ),
  ]

  const submitHandler = event => {
    const formEl = event.target

    if (formEl.checkValidity() !== true) {
      event.preventDefault()
      validateForm(formEl)
    }
  }

  formElements.forEach(
    /** @param {HTMLElement} formElement */
    formElement => {
      formElement.noValidate = true
      formElement.addEventListener('submit', submitHandler)
    }
  )
}
