import { PAGE_TYPE_CART } from 'shared/consts'
import { CART_CSW_THREE_PRODUCTS } from 'shared/experiments/consts'
import {
  activateExperiment,
  getExperiment,
} from 'shared/experiments/utils/experiments'
import store from 'shared/store'

export default () => {
  const publicRuntimeConfig = store.getState()?.publicRuntimeConfig
  const { experiments = [], pageType = '' } =
    publicRuntimeConfig?.pageProperties || {}
  const { isEnabled } = getExperiment(CART_CSW_THREE_PRODUCTS, experiments)

  if (!isEnabled || pageType !== PAGE_TYPE_CART) {
    return
  }

  void activateExperiment(CART_CSW_THREE_PRODUCTS)
}
