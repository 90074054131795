export const ALGOLIA_CONTAINER_ID = 'algolia-instant-search'
export const FOOTER_LEGAL_TEXT_ID = 'legalText'

// spa container names
export const SPA_CHECKOUT = 'h_spaCheckout'
export const SPA_CART = 'h_spaCart'
export const SPA_SELLER_RATING = 'h_sellerRating'

// modals
export const CONTRACEPTIVE_PRODUCT_INFO_MODAL_ID =
  'contraceptive-product-info-modal'
export const LIFESTYLE_PRODUCT_INFO_MODAL_ID = 'lifestyle-product-info-modal'
export const MEDICAL_DEVICE_INFO_MODAL_ID = 'medical-device-info-modal'
export const PRESCRIPTION_INFO_MODAL_ID = 'prescription-info-modal'
export const PRESCRIPTION_INFO_MODAL_HOME_ONE_ID =
  'prescription-info-modal-home-one'
export const PRESCRIPTION_BONUS_MODAL_ID = 'prescription-bonus-modal'
export const LOGIN_MODAL_ID = 'loginModal'
export const RX_SELECT_MODAL_ID = 'rxSelectModal'
export const RX_DIFFERENCE_MODAL_ID = 'rxDifferenceModal'
export const SHOW_ABO_MODAL_ID = 'showAboModal'
export const SEARCH_BOX_MOBILE_WRAPPER_MODAL_ID = 'h-SearchBox__mobile'
export const CONTACT_SELLER_MESSAGE_MODAL_ID = 'contactSellerMessageModal'
export const OPEN_CONTACT_SELLER_MESSAGE_MODAL = 'openContactSellerMessageModal'
export const OPEN_REQUEST_INVOICE_MESSAGE_MODAL =
  'openRequestInvoiceMessageModal'
export const REGISTER_MODAL_ID = 'register'
export const PROFESSIONAL_ACCOUNT_REGISTER_MODAL_ID =
  'professionalAccountRegister'
export const RED_POINTS_MODAL_ID = 'redPointsModal'
export const ODS_MODAL_ID = 'deleteODSItemModal'
export const ADVICE_MODAL_ID = 'productConsultation'
export const SOCIAL_MODAL_ID = 'productSocial'
export const DELIVERY_INFO_MODAL_ID = 'productDeliveryInfo'
export const METHOTREXAT_QUALITY_INDICATOR_ID = 'methotrexatQualityIndicator'
export const GLUKOKORTIKOID_QUALITY_INDICATOR_ID =
  'glukokortikoidQualityIndicator'
export const LAST_ACTIVITY_ID = 'h_lastActivity'
export const PRODUCT_RATINGS_AND_QNA_ID = 'h_ratingsAndQna'
export const MORE_PRODUCTS_XSELL_ID = 'h_moreProductsXSell'
export const NEWSLETTER_FORM_ID = 'r_newsletterForm'
export const CHANGE_DATE_OF_BIRTH_FORM_ID = 'r_changeDateOfBirthForm'
export const CHANGE_EMAIL_FORM_ID = 'r_changeEmailForm'
export const CHANGE_EMAIL_CONFIRMATION_ID = 'r_changeEmailConfirmation'
export const CHANGE_PASSWORD_FORM_ID = 'r_changePasswordForm'
export const FORGOT_PASSWORD_FORM_ID = 'r_forgotPasswordForm'
export const REGISTER_FORM_ID = 'r_registerForm'
export const PROFESSIONAL_ACCOUNT_REGISTER_FORM_ID =
  'r_professionalAccountRegisterForm'
export const ONE_TIME_PASSWORD_LOGIN_FORM_ID = 'r_oneTimePasswordLoginForm'
export const PASSKEY_FAKE_DOOR_ID = 'r_passkeyFakeDoor'
export const RESET_PASSWORD_FORM_ID = 'r_resetPasswordForm'
export const MEDICATION_PLAN_FORM_ID = 'r_medicationPlanForm'
export const NEWSLETTER_JSON_DATA_CONTAINER_ID =
  'r_newsletterFormJsonDataContainer'
export const RATINGS_CONTAINER_ID = 'user-reviews-section'
export const QNA_CONTAINER_ID = 'user-questions-section'
export const REVIEWS_INFORMATION_BOX_ID = 'reviews-information-box'
export const DUMMY_SELLER_ID = '-1'

export const MEDICATION_PLAN_CONTAINER_ID = 'medicationCoach'
export const CONTACT_LENS_MODAL_ID = 'contactLens'
export const RETURN_LABEL_CUSTOMER_CARE_CONTAINER_ID =
  'returnLabelCustomerCareContainer'
