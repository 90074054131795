import {
  BUTTON_ON_SUBMIT_ANIMATION_STYLES,
  SPINNER_ON_SUBMIT_COLOR,
  SPINNER_ON_SUBMIT_REBRANDING_SIZE,
} from './consts'

const LEGACY_PROP_MAP = {
  ariaControls: 'aria-controls',
  ariaDescribedby: 'aria-describedby',
  ariaHaspopup: 'aria-haspopup',
  ariaHidden: 'aria-hidden',
  ariaPressed: 'aria-pressed',
  expanded: 'aria-expanded',
  pressed: 'aria-pressed',
  scope: 'data-scope',
}

export const applyLegacyPropAliases = props =>
  Object.entries(props).reduce((carry, [name, value]) => {
    const nonLegacyName = LEGACY_PROP_MAP[name]
    if (!nonLegacyName) {
      return { ...carry, [name]: value }
    }
    if (carry[nonLegacyName] != null) {
      return carry
    }
    return {
      ...carry,
      [nonLegacyName]: value,
    }
  }, {})

export const spinnerRebrandingStyles = (
  svgSpinnerOpts,
  buttonStyles = BUTTON_ON_SUBMIT_ANIMATION_STYLES.rebrandingFilledButton
) => {
  const isFilledButton =
    buttonStyles === BUTTON_ON_SUBMIT_ANIMATION_STYLES.rebrandingFilledButton

  const spinnerColor = isFilledButton
    ? SPINNER_ON_SUBMIT_COLOR.white
    : SPINNER_ON_SUBMIT_COLOR.primary

  return {
    ...svgSpinnerOpts,
    size: SPINNER_ON_SUBMIT_REBRANDING_SIZE,
    color: spinnerColor,
  }
}
