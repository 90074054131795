import type { Context } from 'koa'

import BackendAPIService from 'shared/services/api/BackendAPIService'
import type { ServiceParameters } from 'shared/services/api/commonTypes'
import type { OrderInfoResponseV2 } from 'shared/services/api/models'
import { OrderInfoResponse } from 'shared/services/api/models/orders/orderInfoResponse'
import assert from 'shared/utils/assert'
import { createTenantPath } from 'shared/utils/routing'

interface IMiraklServiceV2 {
  getMiraklOrderInfoUrl(subOrderId: string): string
  fetchMiraklOrderInfo(
    ctx: Context,
    subOrderId: string
  ): Promise<OrderInfoResponse>
}

class MiraklServiceV2 extends BackendAPIService implements IMiraklServiceV2 {
  private readonly basePath: string
  protected tenantPath: string

  constructor(params: ServiceParameters) {
    super({
      apiVersion: 'v2',
      ...params,
    })
    const { tenant, coreShopEnvironment } = params
    this.tenantPath = createTenantPath(tenant, coreShopEnvironment)
    this.basePath = `/mirakl/${this.apiVersion}`
  }

  public getMiraklOrderInfoUrl(subOrderId: string): string {
    return `${this.basePath}/${this.tenantPath}/${subOrderId}`
  }

  async fetchMiraklOrderInfo(
    ctx: Context,
    subOrderId: string
  ): Promise<OrderInfoResponse> {
    assert(
      subOrderId,
      'MiraklServiceV2.fetchMiraklOrderInfo: please provide subOrderId'
    )
    const { data } = await this.get<OrderInfoResponseV2>(
      this.getMiraklOrderInfoUrl(subOrderId),
      undefined,
      ctx
    )

    return new OrderInfoResponse(data)
  }
}

export default MiraklServiceV2
