import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const PATH =
  'M92.428 87.883H68.856L92.92 64.967a6.624 6.624 0 0 0 0-9.639l-31.077-29.6a6.976 6.976 0 0 0-4.267-1.882h-.024a7.02 7.02 0 0 0-5.465 1.882L7.062 68.613a6.6 6.6 0 0 0-2.03 5.427v.012a6.687 6.687 0 0 0 2.033 4.2l10.112 9.63h-9.6a2.572 2.572 0 0 0 0 5.143h84.851a2.572 2.572 0 1 0 0-5.143zm-30.889-.133l-16.915-.058V76.377h8.342a6.868 6.868 0 0 0 6.963-6.754V61.8h28.858zm25.322-31.091H59.929v-25.65zm-32.074-26.4v39.364a1.75 1.75 0 0 1-1.821 1.612h-41.2zM39.481 76.377v11.3l-15.119-.05-11.811-11.25zm24.192-57.288a2.57 2.57 0 0 1 .088-3.636l8.094-7.709a2.571 2.571 0 0 1 3.545 3.724l-8.093 7.709a2.571 2.571 0 0 1-3.634-.088zm30.616 3.645a2.567 2.567 0 0 1-.088 3.635l-9.67 9.21a2.571 2.571 0 0 1-3.546-3.724l9.67-9.21a2.569 2.569 0 0 1 3.634.089zm-21.917.919l16.769-15.97a2.571 2.571 0 1 1 3.546 3.724l-16.769 15.97a2.571 2.571 0 1 1-3.546-3.724z'

const IconSendEnvelope: FC<IconProps> = props => (
  <SvgIcon {...props} path={PATH} viewBox="0 0 100 100" />
)

export default IconSendEnvelope
