// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

export interface NewThreadMessageAttachmentV1 {
  data: string
  name: string
  size?: number | string
  type: string
}

export class NewThreadMessageAttachment
  extends Model
  implements NewThreadMessageAttachmentV1
{
  public data: string
  public name: string
  public size: number
  public type: string

  constructor(src: NewThreadMessageAttachmentV1) {
    super(src)
    this.data = src.data
    this.name = src.name
    if (src.size) {
      this.size = Number(src.size)
    }
    this.type = src.type
  }
}
