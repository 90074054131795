// @ts-nocheck
import type { PimProductV1 } from '@redteclab/api/clients/bully'

import { NOTEPAD_PRODUCT_STOCK_STATUS } from 'shared/consts/marketplace'
import { Model } from 'shared/services/api/models/Model'

import { NotepadProductFlags } from './notepadProductFlags'
import { NotepadProductOffer } from './notepadProductOffer'
import type {
  NotepadProductOfferV2,
  PackSize,
  PharmaForm,
  Prices,
} from './notepadResponseTypes'

// Simple model from PIM product Model

export class NotepadProduct extends Model {
  canonicalUrl: string
  name: string
  discount: number
  flags: NotepadProductFlags
  images: string[]
  offers: NotepadProductOffer[]
  prices: Prices
  stockStatus: NOTEPAD_PRODUCT_STOCK_STATUS
  pharmaForm: PharmaForm
  upid: string
  orderLimit: number
  packageSize: PackSize
  pzn: string | undefined
  isbn: string | undefined
  ean: string | undefined
  outOfStockMessage: string | undefined

  constructor(src: PimProductV1) {
    super(src)
    this.canonicalUrl = src.canonical_url
    this.name = src.name
    this.discount = src.discount
    this.flags = new NotepadProductFlags(src.flags)
    this.images = src.images

    this.offers = Array.isArray(src.offers)
      ? src.offers.map(
          (offer: NotepadProductOfferV2) => new NotepadProductOffer(offer)
        )
      : []
    this.prices = src.prices
    this.stockStatus = src.stock_status
    this.pharmaForm = src.pharma_form
    this.upid = src.upid
    this.orderLimit = src.order_limit
    this.packageSize = src.package_size
    this.pzn = src.pzn
    this.isbn = src.isbn
    this.ean = src.ean
    this.outOfStockMessage = src.out_of_stock_message
    this.variantAttributes = src.variant_attributes
  }
}
