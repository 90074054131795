// This function checks if passed element has entered the viewport and returns true until top of that element stays within viewport.

const isElementEnteredViewport = (element: Element) => {
  const { top, bottom } = element.getBoundingClientRect()
  const windowHeight = window.innerHeight

  return top >= 0 && bottom >= 0 && top < windowHeight
}

export default isElementEnteredViewport
