/* eslint-disable no-undef */
import { IS_BROWSER, IS_JEST, IS_NODE, IS_STORYBOOK } from 'shared/consts/env'

/**
 * For more info on what and why `__webpack_public_path__`
 * plsease see https://webpack.js.org/guides/public-path/#on-the-fly
 *
 * In short `__webpack_public_path__` is used to manage dynamic asset
 * paths (ie for CNDs). We want to dynamically set the asset paths as
 * an ENV variable, when running the app, and not at build time, as it
 * usually happens.
 *
 * We are using it in Layout component to build paths for all assets and
 * we are also using it in every `runScripts` to set adequate values
 */

let dynamicAssetPath

if (IS_STORYBOOK) {
  dynamicAssetPath = ''
} else if (IS_NODE) {
  dynamicAssetPath = process.env.ASSET_PATH
  __webpack_public_path__ = dynamicAssetPath
} else if (IS_JEST) {
  window.__webpack_public_path__ = process.env.ASSET_PATH
} else if (IS_BROWSER) {
  __webpack_public_path__ = window.__webpack_public_path__
}

export default dynamicAssetPath
