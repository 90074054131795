import { IconProfileUserAvatar } from '@redteclab/icons'
import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const IconProfile: FC<IconProps> = props => (
  <SvgIcon {...props} component={IconProfileUserAvatar} />
)

export default IconProfile
