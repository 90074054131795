export const createOverlayDiv = () => document.createElement('div')

export const addOverlayDivToElem = (
  element,
  overlayDiv,
  transitionDurationInSec
) => {
  Object.assign(overlayDiv.style, {
    position: 'absolute',
    left: 0,
    background: 'black',
    borderRadius: '5px',
    zIndex: 1,
    transition: `width ${transitionDurationInSec}s ease-in`,
    height: '100%',
    width: 0,
    opacity: 0.3,
    '-webkit-transition-property': 'width',
    '-webkit-transition-duration': `${transitionDurationInSec}s`,
    '-webkit-timing-function': 'ease-in',
  })
  element.append(overlayDiv)
}
