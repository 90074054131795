import { PAGE_TYPE_CART } from 'shared/consts'
import {
  CSW_CLICK_ADD_TO_CART,
  CSW_PRODUCT_CLICK,
  CTA_BUTTON_ON_ALL_CSW,
} from 'shared/experiments/consts'
import {
  activateExperiment,
  getExperimentOnClient,
} from 'shared/experiments/utils/experiments'
import trackEvent from 'shared/experiments/utils/trackOptimizelyEvent'
import store from 'shared/store'

export default () => {
  const cartCswChangePlace = getExperimentOnClient(CTA_BUTTON_ON_ALL_CSW)
  const { pageType } = store.getPublicRuntimeConfig()?.pageProperties || {}

  if (pageType === PAGE_TYPE_CART) {
    return
  }

  if (cartCswChangePlace.isEnabled) {
    void activateExperiment(CTA_BUTTON_ON_ALL_CSW)

    const xSellWidgets = document.querySelectorAll(
      '[data-clientside-api-call~="fetchCrossSell"]'
    )

    xSellWidgets.forEach(xSellWidget => {
      xSellWidget.addEventListener('click', event => {
        let tracked = 0
        const target = <HTMLElement>event.target

        if (
          target.closest('.o-ProductSliderItem__add-to-cart') &&
          tracked === 0
        ) {
          trackEvent(CSW_CLICK_ADD_TO_CART)
          tracked = 1
        }

        if (
          target.closest(
            '[data-clientside-hook~="slider__item"] .o-ProductSliderItem__main'
          ) &&
          tracked === 0
        ) {
          trackEvent(CSW_PRODUCT_CLICK)
          tracked = 1
        }
      })
    })
  }
}
