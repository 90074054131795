import { subscribe } from 'shared/store'
import { SEARCH_RESULTS_HITS_UPDATED } from 'shared/store/ducks/events'
import { HIDDEN_CLASS } from 'views/assets/scripts/consts'
import { CLASS_PRODUCT_LABELS_MOBILE } from 'views/consts'
import debounce from 'views/utils/debounce'

const SELECTOR_PRODUCT_LABELS =
  '[data-clientside-hook~="searchProductListItemLabels"]'
const SELECTOR_PRODUCT_LABELS_COUNTER =
  '[data-clientside-hook~="productLabelCounter"]'

/*
 * The length of the product labels is checked here
 * when the length is bigger than viewport the overflowing labels are hidden
 * and a count of the hidden labels is shown
 */
export const reduceLabels = labels => {
  let listWidth

  labels.forEach(({ labelsList, items, itemsWidth: width }) => {
    const counter = labelsList.querySelector(SELECTOR_PRODUCT_LABELS_COUNTER)

    listWidth = listWidth || labelsList.offsetWidth
    let itemsWidth = width
    if (items?.length && counter) {
      items.forEach(item => item.classList.remove(HIDDEN_CLASS))
      counter.classList.add(HIDDEN_CLASS)

      if (itemsWidth >= listWidth) {
        let indexToBeHidden = items.length - 2

        while (itemsWidth >= listWidth && indexToBeHidden > 0) {
          // TODO refactor: accessing offsetWidth within a loop is heavy operation

          itemsWidth -= items[indexToBeHidden].offsetWidth
          items[indexToBeHidden].classList.add(HIDDEN_CLASS)

          indexToBeHidden -= 1
        }
        const count = items.length - 2 - indexToBeHidden
        if (count > 0) {
          counter.querySelector(
            '.a-BorderedText__label'
          ).textContent = `+${count}`
          counter.classList.remove(HIDDEN_CLASS)
        }
      }
    }
  })
}

export const getLabels = () => {
  const labelsLists = document.querySelectorAll(SELECTOR_PRODUCT_LABELS)
  const labels = []

  labelsLists.forEach(labelsList => {
    const items = labelsList.querySelectorAll("li:not([aria-hidden='true'])")
    const counter = labelsList.querySelector(SELECTOR_PRODUCT_LABELS_COUNTER)

    if (items.length > 3 && counter) {
      counter.classList.add(HIDDEN_CLASS)

      let itemsWidth = 0
      items.forEach(item => {
        item.classList.remove(HIDDEN_CLASS)
        // TODO refactor: accessing offsetWidth within a loop is heavy operation and takes here >1 sec (profiled)
        itemsWidth += item.offsetWidth + 7 // 7 === 0.5rem => margin
      })

      if (itemsWidth > 0) {
        labels.push({
          labelsList,
          itemsWidth,
          items,
        })
      }
    }
  })

  return labels
}

export const handleSerpLabels = () => {
  reduceLabels(getLabels())
}

const revealMobileLabels = () => {
  document
    .querySelectorAll(`.${CLASS_PRODUCT_LABELS_MOBILE}`)
    .forEach(element => element.classList.remove(CLASS_PRODUCT_LABELS_MOBILE))
}

const handleLabels = () => {
  const labels = getLabels()
  reduceLabels(labels)
  window.addEventListener(
    'resize',
    debounce(() => {
      reduceLabels(labels)
    })
  )
}

export default () => {
  subscribe.after(SEARCH_RESULTS_HITS_UPDATED, () => {
    handleLabels()
  })

  subscribe.after.once(SEARCH_RESULTS_HITS_UPDATED, () => {
    revealMobileLabels()
  })
}
