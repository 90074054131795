import { IconArrowRight as FigmaIconArrowRight } from '@redteclab/icons'
import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const IconArrowRight: FC<IconProps> = props => (
  <SvgIcon {...props} component={FigmaIconArrowRight} />
)

export default IconArrowRight
