import { IconCaretRight as FigmaIconCaretRight } from '@redteclab/icons'
import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const IconCaretRight: FC<IconProps> = props => (
  <SvgIcon {...props} component={FigmaIconCaretRight} />
)

export default IconCaretRight
