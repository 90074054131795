import classNames from 'clsx'
import { bool, node, string } from 'prop-types'
import { type ComponentProps, type FC, type ReactElement } from 'react'

import './TextWithIcon.scss'

type TextWithIconProps = ComponentProps<'div'> & {
  alignIconToTop?: boolean
  alignToTop?: boolean
  between?: boolean
  centered?: boolean
  icon?: ReactElement | null
  iconClassName?: string
  iconRound?: boolean
  inline?: boolean
  qaId?: string
  right?: boolean
}

const TextWithIcon: FC<TextWithIconProps> = ({
  alignIconToTop = false,
  alignToTop = false,
  between = false,
  centered = false,
  children,
  className: classNameProp,
  id,
  icon = null,
  iconClassName,
  iconRound = false,
  inline = false,
  qaId,
  right = false,
  ...rest
}) => {
  const iconSize = icon?.props.size

  const className = classNames(
    'l-flex',
    {
      'l-flex--center': centered,
      'l-flex--distribute-space': between,
      'l-flex--inline': inline,
      'l-flex--start': alignToTop || iconRound,
      'u-padding-large--top': iconRound,
      'm-TextWithIcon': true,
    },
    classNameProp
  )
  const allIconClassNames = classNames('m-TextWithIcon__icon', iconClassName, {
    'u-margin-xx-small--right': iconSize !== 'large' && !right,
    'u-margin--right': iconSize === 'large' && !right,
    'u-margin-xx-small--left': iconSize !== 'large' && right,
    'u-margin--left': iconSize === 'large' && right,
    'm-TextWithIcon__icon--round u-margin-large--right': iconRound,
    'l-flex__start': alignIconToTop,
  })

  const renderedIcon = icon && <div className={allIconClassNames}>{icon}</div>

  return (
    <div className={className} data-qa-id={qaId} id={id} {...rest}>
      {!right && renderedIcon}
      <div className="m-TextWithIcon__text">{children}</div>
      {right && renderedIcon}
    </div>
  )
}

TextWithIcon.propTypes = {
  alignIconToTop: bool,
  alignToTop: bool,
  between: bool,
  centered: bool,
  children: node,
  className: string,
  icon: node,
  iconClassName: string,
  iconRound: bool,
  id: string,
  inline: bool,
  qaId: string,
  right: bool,
}

export default TextWithIcon
