import type { LetterboxMarketplaceContentV1 } from '@redteclab/api/clients/bully'

import { Model } from 'shared/services/api/models'

export class LetterboxMarketplaceDocumentContent extends Model {
  public readonly type: string | undefined
  public readonly size: number | undefined
  public readonly documentId: string

  constructor(src: LetterboxMarketplaceContentV1) {
    super(src, { allowEmpty: false })
    this.type = src.type
    this.size = src.size
    this.documentId = src.document_id
  }
}
