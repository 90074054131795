// @ts-nocheck
import { type CartMetaDataV2 } from '@redteclab/api/clients/bully'
import { AxiosResponse } from 'axios'

import assert from 'shared/utils/assert'
import {
  CartControllerCreateCartRequestBodyV2,
  CartControllerMiniCartResposeV2,
  CartControllerRedPointsRequestV2,
  CartControllerResponseV2,
  CartControllerVoucherRequestV2,
} from 'types/api/cart'

import BackendAPIService from './BackendAPIService'
import { ServiceParameters } from './commonTypes/ServiceParameters'

const API_VERSION = 'v2'

interface ICartService {
  fetchCart: (
    purgeMessages?: boolean
  ) => Promise<AxiosResponse<CartControllerResponseV2>>
  fetchMiniCart: () => Promise<AxiosResponse<CartControllerMiniCartResposeV2>>
  createCart: (
    body: CartControllerCreateCartRequestBodyV2
  ) => Promise<AxiosResponse<CartControllerResponseV2>>
  deleteCartItem: (
    offerId: string,
    pType: string
  ) => Promise<AxiosResponse<void>>
  deleteVoucher: (voucher: string) => Promise<AxiosResponse<void>>
  addVoucher: (
    voucher: CartControllerVoucherRequestV2
  ) => Promise<AxiosResponse<CartControllerResponseV2>>
  addRedPoints: (
    voucher: CartControllerRedPointsRequestV2
  ) => Promise<AxiosResponse<CartControllerResponseV2>>
}

class CartService extends BackendAPIService implements ICartService {
  constructor(props: ServiceParameters) {
    super(props)

    this.apiVersion = API_VERSION
  }

  public getCartUrl = (): string =>
    `/cacheable/cart/${this.apiVersion}/${this.tenantPath}`
  public putCartUrl = (): string =>
    `/cacheable/cart/${this.apiVersion}/${this.tenantPath}`
  public getMiniCartUrl = (): string =>
    `/cacheable/cart/v4/${this.tenantPath}/minicart`
  public createCartUrl = (): string =>
    `/cacheable/cart/${this.apiVersion}/${this.tenantPath}/entry`
  public deleteCartUrl = (offerId: string, pType: string): string =>
    `/cacheable/cart/${this.apiVersion}/${this.tenantPath}/entry/${offerId}?prescriptionType=${pType}`
  public addERxCartUrl = (): string =>
    `/cart/${this.apiVersion}/${this.tenantPath}/entries/eRx`
  public deleteERxCartUrl = (hashedToken: string): string =>
    `/cacheable/cart/${this.apiVersion}/${this.tenantPath}/entries/eRx/${hashedToken}`
  public voucherUrl = (): string =>
    `/cacheable/cart/${this.apiVersion}/${this.tenantPath}/voucher`
  public redPointsUrl = (): string =>
    `/cacheable/cart/${this.apiVersion}/${this.tenantPath}/redpoints`
  public metadataUrl = (): string =>
    `/cacheable/cart/${this.apiVersion}/${this.tenantPath}/metadata`

  async fetchMetadata(): Promise<AxiosResponse<CartMetaDataV2>> {
    const response = await this.get<CartMetaDataV2>(this.metadataUrl())
    this.logger.debug(`CartService fetch cart!`, 'dataFetching')
    return response
  }

  async fetchCart(
    purgeMessages?: boolean
  ): Promise<AxiosResponse<CartControllerResponseV2>> {
    const shouldPurge: boolean = purgeMessages || false
    const response: AxiosResponse<CartControllerResponseV2> = await this.get(
      this.getCartUrl(),
      { params: { purgeMessages: `${shouldPurge}` } }
    )

    this.logger.debug(`CartService fetch cart!`, 'dataFetching')
    return response
  }

  async putCart(props: {
    prescriptionFollows?: boolean
    collectiveOrder?: boolean
  }): Promise<AxiosResponse<CartControllerResponseV2>> {
    const response: AxiosResponse<CartControllerResponseV2> = await this.put(
      this.putCartUrl(),
      props
    )
    this.logger.debug(`CartService put prescriptionType!`, 'dataFetching')
    return response
  }

  public async fetchMiniCart(): Promise<
    AxiosResponse<CartControllerMiniCartResposeV2>
  > {
    const response: AxiosResponse<CartControllerMiniCartResposeV2> =
      await this.get(this.getMiniCartUrl())
    this.logger.debug(`CartService fetch minicart!`, 'dataFetching')
    return response
  }

  public async createCart(
    body: CartControllerCreateCartRequestBodyV2,
    purgeMessages?: boolean
  ): Promise<AxiosResponse<CartControllerResponseV2>> {
    assert(body, 'CartService.createCart: body missing')
    const shouldPurge: boolean = purgeMessages || false
    const response: AxiosResponse<CartControllerResponseV2> = await this.post(
      this.createCartUrl(),
      body,
      {
        params: { purgeMessages: `${shouldPurge}` },
      }
    )

    this.logger.debug(`CartService create/update cart!`, 'dataFetching')
    return response
  }

  async deleteCartItem(
    offerId: string,
    pType: string
  ): Promise<AxiosResponse<void>> {
    let response: AxiosResponse<void> = null

    try {
      response = await this.delete(this.deleteCartUrl(offerId, pType), {
        data: {},
      })
    } catch (_e) {
      // Do nothing, temp solution for 400 response
    }
    this.logger.debug(`CartService delete cart!`, 'dataFetching')
    return response
  }

  public async deleteVoucher(code: string): Promise<AxiosResponse<void>> {
    assert(code, 'CartService.deleteVoucher: code missing')
    const response: AxiosResponse<void> = await this.delete(
      `${this.voucherUrl()}/${code}`,
      { data: {} }
    )
    this.logger.debug(`CartService delete voucher in cart!`, 'dataFetching')
    return response
  }

  async addVoucher({
    voucherCode,
    purgeMessages,
  }: CartControllerVoucherRequestV2): Promise<
    AxiosResponse<CartControllerResponseV2>
  > {
    assert(voucherCode, 'CartService.addVoucher: code missing')
    const shouldPurge: boolean = purgeMessages || false
    const response: AxiosResponse<CartControllerResponseV2> = await this.post(
      this.voucherUrl(),
      {
        voucherCode,
      },
      { params: { purgeMessages: `${shouldPurge}` } }
    )
    this.logger.debug(`CartService add voucher to cart!`, 'dataFetching')
    return response
  }

  public async createERxCart(
    body: string[]
  ): Promise<AxiosResponse<CartControllerResponseV2>> {
    assert(body, 'CartService.addERxToCart: body missing')
    const response: AxiosResponse<CartControllerResponseV2> = await this.post(
      this.addERxCartUrl(),
      body
    )
    this.logger.debug(`CartService add eRX cart!`, 'dataFetching')
    return response
  }

  async deleteCartERxItem(hashedToken: string): Promise<AxiosResponse<void>> {
    const response: AxiosResponse<void> = await this.delete(
      this.deleteERxCartUrl(hashedToken),
      { data: {} }
    )
    this.logger.debug(`CartService delete eRx cart!`, 'dataFetching')
    return response
  }

  async deleteRedPoints(): Promise<AxiosResponse<CartControllerResponseV2>> {
    const response: AxiosResponse<CartControllerResponseV2> = await this.delete(
      this.redPointsUrl(),
      { data: {} }
    )
    this.logger.debug('CartService delete redpoints in cart!', 'dataFetching')
    return response
  }

  async addRedPoints({
    redpointsAmount,
  }: CartControllerRedPointsRequestV2): Promise<
    AxiosResponse<CartControllerResponseV2>
  > {
    assert(redpointsAmount, 'CartService.addRedPoints: amount missing')
    const response: AxiosResponse<CartControllerResponseV2> = await this.post(
      this.redPointsUrl(),
      {
        redpointsAmount,
      }
    )
    this.logger.debug('CartService add redpoints to cart!', 'dataFetching')
    return response
  }
}

export default CartService
