export const SORT_BY_RELEVANCE = 'Relevanz'
export const SORT_BY_PRICE = 'Preis'
export const SORT_BY_RATING = 'Bewertung'

export const PRODUCT_FILTER_DEFAULT = {
  from: 0,
  to: 10,
  total: 20,
  limit: 10,
  limitOptions: [10, 20, 30, 40, 50],
  sortBy: SORT_BY_RELEVANCE,
  sortByOptions: [SORT_BY_RELEVANCE, SORT_BY_PRICE, SORT_BY_RATING],
}

export const SEARCH_CHANNEL_ALGOLIA = 'algolia'
export const HITS_BASE_CLASSNAME = 'o-Hits'
export const ALGOLIA_SEARCH_LIST_HOOK = 'algoliaSearchListContainer'
export const ALGOLIA_SEARCH_LIST_SELECTOR = `[data-clientside-hook~="${ALGOLIA_SEARCH_LIST_HOOK}"]`

export const CLASS_PRODUCT_LABELS_MOBILE =
  'o-SearchProductListItem__labels--no-js-mobile'
