// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"font-family-header": "\"helvetica neue\",\"Nimbus Sans L\",sans-serif",
	"font-family-header-loaded": "\"helvetica neue\",\"Nimbus Sans L\",sans-serif",
	"font-family-body": "\"helvetica neue\",\"Nimbus Sans L\",sans-serif",
	"font-family-body-loaded": "\"helvetica neue\",\"Nimbus Sans L\",sans-serif",
	"font-weight-light": "300",
	"font-weight-normal": "400",
	"font-weight-bold": "700",
	"line-height-base": "1.5",
	"font-size-base": "14px",
	"font-size-base-small": "12px",
	"font-size-base-html": "14px",
	"font-size-base-h0": "32px",
	"font-size-base-h1": "24px",
	"font-size-base-h2": "20px",
	"font-size-base-h3": "16px",
	"font-size-base-h4": "14px",
	"font-size-base-h5": "14px",
	"font-size-base-h6": "12px",
	"font-size-medium-html": "14px",
	"font-size-medium-h0": "86px",
	"font-size-medium-h1": "33px",
	"font-size-medium-h2": "22px",
	"font-size-medium-h3": "16px",
	"font-size-medium-h4": "14px",
	"font-size-medium-h5": "14px",
	"font-size-medium-h6": "12px"
};
module.exports = ___CSS_LOADER_EXPORT___;
