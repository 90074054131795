import type { Reducer } from 'redux'

import { type NowDataState } from 'types/redux'

import { type NowDataActionTypes } from './actions'
import preloadedNowDataState from './preloadedState'
import { UPDATE_NOW_DATA_SUCCESS } from './types.consts'

// REDUCER: modify state according to dispatched actions
const reducer: Reducer<NowDataState | null> = (
  state: NowDataState | null = preloadedNowDataState,
  action: NowDataActionTypes
): NowDataState | null => {
  if (action.type === UPDATE_NOW_DATA_SUCCESS) {
    return action.payload
  }

  return state
}
export default reducer
