import { IconClose } from '@redteclab/icons'
import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const IconCross: FC<IconProps> = props => (
  <SvgIcon {...props} component={IconClose} />
)

export default IconCross
