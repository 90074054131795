export const ANIMATION_DURATION_MS = 200

export const COMPONENT_SIZE = ['x-small', 'small', 'default', 'large']

export const ICON_SIZE = [
  'x-small',
  'small',
  'default',
  'large',
  'x-large',
  'xx-large',
]
