import {
  CART_CHECKOUT_BUTTON_CLICK,
  CART_FREE_GIFT_PROMOTION,
} from 'shared/experiments/consts'
import {
  activateExperiment,
  getExperimentOnClient,
} from 'shared/experiments/utils/experiments'
import { getExperimentNamePerTenant } from 'shared/experiments/utils/tenantBasedExperimentsUtils'
import trackEvent from 'shared/experiments/utils/trackOptimizelyEvent'
import store from 'shared/store'

export default () => {
  const { publicRuntimeConfig } = store.getState()
  const { tenant, deviceClass } = publicRuntimeConfig?.publicConfig || {}
  const cartFreeGiftPromotionExperiment = getExperimentNamePerTenant(
    CART_FREE_GIFT_PROMOTION,
    tenant,
    deviceClass
  )
  const cartFreeGiftPromotionExperimentActivated =
    window.sessionStorage.getItem(cartFreeGiftPromotionExperiment)

  const { isEnabled } = getExperimentOnClient(cartFreeGiftPromotionExperiment)

  if (!isEnabled) {
    return
  }

  const isElementInViewport = (el: Element): boolean => {
    const rect = el.getBoundingClientRect()
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }
  const freebieContainer = document.querySelector('.o-ProductListCart__freebie')

  const handleScroll = () => {
    if (
      !cartFreeGiftPromotionExperimentActivated &&
      freebieContainer &&
      isElementInViewport(freebieContainer)
    ) {
      void activateExperiment(cartFreeGiftPromotionExperiment)
      window.sessionStorage.setItem(cartFreeGiftPromotionExperiment, 'true')

      window.removeEventListener('scroll', handleScroll)
    }
  }

  // Check if the element is already in the viewport on page load
  handleScroll()
  //On scroll
  window.addEventListener('scroll', handleScroll)

  const toCheckoutButtonBottom = document.getElementById('ToCheckout')
  const toCheckoutButtonTop = document.getElementById('ToCheckoutTop')

  toCheckoutButtonBottom?.addEventListener('click', () => {
    trackEvent(CART_CHECKOUT_BUTTON_CLICK)
  })

  toCheckoutButtonTop?.addEventListener('click', () => {
    trackEvent(CART_CHECKOUT_BUTTON_CLICK)
  })
}
