export const ERROR_TOO_LARGE = 'ERROR_TOO_LARGE'
export const ERROR_WRONG_EXT = 'ERROR_WRONG_EXT'
export const ERROR_TOO_SHORT_MESSAGE = 'ERROR_TOO_SHORT_MESSAGE'
export const ERROR_TOO_LONG_MESSAGE = 'ERROR_TOO_LONG_MESSAGE'

export const ERRORS = {
  [ERROR_WRONG_EXT]:
    /*i18n*/ 'account.messages.contactSellerForm.errors.invalidExtension',
  [ERROR_TOO_LARGE]:
    /*i18n*/ 'account.messages.contactSellerForm.errors.invalidSize',
  [ERROR_TOO_SHORT_MESSAGE]:
    /*i18n*/ 'account.messages.contactSellerForm.errors.messageTooShort',
  [ERROR_TOO_LONG_MESSAGE]:
    /*i18n*/ 'account.messages.contactSellerForm.errors.messageTooLong',
}
