import cssVars from 'views/utils/cssVars'

export const BREAKPOINT_TINY = cssVars.breakpoints.tiny
export const BREAKPOINT_SMALL = cssVars.breakpoints.small
export const BREAKPOINT_MEDIUM = cssVars.breakpoints.medium
export const BREAKPOINT_LARGE = cssVars.breakpoints.large

export const BREAKPOINT_SMALL_TINY = cssVars.breakpoints['small-tiny']

export const BREAKPOINT_LANDSCAPE_SMALL = cssVars.breakpoints['landscape-small']
export const BREAKPOINT_IE11 = cssVars.breakpoints.ie
