import { UNAUTHORIZED } from 'shared/consts/codes'

import BackendError from './BackendError'

class UnauthorizedError extends BackendError {
  constructor(messageWithData) {
    super(messageWithData)
    this.status = UNAUTHORIZED
  }
}

export default UnauthorizedError
