import { padWithLeadingZeroes } from 'shared/services/api/helpers/validatorUtils'

class ValidatorClass {
  regExpValidPzn = /^([a-z]{0,2})(\d+)$/i
  regExpExceptionsPzn = [/^d\d$/i]
  pznMaxLength = 12
  pznMinNumericLength = 8
  optionalPrefix = null

  isMatchingAnyException(pzn) {
    return this.regExpExceptionsPzn.some(regex => regex.test(pzn))
  }

  validate(pzn) {
    this.pzn = pzn

    this.res = {
      valid: false,
      pzn: null,
      pznWithoutLeadingLetters: null,
    }

    return this.strategy()
  }

  strategy() {
    if (!this.pzn || this.pzn.length > this.pznMaxLength) {
      this.res.valid = false

      return this.res
    }

    const matches = this.pzn.match(this.regExpValidPzn)
    this.res.valid = Boolean(matches) && !this.isMatchingAnyException(this.pzn)

    if (this.res.valid) {
      const [, letters, digits] = matches
      const lettersUpperCase = letters.toUpperCase()

      const prefix =
        lettersUpperCase === this.optionalPrefix ? '' : lettersUpperCase
      const pznWithoutLeadingLetters = padWithLeadingZeroes(digits)
      const numericPart = prefix ? digits : padWithLeadingZeroes(digits)

      this.res.pzn = `${prefix}${numericPart}`
      this.res.pznWithoutLeadingLetters = pznWithoutLeadingLetters
    }

    return this.res
  }

  arePznsEqual(pzn1, pzn2) {
    if ([pzn1, pzn2].some(pzn => !pzn || !this.regExpValidPzn.test(pzn))) {
      return false
    }

    const [, letters1, digits1] = pzn1.match(this.regExpValidPzn)
    const [, letters2, digits2] = pzn2.match(this.regExpValidPzn)

    const prefixes = [letters1, letters2].filter(Boolean)
    if (prefixes.length === 1) {
      if (prefixes[0] !== this.optionalPrefix) {
        return false
      }
    } else if (letters1 !== letters2) {
      return false
    }

    const [shortest, longest] = [digits1, digits2].sort(
      ({ length: l1 }, { length: l2 }) => l1 - l2
    )
    const lengthDiff = longest.length - shortest.length

    return (
      longest.endsWith(shortest) &&
      longest.substr(0, lengthDiff) === '0'.repeat(lengthDiff)
    )
  }
}

export const validator = new ValidatorClass()
export const validate = pzn => validator.validate(pzn)
export const arePznsEqual = (pzn1, pzn2) => validator.arePznsEqual(pzn1, pzn2)
