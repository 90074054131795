import classNames from 'clsx'
import type { ReactNode } from 'react'

import { TENANT_IT } from 'shared/consts'
import IconProfile from 'views/components/atoms/Icon/IconProfile'
import { ADVICE_MODAL_ID } from 'views/consts'
import setInnerHTML from 'views/utils/setInnerHTML'
import { usePublicConfig } from 'views/utils/shopAndRuntimeConfigUtils'

type ModalTitleProps = {
  id: string
  title?: ReactNode | string
  className?: string
  titleHasInnerHTML?: boolean
  children?: ReactNode
}

const ModalTitle = ({
  id,
  title = null,
  className = '',
  titleHasInnerHTML = false,
  children = null,
}: ModalTitleProps) => {
  const { tenant } = usePublicConfig()

  if (!title) {
    return null
  }

  const titleId = `${id}__Modal__title`

  const headingProps = {
    className: classNames(
      'm-Modal__title a-h2@medium u-no-margin u-font-weight--normal',
      className
    ),
    id: titleId,
    ...(titleHasInnerHTML ? setInnerHTML(title) : { children: title }),
  }

  const isProductConsultationAndIsRebranding =
    id === ADVICE_MODAL_ID && tenant === TENANT_IT

  return (
    <span className="m-Modal__header a-box--medium a-box a-box--light a-box--no-rounded-corners">
      {isProductConsultationAndIsRebranding && (
        <IconProfile className="m-Modal__icon-profile" />
      )}
      {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
      <h2 {...headingProps} />
      {children}
    </span>
  )
}

export default ModalTitle
