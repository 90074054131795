import qs from 'qs'

import { ADDRESS_TYPE } from 'shared/consts/address'
import assert from 'shared/utils/assert'

import BackendAPIService from './BackendAPIService'

// We need to reuse this Service and adjust it...

const API_VERSION = 'v2'

const config = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
}

class AddressService extends BackendAPIService {
  constructor(props) {
    super(props)

    this.apiVersion = API_VERSION
  }

  addressRoot() {
    return `/cacheable/addresses/${this.apiVersion}/${this.tenantPath}`
  }

  getRootUrl(id) {
    if (id) {
      return `${this.addressRoot()}/${id}`
    }
    return `${this.addressRoot()}/`
  }

  getRootAddressesUrl(types = []) {
    if (types.length) {
      return `${this.addressRoot()}/?${qs.stringify(
        { types },
        { arrayFormat: 'repeat' }
      )}`
    }
    return `${this.addressRoot()}/`
  }

  getRootDoorDeliveryUrl() {
    return `${this.addressRoot()}/doorDelivery`
  }

  getRootPupDeliveryUrl() {
    return `${this.addressRoot()}/pickupStationDelivery`
  }

  getDefaultZipUrl() {
    return `${this.addressRoot()}/defaultZip`
  }

  async createAddress(payload) {
    assert(payload, 'AddressService.createAddress: please provide "payload"')
    const response = await this.post(this.getRootUrl(), payload, config)
    this.logger.debug('Address Service create address!', 'dataCreation')
    return response
  }

  async updateAddress(id, payload) {
    assert(id, 'AddressService.updateAddress: please provide "id"')
    assert(payload, 'AddressService.updateAddress: please provide "payload"')
    const response = await this.put(this.getRootUrl(id), payload, config)
    this.logger.debug(
      `Address Service update address id ${id} !`,
      'dataUpdating'
    )
    return response
  }

  async deleteAddress(id) {
    assert(id, 'AddressService.deleteAddress: please provide "id"')
    const response = await this.delete(this.getRootUrl(id), {
      ...config,
      data: {},
    })
    this.logger.debug(
      `Address Service delete address id ${id} !`,
      'dataDeleting'
    )
    return response
  }

  async doorDelivery(ctx = null) {
    const response = await this.get(this.getRootDoorDeliveryUrl(), null, ctx)
    this.logger.debug(`Address Service get door delivery list!`, 'dataFetching')
    return response
  }

  /**
   *
   * @param {import('koa').Context} ctx
   * @returns
   */
  async getBillingAddress(ctx = null) {
    const {
      data: { addresses },
    } = await this.doorDelivery(ctx)
    return (
      (addresses?.length &&
        addresses.find(address => address.type === ADDRESS_TYPE.BILLING)) ||
      {}
    )
  }

  async getDefaultZip(ctx = null) {
    const response = await this.get(this.getDefaultZipUrl(), null, ctx)

    return response.data.zip
  }

  async getShippingAddress(ctx = null) {
    const {
      data: { addresses: shippingAddresses },
    } = await this.doorDelivery(ctx)
    const {
      data: { addresses: pickupAddresses },
    } = await this.pickupStationDelivery()
    const addresses = [
      ...shippingAddresses.reverse(),
      ...pickupAddresses.reverse(),
    ]
    return (
      (addresses?.length &&
        addresses.filter(
          address =>
            address.type === ADDRESS_TYPE.SHIPPING ||
            address.type === ADDRESS_TYPE.PICKUP
        )) ||
      []
    )
  }

  async upsertBillingAddress(payload, ctx = null) {
    assert(
      payload,
      'AddressService.upsertBillingAddress: please provide "payload"'
    )
    const address = await this.getBillingAddress(ctx)
    const { id: addressId } = address
    const newAddress = addressId
      ? await this.updateAddress(addressId, {
          ...address,
          ...payload,
        })
      : await this.createAddress({ ...payload, type: ADDRESS_TYPE.BILLING })
    return { address: newAddress, created: Boolean(addressId) }
  }

  async upsertShippingAddress(payload) {
    assert(
      payload,
      'AddressService.upsertShippingAddress: please provide "payload"'
    )
    const newAddress = payload.id
      ? await this.updateAddress(payload.id, {
          ...payload,
        })
      : await this.createAddress({ ...payload })
    return { address: newAddress, created: Boolean(payload.id) }
  }

  async pickupStationDelivery() {
    const response = await this.get(this.getRootPupDeliveryUrl())
    this.logger.debug(
      `Address Service get pickupStationDelivery list!`,
      'dataFetching'
    )
    return response
  }

  async getAddresses(types = []) {
    const {
      data: { addresses },
    } = await this.get(this.getRootAddressesUrl(types))
    return addresses.reverse()
  }
}

export default AddressService
