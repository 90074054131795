// @ts-nocheck
import Storage from 'views/assets/scripts/services/storage/storage'

export default () => {
  const HOOK_NAME = 'dismiss'

  function handleClick(event) {
    const element = event.currentTarget
    const controlledElementId = element.getAttribute('aria-controls')
    const controlledElement = document.getElementById(controlledElementId)
    const { transitionOutClass, dismissedClass } = element.dataset

    try {
      const key = `dismissed:${controlledElementId}`
      const storage = new Storage('sessionStorage')
      storage.set(key, '1')
    } catch (_) {
      /* ignore errors */
    }

    if (!transitionOutClass || !AnimationEvent) {
      controlledElement.classList.add(dismissedClass)
    } else {
      const onAnimationEnd = () => {
        controlledElement.classList.remove(transitionOutClass)
        controlledElement.classList.add(dismissedClass)
        controlledElement.removeEventListener('animationend', onAnimationEnd)
      }
      controlledElement.classList.add(transitionOutClass)
      controlledElement.addEventListener('animationend', onAnimationEnd)
    }
  }

  function initDismiss(element: HTMLElement): HTMLElement {
    element.addEventListener('click', handleClick)
    element.dataset.dismissReady = '1'
    return element
  }

  const elements = Array.from(
    document.querySelectorAll(`[data-clientside-hook~=${HOOK_NAME}]`)
  )
  return elements.map(initDismiss)
}
