import { Model } from 'shared/services/api/models/Model'

import type { OrderInfoThreadsV2 } from './ordersResponseTypes'

export class OrderInfoThreads extends Model {
  public code: string
  public label: string
  public type: string
  public id: string

  constructor(src: OrderInfoThreadsV2) {
    super(src)
    this.id = src.id
    this.code = src.reason.code
    this.label = src.reason.label
    this.type = src.reason.type
  }
}
