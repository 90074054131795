// @ts-nocheck
import { Context } from 'koa'

import {
  SUBSCRIPTION_ACTION,
  SUBSCRIPTION_TYPE,
} from 'shared/consts/mailingSubscription'
import {
  CreateSubscriptionBody,
  CreateSubscriptionBodyV1,
  FetchSubscriptionResponse,
  FetchSubscriptionResponseV1,
} from 'shared/services/api/models'
import assert from 'shared/utils/assert'

import BackendAPIService from './BackendAPIService'
import { ServiceParameters } from './commonTypes/ServiceParameters'

const API_VERSION = 'v1'

interface IMailingSubscriptionService {
  createSubscription(
    ctx: Context,
    createSubscriptionBody: CreateSubscriptionBodyV1
  ): Promise<number>

  activateSubscription(ctx: Context, subscriptionToken: string): Promise<number>
  unsubscribeSubscription(ctx: Context, emailAddress: string): Promise<number>

  fetchMailingSubscription(
    ctx: Context,
    customerNumber: string
  ): Promise<FetchSubscriptionResponse>
}

class MailingSubscriptionService
  extends BackendAPIService
  implements IMailingSubscriptionService
{
  constructor(params: ServiceParameters) {
    super({ apiVersion: API_VERSION, ...params })
    this.baseURL = `/mailing-subscription/${this.apiVersion}/${this.tenantPath}`
  }

  public getSubscriptionURL() {
    return `${this.baseURL}/subscriptions`
  }

  public getActivateSubscriptionURL(subscriptionToken: string) {
    return `${this.baseURL}/activate/subscriptions/${subscriptionToken}`
  }

  async fetchMailingSubscription(
    ctx: Context,
    customerNumber: string
  ): Promise<FetchSubscriptionResponse> {
    assert(
      customerNumber,
      'MailingSubscriptionService.fetchMailingSubscription: please provide "customerNumber"'
    )
    return (
      (await this.fetchSubscriptions(ctx, customerNumber)).find(
        subscription =>
          subscription.subscriptionType === SUBSCRIPTION_TYPE.NEWSLETTER
      ) || new FetchSubscriptionResponse(null)
    )
  }

  async fetchSubscriptions(
    ctx: Context,
    customerNumber: string
  ): Promise<FetchSubscriptionResponse[]> {
    assert(
      customerNumber,
      'MailingSubscriptionService.fetchSubscriptions: please provide "customerNumber"'
    )

    const { data: subscriptions } = await this.get<
      FetchSubscriptionResponseV1[]
    >(
      this.getSubscriptionURL(),
      {
        params: {
          customerNumber,
        },
      },
      ctx
    )
    return subscriptions?.length
      ? subscriptions.map(
          subscription => new FetchSubscriptionResponse(subscription)
        )
      : []
  }

  async createSubscription(
    ctx: Context,
    createSubscriptionBody: CreateSubscriptionBodyV1
  ): Promise<number> {
    const payload: CreateSubscriptionBody = new CreateSubscriptionBody(
      createSubscriptionBody
    )

    const { status } = await this.post<CreateSubscriptionBodyV1, void>(
      this.getSubscriptionURL(),
      payload,
      {},
      ctx
    )
    return status
  }
  async activateSubscription(
    ctx: Context,
    subscriptionToken: string
  ): Promise<number> {
    assert(
      subscriptionToken,
      'MailingSubscriptionService.activateSubscription: please provide "subscriptionToken"'
    )
    const { status } = await this.put(
      this.getActivateSubscriptionURL(subscriptionToken),
      {},
      {},
      ctx
    )
    return status
  }

  async unsubscribeSubscription(
    ctx: Context,
    emailAddress: string
  ): Promise<number> {
    assert(
      emailAddress,
      'MailingSubscriptionService.unsubscribeSubscription: please provide "emailAddress"'
    )
    const { status } = await this.patch(
      this.getSubscriptionURL(),
      {
        subscriptionType: SUBSCRIPTION_TYPE.NEWSLETTER,
        emailAddress,
        action: SUBSCRIPTION_ACTION.UNSUBSCRIBE,
      },
      {},
      ctx
    )
    return status
  }
}

export default MailingSubscriptionService
