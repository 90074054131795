import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const PATH =
  'M50.6666 100.667C78.2808 100.667 100.667 78.2809 100.667 50.6667C100.667 23.0524 78.2808 0.666672 50.6666 0.666672C23.0524 0.666672 0.666626 23.0524 0.666626 50.6667C0.666626 78.2809 23.0524 100.667 50.6666 100.667 M50.6669 44.6666C53.9806 44.6666 56.6669 47.3529 56.6669 50.6666V70.6666C56.6669 73.9803 53.9806 76.6666 50.6669 76.6666C47.3532 76.6666 44.6669 73.9803 44.6669 70.6666V50.6666C44.6669 47.3529 47.3532 44.6666 50.6669 44.6666 M44.6669 30.6667C44.6669 27.3529 47.3532 24.6667 50.6669 24.6667H50.7169C54.0306 24.6667 56.7169 27.3529 56.7169 30.6667C56.7169 33.9804 54.0306 36.6667 50.7169 36.6667H50.6669C47.3532 36.6667 44.6669 33.9804 44.6669 30.6667Z'

const IconCircleInfo: FC<IconProps> = props => (
  <SvgIcon {...props} path={PATH} viewBox="0 0 100 100" />
)

export default IconCircleInfo
