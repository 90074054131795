import {
  SELECTOR_DUMMY_SEARCH_BOX,
  SELECTOR_MOBILE_SEARCH_ICON,
} from 'views/containers/browser/SearchBoxContainer/consts'

const OVERLAY_CLASSNAME = 'o-SearchboxMobile__overlay'

export default () => {
  const mobileSearchIcon = document.querySelector(SELECTOR_MOBILE_SEARCH_ICON)
  const mobileDummySearchBox = document.querySelector(SELECTOR_DUMMY_SEARCH_BOX)
  const overlay = document.querySelector(
    '[data-clientside-hook~="SearchboxMobileOverlay"]'
  )
  const searchboxCloseButton = document.querySelector(
    '[data-clientside-hook~="hideSearchboxMobile"]'
  )
  const searchboxContainer = document.querySelector(
    '[data-clientside-hook~="SearchBoxMobileContainer"]'
  )
  const mobileSearchTriggers = [mobileDummySearchBox, mobileSearchIcon].filter(
    Boolean
  )
  const mobileSearchboxClosers = [searchboxCloseButton, overlay].filter(Boolean)

  mobileSearchTriggers.forEach(el => {
    el.addEventListener('click', () => {
      window.scrollTo(0, 0)
      searchboxContainer.classList.add('active')
      overlay.classList.add(OVERLAY_CLASSNAME)
    })
  })

  mobileSearchboxClosers.forEach(el => {
    el.addEventListener('click', () => {
      overlay.classList.remove(OVERLAY_CLASSNAME)
      searchboxContainer.classList.remove('active')
    })
  })
}
