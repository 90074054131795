import { type AxiosError } from 'axios'

class DataLogError extends Error {
  status: string
  error?: string
  method?: string
  url?: string
  customMessage?: string
  'x-request-id'?: string

  constructor(messageWithData: AxiosError) {
    let message = String(messageWithData)
    const { request: { path = null } = {}, response } = messageWithData

    if (path !== null) {
      message = `${message} on requested URL: ${path}`
    }

    super(message)

    this.name = this.constructor.name
    this.status = response?.status || response?.data?.statusCode || 'Unknown'

    try {
      // extend with axios custom error props to help tracking the root cause
      // We need to send them as strings so they will appear in apm
      this.error = JSON.stringify(response?.data.error)
      this.message = JSON.stringify(response?.data.message)
      this['x-request-id'] = response?.headers['x-request-id'] || 'not provided'
      this.method = response?.config.method
      this.url = response?.config.url
    } catch (e) {
      this.customMessage = 'Failed to parse custom error data'
    }
  }
}

export default DataLogError
