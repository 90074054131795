import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const PATH =
  'M50,5A44.842,44.842,0,0,0,34.626,7.7a2.568,2.568,0,0,0,1.756,4.826A40.137,40.137,0,1,1,19.419,24.507v6.976a2.569,2.569,0,1,0,5.137,0V18.747a2.551,2.551,0,0,0-1.676-2.389,2.5,2.5,0,0,0-.23-.133,2.531,2.531,0,0,0-1.452-.192H9.105a2.569,2.569,0,0,0,0,5.137h6.417A44.95,44.95,0,1,0,50,5Z'

const IconRefresh: FC<IconProps> = props => (
  <SvgIcon {...props} path={PATH} viewBox="0 0 100 100" />
)

export default IconRefresh
