import { IconCaretUp as FigmaIconCaretUp } from '@redteclab/icons'
import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const IconCaretUp: FC<IconProps> = props => (
  <SvgIcon {...props} component={FigmaIconCaretUp} />
)

export default IconCaretUp
