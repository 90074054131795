import {
  type PimPageableProductListV1,
  type PimProductV1,
  type PimProductWithVariantsV1,
} from '@redteclab/api/clients/bully'
import { type AxiosResponse } from 'axios'

import throwIfNotValidId from 'server/utils/throwIfNotValidId'
import assert from 'shared/utils/assert'

import BackendAPIService from './BackendAPIService'
import { validate } from './helpers/idValidator'

class PimProductService extends BackendAPIService {
  readonly apiVersion: string = 'v1'

  getBasePath(): string {
    return `/cacheable/${this.language}/pim/${this.apiVersion}/${this.tenantPath}`
  }

  getPimProductPath(upid: string): string {
    // @ts-ignore
    const { valid, id } = validate(upid)
    throwIfNotValidId(valid, id)
    return `${this.getBasePath()}/products/${id}`
  }

  getPimProductWithVariantsPath(upid: string): string {
    // @ts-ignore
    const { valid, id } = validate(upid)
    throwIfNotValidId(valid, id)
    return `${this.getBasePath()}/products-with-variants/${id}`
  }

  getPimProductsPath({
    upids,
    offset = 0,
    limit = 0,
    fields,
  }: {
    upids: string[]
    offset: number
    limit: number
    fields?: string
  }): string {
    let path = `${this.getBasePath()}/products?upids=${upids.join(
      ','
    )}&offset=${offset}&limit=${limit}`

    if (fields) {
      path = path + `&fields=${fields}`
    }

    return path
  }

  async fetchPimProduct(
    id: string,
    params: { channel: string }
  ): Promise<AxiosResponse<PimProductV1>> {
    assert(id, 'PimProductService.fetchProduct: please provide an "id"')
    this.logger.debug(`Fetching PimProduct ${id}`, 'dataFetching')
    const { channel } = params || {}
    const response = await this.get<PimProductV1>(this.getPimProductPath(id), {
      params: { channel },
    })
    this.logger.debug(`PimProduct ${id} fetched!`, 'dataFetching')
    return response
  }

  async fetchPimProductWithVariants(
    id: string,
    params: { channel: string; variantsChannels: string }
  ): Promise<AxiosResponse<PimProductWithVariantsV1>> {
    assert(id, 'PimProductService.fetchProduct: please provide an "id"')
    this.logger.debug(`Fetching PimProduct ${id}`, 'dataFetching')
    const { channel, variantsChannels } = params || {}
    const response = await this.get<PimProductWithVariantsV1>(
      this.getPimProductWithVariantsPath(id),
      {
        params: { channel, variantsChannels },
      }
    )
    this.logger.debug(`PimProduct ${id} fetched!`, 'dataFetching')
    return response
  }

  async fetchMultiplePimProducts(
    upids: string[],
    options: {
      offset: number
      limit: number
      channel?: string
      fields?: string
    } = {
      offset: 0,
      limit: 0,
    }
  ): Promise<AxiosResponse<PimPageableProductListV1>> {
    assert(
      upids,
      'PimProductService.fetchMultiplePimProducts: The list of "upids" may not be null or empty.'
    )

    this.logger.debug(
      `Fetching multiple pim products: ${upids.join(', ')} start`,
      'dataFetching'
    )

    const response = await this.get<PimPageableProductListV1>(
      this.getPimProductsPath({ upids, ...options })
    )

    this.logger.debug(
      `Fetching multiple pim products: ${upids.join(', ')} finish.`,
      'dataFetching'
    )

    return response
  }
}

export default PimProductService
