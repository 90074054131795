import { stringify } from 'qs'

import assert from 'shared/utils/assert'

import BackendAPIService from './BackendAPIService'

const API_VERSION = 'v2'
const API_VERSION_V3 = 'v3'

class RedPointsService extends BackendAPIService {
  constructor(params) {
    super({ apiVersion: 'v1', ...params })
    this.apiVersionV2 = API_VERSION
    this.apiVersionV3 = API_VERSION_V3
  }

  postActivateCustomerByOptinCode(optinCode) {
    return `/redpoints/${this.apiVersion}/${this.tenantPath}/optin/${optinCode}`
  }

  getRedPointsCustomerBasePathV2() {
    return `/redpoints/${this.apiVersionV2}/${this.tenantPath}/customer`
  }

  getRedPointsCustomerBasePath(shopCustomerId) {
    return `/redpoints/${this.apiVersion}/${this.tenantPath}/customers/${shopCustomerId}`
  }

  getRedPointsOrdersBasePath(shopCustomerId) {
    return `/redpoints/${this.apiVersion}/${this.tenantPath}/customers/${shopCustomerId}/orders`
  }

  getUpdateOrdersPath(req) {
    return `${this.getRedPointsOrdersBasePath(req.shopCustomerID)}/${
      req.shopOrderID
    }/update`
  }

  getEventListPath(req) {
    return `${this.getRedPointsCustomerBasePath(
      req.shopCustomerID
    )}/events?${stringify({
      ...req.params,
    })}`
  }

  getEventPath(shopCustomerId, eventId) {
    return `${this.getRedPointsOrdersBasePath(
      shopCustomerId
    )}/events/${eventId}`
  }

  getReserveOrdersPath(req) {
    return `${this.getRedPointsOrdersBasePath(req.shopCustomerId)}/cart/${
      req.shopCartId
    }`
  }

  getPlaceOrdersPath(req) {
    return `${this.getRedPointsOrdersBasePath(req.shopCustomerID)}/${
      req.shopOrderId
    }?${stringify({
      ...req.shopCartId,
    })}`
  }

  getOrderPath(shopCustomerId, shopOrderId) {
    return `${this.getRedPointsOrdersBasePath(shopCustomerId)}/${shopOrderId}`
  }

  checkOptInBasePath(optInCode) {
    return `/redpoints/${this.apiVersion}/${this.tenantPath}/optin/${optInCode}`
  }

  createOptInCodeBasePath(req) {
    return `${this.getRedPointsCustomerBasePath(
      req.shopCustomerID
    )}/interested?${stringify({
      ...req.params,
    })}`
  }

  getRedPointsOrderpointsBasePath(params) {
    return `/redpoints/${this.apiVersion}/${
      this.tenantPath
    }/orderpoints?${stringify({
      ...params,
    })}`
  }

  getRegistrationStatusPath(shopCustomerId) {
    return `${this.getRedPointsCustomerBasePath(
      shopCustomerId
    )}/registrationstatus`
  }

  getRedPointsInterestedPath() {
    return `/redpoints/${this.apiVersionV3}/${this.tenantPath}/interested`
  }

  getRedPointsEventsListPathV2(params) {
    return `/redpoints/${this.apiVersionV2}/${
      this.tenantPath
    }/events?${stringify({
      ...params,
    })}`
  }

  async getRegistrationStatus(shopCustomerId) {
    assert(
      shopCustomerId,
      'RedPointsService.getRegistrationStatus: please provide a "shopCustomerId"'
    )
    const response = await this.get(
      this.getRegistrationStatusPath(shopCustomerId)
    )
    this.logger.debug(
      `RP get registration status for ${shopCustomerId}`,
      'dataFetching'
    )
    return response
  }

  async getCustomerData(shopCustomerId) {
    assert(
      shopCustomerId,
      'RedPointsService.getCustomerData: please provide a "shopCustomerId"'
    )
    const response = await this.get(
      this.getRedPointsCustomerBasePath(shopCustomerId)
    )
    this.logger.debug(
      `RP get customer data for ${shopCustomerId}`,
      'dataFetching'
    )
    return response
  }

  async getCustomerDetails() {
    const response = await this.get(this.getRedPointsCustomerBasePathV2())
    this.logger.debug(`RP get customer data`, 'dataFetching')
    return response
  }

  async createCustomer(shopCustomerId, payload) {
    assert(
      shopCustomerId,
      'RedPointsService.createCustomer: please provide a "createCustomer"'
    )
    assert(
      payload,
      'RedPointsService.createCustomer: please provide a "payload"'
    )
    const response = await this.put(
      this.getRedPointsCustomerBasePath(shopCustomerId),
      payload
    )
    this.logger.debug(
      `RP create or update customer ${shopCustomerId}`,
      'dataCreation'
    )
    return response
  }

  async createOrderpoints(
    {
      shopCartId,
      totalVoucherDiscountCents,
      totalRedeemedRedpointsDiscountCents,
    },
    payload
  ) {
    assert(
      shopCartId,
      'RedPointsService.createOrderpoints: please provide a "shopCartId"'
    )
    assert(
      totalVoucherDiscountCents,
      'RedPointsService.createOrderpoints: please provide a "totalVoucherDiscountCents"'
    )
    assert(
      payload,
      'RedPointsService.createOrderpoints: please provide a "payload"'
    )
    const response = await this.post(
      this.getRedPointsOrderpointsBasePath({
        shopCartId,
        totalVoucherDiscountCents,
        totalRedeemedRedpointsDiscountCents,
      }),
      payload
    )
    this.logger.debug(`RP create orderpoints ${shopCartId}`, 'dataCreation')
    return response
  }

  async checkOptIn(optInCode) {
    assert(
      optInCode,
      'RedPointsService.checkOptIn: please provide an "optInCode"'
    )
    const response = await this.get(this.checkOptInBasePath(optInCode))
    this.logger.debug(`RP check optin code ${optInCode}`, 'dataFetching')
    return response
  }

  async createOptInCode(req, payload) {
    assert(
      req.shopCustomerID,
      'RedPointsService.createOptInCode: please provide an "shopCustomerId"'
    )
    const response = await this.post(this.createOptInCodeBasePath(req), payload)
    this.logger.debug(
      `RP create optin code ${req.shopCustomerId}`,
      'dataCreation'
    )
    return response
  }

  async fetchOrder(shopCustomerId, shopOrderID) {
    assert(
      shopCustomerId,
      'RedPointsService.fetchOrder: please provide a "shopCustomerId"'
    )
    assert(
      shopOrderID,
      'RedPointsService.fetchOrder: please provide a "shopOrderID"'
    )
    const response = await this.get(
      this.getOrderPath(shopCustomerId, shopOrderID)
    )
    this.logger.debug(`RP fetch order ${shopOrderID}`, 'dataFetching')
    return response
  }

  async updateOrder(req, payload) {
    assert(
      req.shopCustomerID,
      'RedPointsService.updateOrders: please provide a "shopCustomerId"'
    )
    assert(
      req.shopOrderID,
      'RedPointsService.updateOrders: please provide a "shopOrderID"'
    )
    assert(payload, 'RedPointsService.updateOrders: please provide a "payload"')
    const response = await this.post(this.getUpdateOrdersPath(req), payload)
    this.logger.debug(`RP update order ${req.shopOrderID}`, 'dataCreation')
    return response
  }

  async reserveOrders(req, payload) {
    assert(
      req.shopCustomerID,
      'RedPointsService.reserveOrders: please provide a "shopCustomerId"'
    )
    assert(
      req.shopCartId,
      'RedPointsService.reserveOrders: please provide a "shopCartID"'
    )
    assert(
      payload,
      'RedPointsService.reserveOrders: please provide a "payload"'
    )
    const response = await this.put(this.getReserveOrdersPath(req), payload)
    this.logger.debug(`RP reserve order ${req.shopCartID}`, 'dataCreation')
    return response
  }

  async placeOrders(req, payload) {
    assert(
      req.shopCustomerID,
      'RedPointsService.placeOrders: please provide a "shopCustomerID"'
    )
    assert(
      req.shopOrderID,
      'RedPointsService.placeOrders: please provide a "shopOrderID"'
    )
    assert(payload, 'RedPointsService.placeOrders: please provide a "payload"')
    const response = await this.put(this.getPlaceOrdersPath(req), payload)
    this.logger.debug(`RP place order ${req.shopOrderID}`, 'dataCreation')
    return response
  }

  async fetchOrderList(req, payload) {
    assert(
      req.shopCustomerID,
      'RedPointsService.fetchOrderList: please provide a "shopCustomerId"'
    )
    assert(
      req.shopCartId,
      'RedPointsService.fetchOrderList: please provide a "shopCartID"'
    )
    assert(
      payload,
      'RedPointsService.fetchOrderList: please provide a "payload"'
    )
    const response = await this.post(
      this.getRedPointsOrdersBasePath(req.shopCustomerID),
      payload
    )
    this.logger.debug(`RP order list ${req.shopCartID}`, 'dataFetching')
    return response
  }

  async fetchEventList(req) {
    assert(
      req.shopCustomerID,
      'RedPointsService.fetchEventList: please provide a "shopCustomerId"'
    )
    const response = await this.get(this.getEventListPath(req))
    this.logger.debug(
      `RP fetch event list ${req.shopCustomerID}`,
      'dataFetching'
    )
    return response
  }

  async fetchEvent(shopCustomerId, eventId) {
    assert(
      shopCustomerId,
      'RedPointsService.fetchEvent: please provide a "shopCustomerId"'
    )
    assert(eventId, 'RedPointsService.fetchEvent: please provide a "eventId"')
    const response = await this.get(this.getEventPath(shopCustomerId, eventId))
    this.logger.debug(`RP fetch event ${eventId}`, 'dataFetching')
    return response
  }

  async fetchEventListV2(req) {
    const response = await this.get(this.getRedPointsEventsListPathV2(req))
    this.logger.debug('RP fetch events list V2')

    return response
  }

  async customerInterested() {
    const response = await this.post(this.getRedPointsInterestedPath(), {
      optInType: 'MANUAL',
    })

    return response
  }

  async activateCustomerByOptinCode(optinCode) {
    assert(
      optinCode,
      'RedPointsService.activateCustomerByOptinCode: please provide a "optinCode"'
    )

    const response = await this.post(
      this.postActivateCustomerByOptinCode(optinCode),
      undefined
    )
    this.logger.debug(`RP activateCustomerByToken ${optinCode}`, 'dataFetching')

    return response
  }
}

export default RedPointsService
