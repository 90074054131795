/**
 * Returns true when finds 'rebranding' className in root element
 */
export const isRebrandingClassNameApplied = (): boolean => {
  // to check rebranding, it checks for the rebranding className in the root layout component
  return (
    document
      ?.querySelector('[data-clientside-hook="root"]')
      ?.classList?.contains('rebranding') ?? false
  )
}
