import assert from 'shared/utils/assert'

import BackendAPIService from './BackendAPIService'

const API_VERSION = 'v1'

const config = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
}

class CheckoutService extends BackendAPIService {
  constructor(props) {
    super(props)

    this.apiVersion = API_VERSION
  }

  checkoutRoot = () => `/checkout/${this.apiVersion}/${this.tenantPath}`

  getRootUrl(orderDraftId) {
    if (orderDraftId) {
      return `${this.checkoutRoot()}/${orderDraftId}`
    }
    return `${this.checkoutRoot()}/`
  }

  getBillingAddressUrl(orderDraftId) {
    return `${this.getRootUrl(orderDraftId)}/billing-address`
  }

  getPhoneNumberUrl(orderDraftId) {
    return `${this.getRootUrl(orderDraftId)}/phoneNumber`
  }

  getShippingAddressUrl(orderDraftId) {
    return `${this.getRootUrl(orderDraftId)}/shippingAddress`
  }

  getShippingOptionUrl(orderDraftId) {
    return `${this.getRootUrl(orderDraftId)}/carrier`
  }

  getPaymentUrl(orderDraftId) {
    return `${this.getRootUrl(orderDraftId)}/payment`
  }

  getSubmitUrl(orderDraftId) {
    return `${this.getRootUrl(orderDraftId)}/order`
  }

  async createOrderDraft(prescriptionFollows = false) {
    const body = { prescriptionFollows }
    const response = await this.post(this.getRootUrl(), body, config)
    this.logger.debug('CheckoutService create OrderDraf!')
    return response
  }

  async fetchOrderDraft(orderDraftId) {
    assert(
      orderDraftId,
      'CheckoutService.createOrderDraft: please provide "orderDraftId"'
    )
    const response = await this.get(this.getRootUrl(orderDraftId), {}, config)
    // this.logger.debug('CheckoutService get OrderDraf!')
    return response
  }

  async setPhoneNumber({ orderDraftId, phoneNumber }) {
    assert(
      phoneNumber,
      'CheckoutService.setPhoneNumber: please provice "phoneNumber"'
    )
    const response = await this.put(
      this.getPhoneNumberUrl(orderDraftId),
      { phoneNumber },
      config
    )
    this.logger.debug(
      `CheckoutService setPhoneNumber(orderDraftId: ${orderDraftId})', phoneNumber(${phoneNumber})`
    )
    return response
  }

  async updateBillingAddress({ orderDraftId, subOrderId, billingAddressId }) {
    assert(
      orderDraftId,
      'CheckoutService.updateBillingAddress: please provide "orderDraftId"'
    )
    assert(
      subOrderId,
      'CheckoutService.updateBillingAddress: please provide "subOrderId"'
    )
    assert(
      billingAddressId,
      'CheckoutService.updateBillingAddress: please provide "billingAddressId"'
    )
    const response = await this.put(
      this.getBillingAddressUrl(orderDraftId),
      {
        subOrderId,
        billingAddressId,
      },
      config
    )
    this.logger.debug(
      `CheckoutService updateBillingAddress(orderDraftId: ${orderDraftId})', billingAddressId(${billingAddressId})`
    )
    return response
  }

  async updateShippingAddress({ orderDraftId, subOrderId, shippingAddressId }) {
    assert(
      orderDraftId,
      'CheckoutService.updateShippingAddress: please provide "orderDraftId"'
    )
    assert(
      subOrderId,
      'CheckoutService.updateShippingAddress: please provide "subOrderId"'
    )
    assert(
      shippingAddressId,
      'CheckoutService.updateShippingAddress: please provide "shippingAddressId"'
    )
    const response = await this.put(
      this.getShippingAddressUrl(orderDraftId),
      {
        subOrderId,
        deliveryAddressId: shippingAddressId,
      },
      config
    )
    this.logger.debug(
      `CheckoutService updateShippingAddress(orderDraftId: ${orderDraftId})', shippingAddressId(${shippingAddressId})`
    )
    return response
  }

  async updateShippingOption({
    orderDraftId,
    subOrderId,
    shippingOptionId,
    shippingCosts,
    displayName,
  }) {
    assert(
      orderDraftId,
      'CheckoutService.updateShippingOption: please provide "orderDraftId"'
    )
    assert(
      subOrderId,
      'CheckoutService.updateShippingOption: please provide "subOrderId"'
    )
    assert(
      shippingOptionId,
      'CheckoutService.updateShippingOption: please provide "shippingOptionId"'
    )
    // use shippingCosts?.toString() so costs of 0 can also be asserted
    assert(
      shippingCosts?.toString(),
      'CheckoutService.updateShippingOption: please provide "shippingCosts"'
    )
    const response = await this.put(
      this.getShippingOptionUrl(orderDraftId),
      {
        subOrderId,
        identifier: shippingOptionId,
        costs: shippingCosts,
        displayName,
      },
      config
    )
    this.logger.debug(
      `CheckoutService updateShippingOption(orderDraftId: ${orderDraftId})', shippingOptionId(${shippingOptionId})`
    )
    return response
  }

  async submitOrder({ isRedPointsRegistrationMarked, orderDraftId }) {
    assert(
      typeof isRedPointsRegistrationMarked === 'boolean',
      'CheckoutService.submitOrder: please provide "isRedPointsRegistrationMarked"'
    )
    assert(
      orderDraftId,
      'CheckoutService.submitOrder: please provide "orderDraftId"'
    )

    return this.post(
      this.getSubmitUrl(orderDraftId),
      { isRedPointsRegistrationMarked },
      config
    )
  }

  async updatePaymentMethod(orderDraftId, payment) {
    assert(
      orderDraftId,
      'CheckoutService.updatePaymentMethod: please provide "orderDraftId"'
    )
    assert(
      payment,
      'CheckoutService.updatePaymentMethod: please provide "payment"'
    )

    const response = await this.put(
      this.getPaymentUrl(orderDraftId),
      payment,
      config
    )

    this.logger.debug(
      `CheckoutService updatePaymentMethod(paymentMethodId: ${payment})'`
    )

    return response
  }
}

export default CheckoutService
