import { subscribe } from 'shared/store'
import { SCROLL_TO_TOP } from 'shared/store/ducks/events'
import throttle from 'shared/utils/throttle'
import toggleBoolStringAttr from 'views/utils/toggleBoolStringAttribute'

import { ARIA_HIDDEN } from './consts'

const OFFSET_TOP_PX = 150
const SCROLL_ANIMATION_FRAME_MS = 20
const SCROLL_THROTTLE_FRAME_MS = 500

export const getScrollElement = (): HTMLElement => {
  if (document.scrollingElement) {
    return <HTMLElement>document.scrollingElement
  }

  // Fallback for legacy browsers
  if (navigator.userAgent.indexOf('WebKit') !== -1) {
    return document.body
  }

  return document.documentElement
}

const getPositionEaseInQuad = (
  startTime: number,
  fromPosition: number,
  delta: number,
  duration: number
) => delta * (startTime /= duration) * startTime + fromPosition

export const scrollToTop = (
  element = getScrollElement(),
  toPosition = 0,
  durationMs = 400
) => {
  let scrollTimeMs = 0
  const fromPosition = element.scrollTop
  const delta = toPosition - fromPosition

  const smoothScroll = () => {
    scrollTimeMs += SCROLL_ANIMATION_FRAME_MS
    element.scrollTop = getPositionEaseInQuad(
      scrollTimeMs,
      fromPosition,
      delta,
      durationMs
    )
    if (scrollTimeMs < durationMs) {
      setTimeout(smoothScroll, SCROLL_ANIMATION_FRAME_MS)
    }
  }

  smoothScroll()
}

export const initScrollToTop = () => {
  const scrollButton = document.querySelector<HTMLElement>(
    '[data-clientside-hook~="ScrollToTop__button"]'
  )
  if (!scrollButton) {
    return
  }

  const toggleScrollButtonVisibility = (scrollPosition: number) =>
    toggleBoolStringAttr(
      scrollButton,
      ARIA_HIDDEN,
      scrollPosition < OFFSET_TOP_PX
    )

  const handlePageScroll = throttle(
    () => toggleScrollButtonVisibility(window.scrollY),
    SCROLL_THROTTLE_FRAME_MS
  )

  scrollButton.addEventListener('click', () => scrollToTop())

  window.addEventListener('scroll', handlePageScroll, { passive: true })
  toggleScrollButtonVisibility(window.scrollY)

  subscribe.after(SCROLL_TO_TOP, () => scrollToTop())
}
