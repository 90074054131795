import { Model } from 'shared/services/api/models/Model'
import { type OrderCarrierNowData } from 'shared/services/api/models/orders/OrderCarrierNowData'

import {
  type ExternalCarriersIdsV1,
  type OrderCarrierV1,
} from './ordersResponseTypes'

export class OrderCarrier extends Model {
  public readonly costs: number
  public readonly externalCarriersIds: ExternalCarriersIdsV1
  public readonly displayName: string
  public readonly identifier: string
  public readonly trackingId: string
  public readonly trackingUrl: string
  public readonly nowData?: OrderCarrierNowData

  constructor(src: OrderCarrierV1) {
    super(src)
    this.costs = src.costs
    this.externalCarriersIds = src.externalCarrierIds
    this.displayName = src.displayName
    this.identifier = src.identifier
    this.trackingId = src.trackingId
    this.trackingUrl = src.trackingUrl
      ? src.trackingUrl.replace(/\{trackingID\}/, this.trackingId)
      : src.trackingUrl
    this.nowData = src.nowData
  }
}
