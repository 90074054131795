import { Model } from 'shared/services/api/models/Model'

export interface ThreadAttachmentV1 {
  id: string
  name: string
  size: number
}

export class ThreadAttachment extends Model {
  public readonly id: string
  public readonly name: string
  public readonly size: number

  constructor(src: ThreadAttachmentV1) {
    // @ts-ignore
    super(src)
    this.id = src.id
    this.name = src.name
    this.size = src.size
  }
}
