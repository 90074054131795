import { type AxiosResponse } from 'axios'

import { NO_CONTENT } from 'shared/consts/codes'
import { type ServiceParameters } from 'shared/services/api/commonTypes'
import { type GetPreferredLanguageResponse } from 'shared/services/api/models/customerData/getPreferredLanguageResponse'

import BackendAPIService from './BackendAPIService'

interface ICustomerDataService {
  getDateOfBirthURL: () => string
  getEmailURL: () => string
  changeDateOfBirth: (dateOfBirth: string) => Promise<boolean>
  changeEmail: (email: string, password: string) => Promise<boolean>

  getPreferredLanguage: (token: string) => Promise<string | undefined>
}

class CustomerDataService
  extends BackendAPIService
  implements ICustomerDataService
{
  constructor(params: ServiceParameters) {
    super({ apiVersion: 'v1', ...params })
  }

  public getDateOfBirthURL(): string {
    return `/customer/${this.apiVersion}/${this.tenantPath}/dateOfBirth`
  }

  public getEmailURL(): string {
    return `/customer/${this.apiVersion}/${this.tenantPath}/changeEmail`
  }

  public getEmailConfirmationURL(token: string): string {
    return `/customer/${this.apiVersion}/${this.tenantPath}/email/${token}`
  }

  public getPreferredLanguageURL(): string {
    return `/customer/${this.apiVersion}/${this.tenantPath}/preferredLanguage`
  }

  async changeDateOfBirth(dateOfBirth: string): Promise<boolean> {
    const { status } = await this.patch(this.getDateOfBirthURL(), {
      dateOfBirth,
    })
    return status === NO_CONTENT
  }

  async changeEmail(email: string, password: string): Promise<boolean> {
    const { status } = await this.post(this.getEmailURL(), { email, password })
    return status === NO_CONTENT
  }

  async changeEmailConfirmation(token: string): Promise<boolean> {
    const emailConfirmationURL = this.getEmailConfirmationURL(
      encodeURIComponent(token)
    )
    const { status } = await this.patch(emailConfirmationURL, {})
    return status === NO_CONTENT
  }

  async changePreferredLanguage(language: string): Promise<boolean> {
    const preferredLanguageURL = this.getPreferredLanguageURL()

    const { status } = await this.patch(preferredLanguageURL, {
      preferredLanguage: language,
    })
    return status === NO_CONTENT
  }

  async getPreferredLanguage(token: string): Promise<string | undefined> {
    let preferredLanguage

    try {
      const response: AxiosResponse<GetPreferredLanguageResponse> =
        await this.get(this.getPreferredLanguageURL(), {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        })
      preferredLanguage = response.data.preferredLanguage
    } catch (e) {
      this.logger.error(
        'Error in fetching preferred language for the customer',
        e
      )
    }

    return preferredLanguage
  }
}

export default CustomerDataService
