/**
 * What I have found is that Safari actually pauses all animations as soon as the pagehide event is fired,
 * whenever the browser begins loading a new page.
 * After pagehide, it won't even allow CSS changes such as showing a spinner that was previously hidden.
 */

import { defaultLoadingIndicatorOpts } from './consts'
import { loadingIndicator } from './loadingIndicator'

const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]'
  })(
    !window['safari'] ||
      (typeof safari !== 'undefined' && window['safari'].pushNotification)
  )

const isIOS = () => {
  const ua = window.navigator.userAgent
  const iOS = ua.match(/iP(ad|od|hone)/i)
  const webkit = ua.match(/WebKit/i)
  return iOS && webkit
}
export const handleFormButtonAnimationPollyfills = ({
  form,
  button,
  svgSpinnerOpts,
  loadingOverlay,
  preserveWidth,
  event,
}) => {
  if ((isSafari || isIOS()) && form) {
    event.preventDefault()
    button.innerHTML = button.dataset.loadingLabel
    return setTimeout(() => form.submit(), 100)
  } else {
    const spinner = document.createElement('div')
    const size = svgSpinnerOpts.size || defaultLoadingIndicatorOpts.size

    spinner.style.height = size

    spinner.innerHTML = loadingIndicator({
      ...defaultLoadingIndicatorOpts,
      ...svgSpinnerOpts,
      preserveWidth: preserveWidth
        ? button.childNodes[0].offsetWidth + 'px'
        : size,
      size,
    })

    // Note: this must be executed AFTER the width from the button was used!
    button.removeChild(button.childNodes[0])

    button.append(spinner)
    loadingOverlay.style.width = '100%'
  }
}
