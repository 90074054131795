import type {
  NotepadV2Entry,
  NotepadV2ProductEntryList,
} from '@redteclab/api/clients/bully'
import { type Context } from 'koa'
import { stringify } from 'qs'

import {
  DEFAULT_ENTRIES_PAGE,
  VISIBLE_ENTRIES_COUNT,
} from 'shared/consts/marketplace'
import type { ServiceParameters } from 'shared/services/api/commonTypes'
import {
  NotepadEntriesResponse,
  NotepadEntry,
} from 'shared/services/api/models'
import assert from 'shared/utils/assert'
import { isEmpty } from 'shared/utils/objectUtils'

import BackendAPIService from './BackendAPIService'

interface GetAllEntriesParams {
  limit?: number
  page?: number
}

export interface INodepadService {
  getAllEntries(
    ctx: Context,
    params: GetAllEntriesParams
  ): Promise<NotepadEntriesResponse>
  getEntry(ctx: Context, { upid }: { upid: string }): Promise<NotepadEntry>
  putEntry(ctx: Context, { upid }: { upid: string }): Promise<NotepadEntry>
  deleteEntry(ctx: Context, { upid }: { upid: string }): Promise<void>
}

class NotepadService extends BackendAPIService implements INodepadService {
  private readonly basePath: string
  constructor(params: ServiceParameters) {
    super({ apiVersion: 'v2', ...params })
    this.basePath = `/${this.language}/notepad/${this.apiVersion}/${this.tenantPath}`
  }

  /**
   * @method getEntriesURL fetch notepad entries list endpoint URL.
   * @param {object} params - request parameters.
   * @param {string} [params.page] - Next or previous page.
   * @constant {string} params.limit - entries limit.
   */
  getEntriesURL(params?: GetAllEntriesParams): string {
    return `${this.basePath}${isEmpty(params) ? '' : `?${stringify(params)}`}`
  }

  /**
   * @function Returns thread reasons URL.
   * @method getEntryURL - returns entry by id endpoint URL.
   * @param {object} params - URL params
   * @param {string} params.upid - entry to fetch
   */
  getEntryResourceURL({ upid }: { upid: string }): string {
    return `${this.basePath}/${upid}`
  }

  async getAllEntries(
    ctx: Context,
    {
      page = DEFAULT_ENTRIES_PAGE,
      limit = VISIBLE_ENTRIES_COUNT,
    }: GetAllEntriesParams
  ): Promise<NotepadEntriesResponse> {
    this.logger.debug(`Notepad service: getAllEntries!`, 'dataFetching')
    const { data } = await this.get<NotepadV2ProductEntryList>(
      this.getEntriesURL({
        page,
        limit,
      }),
      null,
      ctx
    )

    return new NotepadEntriesResponse(data)
  }

  async getEntry(
    ctx: Context,
    { upid }: { upid: string }
  ): Promise<NotepadEntry> {
    assert(upid, 'NotepadService.getEntry: please provide "upid"')
    this.logger.debug(`NotepadService: fetching entry!`, 'dataFetching')
    const { data } = await this.get<NotepadV2Entry>(
      this.getEntryResourceURL({ upid }),
      null,
      ctx
    )

    return new NotepadEntry(data)
  }

  async putEntry(
    ctx: Context,
    { upid }: { upid: string }
  ): Promise<NotepadEntry> {
    assert(upid, 'NotepadService.putEntry: please provide "upid"')
    this.logger.debug(`NotepadService: adding entry!`, 'dataFetching')
    const { data }: { data: NotepadV2Entry } = await this.put(
      this.getEntryResourceURL({ upid }),
      { upid },
      null,
      ctx
    )

    return new NotepadEntry(data)
  }

  async deleteEntry(ctx: Context, { upid }: { upid: string }): Promise<void> {
    assert(upid, 'NotepadService.deleteEntry: please provide "upid"')
    this.logger.debug(`NotepadService: delete entry!`, 'deleteResource')
    await this.delete(this.getEntryResourceURL({ upid }), {}, ctx)
  }
}

export default NotepadService
