import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const PATH =
  'M50 6.86A43.14 43.14 0 1 0 93.141 50 43.19 43.19 0 0 0 50 6.86zm0 81.355A38.215 38.215 0 1 1 88.217 50 38.261 38.261 0 0 1 50 88.215zM73.522 39.05H60.949V26.479a2.46 2.46 0 0 0-2.462-2.462H41.513a2.46 2.46 0 0 0-2.462 2.462V39.05H26.478a2.461 2.461 0 0 0-2.463 2.462v16.976a2.461 2.461 0 0 0 2.463 2.462h12.573v12.571a2.46 2.46 0 0 0 2.462 2.462h16.974a2.46 2.46 0 0 0 2.462-2.462V60.95h12.573a2.461 2.461 0 0 0 2.463-2.462V41.512a2.461 2.461 0 0 0-2.463-2.462zm-17.5 32.009H43.975V58.488a2.46 2.46 0 0 0-2.462-2.462H28.94V43.974h12.573a2.46 2.46 0 0 0 2.462-2.462V28.941h12.05v12.571a2.46 2.46 0 0 0 2.462 2.462H71.06v12.052H58.487a2.46 2.46 0 0 0-2.462 2.462z'

const IconCircleHealth: FC<IconProps> = props => (
  <SvgIcon {...props} path={PATH} viewBox="0 0 100 100" />
)

export default IconCircleHealth
