import {
  ECONDA_SESSION_ID_COOKIE,
  ECONDA_VISITOR_ID_COOKIE,
} from 'shared/consts/econdaCookieIds'
import { type TrackingEventName } from 'shared/experiments/consts'
import { getCookieByName } from 'shared/utils/getCookie'
import noop from 'shared/utils/noop'
import buildClientAPI from 'views/providers/clientAPI'

// For use in case of hydrated react components, client
// scripts or when you know what needs to be triggered but can't implement
// using Track component for some reason (ie event that is different then click).
const trackOptimizelyEvent = (eventName: TrackingEventName, eventTags = {}) => {
  const { clientFockService } = buildClientAPI()

  const { cookie = '' } = document

  const econdaSessionId = getCookieByName(
    ECONDA_SESSION_ID_COOKIE,
    cookie
  )?.split(':')[0]

  const econdaVisitorId = getCookieByName(
    ECONDA_VISITOR_ID_COOKIE,
    cookie
  )?.split(':')[0]

  const eventTagsWithEcondaIds = {
    ...eventTags,
    econdaSessionId,
    econdaVisitorId,
  }

  clientFockService
    .trackExperiment(eventName, eventTagsWithEcondaIds)
    .catch(noop)
}

export default trackOptimizelyEvent
