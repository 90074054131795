import { type AxiosResponse } from 'axios'

import { type ServiceParameters } from 'shared/services/api/commonTypes'
import {
  type AddressSuggestionRequest,
  type AddressSuggestionResponse,
} from 'shared/services/api/models'
import assert from 'shared/utils/assert'

import BackendAPIService from './BackendAPIService'

const API_VERSION = 'v1'

interface IAddressSuggestServiceBE {
  fetchAddresses: (
    payload: AddressSuggestionRequest
  ) => Promise<AxiosResponse<AddressSuggestionResponse>>
}

class AddressSuggestServiceBE
  extends BackendAPIService
  implements IAddressSuggestServiceBE
{
  public suggestionPath: string
  constructor(props: ServiceParameters) {
    super(props)

    this.apiVersion = API_VERSION
    this.suggestionPath = `/address/${this.apiVersion}/${this.tenantPath}/suggestion`
  }

  public async fetchAddresses({
    zip,
    city,
    street,
  }: AddressSuggestionRequest): Promise<AxiosResponse> {
    const payload: AddressSuggestionRequest = {
      zip,
      city,
      street,
      countryCode: 'be',
      language: this.language,
    }
    assert(
      zip?.length && city?.length && street?.length,
      'AddressSuggestServiceBE.fetchAddresses: please provide zip, city and street'
    )
    const response: AxiosResponse<AddressSuggestionResponse> = await this.post(
      this.suggestionPath,
      payload
    )
    this.logger.debug(
      `Get address suggestion for ${zip}, ${city}, ${street}`,
      'dataFetching'
    )
    return response
  }
}

export default AddressSuggestServiceBE
