import { subscribe } from 'shared/store'
import debounce from 'views/utils/debounce'

/**
 * Client script stretches heights of elements
 * (to the highest height in the collection).
 *
 * Stretcher is decoupled from the concrete components and activates
 * when a different actions dispatched through Redux.
 *
 * Stretcher holds elements and heights as arrays of:
 * [containerId][groupName] to make it available for multiple elements
 * on the page within the same container
 *
 * <containerId> — comes from elements dataset as `containerIdDataAttr`
 * describes (in constructor)
 * <groupName> — comes from elements dataset, should be defined within
 * `data-stretch-group` attr
 *
 * Example:
 * <h3
 *    data-clientside-hook="stretchVertically"
 *    data-stretch-group="name"
 * > ...
 *
 * @see app/views/assets/scripts/registerSliderStretcher.js
 */

export default class SliderStretcher {
  static HEIGHT_CONTAINER_SELECTOR =
    '[data-clientside-hook~="stretchVertically"]'
  static TICK = 100 // ms

  /**
   * Get a group name from elements data-attr
   */
  static extractGroup(element) {
    const group = element.dataset.stretchGroup

    if (!group) {
      throw new Error('No group defined in [data-stretch-group] attribute')
    }

    return group
  }

  /**
   * @param itemSelector Selector for the visible part
   *        of elements being processed
   * @param initEvent Stretcher will be inited on this event
   * @param containerIdDataAttr data-attr which contains ID of container
   */
  constructor({ itemSelector, initEvent, containerIdDataAttr }) {
    this.itemSelector = itemSelector
    this.containerIdDataAttr = containerIdDataAttr

    this.groups = {}
    this.maxHeights = {}
    this.subscribes = []
    this.resizeHandlers = []

    this.init = this.init.bind(this)
    this.doWork = this.doWork.bind(this)

    this.subscribes.push(
      subscribe.after(initEvent, ({ containerId }) => this.init(containerId))
    )
  }

  /**
   * Start worker, register recalculation of heights on resize
   */
  init(containerId) {
    const resizer = this.createResizeListener(containerId)
    this.resizeHandlers.push(resizer)

    window.addEventListener('resize', debounce(resizer))
    this.doWork(containerId)
  }

  /**
   * Return curried worker to make it possible to clear an event listening later
   */
  createResizeListener(containerId) {
    let tid

    return () => {
      // Throttle callback so it fires only when resizing _ends_,
      // stops possible leaks
      clearTimeout(tid)
      tid = setTimeout(() => this.doWork(containerId), SliderStretcher.TICK)
    }
  }

  /**
   * Clear subscription to release resources
   */
  clear() {
    this.subscribes.forEach(unsubscribe => unsubscribe())
    this.resizeHandlers.forEach(handler =>
      window.removeEventListener('resize', handler)
    )
  }

  /**
   * Get elements, prepare and apply heights
   */
  doWork(containerId) {
    this.processGroups(containerId)
    this.updateHeights(containerId)
  }

  /**
   * Collect max heights for the defined groups
   */
  processGroups(containerId) {
    const container = document.querySelector(
      `[${this.containerIdDataAttr}="${containerId}"]`
    )
    if (!container) {
      // eslint-disable-next-line no-console
      console.error('No container found')

      return
    }

    this.groups[containerId] = []
    this.maxHeights[containerId] = []
    const items = container.querySelectorAll(this.itemSelector)

    // just for shortening
    const groups = this.groups[containerId]
    const maxHeights = this.maxHeights[containerId]

    // Get all [visible] items inside the container
    Array.prototype.forEach.call(items, outerElement => {
      // Get all the stretchable elements inside the item
      const elements = outerElement.querySelectorAll(
        SliderStretcher.HEIGHT_CONTAINER_SELECTOR
      )
      if (!elements) {
        return
      }

      Array.prototype.forEach.call(elements, element => {
        const group = SliderStretcher.extractGroup(element)

        // Hold both inc/dec of viewport
        element.style.minHeight = 'auto'

        if (!groups[group]) {
          groups[group] = []
        }
        if (!maxHeights[group]) {
          maxHeights[group] = 0
        }

        groups[group].push(element)
        if (element.clientHeight > maxHeights[group]) {
          maxHeights[group] = element.clientHeight
        }
      })
    })
  }

  /**
   * Stretch height for an every element in the group
   */
  updateHeights(containerId) {
    if (!this.groups[containerId]) {
      return
    }

    Object.keys(this.groups[containerId]).forEach(group => {
      this.groups[containerId][group].forEach(element => {
        if (this.maxHeights[containerId][group]) {
          element.style.minHeight = `${this.maxHeights[containerId][group]}px`
        }
      })
    })
  }
}
