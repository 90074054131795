import type { Reducer } from 'redux'

const ADD_SHOP_CONFIG = 'ADD_SHOP_CONFIG'

// TODO Add typing for CONFIG
type AddShopConfig = {
  type: typeof ADD_SHOP_CONFIG
  payload: Record<string, unknown>
}

export const addShopConfig = (
  payload: Record<string, unknown>
): AddShopConfig => {
  return {
    type: ADD_SHOP_CONFIG,
    payload,
  }
}

type ShopActions = AddShopConfig

// @ts-ignore
const reducer: Reducer<Record<string, unknown>> = (
  state: Record<string, unknown> = {},
  action: ShopActions
): Record<string, unknown> => {
  switch (action.type) {
    case ADD_SHOP_CONFIG:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default reducer
