import PillAnimation from 'views/assets/images/animated/animate-pill.svg'
import AnimationRebranding from 'views/assets/images/animated/redcare-animation.gif'
import { isRebrandingClassNameApplied } from 'views/assets/scripts/rebranding'
import cssVars from 'views/utils/cssVars'

export const ANIMATION_DURATION_MS = cssVars.timings['animation-duration-ms']
export const SELECTOR_UI_LOADER = 'blockui'
export const SELECTOR_UI_LOADER_FULLSCREEN = 'blockui--fullscreen'
export const SELECTOR_UI_LOADER_ANIMATION = 'blockui--animation'
export const SELECTOR_UI_LOADER_FADEOUT = 'blockui--fadeout'

const blockUI = (config = {}) => {
  const classNames = [SELECTOR_UI_LOADER]
  if (!config.target) {
    classNames.push(SELECTOR_UI_LOADER_FULLSCREEN)
  }

  const { target = document.body, onShow, onHide } = config
  const overlay = document.createElement('div')
  overlay.setAttribute('class', classNames.join(' '))

  const animation = document.createElement('img')
  animation.src = PillAnimation

  if (isRebrandingClassNameApplied()) {
    animation.src = AnimationRebranding
  }

  animation.classList.add(SELECTOR_UI_LOADER_ANIMATION)

  overlay.appendChild(animation)

  target.appendChild(overlay)

  if (typeof onShow === 'function') {
    onShow()
  }

  return () => {
    const hide = () => {
      target.removeChild(overlay)
      if (typeof onHide === 'function') {
        onHide()
      }
    }

    animation.classList.add(SELECTOR_UI_LOADER_FADEOUT)
    setTimeout(hide, ANIMATION_DURATION_MS)
    return {
      target,
    }
  }
}

export default blockUI
