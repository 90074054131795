import AddressService from 'shared/services/api/AddressService'
import AddressSuggestService from 'shared/services/api/AddressSuggestService'
import AddressSuggestServiceBE from 'shared/services/api/AddressSuggestServiceBE'
import AuthService from 'shared/services/api/AuthService'
import CancellationService from 'shared/services/api/CancellationService'
import CartService from 'shared/services/api/CartService'
import CartServiceV3 from 'shared/services/api/CartServiceV3'
import CheckoutService from 'shared/services/api/CheckoutService'
import ClientAPIService from 'shared/services/api/ClientAPIService'
import ClientFockService from 'shared/services/api/ClientFockService'
import ContactService from 'shared/services/api/ContactService'
import ContentService from 'shared/services/api/ContentService'
import CustomerDataService from 'shared/services/api/CustomerDataService'
import MailingSubscriptionService from 'shared/services/api/MailingSubscriptionService'
import MedicationPlanService from 'shared/services/api/MedicationPlanService'
import MiraklService from 'shared/services/api/MiraklService'
import MiraklServiceV2 from 'shared/services/api/MiraklServiceV2'
import NotepadService from 'shared/services/api/NotepadService'
import NowService from 'shared/services/api/NowService'
import OdsService from 'shared/services/api/OdsService'
import OrdersService from 'shared/services/api/OrdersService'
import ParcellabSecurityHashService from 'shared/services/api/ParcellabSecurityHashService'
import PaymentService from 'shared/services/api/PaymentService'
import PimProductService from 'shared/services/api/PimProductService'
import ProductReviewService from 'shared/services/api/ProductReviewService'
import ReavailabilityService from 'shared/services/api/ReavailabilityService'
import RedPointsService from 'shared/services/api/RedPointsService'
import ReturnLabelService from 'shared/services/api/ReturnLabelService'
import SellerService from 'shared/services/api/SellerService'
import SepaDataService from 'shared/services/api/SepaDataService'
import ShippingService from 'shared/services/api/ShippingService'
import ShippingServiceV2 from 'shared/services/api/ShippingServiceV2'
import VatValidationService from 'shared/services/api/VatValidationService'
import logger, { Logger } from 'shared/services/logger'
import store from 'shared/store'
import MountPointsClass from 'shared/utils/mountPoints'
import type { FeaturesToggles } from 'types/shopConfig'

// Services

export type ClientServices = {
  authService: AuthService
  clientAPIService: ClientAPIService
  clientFockService: ClientFockService
  addressService: AddressService
  sellerService: SellerService
  shippingService: ShippingService
  shippingServiceV2: ShippingServiceV2
  cartService: CartService
  cartServiceV3: CartServiceV3
  checkoutService: CheckoutService
  addressSuggestService: AddressSuggestService
  paymentService: PaymentService
  sepaDataService: SepaDataService
  ordersService: OrdersService
  contentService: ContentService
  redPointsService: RedPointsService
  pimProductService: PimProductService
  productReviewService: ProductReviewService
  miraklService: MiraklService
  miraklServiceV2: MiraklServiceV2
  notepadService: NotepadService
  medicationPlanService: MedicationPlanService
  returnLabelService: ReturnLabelService
  cancellationService: CancellationService
  mailingSubscriptionService: MailingSubscriptionService
  odsService: OdsService
  nowService: NowService
  customerDataService: CustomerDataService
  contactService: ContactService
  reavailabilityService: ReavailabilityService
  vatValidationService: VatValidationService
  parcellabSecurityHashService: ParcellabSecurityHashService
  addressSuggestServiceBE: AddressSuggestServiceBE
}

type ServiceParams = {
  tenant: string
  locale: string
  logger: Logger
  language: string
  coreShopEnvironment: string
  contentTypeJSON: boolean
}

let clientAPI: ClientServices

const buildClientAPI = (): ClientServices => {
  if (clientAPI) {
    return clientAPI
  }

  const {
    pageProperties: { coreShopEnvironment, featureToggles, experiments },
    publicConfig: {
      api: { baseURL },
      tenant,
      language,
      searchConfig,
      locale,
      mountPoints,
    },
  } = store.getPublicRuntimeConfig()

  const serviceParamsCommon: ServiceParams = {
    tenant,
    coreShopEnvironment: <string>coreShopEnvironment,
    locale,
    contentTypeJSON: true,
    language,
    logger,
  }

  const mountPointsInstance = new MountPointsClass({
    coreShopEnvironment: <string>coreShopEnvironment,
    featureToggles: <FeaturesToggles>featureToggles,
    language,
    mountPoints,
    tenant,
    experiments,
  })

  const webClientBaseUrl = '/webclient/'
  const apiBaseUrl = `${webClientBaseUrl}api`

  clientAPI = {
    authService: new AuthService({
      ...serviceParamsCommon,
      baseURL,
    }),
    clientAPIService: new ClientAPIService({
      ...serviceParamsCommon,
      baseURL,
      searchConfig,
    }),
    clientFockService: new ClientFockService({
      ...serviceParamsCommon,
      mountPoints: mountPointsInstance,
      baseURL: webClientBaseUrl,
    }),
    addressService: new AddressService({
      ...serviceParamsCommon,
      baseURL,
    }),
    medicationPlanService: new MedicationPlanService({
      ...serviceParamsCommon,
      baseURL,
    }),
    returnLabelService: new ReturnLabelService({
      ...serviceParamsCommon,
      baseURL,
    }),
    cancellationService: new CancellationService({
      ...serviceParamsCommon,
      baseURL,
    }),
    sellerService: new SellerService({
      ...serviceParamsCommon,
      baseURL,
    }),
    shippingService: new ShippingService({
      ...serviceParamsCommon,
      baseURL,
    }),
    shippingServiceV2: new ShippingServiceV2({
      ...serviceParamsCommon,
      baseURL,
    }),
    cartService: new CartService({
      ...serviceParamsCommon,
      baseURL: apiBaseUrl,
      experiments,
    }),
    cartServiceV3: new CartServiceV3({
      ...serviceParamsCommon,
      baseURL: apiBaseUrl,
      experiments,
    }),
    contentService: new ContentService({
      ...serviceParamsCommon,
      baseURL: apiBaseUrl,
    }),
    checkoutService: new CheckoutService({
      ...serviceParamsCommon,
      baseURL,
      experiments,
    }),
    addressSuggestService: new AddressSuggestService({
      ...serviceParamsCommon,
    }),
    addressSuggestServiceBE: new AddressSuggestServiceBE({
      ...serviceParamsCommon,
      baseURL: apiBaseUrl,
    }),
    paymentService: new PaymentService({
      ...serviceParamsCommon,
      baseURL: apiBaseUrl,
    }),
    sepaDataService: new SepaDataService({
      ...serviceParamsCommon,
      baseURL: apiBaseUrl,
    }),
    ordersService: new OrdersService({
      ...serviceParamsCommon,
      baseURL: apiBaseUrl,
    }),
    redPointsService: new RedPointsService({
      ...serviceParamsCommon,
      baseURL,
    }),
    pimProductService: new PimProductService({
      ...serviceParamsCommon,
      baseURL,
    }),
    productReviewService: new ProductReviewService({
      ...serviceParamsCommon,
      baseURL,
    }),
    miraklService: new MiraklService({
      ...serviceParamsCommon,
      baseURL: apiBaseUrl,
    }),
    miraklServiceV2: new MiraklServiceV2({
      ...serviceParamsCommon,
      baseURL: apiBaseUrl,
    }),
    notepadService: new NotepadService({
      ...serviceParamsCommon,
      baseURL: apiBaseUrl,
    }),
    mailingSubscriptionService: new MailingSubscriptionService({
      ...serviceParamsCommon,
      baseURL: apiBaseUrl,
    }),
    odsService: new OdsService({
      ...serviceParamsCommon,
      baseURL: apiBaseUrl,
    }),
    nowService: new NowService({
      ...serviceParamsCommon,
      baseURL: apiBaseUrl,
    }),
    customerDataService: new CustomerDataService({
      ...serviceParamsCommon,
      baseURL: apiBaseUrl,
    }),
    contactService: new ContactService({
      ...serviceParamsCommon,
      baseURL: apiBaseUrl,
    }),
    reavailabilityService: new ReavailabilityService({
      ...serviceParamsCommon,
      baseURL: apiBaseUrl,
    }),
    vatValidationService: new VatValidationService({
      ...serviceParamsCommon,
      baseURL: apiBaseUrl,
    }),
    parcellabSecurityHashService: new ParcellabSecurityHashService({
      ...serviceParamsCommon,
      baseURL: apiBaseUrl,
    }),
  }
  return clientAPI
}

export default buildClientAPI
