import { TENANT_AT, TENANT_COM } from 'shared/consts/tenants'

import { RELEASE_SWITCH_TOGGLE, RELEASE_SWITCH_TOGGLE_AT } from './names'
import type { ExperimentName } from './types'

export const RELEASE_SWITCH_TOGGLES: Record<string, ExperimentName> = {
  [TENANT_COM]: RELEASE_SWITCH_TOGGLE,
  [TENANT_AT]: RELEASE_SWITCH_TOGGLE_AT,
  default: RELEASE_SWITCH_TOGGLE,
}

/**
 * List of experiments that are manually activated.
 *
 * Add the experiment name here if the experiment is something like AA test or
 * there is no `<Experiment name=... />` part
 */
export const MANUALLY_ACTIVATED: ExperimentName[] = [
  RELEASE_SWITCH_TOGGLE,
  RELEASE_SWITCH_TOGGLE_AT,
]
