import {
  BAD_REQUEST,
  CREATED,
  FORBIDDEN,
  GATEWAY_TIMEOUT,
  INTERNAL_SERVER_ERROR,
  NO_CONTENT,
  NOT_FOUND,
  OK,
  SERVICE_UNAVAILABLE,
  UNAUTHORIZED,
} from 'shared/consts/codes'
import {
  ERROR_AUTH_SERVICE,
  ERROR_BADINPUT,
  ERROR_INVALID_TOKEN,
  ERROR_UNAVAILABLE,
  GENERAL_ERROR,
} from 'shared/consts/errorCodes'
import { REGISTRATION_ORIGIN } from 'shared/consts/mailingSubscription'
import APIError from 'shared/errors/APIError'
import assert from 'shared/utils/assert'
import { parseBoolStringAttribute } from 'shared/utils/boolStringAttribute'
import { toISODateString } from 'shared/utils/dateUtils'
import { isCustomerTypeProfessional } from 'shared/utils/isCustomerTypeProfessional'

import BackendAPIService from './BackendAPIService'

const API_VERSION = 'v2'
const API_VERSION_V3 = 'v3'

const config = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
}

export default class AuthService extends BackendAPIService {
  constructor(props) {
    super(props)
    this.apiVersion = API_VERSION
  }

  /** This endpoint is part of [v1] service */
  getLoginGuestURL() {
    return `/cacheable/auth/v1/${this.tenantPath}/login/guest`
  }

  /** This endpoint is part of [v1] service */
  getRefreshURL() {
    return `/cacheable/auth/v1/${this.tenantPath}/refresh`
  }

  getLoginURL() {
    return `/cacheable/auth/${API_VERSION_V3}/${this.tenantPath}/login`
  }

  getOneTimePasswordRequestURL() {
    return `/cacheable/auth/${API_VERSION_V3}/${this.tenantPath}/login/otp/initiate`
  }

  getOneTimePasswordConfirmationURL() {
    return `/cacheable/auth/${API_VERSION_V3}/${this.tenantPath}/login/otp/confirm`
  }

  getRegisterURL() {
    return `/cacheable/auth/${this.apiVersion}/${this.tenantPath}/register`
  }

  getLogoutURL() {
    return `/cacheable/auth/${this.apiVersion}/${this.tenantPath}/logout`
  }

  getValidateResetPasswordURL() {
    return `/auth/${this.apiVersion}/${this.tenantPath}/reset/verify`
  }

  getResetPasswordURL() {
    return `/cacheable/auth/${this.apiVersion}/${this.tenantPath}/reset`
  }

  getForgotPasswordURL() {
    return `/cacheable/auth/${API_VERSION_V3}/${this.tenantPath}/reset`
  }

  getChangePasswordURL() {
    return `/cacheable/auth/v1/${this.tenantPath}/password`
  }

  async fetchRefreshedToken() {
    this.logger.debug('Refreshing user token', 'dataFetching')

    const path = this.getRefreshURL()

    try {
      const response = await this.get(path)
      this.logger.debug('User token refreshed', 'dataFetching')

      if (response.status === CREATED) {
        return response.data
      }

      /** @type {Error & { response?: import('axios').AxiosResponse }} */
      const error = new Error()
      error.response = response
      throw error
    } catch (e) {
      const status = e?.response?.status
      if (status === GATEWAY_TIMEOUT) {
        throw new APIError(
          'Backend unavailable while refreshing token',
          ERROR_UNAVAILABLE
        )
      }

      if (status === BAD_REQUEST) {
        throw new APIError('Invalid or expired token', ERROR_BADINPUT)
      }

      if (status === UNAUTHORIZED) {
        throw new APIError('Unauthorized', ERROR_BADINPUT)
      }

      if (status === FORBIDDEN) {
        throw new APIError('Access denied', ERROR_BADINPUT)
      }

      if (status) {
        throw new APIError(`Unsupported status: ${status}`)
      }

      throw new APIError(`Network error: ${e.message}`)
    }
  }

  async fetchGuestToken() {
    this.logger.debug('Fetching guest token', 'dataFetching')

    const path = this.getLoginGuestURL()

    try {
      const response = await this.get(path)
      this.logger.debug('Guest token fetched', 'dataFetching')

      if (response.status === CREATED) {
        return response.data
      }

      /** @type {Error & { response?: import('axios').AxiosResponse }} */
      const error = new Error()
      error.response = response
      throw error
    } catch (e) {
      const status = e?.response?.status
      if (status === GATEWAY_TIMEOUT) {
        throw new APIError(
          'Backend unavailable while fetching guest token',
          ERROR_UNAVAILABLE
        )
      }

      if (status) {
        throw new APIError(`Unsupported status: ${status}`)
      }

      throw new APIError(`Network error: ${e.message}`)
    }
  }

  async fetchUserToken(username, password, fcSolution, xForwardedIp) {
    assert(username, 'username missing', ERROR_BADINPUT)
    assert(password, 'password missing', ERROR_BADINPUT)
    this.logger.debug(`fetching user token ${username}`, 'dataFetching')
    const requestConfig = {
      headers: { ...config.headers, 'x-forwarded-for': xForwardedIp },
    }

    const path = this.getLoginURL()

    const payload = {
      username,
      password,
      fcSolution,
    }
    try {
      const { status, data } = await this.post(path, payload, requestConfig)
      this.logger.debug(`Token for ${username} fetched!`, 'dataFetching')
      if (status === CREATED) {
        return data
      }
    } catch (e) {
      if (e.response) {
        const { status } = e.response
        if (status === BAD_REQUEST) {
          throw new APIError(
            'bad input for fetching user token',
            ERROR_BADINPUT
          )
        }

        if (status === NOT_FOUND) {
          throw new APIError('user not found', ERROR_BADINPUT)
        }
        if (status === GATEWAY_TIMEOUT) {
          throw new APIError(
            'backend unavailable while fetching user token',
            ERROR_UNAVAILABLE
          )
        }
      }
    }

    throw new APIError()
  }

  async requestOneTimePassword(email, fcSolution, xForwardedIp) {
    assert(email, 'email missing', ERROR_BADINPUT)
    this.logger.debug(`Request one time password (OTP) for email ${email}`)
    const requestConfig = {
      headers: { ...config.headers, 'x-forwarded-for': xForwardedIp },
    }

    const path = this.getOneTimePasswordRequestURL()

    const payload = {
      username: email,
      fcSolution,
    }
    try {
      const { status } = await this.post(path, payload, requestConfig)
      this.logger.debug(`One time password (OTP) for ${email} requested!`)
      return status === NO_CONTENT
    } catch (e) {
      if (e.response) {
        const { status } = e.response
        if (status === BAD_REQUEST) {
          throw new APIError(
            'bad input for requesting one time password (OTP)',
            ERROR_BADINPUT
          )
        }

        if (status === GATEWAY_TIMEOUT) {
          throw new APIError(
            'backend unavailable while requesting one time password (OTP)',
            ERROR_UNAVAILABLE
          )
        }
      }
    }

    throw new APIError()
  }

  async confirmOneTimePassword(email, otp, fcSolution, xForwardedIp) {
    assert(email, 'email missing', ERROR_BADINPUT)
    assert(otp, 'one time password missing', ERROR_BADINPUT)
    this.logger.debug(`Request one time password (OTP) for email ${email}`)
    const requestConfig = {
      headers: { ...config.headers, 'x-forwarded-for': xForwardedIp },
    }

    const path = this.getOneTimePasswordConfirmationURL()

    const payload = {
      username: email,
      password: otp,
      fcSolution,
    }
    try {
      const { status, data } = await this.post(path, payload, requestConfig)
      this.logger.debug(`OTP: Token for ${email} fetched!`, 'dataFetching')
      if (status === CREATED) {
        return data
      }
    } catch (e) {
      if (e.response) {
        const { status } = e.response
        if (status === BAD_REQUEST) {
          throw new APIError(
            'OTP: Bad input for fetching user token',
            ERROR_BADINPUT
          )
        }

        if (status === NOT_FOUND) {
          throw new APIError('OTP: User not found', ERROR_BADINPUT)
        }
        if (status === GATEWAY_TIMEOUT) {
          throw new APIError(
            'OTP: Backend unavailable while fetching user token',
            ERROR_UNAVAILABLE
          )
        }
      }
    }

    throw new APIError()
  }

  async registerUser({
    salutation,
    firstName,
    lastName,
    email,
    password,
    birthdayYear,
    birthdayMonth,
    birthdayDay,
    newsletterAccepted,
    tosAccepted,
    preferredLanguage,
    customerType,
    companyName,
    vatNumber,
  }) {
    const tos = Boolean(parseBoolStringAttribute(null, tosAccepted))
    const newsletter = Boolean(
      parseBoolStringAttribute(null, newsletterAccepted)
    )

    const dateOfBirth = toISODateString({
      day: birthdayDay,
      month: birthdayMonth,
      year: birthdayYear,
    })

    this.logger.debug(`register user ${email}`, 'dataFetching')

    const path = this.getRegisterURL()

    let payload = {
      salutation,
      firstName,
      lastName,
      email,
      password,
      dateOfBirth,
      newsletterAccepted: newsletter,
      registrationOrigin: REGISTRATION_ORIGIN,
      tosAccepted: tos,
      preferredLanguage,
    }
    //Customer Type is only set for professional Account of Farmaline (BE).
    if (isCustomerTypeProfessional(customerType)) {
      payload = {
        ...payload,
        customerType,
        companyName,
        vatNumber: 'BE' + vatNumber, //In frontend the user enters only the number. But backend expects with country code
      }
    }

    try {
      const { data, status } = await this.post(path, payload, config)
      this.logger.debug(`Token for ${email} fetched!`, 'dataFetching')

      if (status === CREATED) {
        return data
      }
    } catch (error) {
      const status = error?.response?.status
      const message = error?.response?.data?.message

      const isAuthServiceRelated = /^AuthService/.test(message)

      if (isAuthServiceRelated && status === SERVICE_UNAVAILABLE) {
        this.logger.error('AuthService Error - Corrupted registration', {
          error,
        })

        throw new APIError(
          'AuthService Error - Corrupted registration',
          ERROR_AUTH_SERVICE
        )
      }

      if (status === BAD_REQUEST) {
        const invalidParams = []

        const invalidParamsObj = error?.response?.data?.invalidParams
        if (Array.isArray(invalidParamsObj)) {
          invalidParamsObj.forEach(invalidParam => {
            invalidParams.push(invalidParam.key)
          })
        }

        const errors = [...invalidParams, ERROR_BADINPUT]

        throw new APIError('bad input for registering user', ...errors)
      }

      if (status === GATEWAY_TIMEOUT) {
        throw new APIError(
          'backend unavailable while registering user',
          ERROR_UNAVAILABLE
        )
      }
    }

    throw new APIError()
  }

  async logout() {
    try {
      const { status } = await this.post(this.getLogoutURL(), {}, config)

      return OK === status
    } catch (error) {
      const status = error?.response?.status

      if (status === UNAUTHORIZED) {
        throw new APIError('An invalid token has been provided', ERROR_BADINPUT)
      }

      if (status === FORBIDDEN) {
        throw new APIError(
          'Token is not valid or has not been provided',
          ERROR_BADINPUT
        )
      }

      if (status === GATEWAY_TIMEOUT) {
        throw new APIError(
          'Backend unavailable while logging out',
          ERROR_UNAVAILABLE
        )
      }
    }

    throw new APIError()
  }

  async forgotPassword(email, fcSolution, xForwardedIp) {
    assert(email, 'AuthService.forgotPassword: email is mandatory')
    const requestConfig = {
      headers: { ...config.headers, 'x-forwarded-for': xForwardedIp },
      params: {
        email,
        fcSolution: fcSolution ? fcSolution : ' ', //Query Param is marked required on bully. So need to send an empty string if not present
      },
    }
    try {
      const { status } = await this.get(
        this.getForgotPasswordURL(),
        requestConfig
      )
      return status
    } catch (e) {
      if (e.response) {
        const { status } = e.response
        if (status >= GATEWAY_TIMEOUT) {
          this.logger.error(
            `ForgotPassword request for ${email} failed unexpectedly. Status code ${status}`
          )
          throw new APIError(
            'backend unavailable while requesting for forgot password link',
            ERROR_UNAVAILABLE
          )
        } else {
          this.logger.warn(
            `ForgotPassword request for ${email} failed due to client errors. Status code: ${status}`
          )
          return status
        }
      }
    }
  }

  async validateResetPasswordToken(resetToken) {
    assert(
      resetToken,
      'AuthService.validateResetPasswordToken: token is mandatory'
    )
    try {
      const { status } = await this.get(this.getValidateResetPasswordURL(), {
        headers: {
          'Authorization': `Bearer ${resetToken}`,
        },
      })
      return status === NO_CONTENT
    } catch (e) {
      this.logger.error(
        `ValidateResetPasswordToken request for ${resetToken} failed. Status code ${status}`
      )
      throw new APIError('Reset token invalid.', GENERAL_ERROR)
    }
  }

  async resetPassword(password, resetToken) {
    assert(password, 'AuthService.forgotPassword: email is mandatory')
    try {
      const { status } = await this.post(
        this.getResetPasswordURL(),
        {
          password,
        },
        {
          headers: {
            'Authorization': `Bearer ${resetToken}`,
          },
        }
      )
      return status === NO_CONTENT
    } catch (e) {
      if (e.response) {
        const {
          status,
          data: { message },
        } = e.response
        if (status >= INTERNAL_SERVER_ERROR) {
          this.logger.error(
            `ResetPassword request for failed unexpectedly. Status code ${status}`
          )
          throw new APIError(
            'Backend unavailable while posting new password',
            ERROR_UNAVAILABLE
          )
        } else if (
          message &&
          message === 'customer-authentication.error.token.invalid'
        ) {
          throw new APIError('Invalid or expired token', ERROR_INVALID_TOKEN)
        } else if (status === BAD_REQUEST) {
          throw new APIError(
            'Token in invalid format or password validation failed',
            ERROR_BADINPUT
          )
        } else {
          this.logger.warn(
            `ResetPassword request for failed. Status code: ${status}`
          )
          throw new APIError('ResetPassword request for failed.', GENERAL_ERROR)
        }
      }
    }
  }

  async changePassword(newPassword, password) {
    assert(newPassword, 'AuthService.changePassword: new password is mandatory')
    assert(password, 'AuthService.changePassword: password is mandatory')
    try {
      const { status } = await this.post(this.getChangePasswordURL(), {
        newPassword,
        password,
      })
      if (status === OK) {
        return true
      }

      throw new APIError('ChangePassword request with with', GENERAL_ERROR)
    } catch (e) {
      if (e.response) {
        const {
          status,
          data: { message },
        } = e.response
        if (status >= INTERNAL_SERVER_ERROR) {
          this.logger.error(
            `ChangePassword request for failed unexpectedly. Status code ${status}`
          )
          throw new APIError(
            'Backend unavailable while posting new password',
            ERROR_UNAVAILABLE
          )
        } else if (
          status === BAD_REQUEST &&
          message === 'customer-authentication.error.wrong.username.or.password'
        ) {
          return false
        } else {
          this.logger.warn(
            `ChangePassword request for failed. Status code: ${status}`
          )
          throw new APIError(
            'ChangePassword request for failed.',
            GENERAL_ERROR
          )
        }
      }
    }
  }
}
