// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'
import assert from 'shared/utils/assert'

export interface CreateSubscriptionBodyV1 {
  emailAddress: string
  language: string
  subscriptionType: string
  subscriptionRegistrationChannel: string
  customerNumber?: string
  dateOfBirth?: string
  firstName?: string
  lastName?: string
  salutation?: string
}

export class CreateSubscriptionBody extends Model {
  public firstName: string
  public lastName: string
  public salutation: string
  public dateOfBirth: string
  public emailAddress: string
  public subscriptionType: string
  public customerNumber: string
  public subscriptionRegistrationChannel: string
  public language: string

  constructor(src: CreateSubscriptionBodyV1) {
    super(src)
    assert(
      src.emailAddress,
      'MailingSubscriptionService.activateSubscription: please provide "emailAddress"'
    )

    assert(
      src.language,
      'MailingSubscriptionService.activateSubscription: please provide "language"'
    )

    assert(
      src.subscriptionType,
      'MailingSubscriptionService.activateSubscription: please provide "subscriptionType"'
    )

    assert(
      src.subscriptionRegistrationChannel,
      'MailingSubscriptionService.activateSubscription: please provide "subscriptionRegistrationChannel"'
    )

    this.firstName = src.firstName
    this.lastName = src.lastName
    this.salutation = src.salutation
    this.dateOfBirth = src.dateOfBirth
    this.emailAddress = src.emailAddress
    this.subscriptionType = src.subscriptionType // hardcode it
    this.customerNumber = src.customerNumber
    this.subscriptionRegistrationChannel = src.subscriptionRegistrationChannel
    this.language = src.language
  }
}
