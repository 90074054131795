const FULL_WIDTH_SELECTOR = '[data-clientside-hook~="fullWidthElement"]'

export default () => {
  const scrollbarWidth =
    window.innerWidth - document.documentElement.clientWidth

  if (scrollbarWidth === 0) {
    return null
  }

  return [...document.querySelectorAll(FULL_WIDTH_SELECTOR)].map(element => {
    const fullWidthCSSprop = `100vw - ${scrollbarWidth}px`

    element.style.width = `calc(${fullWidthCSSprop})`

    element.style.left = `calc((${fullWidthCSSprop} - 100%) / 2 * -1)`

    return element
  })
}
