import { GATEWAY_TIMEOUT } from 'shared/consts/codes'

import BackendError from './BackendError'

class TimeoutError extends BackendError {
  constructor(messageWithData) {
    super(messageWithData)
    this.status = Number(GATEWAY_TIMEOUT)
  }
}

export default TimeoutError
