// @ts-nocheck
import { CartState } from 'types/redux'

const preloadedCartState: CartState = {
  collectiveOrder: false,
  data: null,
  voucherAccepted: false,
  voucherLoad: false,
  voucherError: null,
  offers: [],
  showSuccess: false,
}

export default preloadedCartState
