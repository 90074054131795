export const PRELOADED_STATE_KEY = '__PRELOADED_STATE__'
export const PRELOADED_STATE_SCRIPT_CLASSNAME = 'preloaded-state-template'
export const PUBLIC_RUNTIME_CONFIG_KEY = 'publicRuntimeConfig'
export const SHOP_CONFIG_KEY = 'shopConfig'
export const SERVER_RUNTIME_CONFIG_KEY = 'serverRuntimeConfig'
export const USER_SESSION = 'userSession'
export const COMPONENT_INITIAL_STATE_KEY = 'componentInitialState'
export const CART_KEY = 'cart'
export const NOW_DATA_KEY = 'nowData'
export const NOW_HOME_ONE_DATA_KEY = 'nowInfo'
export const USER_SESSION_KEY = 'userSession'
export const DEBUG_KEY = 'debug'
