import { isDesktop, isTablet } from 'shared/utils/deviceClassUtils'
import { type DeviceClass } from 'types/shopConfig'

type DevicePlatform = 'desktop' | 'mobile' | 'tablet'

const getDevicePlatform = (deviceClass: DeviceClass): DevicePlatform => {
  if (isDesktop(deviceClass)) {
    return 'desktop'
  }
  if (isTablet(deviceClass)) {
    return 'tablet'
  }

  return 'mobile'
}

export default getDevicePlatform
