import assert from 'shared/utils/assert'

import BackendAPIService from './BackendAPIService'

const API_VERSION = 'v1'

class PaymentService extends BackendAPIService {
  constructor(props) {
    super(props)

    this.apiVersion = API_VERSION
  }

  paymentRoot = () => `/payments/${this.apiVersion}/${this.tenantPath}`

  getRootUrl() {
    return `${this.paymentRoot()}/`
  }

  getFetchUrl(orderDraftId) {
    return `${this.paymentRoot()}/${orderDraftId}/paymentMethods`
  }

  getCreateAndReserveUrl(orderDraftId) {
    return `${this.paymentRoot()}/${orderDraftId}/payments`
  }

  getRedirectDetailsUrl(orderDraftId) {
    return `${this.paymentRoot()}/${orderDraftId}/payments/details`
  }

  getDisableUrl() {
    return `${this.paymentRoot()}/disable`
  }

  getPaymentInformationUrl(orderId) {
    return `${this.paymentRoot()}/ratepay/details?orderNumber=${orderId}&captured=true`
  }

  getOriginKeysUrl() {
    return `${this.paymentRoot()}/originKeys`
  }

  async getPaymentMethods(orderDraftId) {
    assert(
      orderDraftId,
      'PaymentService.getPaymentMethods: please provide orderDraftId'
    )
    const response = await this.get(this.getFetchUrl(orderDraftId))

    this.logger.debug(
      `PaymentService fetches payment methods and data`,
      'dataFetching'
    )
    return response
  }

  async getPaymentInformation(orderId) {
    assert(
      orderId,
      'PaymentService.getPaymentInformation: please provide orderId'
    )
    const response = await this.get(this.getPaymentInformationUrl(orderId))

    this.logger.debug(
      `PaymentService fetches payment information data`,
      'dataFetching'
    )
    return response
  }

  async createPaymentData(orderDraftId, payload) {
    assert(
      orderDraftId,
      'PaymentService.getPaymentMethods: please provide orderDraftId'
    )
    assert(
      payload,
      'PaymentService.createPaymentData: please provide "payload"'
    )

    const response = await this.post(
      this.getCreateAndReserveUrl(orderDraftId),
      payload
    )

    this.logger.debug('PaymentService creates payment data!', 'dataCreation')
    return response
  }

  /*
   * Handles redirects for 3DS1, 3DS native, Sofort and PayPal
   */
  async handleRedirection(orderDraftId, payload) {
    assert(
      orderDraftId,
      'PaymentService.handleRedirection: please provide "orderDraftId"'
    )
    assert(
      payload,
      'PaymentService.handleRedirection: please provide "payload"'
    )
    const response = await this.post(
      this.getRedirectDetailsUrl(orderDraftId),
      payload,
      { timeout: 1000 }
    )

    this.logger.debug('PaymentService handles redirection!', 'dataCreation')
    return response
  }

  async disablePaymentData(payload) {
    assert(
      payload,
      'PaymentService.deletePaymentData: please provide "payload"'
    )
    const response = await this.post(this.getDisableUrl(), payload)
    this.logger.debug(
      `PaymentService disables payment data with id ${payload}!`,
      'dataUpdating'
    )
    return response
  }

  async createOriginKeys(payload) {
    assert(payload, 'PaymentService.createOriginKeys: please provide "payload"')
    const response = await this.post(this.getOriginKeysUrl(), payload)
    this.logger.debug('PaymentService creates originKeys', 'dataCreation')
    return response
  }
}

export default PaymentService
