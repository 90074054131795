import classNames from 'clsx'
import propTypes from 'prop-types'
import { type FC } from 'react'

import { type InferPropsWithDefaults } from 'shared/utils/propTypes'
import { ICON_SIZE } from 'views/consts/styles'

import './Icon.scss'

const SvgIconPropTypes = {
  altText: propTypes.string,
  className: propTypes.string,
  component: propTypes.elementType,
  height: propTypes.number,
  path: propTypes.string,
  size: propTypes.oneOf(ICON_SIZE),
  sizeSmallTiny: propTypes.oneOf(ICON_SIZE),
  viewBox: propTypes.string,
  width: propTypes.number,
}

const SvgIconDefaultProps = {
  height: 30,
  size: 'default',
  width: 30,
}

export type SvgIconProps = InferPropsWithDefaults<
  typeof SvgIconPropTypes,
  typeof SvgIconDefaultProps
>

export type IconProps = Omit<SvgIconProps, 'path'>

const SvgIcon: FC<SvgIconProps> = props => {
  const {
    altText,
    children,
    className,
    component,
    path,
    size,
    sizeSmallTiny,
    ...rest
  } = props

  const Component = component ?? 'svg'

  return (
    <Component
      alt={altText}
      className={classNames(
        `a-icon a-icon--${size}`,
        {
          [`a-icon--${sizeSmallTiny}@small-tiny`]: sizeSmallTiny,
        },
        className
      )}
      {...rest}
    >
      {children || (path && <path d={path} />)}
    </Component>
  )
}

SvgIcon.propTypes = SvgIconPropTypes
SvgIcon.defaultProps = SvgIconDefaultProps

export default SvgIcon
