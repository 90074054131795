// @ts-nocheck
import type { LetterboxDocumentV1 } from '@redteclab/api/clients/bully'

import { Model } from 'shared/services/api/models/Model'
import MountPoints from 'shared/utils/mountPoints'

import {
  LetterboxDocumentContent,
  LetterboxDocumentCustomer,
  LetterboxMarketplaceDocumentContent,
} from './'

export class LetterboxDocument extends Model {
  public readonly orderId: string
  public readonly customerId: string
  public readonly type: string
  public readonly date: string
  public readonly since: string
  public readonly content:
    | LetterboxDocumentContent
    | LetterboxMarketplaceDocumentContent
  public readonly customer: LetterboxDocumentCustomer
  public readonly prescriptionType: string
  public url = ''

  constructor(src: LetterboxDocumentV1) {
    super(src)
    this.content = src.content?.hash
      ? new LetterboxDocumentContent(src.content)
      : new LetterboxMarketplaceDocumentContent(src.content)
    this.customerId = src.customer_id
    this.date = src.date
    this.orderId = src.order_id
    this.since = src.since
    this.type = src.type
    this.customer = src.customer && new LetterboxDocumentCustomer(src.customer)
    this.prescriptionType = src.prescription_type
  }

  getDocumentURL(mountPoints: MountPoints): string {
    this.url = mountPoints.getLetterboxDocumentURL(this)
    return this.url
  }

  getMarketplaceDocumentURL(mountPoints: MountPoints): string {
    this.url = mountPoints.getMarketplaceLetterboxDocumentURL(this)
    return this.url
  }
}
