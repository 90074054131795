// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

export interface OrganizationDetailsV1 {
  displayName: string
  id?: string
  type: string
}

export class OrganizationDetails extends Model {
  public readonly displayName: string
  public readonly id: string
  public readonly type: string

  constructor(src: OrganizationDetailsV1) {
    super(src)
    this.displayName = src.displayName
    this.id = src.id
    this.type = src.type
  }
}
