// URL for imageserver (FALLBACK / FIRST THROW needs tenant ability)
export const STATIC_IMAGES_BASE_URL = 'https://static.shop-apotheke.com'

// Product placeholder image
export const PRODUCT_PLACEHOLDER = `/media/content/placeholder.png`

// Product placeholder image for RX Products
export const RX_PRODUCT_PLACEHOLDER = `/media/content/placeholder-prescription-ad.png`

// Videos for prescription
export const REZEPTES_VIDEO_MP4 =
  'https://static.shop-apotheke.com/media/videos/rezeptes.mp4'
export const REZEPTES_VIDEO_WEBM =
  'https://static.shop-apotheke.com/media/videos/rezeptes.webm'
