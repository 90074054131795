import axios from 'axios'

import { API_TIMEOUT_MS } from 'shared/consts'
import assert from 'shared/utils/assert'

export const ADDRESS_SUGGEST_PROXY_URL = '/nx/addresssuggest'

class AddressSuggestService {
  constructor({ tenant, language, logger }) {
    this.client = axios.create({
      timeout: API_TIMEOUT_MS,
    })
    this.tenant = tenant
    this.language = language
    this.logger = logger
  }

  async request(config) {
    return this.client.request(config)
  }

  async get(path, options = null) {
    return this.request({
      method: 'GET',
      url: path,
      ...options,
    })
  }

  registerUrl = () => `${ADDRESS_SUGGEST_PROXY_URL}/register/`
  zipsUrl = () => `${ADDRESS_SUGGEST_PROXY_URL}/zips/`
  citiesUrl = () => `${ADDRESS_SUGGEST_PROXY_URL}/cities/`
  streetsUrl = () => `${ADDRESS_SUGGEST_PROXY_URL}/streets/`

  async register() {
    this.logger.debug(`AddressSuggestService try to register`)
    const url = this.registerUrl()
    const response = await this.get(url)
    this.logger.debug(
      `AddressSuggestService register response data: ${response.data}`
    )
    return response
  }

  async fetchZips(asid, zipPrefix) {
    assert(asid, 'AddressSuggestService.fetchZips: please provide asid')
    assert(
      zipPrefix,
      'AddressSuggestService.fetchZips: please provide zipPrefix'
    )
    this.logger.debug(
      `AddressSuggestService try to call fetchZips(asid= ${asid}, zipPrefix= ${zipPrefix}`
    )

    const response = await this.get(this.zipsUrl(), {
      params: {
        prefix: zipPrefix,
      },
      headers: {
        'as-sessionid': asid,
      },
    })
    this.logger.debug(
      `AddressSuggestService fetchZips response data: ${response.data}`
    )
    return response
  }

  async fetchCity(asid, zip) {
    assert(asid, 'AddressSuggestService.fetchCity: please provide asid')
    assert(zip, 'AddressSuggestService.fetchCity: please provide zip')
    this.logger.debug(
      `AddressSuggestService try to call fetchCity(asid= ${asid}, zip= ${zip}`
    )
    const response = await this.get(this.citiesUrl(), {
      params: {
        zip,
      },
      headers: {
        'as-sessionid': asid,
      },
    })
    this.logger.debug(
      `AddressSuggestService fetchCity response data: ${response.data}`
    )
    return response
  }

  async fetchStreets({ asid, zip, prefix }) {
    assert(asid, 'AddressSuggestService.fetchStreets: please provide asid')
    assert(zip, 'AddressSuggestService.fetchStreets: please provide zip')
    assert(prefix, 'AddressSuggestService.fetchStreets: please provide prefix')
    this.logger.debug(
      `AddressSuggestService try to call fetchStreets(asid= ${asid}, zip= ${zip}, prefix= ${prefix}`
    )
    const response = await this.get(this.streetsUrl(), {
      params: {
        zip,
        prefix,
      },
      headers: {
        'as-sessionid': asid,
      },
    })
    this.logger.debug(
      `AddressSuggestService fetchStreets response data: ${response.data}`
    )
    return response
  }
}

export default AddressSuggestService
