import store, { subscribe } from 'shared/store'
import { INIT_CROSSSELL } from 'shared/store/ducks/events'
import { MORE_PRODUCTS_XSELL_ID } from 'views/consts'
import loadCatalogs from 'views/containers/browser/utils/loadCatalogs'

const SHOW_MORE_BUTTON_SELECTOR = '[data-clientside-hook~="showMoreButton"]'

const handleButtonClick = async (container, button) => {
  const {
    publicConfig: { locale },
  } = store.getPublicRuntimeConfig()

  const i18n = await loadCatalogs(locale)
  const hydrateMoreProductsComponent = await import(
    /* webpackChunkName: "MoreProductsWithHydration", webpackPrefetch: true */
    `./MoreProductsWithHydration`
  )

  hydrateMoreProductsComponent.default({
    container,
    i18n,
  })

  button.remove()
}

export default () => {
  subscribe.after(INIT_CROSSSELL, payload => {
    const { selector } = payload

    if (!selector) {
      return
    }

    const slider = document.querySelector(selector)
    if (!slider) {
      return
    }

    const container = slider.querySelector(
      `[data-clientside-hook~="${MORE_PRODUCTS_XSELL_ID}"]`
    )
    const button = slider.querySelector(SHOW_MORE_BUTTON_SELECTOR)

    if (!container || !button) {
      return
    }

    button.addEventListener('click', () => handleButtonClick(container, button))
  })
}
