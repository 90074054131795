import { IconEyeOpen } from '@redteclab/icons'
import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const IconEye: FC<IconProps> = props => (
  <SvgIcon {...props} component={IconEyeOpen} />
)

export default IconEye
