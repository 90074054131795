import { IconCaretDown as FigmaIconCaretDown } from '@redteclab/icons'
import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const IconCaretDown: FC<IconProps> = props => (
  <SvgIcon {...props} component={FigmaIconCaretDown} />
)

export default IconCaretDown
