// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

import { NotepadProductOfferBasePrice } from './notepadProductOfferBasePrice'
import { NotepadProductOfferPriceAdditionalInfo } from './notepadProductOfferPriceAdditionalInfo'
import { NotepadProductOfferPriceV1 } from './notepadResponseTypes'

export class NotepadProductOfferPrice extends Model {
  public basePrice: NotepadProductOfferBasePrice
  public currency: string
  public price: number
  public shippingPrice: number
  public totalPrice: number
  public additionalInformation: NotepadProductOfferPriceAdditionalInfo

  constructor(src: NotepadProductOfferPriceV1) {
    super(src)
    this.additionalInformation = new NotepadProductOfferPriceAdditionalInfo(
      src.additionalInformation
    )
    this.basePrice = src.basePrice
      ? new NotepadProductOfferBasePrice(src.basePrice)
      : null
    this.currency = src.currency
    this.price = src.price
    this.shippingPrice = src.shippingPrice
    this.totalPrice = src.totalPrice
  }
}
