// @ts-nocheck
import { AxiosResponse } from 'axios'

import BackendAPIService from './BackendAPIService'

const API_VERSION = 'v1'

interface ISepaDataService {
  save: (
    iban: string,
    accountHolderName: string
  ) => Promise<AxiosResponse<SepaDataServiceResponseV1>>
}

interface SepaDataServiceResponseV1 {
  accountHolderName: string
  customerNumber: string
  iban: string
  id: string
  tenant: string
}

class SepaDataService extends BackendAPIService implements ISepaDataService {
  constructor(props) {
    super(props)

    this.apiVersion = API_VERSION
  }

  public getFetchUrl = (): string =>
    `/sepa/${this.apiVersion}/${this.tenantPath}/`

  public async save(
    iban: string,
    accountHolderName: string
  ): Promise<AxiosResponse<SepaDataServiceResponseV1>> {
    const response: AxiosResponse<SepaDataServiceResponseV1> = await this.post(
      this.getFetchUrl(),
      {
        iban,
        accountHolderName,
      }
    )

    this.logger.debug(`SepaDataService.save!`, 'validation')

    return response
  }
}

export default SepaDataService
