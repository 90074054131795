import BaseError from 'shared/errors/BaseError'

class AssertionError extends BaseError {
  constructor(message, ...codes) {
    super(message, ...codes)
    this.name = this.constructor.name
  }
}

export default AssertionError
