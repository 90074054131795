// FOCK regex
export const emailRegExp = /[^@\s]+@[^@\s]+\.[^@\s]+/
export const phoneRegExp = /^(\+49\s?[\s\-/]?\s?)?(\d+[\s\-/]+)?(\d+)$/
export const generalPhoneRegExp = /^([+]?[- 0-9]{8,})$/
// Only used in getPatten
export const zipRegExp = /^\d{5}$/

// MONOLITH REGEX BOILERPLATE
// chars
export const standard = 'a-zßA-Zẞ'
export const numeric = '0-9'
export const gravis = 'àÀèÈìÌòÒùÙ'
export const nasal = 'ñõãÑÕÃ'
export const specialCharacters = "Çç' \\-._&/,~’‘"
export const cedilla = 'Çç'
export const akut = 'áÁéÉíÍóÓúÚýÝ'
export const zircumflex = 'âÂêÊîÎôÔûÛ'
export const trema = 'ÄääǞǟЁёëḦḧÏïïÖööȪȫṎṏT̈ÜüüǕǖǗǘǙǚǛǜṺṻẄẅẌẍŸÿÿ'
// eslint-disable-next-line no-useless-escape
export const dash = `\-`
export const dot = '.'
export const space = ' '
export const singleQuote = "\\'"

// regex patterns
export const accountNumberRegExp = new RegExp('^([\\d]{2,12})$')

export const allowedCharacters = new RegExp(
  `${standard}${gravis}${akut}${zircumflex}${nasal}${trema}${specialCharacters}${numeric}`
)
export const allowedCharactersWithoutNumber = new RegExp(
  `${standard}${gravis}${akut}${zircumflex}${nasal}${trema}${specialCharacters}`
)
export const allowedCharactersWithSomeSpecialChars = `${standard}${gravis}${akut}${zircumflex}${nasal}${trema}${cedilla}${space}${singleQuote}${dot}${dash}`

export const bankAccountRegExp = new RegExp('^([\\d]{8})$')
// eslint-disable-next-line no-misleading-character-class
export const commonCharacterRegExp = new RegExp(
  `([${standard}]|[${gravis}]|[${akut}]|[${zircumflex}]|[${nasal}]|[${trema}]|[${specialCharacters}])*`
)
export const creditCardCvcRegExp = new RegExp('^([\\d]{3})$')
export const creditCardNumberRegExp = new RegExp('^([\\d]{13,16})$')
export const dayAndMonthRegExp = new RegExp('^([\\d]{1,})$')
// eslint-disable-next-line no-misleading-character-class
export const eMailAddressRegExp = new RegExp(
  `^([${allowedCharacters}]+)@([${allowedCharacters}]+)\\.([${allowedCharacters}]{2,})$`
)

export const taxNumberRegExp = new RegExp('^[a-zA-Z]{6}[a-zA-Z0-9]{10}$')
export const passwordRegExp = new RegExp('^(.{6,})$')
export const salutationRegExp = new RegExp('^([\\w]+)$')
// eslint-disable-next-line no-misleading-character-class
export const simpleTextFieldRegExp = new RegExp(`^([${allowedCharacters}]+)$`)
// eslint-disable-next-line no-misleading-character-class
export const simpleTextFieldOnlyCharactersRegExp = new RegExp(
  `^([${allowedCharactersWithoutNumber}]+)$`
)
// eslint-disable-next-line no-misleading-character-class
export const streetRegExp = new RegExp(`^([${allowedCharacters}]{2,})$`)

// eslint-disable-next-line no-misleading-character-class
export const streetAccountRegExp = new RegExp(
  `^([${numeric}${allowedCharactersWithSomeSpecialChars}]{2,})$`
)
// eslint-disable-next-line no-misleading-character-class
export const onlyCharactersRegExp = new RegExp(
  `^([${allowedCharactersWithoutNumber}]{2,})$`
)
export const yearRegExp = new RegExp('^([\\d]{4,})$')
export const addressFormMaxLength = 50

export const validUrlRegExp =
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)$/

export const latinLettersRegex = /[\xc0-\xd6\xd8-\xf6\xf8-\xff\u0100-\u017f]/g
export const urlSpecialCharRegex = /[^\w\s-]/g
export const urlWhitespaceDashRegex = /[\s_-]+/g
