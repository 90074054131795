import type {
  CartMiniCartV4,
  CartV2,
  CartV2CartEntryRequestV1,
  CartV2RedPointsRequestV1,
  CartV2VoucherRequestV1,
} from '@redteclab/api/clients/bully'
import type { AxiosResponse } from 'axios'

import assert from 'shared/utils/assert'

import BackendAPIService from './BackendAPIService'
import type { ServiceParameters } from './commonTypes/ServiceParameters'

const API_VERSION = 'v3'

interface ICartServiceV3 {
  fetchCart: (purgeMessages?: boolean) => Promise<AxiosResponse<CartV2>>
  createCart: (body: CartV2CartEntryRequestV1) => Promise<AxiosResponse<CartV2>>
  deleteCartItem: (
    offerId: string,
    pType: string
  ) => Promise<AxiosResponse<void> | null>
  fetchMiniCart: () => Promise<AxiosResponse<CartMiniCartV4>>
  addRedPoints: (
    voucher: CartV2RedPointsRequestV1
  ) => Promise<AxiosResponse<CartV2>>
  deleteVoucher: (voucher: string) => Promise<AxiosResponse<void>>
  addVoucher: (
    voucher: CartV2VoucherRequestV1
  ) => Promise<AxiosResponse<CartV2>>
}

export interface INowParams {
  nowZipCode: string
  isNowSelected?: boolean
}

class CartServiceV3 extends BackendAPIService implements ICartServiceV3 {
  constructor(props: ServiceParameters) {
    super(props)
    this.apiVersion = API_VERSION
  }

  public getCartUrl = (): string =>
    `/cacheable/${this.language}/cart/${this.apiVersion}/${this.tenantPath}`
  public putCartUrl = (): string =>
    `/cacheable/${this.language}/cart/${this.apiVersion}/${this.tenantPath}`
  public createCartUrl = (): string =>
    `/${this.language}/cart/${this.apiVersion}/${this.tenantPath}/entry`
  public deleteCartUrl = (offerId: string, pType: string): string =>
    `/cacheable/${this.language}/cart/${this.apiVersion}/${this.tenantPath}/entry/${offerId}?prescriptionType=${pType}`
  public getMiniCartUrl = (): string =>
    `/cacheable/${this.language}/cart/v4/${this.tenantPath}/minicart`
  public redPointsUrl = (): string =>
    `/cacheable/${this.language}/cart/${this.apiVersion}/${this.tenantPath}/redpoints`
  public voucherUrl = (): string =>
    `/cacheable/${this.language}/cart/${this.apiVersion}/${this.tenantPath}/voucher`

  async fetchCart(
    purgeMessages?: boolean,
    nowZipCode?: string,
    includeMaxDosage?: boolean
  ): Promise<AxiosResponse<CartV2>> {
    const shouldPurge: boolean = purgeMessages || false
    const shouldIncludeMaxDosage: boolean = includeMaxDosage || true

    const response: AxiosResponse<CartV2> = await this.get(this.getCartUrl(), {
      params: {
        purgeMessages: `${shouldPurge}`,
        nowZipCode,
        includeMaxDosage: `${shouldIncludeMaxDosage}`,
      },
    })

    this.logger.debug(`CartServiceV3 fetch cart!`, 'dataFetching')
    return response
  }

  public async createCart(
    body: CartV2CartEntryRequestV1,
    purgeMessages?: boolean,
    nowParams?: INowParams,
    includeMaxDosage?: boolean
  ): Promise<AxiosResponse<CartV2>> {
    assert(body, 'CartServiceV3.createCart: body missing')
    const shouldPurge: boolean = purgeMessages || false
    const shouldIncludeMaxDosage: boolean = includeMaxDosage || true
    const response: AxiosResponse<CartV2> = await this.post(
      this.createCartUrl(),
      body,
      {
        params: {
          purgeMessages: `${shouldPurge}`,
          nowZipCode: nowParams?.nowZipCode,
          isNowSelected: nowParams?.isNowSelected,
          includeMaxDosage: `${shouldIncludeMaxDosage}`,
        },
      }
    )

    this.logger.debug(`CartServiceV3 create/update cart!`, 'dataFetching')
    return response
  }

  async putCart(
    props: {
      prescriptionFollows?: boolean
      collectiveOrder?: boolean
    },
    nowParams?: INowParams,
    includeMaxDosage?: boolean
  ): Promise<AxiosResponse<CartV2>> {
    const shouldIncludeMaxDosage: boolean = includeMaxDosage || true
    const response: AxiosResponse<CartV2> = await this.put(
      this.putCartUrl(),
      props,
      {
        params: {
          nowZipCode: nowParams?.nowZipCode,
          isNowSelected: nowParams?.isNowSelected,
          includeMaxDosage: `${shouldIncludeMaxDosage}`,
        },
      }
    )
    this.logger.debug(`CartServiceV3 put prescriptionType!`, 'dataFetching')
    return response
  }

  async deleteCartItem(
    offerId: string,
    pType: string
  ): Promise<AxiosResponse<void> | null> {
    try {
      const response: AxiosResponse<void> = await this.delete(
        this.deleteCartUrl(offerId, pType),
        {
          data: {},
        }
      )
      this.logger.debug(`CartServiceV3 delete cart!`, 'dataFetching')
      return response
    } catch (_e) {
      this.logger.debug(`Cart: unable to delete cart item: ${_e}`)
      return null
    }
  }

  public async fetchMiniCart(): Promise<AxiosResponse<CartMiniCartV4>> {
    const response: AxiosResponse<CartMiniCartV4> = await this.get(
      this.getMiniCartUrl()
    )
    this.logger.debug(`CartServiceV3 fetch minicart!`, 'dataFetching')
    return response
  }

  async deleteRedPoints(
    nowParams?: INowParams
  ): Promise<AxiosResponse<CartV2>> {
    const response: AxiosResponse<CartV2> = await this.delete(
      this.redPointsUrl(),
      {
        data: {},
        params: {
          nowZipCode: nowParams?.nowZipCode,
          isNowSelected: nowParams?.isNowSelected,
        },
      }
    )
    this.logger.debug('CartService delete redpoints in cart!', 'dataFetching')
    return response
  }

  async addRedPoints(
    { redpointsAmount }: CartV2RedPointsRequestV1,
    nowParams?: INowParams
  ): Promise<AxiosResponse<CartV2>> {
    assert(redpointsAmount, 'CartService.addRedPoints: amount missing')
    const response: AxiosResponse<CartV2> = await this.post(
      this.redPointsUrl(),
      {
        redpointsAmount,
      },
      {
        params: {
          nowZipCode: nowParams?.nowZipCode,
          isNowSelected: nowParams?.isNowSelected,
        },
      }
    )
    this.logger.debug('CartService add redpoints to cart!', 'dataFetching')
    return response
  }

  public async deleteVoucher(code: string): Promise<AxiosResponse<void>> {
    assert(code, 'CartServiceV3.deleteVoucher: code missing')
    const response: AxiosResponse<void> = await this.delete(
      `${this.voucherUrl()}/${code}`,
      { data: {} }
    )
    this.logger.debug(`CartServiceV3 delete voucher in cart!`, 'dataFetching')
    return response
  }

  public async addVoucher(
    voucherPayload: CartV2VoucherRequestV1,
    purgeMessages = false,
    nowParams?: INowParams
  ): Promise<AxiosResponse<CartV2>> {
    assert(voucherPayload.voucherCode, 'CartServiceV3.addVoucher: code missing')
    const shouldPurge: boolean = purgeMessages
    const response: AxiosResponse<CartV2> = await this.post(
      this.voucherUrl(),
      voucherPayload,
      {
        params: {
          purgeMessages: `${shouldPurge}`,
          nowZipCode: nowParams?.nowZipCode,
          isNowSelected: nowParams?.isNowSelected,
        },
      }
    )
    this.logger.debug(`CartServiceV3 add voucher to cart!`, 'dataFetching')
    return response
  }
}

export default CartServiceV3
