import 'intersection-observer'

const defaultOptions = {
  root: null,
  rootMargin: '200px 0px',
  threshold: 0,
}

const intersectionObserver = ({
  callback,
  targetElements,
  options = defaultOptions,
}) => {
  if (!callback || !targetElements) {
    return {}
  }

  const observeElements = Array.isArray(targetElements)
    ? targetElements
    : [targetElements]

  const IO = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting || entry.boundingClientRect.top <= 0) {
      callback()
      IO.disconnect()
    }
  }, options)

  Array.prototype.forEach.call(observeElements, element => {
    IO.observe(element)
  })

  return IO
}

export default intersectionObserver
