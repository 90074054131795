import {
  subscribeActionAfter,
  subscribeActionBefore,
  subscribeAfter,
  subscribeBefore,
} from 'redux-subscribe-action'

/**
 * exports a subscribe object with methodes to attach callbacks to
 * all (or certain) actions arriving at the store
 * also exposes 'once' methodes for easier development
 *
 * subscribe needs the subscribeActionMiddleware (from redux-subscribe-action)
 * to be attached to the redux store
 */

// generate a function to subscribe just once to an action
const generateOnce = subscribeMethod => (action, cb) => {
  // curStore is the current store object (before or after change depending on method)
  const unsubscribe = subscribeMethod(action, curStore => {
    cb(curStore)
    unsubscribe()
  })
}
const generateAllOnce = subscribeMethod => cb => {
  // curStore is the current store object (before or after change depending on method)
  const unsubscribe = subscribeMethod(curStore => {
    cb(curStore)
    unsubscribe()
  })
}

const subscribeMethods = {
  before: subscribeActionBefore,
  beforeAll: subscribeBefore,
  after: subscribeActionAfter,
  afterAll: subscribeAfter,
}
// extend with once functions
subscribeMethods.before.once = generateOnce(subscribeActionBefore)
subscribeMethods.beforeAll.once = generateAllOnce(subscribeBefore)
subscribeMethods.after.once = generateOnce(subscribeActionAfter)
subscribeMethods.afterAll.once = generateAllOnce(subscribeAfter)

export const subscribe = subscribeMethods

export { unsubscribeAll } from 'redux-subscribe-action'
