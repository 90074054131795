/* Since we want to attach a ref to this component,
 * we cannot use a stateless function.
 */
import { node, string } from 'prop-types'
import { Component, createContext, createRef } from 'react'

const { Provider, Consumer } = createContext('form')

class Form extends Component {
  constructor(props) {
    super(props)
    this.formRef = createRef()
  }

  render() {
    const { name } = this.props

    return (
      <Provider value={name}>
        <form ref={this.formRef} {...this.props} />
      </Provider>
    )
  }
}

Form.propTypes = {
  children: node.isRequired,
  name: string.isRequired,
}

export const FormConsumer = Consumer
export default Form
