import { HIDDEN_CLASS } from './consts'

export default () => {
  const mobileDeviceElements = [
    ...document.querySelectorAll('[data-clientside-hook~="mobileOnly"]'),
  ]

  const isMobileDevice = (w =>
    (typeof w.matchMedia !== 'undefined' ||
      typeof w.msMatchMedia !== 'undefined') &&
    w.matchMedia('(pointer:coarse)').matches)(window)

  return mobileDeviceElements.map(mobileDeviceElement => {
    if (!isMobileDevice) {
      mobileDeviceElement.classList.add(HIDDEN_CLASS)
    }

    return mobileDeviceElement
  })
}
