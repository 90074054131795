import camelCase from 'camelcase'

// TODO: maybe we should do a string test here
export const isUpperCase = char =>
  char.toUpperCase() === char && char.toLowerCase() !== char

const baseActions = ['set', 'update', 'delete']

export const createActionNames = ({
  resourceName,
  includeBase = true,
  additionalActions = [],
}) => {
  const additionalActionsWithLowerCase = additionalActions.map(action =>
    action.toLowerCase()
  )

  const actions = includeBase
    ? [...baseActions, ...additionalActionsWithLowerCase]
    : [...additionalActionsWithLowerCase]

  return actions.reduce((acc, action) => {
    const actionName = camelCase(`${action} ${resourceName}`)
      .split('')
      .reduce(
        (str, char) =>
          `${str}${isUpperCase(char) ? '_' : ''}${char.toUpperCase()}`,
        ''
      )
    acc[actionName] = actionName
    acc[camelCase(actionName)] = actionName
    return acc
  }, {})
}
