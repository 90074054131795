// @ts-nocheck
import store from 'shared/store'
import MountPoints from 'shared/utils/mountPoints'
import { PublicRuntimeConfig } from 'types/shopConfig'

/**
 * The class instance handles the eRX announcement click event:
 * there is an `<A>` tag wrapping the announcement layout.
 * Nested HTML anchor (or button) is invalid and not working properly,
 * so the manual redirection introduced.
 */
class ErxMobileAnnouncement {
  static SELECTOR_MENU_ITEM =
    '[data-clientside-hook~="handle-erx-announcement"]'
  static SELECTOR_MENU_ITEM_BUTTON =
    '[data-clientside-hook~="wrap-erx-announcement"]'

  private readonly menuItem: HTMLElement | null
  private readonly announcement: HTMLElement | null

  constructor(private readonly publicRuntimeConfig: PublicRuntimeConfig) {
    this.menuItem = document.querySelector(
      ErxMobileAnnouncement.SELECTOR_MENU_ITEM
    )
    this.announcement =
      this.menuItem?.querySelector(
        ErxMobileAnnouncement.SELECTOR_MENU_ITEM_BUTTON
      ) || null
  }

  /**
   * Cannot be assigned in the constructor: too early for the context
   */
  private getMountPoints(): MountPoints {
    const {
      publicConfig: { mountPoints, tenant, language },
      pageProperties: { coreShopEnvironment },
    } = this.publicRuntimeConfig

    return new MountPoints({
      mountPoints,
      tenant,
      language,
      coreShopEnvironment,
    })
  }

  private shouldDisplayAnnouncement(): boolean {
    return Boolean(this.menuItem && this.announcement)
  }

  private redirectToErezeptTab = () => {
    window.location.href = this.getMountPoints().getRedeemRecipe('#e-rezept')
  }

  private preventWrapperLinkTransition = (event: Event) => {
    const target = <HTMLElement>event.target

    if (
      target.tagName.toLowerCase() !== 'a' &&
      target.closest(ErxMobileAnnouncement.SELECTOR_MENU_ITEM_BUTTON)
    ) {
      event.preventDefault()
    }
  }

  private bindAnnouncement() {
    this.announcement!.addEventListener('click', this.redirectToErezeptTab)
    this.menuItem!.addEventListener('click', this.preventWrapperLinkTransition)
  }

  public init() {
    if (this.shouldDisplayAnnouncement()) {
      this.bindAnnouncement()
    }
  }
}

const handleMobileErxAnnouncement = () =>
  new ErxMobileAnnouncement(store.getPublicRuntimeConfig()).init()

export default handleMobileErxAnnouncement
