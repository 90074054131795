// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

import {
  OrganizationDetails,
  OrganizationDetailsV1,
} from './OrganizationDetails'

export interface ThreadParticipantV1 {
  id: string
  type: string
  organizationDetails?: OrganizationDetailsV1
  displayName: string
}

export class ThreadParticipant extends Model implements ThreadParticipantV1 {
  public readonly id: string
  public readonly type: string
  public readonly organizationDetails: OrganizationDetails
  public readonly displayName: string

  public constructor(src: ThreadParticipantV1) {
    super(src)
    this.id = src.id
    this.type = src.type
    if (src.organizationDetails) {
      this.organizationDetails = new OrganizationDetails(
        src.organizationDetails
      )
    }
    this.displayName = src.displayName
  }
}
