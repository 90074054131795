import store from 'shared/store'
import throttle from 'shared/utils/throttle'
import {
  BREAKPOINT_LANDSCAPE_SMALL,
  FOCUS_OR_VALUE_CLASSNAME,
} from 'views/consts'
import {
  SELECTOR_DUMMY_SEARCH_BOX,
  SELECTOR_DUMMY_SEARCH_BOX_INPUT,
} from 'views/containers/browser/SearchBoxContainer/consts'

const SCROLL_TRESHOLD = 250
const THROTTLE_INTERVAL = 150
const HIDE_CLASS = 'o-HeaderSmall__dummyInput--hidden'
const TRANSPARENT_MOBILE_HEADER_CLASS = 'o-HeaderSmall--transparent'
const MARGIN_CLASS = 'u-margin--bottom'
const MOBILE_HEADER_SELECTOR = '[data-clientside-hook="header"]'

export const handleDummySearchBoxUpdate = value => {
  /** @type {HTMLInputElement} */
  const mobileDummySearchBoxInput = document.querySelector(
    SELECTOR_DUMMY_SEARCH_BOX_INPUT
  )

  if (mobileDummySearchBoxInput) {
    mobileDummySearchBoxInput.value = value

    if (!value) {
      mobileDummySearchBoxInput.classList.remove(FOCUS_OR_VALUE_CLASSNAME)
      return
    }

    mobileDummySearchBoxInput.classList.add(FOCUS_OR_VALUE_CLASSNAME)
  }
}

// logic for showing/hiding dummy search box on mobile
// it's called dummy b/c it doesn't do anything but shows the value in actual
// mobile search box
export const addScrollEventForDummySearchBox = mobileDummySearchBox => {
  let previousScrollPosY = 0

  const hideSearchBox = mobileHeader => {
    mobileDummySearchBox.classList.add(HIDE_CLASS)
    // appart from hiding dummy searchbox, we also have to tweak some styles
    // of top part of the header (`mobileHeader`)
    mobileHeader.classList.add(MARGIN_CLASS, TRANSPARENT_MOBILE_HEADER_CLASS)
  }

  const showSearchBox = mobileHeader => {
    mobileHeader.classList.remove(MARGIN_CLASS, TRANSPARENT_MOBILE_HEADER_CLASS)
    mobileDummySearchBox.classList.remove(HIDE_CLASS)
  }

  const mobileHeader = document.querySelector(MOBILE_HEADER_SELECTOR)

  document.addEventListener(
    'scroll',
    throttle(() => {
      // case when position is the same as previous one (might happen although rarely),
      if (previousScrollPosY === window.scrollY) {
        return
      }

      // top of the page
      if (window.scrollY <= SCROLL_TRESHOLD) {
        mobileDummySearchBox.classList.remove(HIDE_CLASS)

        return
      }

      if (window.matchMedia(BREAKPOINT_LANDSCAPE_SMALL).matches) {
        hideSearchBox(mobileHeader)
      } else if (previousScrollPosY < window.scrollY) {
        // scrolling down
        hideSearchBox(mobileHeader)
      } else {
        // scrolling up
        showSearchBox(mobileHeader)
      }

      previousScrollPosY = window.scrollY
    }, THROTTLE_INTERVAL)
  )
}

export default () => {
  const {
    pageProperties: { searchState },
  } = store.getPublicRuntimeConfig()

  const { query } = searchState || {}
  if (query) {
    handleDummySearchBoxUpdate(query)
  }
  let mobileDummySearchBox = document.querySelector(SELECTOR_DUMMY_SEARCH_BOX)

  if (!mobileDummySearchBox) {
    return
  }

  addScrollEventForDummySearchBox(mobileDummySearchBox)

  mobileDummySearchBox.children[0].classList.add('o-HeaderSmall__background')
}
