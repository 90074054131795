export const NO_OVERFLOW_CLASS = 'u-no-overflow'
export const NO_DROPDOWN_OVERFLOW_SMALL_TINY_CLASS =
  '.u-no-overflow--dropdown@small-tiny'
export const TRANSPARENT_CLASS = 'u-transparent'
export const HIDDEN_CLASS = 'u-hidden'
export const HIDDEN_MEDIUM_CLASS = 'u-hidden@medium'

export const ARIA_HIDDEN = 'aria-hidden'
export const ARIA_EXPANDED = 'aria-expanded'
export const ARIA_CONTROLS = 'aria-controls'
export const ARIA_BUSY = 'aria-busy'
export const ARIA_DISABLED = 'aria-disabled'
export const ARIA_SELECTED = 'aria-selected'
export const ARIA_PRESSED = 'aria-pressed'

export const CLIENTSIDE_HOOK_DATA_ATTR = 'data-clientside-hook'
export const LAYOUT_SELECTOR = '[data-clientside-hook~="layout"]'

export const BODY_HOVER_CLASS = 'hover-active'

export const OVERLAY_SELECTOR = '[data-clientside-hook~="overlay"]'
export const MENUBAR_OVERLAY_SELECTOR =
  '[data-clientside-hook~="menubarOverlay"]'
export const BACKGROUND_OVERLAY_SELECTOR = '[data-clientside-hook~="bgOverlay"]'
export const WITH_STICKY_BUYBOX = 'with-sticky-buybox'

export const REDIRECT_DELAY_MS = 5000

export const defaultLoadingIndicatorOpts = {
  size: '40px',
  color: 'white',
  thickness: 3.6,
  label: 'loading',
}
