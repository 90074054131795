// @ts-nocheck
import { Children, isValidElement, type ReactElement } from 'react'

import {
  DEFAULT_VARIANT_NAME,
  ExperimentName,
  IExperimentVariant,
  type TenantBasedExperiment,
} from 'shared/experiments/consts'
import { DEFAULT_EXPERIMENT } from 'shared/experiments/consts/experiments'
import { getExperiments } from 'shared/experiments/utils/experiments'
import { Experiment, Tenant } from 'types/shopConfig'
import { usePublicConfig } from 'views/utils/shopAndRuntimeConfigUtils'

export const getExperimentNamesForTenant = (
  tenant: Tenant,
  experimentNames: TenantBasedExperiment
): ExperimentName[] => {
  const experimentNamesForTenant = experimentNames[tenant]
  if (experimentNamesForTenant) {
    return [experimentNamesForTenant.desktop, experimentNamesForTenant.mobile]
  }
  return []
}

/**
 * If Variant has `tenant` prop, it should match the current tenant.
 * If there is no such prop, `true` is returned.
 */
export const validateTenant = (
  variant: IExperimentVariant,
  tenant: Tenant
): boolean => {
  const { tenant: variantTenant } = variant.props
  const tenants: Array<Tenant | unknown> = Array.isArray(variantTenant)
    ? variantTenant
    : [variantTenant]
  const shouldMatch = tenants.filter(Boolean).length > 0

  return shouldMatch && tenant ? tenants.includes(tenant) : true
}

export const getExperimentsList = (...names: unknown[]) =>
  names.flat().filter((name): name is ExperimentName => Boolean(name))

export const compileExperiment = (
  experimentsNames: ExperimentName[],
  experiments: Experiment[]
): Experiment => {
  const activeExperiments = getExperiments(experimentsNames, experiments) || []

  const experimentName: ExperimentName =
    activeExperiments[0]?.name || experimentsNames[0]
  const experiment: Experiment = activeExperiments[0] || {
    ...DEFAULT_EXPERIMENT,
    name: experimentName,
  }

  if (!experiment.variant) {
    experiment.variant = DEFAULT_VARIANT_NAME
  }

  return experiment
}

export const getMatchedVariant = (
  experiment: Experiment,
  nodes: ReactElement
): ReactElement => {
  // eslint-disable-next-line react-hooks/rules-of-hooks -- TODO This function uses a hook, so is also a hook and should be renamed to `use…`
  const { tenant } = usePublicConfig()

  let matchedVariant: ReactElement
  let defaultVariant

  Children.forEach<ReactElement>(nodes, (node: IExperimentVariant) => {
    if (matchedVariant || !isValidElement(node)) {
      return
    }

    const { name: variantName } = node.props
    if (experiment.variant === variantName && validateTenant(node, tenant)) {
      matchedVariant = node
    }

    if (variantName === DEFAULT_VARIANT_NAME) {
      defaultVariant = node
    }
  })

  //@ts-ignore If it exists, we use it
  const firstChild = nodes?.[0]
  if (!matchedVariant && defaultVariant && isValidElement(defaultVariant)) {
    matchedVariant = defaultVariant
  }
  if (!matchedVariant && firstChild && isValidElement(firstChild)) {
    matchedVariant = firstChild
  }

  return matchedVariant
}
