import { getBirthDayErrorDataSetName } from 'shared/utils/formValidation'
import { stringToInt } from 'shared/utils/stringUtils'
import { SELECT_ELEMENT } from 'views/assets/scripts/select'

const BDS_SELECTOR = '[data-clientside-hook~="formBirthdaySelectValidation"]'
const ERR_SELECTOR = '[data-clientside-hook~="formControlValidation__error"]'
const DAY_SELECTOR = '[data-clientside-hook~="birthDaySelect__day"]'
const MONTH_SELECTOR = '[data-clientside-hook~="birthDaySelect__month"]'
const YEAR_SELECTOR = '[data-clientside-hook~="birthDaySelect__year"]'
const MIN_AGE_DATASET_NAME = 'minAge'
const ERROR_CLASS = 'has-error'

export default () => {
  const birthDayElements = [...document.querySelectorAll(BDS_SELECTOR)]

  return birthDayElements.map(element => {
    const errorElement = element.querySelector(ERR_SELECTOR)
    const daySelect = element.querySelector(`${DAY_SELECTOR} ${SELECT_ELEMENT}`)
    const monthSelect = element.querySelector(
      `${MONTH_SELECTOR} ${SELECT_ELEMENT}`
    )
    const yearSelect = element.querySelector(
      `${YEAR_SELECTOR} ${SELECT_ELEMENT}`
    )
    const selects = [daySelect, monthSelect, yearSelect]

    const setCustomValidity = (errorMessage = '') => {
      selects.forEach(select => {
        select.setCustomValidity(errorMessage)
      })
    }

    const validate = () => {
      setCustomValidity()

      const nonValidSelects = selects.filter(select => !select.validity.valid)

      if (!nonValidSelects?.length) {
        const day = stringToInt(daySelect?.value)
        const month = stringToInt(monthSelect?.value)
        const year = stringToInt(yearSelect?.value)
        const minAge =
          stringToInt(element?.dataset?.[MIN_AGE_DATASET_NAME]) || 0
        const errorMsg =
          element?.dataset?.[
            getBirthDayErrorDataSetName({ day, month, year, minAge })
          ] || null

        if (errorMsg) {
          setCustomValidity(errorMsg)
          errorElement.textContent = errorMsg
          element.classList.add(ERROR_CLASS)
        } else {
          errorElement.textContent = ''
          element.classList.remove(ERROR_CLASS)
        }
      } else {
        errorElement.textContent = nonValidSelects[0].validationMessage
        element.classList.add(ERROR_CLASS)
      }
    }

    selects.forEach(select => {
      select.addEventListener('change', validate)
      select.addEventListener('validateSelf', validate)
    })

    return element
  })
}
