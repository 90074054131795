const ADD_PUBLIC_RUNTIME_CONFIG = 'ADD_PUBLIC_RUNTIME_CONFIG'
export const UPDATE_PAGE_PROPERTIES = 'UPDATE_PAGE_PROPERTIES'
export const UPDATE_PUBLIC_CONFIG = 'UPDATE_PUBLIC_CONFIG'

export const addPublicRuntimeConfig = payload => ({
  type: ADD_PUBLIC_RUNTIME_CONFIG,
  payload,
})

export const updatePageProperties = payload => ({
  type: UPDATE_PAGE_PROPERTIES,
  payload,
})

export const updatePublicConfig = payload => ({
  type: UPDATE_PUBLIC_CONFIG,
  payload,
})

const reducer = (state = {}, action = { type: null }) => {
  switch (action.type) {
    case ADD_PUBLIC_RUNTIME_CONFIG:
      return {
        ...state,
        ...action.payload,
      }
    case UPDATE_PAGE_PROPERTIES:
      return {
        ...state,
        pageProperties: {
          ...state.pageProperties,
          ...action.payload,
        },
      }
    case UPDATE_PUBLIC_CONFIG:
      return {
        ...state,
        publicConfig: {
          ...state.publicConfig,
          ...action.payload,
        },
      }
    default:
      return state
  }
}

export default reducer
