import toggleBoolStringAttr from 'views/utils/toggleBoolStringAttribute'

import {
  ARIA_HIDDEN,
  BACKGROUND_OVERLAY_SELECTOR,
  BODY_HOVER_CLASS,
  MENUBAR_OVERLAY_SELECTOR,
  OVERLAY_SELECTOR,
} from './consts'

const registerListeners = (elements, overlay, backgroundOverlay) => {
  const isHoverActive = () => document.body.classList.contains(BODY_HOVER_CLASS)

  const hide = () => {
    elements.forEach(elem => toggleBoolStringAttr(elem, ARIA_HIDDEN, true))
    toggleBoolStringAttr(overlay, ARIA_HIDDEN, true)
    toggleBoolStringAttr(backgroundOverlay, ARIA_HIDDEN, true)
    overlay.removeEventListener('click', hide)
  }

  const show = element => {
    toggleBoolStringAttr(element, ARIA_HIDDEN, false)
    toggleBoolStringAttr(overlay, ARIA_HIDDEN, false)
    toggleBoolStringAttr(backgroundOverlay, ARIA_HIDDEN, false)
    overlay.addEventListener('click', hide)
  }

  return elements.map(element => {
    element.addEventListener('mouseenter', () => {
      if (!isHoverActive()) {
        return
      }
      show(element)
    })
    element.addEventListener('mouseleave', () => {
      if (!isHoverActive()) {
        return
      }
      hide()
    })
    element.addEventListener('click', () => {
      if (isHoverActive()) {
        return
      }

      if (element.getAttribute(ARIA_HIDDEN) === 'true') {
        hide()
        show(element)
      } else {
        hide()
      }
    })

    return element
  })
}

export default () => {
  const headerFlyoutElements = [
    ...document.querySelectorAll('[data-clientside-hook~="headerFlyout"]'),
  ]
  const headerOverlay = document.querySelector(OVERLAY_SELECTOR)

  const menubarFlyoutElements = [
    ...document.querySelectorAll('[data-clientside-hook~="menubarFlyout"]'),
  ]

  const menubarOverlay = document.querySelector(MENUBAR_OVERLAY_SELECTOR)
  const backgroundOverlay = document.querySelector(BACKGROUND_OVERLAY_SELECTOR)

  return [
    registerListeners(headerFlyoutElements, headerOverlay, backgroundOverlay),
    registerListeners(menubarFlyoutElements, menubarOverlay, backgroundOverlay),
  ]
}
