import { Model } from 'shared/services/api/models/Model'

import { type OrderLineItemV1 } from './ordersResponseTypes'

export class OrderLineItem extends Model {
  public canOpenIncident: boolean
  public canRefund: boolean
  public categoryCode: string
  public categoryLabel: string
  public commissionFee: number
  public commissionRateVat: number
  public commissionVat: number
  public createdDate: string
  public debitedDate: string
  public description: string
  public lastUpdatedDate: string
  public offerId: number
  public offerSku: string
  public offerStateCode: string
  public orderLineId: string
  public orderLineIndex: number
  public orderLineState: string
  public orderLineStateReasonCode: string
  public orderLineStateReasonLabel: string
  public price: number
  public priceAdditionalInfo: string
  public priceUnit: number
  public productSku: string
  public productTitle?: string
  public productImage?: string
  public packagingQuantity?: string
  public packagingUnit?: string
  public pharmaForm: string
  public quantity: number
  public shippedDate: string
  public shippingPrice: number
  public totalCommission: number
  public totalPrice: number

  constructor(src: OrderLineItemV1) {
    // @ts-ignore
    super(src)

    this.canOpenIncident = src.canOpenIncident
    this.canRefund = src.canRefund
    this.categoryCode = src.categoryCode
    this.categoryLabel = src.categoryLabel
    this.commissionFee = src.commissionFee
    this.commissionRateVat = src.commissionRateVat
    this.commissionVat = src.commissionVat
    this.createdDate = src.createdDate
    this.debitedDate = src.debitedDate
    this.description = src.description
    this.lastUpdatedDate = src.lastUpdatedDate
    this.offerId = src.offerId
    this.offerSku = src.offerSku
    this.offerStateCode = src.offerStateCode
    this.orderLineId = src.orderLineId
    this.orderLineIndex = src.orderLineIndex
    this.orderLineState = src.orderLineState
    this.orderLineStateReasonCode = src.orderLineStateReasonCode
    this.orderLineStateReasonLabel = src.orderLineStateReasonLabel
    this.price = src.price
    this.priceAdditionalInfo = src.priceAdditionalInfo
    this.priceUnit = src.priceUnit
    this.productSku = src.productSku
    this.productTitle = src.productTitle
    this.productImage = src.productImage
    this.packagingQuantity = src.packagingQuantity
    this.packagingUnit = src.packagingUnit
    this.pharmaForm = src.pharmaForm
    this.quantity = src.quantity
    this.shippedDate = src.shippedDate
    this.shippingPrice = src.shippingPrice
    this.totalCommission = src.totalCommission
    this.totalPrice = src.totalPrice
  }
}
