import { Model } from 'shared/services/api/models/Model'

export interface ThreadMetadataV1 {
  lastMessageDate: string
  totalCount: number
}

export class ThreadMetadata extends Model {
  public readonly lastMessageDate: string
  public readonly totalCount: number

  constructor(src: ThreadMetadataV1) {
    // @ts-ignore
    super(src)
    this.lastMessageDate = src.lastMessageDate
    this.totalCount = Number(src.totalCount)
  }
}
