import store from 'shared/store'
import { NOW_SELLER_MODAL_CONTAINER_ID } from 'views/components/organisms/NowSellerModal/names'
import { isNowPickupOrder } from 'views/components/utils/isNowPickupOrder'
import loadCatalogs from 'views/containers/browser/utils/loadCatalogs'

import { OPEN_NOW_SELLER_MODAL } from './clientsideHook'

const handleButtonClick = async (sellerId, nowSellerModal) => {
  const {
    publicConfig: { locale },
    pageProperties: { featureToggles, subOrder },
  } = store.getPublicRuntimeConfig()

  const isPickup = isNowPickupOrder(subOrder)
  const i18n = await loadCatalogs(locale)

  try {
    const nowSellerModalContainer = await import(
      /* webpackChunkName: "NowSellerModalContainerWithRender" */
      './NowSellerModalContainerWithRender'
    )

    nowSellerModalContainer.default({
      container: nowSellerModal,
      sellerId,
      i18n,
      featureToggles,
      isPickup,
    })
  } catch (err) {
    throw new Error(err)
  }
}

export default () => {
  const nowSellerModalButtons = document.querySelectorAll(
    `[data-clientside-hook*="${OPEN_NOW_SELLER_MODAL}"]`
  )
  const nowSellerModal = document.getElementById(NOW_SELLER_MODAL_CONTAINER_ID)

  if (!nowSellerModalButtons.length || !nowSellerModal) {
    return
  }

  nowSellerModalButtons.forEach(button => {
    const sellerId = button
      .getAttribute('data-clientside-hook')
      .match(new RegExp(`${OPEN_NOW_SELLER_MODAL}--(.+)$`))[1]

    if (!sellerId) {
      return
    }

    button.addEventListener('click', () =>
      handleButtonClick(sellerId, nowSellerModal)
    )
  })
}
