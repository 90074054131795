import { HIDE_ON_INIT_ATTR } from 'views/components/organisms/Collapsible/consts'
import reflow from 'views/utils/reflow'
import toggleBoolStringAttr from 'views/utils/toggleBoolStringAttribute'

import { ARIA_CONTROLS, ARIA_EXPANDED, ARIA_HIDDEN } from './consts'

export function hideBody({
  expanderButtonElement,
  expanderBodyElement,
  transitionStyles,
  init,
}) {
  expanderBodyElement.style.transition = ''

  requestAnimationFrame(() => {
    // on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element’s height to its current pixel height, so we
    // aren’t transitioning out of ‘auto’
    expanderBodyElement.style.height = `${expanderBodyElement.scrollHeight}px`
    expanderBodyElement.style.transition = transitionStyles

    requestAnimationFrame(() => {
      // on the next frame (as soon as the previous style change has taken effect),
      // have the element transition to height: 0
      expanderBodyElement.style.height = '0px'
      expanderBodyElement.style.overflow = null

      const displayNone = () => {
        // After the hideBody animation is done, the content needs to be removed
        // from the document flow or it will sometimes cause spacing issues (for example
        // displaying a scrollbar inside modals when there is only empty space at the
        // bottom).
        // After the transition on expanderBody ends, the event listener
        // should be removed again.
        expanderBodyElement.removeEventListener('transitionend', displayNone)
        expanderBodyElement.style.display = 'none'
      }

      if (!init) {
        expanderBodyElement.addEventListener('transitionend', displayNone)
      }

      expanderButtonElement.blur()
    })
  })
}

export function showBody(expanderBody, transitionStyles) {
  expanderBody.focus()
  // The element must be a block element before the height is set (or it will be 0)!
  expanderBody.style.display = 'block'
  // have the element transition to the height of its inner content
  expanderBody.style.height = `${expanderBody.scrollHeight}px`
  expanderBody.style.transition = transitionStyles

  const onTransitionEnd = () => {
    // when the next css transition finishes (which should be the one we just triggered)
    // remove this event listener so it only gets triggered once
    expanderBody.removeEventListener('transitionend', onTransitionEnd)
    expanderBody.style.height = 'auto'
    expanderBody.style.overflow = 'visible'
    expanderBody.style.display = 'block'
  }

  expanderBody.addEventListener('transitionend', onTransitionEnd)
}

export function ariaToggle({
  expanderButtonElement,
  expanderBodyElement,
  visibility,
}) {
  toggleBoolStringAttr(expanderBodyElement, ARIA_HIDDEN, !visibility)
  toggleBoolStringAttr(expanderButtonElement, ARIA_EXPANDED, visibility)
  reflow()
}

/**
 * expander
 * ========
 *
 * The KEY_TAG here is preferaibily a <button> with two mandatory attributes:
 * - data-clientside-hook: containing "expander__button" (can coexists with other values)
 * - aria-controls: contains the id of the EXPANDING_TARGET element
 *
 * the expander will be enabled IF AND ONLY IF
 * - those two attributes are set
 * AND
 * - can be found a node with an id attribute equal to the value found in the aria-controls
 *
 * Once effective it will take actions on click on the KEY_TAG
 * The effect of the first click on the KEY_TAG depends on the initial state that is
 *  determined by the presence of a specific attribute=value on the EXPANDING_TARGET
 *  and this attribute should be set whenever the EXPANDING_TARGET needs to be expanded
 *  right from the beginning.
 * To initialize the EXPANDING_TARGET as expanded we should
 * explicitly add the attribute "aria-expanded" as "true" to KEY_TAG
 * otherwise it will be initialized as non-expanded
 *
 * Now that we know how to set the initial state, let's get a look at the transitions:
 * - toggle 2 expanded
 *    KEY_TAG aria-hidden attribute is removed
 *    EXPANDING_TARGET aria-expanded attribute is set to 'true'
 *    focus on EXPANDING_TARGET
 *    attempt to smooth expand expand to the needed height
 *
 * - toggle 2 non-expanded
 *    KEY_TAG aria-hidden attribute is set to 'true'
 *    EXPANDING_TARGET aria-expanded attribute is removed
 *    attempt to smooth expand collapse to zero height
 *
 * @example
 *    <button
 *      data-clientside-hook="expander__button"    <-- KEY_TAG
 *      aria-controls="%TARGET_ID%"                <-- TARGET_ID node
 *    >
 *      Press me
 *    </button>
 *    <div id="%TARGET_ID%">whatever</div>         <-- EXPANDING_TARGET node with id=%TARGET_ID%
 *
 * @returns {Array.<{button: HTMLElement, body: HTMLElement}>}
 * Of literal objects each one corresponding to an enabled button; the object will contain
 * - a redefence to the KEY_TAG node
 * - a reference to the EXPANDING_TARGET node
 */
export default () => {
  /** @type {NodeListOf<HTMLElement>} */
  const expanderButtonElementList = document.querySelectorAll(
    '[data-clientside-hook~="expander__button"]'
  )
  const expanderButtonElements = [...expanderButtonElementList]

  return expanderButtonElements.reduce(
    (reducedCollection, expanderButtonElement) => {
      const bodyId = expanderButtonElement.getAttribute(ARIA_CONTROLS)
      const expanderBodyElement = document.getElementById(bodyId)

      if (!bodyId || !expanderBodyElement) {
        return reducedCollection
      }

      let isExpanded =
        expanderButtonElement.getAttribute(ARIA_EXPANDED) === 'true'
      const expanderBodyElementTransition =
        expanderBodyElement.style.transition || 'height 0.5s'

      expanderButtonElement.addEventListener('click', () => {
        ariaToggle({
          expanderButtonElement,
          expanderBodyElement,
          visibility: !isExpanded,
        })
        if (isExpanded) {
          hideBody({
            expanderButtonElement,
            expanderBodyElement,
            transitionStyles: expanderBodyElementTransition,
          })
        } else {
          showBody(expanderBodyElement, expanderBodyElementTransition)
        }

        isExpanded = !isExpanded
      })

      const triggerHideBody =
        expanderBodyElement.getAttribute(HIDE_ON_INIT_ATTR) === 'true'

      // INFO this is partially reproducing the previous behaviour,
      // when the attrs were toggled inside the hideBody() and reflown by transition,
      // which was done on the first client script run (unconditionally)
      // Such logic is a hack and a bug in this context because it means the initial state of the SSR component
      // differs from its hydrated initial version: SSR version of Collapsible doesn't have aria-* attrs set
      // TODO Consider fixing Button/index.js:75 and checking the general logic of assigning the aria-hidden
      ariaToggle({
        expanderButtonElement,
        expanderBodyElement,
        visibility: isExpanded,
      })

      if (triggerHideBody && !isExpanded) {
        hideBody({
          expanderButtonElement,
          expanderBodyElement,
          transitionStyles: expanderBodyElementTransition,
          init: true,
        })
      }

      reducedCollection.push({
        button: expanderButtonElement,
        body: expanderBodyElement,
      })

      return reducedCollection
    },
    []
  )
}
