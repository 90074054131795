import logger from 'shared/services/logger'
import { ERROR_CLASSNAME } from 'views/consts'

export const CUSTOM_VALIDATION_MESSAGE_ATTR = 'data-custom-validity-message'
export const MIN_LENGTH_VALIDATION_MESSAGE_ATTR =
  'data-min-length-validity-message'
export const MAX_LENGTH_VALIDATION_MESSAGE_ATTR =
  'data-max-length-validity-message'
export const NOT_EQUAL_TO_FIELD_ID_ATTR = 'data-not-equal-to-field-id'
export const NOT_EQUAL_TO_VALIDATION_MESSAGE_ATTR =
  'data-not-equal-to-validity-message'
export const CUSTOM_PATTERN_MISMATCH_MESSAGE_ATTR =
  'data-custom-pattern-mismatch-message'

/**
 * formControlValidation
 * =====================
 *
 * Uses Constraint Validation DOM Properties https://www.w3schools.com/js/js_validation_api.asp
 *
 * ACTS ON: all nodes with an attribute data-clientside-hook~="formControlValidation"
 *          that share a <label> ancestor with a node with an attribute
 *          data-clientside-hook~="formControlValidation__error"
 *
 * Although the depth of the involving elements can be arbitrary enough to lead easily
 * to collisions it is pretty easy for the user to apply in a more restricted context
 * as something similar to the following :
 *
<label data-clientside-hook="floatingLabel">                                     <--- ROOTlabel
  <div className="whataver">
    <input
      data-clientside-hook="floatingLabel__formControl formControlValidation "   <--- FCV
      type="text" value=""
    />
  </div>
  <p data-clientside-hook="formControlValidation__error" />                      <--- FCVerr
</label>
 *
 * Whevever either a custom `validateSelf` event is triggered from the FCV node
 *          either a native blur event is triggered from the FCV node
 * this will imply:
 * - TO BE CONTINUED
 *
 */
export default (container = 'body') => {
  const FCV_SELECTOR = `${container} [data-clientside-hook~="formControlValidation"]`
  const FCVERR_SELECTOR = `${container} [data-clientside-hook~="formControlValidation__error"]`
  const ROOTLABEL_SELECTOR = `${container} [data-clientside-hook~="formControlContainer"]`

  const formControlElements = [...document.querySelectorAll(FCV_SELECTOR)]

  const setCustomValidity = (element, attr) => {
    if (element.hasAttribute(attr)) {
      element.setCustomValidity(element.getAttribute(attr))
    } else {
      logger.warn(
        `The data attribute ${attr} is missing. Please provide it to set the custom error message.`
      )
    }
  }

  const hasValidLength = (element, value) => {
    const minLength = element.hasAttribute('minlength')
      ? parseInt(element.getAttribute('minlength'), 10)
      : 0
    const maxLength = element.hasAttribute('maxlength')
      ? parseInt(element.getAttribute('maxlength'), 10)
      : Infinity

    if (value.length < minLength) {
      setCustomValidity(element, MIN_LENGTH_VALIDATION_MESSAGE_ATTR)

      return false
    } else if (value.length > maxLength) {
      setCustomValidity(element, MAX_LENGTH_VALIDATION_MESSAGE_ATTR)

      return false
    }

    return true
  }

  const isNotEqualToField = (element, value) => {
    if (value === '') {
      return true
    }

    const isNotEqualInput = formControlElements.find(
      input =>
        input.hasAttribute(NOT_EQUAL_TO_FIELD_ID_ATTR) &&
        input.hasAttribute(NOT_EQUAL_TO_VALIDATION_MESSAGE_ATTR) &&
        input.getAttribute(NOT_EQUAL_TO_FIELD_ID_ATTR) ===
          element.getAttribute('id')
    )

    if (isNotEqualInput) {
      const isNotEqualLabel = isNotEqualInput.closest(ROOTLABEL_SELECTOR)
      if (isNotEqualLabel) {
        const isNotEqualError = isNotEqualLabel.querySelector(FCVERR_SELECTOR)

        if (isNotEqualError) {
          if (value === isNotEqualInput.value.trim()) {
            isNotEqualInput.classList.add(ERROR_CLASSNAME)
            setCustomValidity(
              isNotEqualInput,
              NOT_EQUAL_TO_VALIDATION_MESSAGE_ATTR
            )
            isNotEqualError.textContent = isNotEqualInput.validationMessage
          } else {
            isNotEqualInput.classList.remove(ERROR_CLASSNAME)
            isNotEqualError.textContent = ''
          }
        }
      }

      return true
    }

    if (
      !element.hasAttribute(NOT_EQUAL_TO_FIELD_ID_ATTR) ||
      !element.hasAttribute(NOT_EQUAL_TO_VALIDATION_MESSAGE_ATTR)
    ) {
      return true
    }

    const searchedInput = formControlElements.find(
      input =>
        input.getAttribute('id') ===
        element.getAttribute(NOT_EQUAL_TO_FIELD_ID_ATTR)
    )

    if (!searchedInput) {
      return true
    }

    if (value === searchedInput.value.trim()) {
      setCustomValidity(element, NOT_EQUAL_TO_VALIDATION_MESSAGE_ATTR)

      return false
    }

    return true
  }

  const validateSelf = (element, elementErr) => {
    // remove any custom validity that may or may not be set previously
    element.setCustomValidity('')

    let isValid = true

    if (!element.hasAttribute('novalidate')) {
      const normalizedValue =
        element.type === 'password' ? element.value : element.value.trim()
      const hasWhiteSpace = normalizedValue !== element.value

      if (hasWhiteSpace) {
        element.value = normalizedValue
        isValid = hasValidLength(element, normalizedValue)
      }

      if (isValid) {
        isValid = isNotEqualToField(element, normalizedValue)
      }

      if (isValid) {
        isValid = element.validity.valid
      }
    }

    if (isValid) {
      element.classList.remove(ERROR_CLASSNAME)
      elementErr.textContent = ''
    } else {
      element.classList.add(ERROR_CLASSNAME)
      if (element.validity.patternMismatch) {
        setCustomValidity(element, CUSTOM_PATTERN_MISMATCH_MESSAGE_ATTR)
      } else {
        setCustomValidity(element, CUSTOM_VALIDATION_MESSAGE_ATTR)
      }
      elementErr.textContent = element.validationMessage
    }
  }

  return formControlElements
    .map(FCVelement => {
      const ROOTlabel = FCVelement.closest(ROOTLABEL_SELECTOR)
      if (ROOTlabel === null) {
        return null
      }

      const FCVerr = ROOTlabel.querySelector(FCVERR_SELECTOR)
      if (FCVerr === null) {
        return null
      }

      const runValidation = e => {
        // deactivate validation if the focus jumps from formFiled to submit
        // otherwise blur stops the click/submit event
        if (e.relatedTarget && e.relatedTarget.type === 'submit') {
          return true
        }
        validateSelf(FCVelement, FCVerr)
        return true
      }

      // `focusout` not supported by Safari for checkbox and radio inputs
      if (/radio|checkbox/.test(FCVelement?.type)) {
        FCVelement.addEventListener('click', runValidation)
      } else {
        FCVelement.addEventListener('focusout', runValidation)
      }

      FCVelement.addEventListener('validateSelf', () =>
        validateSelf(FCVelement, FCVerr)
      )
      return FCVelement
    })
    .filter(Boolean)
}
