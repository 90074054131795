import {
  HEADER_CART_ICON_CLICK,
  HEADER_CATEGORIES_HOVER,
  HEADER_LOGIN_ICON_CLICK,
  HEADER_MOBILE_BURGER_MENU_CLICK,
  HEADER_MY_APOTHEKE_ICON_CLICK,
  HEADER_NOTEPAD_ICON_CLICK,
  HEADER_NOTEPAD_LINK_CLICK,
  HEADER_PRODUCTS_HOVER,
  HEADER_REDEEM_PRESCRIPTION_CLICK,
  HEADER_SEARCH_BOX_CLICK,
  HEADER_SEARCH_BUTTON_CLICK,
  HEADER_SEARCH_ICON_CLICK,
  NEW_HEADER,
} from 'shared/experiments/consts'
import {
  activateExperiment,
  getExperiment,
} from 'shared/experiments/utils/experiments'
import { bindTrackingEvents } from 'shared/experiments/utils/tracking'
import store from 'shared/store'

const handleHeaderEvents = () => {
  // Desktop
  bindTrackingEvents([
    {
      selector: '.o-HeaderLarge #h-SearchBox [name="query-button"]',
      event: 'click',
      trackingEvent: HEADER_SEARCH_BUTTON_CLICK,
    },
    {
      selector: '.o-HeaderLarge #h-SearchBox #h-SearchBox__input',
      event: 'click',
      trackingEvent: HEADER_SEARCH_BOX_CLICK,
    },
    {
      selector: '.o-HeaderLarge #account-notepad-link',
      event: 'click',
      trackingEvent: HEADER_NOTEPAD_LINK_CLICK,
      // eslint-disable-next-line @typescript-eslint/ban-types
      handler: (event: Event, track: Function) => {
        event.stopPropagation()
        track()
      },
    },
    {
      selector: '.o-HeaderLarge [name="notepad"]',
      event: 'click',
      trackingEvent: HEADER_NOTEPAD_ICON_CLICK,
    },
    {
      selector: '.o-HeaderLarge [name="login-icon"]',
      event: 'click',
      trackingEvent: HEADER_LOGIN_ICON_CLICK,
    },
    {
      selector: '.o-HeaderLarge [name="my-apotheke-icon"]',
      event: 'click',
      trackingEvent: HEADER_MY_APOTHEKE_ICON_CLICK,
    },
    {
      selector: '.o-HeaderLarge [name="cart"]',
      event: 'click',
      trackingEvent: HEADER_CART_ICON_CLICK,
    },
    {
      selector: '.o-Menubar .o-Menubar__item--categories',
      event: 'mouseenter',
      trackingEvent: HEADER_CATEGORIES_HOVER,
    },
    {
      selector: '.o-header-menubar [name="menu-category-allProducts"]',
      event: 'mouseenter',
      trackingEvent: HEADER_PRODUCTS_HOVER,
    },
  ])

  // Mobile
  bindTrackingEvents([
    {
      selector: '.o-HeaderSmall .o-HeaderSmall__dummyInput',
      event: 'click',
      trackingEvent: HEADER_SEARCH_BOX_CLICK,
    },
    {
      selector: '.o-HeaderSmall [name="searchbox-mobile-search-icon"]',
      event: 'click',
      trackingEvent: HEADER_SEARCH_ICON_CLICK,
    },
    {
      selector: '.o-HeaderSmall [name="m-notepad-button"]',
      event: 'click',
      trackingEvent: HEADER_NOTEPAD_ICON_CLICK,
    },
    {
      selector: '.o-HeaderSmall [name="smallheader-menu-button"]',
      event: 'click',
      trackingEvent: HEADER_MOBILE_BURGER_MENU_CLICK,
    },
    {
      selector:
        '#h_OffCanvas [href*="rezeptbonus.htm"], #h_OffCanvas [href*="rezept-online-einloesen"]',
      event: 'click',
      trackingEvent: HEADER_REDEEM_PRESCRIPTION_CLICK,
    },
    {
      selector: '#h_OffCanvas [name="redeemRecipe"]',
      event: 'click',
      trackingEvent: HEADER_REDEEM_PRESCRIPTION_CLICK,
    },
    {
      selector: '.o-HeaderSmall [name="cart"]',
      event: 'click',
      trackingEvent: HEADER_CART_ICON_CLICK,
    },
  ])
}

export default async () => {
  const {
    pageProperties: { experiments },
  } = store.getState().publicRuntimeConfig
  const experiment = getExperiment(NEW_HEADER, experiments)

  if (experiment.isEnabled) {
    handleHeaderEvents()
    await activateExperiment(NEW_HEADER)
  }
}
