import { LOGO_APPLE_STORE, LOGO_GOOGLE_STORE } from 'shared/consts/appLogos'
import {
  TENANT_AT,
  TENANT_BE,
  TENANT_CH,
  TENANT_COM,
  TENANT_FR,
  TENANT_IT,
} from 'shared/consts/tenants'
import { type Tenant } from 'types/shopConfig'
import logoAppstoreFr from 'views/assets/images/logos/logo-appstore-fr.svg'
import logoAppstoreIt from 'views/assets/images/logos/logo-appstore-it.svg'
import logoAppstore from 'views/assets/images/logos/logo-appstore.svg'
import logoCapitalHorizontalFR from 'views/assets/images/logos/logo-capital-fr-horizontal.png'
import logoCustomerFriendlinessAward from 'views/assets/images/logos/logo-customer-friendliness-award.png'
import logoDownloadItaly from 'views/assets/images/logos/logo-download-italy.png'
import logoDownload from 'views/assets/images/logos/logo-download.svg'
import logoEaep from 'views/assets/images/logos/logo-eaep.svg'
import logoGoogleplayFr from 'views/assets/images/logos/logo-google-play-fr.svg'
import logoGoogleplayIt from 'views/assets/images/logos/logo-google-play-it.svg'
import logoGoogleplay from 'views/assets/images/logos/logo-google-play.svg'
import logoLogisticChampionAward from 'views/assets/images/logos/logo-logistic-champion-award.png'
import logoSecureGlobalSign from 'views/assets/images/logos/logo-secure-global-sign.png'
import logoSwissPostFr from 'views/assets/images/logos/logo-swiss-post-fr.svg'
import logoSwissPostDe from 'views/assets/images/logos/logo-swiss-post.svg'
import logoTrustedShop from 'views/assets/images/logos/logo-trusted.svg'
import logoTrustpilotBe from 'views/assets/images/logos/logo-trustpilot-be.png'
import logoTrustpilotIt from 'views/assets/images/logos/logo-trustpilot-it.png'
import logoTuvQR from 'views/assets/images/logos/logo-tuv-qr.png'
import logoTuv from 'views/assets/images/logos/logo-tuv.svg'
import logoVeterinaryItaly from 'views/assets/images/logos/logo-veterinary-italy.png'
import logoVeterinary from 'views/assets/images/logos/logo-veterinary.png'
import logoVSVCH from 'views/assets/images/logos/logo-vsv-ch.png'
import logoWebshopAward from 'views/assets/images/logos/logo-webshop-award.svg'

export const LOGOS = {
  trustedshops: logoTrustedShop,
  trustpilot: logoTrustpilotIt,
  trustpilotBe: logoTrustpilotBe,
  tuv: logoTuv,
  tuvqr: logoTuvQR,
  eaep: logoEaep,
  download: logoDownload,
  downloadItaly: logoDownloadItaly,
  webshopaward: logoWebshopAward,
  capital: logoCapitalHorizontalFR,
  vsv: logoVSVCH,
  veterinary: logoVeterinary,
  veterinaryItaly: logoVeterinaryItaly,
  swissPostDe: logoSwissPostDe,
  swissPostFr: logoSwissPostFr,
  secureGlobalSign: logoSecureGlobalSign,
  logisticChampionAward: logoLogisticChampionAward,
  customerFriendlinessAward: logoCustomerFriendlinessAward,
}

type LogoData = {
  src: string
  width: number
  height: number
}

export const APP_LOGOS_MAP: Record<
  Tenant,
  Record<string, LogoData> | undefined
> = {
  [TENANT_COM]: {
    [LOGO_APPLE_STORE]: { src: logoAppstore, width: 135, height: 40 },
    [LOGO_GOOGLE_STORE]: { src: logoGoogleplay, width: 135, height: 40 },
  },
  [TENANT_AT]: {
    [LOGO_APPLE_STORE]: { src: logoAppstore, width: 135, height: 40 },
    [LOGO_GOOGLE_STORE]: { src: logoGoogleplay, width: 135, height: 40 },
  },
  [TENANT_IT]: {
    [LOGO_APPLE_STORE]: { src: logoAppstoreIt, width: 120, height: 40 },
    [LOGO_GOOGLE_STORE]: { src: logoGoogleplayIt, width: 135, height: 40 },
  },
  [TENANT_FR]: {
    [LOGO_APPLE_STORE]: { src: logoAppstoreFr, width: 127, height: 40 },
    [LOGO_GOOGLE_STORE]: { src: logoGoogleplayFr, width: 135, height: 40 },
  },
  [TENANT_BE]: undefined,
  [TENANT_CH]: undefined,
}
