import { GENERAL_ERROR } from 'shared/consts/errorCodes'

import BaseError from './BaseError'

export default class APIError extends BaseError {
  constructor(message = null, ...codes) {
    let c = codes
    if (!c || c.length === 0) {
      c = [GENERAL_ERROR]
    }

    super(message, ...c)
    this.name = this.constructor.name
  }
}
