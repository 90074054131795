import store from 'shared/store'
import floatingLabel from 'views/assets/scripts/floatingLabel'
import formControlValidation from 'views/assets/scripts/formValidation/formControlValidation'
import modal from 'views/assets/scripts/modal'
import loadCatalogs from 'views/containers/browser/utils/loadCatalogs'

export default async () => {
  const container = document.getElementById('newsletterModalFormContainer')

  const {
    publicConfig: { locale },
  } = store.getPublicRuntimeConfig()
  const i18n = await loadCatalogs(locale)

  if (!container) return

  const { default: newsletterModalContainer } = await import(
    /* webpackChunkName: "NewsletterModalContainerWithHydration" */
    `./NewsletterModalContainerWithHydration`
  )

  await newsletterModalContainer({
    container,
    i18n,
  })

  floatingLabel()
  formControlValidation()
  modal()

  if (window?.dataLayer) {
    window.dataLayer.push({ 'event': 'newsletterSubscribe' })
  }
}
