export const COD = 'nativeCashOnDelivery' // Cash on delivery
export const CREDIT_CARD = 'scheme' // Credit card
export const DIRECT_DEBIT = 'nativeDirectDebit' // deprecated, will be removed
export const DIRECT_EBANKING = 'directEbanking' // SOFORT by Klarna
export const INVOICE = 'nativeInvoice' // invoice formerly known as deprecated formerly known as invoice
export const KLARNA_PAYLATER = 'klarna' // Formerly known as invoice
export const KLARNA_PAYNOW = 'klarna_paynow' // Formerly known as direct debit
export const PAYPAL = 'paypal' // PayPal
export const APPLE_PAY = 'applepay' // Apple Pay
export const PREPAYMENT = 'nativePrepayment' // Prepayment
export const SIMPLE_BANK_DEBIT = 'simpleBankDebit' // simpleBankDebit
export const NATIVE_CASH_ON_DELIVERY = 'nativeCashOnDelivery'
export const TWINT = 'twint'
export const RATEPAY = 'ratepay'
export const BANCONTACT = 'bcmc'
export const BANCONTACT_MOBILE = 'bcmc_mobile' //bancontact

// Prepayment: what we receive in qs-env as of Dec. 1st 2020. Differs from the contact.
export const PREPAYMENT_OLD = 'prepayment'
export const KEY_PREFIX = 'payments.name.'
export const INVOICE_OLD = 'invoice'

export const getPaymentsTypeKeyMapping = i18n =>
  new Map([
    [CREDIT_CARD, i18n._('payments.name.scheme')],
    [DIRECT_DEBIT, i18n._('payments.name.nativeDirectDebit')],
    [DIRECT_EBANKING, i18n._('payments.name.directEbanking')],
    [INVOICE, i18n._('payments.name.nativeInvoice')],
    [KLARNA_PAYLATER, i18n._('payments.name.klarna')],
    [KLARNA_PAYNOW, i18n._('payments.name.klarna_paynow')],
    [PAYPAL, i18n._('payments.name.paypal')],
    [APPLE_PAY, i18n._('payments.name.applepay')],
    [PREPAYMENT, i18n._('payments.name.nativePrepayment')],
    [PREPAYMENT_OLD, i18n._('payments.name.nativePrepayment')],
    [INVOICE_OLD, i18n._('payments.name.nativeInvoice')],
    [SIMPLE_BANK_DEBIT, 'Banklastschrift'], // FIXME: we have to add lokalise key payments.name.simpleBankDebit
    [NATIVE_CASH_ON_DELIVERY, i18n._('payments.name.nativeCashOnDelivery')],
    [TWINT, i18n._('payments.name.twint')],
    [RATEPAY, i18n._('payments.name.ratepay')],
    [BANCONTACT_MOBILE, i18n._('payments.name.bancontact')],
    [BANCONTACT, i18n._('payments.name.bancontact')],
  ])
