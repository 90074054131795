/**
 * asyncDataFetching
 * =================
 *
 * Allows to fill asynchronously the content of one or more tags.
 *
 * Each KEY_TAG must be attribute compliant with the following guidance:
 *
 * - mandatory attribute data-clientside-autofetch="true"
 *   | this has to be included and has to be valued as "true"
 *
 * - mandatory attribute data-clientside-api-call="__ENUM__"
 *   | the possible __ENUM__ values are:
 *   | - fetchLegalText:
 *   |   gets the legal text content (html)
 *   |
 *   | - fetchUserSessionData (SIDE EFFECTS)
 *   |   this is a bit different cause it retrieve a literal and based on the values found:
 *   |   > (using a loggedIn BOOLEAN value in the resp)
 *   |     if the user is logged in add a "user-logged-in" class to the body tag
 *   |     otherwise in add a "user-logged-out" class to the body tag
 *   |   > (using a numOfCartItems INTEGER in the response)
 *   |     in the first tag with a attribute data-clientside-hook which contains
 *   |     | "Menubar__cart-badge" sets the INTEGER as textContent
 *   |     in the first tag with a attribute data-clientside-hook which contains
 *   |     | "SmallHeader__cart-badge" sets the INTEGER as textContent
 *   |   > (using a firstName STRING in the response)
 *   |     in the first tag with a attribute data-clientside-hook which contains
 *   |     | "OffCanvasMenu__header-firstName" sets the STRING as textContent
 *
 * - optional attribute data-clientside-fallback="STRING" - DEFAULT = """
 *   | in case something goes wrong (network error, srv error, etc)
 *   | the STRING siven will be used as a fallback value
 *
 * - optional attribute data-clientside-set-content="BOOLEAN" - DEFAULT = "true"
 *   | by default the content retrieved will be set as the innerHTML of the tag itself,
 *   | but if this attribute is set to "false" then the content is simply returned and
 *   | not set as innerHTML of the tag
 *
 * @returns {Array}
 * containing promises (some of which may autofill html, those reative to
 * tags with data-clientside-set-content="false")
 * this array is mainly used for testing purposes, since the function is designed to autonomously
 *  check the DOM
 * and apply side effects using asynch requests responses.
 *
 * @example for KEY_TAG
<div
  data-clientside-autofetch="true"
  data-clientside-fallback="Oops, etwas ist schief gelaufen"
  data-clientside-api-call="fetchLegalText"
  data-clientside-set-content="true" <-- actually needed only if false
></div>
 *
 */

import { MULTIPLE_CHOICES, OK } from 'shared/consts/statusCodes'
import logger from 'shared/services/logger'
import store, { subscribe } from 'shared/store'
import {
  BIND_MODAL,
  CHARGE_SEARCH_PARAMS,
  INIT_CROSSSELL,
} from 'shared/store/ducks/events'
import assert from 'shared/utils/assert'
import buildClientAPI from 'views/providers/clientAPI'
import intersectionObserver from 'views/utils/intersectionObserver'

const adjustContainerHeight = container => {
  const contentWrapper = document.createElement('div')
  contentWrapper.classList.add('u-skeleton-transition')
  container.parentNode.insertBefore(contentWrapper, container)
  contentWrapper.style.minHeight = `${container.offsetHeight}px`
  contentWrapper.appendChild(container)

  requestAnimationFrame(() => {
    contentWrapper.classList.add('u-skeleton-transition--reset')
  })
}

// TODO: improve the event logix
export default () => {
  const { clientAPIService: bully, clientFockService: fock } = buildClientAPI()

  const CALLABLE_API = {
    bully,
    fock,
  }

  const elements = [
    ...document.querySelectorAll('[data-clientside-autofetch="true"]'),
  ]

  return elements.map(element => {
    const container = element
    const api = element.getAttribute('data-clientside-api')
    const apiCall = element.getAttribute('data-clientside-api-call')
    const fallback = element.getAttribute('data-clientside-fallback')
    const callOptions = element.getAttribute('data-clientside-call-options')
    const topic = element.getAttribute('data-clientside-publish-topic')
    const callOnTopic = element.getAttribute('data-clientside-call-on-topic')
    const lazyLoad = element.getAttribute('data-clientside-lazyload') === 'true'
    const options = callOptions ? JSON.parse(callOptions) : {}

    if (!api) {
      logger.error('asyncDataFetching: No api specified')
      return null
    }

    /**
     * since it is used as a boolean, wouldn't it be better (and less verbose)
     * to use simply hasAttribute ?
     * if yes remeber to find a difeerent name for it cause the default value
     * (false <=> no attribute)
     * should be the one that setsThe content. so for example could be 'data-clientside-call-api'
     */
    const setContentFlag = container.getAttribute('data-clientside-set-content')

    assert(apiCall, 'asyncDatafetching: "apiCall" not provided')

    const setContent = response => {
      if (response.status >= OK && response.status < MULTIPLE_CHOICES) {
        const { data } = response

        adjustContainerHeight(container)
        container.innerHTML = data

        if (topic) {
          ;[].concat(topic.split(',')).forEach(subject => {
            if (subject === BIND_MODAL || subject === CHARGE_SEARCH_PARAMS) {
              const { firstChild: { className } = {} } = container

              store.dispatch({
                type: subject,
                selector: className
                  ? `.${className.replace(/\s+/g, '.')}`
                  : null,
              })
            } else if (subject === INIT_CROSSSELL) {
              const { params: { wid } = {} } = options
              /**
               * CRO-2248. On some pages there are 2 crosssell widgets displayed.
               * They can contain similar products. To avoid repeating, we
               * return in the data of the top (first) widget the html attribute,
               * that contains all productIds of this widget. Then we save them in
               * sessionStorage. After that we send the request to the second widget
               * with the corresponding parameter to econda, that we read from the
               * sessionStorage.
               **/
              const parser = new DOMParser()
              const widgetContent = parser.parseFromString(data, 'text/html')
              const excludedProducts = widgetContent
                .querySelector(`[data-crosssell-id="${wid}"]`)
                .getAttribute('data-slider-excluded-products')

              if (excludedProducts) {
                sessionStorage.setItem('excludedProducts', excludedProducts)
              }

              store.dispatch({
                type: subject,
                selector: `[data-crosssell-id="${wid}"]`,
              })
            } else {
              store.dispatch({ type: subject })
            }
          })
        }
      } else {
        container.innerHTML = fallback
      }
    }

    const fetchData = opts => {
      if (setContentFlag === 'false') {
        return CALLABLE_API[api][apiCall](options)
      }

      return CALLABLE_API[api][apiCall](opts)
        .then(setContent)
        .catch(error => {
          logger.error(error)
          container.innerHTML = fallback
        })
    }

    const doCall = opts => {
      if (lazyLoad) {
        return intersectionObserver({
          callback: () => {
            fetchData(opts)
          },
          targetElements: container,
        })
      }

      return fetchData(opts)
    }

    if (callOnTopic) {
      return subscribe.after.once(callOnTopic, data =>
        doCall(Object.assign(options, data.userSession))
      )
    }

    return doCall(options)
  })
}
