/**
 * floatingLabel
 * =============
 *
 * Automatically extends what could be obtained with a :focus pseudoselector
 * adding/r
 *
 * The 'enabling' markup looks like the following one:

<div data-clientside-hook="floatingLabel many_others_values_if_needed">       <-- KEY_TAG
  <input type="text" data-clientside-hook="floatingLabel__formControl"/>      <-- CONTROL_TAG
</div>
 *
 * Basically for each KEY_TAG found it will:
 * - add the 'has-js' class
 * - on the first found CONTROL_TAG it will enable two listeners for:
 *    - focus: will add a 'has_focus' class on it
 *    - blur: if the content is empty it will remove the 'has_focus'
 *
 * @returns {Array}
 * of literal object containing
 * - formControlElement: a reference to the relative KEY_TAG
 * - controlElement: a reference to the relative CONTROL_TAG
 */

import { FOCUS_OR_VALUE_CLASSNAME, HAS_JS_CLASSNAME } from 'views/consts'
import detectAutofill from 'views/utils/detectAutofill'

const addFocus = e => e.target.classList.add(FOCUS_OR_VALUE_CLASSNAME)

const removeFocus = e => {
  // with the IF the coverage leaks the branch here
  if (e.target.value === '') {
    e.target.classList.remove(FOCUS_OR_VALUE_CLASSNAME)
  }
}

export default (container = 'body') => {
  const formControlElements = [
    ...document.querySelectorAll(
      `${container} [data-clientside-hook~="floatingLabel"]`
    ),
  ]

  const autoFocus = element => {
    if (element.value) {
      addFocus({ target: element })
    } else {
      const onPageShow = () => {
        if (element.value) {
          addFocus({ target: element })
        }

        window.removeEventListener('pageshow', onPageShow)
      }

      window.addEventListener('pageshow', onPageShow)
    }
  }

  return formControlElements
    .map(formControlElement => {
      formControlElement.classList.add(HAS_JS_CLASSNAME)
      /** @type HTMLElement|undefined */
      const controlElement = formControlElement.querySelector(
        '[data-clientside-hook~="floatingLabel__formControl"]'
      )
      if (!controlElement) {
        return false
      }

      /* The removeEventListener prevents memory leaks here, because the floatingLabel client script
      is potentially called multiple times. Also note that addFocus and removeFocus need to be defined
      outside of the default function or else their reference is changed. */
      controlElement.removeEventListener('focus', addFocus)
      controlElement.addEventListener('focus', addFocus)
      controlElement.removeEventListener('blur', removeFocus)
      controlElement.addEventListener('blur', removeFocus)

      detectAutofill(controlElement)
      autoFocus(controlElement)

      return {
        formControlElement,
        controlElement,
      }
    })
    .filter(Boolean)
}
