import { type AxiosResponse } from 'axios'

import { type ServiceParameters } from 'shared/services/api/commonTypes'
import { type ParcellabSecurityHashResponseV1 } from 'shared/services/api/models'
import assert from 'shared/utils/assert'

import BackendAPIService from './BackendAPIService'

const API_VERSION = 'v1'

interface IParcellabSecurityHashService {
  getParcellabSecurityHash: (
    orderNo: string
  ) => Promise<AxiosResponse<ParcellabSecurityHashResponseV1>>
}

class ParcellabSecurityHashService
  extends BackendAPIService
  implements IParcellabSecurityHashService
{
  constructor(props: ServiceParameters) {
    super(props)

    this.apiVersion = API_VERSION
  }

  public getParcellabSecurityHashUrl = (orderNo: string): string =>
    `/parcellab-security-hash/${this.apiVersion}/${this.tenantPath}/${orderNo}`

  public async getParcellabSecurityHash(
    orderNo: string
  ): Promise<AxiosResponse<ParcellabSecurityHashResponseV1>> {
    assert(
      orderNo,
      'ParcellabSecurityHashService.getParcellabSecurityHash: please provide an "orderId"'
    )
    const response: AxiosResponse<ParcellabSecurityHashResponseV1> =
      await this.get(this.getParcellabSecurityHashUrl(orderNo))
    this.logger.debug(`Get parcellabSecurityHash`, 'dataFetching')
    return response
  }
}

export default ParcellabSecurityHashService
