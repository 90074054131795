import { DEFAULT_LOCALE } from 'shared/consts'
import store from 'shared/store'
import { CONTACT_SELLER_MESSAGE_MODAL_UPDATE } from 'shared/store/ducks/events'
import loadCatalogs from 'views/containers/browser/utils/loadCatalogs'
import buildClientAPI from 'views/providers/clientAPI'

const SELECTOR_CONTACT_SELLER_MODAL =
  '[data-clientside-hook~="ContactSellerMessageModal"]'
const SELECTOR_CONTACT_SELLER_MODAL_BUTTON =
  '[data-clientside-hook~="openContactSellerMessageModal"]'
const SELECTOR_REQUEST_INVOICE_MODAL_BUTTON =
  '[data-clientside-hook~="openRequestInvoiceMessageModal"]'

export default () => {
  const buttonsContactSellerModalTrigger = document.querySelectorAll(
    SELECTOR_CONTACT_SELLER_MODAL_BUTTON
  )

  const buttonsRequestInvoiceModalTrigger = document.querySelectorAll(
    SELECTOR_REQUEST_INVOICE_MODAL_BUTTON
  )

  const mountComponent = async container => {
    const {
      publicConfig: { locale = DEFAULT_LOCALE },
      pageProperties: { orderThreadSubjects = {}, customerId, orderId },
    } = store.getPublicRuntimeConfig()

    // Launch file imports in parallel
    const i18n = await loadCatalogs(locale)
    const hydrationScript = import(
      /* webpackChunkName: "ContactSellerMessageModalContainerWithHydration", webpackPrefetch: true */
      './ContactSellerMessageModalContainerWithHydration'
    )

    const { default: hydrateComponent } = await hydrationScript
    await hydrateComponent({
      i18n,
      container,
      subjectsList: orderThreadSubjects.reasons,
      api: { miraklService: buildClientAPI().miraklService },
      customerId,
      orderId,
    })
  }

  const handleClick = event => {
    event.preventDefault()

    const sellerId = event.currentTarget.getAttribute('data-seller-id')
    const subOrderId = event.currentTarget.getAttribute('data-suborder-id')
    const orderId = event.currentTarget.getAttribute('data-order-id')
    const invoiceExist =
      event.currentTarget.getAttribute('data-invoice-exist') === 'true'
    const isCancellation =
      event.currentTarget.getAttribute('data-cancellation') === 'true'
    const code = event.currentTarget.getAttribute('data-selected-subject-code')

    store.dispatch({
      type: CONTACT_SELLER_MESSAGE_MODAL_UPDATE,
      props: {
        sellerId,
        subOrderId,
        orderId,
        invoiceExist,
        isCancellation,
        code,
      },
    })
  }

  const handleRequestInvoiceClick = async event => {
    event.preventDefault()

    const code = event.currentTarget.getAttribute('data-selected-subject-code')
    const orderId = event.currentTarget.getAttribute('data-order-id')
    const subOrderId = event.currentTarget.getAttribute('data-suborder-id')
    const sellerId = event.currentTarget.getAttribute('data-seller-id')

    store.dispatch({
      type: CONTACT_SELLER_MESSAGE_MODAL_UPDATE,
      props: { code, orderId, subOrderId, sellerId },
    })
  }

  mountComponent(document.querySelector(SELECTOR_CONTACT_SELLER_MODAL))

  if (buttonsContactSellerModalTrigger) {
    buttonsContactSellerModalTrigger.forEach(button => {
      button.addEventListener('click', handleClick)
      button.getAttribute('data-opened') === 'true' && button.click()
    })
  }

  // not sure why it is triggering separately
  // should be removed
  // not removing it as Roses may possibly work on it
  if (buttonsRequestInvoiceModalTrigger) {
    buttonsRequestInvoiceModalTrigger.forEach(button => {
      button.addEventListener('click', handleRequestInvoiceClick)
      button.getAttribute('data-opened') === 'true' && button.click()
    })
  }
}
