import classNames from 'clsx'

type ModalClassesProps = {
  className?: string
  size?: string
  light?: boolean
  fullScreen?: boolean
  initShow?: boolean
  open?: boolean | null | undefined
}

const useModalClasses = ({
  className,
  size,
  light,
  fullScreen,
  initShow,
  open,
}: ModalClassesProps) => {
  const dialogClassname = classNames('m-Modal__dialog', className, {
    'm-Modal__dialog--medium': size === 'medium',
    'm-Modal__dialog--large': size === 'large',
    'm-Modal__dialog--xlarge': size === 'xlarge',
    'm-Modal__dialog--light': light,
    'm-Modal__dialog--fullScreen': fullScreen,
  })

  const containerClassname = classNames('m-Modal', {
    'initShow': initShow || open,
  })

  return { dialogClassname, containerClassname }
}

export default useModalClasses
