import { subscribe } from 'shared/store'
import { CLOSE_OFFCANVAS } from 'shared/store/ducks/events'
import { BREAKPOINT_SMALL_TINY } from 'views/consts'
import toggleBoolStringAttr from 'views/utils/toggleBoolStringAttribute'

import { ARIA_HIDDEN, LAYOUT_SELECTOR, NO_OVERFLOW_CLASS } from './consts'

const LAYOUT_VISIBILTY_CLASS = 'has-visible-menu'

export default () => {
  const layoutElement = document.querySelector(LAYOUT_SELECTOR)
  const menuButtonElements = [
    ...document.querySelectorAll('[data-clientside-hook~="menu-button"]'),
  ]
  /** @type HTMLElement */
  const menuElement = document.querySelector('[data-clientside-hook~="menu"]')
  const headerElement = document.querySelector(
    '[data-clientside-hook~="header"]'
  )
  const { body } = document
  const html = document.querySelector('html')
  const menuMediaQuery = window.matchMedia(BREAKPOINT_SMALL_TINY)

  const isIphone = /iPhone/.test(navigator.userAgent)
  const getScrollYPosition = () =>
    window.pageYOffset || document.documentElement.scrollTop
  const scrollYAxisTo = yPosition => {
    document.documentElement.scrollTop = document.body.scrollTop = yPosition
  }
  let currentScrollYPosition = getScrollYPosition()

  const isVisible = () => {
    return (
      menuElement !== null && menuElement.getAttribute(ARIA_HIDDEN) !== 'true'
    )
  }

  /**
   * Hide menu, focus on element if set
   * @param element
   */
  const hide = (element = null) => {
    if (layoutElement) {
      body.classList.remove(NO_OVERFLOW_CLASS)
      if (isIphone) {
        html.classList.remove(NO_OVERFLOW_CLASS)
      }
      layoutElement.addEventListener(
        'transitionend',
        function removeBodyNoOverflowOnEnd() {
          layoutElement.removeEventListener(
            'transitionend',
            removeBodyNoOverflowOnEnd
          )
          if (isIphone) {
            // preserve the previous scroll position
            scrollYAxisTo(currentScrollYPosition)
          }
        }
      )
      layoutElement.classList.remove(LAYOUT_VISIBILTY_CLASS)
    }

    if (headerElement) {
      headerElement.classList.remove(LAYOUT_VISIBILTY_CLASS)
    }
    if (menuElement) {
      toggleBoolStringAttr(menuElement, ARIA_HIDDEN, true)
    }
    if (layoutElement) {
      layoutElement.removeEventListener('click', onOutsideMenuClickHandler)
    }

    if (element) {
      element.focus()
    }
  }

  /**
   * Hides menu when click outside of offcanvas is registered.
   * Afterwards it removes the handler
   */
  const onOutsideMenuClickHandler = event => {
    if (menuElement && !menuElement.contains(event.target) && isVisible()) {
      hide()
    }
  }

  /**
   * Show menu
   */
  const show = () => {
    if (layoutElement) {
      layoutElement.classList.add(LAYOUT_VISIBILTY_CLASS)
    }
    if (headerElement) {
      headerElement.classList.add(LAYOUT_VISIBILTY_CLASS)
    }
    if (isIphone) {
      currentScrollYPosition = getScrollYPosition()
    }
    body.classList.add(NO_OVERFLOW_CLASS)
    if (isIphone) {
      // prevent scrolling while off-canvas open
      // this line also causes scroll the page to top
      html.classList.add(NO_OVERFLOW_CLASS)
    }
    if (menuElement) {
      toggleBoolStringAttr(menuElement, ARIA_HIDDEN, false)
      menuElement.focus()
    }
    if (layoutElement) {
      layoutElement.addEventListener('click', onOutsideMenuClickHandler)
    }
  }

  /**
   * On viewport resize: hide menu if doesn't needed
   */
  menuMediaQuery.addEventListener('change', () => {
    menuMediaQuery.matches && hide()
  })

  subscribe.after(CLOSE_OFFCANVAS, () => {
    hide()
  })

  return {
    buttons: menuButtonElements
      ?.map(menuButtonElement => {
        menuButtonElement.addEventListener('click', event => {
          event.preventDefault()
          ;(isVisible() ? hide : show)(menuButtonElement)
        })

        return menuButtonElement
      })
      .filter(Boolean),
    menu: menuElement,
    layout: layoutElement,
    header: headerElement,
    consts: {
      MENU_VISIBILTY_ATTRIBUTE: ARIA_HIDDEN,
      LAYOUT_VISIBILTY_CLASS,
      BODY_NO_OVERLFOW_CLASS: NO_OVERFLOW_CLASS,
      BREAKPOINT_SMALL_TINY,
    },
    isVisible,
    show,
  }
}
