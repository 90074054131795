import {
  TENANT_BE,
  TENANT_BE_QS,
  TENANT_CH,
  TENANT_CH_QS,
  TENANT_FR,
  TENANT_FR_QS,
  TENANT_IT,
  TENANT_IT_QS,
} from 'shared/consts'
import store from 'shared/store'
import { NOW_FAQ_MODAL_CONTAINER_ID } from 'views/components/organisms/NowFaqModal/names'
import loadCatalogs from 'views/containers/browser/utils/loadCatalogs'

const hydrateAndMountComponent = ({
  wrappingElement,
  wrappedComponent,
  i18n,
}) => {
  wrappedComponent({
    i18n,
    container: wrappingElement,
  })
}

const mountNowFaqModalContainer = async () => {
  const modal = document.getElementById(NOW_FAQ_MODAL_CONTAINER_ID)

  if (!modal) {
    return
  }

  const {
    publicConfig: { locale, tenant },
  } = store.getPublicRuntimeConfig()

  // if the tenant is IT or FR then we don't need NOW modal
  // currently COM and AT offers NOW
  if ([TENANT_IT, TENANT_FR, TENANT_CH, TENANT_BE].includes(tenant)) {
    return
  }

  // we also need to restrict the calls made to NOW modal in QS environment
  if (
    [TENANT_IT_QS, TENANT_FR_QS, TENANT_CH_QS, TENANT_BE_QS].includes(tenant)
  ) {
    return
  }

  const i18n = await loadCatalogs(locale)

  const nowFaqModalContainer = await import(
    /* webpackChunkName: "NowFaqModalContainerWithHydration" */
    './NowFaqModalContainerWithHydration'
  )

  hydrateAndMountComponent({
    wrappingElement: modal,
    wrappedComponent: nowFaqModalContainer.default,
    i18n,
  })
}

export default mountNowFaqModalContainer
