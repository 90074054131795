import { runSafeEachScript } from 'views/assets/scripts/runScripts'

import 'views/containers/crosssell/CrosssellSliderCompact/CrosssellSliderCompact.scss'

/**
 * Conditionally load extra client scripts, needed for the injected slider
 * NB doesn't work with styles
 */
const initClientScripts = async () => {
  const [slider, registerSliderStretcher] = await Promise.all([
    import(/* webpackChunkName: "slider" */ 'views/assets/scripts/slider'),
    import(
      /* webpackChunkName: "registerSliderStretcher" */ 'views/assets/scripts/registerSliderStretcher'
    ),
  ])

  runSafeEachScript([slider.initSlider, registerSliderStretcher.default])
}

export default initClientScripts
