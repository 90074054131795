import { tns } from 'tiny-slider/src/tiny-slider'

import store, { subscribe } from 'shared/store'
import {
  ADAPT_SLIDER_ARROWS,
  changeSliderIndex,
  CREATE_SLIDER,
  createSliderAction,
  INIT_SLIDER,
  initSliderAction,
} from 'shared/store/ducks/events'
import debounce from 'views/utils/debounce'
import isIE from 'views/utils/isIE'

import {
  ADAPTABLE_ARROWS_HIDDEN_CLASS,
  ADAPTABLE_ARROWS_SELECTOR,
  SLIDER_HEADER_SELECTOR,
  SLIDER_IMAGE_SELECTOR,
  SLIDER_ITEMS_SELECTOR,
  SLIDER_NAV_NEXT_SELECTOR,
  SLIDER_NAV_PREV_SELECTOR,
  SLIDER_PREVENT_CLICK_SELECTOR,
  SLIDER_SELECTOR,
} from './MediaGallery/consts'

const GUTTER = 10
const BASE_SLIDER_OPTIONS = {
  autoplay: false,
  nav: true,
  gutter: GUTTER,
  loop: false,
  preventScrollOnTouch: 'auto', // to prevent console error, see: https://github.com/ganlanyuan/tiny-slider/issues/370#issuecomment-576718994
}
const SMALL_WIDTH = 720
const TINY_WIDTH = 480

const ADAPT_STRATEGY = {
  default: ({ sliderImage }) => {
    return sliderImage.scrollHeight
  },
  centeredBySlide: ({ sliderElement }) => {
    const slide = sliderElement.querySelector(SLIDER_ITEMS_SELECTOR)

    return slide.scrollHeight / 2
  },
  centeredByImage: ({ sliderImage }) => {
    return sliderImage.scrollHeight / 2
  },
}

/**
 * Shows navigation buttons for passed  slider
 *
 * @param {HTMLElement} slider
 */
const showSliderNavArrows = slider => {
  if (!slider) {
    return
  }

  ;[...slider.querySelectorAll(ADAPTABLE_ARROWS_SELECTOR)].forEach(navArrow => {
    navArrow.classList.remove(ADAPTABLE_ARROWS_HIDDEN_CLASS)
  })
}

const updateView = ({
  slider,
  sliderElement,
  sliderItems,
  slideBy,
  slideBySmall,
  slideByTiny,
}) => {
  const isSmall = window.matchMedia(`(max-width: ${SMALL_WIDTH}px)`).matches
  const isTiny = window.matchMedia(`(max-width: ${TINY_WIDTH}px)`).matches
  const isSmallOnly = isSmall && !isTiny

  if (
    !(
      (!isSmall && !Number.isInteger(parseFloat(slideBy))) ||
      (isSmallOnly && !Number.isInteger(parseFloat(slideBySmall))) ||
      (isTiny && !Number.isInteger(parseFloat(slideByTiny)))
    )
  ) {
    return
  }

  const {
    slideCount,
    displayIndex,
    slideBy: slideByRounded,
    slideItems,
  } = slider.getInfo()
  const lastIndex = displayIndex + slideByRounded - 1 === slideCount
  const sliderWidth = sliderElement.clientWidth
  const itemsWidth = slideItems[0].clientWidth * slideByRounded
  const slideOffset = sliderWidth - itemsWidth + GUTTER

  if (displayIndex === 1) {
    sliderItems.style.left = '0'
  } else if (lastIndex) {
    sliderItems.style.left = `${slideOffset}px`
  } else {
    sliderItems.style.left = `${slideOffset / 2}px`
  }
}

const adaptArrows = sliderElement => {
  const strategy =
    sliderElement.getAttribute('data-slider-adapt-strategy') || 'default'
  const adaptableArrows = [
    ...sliderElement.querySelectorAll(ADAPTABLE_ARROWS_SELECTOR),
  ]

  if (!adaptableArrows.length) {
    return
  }

  let offsetTop = 0
  const adapt = () => {
    adaptableArrows.forEach(adaptableArrow => {
      adaptableArrow.style.top = `${offsetTop}px`
    })
  }

  const sliderHeader = sliderElement.querySelector(SLIDER_HEADER_SELECTOR)
  if (sliderHeader) {
    offsetTop += sliderHeader.scrollHeight
  }

  const sliderImages = sliderElement.querySelectorAll(SLIDER_IMAGE_SELECTOR)

  let loadedImages = 0
  const updateOffsetAndAdapt = sliderImage => {
    loadedImages += 1

    if (loadedImages === sliderImages.length) {
      offsetTop += ADAPT_STRATEGY[strategy || 'default']({
        sliderImage,
        sliderElement,
      })
      adapt()
    }
  }

  showSliderNavArrows(sliderElement)

  sliderImages.forEach(sliderImage => {
    const img = sliderImage.querySelector('img')

    // seems that lazyloading doesn't work (as expected?) on IE as img.complete and/or img.onload
    // are executed much later, ie when slider is at the end. To counter that we trigger
    // arrows adjustment imidiately on IE and it doesn't cause regressions in some Slider stories.
    if (isIE()) {
      updateOffsetAndAdapt(sliderImage)
    }

    if (img.complete) {
      updateOffsetAndAdapt(sliderImage)
    } else {
      img.onload = () => updateOffsetAndAdapt(sliderImage)
    }
  })
}

export const createSlider = sliderElement => {
  const sliderItems = sliderElement.querySelector(SLIDER_ITEMS_SELECTOR)
  const buttonPrev = sliderElement.querySelector(SLIDER_NAV_PREV_SELECTOR)
  const buttonNext = sliderElement.querySelector(SLIDER_NAV_NEXT_SELECTOR)

  if (!sliderItems || !buttonPrev || !buttonNext) {
    return null
  }

  const slideBy = sliderElement.getAttribute('data-slider-slideby') || 1
  const slideBySmall =
    sliderElement.getAttribute('data-slider-slideby-small') || 1
  const slideByTiny =
    sliderElement.getAttribute('data-slider-slideby-tiny') || 1
  const axis = sliderElement.getAttribute('data-slider-axis') || 'horizontal'
  const params = {
    ...BASE_SLIDER_OPTIONS,
    container: sliderItems,
    prevButton: buttonPrev,
    nextButton: buttonNext,
    items: slideByTiny,
    slideBy: Math.floor(slideByTiny),
    axis,
    responsive: {
      [SMALL_WIDTH]: {
        items: slideBy,
        slideBy: Math.floor(slideBy),
      },
      [TINY_WIDTH]: {
        items: slideBySmall,
        slideBy: Math.floor(slideBySmall),
      },
    },
    // causes bug with overcloning/messing the nav items:
    // autoWidth: axis === 'horizontal'
    autoWidth: false,
  }

  // Needs to be created first, before querying thumbs below
  // (some additional thumbs clones from the source ones)
  const slider = tns(params)

  // Prevent opening image src
  Array.prototype.forEach.call(
    sliderItems.querySelectorAll(SLIDER_PREVENT_CLICK_SELECTOR),
    link => {
      link.addEventListener('click', e => {
        e.preventDefault()
      })
    }
  )

  if (!slider.id) {
    slider.id = Math.random().toString(36).substr(2, 9)
  }

  sliderElement.dataset.sliderId = slider.id

  if (slider.events) {
    slider.events.on('indexChanged', () => {
      store.dispatch(
        changeSliderIndex({
          containerId: slider.id,
        })
      )

      updateView({
        slider,
        sliderElement,
        sliderItems,
        slideBy,
        slideBySmall,
        slideByTiny,
      })
    })

    slider.events.on('newBreakpointStart', () => {
      slider.initSliderTransform?.()
    })
  }

  requestAnimationFrame(() => {
    store.dispatch(createSliderAction({ containerId: slider.id, slider }))
  })

  return slider
}

export const initSlider = () => {
  // TODO: hmm, it seems that this is not triggered correctly on IE11
  // (homepage xsell), might be worth investigating why.
  subscribe.after(INIT_SLIDER, () => {
    ;[...document.querySelectorAll(SLIDER_SELECTOR)].forEach(element => {
      const id = element.getAttribute('data-slider-id')

      if (id === null) {
        createSlider(element)
      }
    })
  })

  subscribe.after(CREATE_SLIDER, payload => {
    const { containerId } = payload
    const sliderElement = document.querySelector(
      `[data-slider-id~="${containerId}"]`
    )

    if (sliderElement) {
      showSliderNavArrows(sliderElement)
      adaptArrows(sliderElement)
      window.addEventListener(
        'resize',
        debounce(() => {
          adaptArrows(sliderElement)
        })
      )
    }
  })

  subscribe.after(ADAPT_SLIDER_ARROWS, ({ delay }) => {
    ;[...document.querySelectorAll(SLIDER_SELECTOR)].forEach(element => {
      setTimeout(() => adaptArrows(element), delay)
    })
  })

  const sliders = document.querySelectorAll(SLIDER_SELECTOR)
  if (sliders.length > 0) {
    store.dispatch(initSliderAction())
  }
}
