export const BUTTON_STYLE_TO_CLASS_NAME_MAP = {
  'buttonGhost': 'a-Button--ghost',
  'buttonPrimary': 'a-Button--primary',
  'buttonSecondary': 'a-Button--secondary',
  'disabled': 'a-Button--disabled',
  'buttonFilter': 'a-ButtonFilter',
  'buttonFilterHover': 'a-ButtonFilter--hover',
  'buttonHealth': 'a-ButtonHealth',
  'buttonHealthHover': 'a-ButtonHealth--hover',
  'buttonLink': 'a-ButtonLink',
  'buttonLinkHover': 'a-ButtonLink--hover',
  'productVariant': 'a-ProductVariant',
  'buttonAccepted': 'a-ButtonAccepted',
  'buttonLoad': 'a-ButtonLoad',
  'buttonTransparent': 'a-Button--transparent',
}

export const BUTTON_STYLES = Object.keys(BUTTON_STYLE_TO_CLASS_NAME_MAP)

export const BUTTON_ON_SUBMIT_ANIMATION_STYLES = {
  rebrandingFilledButton: 'rebrandingFilledButton',
  rebrandingBorderedButton: 'rebrandingBorderedButton',
  standard: 'standard',
}

export const SPINNER_ON_SUBMIT_COLOR = {
  primary: '#ed0334',
  white: '#fff',
}

export const SPINNER_ON_SUBMIT_REBRANDING_SIZE = '24px'
