// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'
import { OrderLineItem } from 'shared/services/api/models/orders/orderLineItem'

import {
  OrderLineItemsResponseItemV1,
  OrderLineItemV1,
} from './ordersResponseTypes'

export class OrderLineItemsResponseItem extends Model {
  public orderId: string
  public commercialOrderId: string
  public lineItems: OrderLineItem[]

  constructor(src: OrderLineItemsResponseItemV1) {
    super(src)

    this.orderId = src.orderId
    this.commercialOrderId = src.commercialOrderId
    this.lineItems = Array.isArray(src.lineItems)
      ? src.lineItems.map(
          (orderLineItem: OrderLineItemV1) => new OrderLineItem(orderLineItem)
        )
      : []
  }
}
