export const ABOUT = 'ABOUT'
export const ACCOUNTABILITY = 'ACCOUNTABILITY'
export const ADD_OFFER_TO_CART = 'ADD_OFFER_TO_CART'
export const AGB = 'AGB'
export const CART = 'CART'
export const CHECKOUT_ADDRESS_FORMAT = 'CHECKOUT_ADDRESS_FORMAT'
export const CHECKOUT_FORMAT = 'CHECKOUT_FORMAT'
export const CHECKOUT_PAYMENT_FORMAT = 'CHECKOUT_PAYMENT_FORMAT'
export const CHECKOUT_SHIPPING_FORMAT = 'CHECKOUT_SHIPPING_FORMAT'
export const CHECKOUT_VERIFICATION_FORMAT = 'CHECKOUT_VERIFICATION_FORMAT'
export const CHECKOUT_REDIRECT_RETURN_FORMAT = 'CHECKOUT_REDIRECT_RETURN_FORMAT'
export const CLIMATE_NEUTRAL_SHIPPING = 'CLIMATE_NEUTRAL_SHIPPING'
export const CMS_BRANDS = 'CMS_BRANDS'
export const CONFIRMATION = 'CONFIRMATION'
export const CONTACT = 'CONTACT'
export const DELIVERY_TRACKING = 'DELIVERY_TRACKING'
export const FAQ = 'FAQ'
export const FAQ_SHIPPING_DELIVERY = 'FAQ_SHIPPING_DELIVERY'
export const FAQ_RATING_GUIDELINES = 'FAQ_RATING_GUIDELINES'
export const FAQ_SEARCH = 'FAQ_SEARCH'
export const FAQ_SELLER_RATING_GUIDELINES = 'FAQ_SELLER_RATING_GUIDELINES'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORWARD = 'FORWARD'
export const GENERAL_TERMS = 'GENERAL_TERMS'
export const HOME_PAGE = 'HOME_PAGE'
export const IMPRINT = 'IMPRINT'
export const LEGAL_TEXT_FORMAT = 'LEGAL_TEXT_FORMAT'
export const LOGOUT = 'LOGOUT'
export const MYREDPOINTS = 'MYREDPOINTS'
export const MY_THERAPY_MS = 'MY_THERAPY_MS'
export const NEW_CART = 'NEW_CART'
export const NEW_CHECKOUT = 'NEW_CHECKOUT'
export const NEW_LOGIN_AND_REGISTER = 'NEW_LOGIN_AND_REGISTER'
export const NEW_CHECKOUT_ADDRESS = 'NEW_CHECKOUT_ADDRESS'
export const NEW_LOGIN = 'NEW_LOGIN'
export const NEW_MARKETPLACE_COLLECTION = 'NEW_MARKETPLACE_COLLECTION'
export const NEW_REGISTER = 'NEW_REGISTER'
export const PROFESSIONAL_ACCOUNT_REGISTER = 'PROFESSIONAL_ACCOUNT_REGISTER'
export const NEWSLETTER_FOCK_UNSUBSCRIBE = 'NEWSLETTER_FOCK_UNSUBSCRIBE'
export const ONLINE_DOCTOR_SERVICE = 'ONLINE_DOCTOR_SERVICE'
export const ORDER_RETURN_REQUEST = 'ORDER_RETURN_REQUEST'
export const ORDER_SHIPMENT_TRACKING = 'ORDER_SHIPMENT_TRACKING'
export const ORDERS_LIST = 'ORDERS_LIST'
export const ORDERS = 'ORDERS'
export const PREMIUM_DELIVERY = 'PREMIUM_DELIVERY'
export const PRIVACY_SECURITY_CREDIT_CHECK = 'PRIVACY_SECURITY_CREDIT_CHECK'
export const PRIVACY_SECURITY = 'PRIVACY_SECURITY'
export const PRODUCTS_BASE = 'PRODUCTS_BASE'
export const MEDCOACH = 'MEDCOACH'
export const REDEEM_RECIPE_FUNNEL = 'REDEEM_RECIPE_FUNNEL'
export const REDEEM_RECIPE = 'REDEEM_RECIPE'
export const REDEEM_E_RECIPE = 'REDEEM_E_RECIPE'
export const NFC_LANDING_PAGE = 'NFC_LANDING_PAGE'
export const RED_POINTS_PROMO_URL = 'RED_POINTS_PROMO_URL'
export const REDPOINTS = 'REDPOINTS'
export const SAE_ONLY_REDPOINTS = 'SAE_ONLY_REDPOINTS'
export const REGISTER = 'REGISTER'
export const SEARCH = 'SEARCH'
export const TRANSITION = 'TRANSITION'
export const MESSAGE_THREAD = 'MESSAGE_THREAD'
export const MESSAGE_THREAD_LIST = 'MESSAGE_THREAD_LIST'
export const MESSAGE_DOWNLOAD_ATTACHMENT = 'MESSAGE_DOWNLOAD_ATTACHMENT'
export const USER_ACCOUNT = 'USER_ACCOUNT'
export const NEW_USER_ACCOUNT = 'NEW_USER_ACCOUNT'
export const NOTEPAD = 'NOTEPAD'
export const ADD_TO_NOTEPAD = 'ADD_TO_NOTEPAD'
export const ODS_TO_CART = 'ODS_TO_CART'
export const ODS_VIDEO_CONSULTATION = 'ODS_VIDEO_CONSULTATION'
export const OFFERS = 'OFFERS'
export const ACCOUNT_REDPOINTS = 'ACCOUNT_REDPOINTS'
export const PRESCRIPTION_MANAGER = 'PRESCRIPTION_MANAGER'
export const SWITCH_PAGE = 'SWITCH_PAGE'
export const REFUND = 'REFUND'
export const INCIDENT = 'INCIDENT'
export const NEW_ORDERS_LIST = 'NEW_ORDERS_LIST'
export const NEW_NOTEPAD = 'NEW_NOTEPAD'
export const NEW_NEWSLETTER = 'NEW_NEWSLETTER'
export const NEW_ACCOUNT_NEWSLETTER = 'NEW_ACCOUNT_NEWSLETTER'
export const NEW_SHIPPING_ADDRESS = 'NEW_SHIPPING_ADDRESS'
export const SHIPPING_ADDRESS = 'SHIPPING_ADDRESS'
export const NEW_BILLING_ADDRESS = 'NEW_BILLING_ADDRESS'
export const BILLING_ADDRESS = 'BILLING_ADDRESS'
export const SELLER_RATING = 'SELLER_RATING'
export const SELLER_PRODUCTS = 'SELLER_PRODUCTS'
export const MEDICATION_PLAN = 'MEDICATION_PLAN'
export const USER_LOGIN_STATUS = 'USER_LOGIN_STATUS'
export const REDPOINTS_CONDITIONS = 'REDPOINTS_CONDITIONS'
export const RETURN_LABEL_CUSTOMER_CARE = 'RETURN_LABEL_CUSTOMER_CARE'
export const REDCOINS = 'REDCOINS'
export const REDCOINS_ACCOUNT = 'REDCOINS_ACCOUNT'
export const WELCOME = 'WELCOME'
export const NEW_CUSTOMER_VOUCHER = 'NEW_CUSTOMER_VOUCHER'
