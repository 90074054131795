import Cookies from 'js-cookie'

import {
  AD_SERVER_CAMPAIGN_COOKIE_NAME,
  AD_SERVER_METADATA_DATA_ATTR,
  AD_SERVER_TRACKING_URLS_COOKIE_PREFIX,
  AD_SERVER_USER_ID_COOKIE,
} from 'shared/consts/adServer'
import store, { subscribe } from 'shared/store'
import { INIT_CROSSSELL } from 'shared/store/ducks/events'
import {
  type ClientCookieOptions,
  extractKevelIdsAndClickUrlFromMetadataContent,
  getTargetingKeywordsFromSearchParams,
  isAdServerEnabled,
} from 'shared/utils/adServerUtils'
import { getTenantWithEnv } from 'shared/utils/getTenantWithEnv'
import { isEmpty } from 'shared/utils/objectUtils'
import type { AdServerMetadataCookieWithPzn } from 'types/adServerTypes'
import { AD_BANNER_CAMPAIGN_CODE_SEARCH_PARAM } from 'views/consts'
import {
  attachCloseAdBannerEvents,
  initAdServer,
  pushSetupPreloadedSlotsCommand,
} from 'views/utils/adServerUtils'

interface CampaignCookie {
  [key: string]: number
}

const CAMPAIGN_MAX_AGE_DAYS = 1

const setupCrossSellAds = (slider: HTMLElement) => {
  let moreLink: Element | null

  const sponsoredItems: NodeListOf<HTMLElement> | undefined =
    slider?.querySelectorAll(`[${AD_SERVER_METADATA_DATA_ATTR}]`)

  if (slider && sponsoredItems?.length) {
    const wid: string | undefined | null =
      slider.getAttribute('data-crosssell-id')
    moreLink = slider.querySelector(
      '[data-clientside-hook~="adServerSliderMoreLink"]'
    )

    const cookieValue: AdServerMetadataCookieWithPzn = Object.fromEntries(
      [...sponsoredItems]
        .map(slot => ({
          content: extractKevelIdsAndClickUrlFromMetadataContent(
            JSON.parse(slot?.getAttribute(AD_SERVER_METADATA_DATA_ATTR) || '{}')
          ),
          pzn: slot?.getAttribute('data-product-id'),
        }))
        .map(meta => [meta.pzn, meta.content])
    )

    const hasCookie = !isEmpty(cookieValue)

    if (hasCookie && moreLink && wid) {
      const {
        publicConfig: { mountPoints },
      } = store.getPublicRuntimeConfig()
      const cookieOptions: ClientCookieOptions = {
        // 30 mins
        expires: 30 / 1440,
        path: mountPoints.SEARCH as string,
        httpOnly: false,
      }

      moreLink.addEventListener('click', () => {
        if (hasCookie) {
          Cookies.withConverter<never>({
            write: (value: string) => value,
          }).set(
            `${AD_SERVER_TRACKING_URLS_COOKIE_PREFIX}${wid}`,
            JSON.stringify(cookieValue),
            cookieOptions
          )
        }
      })
    }

    pushSetupPreloadedSlotsCommand(slider)
  }
}

const getAndSetUpdatedCampaigns = (
  campaigns: CampaignCookie
): CampaignCookie => {
  const updatedCampaigns = { ...campaigns }
  const today = new Date()
  const campaignCodes: string[] = Object.keys(campaigns) as string[]

  campaignCodes.forEach((campaignCode: string) => {
    if (campaigns[campaignCode] < today.getTime()) {
      delete updatedCampaigns[campaignCode]
    }
  })

  Cookies.withConverter<never>({
    write: (value: string) => value,
  }).set(AD_SERVER_CAMPAIGN_COOKIE_NAME, JSON.stringify(updatedCampaigns), {
    expires: 365,
  })

  return updatedCampaigns
}

const enableCampaign = (
  campaigns: CampaignCookie,
  campaignCode: string
): void => {
  const updatedCampaigns = { ...campaigns }
  const expires = new Date()
  expires.setDate(expires.getDate() + CAMPAIGN_MAX_AGE_DAYS)

  updatedCampaigns[campaignCode] = expires.getTime()

  Cookies.withConverter<never>({
    write: (value: string) => value,
  }).set(AD_SERVER_CAMPAIGN_COOKIE_NAME, JSON.stringify(updatedCampaigns), {
    expires: 365,
  })
}

const getEnabledCampaigns = (): string[] => {
  const campaignCookie = Cookies.get(AD_SERVER_CAMPAIGN_COOKIE_NAME)
  const pageUrl = new URL(window.location.href)
  const campaignCode =
    pageUrl.searchParams.get(AD_BANNER_CAMPAIGN_CODE_SEARCH_PARAM) || ''
  let campaigns = {}

  try {
    campaigns = campaignCookie ? JSON.parse(campaignCookie) : {}
    // eslint-disable-next-line no-empty
  } catch (_) {}

  if (Object.keys(campaigns).length) {
    campaigns = getAndSetUpdatedCampaigns(campaigns)
  }

  if (campaignCode) {
    enableCampaign(campaigns, campaignCode)

    return [...new Set([...Object.keys(campaigns), campaignCode])]
  }

  return [...new Set([...Object.keys(campaigns)])]
}

export default () => {
  const {
    publicConfig: { tenant, language },
    pageProperties: { experiments, pageType, coreShopEnvironment },
  } = store.getPublicRuntimeConfig() || {}

  if (isAdServerEnabled(experiments)) {
    const keywords = window._targeting?.keywords || []
    const context = window._targeting?.context || {}

    const loggedIn = Boolean(Cookies.get(AD_SERVER_USER_ID_COOKIE))

    window._targeting = {
      context: {
        ...context,
        pageType,
        language,
        loggedIn,
        campaignCodes: getEnabledCampaigns(),
        tenant: getTenantWithEnv(tenant, coreShopEnvironment as string),
      },
      keywords: [
        ...keywords,
        ...getTargetingKeywordsFromSearchParams(window.location.search),
      ],
    }

    subscribe.after(INIT_CROSSSELL, payload => {
      // @ts-ignore
      const { selector } = payload

      const slider = document.querySelector(selector)

      setupCrossSellAds(slider)
    })

    initAdServer()

    attachCloseAdBannerEvents()
  }
}
