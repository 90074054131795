// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

import { IncidentResponse, IncidentResponseV1 } from './IncidentResponse'
import { NewMessageResponse, NewMessageResponseV1 } from './NewMessageResponse'

export interface NewThreadResponseV1 {
  threadResponse: NewMessageResponseV1
  incidentResponse: IncidentResponseV1
}

export class NewThreadResponse extends Model {
  public readonly threadResponse: NewMessageResponse
  public readonly incidentResponse: IncidentResponse

  constructor(src: NewThreadResponseV1) {
    super(src)
    this.threadResponse = new NewMessageResponse(src.threadResponse)
    if (src.incidentResponse) {
      this.incidentResponse = new IncidentResponse(src.incidentResponse)
    }
  }
}
