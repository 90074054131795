// @ts-nocheck
/**
 * Functions exported from this file return user–facing URLs on which
 * fock application is expected to be mounted on.
 * a.k.a. “external routes” or “mount points”
 *
 * Do not confuse with server/utils/routes.
 */
import { join as joinPath } from 'path'
import { format as formatUrl, parse as parseUrl } from 'url'

import { stringify } from 'query-string'
import { uriQueryInDoubleQuotedAttr } from 'xss-filters'

import {
  LOGIN_OTP_STATE,
  NEW_CUSTOMER_VOUCHER,
  TENANT_CH,
  TRACKING_DATA_KEYS,
  USER_LOGIN_STATUS,
} from 'shared/consts'
import {
  ABOUT,
  ACCOUNT_REDPOINTS,
  ACCOUNTABILITY,
  ADD_OFFER_TO_CART,
  AGB,
  BILLING_ADDRESS,
  CART,
  CHECKOUT_ADDRESS_FORMAT,
  CHECKOUT_FORMAT,
  CHECKOUT_PAYMENT_FORMAT,
  CHECKOUT_REDIRECT_RETURN_FORMAT,
  CHECKOUT_SHIPPING_FORMAT,
  CHECKOUT_VERIFICATION_FORMAT,
  CLIMATE_NEUTRAL_SHIPPING,
  CMS_BRANDS,
  CONFIRMATION,
  CONTACT,
  DELIVERY_TRACKING,
  FAQ,
  FAQ_RATING_GUIDELINES,
  FAQ_SEARCH,
  FAQ_SELLER_RATING_GUIDELINES,
  FAQ_SHIPPING_DELIVERY,
  FORGOT_PASSWORD,
  FORWARD,
  GENERAL_TERMS,
  HOME_PAGE,
  IMPRINT,
  INCIDENT,
  LEGAL_TEXT_FORMAT,
  LOGOUT,
  MEDCOACH,
  MEDICATION_PLAN,
  MESSAGE_THREAD,
  MESSAGE_THREAD_LIST,
  MY_THERAPY_MS,
  MYREDPOINTS,
  NEW_ACCOUNT_NEWSLETTER,
  NEW_BILLING_ADDRESS,
  NEW_CART,
  NEW_CHECKOUT,
  NEW_CHECKOUT_ADDRESS,
  NEW_LOGIN,
  NEW_LOGIN_AND_REGISTER,
  NEW_MARKETPLACE_COLLECTION,
  NEW_NEWSLETTER,
  NEW_NOTEPAD,
  NEW_ORDERS_LIST,
  NEW_REGISTER,
  NEW_SHIPPING_ADDRESS,
  NEW_USER_ACCOUNT,
  NEWSLETTER_FOCK_UNSUBSCRIBE,
  NFC_LANDING_PAGE,
  NOTEPAD,
  ODS_VIDEO_CONSULTATION,
  OFFERS,
  ONLINE_DOCTOR_SERVICE,
  ORDER_RETURN_REQUEST,
  ORDER_SHIPMENT_TRACKING,
  ORDERS,
  ORDERS_LIST,
  PREMIUM_DELIVERY,
  PRESCRIPTION_MANAGER,
  PRIVACY_SECURITY,
  PRIVACY_SECURITY_CREDIT_CHECK,
  PRODUCTS_BASE,
  PROFESSIONAL_ACCOUNT_REGISTER,
  RED_POINTS_PROMO_URL,
  REDCOINS,
  REDCOINS_ACCOUNT,
  REDEEM_E_RECIPE,
  REDEEM_RECIPE,
  REDEEM_RECIPE_FUNNEL,
  REDPOINTS,
  REFUND,
  SAE_ONLY_REDPOINTS,
  SEARCH,
  SELLER_PRODUCTS,
  SELLER_RATING,
  SHIPPING_ADDRESS,
  SWITCH_PAGE,
  TRANSITION,
  USER_ACCOUNT,
  WELCOME,
} from 'shared/consts/mountPoints'
import { ML_TENANTS } from 'shared/consts/multilanguage'
import { isSaeOnly } from 'shared/experiments/utils/featureFlags'
import { isToggleEnabled, TOGGLES_KEYS } from 'shared/featureToggles'
import { LetterboxDocument } from 'shared/services/api/models/letterbox'
import { OrderResponse } from 'shared/services/api/models/orders/orderResponse'
import MountPointsBase, {
  QueryParamsObject,
} from 'shared/utils/mountPointsBase'
import { pick } from 'shared/utils/objectUtils'
import { removeNonLatinLetters } from 'shared/utils/stringUtils'
import { normalizeUrl } from 'shared/utils/url'
import { TargetPageParams } from 'types/targetPage.d'
import {
  urlSpecialCharRegex,
  urlWhitespaceDashRegex,
} from 'views/consts/validation'

class MountPoints extends MountPointsBase {
  getAbout(): string {
    return this.getUrl(ABOUT)
  }

  getAccountability(): string {
    return this.getUrl(ACCOUNTABILITY)
  }

  /**
   * Convenient function that handles routes in a feature toggle dependency:
   * - legacy add to cart route (for old non offer based products). Default
   *
   * - adding offer based product to cart
   *   rendering the "cart intermediary page" where product that was added to cart is shown to user
   *   @see cartIntermediateGetHandler
   */
  getAddToCart(params: Record<string, unknown> = {}): string {
    return this.setQueryParams(this.getUrl(ADD_OFFER_TO_CART), params)
  }

  getAGB(): string {
    return this.getUrl(AGB)
  }

  addCheckoutRoutingPrefix(s: string, isWebview: boolean): string[] {
    return [
      s,
      ['', `${isWebview ? 'webview/' : ''}new-checkout`]
        .concat(s.split('/').splice(4))
        .join('/'),
      ['', `${isWebview ? 'webview/' : ''}new_checkout`]
        .concat(s.split('/').splice(4))
        .join('/'),
    ]
  }

  getCheckout(): string {
    return this.applyCommonRouteParams(this.getUrl(CHECKOUT_FORMAT))
  }

  getCheckoutAddress(): string {
    return this.applyCommonRouteParams(this.getUrl(CHECKOUT_ADDRESS_FORMAT))
  }

  getNewCheckoutAddress(): string {
    return this.applyCommonRouteParams(this.getUrl(NEW_CHECKOUT_ADDRESS))
  }

  getCheckoutPayment(): string {
    return this.applyCommonRouteParams(this.getUrl(CHECKOUT_PAYMENT_FORMAT))
  }

  getCheckoutShipping(): string {
    return this.applyCommonRouteParams(this.getUrl(CHECKOUT_SHIPPING_FORMAT))
  }

  getCheckoutVerification(): string {
    return this.applyCommonRouteParams(
      this.getUrl(CHECKOUT_VERIFICATION_FORMAT)
    )
  }

  getCheckoutRedirectReturn(): string {
    return this.applyCommonRouteParams(
      this.getUrl(CHECKOUT_REDIRECT_RETURN_FORMAT)
    )
  }

  getClimateNeutralShippingUrl(): string {
    return this.getUrl(CLIMATE_NEUTRAL_SHIPPING)
  }

  getCmsAllProductsFromCategory(path: string): string {
    const cmsAllProductsFromCategoryPath = ML_TENANTS.includes(this.tenant)
      ? `/${this.language}${path}`
      : path

    return normalizeUrl(
      joinPath(cmsAllProductsFromCategoryPath, 'all-from-category')
    )
  }

  getCmsBrands(): string {
    return this.getUrl(CMS_BRANDS)
  }

  getConfirmation(orderNumber: string): string {
    return this.getUrl(CONFIRMATION).replace(':orderNumber', orderNumber)
  }

  getContact(): string {
    return this.getUrl(CONTACT)
  }

  getContent(name: string): string {
    return normalizeUrl(`/${name}/`)
  }

  getDeliveryTracking(params: QueryParamsObject): string {
    return this.setQueryParams(this.getUrl(DELIVERY_TRACKING), params)
  }

  getFaq(): string {
    return this.getUrl(FAQ)
  }

  getFaqShippingDelivery(): string {
    return this.getUrl(FAQ_SHIPPING_DELIVERY)
  }

  getFaqRatingGuidelines(): string {
    return this.getUrl(FAQ_RATING_GUIDELINES)
  }

  getFaqSearch(): string {
    return this.getUrl(FAQ_SEARCH)
  }

  getFaqSellerRatingGuidelines(): string {
    return this.getUrl(FAQ_SELLER_RATING_GUIDELINES)
  }

  getForgotPassword(): string {
    return this.getUrl(FORGOT_PASSWORD)
  }

  getForward(pathName: string): string {
    return this.getUrl(FORWARD).replace(':pathName', pathName)
  }

  getGeneralTerms(): string {
    return this.getUrl(GENERAL_TERMS)
  }

  getHomePage(): string {
    return this.getUrl(HOME_PAGE)
  }

  getImprint(): string {
    return this.getUrl(IMPRINT)
  }

  getLegalText(): string {
    return this.applyCommonRouteParams(this.getUrl(LEGAL_TEXT_FORMAT))
  }

  getLogout(): string {
    return this.getUrl(LOGOUT)
  }

  getLoginAndRegister(targetPageParams?: TargetPageParams): string {
    const url = this.getUrl(NEW_LOGIN_AND_REGISTER)

    return this.setQueryParams(url, targetPageParams)
  }

  getMedicationPlan() {
    return this.getUrl(MEDICATION_PLAN)
  }

  getOnlineDoctorService(id: string | number | null): string {
    return this.setQueryParams(this.getUrl(ONLINE_DOCTOR_SERVICE), {
      id: id ? String(id) : undefined,
    })
  }

  getOrderDetails(baseUrl: string, order: OrderResponse): string {
    return `${baseUrl}${this.applyCommonRouteParams(this.getUrl(ORDERS))}${
      order.orderMasterData.orderNumber
    }/${order.subOrders[0].subOrderId}`
  }

  getOrderReturnRequest(): string {
    return this.getUrl(ORDER_RETURN_REQUEST)
  }

  getOrdersDetails(
    {
      orderNumber,
      subOrderNumber,
    }: { orderNumber: string; subOrderNumber: string } = {
      orderNumber: '',
      subOrderNumber: '',
    }
  ): string {
    return `${this.getOrdersList()}${orderNumber}/${subOrderNumber}/`
  }

  getReturnLabelConfirmation({
    orderNumber,
    subOrderNumber,
  }: {
    orderNumber: string
    subOrderNumber: string
  }) {
    return `${this.getOrdersDetails({
      orderNumber,
      subOrderNumber,
    })}return-label/confirmation`
  }

  getReturnLabelCustomerCarePage({
    orderNumber,
    subOrderNumber,
  }: {
    orderNumber: string
    subOrderNumber: string
  }) {
    return `${this.getOrdersDetails({
      orderNumber,
      subOrderNumber,
    })}return-label/customer-care`
  }

  getOrderShipmentTracking(): string {
    return this.getUrl(ORDER_SHIPMENT_TRACKING)
  }

  getOrdersList(): string {
    return isToggleEnabled(
      this.featureToggles,
      TOGGLES_KEYS.NEW_MY_ACCOUNT_ORDERS
    )
      ? this.getUrl(NEW_ORDERS_LIST)
      : this.getUrl(ORDERS_LIST)
  }

  getOrdersOverview(orderNumber = ''): string {
    return `${this.getOrdersList()}${orderNumber}/`
  }

  getReturnLabelDocumentForOrder({
    orderNumber,
    subOrderNumber,
  }: {
    orderNumber: string
    subOrderNumber: string
  }): string {
    return `${this.getOrdersDetails({
      orderNumber,
      subOrderNumber,
    })}return-label/document`
  }

  getPremiumDelivery(): string {
    return this.getUrl(PREMIUM_DELIVERY)
  }

  getPrivacySecurityCreditCheck(): string {
    return this.getUrl(PRIVACY_SECURITY_CREDIT_CHECK)
  }

  getPrivacySecurity(): string {
    return this.getUrl(PRIVACY_SECURITY)
  }

  getProductReviews(url?: string): string {
    const hash = '#user-reviews-section'

    if (!url) {
      return hash
    }

    const urlObject = parseUrl(url)

    const [base, productId, ...pathParts] = urlObject.pathname
      .replace(/^\/+/, '')
      .split('/')

    urlObject.pathname = [base, productId, ...pathParts].join('/')

    urlObject.hash = hash

    // Remove origin
    if (process.env.APP_ENV !== 'production') {
      urlObject.protocol = ''
      urlObject.slashes = false
      urlObject.host = ''
      urlObject.hostname = ''
    }

    return normalizeUrl(formatUrl(urlObject))
  }

  getProduct(
    id: number | string,
    targetPageCommand?: string = undefined
  ): string {
    const url = normalizeUrl(joinPath(this.getUrl(PRODUCTS_BASE), String(id)))
    return this.setQueryParams(url, { targetPageCommand })
  }

  getProductUrl(displayName: string, id: number | string): string | null {
    return normalizeUrl(
      joinPath(
        this.getUrl(PRODUCTS_BASE),
        String(id),
        removeNonLatinLetters(displayName)
          .toLowerCase()
          .trim()
          .replace(urlSpecialCharRegex, '')
          .replace(urlWhitespaceDashRegex, '-')
          .concat('.htm')
      )
    )
  }

  getRedPointsPromoUrl(): string {
    return this.getUrl(RED_POINTS_PROMO_URL)
  }

  getRegister(targetPageParams?: TargetPageParams): string {
    const url = this.getUrl(NEW_REGISTER)

    return this.setQueryParams(url, targetPageParams)
  }

  getProfessionalAccountRegister(targetPageParams?: TargetPageParams): string {
    const url = this.getUrl(PROFESSIONAL_ACCOUNT_REGISTER)

    return this.setQueryParams(url, targetPageParams)
  }

  getSearch({
    query,
    escapeQuery = true,
    internal = true,
    banner = false,
    channel = null,
    userToken = null,
    trackingData = null,
    wid = null,
  }: {
    query: string
    escapeQuery?: boolean
    internal?: boolean
    banner?: boolean
    channel?: string | null
    userToken?: string | null
    trackingData?: Record<
      typeof TRACKING_DATA_KEYS[number],
      string | number | undefined
    > | null
    wid?: string | null
  }): string {
    let params: Record<string, unknown> = {}

    if (internal) {
      params.i = 1
    } else if (banner) {
      params.b = 1
    }

    if (query != null) {
      params.q = query
    }

    if (channel) {
      params.searchChannel = channel
    }

    if (userToken) {
      params.userToken = userToken
    }

    if (trackingData) {
      params = { ...params, ...pick(trackingData, TRACKING_DATA_KEYS) }
    }

    if (wid) {
      params.wid = wid
    }

    let route = `${this.getUrl(SEARCH)}?${stringify(params)}`

    if (!escapeQuery) {
      route = route.replace(/(\?|&)q=[^&]*/, `$1q=${query}`)
    }

    return route
  }

  getSearchProductsList(
    products: { id: string }[],
    options?: { wid?: string }
  ): string {
    const productIds = products.map(({ id }) => String(id)).join(' ')
    return this.getSearch({ query: productIds, internal: false, ...options })
  }

  getTransition(targetUrl: string): string {
    const queryString = `?${stringify({ target: targetUrl })}`
    return `${this.getUrl(TRANSITION)}${queryString}`
  }

  getNewsletter(): string {
    return this.getUrl(NEW_NEWSLETTER)
  }

  getRefund(orderId: string, subOrderId: string): string {
    return `${this.getUrl(REFUND)}${orderId}/${subOrderId}/`
  }

  getIncident(orderId: string, subOrderId: string): string {
    return `${this.getUrl(INCIDENT)}${orderId}/${subOrderId}/`
  }

  getIncidentConfirmation(
    orderId: string,
    subOrderId: string,
    threadId: string
  ): string {
    return `${this.getIncident(orderId, subOrderId)}bestaetigung/${threadId}`
  }

  getRedpoints(): string {
    const isChTenant = this.tenant === TENANT_CH
    let pageUrl = this.getUrl(
      isSaeOnly(this.experiments, this.tenant) ? SAE_ONLY_REDPOINTS : REDPOINTS
    )

    if (isChTenant) {
      pageUrl = this.getUrl(REDCOINS)
    }

    return pageUrl
  }

  getODSVideoConsultation(): string {
    return this.getUrl(ODS_VIDEO_CONSULTATION)
  }

  getNewsletterUnsubscribe(): string {
    return this.getUrl(NEWSLETTER_FOCK_UNSUBSCRIBE)
  }

  getLogin(
    targetPageParams?: TargetPageParams,
    state: LOGIN_OTP_STATE = LOGIN_OTP_STATE.LOGIN,
    email: string = undefined
  ): string {
    const url = this.getUrl(NEW_LOGIN)
    const urlWithTargetPageParams = this.setQueryParams(url, targetPageParams)
    return this.setQueryParams(urlWithTargetPageParams, { state, email })
  }

  getNewCheckout(): string {
    return this.applyCommonRouteParams(this.getUrl(NEW_CHECKOUT))
  }

  getUserAccount(): string {
    return isToggleEnabled(this.featureToggles, TOGGLES_KEYS.NEW_MY_ACCOUNT)
      ? this.applyCommonRouteParams(this.getUrl(NEW_USER_ACCOUNT))
      : this.getUrl(USER_ACCOUNT)
  }

  getMessageThread(threadId = '') {
    return `${this.getUrl(MESSAGE_THREAD)}${threadId}${threadId ? '/' : ''}`
  }

  getMessageThreadList() {
    return this.getUrl(MESSAGE_THREAD_LIST)
  }

  getMessageDownloadDocument({ attachmentId = '', threadId = '' }) {
    return `${this.getUrl(MESSAGE_THREAD)}${threadId}/${attachmentId}/`
  }

  getNewUserAccount(): string {
    return this.applyCommonRouteParams(this.getUrl(NEW_USER_ACCOUNT))
  }

  getCart() {
    return this.applyCommonRouteParams(this.getUrl(CART))
  }

  getNewCart(params: QueryParamsObject = {}) {
    return this.applyCommonRouteParams(
      this.setQueryParams(this.getUrl(NEW_CART), params)
    )
  }

  getNotepad(params = {}): string {
    return isToggleEnabled(
      this.featureToggles,
      TOGGLES_KEYS.NEW_MY_ACCOUNT_NOTEPAD
    )
      ? this.setQueryParams(this.getUrl(NEW_NOTEPAD), params)
      : this.setQueryParams(this.getUrl(NOTEPAD), params)
  }

  getShippingAddress(params = {}): string {
    return isSaeOnly(this.experiments, this.tenant)
      ? this.setQueryParams(this.getUrl(NEW_SHIPPING_ADDRESS), params)
      : this.setQueryParams(this.getUrl(SHIPPING_ADDRESS), params)
  }

  getBillingAddress(params = {}): string {
    const key = isSaeOnly(this.experiments, this.tenant)
      ? NEW_BILLING_ADDRESS
      : BILLING_ADDRESS

    return this.setQueryParams(this.getUrl(key), params)
  }

  getNotepadEntry(upid: string): string {
    return `${this.getUrl(NEW_NOTEPAD)}${upid}/`
  }

  getNotepadDeleteEntry(upid: string): string {
    return `${this.getUrl(NEW_NOTEPAD)}${upid}/delete/`
  }

  getLetterboxDocumentURL(document: LetterboxDocument) {
    return `${this.getUrl(NEW_ORDERS_LIST)}${document.orderId}/document/${
      document.type
    }/${document.content.hash}`
  }

  getMarketplaceLetterboxDocumentURL(document: LetterboxDocument) {
    return `${this.getUrl(NEW_ORDERS_LIST)}${document.orderId}/document/${
      document.content.documentId
    }/`
  }

  getMedcoach() {
    return this.getUrl(MEDCOACH)
  }

  getMyTherapyMS() {
    return this.getUrl(MY_THERAPY_MS)
  }

  getRedeemRecipeFunnel(hash = '') {
    return `${this.getUrl(REDEEM_RECIPE_FUNNEL)}${hash}`
  }

  getMarketplaceCollection() {
    return this.getUrl(NEW_MARKETPLACE_COLLECTION)
  }

  getRedeemRecipe(hash = '') {
    return `${this.getUrl(REDEEM_RECIPE)}${hash}`
  }

  getRedeemERecipe(hash = '') {
    return `${this.getUrl(REDEEM_E_RECIPE)}${hash}`
  }

  getNfcLandingPage() {
    return `${this.getUrl(NFC_LANDING_PAGE)}`
  }

  getPrescriptionManager() {
    return this.getUrl(PRESCRIPTION_MANAGER)
  }

  getOffersUrl(offerId: string) {
    return `${this.getUrl(OFFERS)}${offerId}/`
  }

  getSwitchPageUrl(release: string) {
    return `${this.getUrl(SWITCH_PAGE)}${release}`
  }

  getContinueShoppingPage(productId: number | string): string {
    if (productId) {
      return this.getProduct(productId)
    } else {
      return this.getHomePage()
    }
  }

  getAccountNewsletter() {
    return this.getUrl(NEW_ACCOUNT_NEWSLETTER)
  }

  getAccountNewsletterSubscribe(): string {
    return `${this.getUrl(NEW_ACCOUNT_NEWSLETTER)}subscribe/`
  }

  getAccountNewsletterUnsubscribe(): string {
    return `${this.getUrl(NEW_ACCOUNT_NEWSLETTER)}unsubscribe/`
  }

  getAccountRedpoints(params?: URLSearchParams): string {
    const isChTenant = this.tenant === TENANT_CH
    let pageUrl = this.getUrl(
      isSaeOnly(this.experiments, this.tenant) ? MYREDPOINTS : ACCOUNT_REDPOINTS
    )

    if (isChTenant) {
      pageUrl = this.getUrl(REDCOINS_ACCOUNT)
    }

    if (params) {
      return `${pageUrl}?${params.toString()}`
    }

    return pageUrl
  }

  getSellerRating(
    orderId: string,
    subOrderId: string,
    sellerId: string
  ): string {
    return `${this.getUrl(
      SELLER_RATING
    )}?orderid=${orderId}&suborderid=${subOrderId}&sellerid=${sellerId}`
  }

  getSellerProducts(sellerId: string): string {
    return uriQueryInDoubleQuotedAttr(
      decodeURIComponent(`${this.getUrl(SELLER_PRODUCTS)}id-${sellerId}/`)
    )
  }

  getUserLoginStatusUrl(): string {
    return this.getUrl(USER_LOGIN_STATUS)
  }

  getWelcomePage(): string {
    return this.getUrl(WELCOME)
  }

  getNewCustomerVoucherLandingPage(): string {
    return this.getUrl(NEW_CUSTOMER_VOUCHER)
  }
}

export default MountPoints
