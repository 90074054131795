import ConsoleRawStream from './ConsoleRawStream'

class ColorizedStream extends ConsoleRawStream {
  write(rec) {
    super.write(
      rec.level,
      `[${ColorizedStream.getLevel(rec)}] (${rec.timestamp}) - ${
        rec.message
      } ${ColorizedStream.getErrorMessage(rec)}`
    )
  }

  static getLevel(rec) {
    let level = rec.level.toUpperCase()

    switch (level) {
      case 'INFO':
        level = ColorizedStream.green(level)
        break
      case 'WARN':
        level = ColorizedStream.yellow(level)
        break
      case 'ERROR':
        level = ColorizedStream.red(level)
        break
      default:
        break
    }

    return level.padEnd(15, ' ')
  }

  static getErrorMessage(rec) {
    let errorMessage = ''
    if (rec.error_name && rec.error_message && rec.error_stacktrace) {
      errorMessage = `\n${rec.error_name}: "${rec.error_message}"\n--- StackTrace ---\n${rec.error_stacktrace}`
    }

    return errorMessage
  }

  static green(string) {
    return `\x1b[32m${string}\x1b[39m`
  }

  static yellow(string) {
    return `\x1b[33m${string}\x1b[39m`
  }

  static red(string) {
    return `\x1b[31m${string}\x1b[39m`
  }
}

export default ColorizedStream
