import { NO_CONTENT } from 'shared/consts/codes'
import { type ServiceParameters } from 'shared/services/api/commonTypes'

import BackendAPIService from './BackendAPIService'

type ContactServicePayload = {
  params: Record<string, unknown>
}
interface IContactService {
  getContactURL: () => string
  getCallbackURL: () => string
  sendContactRequest: (payload: ContactServicePayload) => Promise<boolean>
  sendCallbackRequest: (payload: ContactServicePayload) => Promise<boolean>
}

class ContactService extends BackendAPIService implements IContactService {
  constructor(params: ServiceParameters) {
    super({ apiVersion: 'v1', ...params })
  }

  private prepareKey(key: string) {
    if (key.indexOf('§emailForm.') === 0) {
      return key.substring(11)
    } else if (key.indexOf('§callbackForm.') === 0) {
      return key.substring(14)
    }

    return key
  }

  private prepareKeys(params: Record<string, unknown>) {
    return Object.keys(params).reduce(
      (result, key) => ({
        ...result,
        [this.prepareKey(key)]: params[key],
      }),
      {}
    )
  }

  public getContactURL(): string {
    return `/contact/${this.apiVersion}/${this.tenantPath}/${this.language}/contact`
  }

  public getCallbackURL(): string {
    return `/contact/${this.apiVersion}/${this.tenantPath}/${this.language}/callback`
  }

  public async sendContactRequest(
    payload: ContactServicePayload
  ): Promise<boolean> {
    const { status } = await this.post(
      this.getContactURL(),
      this.prepareKeys(payload.params)
    )

    return status === NO_CONTENT
  }
  public async sendCallbackRequest(
    payload: ContactServicePayload
  ): Promise<boolean> {
    const { status } = await this.post(
      this.getCallbackURL(),
      this.prepareKeys(payload.params)
    )

    return status === NO_CONTENT
  }
}

export default ContactService
