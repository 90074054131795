import assert from 'shared/utils/assert'

import BackendAPIService from './BackendAPIService'
import type { ServiceParameters } from './commonTypes'
import type { IMedication } from './models/medicationPlan'

const config = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
}

type GetAllMedicationPlanResponse = {
  medications: IMedication[]
}

const API_VERSION = 'v1'

class MedicationPlanService extends BackendAPIService {
  constructor(props: ServiceParameters) {
    super(props)

    this.apiVersion = API_VERSION
  }

  getRootUrl(id?: string) {
    const medicationUrl = `medicationplan/${this.apiVersion}/${this.tenantPath}/medication`
    if (id) {
      return `${medicationUrl}/${id}`
    }
    return medicationUrl
  }

  getSubmitUrl() {
    return `${this.getRootUrl()}/requestCheck`
  }

  async getMedications(): Promise<IMedication[]> {
    const { data } = await this.get<GetAllMedicationPlanResponse>(
      this.getRootUrl(),
      config
    )
    return data.medications
  }

  async createMedication(payload: IMedication): Promise<IMedication> {
    assert(
      payload,
      'MedicationPlanService.createMedication: please provide "payload"'
    )
    const res = await this.post(this.getRootUrl(), payload, config)
    return res.data as IMedication
  }

  async editMedication(payload: IMedication, id: string): Promise<IMedication> {
    assert(
      payload,
      'MedicationPlanService.editMedication: please provide "payload"'
    )
    const res = await this.patch(this.getRootUrl(id), payload, config)
    return res.data as IMedication
  }

  async deleteMedication(id: string): Promise<void> {
    await this.delete(this.getRootUrl(id), { ...config, data: {} })
  }

  async submitMedicationPlan(): Promise<void> {
    await this.post(this.getSubmitUrl(), {}, config)
  }
}

export default MedicationPlanService
