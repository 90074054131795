// @ts-nocheck
import {
  DEVICE_CLASS_BOT,
  DEVICE_CLASS_DESKTOP,
  DEVICE_CLASS_MOBILE_BOT,
} from 'shared/consts/deviceTypes'

/**
 * Device classes that need for desktop view
 */
const desktopGroup = [DEVICE_CLASS_DESKTOP, DEVICE_CLASS_BOT]

const botGroup = [DEVICE_CLASS_BOT, DEVICE_CLASS_MOBILE_BOT]

const normalize = deviceClass => (deviceClass ? deviceClass.toLowerCase() : '')

export const isDesktop = deviceClass =>
  !deviceClass || desktopGroup.includes(normalize(deviceClass))

export const isMobile = deviceClass =>
  /mobile|iphone/.test(normalize(deviceClass))

export const isTablet = deviceClass =>
  /tablet|ipad/.test(normalize(deviceClass))

export const isBot = deviceClass => botGroup.includes(normalize(deviceClass))
