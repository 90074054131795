import { SALUTATION_MR, SALUTATION_MRS } from 'views/consts/customer'

export const BILLING = 'billing'
export const SHIPPING = 'shipping'
export const PUP = 'pickupStation'

export const BILLING_FORM_ID = 'billing-address-form'

export const ADDRESS_SALUTATION = {
  [SALUTATION_MR]: 'mr',
  [SALUTATION_MRS]: 'mrs',
}
