import { shape, string } from 'prop-types'
import { ErrorMessage as ReactHookFormErrorMessage } from 'react-hook-form/dist/react-hook-form.ie11'

import './ErrorMessage.scss'

const ErrorMessage = ({ text, errors, name, 'data-qa-id': dataQa }) => {
  const props = {}
  if (dataQa) {
    props['data-qa-id'] = dataQa
  }

  if (errors?.[name]) {
    return (
      <ReactHookFormErrorMessage errors={errors} name={name.toString()}>
        {({ message }) => (
          <p
            className="a-ErrorMessage"
            data-clientside-hook="formControlValidation__error"
            aria-live="polite"
            {...props}
          >
            {message}
          </p>
        )}
      </ReactHookFormErrorMessage>
    )
  }
  return (
    <p
      className="a-ErrorMessage"
      data-clientside-hook="formControlValidation__error"
      aria-live="polite"
      {...props}
    >
      {text}
    </p>
  )
}

export const errorMessageType = {
  text: string,
  name: string,
  errors: shape({}),
  'data-qa-id': string,
}

ErrorMessage.propTypes = errorMessageType

ErrorMessage.defaultProps = {
  text: '',
  name: null,
  errors: null,
  'data-qa-id': undefined,
}

export default ErrorMessage
