import { shouldRenderNewLayout } from 'shared/experiments/components/NewSerp/helpers'
import store, { subscribe } from 'shared/store'
import {
  PRODUCT_FILTER_CHANGED,
  PRODUCT_FILTER_RESET,
  SEARCH_RESULTS_UPDATED,
} from 'shared/store/ducks/events'
import throttle from 'shared/utils/throttle'
import createA11Dialog from 'views/utils/createA11Dialog'
import toggleBoolStringAttr from 'views/utils/toggleBoolStringAttribute'

import { ARIA_HIDDEN } from './consts'

const SELECTOR_LIST = '[data-clientside-hook~="refinementList"]'
const SELECTOR_MODAL = '[data-clientside-hook~="modal"]'
const SELECTOR_CLOSE_MODAL_BUTTON =
  '[data-clientside-hook~="refinementList__close-modal"]'
const SELECTOR_CONFIRM_SELECTION_BUTTON =
  '[data-clientside-hook~="refinementList__confirm-selection"]'
const SELECTOR_CANCEL_SELECTION_BUTTON =
  '[data-clientside-hook~="refinementList__cancel-selection"]'
const SELECTOR_PRODUCT_FILTER_MODAL_HEADER =
  '[data-clientside-hook~="productFilterModal__header"]'
const SELECTOR_PRODUCT_FILTER_MODAL_DIALOG =
  '[data-clientside-hook~="productFilterModal__content"]'

const SCROLLING_THROTTLE_INTERVAL = 100

export const getSearchStateFromStore = () => {
  return store.getState()?.publicRuntimeConfig?.pageProperties?.searchState
}

/*
 * topScrollLastPosition - variable to store last scroll position of parent filters modal
 * isListeningToScrollChange - locker to prevent from listening for children modals scoll
 */
let topScrollLastPosition = 0
let isListeningToScrollChange = true
export const toggleParentModalOverflow = (parentModal, switcher = false) => {
  if (switcher) {
    parentModal.scrollTo?.(0, 0) // fix for iOS Safari NB: Doesn`t work for IE
    isListeningToScrollChange = false
    parentModal.classList.add('u-overflow-y--inherit@small-tiny')
  } else {
    isListeningToScrollChange = true
    parentModal.scrollTo?.(0, topScrollLastPosition)
    parentModal.classList.remove('u-overflow-y--inherit@small-tiny')
  }
}

export const showProductFilterModalHeader = productFilterModalHeader => {
  toggleBoolStringAttr(productFilterModalHeader, ARIA_HIDDEN, false)
}

export const hideProductFilterModalHeader = productFilterModalHeader => {
  toggleBoolStringAttr(productFilterModalHeader, ARIA_HIDDEN, true)
}

export default () => {
  const {
    publicConfig: { deviceClass },
    pageProperties: { pageType },
  } = store.getPublicRuntimeConfig()

  if (shouldRenderNewLayout({ deviceClass, pageType })) {
    // All the logic in the new SERP is migrated into the components
    // so there is no need to call the client script
    return []
  }

  /** @type HTMLElement */
  const productFilterModalHeader = document.querySelector(
    SELECTOR_PRODUCT_FILTER_MODAL_HEADER
  )

  const parentModal = document.querySelector(
    SELECTOR_PRODUCT_FILTER_MODAL_DIALOG
  )
  parentModal?.addEventListener(
    'scroll',
    throttle(e => {
      if (isListeningToScrollChange) {
        topScrollLastPosition = e.target.scrollTop
      }
    }, SCROLLING_THROTTLE_INTERVAL)
  )

  let searchState = getSearchStateFromStore()
  subscribe.after(SEARCH_RESULTS_UPDATED, payload => {
    searchState = payload.searchState
  })

  return [...document.querySelectorAll(SELECTOR_LIST)].map(list => {
    /** @type NodeListOf<HTMLElement> */
    const closeButtonsList = list.querySelectorAll(SELECTOR_CLOSE_MODAL_BUTTON)
    /** @type HTMLElement[] */
    const closeButtons = [...closeButtonsList]
    /** @type HTMLElement */
    const confirmSelectionButton = list.querySelector(
      SELECTOR_CONFIRM_SELECTION_BUTTON
    )
    /** @type HTMLElement */
    const cancelSelectionButton = list.querySelector(
      SELECTOR_CANCEL_SELECTION_BUTTON
    )
    const modal = createA11Dialog({
      element: list.querySelector(SELECTOR_MODAL),
    })

    let refineFunction = null
    const shippingRefineFunctions = {}
    let filterValues =
      searchState?.refinementList?.[list.dataset.listAttribute] || []
    const refinesCheckStateHandlers = {}

    subscribe.after(PRODUCT_FILTER_CHANGED, payload => {
      const {
        attribute,
        refine,
        selected,
        value,
        setIsChecked,
        shippingOptions,
      } = payload

      if (list.dataset.listAttribute !== attribute) {
        return
      }

      refinesCheckStateHandlers[selected] = setIsChecked

      if (shippingOptions) {
        if (value) {
          shippingRefineFunctions[selected] = refine
          filterValues = [selected, ...filterValues]
        } else {
          filterValues = filterValues.filter(item => item !== selected)
        }
      } else {
        if (!refineFunction) {
          refineFunction = refine
        }
        filterValues = filterValues.includes(selected)
          ? filterValues.filter(refinement => refinement !== selected)
          : [...filterValues, selected]
      }

      if (
        list.querySelector(SELECTOR_MODAL).getAttribute(ARIA_HIDDEN) === 'true'
      ) {
        if (shippingOptions) {
          refine(value)
        } else {
          refine(filterValues)
        }
      }

      if (confirmSelectionButton.getAttribute(ARIA_HIDDEN) === 'true') {
        closeButtons.forEach(closeButton =>
          toggleBoolStringAttr(closeButton, ARIA_HIDDEN, true)
        )
        toggleBoolStringAttr(confirmSelectionButton, ARIA_HIDDEN, false)
        toggleBoolStringAttr(cancelSelectionButton, ARIA_HIDDEN, false)
      }
    })

    subscribe.after(PRODUCT_FILTER_RESET, () => {
      filterValues = []
      refineFunction = null
    })

    modal.on('show', () => {
      hideProductFilterModalHeader(productFilterModalHeader)
      toggleParentModalOverflow(parentModal, true)
      const hasFilterValues = filterValues.length > 0
      toggleBoolStringAttr(
        confirmSelectionButton,
        ARIA_HIDDEN,
        !hasFilterValues
      )
      toggleBoolStringAttr(cancelSelectionButton, ARIA_HIDDEN, !hasFilterValues)
      closeButtons.forEach(closeButton =>
        toggleBoolStringAttr(closeButton, ARIA_HIDDEN, hasFilterValues)
      )
    })

    modal.on('hide', () => {
      toggleParentModalOverflow(parentModal, false)
    })

    const discardLastSelectionChanges = () => {
      const arrayOfShippingRefineFunctions = Object.entries(
        shippingRefineFunctions
      )
      if (arrayOfShippingRefineFunctions.length) {
        arrayOfShippingRefineFunctions.forEach(([selected, refine]) => {
          refine(searchState?.toggle?.[selected])
        })
        filterValues = []
      } else if (refineFunction) {
        const defaultFilterValues =
          searchState?.refinementList?.[list.dataset.listAttribute] || []
        Object.entries(refinesCheckStateHandlers).forEach(
          ([refinementName, setIsChecked]) => {
            setIsChecked(defaultFilterValues.includes(refinementName))
          }
        )
        refineFunction(defaultFilterValues)
        filterValues = defaultFilterValues
      }
    }

    cancelSelectionButton.addEventListener('click', event => {
      event.preventDefault()
      if (refineFunction || Object.entries(shippingRefineFunctions).length) {
        discardLastSelectionChanges()
      }
      modal.hide()
      showProductFilterModalHeader(productFilterModalHeader)
    })

    confirmSelectionButton.addEventListener('click', event => {
      event.preventDefault()
      if (refineFunction) {
        refineFunction(filterValues)
      }
      modal.hide()
      showProductFilterModalHeader(productFilterModalHeader)
    })

    closeButtons.forEach(closeButton =>
      closeButton.addEventListener('click', event => {
        event.preventDefault()
        modal.hide()
        showProductFilterModalHeader(productFilterModalHeader)
      })
    )

    return list
  })
}
