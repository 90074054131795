// @ts-nocheck
import { ShippingControllerResponseV1 } from 'types/api/shipping'

import BackendAPIService from './BackendAPIService'

const API_VERSION = 'v2'

interface IShippingService {
  fetchStandardShippingOptions: () => Promise<ShippingControllerResponseV1>
}

class ShippingServiceV2 extends BackendAPIService implements IShippingService {
  constructor(props) {
    super(props)

    this.apiVersion = API_VERSION
  }

  public getFetchUrl = (): string =>
    `/carriers/${this.apiVersion}/${this.tenantPath}/`

  public async fetchStandardShippingOptions(): Promise<ShippingControllerResponseV1> {
    let response: ShippingControllerResponseV1 = Object.create({
      carriers: [],
    })

    try {
      response = (
        await this.get<ShippingControllerResponseV1>(this.getFetchUrl())
      )?.data
    } catch (_) {
      // Do nothing
    }
    this.logger.debug(`SHIPSe fetch carriers v2!`, 'dataFetching')
    return response
  }
}

export default ShippingServiceV2
