/**
 * toggleClass
 * ========
 *
 * Simple client script dedicated to a single purpose:
 * toggle class (described by CLASS_NAME attribute) for configured element (has TARGET_ID id).
 * Toggling invokes by click on element with KEY_TAG clientside hook.
 *
 * FIXME split into two separate scripts: toggle/expand
 *
 * @example
 * <button
 *   data-clientside-hook="toggleClass"         <-- KEY_TAG // use expandClass for expand only
 *   aria-controls="%TARGET_ID%"                <-- TARGET_ID node
 *   data-toggle-classname="%CLASS_NAME%"       <-- CLASS_NAME to be toggled
 * >Press me</button>
 * <div id="%TARGET_ID%">whatever</div>         <-- TARGET node with id=%TARGET_ID%
 *                                                  can be anywhere in the dom
 *
 * @returns {Object[]}
 * Array of literal objects each one will contain
 * - a redefence to the KEY_TAG node
 * - a reference to the TARGET_ID node
 */
import { ARIA_CONTROLS } from './consts'

export default () => {
  const TOGGLE_HOOK_NAME = 'toggleClass'
  const EXPAND_HOOK_NAME = 'expandClass'
  const TOGGLE_CLASS = 'data-toggle-classname'

  const toggleElements = [
    ...document.querySelectorAll(
      `[data-clientside-hook~="${TOGGLE_HOOK_NAME}"]`
    ),
  ]
  const expandElements = [
    ...document.querySelectorAll(
      `[data-clientside-hook~="${EXPAND_HOOK_NAME}"]`
    ),
  ]

  return [...toggleElements, ...expandElements].reduce(
    (resultArr, buttonElement) => {
      const controlledId = buttonElement.getAttribute(ARIA_CONTROLS)
      const togglingElement = document.getElementById(controlledId)
      const togglingClassname = buttonElement.getAttribute(TOGGLE_CLASS)

      const toggle = event => {
        event.preventDefault()

        if (togglingElement.classList.contains(togglingClassname)) {
          togglingElement.classList.remove(togglingClassname)
        } else {
          togglingElement.classList.add(togglingClassname)
        }
      }

      const expand = event => {
        event.preventDefault()
        togglingElement.classList.add(togglingClassname)
      }

      if (controlledId && togglingElement) {
        if (buttonElement.dataset.clientsideHook.includes(TOGGLE_HOOK_NAME)) {
          buttonElement.addEventListener('click', toggle)
        } else {
          buttonElement.addEventListener('click', expand)
        }

        resultArr.push({
          buttonElement,
          togglingElement,
        })
      }

      return resultArr
    },
    []
  )
}
