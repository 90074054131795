import { PRODUCT_DATA_PROVIDER_NOVELTIES } from 'shared/consts/cmsTemplates'
import type { SearchStateProp } from 'types/search'

export const DEFAULT_FILTERS_LIST = [
  'stockStatusReason:product.stockStatus.available',
  'stockStatusReason:product.stockStatus.nl',
] as const

export const SEARCHABLE_FILTERS_LIST = [
  ...DEFAULT_FILTERS_LIST,
  'stockStatusReason:product.stockStatus.notInCatalog',
  'stockStatusReason:product.stockStatus.av',
  'stockStatusReason:product.stockStatus.gratis',
] as const

export const DEFAULT_FILTERS = '(%statusIn)'.replace(
  '%statusIn',
  DEFAULT_FILTERS_LIST.join(' OR ')
)

export const SEARCHABLE_FILTERS = SEARCHABLE_FILTERS_LIST.join(' OR ')

const ALL_RX_PRODUCTS_EXCEPT_STATUS_AV_AND_GRATIS =
  'prescriptionMedicine:true AND NOT stockStatusReason:product.stockStatus.gratis AND NOT stockStatusReason:product.stockStatus.av'

export const DEFAULT_FILTERS_RX_NOT_IN_CATALOG = `${DEFAULT_FILTERS} OR ${ALL_RX_PRODUCTS_EXCEPT_STATUS_AV_AND_GRATIS}`

export const DEFAULT_PARAMS = {
  analytics: false,
  analyticsTags: [],
  clickAnalytics: false,
  filters: DEFAULT_FILTERS,
}

export const EMPTY_RESPONSE = {
  hits: [],
  nbHits: 0,
  processingTimeMS: 0,
}

export const ANALYTICS_TAG_RESULTS = 'results'
export const ANALYTICS_TAG_SUGGESTIONS = 'suggestions'

export const SEARCH_TYPE = 'searchType'

export const HITS_PER_PAGE_SEARCH_BOX = 5
export const HITS_PER_PAGE_PRODUCT_FILTER = 30
export const AD_SERVER_HITS_LIMIT = 100

export const RELEVANCY_DESC_KEY = 'relevancy_desc'
export const TOP_SELLER_COUNT_DESC_KEY = 'topsellerCount_desc'
export const PRICE_ASC_KEY = 'price_asc'
export const PRICE_DESC_KEY = 'price_desc'
export const NEW_ADDED_PRODUCTS_KEY = 'Novelties'

export const SORTING_KEYS = [
  RELEVANCY_DESC_KEY,
  TOP_SELLER_COUNT_DESC_KEY,
  PRICE_ASC_KEY,
  PRICE_DESC_KEY,
  NEW_ADDED_PRODUCTS_KEY,
] as const

export const CATEGORY_LEAF_SORTING_KEYS = [
  TOP_SELLER_COUNT_DESC_KEY,
  PRICE_ASC_KEY,
  PRICE_DESC_KEY,
  NEW_ADDED_PRODUCTS_KEY,
] as const

export const DISJUNCTIVE_FACETS = [
  'pharmaForm',
  'variants.packSize',
  'price',
  'averageRating',
  'brandIntern',
  'nowProductGroup',
  'filterAttributes',
  'primaryCategory',
  'activeSubstances',
  'prescriptionMedicine',
  'manufacturer',
  'potency',
  'hair_type_multi',
  'skin_type_multi',
  'uv_protection',
  'application_areas',
  'lens_types',
  'absorbency',
  'book_language',
  'authors',
  'animal_species',
  'schuessler_salts',
  'voltage',
  'life_stage',
  'variety',
  'special_needs',
  'certificates',
  'recommended_age',
  'hair_color',
  'hair_length',
  'haircut',
  'head_circumference',
  'publisher',
  'divisibility',
  'sports',
  'discountInPercent',
  'texture',
  'product_type_filter',
  'mineral_and_trace_elements',
  'vitamins',
] as const

export const SEARCH_STATE_PROPS_WHITELIST = [
  'query',
  'refinementList',
  'page',
  'toggle',
  'range',
  'menu',
  'hitsPerPage',
  'sortBy',
  'filters',
  'configure',
] as const

export const SEO_FRIENDLY_QUERY_PARAMS: SearchStateProp[] = [
  'query',
  'refinementList',
  'page',
  'toggle',
  'range',
  'menu',
  'hitsPerPage',
  'sortBy',
]

export const TRACKING_DATA_KEYS = [
  'eventName',
  'eventType',
  'objectIDs',
  'position',
  'query',
  'queryID',
] as const

export const CTX_RULE_PRODUCTS_NOVELTIES = 'ProductsNovelties'
export const CTX_RULE_PARTNER_PRODUCTS = 'PartnerProducts'
export const CTX_RULE_OFFERS = 'Angebote'

export const SCHEMA_TO_RULE_CONTEXT = {
  [PRODUCT_DATA_PROVIDER_NOVELTIES]: CTX_RULE_PRODUCTS_NOVELTIES,
}
