// @ts-nocheck
import { ParsedUrlQuery } from 'querystring'

import { type OdsRequestBodyV1 } from '@redteclab/api/clients/bully'

import { OdsPatchCartRequest } from 'shared/services/api/models'
import assert from 'shared/utils/assert'

import BackendAPIService from './BackendAPIService'

const API_VERSION = 'v1'

const config = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
}

class OdsService extends BackendAPIService {
  constructor(props) {
    super(props)

    this.apiVersion = API_VERSION
  }

  getBasePath() {
    // /ods/v1/{tenant}
    return `/ods/${this.apiVersion}/${this.tenantPath}`
  }

  getCartPath() {
    return `${this.getBasePath()}/cart`
  }

  getTreatmentsBasePath() {
    return `/ods/${this.apiVersion}/${this.tenantPath}/treatments`
  }

  getTreatmentsBasePathV2() {
    return `/ods/v2/${this.tenantPath}/treatments`
  }

  async patchCart(urlQuery: ParsedUrlQuery) {
    assert(urlQuery, 'OdsService.patchCart: Please provide urlQuery')

    const payload: OdsPatchCartRequest = {
      correlationId: String(urlQuery.correlationId),
      hmac: String(urlQuery.hmac),
      products: [
        {
          upid: String(urlQuery.product),
          quantity: Number(urlQuery.quantity),
          prescriptionType: String(urlQuery.prescriptionType),
        },
      ],
      provider: String(urlQuery.clientId),
      shipping: String(urlQuery.shipping),
      treatmentId: String(urlQuery.treatmentId),
    }

    const response = await this.patch(this.getCartPath(), payload, config)
    this.logger.debug('OdsService added products to cart!', 'dataCreation')

    return response
  }

  async getOdsIndicationUrlByICD10(icd10) {
    const payload: OdsRequestBodyV1 = {
      icd10,
    }
    const response = await this.post(this.getTreatmentsBasePath(), payload)
    this.logger.debug(`Fetching treatments`, 'dataFetching')
    return response.data.treatments.find(x => x.provider === 'zava').data
      .landingPage
  }

  async getOdsIndicationUrlByTreatmentName(treatmentName) {
    const response = await this.get(
      this.getTreatmentsBasePathV2() + '/treatmentName/' + treatmentName
    )
    this.logger.debug(`Fetching treatment`, 'dataFetching')
    return response.data.landingPage
  }
}

export default OdsService
