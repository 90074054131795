class BackendError extends Error {
  constructor(messageWithData) {
    let message = String(messageWithData)
    const { request: { path = null } = {} } = messageWithData
    if (path !== null) {
      message = `${message} on requested URL: ${path}`
    }
    super(message)
    // Ensure the name of this error is the same as the class name
    this.name = this.constructor.name

    // extend with axios custom error props
    this.config = messageWithData.config
    this.code = messageWithData.code
    this.request = messageWithData.request
    this.response = messageWithData.response
  }
}

export default BackendError
