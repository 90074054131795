import { subscribe } from 'shared/store'
import { SEARCH_RESULTS_UPDATED } from 'shared/store/ducks/events'
import {
  AVERAGE_RATING_DEFAULT_VALUES,
  PRICE_DEFAULT_VALUES,
} from 'views/components/organisms/AlgoliaInstantSearch/ProductFilter/consts'
import toggleBoolStringAttr from 'views/utils/toggleBoolStringAttribute'

import { ARIA_HIDDEN } from './consts'

const SELECTOR_PRODUCT_FILTER_MODAL =
  '[data-clientside-hook~="productFilterModal"]'
const SELECTOR_PRODUCT_FILTER_MODAL_CLOSE_BUTTON =
  '[data-clientside-hook~="productFilter__close-modal"]'
const SELECTOR_PRODUCT_FILTER_MODAL_APPLY_FILTER_BUTTON =
  '[data-clientside-hook~="productFilter__apply-filter"]'

export const SEARCH_TYPE_REFINEMENT_LIST = 'refinementList'
export const SEARCH_TYPE_NOW_CHECKBOX = 'toggle'
export const SEARCH_TYPE_NOW_CHECKBOX_KEY = 'nowProductGroup'
export const SEARCH_TYPE_ABO_CHECKBOX = 'toggle'
export const SEARCH_TYPE_ABO_CHECKBOX_KEY = 'filterAttributes'
export const SEARCH_TYPE_RANGE = 'range'
export const RANGE_SUB_TYPES_AVERAGE_RATING = 'averageRating'
export const RANGE_SUB_TYPES_PRICE = 'price'
export const CATEGORY_MENU = 'menu'

export const isFilterInDefaultState = searchState => {
  if (searchState?.[CATEGORY_MENU]) {
    return false
  }

  if (
    searchState[SEARCH_TYPE_REFINEMENT_LIST] &&
    Object.values(searchState[SEARCH_TYPE_REFINEMENT_LIST]).some(Boolean)
  ) {
    return false
  }
  if (searchState?.[SEARCH_TYPE_NOW_CHECKBOX]?.[SEARCH_TYPE_NOW_CHECKBOX_KEY]) {
    return false
  }

  if (searchState?.[SEARCH_TYPE_ABO_CHECKBOX]?.[SEARCH_TYPE_ABO_CHECKBOX_KEY]) {
    return false
  }

  if (searchState[SEARCH_TYPE_RANGE]) {
    const range = searchState[SEARCH_TYPE_RANGE]

    if (
      range[RANGE_SUB_TYPES_AVERAGE_RATING] &&
      (range[RANGE_SUB_TYPES_AVERAGE_RATING].min !==
        AVERAGE_RATING_DEFAULT_VALUES.min ||
        range[RANGE_SUB_TYPES_AVERAGE_RATING].max !==
          AVERAGE_RATING_DEFAULT_VALUES.max)
    ) {
      return false
    }

    if (
      range[RANGE_SUB_TYPES_PRICE] &&
      (range[RANGE_SUB_TYPES_PRICE].min !== PRICE_DEFAULT_VALUES.min ||
        range[RANGE_SUB_TYPES_PRICE].max !== PRICE_DEFAULT_VALUES.max)
    ) {
      return false
    }
  }

  return true
}

export default () => {
  const productFilterModal = document.querySelector(
    SELECTOR_PRODUCT_FILTER_MODAL
  )
  if (!productFilterModal) {
    return null
  }

  const closeButton = productFilterModal.querySelector(
    SELECTOR_PRODUCT_FILTER_MODAL_CLOSE_BUTTON
  )
  const applyFilterButton = productFilterModal.querySelector(
    SELECTOR_PRODUCT_FILTER_MODAL_APPLY_FILTER_BUTTON
  )

  toggleBoolStringAttr(closeButton, ARIA_HIDDEN, false)

  subscribe.after(SEARCH_RESULTS_UPDATED, payload => {
    const inDefaultState = isFilterInDefaultState(payload.searchState)
    toggleBoolStringAttr(applyFilterButton, ARIA_HIDDEN, inDefaultState)
    toggleBoolStringAttr(closeButton, ARIA_HIDDEN, !inDefaultState)
  })

  return productFilterModal
}
