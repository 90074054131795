import getAppVersion from 'shared/utils/getAppVersion'

class StreamWrapper {
  constructor(innerStream) {
    this.innerStream = innerStream
  }

  write(rec) {
    this.innerStream.write(StreamWrapper.enhanceInfo(rec))
  }

  static enhanceInfo(rec) {
    const date = new Date()

    const info = {
      level: rec.levelName,
      message: String(rec.message),
      timestamp: date.toISOString(),
    }

    if (rec.error) {
      info.error_name = rec.error.name || undefined
      info.error_message = rec.error.message || undefined
      info.error_stacktrace = rec.error.stack || undefined
    }

    const moduleVersion = getAppVersion()
    if (process.env.npm_package_name && moduleVersion) {
      info.module_name = process.env.npm_package_name
      info.module_version = moduleVersion
    }

    return info
  }
}

export default StreamWrapper
