import { AD_SERVER_EXPERIMENTS_PREFIX } from 'shared/consts/adServer'
import {
  type ExperimentName,
  MANUALLY_ACTIVATED,
} from 'shared/experiments/consts'
import { event as eventTag } from 'shared/experiments/utils/event'
import trackEvent from 'shared/experiments/utils/trackOptimizelyEvent'
import logger from 'shared/services/logger'
import store from 'shared/store'
import type { Experiment } from 'types/shopConfig'
import buildClientAPI from 'views/providers/clientAPI'

const getExperimentsInDom = (
  includeExperimentsWithPreventActivation: boolean
): ExperimentName[] => {
  // Experiments wrapped in Experiment component will be caught with this.
  // We do it this way b/c we don't want to activate all features but just the ones
  // that are rendered for that user and ones that we've manually setup to be
  // activated (ones comming from experimentsToManuallyActivate)
  return [
    ...document.querySelectorAll<HTMLElement>(
      includeExperimentsWithPreventActivation
        ? '[data-experiment]'
        : '[data-experiment]:not([data-prevent-activation])'
    ),
  ].map(({ dataset: { experiment } }) => experiment as ExperimentName)
}

const getExperimentsInCookie = (
  experiments: Experiment[]
): ExperimentName[] => {
  // We only activate the features from experiments
  // If a feature has variant variable, it is from an experiment
  return experiments
    .filter(exp => exp?.variant)
    .map(exp => exp?.name)
    .filter(Boolean)
}

export const getExperimentsToActivate = ({
  includeExperimentsWithPreventActivation = false,
} = {}): ExperimentName[] => {
  const {
    pageProperties: { experiments: experimentsRaw },
  } = store.getPublicRuntimeConfig()
  const experiments = experimentsRaw || []
  const experimentsInCookie: ExperimentName[] =
    getExperimentsInCookie(experiments)
  const experimentsInDOM: ExperimentName[] = getExperimentsInDom(
    includeExperimentsWithPreventActivation
  )
  const adServerExperiments: ExperimentName[] = experiments
    .filter(
      ({ isEnabled, name }) =>
        isEnabled && name.startsWith(AD_SERVER_EXPERIMENTS_PREFIX)
    )
    .map(({ name }) => name)

  const uniqueExperiments = new Set([
    ...MANUALLY_ACTIVATED,
    ...experimentsInDOM,
    ...adServerExperiments,
  ])

  return (
    Array.from(uniqueExperiments)
      // only activate those which are also present in the cookie
      .filter((exp: ExperimentName) => experimentsInCookie.includes(exp))
  )
}

export const activateExperiments = async () => {
  const experimentsToActivate = getExperimentsToActivate()

  if (experimentsToActivate?.length) {
    const { clientFockService } = buildClientAPI()

    await clientFockService.activateExperiments(experimentsToActivate)
  }
}

export const startTrackingExperiments = () => {
  document
    .querySelectorAll<HTMLElement>('[data-tracking-event]')
    .forEach((element: HTMLElement) => {
      element.addEventListener('click', () => {
        // NB No need to stop the event propagation: it is being sent via Beacon API
        const { trackingEvent, trackingData = '' } = element.dataset

        let tags = {}
        if (trackingData) {
          try {
            tags = JSON.parse(trackingData)
          } catch (_) {
            logger.error('Cannot parse tracking data')
          }
        }

        if (trackingEvent) {
          try {
            trackEvent(eventTag`${trackingEvent}`, tags)
          } catch (e) {
            const { message = '' } = <Error>e || {}
            logger.error(`Cannot track event: ${message}`)
          }
        }
      })
    })
}
