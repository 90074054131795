import { DEFAULT_LOCALE } from 'shared/consts'
import store from 'shared/store'
import loadCatalogs from 'views/containers/browser/utils/loadCatalogs'
import { shouldDisplayNowGlobalBar } from 'views/utils/nowGlobalBar'

const containerEl = document.querySelector(
  '[data-clientside-hook="h-NowGlobalBar"]'
)
const mount = async container => {
  const {
    publicConfig: { locale = DEFAULT_LOCALE },
    pageProperties: { pageType, experiments = [] },
  } = store.getPublicRuntimeConfig()
  const i18n = await loadCatalogs(locale)

  if (!shouldDisplayNowGlobalBar(pageType, experiments)) {
    return
  }

  const nowGlobalBarContainer = await import(
    /* webpackChunkName: "NowGlobalBarWithRender", webpackPrefetch: true */
    './NowGlobalBarWithRender'
  )

  nowGlobalBarContainer.default({
    container,
    i18n,
  })
}

export default () => {
  if (containerEl) {
    mount(containerEl)
  }
}
