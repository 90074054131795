import { ALGOLIA_SPLIT, EXPERIMENT_VARIATION } from 'shared/experiments/consts'
import { getExperiment } from 'shared/experiments/utils/experiments'
import { type Experiment } from 'types/shopConfig'

const isAlgoliaAbTestEnabled = (experiments: Experiment[]): boolean => {
  const { variant } = getExperiment(ALGOLIA_SPLIT, experiments)
  return variant !== EXPERIMENT_VARIATION.V1
}

export default isAlgoliaAbTestEnabled
