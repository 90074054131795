const clearAnimation = (ev: Event) => {
  const target = ev.target as HTMLElement

  target.classList.add('u-no-animation')
}

export const clearAnimationOnAnimComplete = (dialog: HTMLElement | null) => {
  return dialog?.addEventListener('animationend', clearAnimation, false)
}

export const removeClearAnimationOnAnimCompleteListener = (
  dialog?: HTMLElement | null
) => {
  dialog?.classList.remove('u-no-animation')
  return dialog?.removeEventListener('animationend', clearAnimation, false)
}
