export const CMS_COMPONENT_HEADING = 'cms.component.Heading'
export const CMS_COMPONENT_BRANDS = 'cms.component.legacy.Brands'
export const CMS_COMPONENT_STICKY_BANNER = 'cms.component.StickyBanner'
export const CMS_COMPONENT_MENU = 'cms.component.Menu'
export const CMS_COMPONENT_CATEGORY_SMALL = 'cms.component.legacy.CategorySmall'
export const CMS_COMPONENT_ADDITIONAL_TEASER = 'cms.component.AdditionalTeaser'
export const CMS_COMPONENT_CATEGORY_WIDE = 'cms.component.legacy.CategoryWide'
export const CMS_COMPONENT_CROSSELL = 'cms.component.legacy.Crossell'
export const CMS_COMPONENT_DOCUMENTS = 'cms.component.legacy.Documents'
export const CMS_COMPONENT_HTML = 'cms.component.legacy.Html'
export const CMS_COMPONENT_IMAGE_BOX = 'cms.component.ImageBox'
export const CMS_COMPONENT_KEY_VISUAL = 'cms.component.KeyVisual'
export const CMS_COMPONENT_LINK_BOX = 'cms.component.LinkBox'
export const CMS_COMPONENT_LINKS = 'cms.component.legacy.Links'
export const CMS_COMPONENT_ODS_LINKOUT = 'cms.component.OdsLinkout'
export const CMS_COMPONENT_PRODUCT_BATTERY =
  'cms.component.legacy.ProductBattery'
export const CMS_COMPONENT_PRODUCT_SLIDER = 'cms.component.ProductSlider'
export const CMS_COMPONENT_RATINGS = 'cms.component.legacy.Ratings'
export const CMS_COMPONENT_GLOSSARY_NAVIGATION =
  'cms.component.GlossaryNavigation'
export const CMS_COMPONENT_GLOSSARY_DETAIL = 'cms.component.GlossaryDetail'
export const CMS_COMPONENT_TEXT_AND_IMAGE = 'cms.component.TextAndImage'
export const CMS_COMPONENT_RATGEBER_WIDGET = 'cms.component.RatgeberWidget'
export const CMS_COMPONENT_GLOSSARY_OVERVIEW = 'cms.component.GlossaryOverview'
export const CMS_COMPONENT_SEARCH_TERMS = 'cms.component.SearchTerms'
export const CMS_COMPONENT_TEXT = 'cms.component.Text'
export const CMS_COMPONENT_TIP = 'cms.component.Tip'
export const CMS_COMPONENT_VIDEOS = 'cms.component.legacy.Videos'
export const CMS_COMPONENT_VIDEO_BOX = 'cms.component.VideoBox'
export const CMS_COMPONENT_EYECATCHER = 'cms.component.Eyecatcher'
export const CMS_COMPONENT_PRODUCT_HIGHLIGHTS =
  'cms.component.ProductHighlights'
export const CMS_COMPONENT_EYECATCHER_PRODUCT_RATING =
  'cms.component.EyecatcherProductRating'
export const CMS_COMPONENT_EYECATCHER_PRODUCT_NOVELTY =
  'cms.component.EyecatcherProductNovelty'
export const CMS_COMPONENT_EYECATCHER_TWO_FOR_ONE =
  'cms.component.EyecatcherTwoForOne'
export const CMS_COMPONENT_EYECATCHER_RED_POINTS =
  'cms.component.EyecatcherRedpoints'
export const CMS_COMPONENT_BRANDSHOP_NAVIGATION =
  'cms.component.BrandshopNavigation'
export const CMS_COMPONENT_BRANDSHOPS_OVERVIEW_NAVIGATION =
  'cms.component.BrandshopsOverviewNavigation'
export const CMS_COMPONENT_EYECATCHER_RX = 'cms.component.EyecatcherRx'
export const CMS_COMPONENT_EYECATCHER_APP_DOWNLOAD =
  'cms.component.EyecatcherAppDownload'
export const CMS_COMPONENT_EYECATCHER_NEWSLETTER =
  'cms.component.EyecatcherNewsletter'
export const CMS_COMPONENT_EYECATCHER_RED_POINT_FEATURED_PRODUCT =
  'cms.component.EyecatcherRedpointsFeaturedProduct'
export const CMS_COMPONENT_ICONS_WITH_TEXT = 'cms.component.IconsWithText'
export const CMS_COMPONENT_EYECATCHER_SMART = 'cms.component.EyecatcherSmart'
export const CMS_COMPONENT_VOUCHER_ON_PRODUCT = 'cms.component.VoucherOnProduct'
export const CMS_COMPONENT_VOUCHER_ON_BRAND = 'cms.component.VoucherOnBrand'
export const CMS_COMPONENT_QUICK_INFO_BOX = 'cms.component.QuickInfoBox'
export const CMS_COMPONENT_TOC = 'cms.component.TableOfContents'
export const CMS_COMPONENT_TEXT_AND_VIDEO = 'cms.component.TextAndVideo'
export const CMS_COMPONENT_VIDEO_SLIDER = 'cms.component.VideoSlider'
export const CMS_COMPONENT_PRODUCT_RATING = 'cms.component.ProductRating'
export const CMS_COMPONENT_BANNER = 'cms.component.Banner'
export const CMS_COMPONENT_DOWNLOADS = 'cms.component.Downloads'
export const CMS_COMPONENT_INFO_BOX = 'cms.component.InfoBox'
export const CMS_COMPONENT_TAG_CLOUD = 'cms.component.TagCloud'
export const CMS_COMPONENT_PAGE_LIST = 'cms.component.PageList'
export const CMS_COMPONENT_ICON_LINK_LIST = 'cms.component.IconList'
export const CMS_COMPONENT_PAGE_SLIDER = 'cms.component.PageSlider'
export const CMS_COMPONENT_EYECATCHER_CORONA = 'cms.component.EyecatcherCorona'
export const CMS_COMPONENT_NOTIFICATION = 'cms.component.Notification'
export const CMS_COMPONENT_CATEGORY_GRID = 'cms.component.CategoryGrid'
export const CMS_COMPONENT_MEDICATION_PLAN = 'cms.component.MedicalCoach'
export const CMS_COMPONENT_ACCORDION = 'cms.component.Accordion'
export const CMS_COMPONENT_HOW_TO = 'cms.component.HowTo'
export const CMS_COMPONENT_BUTTON_WITH_TEXT = 'cms.component.ButtonWithText'
export const CMS_COMPONENT_BUTTONS = 'cms.component.Buttons'
export const CMS_COMPONENT_VOUCHER_CODE = 'cms.component.VoucherCode'
export const CMS_COMPONENT_TABS = 'cms.component.Tabs'
export const CMS_COMPONENT_ADVANTAGES_BOX = 'cms.component.OdsIconList'
export const CMS_COMPONENT_BENEFITS_BOX = 'cms.component.OdsBenefitsList'
export const CMS_COMPONENT_HEADLINE_BANNER = 'cms.component.OdsHeadlineBanner'
export const CMS_COMPONENT_EXPERIMENT = 'cms.component.FockExperiment'

export const CMS_COMPONENTS_WITH_SLIDER = [
  CMS_COMPONENT_PRODUCT_BATTERY,
  CMS_COMPONENT_PRODUCT_SLIDER,
]

export const CMS_COMPONENTS_WITH_PRODUCTS = [
  CMS_COMPONENT_EYECATCHER_PRODUCT_NOVELTY,
  CMS_COMPONENT_EYECATCHER_PRODUCT_RATING,
  CMS_COMPONENT_EYECATCHER_RED_POINT_FEATURED_PRODUCT,
  CMS_COMPONENT_EYECATCHER_TWO_FOR_ONE,
  CMS_COMPONENT_PRODUCT_HIGHLIGHTS,
  CMS_COMPONENT_PRODUCT_RATING,
  CMS_COMPONENT_RATINGS,
  CMS_COMPONENT_VOUCHER_ON_PRODUCT,
  CMS_COMPONENT_TABS,
]

export const CMS_COMPONENT_IGNORE_HEADLINE = [
  CMS_COMPONENT_BRANDS,
  CMS_COMPONENT_ODS_LINKOUT,
  CMS_COMPONENT_PRODUCT_BATTERY,
  CMS_COMPONENT_PRODUCT_SLIDER,
  CMS_COMPONENT_RATINGS,
  CMS_COMPONENT_EYECATCHER_RED_POINTS,
  CMS_COMPONENT_BRANDSHOP_NAVIGATION,
  CMS_COMPONENT_BRANDSHOPS_OVERVIEW_NAVIGATION,
  CMS_COMPONENT_EYECATCHER_RX,
  CMS_COMPONENT_EYECATCHER_APP_DOWNLOAD,
  CMS_COMPONENT_QUICK_INFO_BOX,
  CMS_COMPONENT_CROSSELL,
  CMS_COMPONENT_EYECATCHER_NEWSLETTER,
  CMS_COMPONENT_EYECATCHER_RED_POINT_FEATURED_PRODUCT,
  CMS_COMPONENT_TOC,
  CMS_COMPONENT_PAGE_SLIDER,
  CMS_COMPONENT_EYECATCHER_CORONA,
  CMS_COMPONENT_NOTIFICATION,
  CMS_COMPONENT_SEARCH_TERMS,
  CMS_COMPONENT_HEADLINE_BANNER,
  CMS_COMPONENT_ICON_LINK_LIST,
]

export const CMS_COMPONENTS = [
  CMS_COMPONENT_HEADING,
  CMS_COMPONENT_BRANDS,
  CMS_COMPONENT_CATEGORY_SMALL,
  CMS_COMPONENT_CATEGORY_WIDE,
  CMS_COMPONENT_CROSSELL,
  CMS_COMPONENT_DOCUMENTS,
  CMS_COMPONENT_HTML,
  CMS_COMPONENT_IMAGE_BOX,
  CMS_COMPONENT_KEY_VISUAL,
  CMS_COMPONENT_LINK_BOX,
  CMS_COMPONENT_LINKS,
  CMS_COMPONENT_PRODUCT_BATTERY,
  CMS_COMPONENT_PRODUCT_SLIDER,
  CMS_COMPONENT_TEXT_AND_IMAGE,
  CMS_COMPONENT_RATGEBER_WIDGET,
  CMS_COMPONENT_RATINGS,
  CMS_COMPONENT_TEXT,
  CMS_COMPONENT_TIP,
  CMS_COMPONENT_VIDEOS,
  CMS_COMPONENT_VIDEO_BOX,
  CMS_COMPONENT_EYECATCHER,
  CMS_COMPONENT_ODS_LINKOUT,
  CMS_COMPONENT_PRODUCT_HIGHLIGHTS,
  CMS_COMPONENT_QUICK_INFO_BOX,
  CMS_COMPONENT_VOUCHER_ON_BRAND,
  CMS_COMPONENT_VOUCHER_ON_PRODUCT,
  CMS_COMPONENT_EYECATCHER_RED_POINTS,
  CMS_COMPONENT_BRANDSHOP_NAVIGATION,
  CMS_COMPONENT_ICONS_WITH_TEXT,
  CMS_COMPONENT_BRANDSHOPS_OVERVIEW_NAVIGATION,
  CMS_COMPONENT_EYECATCHER_RX,
  CMS_COMPONENT_EYECATCHER_APP_DOWNLOAD,
  CMS_COMPONENT_EYECATCHER_PRODUCT_RATING,
  CMS_COMPONENT_EYECATCHER_PRODUCT_NOVELTY,
  CMS_COMPONENT_EYECATCHER_NEWSLETTER,
  CMS_COMPONENT_EYECATCHER_TWO_FOR_ONE,
  CMS_COMPONENT_SEARCH_TERMS,
  CMS_COMPONENT_EYECATCHER_RED_POINT_FEATURED_PRODUCT,
  CMS_COMPONENT_EYECATCHER_SMART,
  CMS_COMPONENT_GLOSSARY_OVERVIEW,
  CMS_COMPONENT_GLOSSARY_DETAIL,
  CMS_COMPONENT_TOC,
  CMS_COMPONENT_TEXT_AND_VIDEO,
  CMS_COMPONENT_VIDEO_SLIDER,
  CMS_COMPONENT_PRODUCT_RATING,
  CMS_COMPONENT_BANNER,
  CMS_COMPONENT_DOWNLOADS,
  CMS_COMPONENT_INFO_BOX,
  CMS_COMPONENT_TAG_CLOUD,
  CMS_COMPONENT_PAGE_LIST,
  CMS_COMPONENT_ICON_LINK_LIST,
  CMS_COMPONENT_PAGE_SLIDER,
  CMS_COMPONENT_EYECATCHER_CORONA,
  CMS_COMPONENT_NOTIFICATION,
  CMS_COMPONENT_CATEGORY_GRID,
  CMS_COMPONENT_MEDICATION_PLAN,
  CMS_COMPONENT_ACCORDION,
  CMS_COMPONENT_HOW_TO,
  CMS_COMPONENT_BUTTON_WITH_TEXT,
  CMS_COMPONENT_BUTTONS,
  CMS_COMPONENT_VOUCHER_CODE,
  CMS_COMPONENT_TABS,
  CMS_COMPONENT_ADVANTAGES_BOX,
  CMS_COMPONENT_BENEFITS_BOX,
  CMS_COMPONENT_EXPERIMENT,
  CMS_COMPONENT_STICKY_BANNER,
  CMS_COMPONENT_MENU,
]
