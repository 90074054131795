export default ({ src, callback, error }) => {
  const script = document.createElement('script')
  script.src = src
  script.defer = true
  script.async = true

  if (callback !== null) {
    if (script.readyState) {
      script.onreadystatechange = () => {
        if (
          script.readyState === 'loaded' ||
          script.readyState === 'complete'
        ) {
          script.onreadystatechange = null
          callback()
        } else {
          error()
        }
      }
    } else {
      script.onload = callback
      script.onerror = error
    }
  }

  document.getElementsByTagName('head')[0].appendChild(script)
}
