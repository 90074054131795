// @ts-nocheck
import urlUtil from 'url'

import deepmerge from 'deepmerge'
import { parse } from 'qs'
import { extract } from 'query-string'
import { inHTMLData } from 'xss-filters'

import { IS_DEVELOPMENT } from 'shared/consts'
import {
  ANALYTICS_TAG_RESULTS,
  SEARCH_STATE_PROPS_WHITELIST,
  SEARCHABLE_FILTERS,
} from 'shared/consts/algolia'
import isAlgoliaAbTestEnabled from 'shared/experiments/browser/algoliaSplit/isAlgoliaAbTestEnabled'
import { validateAlgoliaPznQuery } from 'shared/services/api/helpers/idValidator'
import logger from 'shared/services/logger'
import { parseSearchStateToUrl } from 'shared/utils/algolia'
import { getSearchType } from 'shared/utils/algolia/getSearchType'
import QueryParamsMapper from 'shared/utils/algolia/schema/QueryParamsMapper'
import validateSchema from 'shared/utils/json-schema-validator-light'
import {
  setValueByPath,
  walkOverPropsRecursively,
} from 'shared/utils/objectUtils'
import { SearchState, SearchStateProp, SearchType } from 'types/search'
import { DeviceClass, Experiment } from 'types/shopConfig'

import { getPzns, getTenantSpecificFilters } from './filters'
import getDevicePlatform from './getDevicePlatform'
import searchStateSchema from './schema/searchStateSchema'

export const getSearchUrl = ({
  searchUrl: url = '',
  searchState,
  indexName,
  userToken,
}: {
  searchUrl: string
  searchState: SearchState
  indexName: string
  userToken: string
}): string => {
  const { pathname } = urlUtil.parse(url)

  return `${pathname}${parseSearchStateToUrl(searchState, {
    indexName,
    userToken,
  })}`
}

export const isPznQuery = (searchState: GenericRecord): boolean =>
  searchState?.filters?.includes('objectID:')

const stringToBooleanTranform = (value: unknown): unknown => {
  if (['true', 'false'].includes(<string>value)) {
    return value === 'true'
  }

  return value
}

/**
 * Parse a generic record from the parsed URL to the valid SearchState
 */
export const parseSearchState = (searchState: GenericRecord): SearchState => {
  if (!searchState) {
    return {}
  }

  const queryParamsMapper = new QueryParamsMapper({})
  const paramsNames = queryParamsMapper.getParamsNames()

  return Object.entries(searchState).reduce<SearchState>(
    (acc, [key, value]) => {
      let newSearchState = acc

      const path = paramsNames.includes(key)
        ? queryParamsMapper.getClearPathByValue(key, '')
        : null

      if (path) {
        newSearchState = setValueByPath<SearchState>({
          obj: newSearchState,
          path,
          value: stringToBooleanTranform(value),
        })

        if (path !== key) {
          delete newSearchState[key]
        }
      } else {
        newSearchState[key] = value
      }

      return newSearchState
    },
    deepmerge({}, searchState)
  )
}

export const urlToSearchState = (search: string): SearchState => {
  try {
    return parseSearchState(parse(search))
  } catch (error) {
    return {}
  }
}

export const normalizeSearchQuery = (
  searchQuery: string | string[]
): string => {
  if (Array.isArray(searchQuery)) {
    return searchQuery[0]?.trim()
  }

  return searchQuery.trim()
}

const escapeStringProps = (searchState: SearchState) =>
  walkOverPropsRecursively<SearchState>(
    {
      transform: propValue =>
        typeof propValue === 'string' ? inHTMLData(propValue) : propValue,
    },
    searchState
  )

const filterUnknownProps = (searchState: SearchState): SearchState =>
  Object.fromEntries(
    Object.entries(searchState).filter(([prop]: SearchStateProp[]) =>
      SEARCH_STATE_PROPS_WHITELIST.includes(prop)
    )
  )

export const sanitizeSearchState = (searchState: SearchState) =>
  escapeStringProps(filterUnknownProps(searchState))

export const sanitizeQuery = (query: string): string => inHTMLData(query)

export const validateSearchStateSchema = (
  searchState: SearchState
): boolean => {
  const { valid, error } = validateSchema(
    {
      schema: searchStateSchema,
      banUnknownProperties: true,
    },
    searchState
  )

  if (!valid && IS_DEVELOPMENT) {
    logger.error(`Got invalid search state: ${error}`)
    logger.error(JSON.stringify(searchState, null, '  '))
  }

  return valid
}

/**
 * CRO-2851
 *
 * Used in case when the list of serapated PZNs is queried, i.e.: (upmNVM89R 08123458), to make the
 * response accurate and avoid extra products returned, as in the case with neuralsearch index.
 *
 * Modifies the request so that the the "query" is left empty and the UPIDs set in the filter as ObjectIDs,
 * i.e.: {"filters": "objectID:upmNVM89R OR objectID:08123458"}, since upm* codes (mirakle products) and
 * PZNs are both objectIDs in algolia.
 */
export const getObjectIdsSearchState = searchState => {
  const { query } = searchState

  if (!query) {
    return searchState
  }

  const pzns = getPzns(query)

  if (!pzns.length) {
    return searchState
  }

  const allValid = pzns.every((pzn, index) => {
    const isPznValid = validateAlgoliaPznQuery(pzn)

    if (isPznValid.valid) {
      pzns[index] = isPznValid.id
    } else {
      return false
    }
    return true
  })

  if (!allValid) {
    return searchState
  }

  return {
    ...searchState,
    ...{ query: '', filters: pzns.map(pzn => `objectID:${pzn}`).join(' OR ') },
  }
}

const getDefaultSearchState = ({
  query,
  indexName,
  deviceClass,
  searchType,
  ignoreFilters,
  experiments,
  tenant,
}: {
  query: string
  indexName: string
  deviceClass: DeviceClass
  searchType: SearchType
  ignoreFilters: boolean
  experiments: Experiment[] | undefined
  tenant?: string
}): SearchState => ({
  clickAnalytics: true,
  analytics: true,
  analyticsTags: [
    ANALYTICS_TAG_RESULTS,
    getDevicePlatform(deviceClass),
    searchType,
  ],
  searchType,
  sortBy: indexName,
  filters: ignoreFilters
    ? SEARCHABLE_FILTERS
    : getTenantSpecificFilters(tenant),
  query: query ? sanitizeQuery(query) : undefined,
  enableABTest: isAlgoliaAbTestEnabled(experiments),
})

export const getSearchState = ({
  url,
  indexName,
  deviceClass,
  ignoreFilters = false,
  experiments,
  tenant,
}: {
  url: string
  indexName: string
  deviceClass: DeviceClass
  ignoreFilters: boolean
  experiments?: Experiment[] | undefined
  tenant?: string
}): SearchState => {
  const searchStateFromUrl = urlToSearchState(extract(url))
  const { q, query, ...rest } = searchStateFromUrl
  const searchQuery = normalizeSearchQuery(query || q || '')
  const searchState = sanitizeSearchState(rest)
  const searchType = getSearchType(<GenericRecord>rest)

  const searchStateDefault = getDefaultSearchState({
    indexName,
    ignoreFilters,
    deviceClass,
    searchType,
    query: searchQuery,
    experiments,
    tenant,
  })

  return validateSearchStateSchema(searchState)
    ? {
        ...searchStateDefault,
        ...searchState,
      }
    : searchStateDefault
}
