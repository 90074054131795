// @ts-nocheck
import {
  SUBSCRIPTION_STATUS,
  SUBSCRIPTION_TYPE,
} from 'shared/consts/mailingSubscription'
import { Model } from 'shared/services/api/models/Model'

export interface FetchSubscriptionResponseV1 {
  id: string
  tenant: string
  subscriptionType: SUBSCRIPTION_TYPE
  customerNumber: string
  firstName: string
  lastName: string
  salutation: string
  dateOfBirth: string
  emailAddress: string
  subscriptionRegistrationChannel: string
  subscriptionRegistrationDate: string
  subscriptionStatus: SUBSCRIPTION_STATUS
  language: string
}

export class FetchSubscriptionResponse extends Model {
  public id: string
  public tenant: string
  public subscriptionType: SUBSCRIPTION_TYPE
  public customerNumber: string
  public firstName: string
  public lastName: string
  public salutation: string
  public dateOfBirth: string
  public emailAddress: string
  public subscriptionRegistrationChannel: string
  public subscriptionRegistrationDate: string
  public subscriptionStatus: SUBSCRIPTION_STATUS
  public language: string

  constructor(src: FetchSubscriptionResponseV1) {
    super(src)

    if (src) {
      this.id = src.id
      this.tenant = src.tenant
      this.firstName = src.firstName
      this.lastName = src.lastName
      this.salutation = src.salutation
      this.dateOfBirth = src.dateOfBirth
      this.emailAddress = src.emailAddress
      this.subscriptionType = src.subscriptionType
      this.customerNumber = src.customerNumber
      this.subscriptionStatus = src.subscriptionStatus
      this.subscriptionRegistrationDate = src.subscriptionRegistrationDate
      this.subscriptionRegistrationChannel = src.subscriptionRegistrationChannel
      this.language = src.language
    }
  }
}
