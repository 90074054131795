// eslint-disable-next-line no-restricted-imports
import {
  SEARCH_TYPE_EXTERN,
  SEARCH_TYPE_INTERN,
  SEARCH_TYPE_TEASER,
} from 'server/consts/dataLayer'
import { type SearchType } from 'types/search'

/**
 * If there's neither of these params, but we got a search results page,
 * then it's defined as an "external search" which might be triggered
 * for users coming from Newsletter, Google, etc. and landing on a SERP.
 * But also onsite this might happen if you click on "show all" in the cross sell widgets.
 *
 * Every search that has happened onsite in the shop (triggered via the search box),
 * gets a `i=1` param as is defined as "internal search" in econda tracking.
 *
 * `B=1` is triggered by a click on a banner which redirects to a SERP (`b` is an alias).
 */
type InternalSearchParams = {
  i?: string
  B?: string
  b?: string
}

export const getSearchType = ({
  i,
  B,
  b,
}: InternalSearchParams = {}): SearchType => {
  if (i === '1') {
    return SEARCH_TYPE_INTERN
  }

  if (B === '1' || b === '1') {
    return SEARCH_TYPE_TEASER
  }

  return SEARCH_TYPE_EXTERN
}

export const getSearchTypeParam = (
  searchType: unknown
): InternalSearchParams => {
  if (searchType === SEARCH_TYPE_INTERN) {
    return { i: '1' }
  }
  if (searchType === SEARCH_TYPE_TEASER) {
    return { B: '1' }
  }

  return {}
}
