import {
  PRESCRIPTION_TYPE_TO_PRICE_TYPE,
  PRODUCT_TEMPLATES,
} from 'shared/consts/productTemplates'
import { TENANT_COM } from 'shared/consts/tenants'
import { getPricesWithListPriceDefaulting } from 'views/utils/productUtils'

/**
 * Determines and returns the product template to use for a product with the given props
 * @param params paramter object
 * @param params.flags product flags (from product object)
 * @param params.tenant shop tenant prop (from product object)
 */
export const getProductTemplate = ({ flags = {}, tenant }) => {
  if (TENANT_COM !== tenant) {
    return PRODUCT_TEMPLATES.OTC
  }

  switch (true) {
    case flags.prescriptionMedicine:
      return PRODUCT_TEMPLATES.RX
    case flags.medicament:
      return PRODUCT_TEMPLATES.OTX
    default:
      return PRODUCT_TEMPLATES.OTC
  }
}

/**
 * Checks if price obj have type and amount fields defined and with correct type
 *
 * @param {object} price
 * @returns {boolean}
 */
const isPriceValid = ({ type, amount }) =>
  type && typeof type === 'string' && typeof amount === 'number'

/**
 * Returns the price object to use for a given prescriptionType
 *
 * @param {array} prices array of price objects (usually from Bully)
 * @param {string} requestedPrescriptionType
 */
export const getPriceForType = (prices, requestedPrescriptionType) => {
  if (!prices || !Array.isArray(prices)) {
    return {}
  }

  const requestedPrice = prices.find(
    price => price?.type === requestedPrescriptionType
  )

  return requestedPrice && isPriceValid(requestedPrice) ? requestedPrice : {}
}

export const shouldShowThePriceWithExtraMargin = (variants, prescriptionType) =>
  variants.some(
    variant =>
      variant?.prices &&
      getPriceForType(
        variant.prices,
        PRESCRIPTION_TYPE_TO_PRICE_TYPE[prescriptionType]
      ).amount?.toString().length > 5
  )

/*
 * This function is used to attach the list price to the product prices object and returns the product with it.
 * @param {Object} product The product object.
 * @return {Object} The product with the list price attached.
 */
export const getProductWithListPriceCorrection = product => {
  const currentOffer =
    product.offers?.find(offer =>
      product.offerId
        ? product.offerId === offer.offerId
        : offer.offerFlags?.includes('bestPrice')
    ) || undefined

  const prices =
    product.discount > 0
      ? getPricesWithListPriceDefaulting(currentOffer, product.prices).filter(
          price => typeof price.amount !== 'undefined'
        )
      : product.prices

  return {
    ...product,
    prices,
  }
}
