import { Model } from 'shared/services/api/models/Model'

import { NotepadProductFlagPharmaObl } from './notepadProductFlagPharmaObl'
import { type ProductFlags } from './notepadResponseTypes'

export class NotepadProductFlags extends Model {
  public pharmaObl!: NotepadProductFlagPharmaObl
  public prescriptionMedicine!: NotepadProductFlagPharmaObl

  constructor(src: ProductFlags) {
    // @ts-ignore
    super(src)
    if (src) {
      this.pharmaObl = new NotepadProductFlagPharmaObl(src.pharmaObl)
      this.prescriptionMedicine = new NotepadProductFlagPharmaObl(
        src.prescriptionMedicine
      )
    }
  }
}
