/*
 * Gallery common consts
 */
export const GALLERY_SELECTOR = '[data-clientside-hook~="imageGallery"]'
export const GALLERY_MAJOR_SELECTOR =
  '[data-clientside-hook~="imageGallery__major"]'
export const RED_POINT_SELECTOR =
  '[data-clientside-hook~="imageGallery__redPoint"]'
export const VIDEO_BADGE_SELECTOR =
  '[data-clientside-hook~="imageGallery__videoBadge"]'
export const OVERLAY_SELECTOR =
  '[data-clientside-hook~="imageGallery__overlay"]'
export const NAV_SMALLSCREEN_SELECTOR =
  '[data-clientside-hook~="imageGallery__smallscreen-nav"]'
export const ZOOMABLE_SELECTOR =
  '[data-clientside-hook~="imageGallery__zoomable"]'

/*
 * Slides & slider consts
 */
export const SLIDE_VISIBLE_CLASS = 'tns-slide-active'
export const SLIDE_ANCHOR_SELECTED_CLASS = 'a-Button--image-thumbnail-selected'
export const SLIDE_ITEM_SELECTOR = '.tns-item'
export const SLIDE_IMAGE_SELECTOR =
  '[data-clientside-hook~="imageGallery__minor-slide"]'
export const SLIDE_ANCHOR_SELECTOR = '.a-Button--image-thumbnail'

export const SLIDER_SELECTOR = '[data-clientside-hook~="slider"]'
export const SLIDER_ITEMS_SELECTOR = '[data-clientside-hook~="slider__items"]'
export const SLIDER_NAV_PREV_SELECTOR = '[data-clientside-hook~="slider__prev"]'
export const SLIDER_NAV_NEXT_SELECTOR = '[data-clientside-hook~="slider__next"]'
export const SLIDER_PREVENT_CLICK_SELECTOR =
  '[data-clientside-hook~="prevent-click"]'
export const SLIDER_HEADER_SELECTOR = '[data-clientside-hook~="slider__header"]'
export const SLIDER_IMAGE_SELECTOR = '[data-clientside-hook~="slider__image"]'
export const THUMBNAIL_SLIDER_SELECTOR =
  '[data-clientside-hook~="slider__thumbnails"]'
export const SLIDE_ACTIVE_CLASS = 'tns-slide-active'
export const ADAPTABLE_ARROWS_SELECTOR =
  '[data-clientside-hook~="adaptable__arrows"]'
export const ADAPTABLE_ARROWS_HIDDEN_CLASS = 'o-SliderHorizontal__nav--hidden'

export const SLIDER_BASE_OPTIONS = {
  items: 1,
  slideBy: 1,
  gutter: 0,
  autoplay: false,
  lazyload: true, // TODO: figure out why setting this to true makes gallery work as expected
  axis: 'horizontal',
  swipeAngle: 40,
  loop: false,
  preventScrollOnTouch: 'auto', // to prevent console error, see: https://github.com/ganlanyuan/tiny-slider/issues/370#issuecomment-576718994
}

export const FIXED_HEIGHT_CLASS = 'fixed-height'
export const SLIDER_ITEM_SELECTOR =
  '[data-clientside-hook~="imageGallery__item"]'

/*
 * Modal consts
 */
export const MODAL_SELECTOR = '[data-clientside-hook~="modal"]'
export const MODAL_CONTENT_SELECTOR =
  '[data-clientside-hook~="imageGallery__modal-content"]'
export const MODAL_SHOW_TRIGGER_SELECTOR =
  '[data-clientside-hook~="imageGallery__modal-show-trigger"]'
export const MODAL_GALLERY_CONTROLS_SELECTOR =
  '[data-clientside-hook~="imageGallery__modal__controls"]'
export const MODAL_GALLERY_PREV_SELECTOR =
  '[data-clientside-hook~="imageGallery__modal__previous"]'
export const MODAL_GALLERY_NEXT_SELECTOR =
  '[data-clientside-hook~="imageGallery__modal__next"]'

/*
 * Video consts
 */
export const VIDEO_SELECTOR = 'video'
export const VIDEO_SLIDE_DATA_SELECTOR = 'imageGallery__video'
export const VIDEO_CONTAINER_SELECTOR =
  '[data-clientside-hook~="videoWithThumbnail__gallery"]'
export const VIDEO_THUMBNAIL_SELECTOR =
  '[data-clientside-hook~="videoWithThumbnail__thumbnail"]'
export const VIDEO_PLAY_DATA_SELECTOR = 'imageGallery__playVideo'
export const VIDEO_PLAY_SELECTOR = `[data-clientside-hook~="${VIDEO_PLAY_DATA_SELECTOR}"]`
export const VIDEO_TRANSITION_CLASS = 'u-transition--opacity-200ms-in-out'
