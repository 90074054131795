// TODO Is the index the right place for something like this here?
export const API_VERSION = 'v2'

export const DEFAULT_LOCALE = 'de-DE'
export const DEFAULT_LANGUAGE = 'de'

export const LOGIN_FORM_SUBMITTED = 'loginFormSubmitted'
export const PRODUCT_TARGET_PAGE_CODE = 'product.htm'
export const CART_TARGET_PAGE_CODE = 'cart'
export const CHECKOUT_ADDRESS_TARGET_PAGE_CODE = 'checkoutAddress'
export const ACCOUNT_TARGET_PAGE_CODE = 'account'

export const SIX_HOURS_MS = 6 * 3600 * 1000
export const FIVE_MINUTES_MS = 5 * 60 * 1000

export const ABO_LABEL = 'ABO'

export const API_TIMEOUT_MS = 8000
export const FILE_UPLOAD_TIMEOUT = 35000
export const KOA_SERVER_TIMEOUT_MS = 12000

export const STATE_RESET_MS = 3000

export const LOGIN_SOURCE_PAGE = 'login'
export const REGISTRATION_SOURCE_PAGE = 'registration'
export const PROFESSIONAL_ACCOUNT_REGISTRATION_SOURCE_PAGE =
  'professionalAccountRegistration'

export enum CART_ACTION {
  ADD = 'add',
  CHECK_OVERDRAFTS = 'checkOverdrafts',
}

// Standard exports
export * from './algolia'
export * from './allowedIps'
export * from './appLogos'
export * from './authCookie'
export * from './channelBasedPricing'
export * from './checkout'
export * from './cmsComponents'
export * from './cmsProps'
export * from './cmsTemplates'
export * from './coreShopEnvironments'
export * from './crosssellWidgets'
export * from './deliveryLogos'
export * from './deviceTypes'
export * from './econdaCookieIds'
export * from './env'
export * from './errorCodes'
export * from './featureFlagCookie'
export * from './formFields'
export * from './gtm'
export * from './httpHeaders'
export * from './languages'
export * from './login'
export * from './mailingSubscription'
export * from './media'
export * from './mountPoints'
export * from './notificationTypes'
export * from './page'
export * from './prescription'
export * from './productFlags'
export * from './redpointsSubscription'
export * from './shopLogos'
export * from './statusCodes'
export * from './stockStatus'
export * from './tenants'
export * from './trackingDefaultParams'
export * from './swap'
export * from './order/returnLabel'
