import type {
  OrderInfoResponseV2,
  OrderInfoThreadsV2,
  OrderLineItemV2,
} from 'shared/services/api/models'
import { Model } from 'shared/services/api/models'
import { OrderInfoLineItem } from 'shared/services/api/models/orders/orderInfoLineItem'

import { OrderInfoThreads } from './orderInfoThreads'

export class OrderInfoResponse extends Model {
  public hasIncident: boolean
  public canOpenIncident: boolean
  public returnAvailable: boolean
  public orderLineItems: OrderInfoLineItem[]
  public orderLineId: string
  public threads: OrderInfoThreads[]

  constructor(src: OrderInfoResponseV2) {
    super(src)

    this.hasIncident = src.hasIncident
    this.canOpenIncident = src.canOpenIncident
    this.returnAvailable = src.returnAvailable
    this.orderLineItems = Array.isArray(src.lines)
      ? src.lines.map(
          (orderLineItem: OrderLineItemV2) =>
            new OrderInfoLineItem(orderLineItem)
        )
      : []
    this.orderLineId = src.orderId
    this.threads = Array.isArray(src.threads)
      ? src.threads.map(
          (orderThread: OrderInfoThreadsV2) => new OrderInfoThreads(orderThread)
        )
      : []
  }
}
