import updateCustomerPreferredLanguage from 'shared/utils/updateCustomerPreferredLanguage'

export default () => {
  const languageLinksDesktop = document.querySelectorAll<HTMLLinkElement>(
    '.o-LanguageSwitcherDesktop__link a'
  )
  if (languageLinksDesktop) {
    Array.from(languageLinksDesktop).forEach(link => {
      link.addEventListener('click', () => {
        updateCustomerPreferredLanguage(link.dataset.lang as string)
      })
    })
  }
}
