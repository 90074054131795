// @ts-nocheck
import { SA_SELLER_ID } from 'shared/consts/checkout'
import { PRESCRIPTION_TYPE_ODS } from 'shared/consts/productTemplates'
import { CustomerData } from 'shared/services/api/models/customerData'
import { Model } from 'shared/services/api/models/Model'
import MountPoints from 'shared/utils/mountPoints'
import { OrderStatus as Status } from 'views/components/organisms/OrderDetailsContent/OrderStatus/const'
import { isNowPickupOrder } from 'views/components/utils/isNowPickupOrder'

import { OrderDocumentPosition } from './orderDocumentPosition'
import { OrderDocuments } from './orderDocuments'
import { OrderMasterData } from './orderMasterData'
import { OrderResponseV1, SuborderV1 } from './ordersResponseTypes'
import { Suborder } from './suborder'

const sortODSsectionProducts = (suborder: Suborder) => {
  const odsSection: OrderDocuments = suborder.orderDocuments.find(
    orderDocument => orderDocument.type === PRESCRIPTION_TYPE_ODS
  )
  if (odsSection) {
    odsSection.documentPositions.sort(
      (a: OrderDocumentPosition, b: OrderDocumentPosition) => {
        if (a.entryType < b.entryType) {
          return -1
        }
        if (a.entryType > b.entryType) {
          return 1
        }
        return 0
      }
    )
  }
}

export class OrderResponse extends Model {
  public readonly customerMasterData: CustomerData
  public readonly orderMasterData: OrderMasterData
  public readonly subOrders: Suborder[]
  public readonly status: string
  public refundAvailable: boolean

  constructor(src: OrderResponseV1) {
    super(src)
    if (src.customerMasterData) {
      this.customerMasterData = new CustomerData(src.customerMasterData)
    }
    if (src.orderMasterData) {
      this.orderMasterData = new OrderMasterData(src.orderMasterData)
    }

    this.subOrders = Array.isArray(src.subOrders)
      ? src.subOrders.map((suborder: SuborderV1) => new Suborder(suborder))
      : []
    this.status = this.getStatus()
  }

  public getSubOrderById(subOrderId: string): Suborder {
    const suborder: Suborder = this.subOrders.find(
      (so: Suborder) => so.subOrderId === subOrderId
    )
    if (suborder) {
      sortODSsectionProducts(suborder)
      return suborder
    }
    throw new Error(`Suborder with ${subOrderId} is not found`)
  }

  // Establish main order status based on suborder statuses
  getStatus(): string {
    if (this.subOrders?.length === 0) {
      return Status.PROCESSING
    }

    const isNowPickupReadyForPickup =
      this.subOrders.length === 1 &&
      this.subOrders.some(isNowPickupOrder) &&
      this.subOrders[0].status === Status.DISPATCHED

    if (isNowPickupReadyForPickup) {
      return Status.READY_FOR_PICKUP
    }

    const isNowPickupCompleted =
      this.subOrders.length === 1 &&
      this.subOrders.some(isNowPickupOrder) &&
      this.subOrders[0].status === Status.CLOSED

    if (isNowPickupCompleted) {
      return Status.COMPLETED
    }

    const isSAEOrder =
      this.subOrders.length == 1 && this.subOrders[0].sellerId === SA_SELLER_ID

    if (isSAEOrder) {
      return this.subOrders[0].status
    }

    const isCancelledOrder = this.subOrders.every(
      subOrder => subOrder.status === Status.CANCELED
    )

    if (isCancelledOrder) {
      return Status.CANCELED
    }

    const isClosedOrder = this.subOrders.every(
      subOrder =>
        subOrder.status === Status.CANCELED || subOrder.status === Status.CLOSED
    )

    if (isClosedOrder) {
      return Status.CLOSED
    }

    const notClosed = this.subOrders.filter(
      subOrder =>
        !(
          subOrder.status === Status.CANCELED ||
          subOrder.status === Status.CLOSED
        )
    )
    let status = notClosed[0].status

    if (isNowPickupOrder(notClosed[0])) {
      if (status === Status.DISPATCHED) {
        status = Status.READY_FOR_PICKUP
      } else if (status === Status.CLOSED) {
        status = Status.COMPLETED
      }
    }

    const differentStatuses = notClosed.some(
      subOrder => subOrder.status !== status
    )

    return differentStatuses ? Status.PROCESSING : status
  }

  public buildOrderDocumentsURLs(mountPoints: MountPoints) {
    this.subOrders.forEach(subOrder => {
      subOrder.sellerId === SA_SELLER_ID
        ? subOrder.documentsToDownload.forEach(document =>
            document.getDocumentURL(mountPoints)
          )
        : subOrder.documentsToDownload.forEach(document =>
            document.getMarketplaceDocumentURL(mountPoints)
          )
    })
  }
}
