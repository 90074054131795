import {
  PRESCRIPTION_TYPE_FREE,
  PRESCRIPTION_TYPE_INSURANCE,
  PRESCRIPTION_TYPE_ODS,
  PRESCRIPTION_TYPE_PRIVAT,
} from './productTemplates'

// TODO: think about moving those into the types folder
export const SA_SELLER_ID = 'shop-apotheke'
export const SA_SELLER_NAME = 'SHOP APOTHEKE'

export const ORDER_TYPE = {
  NORMAL: 'NORMAL',
  SUBSCRIPTION: 'SUBSCRIPTION',
  FREIGHT: 'FREIGHT',
}

export enum SHIPPING_TYPE {
  'carrier.her.001' = 'HERMES',
  'carrier.dhl.001' = 'DHL',
  'carrier.the.001' = 'THERMOMED',
}

export enum DOCUMENT_TYPE {
  PRIVATE_PRESCRIPTION = 'privatePrescription',
  FREE_PRESCRIPTION = 'freePrescription',
  INSURANCE_PRESCRIPTION = 'insurancePrescription',
  NO_PRESCRIPTION = 'noPrescription',
  ODS_SECTION = 'odsSection',
  GRATIS = 'bonusSection',
}

export enum ENTRY_TYPE {
  ARTICLE = 'article',
  SERVICE = 'service',
}

export const ADYEN_PAYMENT_DATA_LOCAL_STORAGE_KEY = 'paymentData'

export const PHONE_NUMBER_MODAL = 'phone-number-modal'

export const PRESCRIPTION_TYPES = [
  PRESCRIPTION_TYPE_PRIVAT,
  PRESCRIPTION_TYPE_FREE,
  PRESCRIPTION_TYPE_INSURANCE,
  PRESCRIPTION_TYPE_ODS,
]
