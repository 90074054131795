import { FORBIDDEN } from 'shared/consts/codes'

import BackendError from './BackendError'

class ForbiddenError extends BackendError {
  constructor(messageWithData) {
    super(messageWithData)
    this.name = this.constructor.name
    this.status = FORBIDDEN
  }
}

export default ForbiddenError
