export const AD_SERVER_BROWSER_ID_CONVERSION_COOKIE = 'asbidc'
export const AD_SERVER_BROWSER_ID_TARGETING_COOKIE = 'asbidt'
export const AD_SERVER_USER_ID_COOKIE = 'asuid'
export const AD_SERVER_BROWSER_ID_SYNC = 'asbid_sync'

export const AD_SERVER_MEDIA_CODE_SEARCH_PARAM = 'mediacode'

export const AD_SERVER_TRACKING_URLS_COOKIE_PREFIX = 'tracking-urls-'
export const AD_SERVER_CROSS_SELL_MORE_LINK = 'adServerSliderMoreLink'
export const AD_SERVER_METADATA_DATA_ATTR = 'data-ssr-as'
export const AD_SERVER_CAMPAIGN_COOKIE_NAME = 'as-campaign'

export const AD_SERVER_EXPERIMENTS_PREFIX = 'ad-exp-'
