import {
  PAGE_TYPE_ACCOUNT,
  PAGE_TYPE_CART,
  PAGE_TYPE_CATEGORY,
  PAGE_TYPE_CHECKOUT,
  PAGE_TYPE_FORGOT_PASSWORD,
  PAGE_TYPE_LOGIN,
  PAGE_TYPE_NEWSLETTER,
  PAGE_TYPE_SEARCH_RESULTS,
  TENANT_COM,
} from 'shared/consts'
import store from 'shared/store'

const ERX_MODAL_ID = 'ErxModal'

const blacklistedPages = [
  PAGE_TYPE_ACCOUNT,
  PAGE_TYPE_FORGOT_PASSWORD,
  PAGE_TYPE_NEWSLETTER,
  PAGE_TYPE_CART,
  PAGE_TYPE_CHECKOUT,
  PAGE_TYPE_LOGIN,
  PAGE_TYPE_SEARCH_RESULTS,
  PAGE_TYPE_CATEGORY,
]

const blacklistedExpas = ['dvd']

const modalCloseEventHandler = (event: MouseEvent) => {
  const target = event.target as HTMLElement
  if (
    !target.closest('.m-Modal__content') &&
    window.location.hash === `#${ERX_MODAL_ID}`
  ) {
    window.location.hash = ''
  }
}

export const triggerErxModal = () => {
  document.addEventListener('click', modalCloseEventHandler)

  setTimeout(function () {
    const scrollPosition = window.scrollY
    window.location.hash = ERX_MODAL_ID
    window.sessionStorage.setItem(ERX_MODAL_ID, 'true')
    window.dataLayer.push({ 'event': 'popin', 'popinName': '__eRxModal' })

    // scroll to original position after modal is closed
    window.addEventListener('hashchange', function () {
      if (window.location.hash !== `#${ERX_MODAL_ID}`) {
        window.scrollTo(0, scrollPosition)
        document.removeEventListener('click', modalCloseEventHandler)
      }
    })
  }, 10000)
}

export const isPageBlacklisted = (page: string) =>
  blacklistedPages.includes(page)

export default () => {
  const isErxModalShown = window.sessionStorage.getItem(ERX_MODAL_ID)
  const publicRuntimeConfig = store.getState()?.publicRuntimeConfig
  const { tenant } = publicRuntimeConfig?.publicConfig || {}
  const { expa = '' } = publicRuntimeConfig?.pageProperties || {}
  const { pageType = '', coreShopEnvironment } =
    publicRuntimeConfig?.pageProperties || {}

  if (
    isPageBlacklisted(pageType) ||
    blacklistedExpas.includes(expa) ||
    isErxModalShown ||
    tenant !== TENANT_COM ||
    coreShopEnvironment === 'qs'
  ) {
    return
  }

  triggerErxModal()
}
