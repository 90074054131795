/**
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith#Polyfill
 */
const addStartsWith = () => {
  if (!String.prototype.startsWith) {
    Object.defineProperty(String.prototype, 'startsWith', {
      value(search, rawPos = 0) {
        const pos = rawPos > 0 ? rawPos | 0 : 0
        return this.substring(pos, pos + search.length) === search
      },
    })
  }
}

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/endsWith#Polyfill
 */
const addEndsWith = () => {
  if (!String.prototype.endsWith) {
    Object.defineProperty(String.prototype, 'endsWith', {
      value(search, rawLength = 0) {
        const length = rawLength > this.length ? this.length | 0 : 0
        return this.substring(length - search.length, length) === search
      },
    })
  }
}

const stringPolyfills = () => {
  addStartsWith()
  addEndsWith()
}

export default stringPolyfills
