// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

import { NotepadProductOfferPrice } from './notepadProductOfferPrice'
import { NotepadProductOfferSeller } from './notepadProductOfferSeller'
import { NotepadProductOfferV1 } from './notepadResponseTypes'

export class NotepadProductOffer extends Model {
  public offerFlags: string[]
  public offerId: string
  public offerPrice: NotepadProductOfferPrice
  public offerType: string
  public seller: NotepadProductOfferSeller

  constructor(src: NotepadProductOfferV1) {
    super(src)
    this.offerFlags = Array.isArray(src.offerFlags) ? src.offerFlags : []
    this.offerId = src.offerId
    this.offerPrice = src.offerPrice
      ? new NotepadProductOfferPrice(src.offerPrice)
      : null
    this.offerType = src.offerType
    this.seller = src.seller ? new NotepadProductOfferSeller(src.seller) : null
  }
}
