import type { LetterboxContentV1 } from '@redteclab/api/clients/bully'

import { Model } from 'shared/services/api/models/Model'

export class LetterboxDocumentContent extends Model {
  public readonly type: string | undefined
  public readonly size: number | undefined
  public readonly hash: string

  constructor(src: LetterboxContentV1) {
    // @ts-ignore
    super(src, { allowEmpty: false })
    this.type = src.type
    this.size = src.size
    this.hash = src.hash
  }
}
