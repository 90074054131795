import { BUTTON_ON_SUBMIT_ANIMATION_STYLES } from 'views/components/atoms/Button/consts'
import { spinnerRebrandingStyles } from 'views/components/atoms/Button/helpers'

import { handleFormButtonAnimationPollyfills } from './formSubmissionAnimation'
import { addOverlayDivToElem, createOverlayDiv } from './helpers'

export default (
    { formSelector, buttonId, transitionDurationInSec, preserveWidth = false },
    svgSpinnerOpts = {}
  ) =>
  () => {
    let formSubmitting = false

    const form = formSelector ? document.querySelector(formSelector) : null
    const button = document.getElementById(buttonId)

    const loadingOverlay = createOverlayDiv()
    if (!form && !button) {
      return
    }

    if (form) {
      const buttonOriginalHtml = button.innerHTML

      form.__resetFormSubmissionAnimation = () => {
        formSubmitting = false
        button.innerHTML = buttonOriginalHtml
      }
    }

    const isRebranding =
      button.dataset.submitStyles !== BUTTON_ON_SUBMIT_ANIMATION_STYLES.standard

    /* we do not use overlay in rebranding, just a smaller loadingIndicator */
    if (button && isRebranding) {
      svgSpinnerOpts = spinnerRebrandingStyles(
        svgSpinnerOpts,
        button.dataset.submitStyles
      )
    }

    if (button && !isRebranding) {
      addOverlayDivToElem(button, loadingOverlay, transitionDurationInSec)
    }

    // If the form is not given, just use the button for applying the loading animation.
    // The current use case is in the cart, where no form element exists and the animation needs
    // to be applied to the checkout button nonetheless.
    ;(form || button).addEventListener(form ? 'submit' : 'click', function (e) {
      if (formSubmitting) {
        e.preventDefault()
        return
      }
      if (!form || form.checkValidity()) {
        formSubmitting = true
        handleFormButtonAnimationPollyfills({
          form,
          button,
          loadingOverlay,
          svgSpinnerOpts,
          preserveWidth,
          event: e,
        })
      }
    })
  }
