// @ts-nocheck
import { IS_NODE } from 'shared/consts'
import { NOW_DATA_KEY } from 'shared/store/helper/consts'

import { UPDATE_NOW_DATA_SUCCESS } from './types.consts'

export const updateNowDataSuccessAction = (
  payload
): UpdateNowDataSuccessAction => {
  if (!IS_NODE) {
    localStorage.setItem(NOW_DATA_KEY, JSON.stringify(payload))
  }

  return {
    type: UPDATE_NOW_DATA_SUCCESS,
    payload,
  }
}

export type NowDataActionTypes = UpdateNowDataSuccessAction
