// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'
import { OrderDeliveryAddressNowData } from 'shared/services/api/models/orders/OrderDeliveryAddressNowData'

import { OrderPickupStation } from './orderPickupStation'
import { OrderDeliveryAddressV1 } from './ordersResponseTypes'

export class OrderDeliveryAddress extends Model {
  public readonly city: string
  public readonly countryCode: string
  public readonly doorCode: string
  public readonly extension: string
  public readonly firstName: string
  public readonly id: string
  public readonly lastName: string
  public readonly pickupStation?: OrderPickupStation
  public readonly salutation: string
  public readonly street: string
  public readonly streetNo: string
  public readonly letterBox: string
  public readonly type: string
  public readonly zip: string
  public readonly nowData?: OrderDeliveryAddressNowData

  constructor(src: OrderDeliveryAddressV1) {
    super(src)
    this.city = src.city
    this.countryCode = src.countryCode
    this.doorCode = src.doorCode
    this.extension = src.extension
    this.firstName = src.firstName
    this.id = src.id
    this.lastName = src.lastName
    if (src.pickupStation) {
      this.pickupStation = new OrderPickupStation(src.pickupStation)
    }
    this.salutation = src.salutation
    this.street = src.street
    this.streetNo = src.streetNo
    this.letterBox = src.letterBox
    this.type = src.type
    this.zip = src.zip
    this.nowData = src.nowData
  }
}
