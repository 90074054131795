import classNames from 'clsx'
import { type ReactNode, useEffect, useState } from 'react'
import { A11yDialog, type ReactA11yDialogProps } from 'react-a11y-dialog'
import { connect } from 'react-redux'

import {
  clearHideModalAction,
  clearShowModalAction,
} from 'shared/store/ducks/events'
import noop from 'shared/utils/noop'
import { NO_OVERFLOW_CLASS } from 'views/assets/scripts/consts'
import ModalCloseButton from 'views/components/molecules/Modal/ModalCloseButton'
import ModalContext from 'views/components/molecules/Modal/ModalContext'
import ModalTitle from 'views/components/molecules/Modal/ModalTitle'
import useModalClasses from 'views/components/molecules/Modal/useModalClasses'

import './Modal.scss'

type A11yDialogInstance = Parameters<
  NonNullable<ReactA11yDialogProps['dialogRef']>
>[0]

const renderTitleContent = ({
  title,
  id,
  titleHasInnerHTML,
  titleDataQaId,
}: {
  title?: ReactNode
  id: string
  titleHasInnerHTML?: boolean
  titleDataQaId?: string
}) => {
  return (
    <ModalTitle
      title={title}
      id={id}
      titleHasInnerHTML={titleHasInnerHTML}
      className="m-Modal__title--left"
    >
      <span
        data-qa-id={titleDataQaId}
        className="u-display--block u-clearfix"
      />
    </ModalTitle>
  )
}

export type ReactModalProps = {
  children: ReactNode
  title?: ReactNode
  id: string
  open?: boolean | null | undefined
  size?: string
  light?: boolean
  fullScreen?: boolean
  titleHasInnerHTML?: boolean
  className?: string
  contentContainerClassName?: string
  showModalId?: string
  hideModalId?: string
  hideCloseButton?: boolean
  clearShowModal?: () => void
  clearHideModal?: () => void
  closeButtonText?: string
  initShow?: boolean
  onHideModal?: () => void
  onShowModal?: () => void
  preventPageScrolling?: boolean
  role?: 'dialog' | 'alertdialog'
  needsDataQaId?: boolean
  disablesOnShowModal?: boolean
}

const ReactModal = (props: ReactModalProps) => {
  const {
    id,
    title = null,
    open = false,
    size,
    light = false,
    fullScreen = false,
    titleHasInnerHTML = false,
    closeButtonText = /*i18n*/ 'general.link.label.close',
    initShow = false,
    className = '',
    contentContainerClassName = '',
    showModalId = '',
    hideModalId = '',
    hideCloseButton = false,
    clearShowModal = noop,
    clearHideModal = noop,
    onShowModal = noop,
    onHideModal = noop,
    preventPageScrolling = false,
    children,
    role = 'dialog',
    needsDataQaId,
    disablesOnShowModal = false,
  } = props
  const dialogRoot = `#${id}`
  const closeButtonDataQaId = needsDataQaId ? `${id}-close-button` : undefined
  const titleDataQaId = needsDataQaId ? `${id}-header` : undefined
  const [modalRef, setModalRef] = useState<A11yDialogInstance | null>(null)

  const { dialogClassname, containerClassname } = useModalClasses({
    className,
    size,
    light,
    fullScreen,
    initShow,
    open,
  })

  const classes = classNames(
    containerClassname,
    'm-Modal--clientSideControlled'
  )

  const contentContainerClasses = classNames(
    contentContainerClassName,
    'a-box a-box--medium m-Modal__contentWrapper'
  )

  useEffect(() => {
    if (preventPageScrolling) {
      document.body.classList.add(NO_OVERFLOW_CLASS)
    }

    return () => document.body.classList.remove(NO_OVERFLOW_CLASS)
  }, [preventPageScrolling])

  useEffect(() => {
    if (modalRef) {
      const showModal = () => {
        clearShowModal()
        if (!disablesOnShowModal) {
          onShowModal()
        }
      }

      const hideModal = () => {
        clearHideModal()
        if (!disablesOnShowModal) {
          onHideModal()
        }
      }

      modalRef.on('show', showModal)
      modalRef.on('hide', hideModal)

      return () => {
        modalRef.off('show', showModal)
        modalRef.off('hide', hideModal)
      }
    }
  }, [
    modalRef,
    clearShowModal,
    onShowModal,
    clearHideModal,
    onHideModal,
    disablesOnShowModal,
  ])

  useEffect(() => {
    if (((!disablesOnShowModal && showModalId === id) || open) && modalRef) {
      modalRef.show()
    }
  }, [showModalId, id, modalRef, open, disablesOnShowModal])

  useEffect(() => {
    if (!disablesOnShowModal && hideModalId === id && modalRef) {
      modalRef.hide()
    }
  }, [hideModalId, id, modalRef, disablesOnShowModal])

  return (
    <>
      <A11yDialog
        id={id}
        // @ts-ignore
        appRoot={['#content', '#root']}
        dialogRoot={dialogRoot}
        title={renderTitleContent({
          title,
          id,
          titleHasInnerHTML,
          titleDataQaId,
        })}
        closeButtonContent={
          hideCloseButton ? null : (
            <ModalCloseButton
              closeButtonText={closeButtonText}
              dataQaId={closeButtonDataQaId}
            />
          )
        }
        role={role}
        dialogRef={dialogReference => setModalRef(dialogReference)}
        classNames={{
          container: classes,
          overlay: 'm-Modal__backdrop',
          dialog: dialogClassname,
          title: 'a-h2 a-box--light a-box--no-rounded-corners u-no-margin',
          closeButton: 'm-Modal__close--right',
        }}
      >
        <ModalContext.Provider value={modalRef}>
          <div className={contentContainerClasses}>{children}</div>
        </ModalContext.Provider>
      </A11yDialog>
      <div id={id} className="m-Modal__root" />
    </>
  )
}

ReactModal.defaultProps = {}

const mapStateToProps = (state: {
  events: { showModal: string; hideModal: string }
}) => {
  return {
    showModalId: state.events.showModal,
    hideModalId: state.events.hideModal,
  }
}

const mapDispatchToProps = (dispatch: ({ type }: { type: string }) => void) => {
  return {
    clearShowModal() {
      dispatch(clearShowModalAction())
    },
    clearHideModal() {
      dispatch(clearHideModalAction())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReactModal)
