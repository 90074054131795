import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const PATH =
  'M36.832 6.343a4.2 4.2 0 0 0-6.156 0L24.529 12.8a4.677 4.677 0 0 0 0 6.435l29.655 30.632-29.907 30.906a4.707 4.707 0 0 0 0 6.444l6.156 6.453a4.227 4.227 0 0 0 6.156 0L75.73 53.1a4.725 4.725 0 0 0 0-6.444z'

const IconArrowBreadcrumb: FC<IconProps> = props => (
  <SvgIcon {...props} path={PATH} viewBox="0 0 100 100" />
)

export default IconArrowBreadcrumb
