/**
 * Functions exported from this file return user–facing URLs on which
 * fock application is expected to be mounted on.
 * a.k.a. “external routes” or “mount points”
 *
 * Do not confuse with server/utils/routes.
 */

import { format as formatUrl, parse as parseUrl } from 'url'

import { stringify } from 'query-string'

import type { ExperimentName } from 'shared/experiments/consts'
import { isExperimentVariableTrue } from 'shared/experiments/utils/featureFlags'
import { applyRouteParams, createTenantPath } from 'shared/utils/routing'
import { normalizeUrl } from 'shared/utils/url'
import type { Experiment, FeaturesToggles, Tenant } from 'types/shopConfig'

export type QueryParamsObject = Record<string, unknown>

type MountPointsBaseArguments = {
  mountPoints: Record<string, unknown>
  tenant: Tenant
  language: string
  coreShopEnvironment: string
  experiments?: Experiment[]
  nx?: boolean
  featureToggles?: FeaturesToggles
}

abstract class MountPointsBase {
  protected readonly mountPoints: Record<string, unknown>
  protected readonly tenant: Tenant
  protected readonly language: string
  protected readonly coreShopEnvironment: string
  protected readonly experiments: Experiment[]
  protected readonly nx: boolean
  protected readonly featureToggles: FeaturesToggles

  constructor({
    mountPoints,
    tenant,
    language,
    coreShopEnvironment,
    experiments = [],
    nx = false,
    featureToggles = {},
  }: MountPointsBaseArguments) {
    this.mountPoints = mountPoints
    this.tenant = tenant
    this.language = language
    this.coreShopEnvironment = coreShopEnvironment
    this.experiments = experiments
    this.nx = nx
    this.featureToggles = featureToggles
  }

  setQueryParams(url: string, additionalQuery: QueryParamsObject): string {
    const { query, ...parsedUrl } = parseUrl(url, true)

    return formatUrl({
      ...parsedUrl,
      search: stringify({ ...query, ...additionalQuery }),
    })
  }

  applyCommonRouteParams(route: string): string {
    const tenantPath = createTenantPath(this.tenant, this.coreShopEnvironment)

    return applyRouteParams(route, {
      tenantPath,
      language: this.language,
    })
  }

  getUrl(key: string): string {
    const mountPoint = this.mountPoints[key]

    if (!mountPoint) {
      throw new Error(`mount point for key ${key} does not exist`)
    }

    return normalizeUrl(`${mountPoint}`) as string
  }

  isExperimentVariableEnabled(key: ExperimentName, variable: string): boolean {
    return isExperimentVariableTrue(key, variable, this.experiments)
  }
}

export default MountPointsBase
