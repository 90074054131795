import { createContext } from 'react'
import { type ReactA11yDialogProps } from 'react-a11y-dialog'

type A11yDialogInstance = Parameters<
  NonNullable<ReactA11yDialogProps['dialogRef']>
>[0]

// the ModalContext is automatically available in all children that are contained in
// the DOM of a <Modal /> so they are e.g. able to close the modal
const ModalContext = createContext<A11yDialogInstance | null>(null)

export default ModalContext
