// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue-base": "#005091",
	"blue-light": "#006cc4",
	"blue-x-light": "#005eab",
	"blue-dark": "#00345e",
	"red-base": "#ea0100",
	"red-light": "#ff1f1e",
	"red-x-light": "#ff0605",
	"red-dark": "#b70100",
	"dark-base": "#444",
	"dark-light": "#5e5e5e",
	"dark-dark": "#2b2b2b",
	"background-base": "#fff",
	"background-light": "#fff",
	"background-dark": "#e6e6e6",
	"greyLight-base": "#dadada",
	"greyLight-light": "#f4f4f4",
	"greyLight-x-light": "#e7e7e7",
	"greyLight-dark": "#c1c1c1",
	"greySky-base": "#efefef",
	"greySky-light": "#fff",
	"greySky-x-light": "#fcfcfc",
	"greySky-dark": "#d6d6d6",
	"positive-base": "#093",
	"positive-light": "#0c4",
	"positive-dark": "#062",
	"negative-base": "#ea0100",
	"negative-light": "#ff1f1e",
	"negative-x-light": "#ff0605",
	"negative-dark": "#b70100",
	"warning-base": "#f6bb14",
	"warning-light": "#f8c945",
	"warning-dark": "#cf9b08",
	"info-base": "#005091",
	"info-light": "#006cc4",
	"info-dark": "#00345e",
	"star-base": "#ffd201",
	"star-light": "#ffdb34",
	"star-dark": "#cda900",
	"facebook-base": "#3a5a98",
	"facebook-light": "#4b71ba",
	"facebook-dark": "#2c4473",
	"youtube-base": "red",
	"youtube-light": "#f33",
	"youtube-dark": "#c00",
	"rxgreen-base": "#34cb9e",
	"rxgreen-light": "#5dd5b1",
	"rxgreen-x-light": "#48d0a8",
	"rxgreen-dark": "#2aa27e",
	"now-base": "#093",
	"now-light": "#0c4",
	"now-dark": "#062",
	"sky-blue-base": "#e6edf3",
	"sky-blue-light": "#fff",
	"sky-blue-dark": "#c4d4e2",
	"twitter-base": "#55acee",
	"twitter-light": "#83c3f3",
	"twitter-dark": "#2795e9",
	"pinterest-base": "#bd081c",
	"pinterest-light": "#ee0a23",
	"pinterest-dark": "#8c0615",
	"google-base": "#d34836",
	"google-light": "#dc6e60",
	"google-dark": "#b03626",
	"grey-base": "#adadad",
	"grey-light": "#c7c7c7",
	"grey-dark": "#949494",
	"whatsapp-base": "#25d366",
	"whatsapp-light": "#4be083",
	"whatsapp-dark": "#1da851"
};
module.exports = ___CSS_LOADER_EXPORT___;
