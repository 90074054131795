import {
  EVENT_MODAL_CLICKED,
  EVENT_MODAL_CLOSED,
  EXIT_INTENT_POPUP_MODAL_ID,
} from 'shared/experiments/browser/exitIntentPopup/consts'
import {
  EXIT_INTENT_POPUP_EXPERIMENT,
  EXPERIMENT_VARIATION,
} from 'shared/experiments/consts'
import {
  activateExperiment,
  getExperimentOnClient,
} from 'shared/experiments/utils/experiments'
import { getExperimentNamePerTenant } from 'shared/experiments/utils/tenantBasedExperimentsUtils'
import trackEvent from 'shared/experiments/utils/trackOptimizelyEvent'
import store, { subscribe } from 'shared/store'
import { actionNames } from 'shared/store/ducks/userSession'
import { isMobile } from 'shared/utils/deviceClassUtils'

export default () => {
  const isExitIntentPopupModalShown = window.sessionStorage.getItem(
    EXIT_INTENT_POPUP_MODAL_ID
  )
  const { publicRuntimeConfig } = store.getState()
  const { tenant, deviceClass } = publicRuntimeConfig?.publicConfig || {}
  const exitIntentPopupExperimentName = getExperimentNamePerTenant(
    EXIT_INTENT_POPUP_EXPERIMENT,
    tenant,
    deviceClass
  )
  const exitIntentPopupExperiment = getExperimentOnClient(
    exitIntentPopupExperimentName
  )

  if (isExitIntentPopupModalShown || !exitIntentPopupExperiment.isEnabled) {
    return
  }

  subscribe.after(actionNames.setUserSession, ({ userSession }) => {
    const { isLoggedIn } = userSession
    if (isLoggedIn) {
      return
    }

    if (isMobile(deviceClass)) {
      setTimeout(() => {
        if (exitIntentPopupExperiment.variant === EXPERIMENT_VARIATION.V1) {
          window.location.hash = EXIT_INTENT_POPUP_MODAL_ID
          window.sessionStorage.setItem(EXIT_INTENT_POPUP_MODAL_ID, 'true')
        }
        void activateExperiment(exitIntentPopupExperimentName)
      }, 240_000)
    } else {
      document.addEventListener(
        'mouseleave',
        event => {
          if (event.clientY <= 0) {
            if (exitIntentPopupExperiment.variant === EXPERIMENT_VARIATION.V1) {
              window.location.hash = EXIT_INTENT_POPUP_MODAL_ID
              window.sessionStorage.setItem(EXIT_INTENT_POPUP_MODAL_ID, 'true')
            }
            void activateExperiment(exitIntentPopupExperimentName)
          }
        },
        { once: true }
      )
    }

    document
      .getElementById(EXIT_INTENT_POPUP_MODAL_ID)
      ?.addEventListener('click', event => {
        const target = event.target

        if (
          target.closest(
            '[data-clientside-hook~="ExitIntentPopupModalContent"]'
          )
        ) {
          trackEvent(EVENT_MODAL_CLICKED)
        }

        if (
          target.closest(
            '[data-clientside-hook~="ExitIntentPopupModalCloser"]'
          ) ||
          target.closest('.m-Modal__backdrop')
        ) {
          trackEvent(EVENT_MODAL_CLOSED)
        }
      })
  })
}
