import { Model } from 'shared/services/api/models/Model'

export interface ThreadEntityV1 {
  id: string
  label: string
  type: string
}

export class ThreadEntity extends Model {
  public id: string
  public label: string
  public type: string

  constructor(src: ThreadEntityV1) {
    // @ts-ignore
    super(src)
    this.id = src.id
    this.label = src.label
    this.type = src.type
  }
}
