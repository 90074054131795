// @ts-nocheck
import type { Dispatch } from 'redux'

import {
  OrderDocument,
  OrderDraft,
} from 'shared/store-checkout/types/orderDraft'
import { RxBonusData } from 'shared/store-checkout/types/redpoints'
import { IState } from 'shared/store-checkout/types/redux'
import { isEmpty } from 'shared/utils/objectUtils'
import buildClientAPI from 'views/providers/clientAPI'

import { addCheckoutErrorAction, getOrderDraftId, getSubOrder } from './common'

// ---------------------------- ACTION TYPES -------------------------

export const FETCH_ORDERDRAFT_REQUEST = 'FETCH_ORDERDRAFT_REQUEST'
export const FETCH_ORDERDRAFT_SUCCESS = 'FETCH_ORDERDRAFT_SUCCESS'
export const UPDATE_ORDERDRAFT = 'UPDATE_ORDERDRAFT'
export const SET_PHONENUMBER = 'SET_PHONENUMBER'
export const SET_PHONE_NUMBER_SUCCESS = 'SET_PHONE_NUMBER_SUCCESS'
export const SET_SHIPPING_OPTION_INFO = 'SET_SHIPPING_OPTION_INFO'
export const SELECT_SHIPPING_OPTION = 'SELECT_SHIPPING_OPTION'

// ---------------------------- SELECTORS -------------------------

export const hasOrderDraft = (state: IState): boolean => {
  try {
    return !isEmpty(state.checkout.orderDraft)
  } catch {
    return false
  }
}

export const getOrderDocuments = (
  state: IState,
  subOrderId: string
): OrderDocument[] => {
  try {
    const subOrder = getSubOrder(state, subOrderId)
    const { orderDocuments } = subOrder

    return orderDocuments || []
  } catch (e) {
    return []
  }
}

// CheckoutConnectedCartSumBox & verification step
export const getTotalRxBonusData = (state: IState): RxBonusData => {
  const rxBonusTotal =
    state?.checkout?.orderDraft?.orderMasterData
      ?.totalAmountPrescriptionBonus || 0
  const hasRxInOrder = rxBonusTotal !== 0

  return {
    hasRx: hasRxInOrder,
    rxBonus: rxBonusTotal,
  }
}

// ---------------------------- ACTIONS -------------------------
export const updateOrderDraftAction = (
  orderDraft: OrderDraft,
  updatedBy = ''
) => {
  return {
    type: UPDATE_ORDERDRAFT,
    orderDraft,
    updatedBy,
  }
}

export const fetchOrderDraftRequest = () => {
  return {
    type: FETCH_ORDERDRAFT_REQUEST,
  }
}

export const fetchOrderDraftSuccess = () => {
  return {
    type: FETCH_ORDERDRAFT_SUCCESS,
  }
}

export const setPhoneNumberSuccess = () => {
  return {
    type: SET_PHONE_NUMBER_SUCCESS,
  }
}

export const setPhonenumber = (phoneNumber: string) => {
  return {
    type: SET_PHONENUMBER,
    phoneNumber,
  }
}

export const selectShippingOptionAction = ({
  subOrderId,
  identifier,
  costs,
  displayName,
}: {
  subOrderId: string
  identifier: string
  costs: number
  displayName: string
}) => {
  return {
    type: SELECT_SHIPPING_OPTION,
    subOrderId,
    identifier,
    costs,
    displayName,
  }
}

// ---------------------------- THUNKS -------------------------

export const createOrderDraftAction = (): ((dispatch) => Promise<void>) => {
  return async dispatch => {
    dispatch(fetchOrderDraftRequest())

    try {
      const { checkoutService } = buildClientAPI()
      const response = await checkoutService.createOrderDraft()
      const orderDraft = response.data

      dispatch(updateOrderDraftAction(orderDraft, 'createOrderDraftAction'))
      dispatch(fetchOrderDraftSuccess())
    } catch (e) {
      dispatch(addCheckoutErrorAction(`createOrderDraftAction: ${e.message}`))
    }
  }
}

export const fetchOrderDraftAction = orderDraftId => {
  const { checkoutService } = buildClientAPI()

  return async dispatch => {
    dispatch(fetchOrderDraftRequest())

    try {
      const response = await checkoutService.fetchOrderDraft(orderDraftId)
      const orderDraft = response.data

      dispatch(updateOrderDraftAction(orderDraft, 'fetchOrderDraftAction'))
      dispatch(fetchOrderDraftSuccess())
    } catch (e) {
      dispatch(addCheckoutErrorAction(`createOrderDraftAction: ${e.message}`))
    }
  }
}

export const hasOrderCoolingRequired = (
  state: IState,
  subOrderId: string
): boolean => {
  try {
    const subOrder = getSubOrder(state, subOrderId)
    const { orderDocuments } = subOrder
    const isCoolingRequired = orderDocuments.some(orderDocument => {
      const { documentPositions } = orderDocument

      return documentPositions.some(documentPosition => {
        return documentPosition.isCoolingRequired
      })
    })

    return isCoolingRequired || false
  } catch (e) {
    return false
  }
}

export const setPhoneNumberAction = ({
  phoneNumber,
  history,
  mountPoints,
}: {
  phoneNumber: string
  history: unknown
  mountPoints: unknown
}) => {
  return async (dispatch: Dispatch, getState: () => IState) => {
    try {
      const { checkoutService } = buildClientAPI()
      const orderDraftId = getOrderDraftId(getState())
      const response = await checkoutService.setPhoneNumber({
        orderDraftId,
        phoneNumber,
      })
      const orderDraft = response.data

      dispatch(updateOrderDraftAction(orderDraft, 'setPhoneNumberAction'))
      dispatch(setPhoneNumberSuccess())
      history.push(mountPoints.getCheckoutShipping())
    } catch (e) {
      dispatch(addCheckoutErrorAction(`setPhoneNumberAction: ${e.message}`))
    }
  }
}
