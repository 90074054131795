import escapeStringRegexp from 'escape-string-regexp'

import {
  PRODUCT_PLACEHOLDER,
  RX_PRODUCT_PLACEHOLDER,
  STATIC_IMAGES_BASE_URL,
} from 'shared/consts'
import { usePublicConfig } from 'views/utils/shopAndRuntimeConfigUtils'

import assert from './assert'

const DEFAULT_DPI = 1
/* Removing DPI x3 due to google pagespeed insights.
 * The reports use the x3 and then complain that the image is too large.
 * Maybe there's a different way to fix that, but for now we just disable it.
 * Here's the report:
 * https://developers.google.com/speed/pagespeed/insights/?url=https%3A%2F%2Fwww.shop-apotheke.com%2Fmarken%2Ffeelgood-shop%2F&tab=mobile&hl=en
 */
const SUPPORTED_DPI = [1, 1.5, 2]

const getStaticUrlImagesBaseRegExp = baseUrl => {
  return escapeStringRegexp(`${baseUrl}/images/`)
}

/**
 * Adds width and height to src of image.
 * If one already exists then it replaces it, otherwise it just adds dimensions.
 *
 * In order to work correctly src have to contain SAE static base url for images.
 *
 * @example
 * const oldSrc = 'https://cdn.shop-apotheke.com/images/image.jpg'
 * const newSrc = addSizeToImageSrc({
 *    src: oldSrc, width: 256, height: 128, dpi: 2, cdnUrl: 'https://cdn.shop-apotheke.com'
 * })
 * // => 'https://cdn.shop-apotheke.com/images/512x256/image.jpg'
 *
 * @param {object} args
 * @param {string} args.src
 * @param {number} args.width
 * @param {number} [args.height] - if its not passed, width value will be used as height
 * @param {string} [args.cdnUrl] - comes per tenant from the shop config
 * @param {number} [args.dpi]
 * @returns {string} - new src with defined image dimensions adjusted by dpi
 */
export const addSizeToImageSrc = ({
  src,
  width,
  height,
  cdnUrl,
  dpi = DEFAULT_DPI,
}) => {
  if (!cdnUrl) {
    return src
  }

  const staticUrlImagesBaseRegExp = getStaticUrlImagesBaseRegExp(cdnUrl)
  height = height || width

  const newSize = `${width * dpi}x${height * dpi}`
  return src?.replace(
    new RegExp(
      // Capture static url base (including /images/ path),
      // them match (but not capture) size (if any given)
      `\\b(${staticUrlImagesBaseRegExp})(?:\\d+x\\d+\\/|)`,
      'g'
    ),
    `$1${newSize}/`
  )
}

/**
 * Calculates new height and width and generates srcset per each defined dpi
 *
 * @example
 * const src = 'https://cdn.shop-apotheke.com/images/2x2/imageName.jpg'
 * const srcset = addDpiSrcsets({ src, cdnUrl: 'https://cdn.shop-apotheke.com' })
 * // => `https://cdn.shop-apotheke.com/images/2x2/imageName.jpg,
 *        https://cdn.shop-apotheke.com/images/3x3/imageName.jpg 1.5x,
 *        https://cdn.shop-apotheke.com/images/4x4/imageName.jpg 2x,
 *        https://cdn.shop-apotheke.com/images/6x6/imageName.jpg 3x`
 *
 * @param {object} args - src on which newly generated srcset will be based
 * @param {string} args.src - src on which newly generated srcset will be based
 * @param {string} args.cdnUrl - comes per tenant from the shop config
 * @returns {string} - generated srcset
 */
export const addDpiSrcsets = ({ src, cdnUrl }) => {
  const size = /\d+x\d+/g

  if (!size.test(src) || !cdnUrl) {
    return src
  }

  const [width, height] = src.match(size)[0].split('x')

  return SUPPORTED_DPI.reduce((srcset, dpi) => {
    const currSrcset = addSizeToImageSrc({
      src,
      cdnUrl,
      width: Math.ceil(parseInt(width, 10) * dpi),
      height: Math.ceil(parseInt(height, 10) * dpi),
    })
    const dpiMultiplier = dpi > 1 ? ` ${dpi}x` : ''
    return [...srcset, `${currSrcset}${dpiMultiplier}`]
  }, []).join(',\n')
}

export const getWebpSrc = ({ cdnUrl, src }) => {
  const staticUrlImagesBaseRegExp = getStaticUrlImagesBaseRegExp(cdnUrl)

  const oldSrcSet = String(src).trim()
  const newSrcSet = oldSrcSet
    .replace(
      new RegExp(
        // Capture static url base (including /images/ path) and rest of the
        // URL up to the last `.`, then match (but not capture) existing
        // extension and capture rest of the URL.
        `^(${staticUrlImagesBaseRegExp}[^\\s?#]+?)(\\.[^\\s.?/#]+)([?#][^\\s]*|)$`,
        'g'
      ),
      (_, base, extension, rest) => {
        if (!extension || extension.toLowerCase() === '.svg') {
          return ''
        }
        return `${base}.webp${rest}`
      }
    )
    .trim()
  return !newSrcSet || newSrcSet === oldSrcSet ? null : newSrcSet
}

export const getWebpSrcSet = ({ cdnUrl, srcSet }) => {
  if (!cdnUrl) {
    return srcSet
  }

  if (!srcSet) {
    return null
  }

  return (
    String(srcSet)
      .trim()
      .split(/\s*,\s*/)
      .map(item => {
        const [, src, descriptor] = item.match(
          // If we could use a `s` flag (which we can’t because of
          // browser support) this RegExp would be much more readable:
          // /^(.*?)(\s+[0-9]+[wx]|)$/s
          /(?:^|\n)((?:.|\s)*?)(\s+[0-9]+[wx]|)(?:$|\n)/
        )
        const newSrc = getWebpSrc({ cdnUrl, src })
        if (!newSrc) {
          return null
        }
        return `${newSrc}${descriptor}`
      })
      .filter(Boolean)
      .join(', ') || null
  )
}

/**
 * @param {string} cdnUrl
 * @returns string
 */
export const getCdnBase = cdnUrl => {
  return `${cdnUrl}/images/`
}

export const isCdnUrl = (publicConfig, url) => {
  const { cdnUrl } = publicConfig
  return url?.startsWith(getCdnBase(cdnUrl))
}

export const isCdnUrlHook = url => {
  // eslint-disable-next-line react-hooks/rules-of-hooks -- TODO This function uses a hook, so is also a hook and should be renamed to `use…`
  return isCdnUrl(usePublicConfig(), url)
}

export const parseSrcset = (srcset = '') =>
  srcset
    ? srcset
        .trim()
        .split(',')
        .reduce((output, chunk) => {
          const [url, descriptor = ''] = chunk.split(/\s+/, 2)

          return descriptor
            ? { ...output, [descriptor.trim()]: url.trim() }
            : output
        }, {})
    : {}

export const chooseSrcByWidth = (srcsets = {}, width) => {
  const maximalWidth = Object.keys(srcsets)
    .map(w => parseInt(w.replace('w', ''), 10))
    .sort((a, b) => a - b)
    .find(w => w >= width)

  return srcsets[`${maximalWidth}w`]
}

/**
 * Function for image placeholder determination
 *
 * @param isRx param to decide which placeholder needs to be taken here
 * @param staticImageBaseUrl base url for the media server
 */
export const getPlaceholderImage = (isRx, staticImageBaseUrl = '') => {
  assert(typeof isRx === 'boolean', 'isRx should be a boolean.')
  assert(
    typeof staticImageBaseUrl === 'string',
    'staticImageBaseUrl should be a string.'
  )

  const imageBaseUrl = staticImageBaseUrl || STATIC_IMAGES_BASE_URL

  return isRx
    ? `${imageBaseUrl}${RX_PRODUCT_PLACEHOLDER}`
    : `${imageBaseUrl}${PRODUCT_PLACEHOLDER}`
}
