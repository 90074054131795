import Cookies, { type SetOption } from 'cookies'

import {
  AUTH_COOKIE_NAME,
  AUTH_COOKIE_NAME_CHECKOUT,
} from 'shared/consts/authCookie'
import isSecureCookie from 'shared/utils/isSecureCookie'

const TOKEN_TYPE = 'Bearer'
const TOKEN_TYPE_REGEX = /^BEARER /gi
const COOKIE_EXPIRATION = 24 * 60 * 60 * 1000

const checkRedirectPaths = (url: string) =>
  url.includes('/redirect') || url.includes('/experiments')

export const getCookieValueFromToken = (token: string) => {
  return token.replace(TOKEN_TYPE_REGEX, '').trim()
}

export const defaultCookieOptions: SetOption = {
  httpOnly: true,
  maxAge: COOKIE_EXPIRATION,
  overwrite: true,
  path: '/',
  sameSite: 'strict',
}

export function getAuthCookie(cookies: Cookies): string | undefined {
  let token = cookies.get(AUTH_COOKIE_NAME)

  if (!token && checkRedirectPaths(cookies?.request?.url || '')) {
    token = cookies.get(AUTH_COOKIE_NAME_CHECKOUT)
  }

  if (!token) {
    return undefined
  }

  return `${TOKEN_TYPE} ${token}`
}

export function getPlainAuthCookie(cookies: Cookies): string | undefined {
  let token = cookies?.get(AUTH_COOKIE_NAME)

  if (!token && checkRedirectPaths(cookies?.request?.url || '')) {
    token = cookies.get(AUTH_COOKIE_NAME_CHECKOUT)
  }

  return token
}

export function setAuthCookie(cookies: Cookies, token: string): void {
  try {
    const cookieValue = getCookieValueFromToken(token)

    cookies.set(AUTH_COOKIE_NAME, cookieValue, {
      ...defaultCookieOptions,
      sameSite: 'lax',
      secure: isSecureCookie,
    })
  } catch (e) {
    // eslint-disable-next-line no-restricted-globals
    if (typeof window === 'undefined') {
      throw e
    }
  }
}

export function deleteAuthCookie(cookies: Cookies): void {
  cookies.set(AUTH_COOKIE_NAME, '', { ...defaultCookieOptions, maxAge: -1 })
}
