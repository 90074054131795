import { hasValidAge, hasValidDay } from 'shared/utils/dateUtils'

export const DAY_ERROR_CODE = 'DAY_ERROR_CODE'
export const MIN_AGE_ERROR_CODE = 'MIN_AGE_ERROR_CODE'
export const DAY_ERROR_DATASET_NAME = 'dayErrorMsg'
export const MIN_AGE_ERROR_DATASET_NAME = 'minAgeErrorMsg'
export const ERROR_DATASET_NAMES_MAP = {
  [DAY_ERROR_CODE]: DAY_ERROR_DATASET_NAME,
  [MIN_AGE_ERROR_CODE]: MIN_AGE_ERROR_DATASET_NAME,
}

export const getBirthDayErrorCode = ({ day, month, year, minAge }) => {
  if (!hasValidDay({ day, month, year })) {
    return DAY_ERROR_CODE
  }

  if (minAge > 0 && !hasValidAge({ day, month, year, minAge })) {
    return MIN_AGE_ERROR_CODE
  }

  return null
}

export const getBirthDayErrorDataSetName = ({ day, month, year, minAge }) => {
  const errorCode = getBirthDayErrorCode({ day, month, year, minAge })
  return ERROR_DATASET_NAMES_MAP?.[errorCode] || null
}
