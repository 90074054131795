// @ts-nocheck
export class SoftLoginExpiredError extends Error {
  readonly userEmail: string

  constructor(messageWithData?: string, userEmail?: string) {
    super(messageWithData)

    this.userEmail = userEmail
  }
}
