import {
  type DynamicTrackingEvent,
  type TrackingEvent,
  type TrackingEventName,
  type TrackingEventSubscription,
} from 'shared/experiments/consts'

import trackOptimizelyEvent from './trackOptimizelyEvent'

/**
 * Removes events handlers added by bindTrackingEvents|bindDynamicEventsTracking
 */
const unscubsribeTracking = (subscriptions: TrackingEventSubscription[]) => {
  subscriptions.forEach(({ element, event, subscription }) =>
    element.removeEventListener(event, subscription)
  )
  subscriptions.length = 0
}

/**
 * Adds an event listener for the static layout element: button, link, container, etc.
 *
 * Returns a callable function that removes the events listeners.
 */
export const bindTrackingEvents = (defs: TrackingEvent[]): CallableFunction => {
  const subscriptions: TrackingEventSubscription[] = []

  defs.forEach(({ selector, event, trackingEvent, handler }) => {
    document.querySelectorAll<HTMLElement>(selector).forEach(element => {
      const track = () => trackOptimizelyEvent(<TrackingEventName>trackingEvent)

      const eventListener = handler ? (e: Event) => handler(e, track) : track

      element.addEventListener(event, eventListener)
      subscriptions.push({
        element,
        event,
        subscription: eventListener,
      })
    })
  })

  return unscubsribeTracking.bind(null, subscriptions)
}

/**
 * Adds an event listener for a click on layout that changes dynamically: pagination,
 * hydrated layout, AJAX requests that update page, etc.
 *
 * Returns a callable function that removes the events listeners.
 */
export const bindDynamicEventsTracking = (
  defs: DynamicTrackingEvent[]
): CallableFunction => {
  const subscriptions: TrackingEventSubscription[] = []

  defs.forEach(
    ({ parentElementSelector, trackingEvent, selector, handler }) => {
      const track = () => trackOptimizelyEvent(<TrackingEventName>trackingEvent)
      const fire = handler ? (e: Event) => handler(e, track) : track

      const eventListener = (e: Event) => {
        if ((<HTMLElement>e.target).closest(selector)) {
          fire(e)
        }
      }

      document
        .querySelectorAll<HTMLElement>(parentElementSelector)
        .forEach(element => {
          element.addEventListener('click', eventListener)

          subscriptions.push({
            element,
            event: 'click',
            subscription: eventListener,
          })
        })
    }
  )

  return unscubsribeTracking.bind(null, subscriptions)
}
