import { CREATE_SLIDER } from 'shared/store/ducks/events'

import SliderStretcher from './SliderStretcher'

// Stretcher for Horizontal Sliders
export default () =>
  new SliderStretcher({
    itemSelector: '[data-clientside-hook="slider__item"]',
    containerIdDataAttr: 'data-slider-id',
    // Needs to be inited after an actual slider script finished
    // as it relies on '.tns-slide-active' css-class.
    // This class marks a visible part of the whole slides collection.
    initEvent: CREATE_SLIDER,
  })
