/**
 * @see https://codeburst.io/throttling-and-debouncing-in-javascript-b01cad5c8edf
 * @see ./debounce.test.js
 */
const debounce = (func, delay) => {
  let timeoutId

  return function (...args) {
    const context = this
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => func.apply(context, args), delay)
  }
}

export default debounce
