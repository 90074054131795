import { parseUrl } from 'query-string'

import { IS_JEST } from 'shared/consts'
import { CSRF_FIELD_NAME } from 'shared/consts/httpHeaders'
import logger from 'shared/services/logger'
import store from 'shared/store'
import { updateNowDataSuccessAction } from 'shared/store/ducks/now/actions'
import { updatePageProperties } from 'shared/store/ducks/publicRuntimeConfig'
import { setUserSession } from 'shared/store/ducks/userSession'
import { NOW_DATA_KEY } from 'shared/store/helper/consts'
import buildClientAPI from 'views/providers/clientAPI'

const getDataFromLocalStorage = dataIdentifier => {
  const localStorageData = localStorage.getItem(dataIdentifier)
  return localStorageData ? !!JSON.parse(localStorageData) : false
}

async function getUserLoginStatusFromAuthTokenWhenEnabled(
  clientFockService,
  userSession
) {
  let isLoggedIn = false
  let isSensitiveExpired = true
  await clientFockService
    .getUserLoginStatus()
    .then(response => {
      isLoggedIn = response.data.isLoggedInUserRegistered
      isSensitiveExpired = response.data.isSensitiveExpired
    })
    .catch(() => {
      logger.error('Unable to fetch user login status')
      isLoggedIn = false
    })
    .finally(() => {
      userSession = {
        ...userSession,
        isLoggedIn,
        isSensitiveExpired,
      }
    })
  return userSession
}

const fetchUserSession = queryStr => {
  const { clientFockService, addressService } = buildClientAPI()

  const updateNowDataWithZipFromBillingAddress = async () => {
    const nowData = getDataFromLocalStorage(NOW_DATA_KEY)
    const isZipCodeSetOnceFromBillingAddress = getDataFromLocalStorage(
      'isZipCodeSetOnceFromBillingAddress'
    )

    // we return early if the user has already taken the ZIP from Billing Address,
    // or if nowData.zipCode has a value already
    // XXX: does the second predicate work? getDataFromLocalStorage (:13) returns boolean so nowData cannot be an object (:48). Investigate
    if (isZipCodeSetOnceFromBillingAddress || nowData?.zipCode) {
      return
    }

    const billingAddress = await addressService.getBillingAddress()

    if (billingAddress?.zip) {
      store.dispatch(
        updateNowDataSuccessAction({
          zipCode: billingAddress.zip,
        })
      )
      localStorage.setItem('isZipCodeSetOnceFromBillingAddress', 'true')
    }
  }

  let params = {}

  const { query } = parseUrl(queryStr || window.location.search)

  if (query.expa && query.expa !== '') {
    params.expa = query.expa
  }

  const {
    pageProperties: { pzn },
  } = store.getPublicRuntimeConfig()

  if (pzn) {
    params.pzn = pzn
  }

  if (window.STORYBOOK_ENV) {
    params = null
  }

  let userSession

  clientFockService
    .fetchUserSessionData()
    .then(response => {
      const { data, headers: { [CSRF_FIELD_NAME]: csrfToken } = {} } = response

      const sessionData = {
        user: {
          ...data,
        },
        isEavCustomer: data?.isEavCustomer,
        expa: data?.expa || 'direct',
      }

      store.dispatch(
        updatePageProperties({ csrfToken, expa: sessionData.expa })
      )
      userSession = sessionData
    })
    .catch(error => {
      logger.error(`UserSessionData not fetched: ${error}`)
      userSession = {}
    })
    .finally(async () => {
      userSession = await getUserLoginStatusFromAuthTokenWhenEnabled(
        clientFockService,
        userSession
      )
      store.dispatch(setUserSession(userSession))

      // if the user is logged in and has no ZIP in localStorage,
      // we try and update nowData with ZIP code from the user's billing address
      if (
        userSession?.isLoggedIn &&
        !userSession?.isSensitiveExpired &&
        // Skip request to external source in test, better to mock it
        !IS_JEST
      ) {
        await updateNowDataWithZipFromBillingAddress()
      }
    })
}

export default fetchUserSession
