import JSModal, {
  type JSModalProps,
} from 'views/components/molecules/Modal/JSModal'
import ReactModal, {
  type ReactModalProps,
} from 'views/components/molecules/Modal/ReactModal'

export type ModalProps = (JSModalProps | ReactModalProps) & {
  isClientSideControlled?: boolean
}

const Modal = (props: ModalProps) => {
  if (props.isClientSideControlled) {
    return <ReactModal {...props} />
  }

  return <JSModal {...props} />
}

export default Modal
export { JSModal, ReactModal, JSModalProps, ReactModalProps }
