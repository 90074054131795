import { BAD_REQUEST } from 'shared/consts/codes'

import BackendError from './BackendError'

class BadRequestError extends BackendError {
  constructor(messageWithData) {
    super(messageWithData)
    this.name = this.constructor.name
    this.status = BAD_REQUEST
  }
}

export default BadRequestError
