import stickybits from 'stickybits'

import 'intersection-observer'

const HEADER_SELECTOR = '[data-clientside-hook~="headerContainer"]'
const TOP_MENU_SELECTOR = '[data-clientside-hook~="topMenu"]'
const STICKY_CLASS = 'o-Header--sticky'

export default () => {
  const header = document.querySelector(HEADER_SELECTOR)
  const topMenu = document.querySelector(TOP_MENU_SELECTOR)

  if (header && !window?.CSS?.supports('position: sticky')) {
    stickybits(HEADER_SELECTOR)
  }

  if (topMenu) {
    const IO = new IntersectionObserver(
      ([entry]) => {
        const { isIntersecting, boundingClientRect: targetInfo } = entry

        if (isIntersecting || targetInfo.top <= 0) {
          document.dispatchEvent(
            new CustomEvent('sticky-change', {
              detail: { isSticky: !isIntersecting },
            })
          )
        }
      },
      { threshold: 0 }
    )

    IO.observe(topMenu)

    document.addEventListener('sticky-change', event => {
      const {
        detail: { isSticky },
      } = event

      if (isSticky) {
        header.classList.add(STICKY_CLASS)
      } else {
        header.classList.remove(STICKY_CLASS)
      }
    })
  }
}
