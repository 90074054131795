// @ts-nocheck
import {
  PARTICIPANT_TYPE_SHOP,
  THREAD_ENTITY_TYPE,
} from 'shared/consts/marketplace'
import { Model } from 'shared/services/api/models/Model'

import { ThreadEntity, ThreadEntityV1 } from './ThreadEntity'
import { ThreadMessage, ThreadMessageV1 } from './ThreadMessage'
import { ThreadMetadata, ThreadMetadataV1 } from './ThreadMetadata'
import { ThreadParticipant, ThreadParticipantV1 } from './ThreadParticipant'
import { ThreadTopic, ThreadTopicV1 } from './ThreadTopic'

export interface MessagingThreadV1 {
  authorizedParticipants: ThreadParticipantV1[]
  currentParticipants: ThreadParticipantV1[]
  dateCreated: string
  dateUpdated: string
  entities: ThreadEntityV1[]
  id: string
  messages?: ThreadMessageV1[]
  metadata: ThreadMetadataV1
  title?: string
  topic: ThreadTopicV1
  allMessagesReaded: boolean
}

export class MessagingThread extends Model {
  public readonly authorizedParticipants: ThreadParticipant[]
  public readonly currentParticipants: ThreadParticipant[]
  public readonly dateCreated: string
  public readonly dateUpdated: string
  public readonly entities: ThreadEntity[]
  public readonly id: string
  public readonly messages: ThreadMessage[]
  public readonly metadata: ThreadMetadata
  public readonly orderNumber: string
  public readonly title: string
  public readonly topic: ThreadTopic
  private readonly seller: ThreadParticipant
  public readonly sellerName: string
  public readonly messagesCount: number
  public readonly url: string
  public readonly lastMessageTimestamp: string
  public readonly allMessagesReaded: boolean

  constructor(src: MessagingThreadV1) {
    super(src)
    this.authorizedParticipants = src.authorizedParticipants?.length
      ? src.authorizedParticipants.map(
          (participant: ThreadParticipantV1) =>
            new ThreadParticipant(participant)
        )
      : []
    this.currentParticipants = src.currentParticipants?.length
      ? src.currentParticipants.map(
          (participant: ThreadParticipantV1) =>
            new ThreadParticipant(participant)
        )
      : []
    this.dateCreated = src.dateCreated
    this.dateUpdated = src.dateUpdated
    this.entities = src.entities?.length
      ? src.entities.map((entity: ThreadEntityV1) => new ThreadEntity(entity))
      : []
    this.id = src.id
    if (src.messages?.length) {
      this.messages = src.messages.map(
        (message: ThreadMessageV1) => new ThreadMessage(message)
      )
    }
    if (src.metadata) {
      this.metadata = new ThreadMetadata(src.metadata)
    }
    this.title = src.title
    if (src.topic) {
      this.topic = new ThreadTopic(src.topic)
    }
    this.seller = this.authorizedParticipants.find(
      ({ type }) => type === PARTICIPANT_TYPE_SHOP
    )
    this.orderNumber = this.entities?.find(
      ({ type }) => type === THREAD_ENTITY_TYPE
    )?.id
    this.sellerName =
      this.seller?.organizationDetails?.displayName || this.seller?.displayName
    this.messagesCount = this.metadata?.totalCount
    this.url = `#${this.id}`
    this.lastMessageTimestamp = this.dateUpdated
    this.allMessagesReaded = src.allMessagesReaded
  }
}
