import {
  blacklistedPages,
  NEWSLETTER_MODAL_ID,
} from 'shared/experiments/browser/newsletterModalCH/consts'
import mountNewsletterModalContainer from 'shared/experiments/components/NewsletterModal/mountNewsletterModalFormContainer'

export const NL_MODAL_CH_WAIT_TIME = 7000

const modalCloseEventHandler = (event: MouseEvent) => {
  const target = event.target as HTMLElement
  if (
    !target.closest('.m-Modal__content') &&
    window.location.hash === `#${NEWSLETTER_MODAL_ID}`
  ) {
    window.location.hash = ''
  }
}

export const triggerNewsletterModal = () => {
  document.addEventListener('click', modalCloseEventHandler)

  setTimeout(async function () {
    window.location.hash = NEWSLETTER_MODAL_ID
    window.sessionStorage.setItem(NEWSLETTER_MODAL_ID, 'true')
    await mountNewsletterModalContainer()
    window._uxa = window._uxa || []
    window._uxa.push([
      'trackPageview',
      `${window.location.pathname}${window.location.hash.replace('#', '/__')}`,
    ])
  }, NL_MODAL_CH_WAIT_TIME)
}

export const getIsSourceBlacklisted = (
  page: string,
  searchUrl: string | undefined
) => {
  if (
    !searchUrl ||
    searchUrl.includes('newsletter=ch') ||
    blacklistedPages.includes(page)
  )
    return true

  return (
    searchUrl.includes('adword=google/S_de-CH_Branded_Exact/Brand_exact') ||
    searchUrl.includes('adword=google/S_fr-CH_Branded_Exact/Brand_exact') ||
    searchUrl.includes('adword=google/S_de-CH_Branded/Gen_Shop-Apotheke')
  )
}
