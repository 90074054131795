import { Model } from 'shared/services/api/models/Model'

export interface IncidentResponseV1 {
  isIncidentOpened: boolean
}

export class IncidentResponse extends Model {
  public readonly isIncidentOpened: boolean

  constructor(src: IncidentResponseV1) {
    // @ts-ignore
    super(src)
    this.isIncidentOpened = src.isIncidentOpened
  }
}
