export const DEFAULT_VARIANT_NAME = 'default'
export const DEFAULT_AD_SERVER_VARIANT = 'default'
export const KEVEL_AD_SERVER_VARIANT = 'kevel'

export enum EXPERIMENT_VARIATION {
  DEFAULT = 'default',
  DEFAULT_2 = 'default_2',
  KEVEL = 'kevel',
  VARIATION_1 = 'variation_1',
  VARIATION_2 = 'variation_2',
  V1 = 'v1',
  V2 = 'v2',
  V3 = 'v3',
  V4 = 'v4',
  V5 = 'v5',
  V6 = 'v6',
  V7 = 'v7',
  V8 = 'v8',
}

export const NON_DEFAULT_AD_SERVER_VARIANTS = [
  KEVEL_AD_SERVER_VARIANT,
  EXPERIMENT_VARIATION.V1,
]
