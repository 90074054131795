// @ts-nocheck
import { Dispatch } from 'react'
import { applyMiddleware, compose, createStore } from 'redux'
import type {
  AnyAction,
  Reducer as RReducer,
  Store as RStore,
  Unsubscribe,
} from 'redux'
import subscribeActionMiddleware from 'redux-subscribe-action'
import thunk from 'redux-thunk'

import getPreloadedState from 'shared/store/getPreloadedState'
import {
  COMPONENT_INITIAL_STATE_KEY,
  PUBLIC_RUNTIME_CONFIG_KEY,
} from 'shared/store/helper/consts'
import { PublicRuntimeConfig } from 'types/shopConfig'

import { unsubscribeAll } from './subscribe'

/**
 * singleton proxy class for the redux store
 * prevents the need of having state in the module itself which causes trouble with storybook
 * and hydrated/rendered components
 */
class Store<Reducer extends RReducer, State> {
  protected initialized = false
  protected reducer: Reducer | null = null
  protected initialState: State | Record<string, never> | null = null

  public store: RStore<State, AnyAction>

  public constructor(reducer: Reducer) {
    this.reducer = reducer
  }

  public initStore(initialState: State | Record<string, never> = {}): void {
    // we save the initial state and reducers inside the singleton so we can reset it later
    if (!this.initialized) {
      const preloadedState = getPreloadedState()
      const mergedState = {
        ...initialState,
        ...preloadedState,
      }

      this.initialState = mergedState
      this.initialized = true
    }

    this.createStore()
  }

  public createStore(): void {
    /* eslint-disable no-restricted-globals */
    const composeEnhancers =
      (typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
      compose
    /* eslint-enable no-restricted-globals */

    this.store = createStore(
      this.reducer,
      this.initialState,
      composeEnhancers(applyMiddleware(subscribeActionMiddleware, thunk))
    )
  }

  public getStore(): RStore<State, AnyAction> {
    return this.store
  }

  // clears the store and all subscriptions
  // this is needed for storybook to work correctly, please use with care
  public clearStore(): void {
    unsubscribeAll()
    this.store = null
  }

  // Proxy the redux store api
  public getState(): State {
    return this.store.getState()
  }

  public dispatch = (action): Dispatch<AnyAction> => {
    return this.store.dispatch(action)
  }

  public subscribe(listener): Unsubscribe {
    return this.store.subscribe(listener)
  }

  public replaceReducer(nextReducer): void {
    this.store.replaceReducer(nextReducer)
  }

  // shortcut  methods
  public getPublicRuntimeConfig(): PublicRuntimeConfig {
    const state = this.getState()
    return state[PUBLIC_RUNTIME_CONFIG_KEY]
  }

  // init state
  public getComponentInitialState<T>(): T | Record<string, never> {
    const state = this.getState()
    return state[COMPONENT_INITIAL_STATE_KEY] || {}
  }
}

export default Store
