// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

export interface NewMessageResponseV1 {
  threadId: string
  messageId: string
}

export class NewMessageResponse extends Model {
  public readonly threadId: string
  public readonly messageId: string

  constructor(src: NewMessageResponseV1) {
    super(src)
    this.threadId = src.threadId
    this.messageId = src.messageId
  }
}
