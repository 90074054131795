import Cookies from 'js-cookie'
import { stringify } from 'query-string'

import { PRODUCT_TARGET_PAGE_CODE } from 'shared/consts'
import { AD_SERVER_USER_ID_COOKIE } from 'shared/consts/adServer'
import {
  TYPE_ADD_TO_CART_CENTER,
  TYPE_CART_BOTTOM,
} from 'shared/consts/crosssellWidgets'
import { ECONDA_VISITOR_ID_COOKIE } from 'shared/consts/econdaCookieIds'
import { CSRF_FIELD_NAME } from 'shared/consts/httpHeaders'
import { NOT_FOUND } from 'shared/consts/statusCodes'
import {
  isRetailMediaAdServerEnabled,
  isRetailMediaCrossSellServiceEnabled,
} from 'shared/experiments/utils/retailMedia'
import store from 'shared/store'
import { getSetAdUserIdentity } from 'shared/utils/adServerUtils'
import assert from 'shared/utils/assert'
import { isTempEmail } from 'shared/utils/isTempUser'
import { omit } from 'shared/utils/objectUtils'
import { createTenantPath } from 'shared/utils/routing'

import APIService from './APIService'

class ClientFockService extends APIService {
  constructor(props) {
    super(props)

    const { tenant, language, locale, coreShopEnvironment, mountPoints } = props

    assert(tenant, 'ClientFockService: no "tenant" option provided')
    assert(
      coreShopEnvironment,
      'ClientFockService: no "coreShopEnvironment" option provided'
    )
    assert(language, 'ClientFockService: no "language" option provided')

    this.baseParams = {
      tenant,
      tenantPath: createTenantPath(tenant, coreShopEnvironment),
      language,
      locale,
      coreShopEnvironment,
      mountPoints,
    }
  }

  getUserSessionDataPath() {
    return `/api/session/v1/${this.baseParams.tenantPath}/customer`
  }

  getNotepadResourcePath(upid) {
    const { mountPoints } = this.baseParams
    return mountPoints.getNotepadEntry(upid)
  }

  getNotepadResourceDeletePath(upid) {
    const { mountPoints } = this.baseParams
    return mountPoints.getNotepadDeleteEntry(upid)
  }

  getAccountNewsletterSubscribePath() {
    const { mountPoints } = this.baseParams
    return mountPoints.getAccountNewsletterSubscribe()
  }

  getAccountNewsletterUnsubscribePath() {
    const { mountPoints } = this.baseParams
    return mountPoints.getAccountNewsletterUnsubscribe()
  }

  getUserLoginStatusPath() {
    const { mountPoints } = this.baseParams
    return mountPoints.getUserLoginStatusUrl()
  }

  static getUpdateDataPath() {
    return 'updateNotepad.go'
  }

  static extractEmvidFromCookie(cookie) {
    if (!cookie) {
      return null
    }
    const parts = cookie.split(':')
    return parts.length > 0 ? parts[0] : null
  }

  async fetchUserSessionData(passedConfig) {
    const config = { ...passedConfig }
    this.logger.debug('Fetching UserSessionData')
    config.params = { ...config.params }

    const response = await this.get(this.getUserSessionDataPath(), config)
    const {
      data: { email },
    } = response

    this.logger.debug('UserSessionData fetched')
    response.data.isTempUser = isTempEmail(email)
    return response
  }

  postProductRatingPath() {
    return 'rateProduct.go'
  }

  async postProductRating(formValues) {
    // we need to set the base to '/'
    // as we need to connect to old shop and not to fock
    const config = {
      baseURL: '/',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        [CSRF_FIELD_NAME]: formValues[CSRF_FIELD_NAME],
      },
    }

    this.logger.debug('Sending ProductRating')
    const response = await this.post(
      this.postProductRatingPath(),
      stringify(formValues),
      config
    )
    this.logger.debug('ProductRating send!')
    return response
  }

  async fetchCrossSell(props) {
    const { path, expa } = props

    let passedParams = props.params

    this.logger.debug('Fetching CrossSell')

    const {
      pageProperties: { experiments },
      publicConfig: {
        crosssell: { widgets },
        adServer: { crossSellWidgetIds: adServerCrossSellWidgetIds = [] },
      },
    } = store.getPublicRuntimeConfig() || {}

    // TODO: what happens if the cookie isn't set
    const cookie = Cookies.get(ECONDA_VISITOR_ID_COOKIE)

    let user
    let targetingKeywords
    let excl
    let targetingContext

    /**
     * CRO-2248. On cartIntermediate and cart pages there are 2 crosssell
     * widgets. Sometimes they contain similar products. To avoid repeating,
     * we include "ecxl" parameter into the econda query for the bottom widget,
     * that contains the productIds of the top widget (we saved before
     * in the sessionStorage).
     **/

    const wid = Number(passedParams?.wid || null)

    if (
      wid &&
      [widgets[TYPE_ADD_TO_CART_CENTER], widgets[TYPE_CART_BOTTOM]].includes(
        wid
      )
    ) {
      excl = sessionStorage.getItem('excludedProducts')
    }

    const shouldFetchCrossSellFromRetailMedia =
      isRetailMediaCrossSellServiceEnabled({
        adServerCrossSellWidgetIds,
        experiments,
        wid,
        widgets,
      }) ||
      isRetailMediaAdServerEnabled({
        widgets,
        experiments,
        wid,
        adServerCrossSellWidgetIds,
      })

    if (shouldFetchCrossSellFromRetailMedia) {
      user = {
        ...getSetAdUserIdentity(Cookies),
        userId: Cookies.get(AD_SERVER_USER_ID_COOKIE),
      }

      targetingKeywords = window._targeting?.keywords?.join(',') || ''
      targetingContext = omit(window._targeting?.context || {}, [
        'pzn',
        'upid',
        'secondLevelCategories',
      ])
    } else {
      passedParams = omit(passedParams, [
        'adServerContext',
        'targetingKeywords',
      ])
    }

    const params = {
      ...this.baseParams,
      ...passedParams,
      expa,
      emvid: ClientFockService.extractEmvidFromCookie(cookie),
      targetingKeywords,
      adServerContext: targetingContext,
      user,
      csc: expa,
      'ctxcustom.content': expa,
      excl,
    }

    // Remove any unexpected URL parameters for the CrossSell widgets
    delete params.mountPoints

    const { status, data } = await this.get(path, { params })
    sessionStorage.removeItem('excludedProducts')

    this.logger.debug('crosssell fetched')

    return {
      status,
      data,
    }
  }

  async addProductToWishList(options) {
    const config = {
      ...options,
      baseURL: '/',
      params: {
        ...options.params,
        notepadAction: 'add',
        targetPageCode: PRODUCT_TARGET_PAGE_CODE,
      },
    }
    this.logger.debug('adding product to the wishlist')
    const response = await this.get(
      ClientFockService.getUpdateDataPath(),
      config
    )
    this.logger.debug('added product to the wishlist')
    return response
  }

  async deleteProductFromWishList(options) {
    const config = {
      ...options,
      baseURL: '/',
      params: {
        ...options.params,
        notepadAction: 'deleteProduct',
        targetPageCode: PRODUCT_TARGET_PAGE_CODE,
      },
    }
    this.logger.debug('removing product from the wishlist')
    const response = await this.get(
      ClientFockService.getUpdateDataPath(),
      config
    )
    this.logger.debug('removed product from the wishlist')
    return response
  }

  async submitProductConsultationMessageForm(passedConfig) {
    const config = { ...passedConfig, baseURL: '/' }
    config.params = { ...config.params, cb: new Date().getTime() }

    this.logger.debug('Submitting ProductConsultationMessageForm')
    const response = await this.get('emailForm.go', config)
    this.logger.debug('ProductConsultationMessageForm submitted')

    return response
  }

  async submitProductConsultationCallbackForm(passedConfig) {
    const config = { ...passedConfig, baseURL: '/' }
    config.params = { ...config.params, cb: new Date().getTime() }

    this.logger.debug('Submitting ProductConsultationMessageForm')
    const response = await this.get('callbackForm.go', config)
    this.logger.debug('ProductConsultationMessageForm submitted')

    return response
  }

  getOptimizelyTrackPath() {
    return `experiments/${this.baseParams.tenantPath}/${this.baseParams.language}/track`
  }

  getOptimizelyActivatePath() {
    return `experiments/${this.baseParams.tenantPath}/${this.baseParams.language}/activate`
  }

  async activateExperiments(featureKeys) {
    this.logger.debug(`Activating features: ${featureKeys}`, 'optimizelyAction')

    const response = await this.post(
      this.getOptimizelyActivatePath(),
      {
        featureKeys,
      },
      { withCredentials: true }
    )

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: 'exp_activated', exp_names: featureKeys })

    this.logger.debug(`Features activated: ${featureKeys}`, 'optimizelyAction')

    return response
  }

  async trackExperiment(eventKey, eventTags) {
    this.logger.debug(`Tracking event: ${eventKey}`, 'optimizelyAction')

    this.sendBeacon(this.getOptimizelyTrackPath(), {
      eventKey,
      eventTags,
    })

    this.logger.debug(
      `Tracking is done for event: ${eventKey}`,
      'optimizelyAction'
    )
  }

  getMonolithNewsletterPath() {
    return '/newsletter.htm'
  }

  async postNewsletter(formValues) {
    const config = {
      baseURL: '/',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      withCredentials: true,
    }

    this.logger.debug('Sending Newsletter')
    const response = await this.post(
      this.getMonolithNewsletterPath(),
      stringify(formValues),
      config
    )
    this.logger.debug('Newsletter send!')
    return response
  }

  async fetchProductFromNotepad(upid) {
    const config = {
      headers: {
        'content-type': 'application/json',
      },
      withCredentials: true,
      isErrorLoggingSkipped: response => `${response?.status}` === NOT_FOUND,
    }
    this.logger.debug(`Fetching Notepad Product ${upid}`)
    const response = await this.get(
      `..${this.getNotepadResourcePath(upid)}`,
      config
    )
    this.logger.debug(`Notepad Product ${upid} fetched!`)

    return response
  }

  async addProductToNotepad(upid) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    }
    this.logger.debug(`Adding Product ${upid} to Notepad`)
    const response = await this.post(
      `..${this.getNotepadResourcePath(upid)}`,
      {},
      config
    )
    this.logger.debug(`Product ${upid} added to Notepad!`)

    return response
  }

  async removeProductFromNotepad(upid) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    }
    this.logger.debug(`Remove Product ${upid} from Notepad`)
    const response = await this.post(
      `..${this.getNotepadResourceDeletePath(upid)}`,
      {},
      config
    )
    this.logger.debug(`Product ${upid} removed from Notepad!`)

    return response
  }

  async accountNewsletterSubscribe() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    }
    this.logger.debug(`Add Newsletter subscription.`)

    const response = await this.post(
      `..${this.getAccountNewsletterSubscribePath()}`,
      {},
      config
    )
    this.logger.debug(`Newsletter subscription added.`)

    return response
  }

  async accountNewsletterUnsubscribe() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    }
    this.logger.debug(`Request Newsletter unsubscription.`)

    const response = await this.post(
      `..${this.getAccountNewsletterUnsubscribePath()}`,
      {},
      config
    )
    this.logger.debug(`Newsletter unsubscription success.`)

    return response
  }

  async getUserLoginStatus() {
    const config = {
      headers: {
        'content-type': 'application/json',
      },
      baseURL: '/',
    }
    this.logger.debug('Fetching user login status')
    const response = await this.get(`${this.getUserLoginStatusPath()}`, config)
    this.logger.debug('Fetching user login status success')

    return response
  }

  async trackEvents(url) {
    this.logger.debug('Request event(s) subscription to ad-server - init')
    await this.get(url)
    this.logger.debug('Request event(s) subscription to ad-server - success')
  }
}

export default ClientFockService
