export const THREAD_ENTITY_TYPE = 'MMP_ORDER'
export const SUBJECT_TYPE_INCIDENT_CLOSE = 'INCIDENT_CLOSE'
export const SUBJECT_TYPE_INCIDENT_OPEN = 'INCIDENT_OPEN'
export const SUBJECT_TYPE_OFFER_MESSAGING = 'OFFER_MESSAGING'
export const SUBJECT_TYPE_ORDER_MESSAGING = 'ORDER_MESSAGING'
export const SUBJECT_TYPE_CANCELATION = 'CANCELATION'
export const PARTICIPANT_TYPE_OPERATOR = 'OPERATOR'
export const PARTICIPANT_TYPE_CUSTOMER = 'CUSTOMER'
export const PARTICIPANT_TYPE_SHOP = 'SHOP'
export const PARTICIPANT_TYPE_ID = '2058' // This should be removed and we should not hardcoded the SHOP ID
export const REASON_CODE = 'REASON_CODE'
export const ALLOWED_SUBJECT_CODES: string[] = [
  '21',
  '24',
  '22',
  '25',
  '20',
  '26',
]

export const CONTACT_SELLER_INITIAL_DEFAULT_MESSAGE_ID =
  /*i18n*/ 'account.messageReason.defaultMessage.shippingQuestion'
export const OPEN_INCIDENT_INITIAL_DEFAULT_MESSAGE_ID =
  /*i18n*/ 'account.messageReason.defaultMessage.articleNotShipped'

export const VISIBLE_THREADS_COUNT = 5

export const ALLOWED_FILE_TYPES_EXTENSIONS_MAP = new Map()
  .set('application/pdf', 'pdf')
  .set('image/jpeg', 'jpeg')
  .set('image/jpg', 'jpg')
  .set('image/png', 'png')
  .set('video/mp4', 'mp4')
  .set('video/quicktime', 'mov')

export const BYTE_TO_MEGABYTES_DENOMINATOR = 1.049e6

export const MAX_MESSAGE_LENGTH = 50000
export const MIN_MESSAGE_LENGTH = 3
export const MAX_FILE_SIZE_MB = 10
export const MAX_SIZE_ONE_FILE = 3

export const REQUEST_INVOICE_SUBJECT_CODE = '24' // The Mirakl code for invoice request subject message
export const REQUEST_CANCELLATION_SUBJECT_CODE = '21' // The Mirakl code for cancellation request subject message
export const MAX_MESSAGE_HTML_NESTING_LEVEL = 10
