export const VISIBLE_ENTRIES_COUNT = 5
export const DEFAULT_ENTRIES_PAGE = 0

export enum NOTEPAD_PRODUCT_STOCK_STATUS {
  AVAILABLE = 'available',
  OUT_OF_SALE = 'out_of_sale',
  OUT_OF_STOCK = 'out_of_stock',
  NOT_IN_CATALOG = 'not_in_catalog',
  FREE = 'free',
  VARIANT = 'variant',
}
