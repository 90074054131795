import BackendError from './BackendError'

class AdServerNetworkError extends BackendError {
  constructor(error: Error) {
    super(error)

    this.name = this.constructor.name

    Error.captureStackTrace(this, this.constructor)
  }
}

export default AdServerNetworkError
