// @ts-nocheck
import type { Dispatch } from 'redux'

import * as CHECKOUT from 'shared/consts/checkout'
import { Address, AddressType } from 'shared/store-checkout/types/addresses'
import {
  CustomerMasterData,
  SubOrder,
} from 'shared/store-checkout/types/orderDraft'
import { IState } from 'shared/store-checkout/types/redux'
import { isEmpty, objectifyArrayWithIds } from 'shared/utils/objectUtils'
import buildClientAPI from 'views/providers/clientAPI'

import {
  addCheckoutErrorAction,
  getOrderDraftId,
  getSubOrder,
  getSubOrders,
} from './common'
import { hasOrderDraft, updateOrderDraftAction } from './orderDraft'

// ---------------------------- ACTION TYPES -------------------------

export const PUP_ERROR = 'PUP_ERROR'
export const FETCH_DOOR_DELIVERY_ADDRESSES_SUCCESS =
  'FETCH_DOOR_DELIVERY_ADDRESSES_SUCCESS'
export const FETCH_DOOR_DELIVERY_ADDRESSES_REQUEST =
  'FETCH_DOOR_DELIVERY_ADDRESSES_REQUEST'
export const FETCH_PUP_ADDRESSES_REQUEST = 'FETCH_PUP_ADDRESSES_REQUEST'
export const FETCH_PUP_ADDRESSES_SUCCESS = 'FETCH_PUP_ADDRESSES_SUCCESS'
export const ADD_ADDRESS = 'ADD_ADDRESS'
export const CHANGE_ADDRESS_SUCCESS = 'CHANGE_ADDRESS_SUCCESS'
export const SET_ADDRESS_TYPE = 'SET_ADDRESS_TYPE'
export const SET_DEFAULT_SHIPPING_ADDRESS_ID = 'SET_DEFAULT_SHIPPING_ADDRESS_ID'
export const SET_BILLING_ADDRESS = 'SET_BILLING_ADDRESS'
export const SET_SHIPPING_ADDRESS_INFO = 'SET_SHIPPING_ADDRESS_INFO'

// ---------------------------- SELECTORS -------------------------

export const hasAddressFetched = (state: IState): boolean => {
  try {
    const hasOrderDraftFetched = hasOrderDraft(state)
    const {
      checkout: { hasDoorDeliveryFetched, hasPupFetched },
    } = state

    return hasOrderDraftFetched && hasDoorDeliveryFetched && hasPupFetched
  } catch (e) {
    return false
  }
}

export const getSubOrderBillingAddress = (
  state: IState,
  subOrderId: string = CHECKOUT.SA_SELLER_ID
): Address | Record<string, never> => {
  try {
    const subOrder = getSubOrder(state, subOrderId)
    const firstSubOrder = state?.checkout?.orderDraft?.subOrders[0]

    return subOrder?.billingAddress || firstSubOrder?.billingAddress || {}
  } catch {
    return {}
  }
}

export const getBillingAddressId = (state: IState): string => {
  try {
    const {
      checkout: { addresses },
    } = state

    const addressArray = Object.values(addresses)
    const { id: billingAddressId } =
      addressArray.find((address: Address) => {
        // because billing is now referenced as an enum this string comparison would break if you compare it to anything outside of the enum
        return address.type === 'billing'
      }) || {}

    return billingAddressId || ''
  } catch (e) {
    return ''
  }
}

export const getDefaultShippingAddressId = (state: IState): string => {
  try {
    const {
      checkout: { addresses },
    } = state

    const addressArray = Object.values(addresses)
    const { id } =
      addressArray.find(address => {
        return address.default
      }) || {}

    let defaultShippingAddressId = id
    if (!defaultShippingAddressId) {
      defaultShippingAddressId = addressArray[0].id // just take the first addresses
    }

    return defaultShippingAddressId || ''
  } catch (e) {
    return ''
  }
}

export const getSelectedShippingAddressId = (
  state: IState,
  subOrderId: string
) => {
  try {
    let selectedShippingAddressId
    const subOrder = getSubOrder(state, subOrderId)
    if (isEmpty(subOrder?.deliveryAddress)) {
      selectedShippingAddressId = getDefaultShippingAddressId(state)
    } else {
      const {
        deliveryAddress: { id },
      } = subOrder
      selectedShippingAddressId = id
    }

    return selectedShippingAddressId || ''
  } catch (e) {
    return ''
  }
}

export const getSelectedShippingAddress = (
  state: IState,
  subOrderId: string
): string => {
  try {
    const selectedShippingAddressId = getSelectedShippingAddressId(
      state,
      subOrderId
    )

    const {
      checkout: { addresses },
    } = state

    const selectedShippingAddress = addresses[selectedShippingAddressId]

    if (selectedShippingAddress) {
      if ('pickupStation' in selectedShippingAddress) {
        return `${selectedShippingAddress.pickupStation.name}, ${selectedShippingAddress.firstName} ${selectedShippingAddress.lastName}, ${selectedShippingAddress.street} ${selectedShippingAddress.streetNo}, ${selectedShippingAddress.zip} ${selectedShippingAddress.city}`
      } else {
        return `${selectedShippingAddress.firstName} ${selectedShippingAddress.lastName}, ${selectedShippingAddress.street} ${selectedShippingAddress.streetNo}, ${selectedShippingAddress.zip} ${selectedShippingAddress.city}`
      }
    }

    return ''
  } catch (e) {
    return ''
  }
}

export const hasClientBillingAddress = (state: IState): boolean => {
  try {
    const {
      checkout: { addresses },
    } = state
    const values = Object.values(addresses)

    return values.some(address => {
      return address.type === 'billing' && address.street !== ''
    })
  } catch (e) {
    return false
  }
}

export const isPupSelected = (state: IState, subOrderId: string): boolean => {
  try {
    const subOrder = getSubOrder(state, subOrderId)
    const { deliveryAddress } = subOrder
    let selectedDeliveryAddress = deliveryAddress

    if (isEmpty(deliveryAddress)) {
      const defaultAddressId = getDefaultShippingAddressId(state).toString()
      const {
        checkout: {
          addresses: { [defaultAddressId]: defaultAddress },
        },
      } = state
      selectedDeliveryAddress = defaultAddress
    }

    return 'pickupStation' in selectedDeliveryAddress
  } catch (e) {
    return false
  }
}

export const getShippingAddresses = (state: IState): Address[] => {
  try {
    const {
      checkout: { addresses },
    } = state
    // TODO: consider using Object.keys instead
    const entries = Object.entries(addresses)
    const shippingAddresses = entries.map(address => {
      return {
        id: address[0],
        ...address[1],
      }
    })

    return shippingAddresses
  } catch (e) {
    return []
  }
}

export const hasOrderFreightTransport = (
  state: IState,
  subOrderId: string
): boolean => {
  try {
    const subOrder = getSubOrder(state, subOrderId)
    const hasFreightTransport =
      subOrder?.orderType === CHECKOUT.ORDER_TYPE.FREIGHT

    return hasFreightTransport || false
  } catch (e) {
    return false
  }
}

export const isAnyPupSelectedInOrderDraft = (state: IState): boolean => {
  try {
    const subOrders = getSubOrders(state)

    return subOrders.some(order => {
      const { subOrderId } = order
      const isPupSelectedInOrder = isPupSelected(state, subOrderId)
      if (order.id !== CHECKOUT.SA_SELLER_ID && isPupSelectedInOrder) {
        return true
      }

      return false
    })
  } catch (e) {
    return false
  }
}

export const hasCartRxEntries = (state: IState): boolean => {
  try {
    const {
      checkout: {
        orderDraft: {
          subOrders,
          orderMasterData: { prescriptionFollows },
        },
      },
    } = state
    const hasCartRX = subOrders.some(subOrder => {
      if (subOrder.sellerId === CHECKOUT.SA_SELLER_ID) {
        const hasSubOrderRx = subOrder.orderDocuments.some(orderDocument => {
          return [
            CHECKOUT.DOCUMENT_TYPE.FREE_PRESCRIPTION,
            CHECKOUT.DOCUMENT_TYPE.INSURANCE_PRESCRIPTION,
            CHECKOUT.DOCUMENT_TYPE.PRIVATE_PRESCRIPTION,
          ].includes(orderDocument.type)
        })

        return hasSubOrderRx
      }

      return false
    })

    const isPrescriptionFollow = !!prescriptionFollows

    return hasCartRX || isPrescriptionFollow
  } catch (e) {
    return false
  }
}

export const hasPhoneNumber = (state: IState): boolean => {
  try {
    const {
      checkout: {
        orderDraft: {
          customerMasterData: { phoneNumber },
        },
      },
    } = state
    if (typeof phoneNumber !== 'undefined') {
      return !!phoneNumber
    }

    return false
  } catch (e) {
    return false
  }
}

export const getPhoneNumber = (state: IState): string => {
  try {
    const {
      checkout: {
        orderDraft: {
          customerMasterData: { phoneNumber },
        },
      },
    } = state

    return phoneNumber
  } catch (e) {
    return ''
  }
}

export const getCustomerSalutationAndName = (
  state: IState
):
  | Pick<CustomerMasterData, 'salutation' | 'firstName' | 'lastName'>
  | Record<string, never> => {
  try {
    return {
      salutation: state?.checkout?.orderDraft?.customerMasterData?.salutation,
      firstName: state?.checkout?.orderDraft?.customerMasterData?.firstName,
      lastName: state?.checkout?.orderDraft?.customerMasterData?.lastName,
    }
  } catch (e) {
    return {}
  }
}

// ---------------------------- ACTIONS -------------------------
// TODO : add return types especially for all actions
// TODO: Refactor all actions to have the same naming schema and end in the word Action
export const addPupErrorAction = (error: unknown) => {
  return {
    type: PUP_ERROR,
    error,
  }
}

export const fetchDoorDeliveryAddressesSuccessAction = (doorDeliveryAddresses: {
  [key: string]: Address
}) => {
  return {
    type: FETCH_DOOR_DELIVERY_ADDRESSES_SUCCESS,
    doorDeliveryAddresses,
  }
}

export const fetchDoorDeliveryAddressesRequest = () => {
  return {
    type: FETCH_DOOR_DELIVERY_ADDRESSES_REQUEST,
  }
}

export const fetchPupAddressesSuccessAction = (pupAddresses: {
  [key: string]: Address
}) => {
  return {
    type: FETCH_PUP_ADDRESSES_SUCCESS,
    pupAddresses,
  }
}

export const fetchPupAddressesRequest = () => {
  return {
    type: FETCH_PUP_ADDRESSES_REQUEST,
  }
}

export const changeAddressSuccessAction = (address: Address[]) => {
  return {
    type: CHANGE_ADDRESS_SUCCESS,
    address,
  }
}

// currently not used
export const setDefaultShippingAddressIdAction = (
  shippingAddressId: string
) => {
  return {
    type: SET_DEFAULT_SHIPPING_ADDRESS_ID,
    shippingAddressId,
  }
}

// currently not used
export const setDefaultShippingAddressIdSuccessAction = (
  shippingAddressId: string
) => {
  return {
    type: SET_DEFAULT_SHIPPING_ADDRESS_ID,
    shippingAddressId,
  }
}

export const addAddressSuccessAction = (
  address: Address,
  addressId: string
) => {
  return {
    type: ADD_ADDRESS,
    address,
    addressId,
  }
}

export const setAddressTypeAction = (
  addressId: string,
  addressType: AddressType
) => {
  return {
    type: SET_ADDRESS_TYPE,
    addressId,
    addressType,
  }
}

export const setBillingAddressSuccessAction = (
  subOrderId: string,
  billingAddressId: string
) => {
  return {
    type: SET_BILLING_ADDRESS,
    subOrderId,
    billingAddressId,
  }
}

export const setShippingAddressSuccessAction = (
  subOrder: SubOrder,
  shippingAddress: Address
) => {
  return {
    type: SET_SHIPPING_ADDRESS_INFO,
    subOrder,
    shippingAddress,
  }
}

// ---------------------------- THUNKS -------------------------

export const getSubOrderShippingAddress = (
  state: IState,
  subOrderId: string = CHECKOUT.SA_SELLER_ID
): Address | null => {
  const subOrder = getSubOrder(state, subOrderId)
  const firstSubOrder = state?.checkout?.orderDraft?.subOrders[0]

  return subOrder?.deliveryAddress || firstSubOrder?.deliveryAddress || null
}

export const fetchDoorDeliveryAddressesAction = (): ((
  dispatch
) => Promise<void>) => {
  return async dispatch => {
    dispatch(fetchDoorDeliveryAddressesRequest())
    try {
      const { addressService } = buildClientAPI()
      const response = await addressService.doorDelivery()
      const { addresses } = response.data
      const objectifiedAddresses = objectifyArrayWithIds(addresses)
      dispatch(fetchDoorDeliveryAddressesSuccessAction(objectifiedAddresses))
    } catch (e) {
      dispatch(
        addCheckoutErrorAction(`fetchDoorDeliveryAddressesAction: ${e.message}`)
      )
    }
  }
}

export const fetchPupAddressesAction = (): ((dispatch) => Promise<void>) => {
  return async dispatch => {
    dispatch(fetchPupAddressesRequest())
    try {
      const { addressService } = buildClientAPI()
      const response = await addressService.pickupStationDelivery()
      const { addresses } = response.data
      const objectifiedAddresses = objectifyArrayWithIds(addresses)
      dispatch(fetchPupAddressesSuccessAction(objectifiedAddresses))
    } catch (e) {
      dispatch(addCheckoutErrorAction(`fetchPupAddressesAction: ${e.message}`))
    }
  }
}

export const setBillingAddressAction = (
  subOrderId: string,
  billingAddressId: string
): ((dispatch: Dispatch, getState: () => IState) => Promise<void>) => {
  return async (dispatch, getState) => {
    try {
      const { checkoutService } = buildClientAPI()
      const orderDraftId = getOrderDraftId(getState())
      const { data: orderDraft } = await checkoutService.updateBillingAddress({
        orderDraftId,
        subOrderId,
        billingAddressId,
      })
      dispatch(setBillingAddressSuccessAction(subOrderId, billingAddressId))
      dispatch(updateOrderDraftAction(orderDraft, 'setBillingAddressAction'))
    } catch (e) {
      dispatch(addCheckoutErrorAction(`setBillingAddressAction: ${e.message}`))
    }
  }
}

export const changeAddressAction = (
  addressId: string,
  address: Address
): ((dispatch: Dispatch, getState: () => IState) => Promise<void>) => {
  return async (dispatch, getState) => {
    try {
      const { addressService } = buildClientAPI()
      const addressData = {
        ...address,
        countryCode: getState().publicRuntimeConfig.publicConfig.language,
      }
      const { data } = await addressService.updateAddress(
        addressId,
        addressData
      )
      dispatch(changeAddressSuccessAction(data))
      const { id, type } = data
      if (type === 'billing') {
        const { subOrderId } = getSubOrder(getState())
        dispatch(setBillingAddressAction(subOrderId, id))
      }
    } catch (e) {
      dispatch(addCheckoutErrorAction(`changeAddressAction: ${e.message}`))
    }
  }
}

export const setShippingAddressAction = (
  subOrderId: string,
  shippingAddressId: string
): ((dispatch: Dispatch, getState: () => IState) => Promise<void>) => {
  return async (dispatch, getState) => {
    try {
      const { checkoutService } = buildClientAPI()
      const state = getState()
      const orderDraftId = getOrderDraftId(state)
      const subOrder = getSubOrder(state, subOrderId)
      const {
        checkout: {
          addresses: { [shippingAddressId]: shippingAddress },
        },
      } = state

      dispatch(setShippingAddressSuccessAction(subOrder, shippingAddress)) // already dispatch locally for responsiveness
      const { subOrderId: finalSubOrderId } = subOrder
      const { data: orderDraft } = await checkoutService.updateShippingAddress({
        orderDraftId,
        subOrderId: finalSubOrderId,
        shippingAddressId,
      })
      dispatch(updateOrderDraftAction(orderDraft, 'setShippingAddressAction'))
    } catch (e) {
      dispatch(addCheckoutErrorAction(`setShippingAddressAction: ${e.message}`))
    }
  }
}

export const addAddressAction = (
  address: Address
): ((dispatch, getState) => Promise<void>) => {
  return async (dispatch, getState) => {
    try {
      const { addressService } = buildClientAPI()
      const addressData = {
        ...address,
        countryCode: getState().publicRuntimeConfig.publicConfig.language,
      }
      const { data: responseAddress } = await addressService.createAddress(
        addressData
      )
      const { id, type } = responseAddress
      dispatch(addAddressSuccessAction(responseAddress, id))
      if (type === 'billing') {
        const { subOrderId } = getSubOrder(getState())
        dispatch(setBillingAddressAction(subOrderId, id))
      }
    } catch (e) {
      dispatch(addCheckoutErrorAction(`addAddressAction: ${e.message}`))
    }
  }
}

export const hasOrderCoolingRequired = (state, subOrderId) => {
  try {
    const subOrder = getSubOrder(state, subOrderId)
    const { orderDocuments } = subOrder
    const isCoolingRequired = orderDocuments.some(orderDocument => {
      const { documentPositions } = orderDocument

      return documentPositions.some(documentPosition => {
        return documentPosition.isCoolingRequired
      })
    })

    return isCoolingRequired || false
  } catch (e) {
    return false
  }
}
