import { arePznsEqual } from 'shared/services/api/helpers/pznValidator'
import { padWithLeadingZeroes } from 'shared/services/api/helpers/validatorUtils'

class IdValidatorClass {
  regExpValidSaeId = /^([a-z]{0,2})(\d+)$/i
  regExpValidMiraklId = /upm[A-Z-a-z0-9]{6}/i
  regExpExceptionsId = [/^d\d$/i]
  idMaxLength = 12
  idMinLength = 8
  optionalPrefix = 'D'

  static shouldIdBePadded(id) {
    return id.substring(0, 3) !== 'upm'
  }

  isMatchingAnyException(id) {
    return this.regExpExceptionsId.some(regex => regex.test(id))
  }

  padIdIfNeeded(id) {
    if (!this.constructor.shouldIdBePadded(id)) {
      return id
    }
    const saeId = id.match(this.regExpValidSaeId)

    if (!Array.isArray(saeId)) {
      return id
    }

    const letters = saeId[1]
    const digits = saeId[2]

    if (letters) {
      const lettersUpperCase = letters.toUpperCase()
      const prefix =
        lettersUpperCase === this.optionalPrefix ? '' : lettersUpperCase

      return prefix ? id : padWithLeadingZeroes(digits, this.idMinLength)
    }

    return padWithLeadingZeroes(digits, this.idMinLength)
  }

  validate(id) {
    this.id = id

    this.res = {
      valid: false,
      id: null,
    }

    return this.strategy()
  }

  strategy() {
    const { id } = this

    this.res.valid = this.isValidId(id)

    if (this.res.valid) {
      this.res.id = this.padIdIfNeeded(id)
    }

    return this.res
  }

  validateAlgoliaPznQuery(id) {
    const notValid = {
      valid: false,
      id: null,
    }

    if (!this.isValidId(id)) {
      return notValid
    }

    const paddedId = this.padIdIfNeeded(id)

    if (paddedId.length < this.idMinLength) {
      return notValid
    }

    return validate(paddedId)
  }

  isValidId(id) {
    if (!id || id.length > this.idMaxLength) {
      return false
    }

    let matches = id.match(this.regExpValidSaeId)

    if (!matches) {
      matches = id.match(this.regExpValidMiraklId)
    }

    return Boolean(matches) && !this.isMatchingAnyException(id)
  }

  areIdsEqual(id1, id2) {
    if ([id1, id2].some(id => !id)) {
      return false
    }

    const matches = id1.match(this.regExpValidSaeId)

    // If id matches first regex then it comes from SAE
    // If id comes from sae that means ID === PZN
    // We can use pzn validator to confirm equality of those id's
    if (matches) {
      return arePznsEqual(id1, id2)
    }

    return id1 === id2
  }
}

const validator = new IdValidatorClass()
export const validate = id => validator.validate(id)
export const validateAlgoliaPznQuery = id =>
  validator.validateAlgoliaPznQuery(id)
export const areIdsEqual = (id1, id2) => validator.areIdsEqual(id1, id2)
