import type { FeaturesToggles } from 'types/shopConfig'

export const USE_CMS_TOP_LEVEL_MENU = 'useCmsTopLevelMenu'

export enum TOGGLES_KEYS {
  BREADCRUMBS_CONTENT_SERVICE = 'useBreadcrumbsContentService',
  NEW_MY_ACCOUNT = 'useNewMyAccount',
  NEW_MY_ACCOUNT_NEWSLETTER = 'useNewMyAccountNewsletter',
  NEW_MY_ACCOUNT_NOTEPAD = 'useNewMyAccountNotepad',
  NEW_MY_ACCOUNT_ORDERS = 'useNewMyAccountOrders',
  USE_NEWSLETTER_FLOW_RELEASE = 'useNewsletterFlowRelease',
  VARNISH_CACHE_VERSION = 'varnishCacheVersion',
  PAPERLES_PACKAGES = 'paperlessPackages',
  REVIEWS_FROM_OTHER_LANGUAGES = 'showReviewsFromOtherLanguages',
  RETURN_LABEL_QA_MODE = 'return-label-qa-mode',
}

export const isToggleEnabled = (
  featureToggles: FeaturesToggles,
  toggleKey: TOGGLES_KEYS
): boolean => {
  return featureToggles?.[toggleKey]?.toString() === 'true'
}
