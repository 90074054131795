import { stringify } from 'qs'

import { SEARCH_TYPE, SEO_FRIENDLY_QUERY_PARAMS } from 'shared/consts/algolia'
import { getSearchTypeParam } from 'shared/utils/algolia/getSearchType'
import QueryParamsMapper from 'shared/utils/algolia/schema/QueryParamsMapper'
import type { SearchStateSchemaParam } from 'shared/utils/algolia/types'
import { isEmpty, isObject } from 'shared/utils/objectUtils'
import type { SearchState, SearchStateProp } from 'types/search'

import { isPznQuery } from './searchState'

const filterRefinementListParams = (
  searchState: SearchState,
  key: string
): GenericRecord => {
  const entries: Array<[string, string | Array<unknown>]> = Object.entries(
    searchState[key]
  )

  return entries.reduce(
    (refListAcc: GenericRecord, [facetName, facetValue]) => {
      if (facetValue?.length) {
        refListAcc[facetName] = facetValue
      }
      return refListAcc
    },
    {}
  )
}

const applyTransformedParamData = (
  alternativeParamObj: SearchStateSchemaParam<unknown>,
  value: unknown
): unknown => alternativeParamObj.$transform?.(value) || value

const recursivelySetAlternativeParams = ({
  searchState,
  map,
  resultObj,
  callLevel = 0,
}: {
  searchState: GenericRecord
  map: GenericRecord
  resultObj: GenericRecord
  callLevel?: number
}): void => {
  const entries = Object.entries(searchState) as Array<
    [SearchStateProp, GenericRecord]
  >

  entries.forEach(([key, value]) => {
    if (
      key === 'refinementList' &&
      !isEmpty(<GenericRecord>searchState[key]) &&
      callLevel === 0
    ) {
      const refinementList = filterRefinementListParams(searchState, key)

      if (!isEmpty(refinementList)) {
        resultObj[key] = refinementList
      }

      return
    }

    if (<string>key === SEARCH_TYPE && searchState[key]) {
      const searchTypeParam = getSearchTypeParam(searchState[key])
      Object.assign(resultObj, searchTypeParam)

      return
    }

    const alternativeParamName = <SearchStateSchemaParam<unknown>>map?.[key]
    if (!alternativeParamName) {
      if (value && (SEO_FRIENDLY_QUERY_PARAMS.includes(key) || callLevel > 0)) {
        resultObj[key] = value
      }

      return
    }

    if (isObject(alternativeParamName)) {
      // if $paramName is specified in the object then we reached the depth of param meta description
      if (alternativeParamName.$paramName) {
        const newValue = applyTransformedParamData(alternativeParamName, value)

        // avoiding unnecessary params in url, keep you urls clean :)
        if (alternativeParamName.$defaultValue !== newValue) {
          resultObj[alternativeParamName.$paramName] = newValue
        }
      } else {
        recursivelySetAlternativeParams({
          searchState: value,
          map: alternativeParamName,
          resultObj,
          callLevel: callLevel + 1,
        })
      }
    }
  })
}

type UrlOptions = {
  indexName: string
  userToken?: string
}

export const parseSearchStateToUrl = (
  searchState: SearchState,
  urlOptions: UrlOptions
) => {
  const { indexName, userToken } = urlOptions
  const urlObject: GenericRecord = {}
  const queryParamsMappingSchema = new QueryParamsMapper({
    sortBy: indexName,
  }).getMappingSchema()

  recursivelySetAlternativeParams({
    searchState,
    map: queryParamsMappingSchema,
    resultObj: urlObject,
  })
  urlObject.userToken = userToken

  if (isPznQuery(searchState.query)) {
    urlObject.sortBy = searchState.sortBy
  }

  return `?${stringify(urlObject)}`.replace(/\?$/g, '')
}
