const MENU_SELECTOR = '[data-clientside-hook~="ms-menu-button"]'
const MENU = '[data-clientside-hook~="ms-menu"]'
const CLOSE_MENU_SELECTOR = '[data-clientside-hook~="close-menu-button"]'
const ACTIVE_CLASS = 'ms-active'

export default () => {
  const menu = document.querySelector(MENU)

  const showNavi = target => {
    target.classList.add(ACTIVE_CLASS)
  }

  const hideNavi = target => {
    target.classList.remove(ACTIVE_CLASS)
  }

  const showMenu = document.querySelector(MENU_SELECTOR)

  showMenu?.addEventListener('click', () => showNavi(menu))

  const closeMenu = document.querySelector(CLOSE_MENU_SELECTOR)

  closeMenu?.addEventListener('click', () => hideNavi(menu))
}
