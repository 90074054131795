import { type SearchStateSchema } from 'shared/utils/algolia/types'
import {
  getObjectPrimitiveValues,
  getPathByValue,
} from 'shared/utils/objectUtils'
import {
  AVERAGE_RATING_DEFAULT_VALUES,
  PRICE_DEFAULT_VALUES,
} from 'views/components/organisms/AlgoliaInstantSearch/ProductFilter/consts'

type QueryParamsMapperArguments = {
  sortBy?: string
  pageNumber?: number
  priceMin?: number
  priceMax?: number
  ratingMin?: number
  ratingMax?: number
}

class QueryParamsMapper {
  readonly #schema: SearchStateSchema

  constructor(defaults: QueryParamsMapperArguments) {
    const {
      sortBy = '',
      pageNumber = 1,
      priceMin = PRICE_DEFAULT_VALUES.min,
      priceMax = PRICE_DEFAULT_VALUES.max,
      ratingMin = AVERAGE_RATING_DEFAULT_VALUES.min,
      ratingMax = AVERAGE_RATING_DEFAULT_VALUES.max,
    } = defaults

    this.#schema = {
      page: {
        $paramName: 'pageNumber',
        $defaultValue: pageNumber,
        $transform: v => Number(v),
      },
      sortBy: {
        $paramName: 'sortBy',
        $defaultValue: sortBy,
      },
      range: {
        price: {
          min: {
            $paramName: 'priceMin',
            $transform: v => Math.floor(Number(v)),
            $defaultValue: priceMin,
          },
          max: {
            $paramName: 'priceMax',
            $transform: v => Math.ceil(Number(v)),
            $defaultValue: priceMax,
          },
        },
        averageRating: {
          min: {
            $paramName: 'ratingMin',
            $defaultValue: ratingMin,
            $transform: v => Number(v),
          },
          max: {
            $paramName: 'ratingMax',
            $defaultValue: ratingMax,
            $transform: v => Number(v),
          },
        },
      },
      toggle: {
        nowProductGroup: {
          $paramName: 'now',
          $defaultValue: false,
        },
        filterAttributes: {
          $paramName: 'abo',
          $defaultValue: false,
        },
      },
      menu: {
        primaryCategory: {
          $paramName: 'category',
          $defaultValue: '',
        },
      },
      userToken: {
        $paramName: 'userToken',
        $defaultValue: '',
      },
    }
  }

  getMappingSchema() {
    return this.#schema
  }

  getParamsNames(): string[] {
    // we're not interested in 0, null or undefined in this array
    return getObjectPrimitiveValues<string>(this.#schema, (k, v) =>
      k === '$paramName' ? v : null
    ).filter(Boolean)
  }

  getClearPathByValue(value: unknown, initialPath: string): string | undefined {
    return getPathByValue(this.#schema, value, initialPath)
      ?.split?.('.')
      .filter(node => /^[^$]+$/gi.test(node))
      .join('.')
  }
}

export default QueryParamsMapper
