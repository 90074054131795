// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

export interface ThreadSubjectV1 {
  code: string
  isCustomerRight?: boolean
  isOperatorRight?: boolean
  isShopRight?: boolean
  label: string
  type?: string
}

export class ThreadSubject extends Model {
  public readonly code: string
  public readonly isCustomerRight: boolean
  public readonly isOperatorRight: boolean
  public readonly isShopRight: boolean
  public readonly label: string
  public readonly type: string

  constructor(src: ThreadSubjectV1) {
    super(src)
    this.code = src.code
    this.isCustomerRight = Boolean(src.isCustomerRight)
    this.isOperatorRight = Boolean(src.isOperatorRight)
    this.isShopRight = Boolean(src.isShopRight)
    this.label = src.label
    this.type = src.type
  }
}
