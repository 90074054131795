// '2011-12-13'.length
const DATE_NO_TIME_WIDTH = 10

/**
 * Get actual year in 4-digit format, independent of locale
 *
 * @returns {number}
 */
export const getActualYear = () => new Date().getFullYear()

/**
 * Date in ISO 8601 date format used in JSON+LD DateType
 *
 * @link https://schema.org/Date
 * @param {Number|Date} timestamp
 * @returns {string}
 */
export const formatDateISO = timestamp =>
  (typeof timestamp === 'number' ? new Date(timestamp) : timestamp)
    .toISOString()
    .substr(0, DATE_NO_TIME_WIDTH)

/**
 * Add `number` days (signed) to the passed date
 *
 * @param {Number|Date} timestamp
 * @param {Number} number
 * @returns {string}
 */
export const dateAddDays = (timestamp, number) => {
  const date = typeof timestamp === 'number' ? new Date(timestamp) : timestamp
  const actualDays = date.getDate()

  date.setDate(actualDays + number)

  return formatDateISO(date)
}

export const hasValidAge = ({ day, month, year, minAge }) =>
  new Date(year + minAge, month - 1, day) <= new Date()

export const hasValidDay = ({ day, month, year }) =>
  day <= new Date(year, month, 0).getDate()

export const toISOStringWithTimezone = date => {
  const year = date.getFullYear()
  const month = `${date.getMonth() + 1}`.padStart(2, '0')
  const day = `${date.getDate()}`.padStart(2, '0')
  const hours = `${date.getHours()}`.padStart(2, '0')
  const minutes = `${date.getMinutes()}`.padStart(2, '0')
  const seconds = `${date.getSeconds()}`.padStart(2, '0')

  const offset = date.getTimezoneOffset()
  const offsetHours = `${Math.floor(Math.abs(offset) / 60)}`.padStart(2, '0')
  const offsetMinutes = `${Math.abs(offset % 60)}`.padStart(2, '0')
  const offsetSign = offset <= 0 ? '+' : '-'
  const timezonePostfix = `${offsetSign}${offsetHours}:${offsetMinutes}`

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezonePostfix}`
}

export const toISODateString = ({ day, month, year }) => {
  const formatWithLeadingZero = number => String(number).padStart(2, '0')

  const formattedDate = `${year}-${formatWithLeadingZero(
    month
  )}-${formatWithLeadingZero(day)}`

  return formattedDate
}

/*
 * Predicate fn that checks if date is today.
 *
 * @param {Date} date - date to check
 * @returns {Boolean}
 * */
export const isToday = date => {
  const today = new Date()

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

/*
 * Predicate fn that checks if date is yesteday.
 *
 * @param {Date} date - date to check
 * @returns {Boolean}
 * */
export const isYesterday = date => {
  const now = new Date()
  const yesterday = new Date(now.setDate(now.getDate() - 1))

  return (
    yesterday.getDate() === date.getDate() &&
    yesterday.getMonth() === date.getMonth() &&
    yesterday.getFullYear() === date.getFullYear()
  )
}

/*
 * Prepares custom date obj with "dd/mm/yy" and "hh:mm" format.
 *
 * It also does yesterday and today checks.
 *
 * @param {String} ts - timestamp / date iso string
 * @returns {{isToday: Boolean, isYesterday: Boolean, date: String, hours: String}}
 * */
export const prepareDateObj = timestamp => {
  const tsDate = new Date(timestamp)

  const addPadding = num => `${num}`.padStart(2, '0')

  const date = [tsDate.getDate(), tsDate.getMonth() + 1, tsDate.getFullYear()]
    .map(addPadding)
    .join('.')

  const hours = [tsDate.getHours(), tsDate.getMinutes()]
    .map(addPadding)
    .join(':')

  return {
    isToday: isToday(tsDate),
    isYesterday: isYesterday(tsDate),
    date,
    hours,
  }
}

/*
 * Checks if the current time is in the period.
 *
 * @param {dateFrom} - date gmt string: "2021-09-28T18:00:00"
 * @param {dateTo} - date gmt string: "2021-09-30T00:00:00"
 *
 * @returns boolean
 * */
export const isPeriodActive = (dateFrom, dateTo) => {
  const now = new Date()
  const showFrom = new Date(dateFrom)
  const showTo = new Date(dateTo)

  if (
    showFrom.toString() === 'Invalid Date' ||
    showTo.toString() === 'Invalid Date'
  ) {
    return true
  }

  return showFrom < now && showTo > now
}
