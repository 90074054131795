/**
 * @see https://codeburst.io/throttling-and-debouncing-in-javascript-b01cad5c8edf
 * @see ./throttle.test.js
 */
const throttle = (func, delay) => {
  let lastFuncTimeoutId
  let lastRunTime

  return function (...args) {
    const context = this

    if (!lastRunTime) {
      func.apply(context, args)
      lastRunTime = Date.now()
    } else {
      clearTimeout(lastFuncTimeoutId)

      lastFuncTimeoutId = setTimeout(function throttled() {
        if (Date.now() - lastRunTime >= delay) {
          func.apply(context, args)
          lastRunTime = Date.now()
        }
      }, delay - (Date.now() - lastRunTime))
    }
  }
}

export default throttle
