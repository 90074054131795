import { PARTICIPANT_TYPE_SHOP } from 'shared/consts/marketplace'
import { ThreadTopic } from 'shared/services/api/models'
import { Model } from 'shared/services/api/models/Model'

import {
  NewThreadMessageAttachment,
  type NewThreadMessageAttachmentV1,
} from './NewThreadMessageAttachment'

export interface NewThreadBodyV1 {
  files: NewThreadMessageAttachmentV1[]
  message: string
  topic: ThreadTopic
  lineItemId: string
  to: string[]
}

export class NewThreadBody extends Model {
  public files: NewThreadMessageAttachment[]
  public body: string
  public topic: ThreadTopic
  public to: string[]
  public lineItemId: string

  constructor(src: NewThreadBodyV1) {
    // @ts-ignore
    super(src)
    this.files = src.files?.length
      ? src.files.map(
          (file: NewThreadMessageAttachmentV1) =>
            new NewThreadMessageAttachment(file)
        )
      : []
    this.body = src.message
    this.topic = src.topic
    this.to = src.to?.length ? src.to : [PARTICIPANT_TYPE_SHOP]
    this.lineItemId = src.lineItemId
  }
}
