import { createActionNames } from './helpers'

export const RESOURCE_STORE_KEY = 'userSession'
export const SET_NEW_USER_SESSION = 'SET_NEW_USER_SESSION'

export const actionNames = createActionNames({
  resourceName: RESOURCE_STORE_KEY,
})

// actions
export const setUserSession = userSession => {
  return { type: actionNames.setUserSession, userSession }
}

export const setNewUserSession = userSession => {
  return { type: SET_NEW_USER_SESSION, userSession }
}

// reducer
const reducer = (state = {}, action = {}) => {
  switch (action.type) {
    case actionNames.setUserSession:
      return { ...state, ...action.userSession }
    case SET_NEW_USER_SESSION:
      return { ...state, newLogin: action.userSession }
    default:
      return state
  }
}

export default reducer
