import A11yDialog from 'a11y-dialog'

import { ARIA_HIDDEN } from 'views/assets/scripts/consts'
import { DIALOG_INITIALISED_ATTR } from 'views/consts'
import toggleBoolStringAttribute from 'views/utils/toggleBoolStringAttribute'

const dialogs = new WeakMap()

const createA11Dialog = ({
  element,
  container = document.body,
  open = undefined,
}: {
  element: HTMLElement
  container?: HTMLElement
  open?: boolean
}): A11yDialog => {
  if (dialogs.has(element)) {
    return dialogs.get(element)
  }

  const isOpen = Boolean(open || element.getAttribute(ARIA_HIDDEN) !== 'true')

  const a11dialog = new A11yDialog(element, container)
  dialogs.set(element, a11dialog)

  // Used for styling  - before this attribute visibility is controlled
  // by :target CSS selector
  element.setAttribute(DIALOG_INITIALISED_ATTR, 'true')

  element.addEventListener('click', (event: MouseEvent) => {
    // If those two are equal, it means that user clicked <dialog>
    // itself and not it’s contents. In practise that means that
    // backdrop was clicked.
    if (event.target === element) {
      a11dialog.hide()
    }
  })

  toggleBoolStringAttribute(element, ARIA_HIDDEN, !isOpen)
  if (isOpen) {
    // SSR’d `<dialog open>` is in different state than one opened
    // with HTMLDialogElement.prototype.showModal() (focus is not
    // trapped and backdrop is missing).
    // To clean this up, we force it close and use A11YDialog’s method
    // to show it.

    a11dialog.show()
  }

  return a11dialog
}

export default createA11Dialog
