import classNames from 'clsx'
import { bool } from 'prop-types'

import Button from 'views/components/atoms/Button'

import './ButtonTransparent.scss'

const ButtonTransparent = props => {
  const {
    children,
    alternative,
    small,
    transparentInline,
    className,
    ...rest
  } = props

  const buttonClassname = classNames('a-Button--transparent', {
    'a-Button--transparent-alt': alternative,
    'a-Button--transparent-small': small,
    'a-Button--transparent-inline': transparentInline,
    [`${className}`]: className,
  })

  return (
    <Button {...rest} className={buttonClassname}>
      {children}
    </Button>
  )
}

ButtonTransparent.propTypes = {
  ...Button.propTypes,
  alternative: bool,
  small: bool,
  transparentInline: bool,
}

ButtonTransparent.defaultProps = {
  ...Button.defaultProps,
  alternative: null,
  small: null,
  transparentInline: null,
}

export default ButtonTransparent
