// todo check all utils, add tests where missed
import { OFFER_TYPE_MIRAKL, OFFER_TYPE_SAE } from 'shared/consts/marketplace'
import {
  PRESCRIPTION_TYPE_TO_PRICE_TYPE,
  PRICE_TYPES,
  PRODUCT_TEMPLATES,
} from 'shared/consts/productTemplates'
import {
  FULL_PRODUCT_STATUS_AVAILABLE,
  PRODUCT_STATUS_AV,
  PRODUCT_STATUS_AVAILABLE,
  PRODUCT_STATUS_GRATIS,
  PRODUCT_STATUS_NA,
  PRODUCT_STATUS_NL,
} from 'shared/consts/stockStatus'
import { TENANT_FR } from 'shared/consts/tenants'
import { isVariantAttributes } from 'shared/experiments/utils/featureFlags'
import { isMobile } from 'shared/utils/deviceClassUtils'
import { getPriceForType } from 'shared/utils/productTemplateUtils'
import {
  PROMOTION_TYPES,
  VALID_PROMOTIONS,
} from 'views/components/organisms/ProductLabels/consts'

/**
 * Determine whether products search result is valid
 *
 * @param product
 * @returns {boolean}
 */
export const isValidProduct = product =>
  Boolean(product.id) &&
  Boolean(product.name) &&
  Boolean(product.thumbnailURL) &&
  Boolean(product.prices)

export const getDocumentTypes = () => ({
  externalProductPDF: /*i18n*/ 'product.link.label.packageInsert',
  internalProductPDF: /*i18n*/ 'product.link.label.packageInsert',
  externalLMIVPDF: /*i18n*/ 'product.link.label.foodInformation',
  internalLMIVPDF: /*i18n*/ 'product.link.label.foodInformation',
  importantPharmaceuticalNotices:
    /*i18n*/ 'product.link.label.pharmaceuticalNotices',
})

export const getDocuments = product => {
  if (product.documents && product.documents.length > 0) {
    const DOC_TYPES = getDocumentTypes()
    return product.documents
      .filter(doc => DOC_TYPES[doc.doctype])
      .map(doc => ({ link: doc.link, text: DOC_TYPES[doc.doctype] }))
  }

  return []
}

export const isProductAvailable = ({ status } = {}) =>
  status === PRODUCT_STATUS_AVAILABLE ||
  status === FULL_PRODUCT_STATUS_AVAILABLE

export const isProductOutOfTrade = ({ status }) => status === PRODUCT_STATUS_AV
export const isProductNotInAssortment = ({ status }) =>
  status === PRODUCT_STATUS_NA

export const isProductNotInCatalog = ({ statusReason }) =>
  statusReason === 'general.label.product.notInCatalog'

export const isPrescriptionProduct = product =>
  product?.flags?.prescriptionMedicine ?? false
// 15.07.2021 sometimes receive products with no or inconsistent variants, hence the nullish check
// https://jira.shop-apotheke.com/browse/CSEAV-8993
export const isProductOutOfStock = ({ status }) =>
  [null, undefined].includes(status) || status === PRODUCT_STATUS_NL

export const isProductFree = ({ status }) => status === PRODUCT_STATUS_GRATIS

export const isVariantVisible = (currentId, { id: variantId, stockStatus }) =>
  variantId === currentId ||
  isProductAvailable(stockStatus) ||
  isProductOutOfStock(stockStatus)

// todo extract promotion types into consts
export const isValidPromotion = (
  promotion,
  { code, headline, description, packshot } = {}
) =>
  VALID_PROMOTIONS.indexOf(promotion) > -1 &&
  Boolean(
    promotion !== PROMOTION_TYPES.BUNDLE &&
      code &&
      headline &&
      description &&
      packshot
  )

export const calculateDiscountPercents = ({
  retailPrice,
  listPrice,
  locale,
}) => {
  const rounded = 100 - (retailPrice / listPrice) * 100

  return rounded.toLocaleString(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
}

export const getRealPrice = ({ prescriptionType, variant }) =>
  getPriceForType(
    variant.prices,
    PRESCRIPTION_TYPE_TO_PRICE_TYPE[prescriptionType]
  )

export const getListPriceSuperScript = ({ superScripts, pharmaObl }) => {
  const { avp, avpPharmaObl } = superScripts

  return pharmaObl && avpPharmaObl ? avpPharmaObl : avp
}

export function shouldRenderPrescriptionTypesModal({
  flags,
  isOTX,
  bestOffer,
}) {
  if (flags?.veterinaryDrug) {
    return false
  }

  if (bestOffer) {
    return isOTX && bestOffer.offerType === OFFER_TYPE_SAE
  }

  return isOTX
}

export const isAllProductVariantsNL = (
  stockStatusOfSelectedVariant,
  variants
) => {
  // If the selected variant is AV, return false
  if (stockStatusOfSelectedVariant.status === PRODUCT_STATUS_AV) {
    return false
  }

  if (variants) {
    // Remove AV products, and check if there are still products
    const visibleVariants = variants.filter(
      ({ stockStatus }) => stockStatus.status !== PRODUCT_STATUS_AV
    )
    if (visibleVariants.length === 0) {
      return false
    }

    // then check if they are all NL
    return (
      variants.filter(
        ({ stockStatus }) => stockStatus.status === PRODUCT_STATUS_NL
      ).length === visibleVariants.length
    )
  }

  return false
}

export const isPricePresent = price => price?.amount > 0

export const isListPriceGreaterThanOrEqualToRetailPrice = ({
  listPrice,
  retailPrice,
}) =>
  isPricePresent(listPrice) &&
  isPricePresent(retailPrice) &&
  listPrice.amount > retailPrice.amount

export const isDiscountValid = (discount, discountBoundaries) => {
  if (!discount || Number.isNaN(discount) || discount <= 0) {
    return false
  }

  if (discountBoundaries) {
    const { min, max } = discountBoundaries

    if ((min && discount < min) || (max && discount > max)) {
      return false
    }
  }

  return true
}

// todo: when the experiment is approved or not, keep the util and add tests here
export const getProductLabelsProps = ({
  displayNowWidget,
  offerTypeSae,
  product,
  productTemplate,
  prescriptionRequiredLabel,
}) => {
  const {
    promotion,
    promotionProduct,
    labels,
    stockStatus,
    multiplierRP,
    redPoints,
    redPointsIsActive,
    redPointsMultiplierIsValid,
    pharmaObl,
    upid,
  } = product

  const offerFlags = product.offers?.find(
    offer => offer.offerId === product.offerId
  )?.offerFlags

  const productLabelsProps = {
    displayNowWidget,
    offerTypeSae,
    pharmaObl,
    stockStatus,
    upid,
  }

  if (
    [PRODUCT_TEMPLATES.OTC, PRODUCT_TEMPLATES.OTX].includes(productTemplate)
  ) {
    productLabelsProps.promotion = promotion
    productLabelsProps.promotionProduct = promotionProduct
    productLabelsProps.labels = labels
    productLabelsProps.redPointsData = {
      productTemplate,
      redPointsMultiplierIsValid,
      redPointsIsActive,
      redPoints,
      multiplierRP,
    }
  } else {
    productLabelsProps.prescription = true
    productLabelsProps.labels = labels.filter(
      label => label !== prescriptionRequiredLabel
    )
  }

  if (!promotion && offerFlags?.includes('isPromotionSet')) {
    productLabelsProps.promotion = PROMOTION_TYPES.BUNDLE
  }
  productLabelsProps.product = product
  return productLabelsProps
}

export const shouldRenderRatings = ({
  tenant,
  productTemplate,
  medicament,
}) => {
  const isRx = productTemplate === PRODUCT_TEMPLATES.RX

  if (isRx) {
    return false
  }

  if (tenant !== TENANT_FR) {
    return true
  }

  return !(medicament && productTemplate === PRODUCT_TEMPLATES.OTC)
}

export const hasFlag = (productFlags, exceptionFlags) =>
  exceptionFlags.some(flag => Boolean(productFlags[flag]))

/*
 * This function is used to attach the list price to the product prices object, mostly for marketplace products.
 * @param {Object} currentOffer The offer that is currently selected
 * @param {Array} prices The prices object of the current product
 */
export const getPricesWithListPriceDefaulting = (currentOffer, prices) => {
  if (currentOffer?.offerType === OFFER_TYPE_MIRAKL) {
    return [
      {
        amount: currentOffer?.strikeThroughOfferPrice?.price,
        type: PRICE_TYPES.LIST,
        currency: currentOffer?.strikeThroughOfferPrice?.currency,
      },
      {
        amount: currentOffer?.offerPrice?.price,
        type: PRICE_TYPES.RETAIL,
        currency: currentOffer?.offerPrice?.currency,
      },
    ]
  }
  if (
    currentOffer?.strikeThroughOfferPrice?.price &&
    currentOffer?.offerPrice?.price
  ) {
    const pricesWithoutListAndRetailPrice = prices.filter(
      price =>
        price.type !== PRICE_TYPES.LIST && price.type !== PRICE_TYPES.RETAIL
    )
    return [
      ...pricesWithoutListAndRetailPrice,
      {
        amount: currentOffer?.strikeThroughOfferPrice?.price,
        type: PRICE_TYPES.LIST,
        currency: currentOffer?.strikeThroughOfferPrice?.currency,
      },
      {
        amount: currentOffer?.offerPrice?.price,
        type: PRICE_TYPES.RETAIL,
        currency: currentOffer?.offerPrice?.currency,
      },
    ]
  } else {
    return prices
  }
}

export const getVariantDiscount = ({
  variantOffer,
  discount,
  discountBoundaries,
}) => {
  const discountPercentage =
    variantOffer?.offerType === OFFER_TYPE_MIRAKL
      ? variantOffer?.offerPrice?.additionalInformation?.discount?.percentage
      : discount
  return isDiscountValid(discount, discountBoundaries) ? discountPercentage : 0
}

// check if product has only package size attribute and is mobile
export const hasPackageSizeOnlyAndIsMobile = (deviceClass, variants) => {
  return isMobile(deviceClass) && isSingleVariantAttribute(variants)
}

export const isSingleVariantAttribute = variants => {
  return (
    checkVariantAttributes(variants) &&
    checkVariantAttributeExcluded(variants) &&
    checkVariantAttributeCLExcluded(variants)
  )
}

const checkVariantAttributes = variants => {
  return !variants.some(variant => {
    return variant?.variantAttributes?.some(variantAttribute => {
      return (
        (variantAttribute.variantCode !== 'package_size' &&
          variantAttribute.variantCode !== 'packaging_variant') ||
        (variantAttribute.variantCode === 'packaging_variant' &&
          variantAttribute.value?.toLowerCase() !== 'set')
      )
    })
  })
}

/** check if product is single attribute variant, having only package size or variante === set */
export const isSingleVariantAttributeSERP = variants => {
  return !variants.some(variant => {
    return variant?.variant_attributes?.some(variantAttribute => {
      return (
        (variantAttribute.variant_attribute !== 'packSize' &&
          variantAttribute.variant_attribute !== 'packaging_variant') ||
        (variantAttribute.variant_attribute === 'packaging_variant' &&
          variantAttribute.value?.toLowerCase() !== 'set')
      )
    })
  })
}
export const hasMultipleVariantAttributes = (variants, experiments) => {
  return (
    isVariantAttributes(experiments) &&
    !isSingleVariantAttributeSERP(variants) &&
    variants?.length > 1
  )
}
const checkVariantAttributeExcluded = variants => {
  return !variants.some(variant => {
    return variant?.variantAttributesExcluded?.some(
      variantAttributeExcluded => {
        return (
          (variantAttributeExcluded.code !== 'package_size' &&
            variantAttributeExcluded.code !== 'packaging_variant') ||
          (variantAttributeExcluded.code === 'packaging_variant' &&
            variantAttributeExcluded?.value.toLowerCase() !== 'set')
        )
      }
    )
  })
}

const checkVariantAttributeCLExcluded = variants => {
  return !variants.some(variant => {
    return variant?.variantAttributesCLExcluded?.length > 0
  })
}
