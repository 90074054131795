import { Model } from 'shared/services/api/models/Model'
import { OrderLineItemsResponseItem } from 'shared/services/api/models/orders/orderLineItemsResponseItem'

import { type OrderLineItemsResponseItemV1 } from './ordersResponseTypes'

export class OrderLineItemsResponse extends Model {
  public orderLineItemsResponse: OrderLineItemsResponseItemV1[]

  constructor(src: OrderLineItemsResponseItemV1[]) {
    // @ts-ignore
    super(src)

    this.orderLineItemsResponse = Array.isArray(src)
      ? src.map(
          (orderLineResponseItem: OrderLineItemsResponseItemV1) =>
            new OrderLineItemsResponseItem(orderLineResponseItem)
        )
      : []
  }
}
