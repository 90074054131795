import { isMarketplace, isSaeOnly } from 'shared/experiments/utils/featureFlags'
import store, { subscribe } from 'shared/store'
import { actionNames } from 'shared/store/ducks/userSession'
import { getCookieByName } from 'shared/utils/getCookie'

/**
 * fail-save (for not found element) querying and setting of props
 *
 * @param {string} selector querySelector for the desired element
 * @param {object} props key/value pairs of props to set
 */
const selectAndSet = (selector, props) => {
  const element = document.querySelector(selector)
  if (element) {
    Object.keys(props).forEach(key => {
      element[key] = props[key]
    })
  }
}

const handleUserSession = () => {
  subscribe.after.once(actionNames.setUserSession, ({ userSession }) => {
    const {
      isLoggedIn = false,
      isTempUser = false,
      expa = null,
      cartItems: numOfCartItems = 0,
    } = userSession

    if (isLoggedIn && !isTempUser) {
      document.body.classList.add('user-logged-in')
    } else {
      document.body.classList.add('user-logged-out')
    }

    if (window.dataLayer) {
      let expaToPush

      try {
        expaToPush = getCookieByName('frozenExpa', document.cookie) || expa
      } catch (e) {
        expaToPush = expa
      }

      window.dataLayer.push({
        event: 'userdata_available',
        'request.expa': expaToPush,
      })
    }

    /**
     * What if, instead of doing it here, every single component involved
     * subscribes to 'userData.received'
     * (PubSub legacy comment, but still valid with redux subscriber) and takes care about his
     * own consumption?
     *
     * answer: that would maybe be the way to go as the separation of concerns would be followed
     *
     * additonal answer: not sure how a component could handle this itself - as the component is
     * server side rendered, it has no possibility to listen or interact in any way.
     * one would either have to create a client-script for individual components or (not good)
     * hydrate/render every component which has changes here
     * maybe the best way would be to wait till the user session can be handled on in fock
     * on the server and then all the html will be already rendered correctly...
     */

    const {
      pageProperties: { experiments },
      publicConfig: { tenant },
    } = store.getPublicRuntimeConfig()

    if (
      !isSaeOnly(experiments, tenant) &&
      !isMarketplace(experiments, tenant)
    ) {
      // app/views/components/organisms/Menubar/index.js
      selectAndSet('[data-clientside-hook~="Menubar__cart-badge"]', {
        textContent: numOfCartItems,
      })

      // app/views/components/organisms/HeaderSmall/index.js
      selectAndSet('[data-clientside-hook~="SmallHeader__cart-badge"]', {
        textContent: numOfCartItems,
      })
    }

    if (isLoggedIn) {
      const firstName = userSession?.user?.firstName
      // app/views/components/organisms/OffCanvas/OffCanvasHeader/index.js
      selectAndSet('[data-clientside-hook~="OffCanvasMenu__header-greeting"]', {
        textContent: `Hallo ${firstName}`,
      })
    }
  })
}

export default handleUserSession
