import {
  NO_DROPDOWN_OVERFLOW_SMALL_TINY_CLASS,
  NO_OVERFLOW_CLASS,
} from 'views/assets/scripts/consts'

let offsetY = 0

/**
 * Adds/removes CSS class to the HTMLElement that disables scrolling
 * @param {object} payload
 * @param {HTMLElement} payload.parent - parent element whose scroll will be disabled/enabled
 * @param {boolean} [payload.isEnabled=false] - enable or disable scroll
 * @param {boolean} [payload.onlySmall=true] - enables/disables scroll only for small-tiny viewports
 */
export const toggleOverflow = ({
  parent,
  isEnabled = false,
  onlySmall = true,
}) => {
  const overflowClass = onlySmall
    ? NO_DROPDOWN_OVERFLOW_SMALL_TINY_CLASS
    : NO_OVERFLOW_CLASS
  if (isEnabled) {
    offsetY = window.pageYOffset
    parent.classList.add(overflowClass)
    return
  }
  parent.classList.remove(overflowClass)
  if (onlySmall) {
    window.scrollTo(0, offsetY)
  }
}
