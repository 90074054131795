import store from 'shared/store'
import asyncDataFetching from 'views/assets/scripts/asyncDataFetching'
import chargeUrlSearchParams from 'views/assets/scripts/chargeUrlSearchParams'
import expander from 'views/assets/scripts/expander'
import hitsPerPage from 'views/assets/scripts/hitsPerPage'
import modal from 'views/assets/scripts/modal'
import nowModal from 'views/assets/scripts/nowModal'
import productFilter from 'views/assets/scripts/productFilter'
import refinementList from 'views/assets/scripts/refinementList'
import { runSafeEachScript } from 'views/assets/scripts/runScripts'
import { ALGOLIA_CONTAINER_ID } from 'views/consts'
import loadCatalogs from 'views/containers/browser/utils/loadCatalogs'

const CLIENT_SCRIPTS = [
  asyncDataFetching,
  modal,
  expander,
  refinementList,
  productFilter,
  nowModal,
  chargeUrlSearchParams,
  hitsPerPage,
]

const initAlgoliaSearchComponents = async container => {
  const {
    publicConfig: { locale },
  } = store.getPublicRuntimeConfig()

  const i18n = await loadCatalogs(locale)

  const hydrateAlgoliaSearchComponents = await import(
    /* webpackChunkName: "AlgoliaSearchContainerWithHydration", webpackPrefetch: true */
    './hydrateAlgoliaSearchComponents'
  )
  hydrateAlgoliaSearchComponents.default({
    container,
    i18n,
  })

  runSafeEachScript(CLIENT_SCRIPTS)
}

export default async () => {
  const container = document.getElementById(ALGOLIA_CONTAINER_ID)
  if (container) {
    await initAlgoliaSearchComponents(container)
  }
}
