// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

export interface ThreadTopicV1 {
  type: string
  value: string
}

export class ThreadTopic extends Model {
  public readonly type: string
  public readonly value: string

  constructor(src: ThreadTopicV1) {
    super(src)
    this.type = src.type
    this.value = src.value
  }
}
