// status codes as strings for API service testing to prevent the need for conversion boilerplate

export const OK = '200'
export const CREATED = '201'
export const MULTIPLE_CHOICES = '300'
export const BAD_REQUEST = '400'
export const UNAUTHORIZED = '401'
export const FORBIDDEN = '403'
export const NOT_FOUND = '404'
export const NOT_ALLOWED = '405'
export const INTERNAL_SERVER_ERROR = '500'
export const SERVICE_UNAVAILABLE = '503'
export const GATEWAY_TIMEOUT = '504'
export const PERMANENT_REDIRECT = '301'
export const TEMP_REDIRECT = '302'
export const SERVER_REQUEST_TIMEOUT = 408
