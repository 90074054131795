// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

import { OrderPaymentV1 } from './ordersResponseTypes'

export class OrderPayment extends Model {
  public readonly paymentKind: string
  public readonly paymentProviderTransactionId: string
  public readonly paymentStatus: string
  public readonly paymentCost: number

  constructor(src: OrderPaymentV1) {
    super(src)
    // To match consts in app/views/const/payments
    this.paymentKind = src.paymentKind.replace('order.payment.kind', '')
    this.paymentProviderTransactionId = src.paymentProviderTransactionId
    this.paymentStatus = src.paymentStatus
    this.paymentCost = src.paymentCost
  }
}
