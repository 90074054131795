import Agent from 'agentkeepalive'

import { API_TIMEOUT_MS } from 'shared/consts'
import assert from 'shared/utils/assert'
import { createTenantPath } from 'shared/utils/routing'

import APIService from './APIService'
import { type ServiceParameters } from './commonTypes/ServiceParameters'

type AgentType = {
  maxSockets: number
  maxFreeSockets: number
  timeout: number
  freeSocketTimeout: number
  scheduling?: 'lifo' | 'fifo'
}

const agentOptions: AgentType = {
  freeSocketTimeout: 4000,
  maxFreeSockets: 10,
  maxSockets: 128,
  timeout: API_TIMEOUT_MS,
  scheduling: 'lifo',
}

export const keepAliveHttpAgent: Agent = new Agent(agentOptions)
export const keepAliveHttpsAgent: Agent.HttpsAgent = new Agent.HttpsAgent(
  agentOptions
)

abstract class BackendAPIService extends APIService {
  protected tenantPath: string
  protected tenant: string
  protected language: string

  constructor(props: ServiceParameters) {
    super(props)

    const { tenant, coreShopEnvironment, language } = props

    assert(tenant, 'APIService: no "tenant" option provided')
    assert(
      coreShopEnvironment,
      'APIService: no "coreShopEnvironment" option provided'
    )
    assert(language, 'APIService: no "language" option provided')

    this.language = language
    this.tenant = tenant
    this.tenantPath = createTenantPath(tenant, coreShopEnvironment)
    this.client.defaults.httpAgent = keepAliveHttpAgent
    this.client.defaults.httpsAgent = keepAliveHttpsAgent
  }
}

export default BackendAPIService
