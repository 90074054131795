import store from 'shared/store'
import formControlValidation from 'views/assets/scripts/formValidation/formControlValidation.js'
import productConsultation from 'views/assets/scripts/productConsultation'
import {
  CONTACT_SA_MODAL_CONTAINER_ID,
  OPEN_CONTACT_SA_MODAL,
} from 'views/components/organisms/ContactShopApothekeModal/consts'
import loadCatalogs from 'views/containers/browser/utils/loadCatalogs'

const hydrateAndMountComponent = ({
  suborderId,
  wrappingElement,
  wrappedComponent,
  i18n,
}) => {
  wrappedComponent({
    container: wrappingElement,
    i18n,
    suborderId,
  })
}

const mountContactShopApothekeModalContainer = async () => {
  const suborderLinks = document.querySelectorAll(
    `[data-clientside-hook*="${OPEN_CONTACT_SA_MODAL}"]`
  )
  const modal = document.getElementById(CONTACT_SA_MODAL_CONTAINER_ID)

  if (!suborderLinks.length || !modal) {
    return
  }

  const {
    publicConfig: { locale },
  } = store.getPublicRuntimeConfig()

  const i18n = await loadCatalogs(locale)

  const contactSAModalContainer = await import(
    /* webpackChunkName: "ContactShopApothekeModalContainerWithHydration" */
    './ContactShopApothekeModalContainerWithHydration'
  )

  let modalInitialized = false

  suborderLinks.forEach(link => {
    const suborderId = link.getAttribute('data-suborder-id')

    if (suborderId) {
      link.addEventListener('click', () => {
        if (!modalInitialized) {
          modalInitialized = true

          hydrateAndMountComponent({
            suborderId,
            wrappingElement: modal,
            wrappedComponent: contactSAModalContainer.default,
            i18n,
          })

          productConsultation()
          formControlValidation()
        }
      })
    }
  })
}

export default mountContactShopApothekeModalContainer
