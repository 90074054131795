import { i18n } from '@lingui/core'

const loadCatalogs = async locale => {
  try {
    const { messages } = await import(
      /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
      `../../../../.locale-client/${locale}/messages.mjs`
    )

    if (!messages) {
      throw new Error('Failed to load i18n messages')
    }

    i18n.load(locale, messages)
    i18n.activate(locale)
  } catch (error) {
    /* eslint-disable no-console */
    if (typeof locale === 'undefined') {
      console.error('Expected `locale` to be String')
    }

    console.error(error)
  }

  return i18n
}

export default loadCatalogs
