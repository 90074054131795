// @ts-nocheck
import {
  AllowedOrdersForRefundResponseV1,
  Model,
} from 'shared/services/api/models'
import { OrdersForRefundItemResponse } from 'shared/services/api/models/orders/ordersForRefundItemResponse'

export class OrdersForRefundResponse extends Model {
  public ordersForRefund: OrdersForRefundItemResponse[]

  constructor(src: AllowedOrdersForRefundResponseV1[]) {
    super(src)
    this.ordersForRefund = src.map(
      order => new OrdersForRefundItemResponse(order)
    )
  }
}
