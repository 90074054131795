import { WidgetInstance } from 'friendly-challenge'

/**
 * Widget callback function for login button toggling.
 */
const loginWidgetCallback = () => {
  document.getElementById('login-submit-btn')?.removeAttribute('disabled')
}

const otpLoginWidgetCallback = () => {
  document.getElementById('otp-submit-btn')?.removeAttribute('disabled')
}

const otpRequestWidgetCallback = () => {
  document.getElementById('otp-request-submit-btn')?.removeAttribute('disabled')
}

const registrationWidgetCallback = () => {
  document.getElementById('register-submit-btn')?.removeAttribute('disabled')
}

const productConsultationMessageFormWidgetCallback = () => {
  document
    .getElementById('callback-form-message-submit-btn')
    ?.removeAttribute('disabled')
}

const productConsultationCallbackFormWidgetCallback = () => {
  document
    .getElementById('callback-form-callback-submit-btn')
    ?.removeAttribute('disabled')
}

const forgotPasswordFormWidgetCallback = () => {
  document
    .getElementById('forgotpassword-form-button')
    ?.removeAttribute('disabled')
}

const professionalAccountRegistrationWidgetCallback = () => {
  document
    .getElementById('professional-account-register-submit-btn')
    ?.removeAttribute('disabled')
}

const createFCWidget = (
  startMode: 'auto' | 'focus' | 'none' = 'focus',
  selector: string,
  doneCallBack: () => void,
  errorCallBack: () => void
) => {
  const container = document.querySelector<HTMLElement>(selector)
  const puzzleUrl = 'https://eu-api.friendlycaptcha.eu/api/v1/puzzle'

  if (!container) {
    return
  }

  new WidgetInstance(container, {
    startMode,
    puzzleEndpoint: puzzleUrl,
    doneCallback: doneCallBack,
    errorCallback: errorCallBack,
  })
}

const createFCWidgetForLogin = () => {
  createFCWidget(
    'auto',
    '#loginForm-frc-captcha',
    loginWidgetCallback,
    loginWidgetCallback
  )
}

const createFCWidgetForOtpRequest = () => {
  createFCWidget(
    'auto',
    '#otpRequestForm-frc-captcha',
    otpRequestWidgetCallback,
    otpRequestWidgetCallback
  )
}

const createFCWidgetForOtpLogin = () => {
  createFCWidget(
    'auto',
    '#otpLoginForm-frc-captcha',
    otpLoginWidgetCallback,
    otpLoginWidgetCallback
  )
}

const createFCWidgetForRegistration = () => {
  createFCWidget(
    'auto',
    '#registerForm-frc-captcha',
    registrationWidgetCallback,
    registrationWidgetCallback
  )
}

const createFCWidgetForProductConsultationMessageForm = () => {
  createFCWidget(
    'auto',
    '#productConsultationMessageForm-frc-captcha',
    productConsultationMessageFormWidgetCallback,
    productConsultationMessageFormWidgetCallback
  )
}

const createFCWidgetForProductConsultationCallbackForm = () => {
  createFCWidget(
    'auto',
    '#productConsultationCallbackForm-frc-captcha',
    productConsultationCallbackFormWidgetCallback,
    productConsultationCallbackFormWidgetCallback
  )
}

const createFCWidgetForForgotPasswordForm = () => {
  createFCWidget(
    'auto',
    '#forgotPasswordForm-frc-captcha',
    forgotPasswordFormWidgetCallback,
    forgotPasswordFormWidgetCallback
  )
}

const createFCWidgetForProfessionalAccountRegister = () => {
  createFCWidget(
    'auto',
    '#professional-account-registerForm-frc-captcha',
    professionalAccountRegistrationWidgetCallback,
    professionalAccountRegistrationWidgetCallback
  )
}

export {
  createFCWidgetForLogin,
  createFCWidgetForOtpRequest,
  createFCWidgetForOtpLogin,
  createFCWidgetForRegistration,
  createFCWidgetForProductConsultationMessageForm,
  createFCWidgetForProductConsultationCallbackForm,
  createFCWidgetForForgotPasswordForm,
  createFCWidgetForProfessionalAccountRegister,
}
