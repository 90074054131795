import {
  NFC_LANDING_PAGE,
  PAGE_TYPE_ACCOUNT,
  PAGE_TYPE_CART,
  PAGE_TYPE_CART_INTERMEDIATE,
  PAGE_TYPE_REDEEM_E_RECIPE,
  PRESCRIPTION_MANAGER,
} from 'shared/consts'
import {
  CROSSSELL_LAYOUT,
  EXPERIMENT_VARIATION,
} from 'shared/experiments/consts'
import { trackCswSlider } from 'shared/experiments/consts/trackCswSlider'
import {
  activateExperiment,
  getExperimentOnClient,
} from 'shared/experiments/utils/experiments'
import store from 'shared/store'
import buildClientAPI from 'views/providers/clientAPI'
import createA11Dialog from 'views/utils/createA11Dialog'

const showCrosssellModal = (delay: number) => {
  const modalContainer = <HTMLElement>document.getElementById(CROSSSELL_LAYOUT)
  const crosssellLayoutShown = sessionStorage.getItem('crosssellLayoutShown')

  if (modalContainer && !crosssellLayoutShown) {
    const modal = createA11Dialog({
      element: modalContainer,
    })

    const crosssellLayoutTimeout = setTimeout(() => {
      modal.show()
      sessionStorage.setItem('crosssellLayoutShown', '1')
      clearTimeout(crosssellLayoutTimeout)
    }, delay)

    document
      .querySelector('.o-CrosssellLayout__go-back')
      ?.addEventListener('click', () => modal.hide())
  }
}

const BLACKLISTED_PAGES = [
  PAGE_TYPE_ACCOUNT,
  PAGE_TYPE_CART,
  PAGE_TYPE_CART_INTERMEDIATE,
  PAGE_TYPE_REDEEM_E_RECIPE,
]

export default async () => {
  const crosssellLayout = getExperimentOnClient(CROSSSELL_LAYOUT)
  const publicRuntimeConfig = store.getState()?.publicRuntimeConfig
  const { mountPoints } = publicRuntimeConfig?.publicConfig || {}
  const { pageType = '', searchUrl } = publicRuntimeConfig?.pageProperties || {}
  const delay = 150000
  const { cartServiceV3 } = buildClientAPI()
  const miniCart = await cartServiceV3.fetchMiniCart()
  const cartQuantity = miniCart?.data?.entriesInCart || 0

  const blacklistedUrls = [
    mountPoints[PRESCRIPTION_MANAGER],
    mountPoints[NFC_LANDING_PAGE],
    '/magazin/e-health/e-rezept/',
    '/lp/rezeptbonus/',
  ]

  if (
    !crosssellLayout.isEnabled ||
    BLACKLISTED_PAGES.includes(pageType) ||
    blacklistedUrls.includes(searchUrl) ||
    cartQuantity !== 0
  ) {
    return
  }

  const crosssellLayoutActivationTimeout = setTimeout(() => {
    void activateExperiment(CROSSSELL_LAYOUT)
    clearTimeout(crosssellLayoutActivationTimeout)
  }, delay)

  if (crosssellLayout.variant !== EXPERIMENT_VARIATION.DEFAULT) {
    showCrosssellModal(delay)
  }

  trackCswSlider({})
}
