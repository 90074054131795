import { CSW_PRODUCT_TITLE } from 'shared/experiments/consts'
import { activateExperiment } from 'shared/experiments/utils/experiments'

export default async () => {
  const domElement = document.querySelector('.csw-title--enabled')

  if (domElement) {
    await activateExperiment(CSW_PRODUCT_TITLE)
  }
}
