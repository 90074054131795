// @ts-nocheck
import url from 'url'

import { ML_TENANTS } from 'shared/consts/multilanguage'
import logger from 'shared/services/logger'
import { Tenant } from 'types/shopConfig'

const isRelative = (href: string): boolean => /^\.\.?(\/|$)/.test(href)
const hasExtension = (href: string): boolean => /\.[^/.]+$/.test(href)

export const stripLeadingSlash = (href: string) => href.replace(/^\/+/, '')
export const stripTrailingSlash = (href: string) => href.replace(/\/+$/, '')

const normalizePathnameSlashPrefix = (href: string): string => {
  if (href === '') {
    return ''
  }
  if (/^\/+$/.test(href)) {
    return '/'
  }

  const slashlessHref = stripLeadingSlash(href)
  if (isRelative(slashlessHref)) {
    return slashlessHref
  }
  return `/${slashlessHref}`
}

const normalizePathnameSlashSuffix = (href: string): string => {
  if (href === '') {
    return ''
  }
  if (/^\/+$/.test(href)) {
    return '/'
  }

  const slashlessHref = stripTrailingSlash(href)
  if (hasExtension(slashlessHref)) {
    return slashlessHref
  }
  return `${slashlessHref}/`
}

const normalizeSearchQuery = (searchQuery: unknown): string => {
  return searchQuery === '?' ? '' : searchQuery
}

const normalizeHash = (hash: unknown): string => {
  return hash === '#' ? '' : hash
}

export const normalizeUrl = (href: string | URL | unknown): string | null => {
  if (href instanceof URL) {
    return normalizeUrl(href.toString())
  }

  if (typeof href !== 'string') {
    return null
  }

  if (href.trim() === '') {
    return ''
  }

  const {
    hostname,
    pathname: oldPathname,
    search: oldSearch,
    hash: oldHash,
    ...urlRest
  } = url.parse(href)

  let newPathname = normalizePathnameSlashPrefix(
    normalizePathnameSlashSuffix(oldPathname || '')
  ).replace(/\/{2,}/g, '/')

  if (newPathname === '/' && hostname) {
    newPathname = ''
  }

  const newSearch = normalizeSearchQuery(oldSearch)
  const newHash = normalizeHash(oldHash)

  return url.format({
    hostname,
    pathname: newPathname,
    search: newSearch,
    hash: newHash,
    ...urlRest,
  })
}

export const getAbsoluteUrl = (baseHref: string, href: string): string => {
  const { host, protocol } = url.parse(baseHref)

  if (!host || !protocol) {
    throw new Error('baseHref is invalid')
  }

  return url.format({
    ...url.parse(href),
    protocol,
    host,
  })
}

export const hasQueryParams = (urlString: string): boolean => {
  const { query } = url.parse(urlString)

  return Boolean(query?.length)
}

export const setHash = (urlString: string, hash: string): string =>
  urlString
    ? url.format(Object.assign(url.parse(urlString), { hash }))
    : urlString

export const getUrlWithoutLanguage = (urlString: string): string => {
  if (urlString.includes('localhost')) return urlString

  const urlStringNormalized = normalizePathnameSlashSuffix(urlString)
  const urlSplitted = urlStringNormalized.split('/')

  if (urlSplitted[urlSplitted.length - 1].length > 3)
    return urlSplitted.join('/')
  return urlSplitted.slice(0, -2).join('/')
}

export const createFormatPathWithLanguage =
  (mlTenants: Tenant[]) =>
  (tenant: Tenant) =>
  (language: string) =>
  (path: string): string => {
    // specific case: href="#" which moves the scroll position to the top
    if (!path) {
      return ''
    }
    if (path === '#') {
      return path
    }
    const strippedPath = stripLeadingSlash(path)
    if (mlTenants.includes(tenant)) {
      return `/${language}/${strippedPath}`
    }
    return `/${strippedPath}`
  }
// preloaded with multilingual tenants
export const formatPathWithLanguage = createFormatPathWithLanguage(ML_TENANTS)

export const stripLanguageFromPath = (path, language) => {
  let stripped = false
  if (path.startsWith('/')) {
    path = stripLeadingSlash(path)
    stripped = true
  }
  let finalPath = path.replace(new RegExp(`^${language}/`), '')
  finalPath = stripped ? `/${finalPath}` : finalPath
  return finalPath
}

export const buildBrandUrl: string = (urlString: string) => {
  try {
    const { pathname, search, hash } = new URL(
      urlString,
      // we just need to compute the path so the base url is not relevant to tenant in this case
      'https://shop-apotheke.com'
    )
    return `${pathname}${pathname.slice(-1) != '/' ? '/' : ''}${search}${hash}`
  } catch (error) {
    logger.error('Invalid brand URL:', { error })

    return ''
  }
}

export const replaceUrlWithLocalhost = (path: string) => {
  const pathSplitted = path.split('/')
  pathSplitted[0] = 'http:'
  pathSplitted[2] = 'localhost:8080'

  return pathSplitted.join('/')
}

export const replaceUrlForProduct = (
  path: string,
  originURL: string,
  shopURL: string
) => {
  const originURLWithoutLanguage = getUrlWithoutLanguage(originURL)
  const shopUrlWithoutLanguage = getUrlWithoutLanguage(shopURL)
  return path.replace(shopUrlWithoutLanguage, originURLWithoutLanguage)
}
