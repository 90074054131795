const TENANT_COM = 'com'
const TENANT_AT = 'at'
const TENANT_CH = 'ch'
const TENANT_FR = 'fr'
const TENANT_IT = 'it'
const TENANT_BE = 'be'

module.exports = {
  TENANT_COM,
  TENANT_AT,
  TENANT_CH,
  TENANT_FR,
  TENANT_IT,
  TENANT_BE,
}
