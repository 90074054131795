// @ts-nocheck
import { AxiosResponse } from 'axios'

import {
  AllSummaryResponseV1,
  FetchCategoryReviewsParams,
  FetchReviewsListParams,
  PaginatedReviewsSummaryResponseV1,
  PaginatedReviewsSummaryResponseWithProductV1,
  ProductV1,
  type ReviewRequestV1,
} from 'types/api/productReview'

import BackendAPIService from './BackendAPIService'
import { ServiceParameters } from './commonTypes/ServiceParameters'

const API_VERSION = 'v1'

const objectToParameters = query => {
  return Object.entries(query)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
}

const defaultReviewListParams: FetchReviewsListParams = {
  page: 1,
  pageSize: 5,
}

const defaultCategoryReviewsParams: FetchCategoryReviewsParams = {
  limit: 2,
}
type CategoryProductsService = {
  fetchProductsFromSubcategories: (
    categoryCode: string
  ) => Promise<AxiosResponse<CategoryReviewsResponseV1 | null>>
}
interface IProductReviewService {
  fetchReviews: (
    variant_code: string,
    params: FetchReviewsListParams
  ) => Promise<AxiosResponse<PaginatedReviewsSummaryResponseV1>>

  fetchReviewsWithCustomLanguage: (
    variant_code: string,
    language: string,
    params: FetchReviewsListParams
  ) => Promise<AxiosResponse<PaginatedReviewsSummaryResponseV1>>

  fetchReviewsSummary: (
    variant_code: string
  ) => Promise<AxiosResponse<SummaryResponseV1>>

  fetchAllReviewsSummary: (
    variant_code: string
  ) => Promise<AxiosResponse<AllSummaryResponseV1>>

  fetchCategoryReviews: (
    categoryCode: string,
    params: FetchCategoryReviewsParams
  ) => Promise<AxiosResponse<CategoryReviewsResponseV1>>
  fetchReviewsFromSubcategories: (
    categoryCode: string,
    params: FetchCategoryReviewsParams
  ) => Promise<AxiosResponse<CategoryReviewsResponseV1>>
  categoryProductsService: CategoryProductsService
}

class ProductReviewService
  extends BackendAPIService
  implements IProductReviewService
{
  constructor(props: ServiceParameters) {
    super(props)

    this.apiVersion = API_VERSION
  }

  /** @var {CategoryProductsService} categoryProductsService */
  categoryProductsService: CategoryProductsService = {
    fetchProductsFromSubcategories: () =>
      new Promise<AxiosResponse<CategoryReviewsResponseV1>>(() => ({})),
  }

  public createReviewUrl = (): string =>
    `/product-review/${this.apiVersion}/${this.language}/${this.tenantPath}/reviews`

  public getReviewsUrl = (
    variant_code: string,
    params: FetchReviewsListParams = defaultReviewListParams
  ): string =>
    `/product-review/${this.apiVersion}/${this.language}/${
      this.tenantPath
    }/variants/${variant_code}/reviews?${objectToParameters(params)}`

  public getReviewsUrlWithCustomLanguage = (
    variant_code: string,
    language: string,
    params: FetchReviewsListParams = defaultReviewListParams
  ): string =>
    `/product-review/${this.apiVersion}/${language}/${
      this.tenantPath
    }/variants/${variant_code}/reviews?${objectToParameters(params)}`

  public getSummaryUrl = (variant_code: string): string =>
    `/product-review/${this.apiVersion}/${this.language}/${this.tenantPath}/variants/${variant_code}/summary`

  public getAllSummaryUrl = (variant_code: string): string =>
    `/product-review/${this.apiVersion}/${this.language}/${this.tenantPath}/variants/${variant_code}/all/summary`

  public getCategoryReviewsUrl = (
    categoryCode: string,
    params: FetchCategoryReviewsParams = defaultCategoryReviewsParams
  ): string =>
    `/product-review/${this.apiVersion}/${this.language}/${
      this.tenantPath
    }/category/${categoryCode}/reviews?${objectToParameters(params)}`

  public checkCustomerReviewUrl = (upid: string): string =>
    `/product-review/${this.apiVersion}/${this.language}/${this.tenantPath}/products/${upid}`

  async createReview(
    reviewBody: ReviewRequestV1
  ): Promise<AxiosResponse<ReviewResponseV1>> {
    const response: AxiosResponse<ReviewResponseV1> = await this.post(
      this.createReviewUrl(),
      reviewBody
    )
    this.logger.debug('Product Review Service creates review', 'dataCreation')

    return response
  }

  async fetchReviews(
    variant_code: string,
    params: FetchReviewsListParams
  ): Promise<AxiosResponse<PaginatedReviewsSummaryResponseV1>> {
    const response: Promise<AxiosResponse<PaginatedReviewsSummaryResponseV1>> =
      this.get(this.getReviewsUrl(variant_code, params))
    this.logger.debug(
      `Reviews and Summary for variant ${variant_code} fetched!`,
      'dataFetching'
    )

    return response
  }

  async fetchReviewsWithProductData(
    product: ProductV1,
    channel
  ): Promise<AxiosResponse<PaginatedReviewsSummaryResponseWithProductV1>> {
    const variant_code = product.variant_code

    if (!product.variant_code) return false

    const { data } = await this.fetchReviews(variant_code, {
      page: 1,
      pageSize: 1,
      channel,
    })

    if (data) {
      const { reviews } = data

      if (!reviews?.length) return false

      return {
        product: {
          canonical_url: product.canonicalURL || product.deeplink,
          image: product.imageUrl || product.image,
          name: product.name || product.productName,
          upid: product.upid,
        },
        review: reviews[0],
      }
    }
  }

  async fetchReviewsWithCustomLanguage(
    variant_code: string,
    language: string,
    params: FetchReviewsListParams
  ): Promise<AxiosResponse<PaginatedReviewsSummaryResponseV1>> {
    const response: Promise<AxiosResponse<PaginatedReviewsSummaryResponseV1>> =
      this.get(
        this.getReviewsUrlWithCustomLanguage(variant_code, language, params)
      )
    this.logger.debug(
      `Reviews and Summary with custom language for variant ${variant_code} fetched!`,
      'dataFetching'
    )

    return response
  }

  async fetchReviewsSummary(
    variant_code: string
  ): Promise<AxiosResponse<SummaryResponseV1>> {
    const response: Promise<AxiosResponse<SummaryResponseV1>> = this.get(
      this.getSummaryUrl(variant_code)
    )
    this.logger.debug(
      `Summary for variant ${variant_code} fetched!`,
      'dataFetching'
    )

    return response
  }

  async fetchAllReviewsSummary(
    variant_code: string
  ): Promise<AxiosResponse<AllSummaryResponseV1>> {
    const response: Promise<AxiosResponse<AllSummaryResponseV1>> = this.get(
      this.getAllSummaryUrl(variant_code)
    )
    this.logger.debug(
      `All summary for variant ${variant_code} fetched!`,
      'dataFetching'
    )

    return response
  }

  async fetchCategoryReviews(
    categoryCode: string,
    params: FetchCategoryReviewsParams
  ): Promise<AxiosResponse<CategoryReviewsResponseV1>> {
    const response: Promise<AxiosResponse<CategoryReviewsResponseV1>> =
      this.get(this.getCategoryReviewsUrl(categoryCode, params))
    this.logger.debug(
      `Category Reviews for ${categoryCode} fetched!`,
      'dataFetching'
    )

    return response
  }

  async checkCustomerReview(
    upid: string
  ): Promise<AxiosResponse<ReviewResponseV1>> {
    const response: Promise<AxiosResponse<ReviewResponseV1>> = this.get(
      this.checkCustomerReviewUrl(upid)
    )
    this.logger.debug(
      `Check whether customer already reviewed product ${upid}`,
      'dataFetching'
    )

    return response
  }

  async fetchReviewsFromSubcategories(
    categoryCode: string,
    channel: string,
    params: FetchCategoryReviewsParams
  ): Promise<CategoryReviewsResponseV1> {
    //if homepage, there is a special logic in the service
    if (categoryCode === 'all') {
      const { data } = await this.fetchCategoryReviews(
        categoryCode,
        defaultCategoryReviewsParams
      )

      return data
    }

    const products =
      await this.categoryProductsService.fetchProductsFromSubcategories(
        categoryCode
      )
    const productWithReviews = await Promise.all(
      products?.map(async product => {
        return await this.fetchReviewsWithProductData(
          product,
          channel,
          'categoryReviews'
        )
      })
    )

    return {
      reviews: productWithReviews.filter(Boolean).slice(0, params.limit),
    }
  }
}

export default ProductReviewService
