import { PAGE_TYPE_CATEGORY, PAGE_TYPE_SEARCH_RESULTS } from 'shared/consts'
import {
  activateExperiment,
  getExperimentOnClient,
} from 'shared/experiments/utils/experiments'
import store from 'shared/store'
import { NOW_SHIPPING_MODAL_CONTAINER_ID } from 'views/components/organisms/NowShippingModal/names'
import { OPEN_NOW_SHIPPING_MODAL } from 'views/containers/browser/NowShippingModalContainer/clientsideHook'
import loadCatalogs from 'views/containers/browser/utils/loadCatalogs'
import { getExperimentNameForPageType } from 'views/utils/nowGlobalBar'

const mountNowShippingModalContainer = async () => {
  const searchResultsLinks = document.querySelectorAll(
    `[data-clientside-hook*="${OPEN_NOW_SHIPPING_MODAL}"]`
  )
  const modal = document.getElementById(NOW_SHIPPING_MODAL_CONTAINER_ID)

  if (!modal) {
    return
  }

  const {
    publicConfig: { locale },
    pageProperties: { pageType },
  } = store.getPublicRuntimeConfig()

  const i18n = await loadCatalogs(locale)

  const nowShippingModalContainer = await import(
    /* webpackChunkName: "NowShippingModalContainerWithHydration" */
    './NowShippingModalContainerWithHydration'
  )

  searchResultsLinks.forEach(link => {
    const searchResultUpid = link.getAttribute('data-product-identifier')

    if (searchResultUpid) {
      link.addEventListener('click', () => {
        nowShippingModalContainer.default({
          searchResultUpid,
          container: modal,
          i18n,
        })
      })
    } else {
      nowShippingModalContainer.default({
        container: modal,
        i18n,
      })
    }
  })

  if (
    pageType === PAGE_TYPE_SEARCH_RESULTS ||
    pageType === PAGE_TYPE_CATEGORY
  ) {
    const experiment = getExperimentOnClient(
      getExperimentNameForPageType(pageType)
    )
    if (experiment && experiment.isEnabled) {
      await activateExperiment(experiment.name)
    }
  }
}

export default mountNowShippingModalContainer
