import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const PATH = `M64.04846,35.54223c2.21442,2.11121,2.29803,5.61786,0.18683,7.83228
c-2.11127,2.21442-5.61786,2.2981-7.83234,0.18683c-2.21442-2.11121-2.29803-5.61786-0.18683-7.83228
c1.12408-1.17902,2.64294-1.73456,4.15253-1.6958c-1.16968,2.461-1.37085,4.72241-0.39178,6.51239
c0.13788,0.25275,0.29645,0.4917,0.47565,0.71631c0.17346,0.21887,0.43085,0.33319,0.69049,0.33319
c0.19299,0,0.38721-0.06262,0.54919-0.19189c0.38141-0.30328,0.44458-0.85822,0.1413-1.23962
c-0.11487-0.14532-0.21826-0.29932-0.30792-0.46417c-0.76031-1.39032-0.46753-3.258,0.62836-5.33917
C62.83917,34.61437,63.4859,35.00591,64.04846,35.54223z M47.39703,53.01452c5.64044,5.37756,5.85345,14.30939,0.47589,19.94983
c-5.37756,5.64038-14.30939,5.85345-19.94983,0.47589c-5.64038-5.37756-5.85345-14.30945-0.47589-19.94983
C32.82477,47.84997,41.75659,47.63689,47.39703,53.01452z M31.4071,62.38329c0-0.63818-0.5174-1.15552-1.15558-1.15552
s-1.15552,0.51733-1.15552,1.15552c0,0.63824,0.51733,1.15558,1.15552,1.15558S31.4071,63.02154,31.4071,62.38329z
 M40.1153,69.39959l-3.19275-13.54205c-0.112-0.47449-0.58478-0.76288-1.06104-0.65717
c-0.47449,0.11261-0.76862,0.5871-0.65656,1.06158l3.19275,13.54205c0.09595,0.40674,0.45782,0.68018,0.85822,0.68018
c0.06665,0,0.13501-0.00806,0.20282-0.02301C39.93323,70.34856,40.22736,69.87408,40.1153,69.39959z M46.1701,63.22735
c0-0.63818-0.5174-1.15552-1.15558-1.15552s-1.15558,0.51733-1.15558,1.15552s0.5174,1.15558,1.15558,1.15558
S46.1701,63.86553,46.1701,63.22735z M100,49.99999c0,27.61426-22.38574,50-50,50c-27.6142,0-50-22.38574-50-50s22.3858-50,50-50
C77.61426-0.00001,100,22.38574,100,49.99999z M83.1134,48.30474L64.69427,30.7442
c2.81805-3.25806,7.10443-6.67816,12.2403-9.48737c2.88068-1.57654,5.7923-2.84149,8.65845-3.77368
c-0.43591-0.47675-0.88263-0.94336-1.3371-1.40234c-2.71344,0.93671-5.45496,2.14349-8.16919,3.62732
c-5.36108,2.93292-9.73932,6.40771-12.67322,9.815L50.39813,17.11444c-1.41077-1.34503-3.64484-1.29175-4.98987,0.11908
L3.16937,61.53692c4.49408,18.23682,19.3847,32.41479,38.00195,35.87427l42.0611-44.11652
C84.57751,51.8839,84.52423,49.64983,83.1134,48.30474z M86.21423,38.22082c3.17883-1.73901,6.1311-3.77002,8.64984-5.90137
c-0.22705-0.57397-0.46228-1.14337-0.71045-1.70642c-2.51398,2.18744-5.5307,4.27838-8.78729,6.05969
c-1.81409,0.99261-3.66956,1.8761-5.51355,2.62634c-0.45148,0.18384-0.66864,0.69855-0.4848,1.15009
c0.13898,0.34235,0.46875,0.54974,0.81683,0.54974c0.11145,0,0.22406-0.02069,0.33203-0.06494
C82.42401,40.15844,84.34039,39.24566,86.21423,38.22082z`

const IconRxVoucher: FC<IconProps> = props => (
  <SvgIcon {...props} path={PATH} viewBox="0 0 100 100" />
)

export default IconRxVoucher
