// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

import { ThreadSubject, ThreadSubjectV1 } from './ThreadSubject'

export interface ThreadSubjectsResponseV1 {
  reasons: ThreadSubjectV1[]
}

export class ThreadSubjectsResponse extends Model {
  public readonly reasons: ThreadSubject[]

  constructor(src: ThreadSubjectsResponseV1) {
    super(src)
    this.reasons = src.reasons?.length
      ? src.reasons.map((reason: ThreadSubjectV1) => new ThreadSubject(reason))
      : []
  }
}
