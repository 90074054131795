import classNames from 'clsx'

import Button from 'views/components/atoms/Button'

import './ButtonSecondary.scss'

const ButtonSecondary = props => {
  const { children, className, ...rest } = props
  const buttonClassname = classNames('a-Button--secondary', className)

  return (
    <Button {...rest} className={buttonClassname}>
      {children}
    </Button>
  )
}

ButtonSecondary.propTypes = Button.propTypes

ButtonSecondary.defaultProps = Button.defaultProps

export default ButtonSecondary
