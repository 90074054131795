// Do not add data types here this will break infering on the reducer switch
// we use typeof instead
// Types
export const ADD_ERROR_NOTIFICATION_ACTION = 'ADD_ERROR_NOTIFICATION_ACTION'
export const ADD_REDPOINTS_BALANCE_SUCCESS = 'ADD_REDPOINTS_BALANCE_SUCCESS'
export const ADD_VOUCHER_REQUEST = 'ADD_VOUCHER_REQUEST'
export const ADD_VOUCHER_ERROR = 'ADD_VOUCHER_ERROR'
export const ADD_VOUCHER_SUCCESS = 'ADD_VOUCHER_SUCCESS'
export const CART_ERROR = 'CART_ERROR'
export const CART_SHIPPING_BOX_UNMOUNTED = 'CART_SHIPPING_BOX_UNMOUNTED'
export const CART_SPA_MOUNTED = 'CART_SPA_MOUNTED'
export const DELETE_CART_ITEM_REQUEST = 'DELETE_CART_ITEM_REQUEST'
export const DELETE_CART_ERX_ITEM_REQUEST = 'DELETE_CART_ERX_ITEM_REQUEST'
export const DELETE_CART_ITEM_SUCCESS = 'DELETE_CART_ITEM_SUCCESS'
export const DELETE_REDPOINTS_SUCCESS = 'DELETE_REDPOINTS_SUCCESS'
export const DELETE_VOUCHER_REQUEST = 'DELETE_VOUCHER_REQUEST'
export const DELETE_VOUCHER_SUCCESS = 'DELETE_VOUCHER_SUCCESS'
export const RESET_VOUCHER_SUCCESS = 'RESET_VOUCHER_SUCCESS'
export const RESET_VOUCHER_ERROR = 'RESET_VOUCHER_ERROR'
export const SET_COLLECTIVE_ORDER = 'SET_COLLECTIVE_ORDER'
export const UPDATE_CART_REQUEST = 'UPDATE_CARTPAGE_REQUEST'
export const UPDATE_CART_VOUCHER_ERROR = 'UPDATE_CART_VOUCHER_ERROR'
export const UPDATE_CART_SUCCESS = 'UPDATE_CARTPAGE_SUCCESS'
export const UPDATE_REDPOINTS_ERROR = 'UPDATE_REDPOINTS_ERROR'
export const UPDATE_REDPOINTS_REQUEST = 'UPDATE_REDPOINTS_REQUEST'
export const UPDATE_REDPOINTS_SUCCESS = 'UPDATE_REDPOINTS_SUCCESS'
export const CHANGE_PRESCRIPTION_FOLLOWS_SUCCESS =
  'CHANGE_PRESCRIPTION_FOLLOWS_SUCCESS'
export const CHANGE_COLLECTIVE_ORDER_SUCCESS = 'CHANGE_COLLECTIVE_ORDER_SUCCESS'
export const OPTIMISTIC_UPDATE_PRESCRIPTION_FOLLOWS =
  'OPTIMISTIC_UPDATE_PRESCRIPTION_FOLLOWS'
export const OPTIMISTIC_UPDATE_COLLECTIVE_ORDER =
  'OPTIMISTIC_UPDATE_COLLECTIVE_ORDER'
export const DELETE_CART_ITEMS = 'DELETE_CART_ITEMS'
