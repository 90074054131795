// @ts-nocheck
import { isPrimitiveValue } from 'shared/utils/objectUtils'

/**
 * Check whether input can not potencially introduce cycles
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#Description
 */
function isCycleSafe(input: unknown): boolean {
  return (
    isPrimitiveValue(input) ||
    (typeof input === 'object' && typeof input.toJSON === 'function')
  )
}

export default isCycleSafe
