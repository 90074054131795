/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosResponse } from 'axios'

import assert from 'shared/utils/assert'
import type { ReavailabityNotificationStatusResponseV1 } from 'types/api/reavailabilityNotification'

import BackendAPIService from './BackendAPIService'
import type { ServiceParameters } from './commonTypes'
import { validate } from './helpers/pznValidator'

const API_VERSION = 'v1'

class ReavailabilityService extends BackendAPIService {
  constructor(props: ServiceParameters) {
    super(props)

    this.apiVersion = API_VERSION
  }

  getBasePath() {
    return `/reavailability/${this.apiVersion}/${this.tenantPath}`
  }

  getIsCustomerSubscribedUrl(id: string) {
    return `${this.getBasePath()}/customer-subscription-status/${id}`
  }

  getSubscribeCustomerUrl(id: string) {
    const validatedUPid = validate(id)

    return `${this.getBasePath()}/subscribe/${validatedUPid?.pzn}`
  }

  /**
   * GET call to fetch the subscriptionStatus for the customer
   */
  async fetchCustomerSubScriptionStatus(id: string, params: any) {
    assert(
      id,
      'ReavailabilityNotification.fetchCustomerSubScriptionStatus: please provide an "id"'
    )
    this.logger.debug(
      `Fetching customerReavailabilitySubscriptionStatus ${id}`,
      'dataFetching'
    )
    const response: AxiosResponse<ReavailabityNotificationStatusResponseV1> =
      await this.get(this.getIsCustomerSubscribedUrl(id), { params })
    this.logger.debug(
      `CustomerReavailabilitySubscriptionStatus ${id} fetched!`,
      'dataFetching'
    )
    return response
  }

  /**
   * POST Call for subscribing the customer to a product
   */
  async createSubscribeCustomerToProduct(id: string, params: any) {
    assert(
      id,
      'ReavailabilityNotification.fetchCustomerSubScriptionStatus: please provide an "id"'
    )
    this.logger.debug(
      `Fetching customerReavailabilitySubscriptionStatus ${id}`,
      'dataFetching'
    )

    const response: AxiosResponse<ReavailabityNotificationStatusResponseV1> =
      await this.post(this.getSubscribeCustomerUrl(id), {}, { params })
    this.logger.debug(
      `Customer subscribed to product ${id} posted!`,
      'dataFetching'
    )
    return response
  }
}

export default ReavailabilityService
