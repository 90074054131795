import { SERVICE_UNAVAILABLE } from 'shared/consts/codes'

import BackendError from './BackendError'

class ServiceUnavailableError extends BackendError {
  constructor(messageWithData) {
    super(messageWithData)
    this.status = SERVICE_UNAVAILABLE
  }
}

export default ServiceUnavailableError
