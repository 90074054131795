import {
  PAGE_TYPE_BRAND_PAGE,
  PAGE_TYPE_CATEGORY,
  PAGE_TYPE_HOMEPAGE,
  PAGE_TYPE_LEGACY,
  PAGE_TYPE_MARKETING,
  PAGE_TYPE_TOPIC_LEXICON,
  PAGE_TYPE_TOPIC_SHOW,
} from 'shared/consts/page'

export const CMS_TEMPLATE_MARKETING = 'cms.template.Marketing'
export const CMS_TEMPLATE_LEGACY = 'cms.template.Legacy'

export const CMS_TEMPLATE_BRAND = 'cms.template.Brand'
export const CMS_TEMPLATE_CATEGORY = 'cms.template.Category'
export const CMS_TEMPLATE_CATEGORY_LEAF = 'cms.template.CategoryLeaf'
export const CMS_TEMPLATE_HOME = 'cms.template.Home'
export const CMS_TEMPLATE_TOPIC = 'cms.template.ThemeShop'
export const CMS_TEMPLATE_LEXICON = 'cms.template.Lexicon'
export const CMS_TEMPLATE_SYMPTOME_LEXICON = 'cms.template.SymptomeLexikon'
export const CMS_TEMPLATE_THERAPIE_LEXICON = 'cms.template.TherapieLexikon'
export const CMS_TEMPLATE_FRAMELESS = 'cms.template.Frameless'

export const PRODUCT_DATA_PROVIDER_OFFERS = 'pim.ProductOffers'
export const PRODUCT_DATA_PROVIDER_SETS = 'pim.ProductSets'
export const PRODUCT_DATA_PROVIDER_NOVELTIES = 'pim.ProductsNovelties'
export const PRODUCT_DATA_PROVIDER_OWN_BRANDS = 'pim.OwnBrands'
export const PRODUCT_DATA_PROVIDER_BY_CATEGORY = 'pim.ProductsByCategory'
export const PRODUCT_DATA_PROVIDER_NOW_PRODUCTS = 'pim.ProductsNow'
export const PRODUCT_DATA_PROVIDER_REDPOINTS = 'pim.ProductRedpoints'
export const PRODUCT_DATA_PROVIDER_ABO_PRODUCTS = 'pim.ProductsAbo'
export const PRODUCT_DATA_PROVIDER_PROMOTIONS = 'pim.ProductsPromotions'

export const CMS_TEMPLATES = [
  CMS_TEMPLATE_MARKETING,
  CMS_TEMPLATE_LEGACY,
  CMS_TEMPLATE_BRAND,
  CMS_TEMPLATE_CATEGORY,
  CMS_TEMPLATE_CATEGORY_LEAF,
  CMS_TEMPLATE_HOME,
  CMS_TEMPLATE_TOPIC,
]

export const CMS_TEMPLATE_TO_PAGE_TYPE = {
  [CMS_TEMPLATE_BRAND]: PAGE_TYPE_BRAND_PAGE,
  [CMS_TEMPLATE_CATEGORY]: PAGE_TYPE_CATEGORY,
  [CMS_TEMPLATE_LEGACY]: PAGE_TYPE_LEGACY,
  [CMS_TEMPLATE_MARKETING]: PAGE_TYPE_MARKETING,
  [CMS_TEMPLATE_CATEGORY_LEAF]: PAGE_TYPE_CATEGORY,
  [CMS_TEMPLATE_HOME]: PAGE_TYPE_HOMEPAGE,
  [CMS_TEMPLATE_TOPIC]: PAGE_TYPE_TOPIC_SHOW,
  [CMS_TEMPLATE_LEXICON]: PAGE_TYPE_TOPIC_LEXICON,
  [CMS_TEMPLATE_SYMPTOME_LEXICON]: PAGE_TYPE_TOPIC_LEXICON,
  [CMS_TEMPLATE_THERAPIE_LEXICON]: PAGE_TYPE_TOPIC_LEXICON,
}
