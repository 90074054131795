import { Trans } from '@lingui/react'
import classNames from 'clsx'
import type { ReactNode } from 'react'

import ButtonSmall from 'views/components/molecules/ButtonSmall'
import ModalCloseButton from 'views/components/molecules/Modal/ModalCloseButton'
import ModalTitle from 'views/components/molecules/Modal/ModalTitle'
import useModalClasses from 'views/components/molecules/Modal/useModalClasses'

import './Modal.scss'

export type JSModalProps = {
  id: string
  children: ReactNode
  title?: ReactNode | string
  open?: boolean | null | undefined
  size?: string
  light?: boolean
  noPadding?: boolean
  fullScreen?: boolean
  titleHasInnerHTML?: boolean
  className?: string
  closeButtonText?: string
  initShow?: boolean
}

const JSModal = ({
  noPadding = false,
  id,
  closeButtonText = /*i18n*/ 'general.link.label.close',
  title = null,
  titleHasInnerHTML = false,
  children,
  initShow = false,
  className = '',
  size,
  light = false,
  fullScreen = false,
  open = false,
}: JSModalProps) => {
  const titleId = title ? `${id}__Modal__title` : undefined
  const { dialogClassname, containerClassname } = useModalClasses({
    className,
    size,
    light,
    fullScreen,
    initShow,
    open,
  })

  const contentInnerClassname = classNames({
    'a-box a-box--medium m-Modal__inner': !noPadding,
  })

  return (
    <div
      className={classNames(containerClassname, 'm-Modal__root')}
      id={id}
      data-clientside-hook="modal"
      aria-hidden={open ? undefined : 'true'}
      role="dialog"
    >
      <div className={dialogClassname} aria-labelledby={titleId}>
        <ModalTitle title={title} id={id} titleHasInnerHTML={titleHasInnerHTML}>
          <div
            className={classNames('m-Modal__close', {
              'm-Modal__close--ssrOpen': open,
            })}
          >
            <ButtonSmall
              data-a11y-dialog-hide
              name="modal-close-button"
              href="#"
              primary
            >
              <ModalCloseButton closeButtonText={closeButtonText} />
            </ButtonSmall>
          </div>
        </ModalTitle>
        <div className="m-Modal__content">
          <div className={contentInnerClassname}>{children}</div>
        </div>
      </div>
      {open ? (
        <div data-a11y-dialog-hide className="m-Modal__backdrop" />
      ) : (
        /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
        <a
          href="#"
          tabIndex={-1}
          data-a11y-dialog-hide
          aria-hidden="true"
          className="m-Modal__backdrop"
        >
          <Trans id="general.link.label.close" />
        </a>
      )}
    </div>
  )
}

export default JSModal
