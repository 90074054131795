import store, { subscribe } from 'shared/store'
import {
  initSliderAction,
  LOAD_LAST_ACTIVITY,
  loadLastActivityAction,
} from 'shared/store/ducks/events'
import { LAST_ACTIVITY_ID } from 'views/consts'
import loadCatalogs from 'views/containers/browser/utils/loadCatalogs'
import intersectionObserver from 'views/utils/intersectionObserver'

import 'intersection-observer'

export const loadLastActivityComponent = async () => {
  const container = document.getElementById(LAST_ACTIVITY_ID)
  const {
    publicConfig: { locale },
  } = store.getPublicRuntimeConfig()

  if (container === null) {
    return
  }

  const i18n = await loadCatalogs(locale)
  const hydrateLastActivityComponent = await import(
    /* webpackChunkName: "LastActivityContainerwithHydration", webpackPrefetch: true */
    `./LastActivityContainerWithHydration`
  )

  hydrateLastActivityComponent.default({
    container,
    i18n,
  })

  const slider = container.querySelector('[data-clientside-hook~="slider"]')
  if (slider) {
    store.dispatch(initSliderAction())
  }
}

export default () => {
  subscribe.after.once(LOAD_LAST_ACTIVITY, loadLastActivityComponent)

  intersectionObserver({
    callback: () => store.dispatch(loadLastActivityAction()),
    targetElements: document.getElementById(LAST_ACTIVITY_ID),
  })
}
