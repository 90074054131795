import { type FC, Suspense } from 'react'

import logger from 'shared/services/logger'

import { ICON_TO_COMPONENT_MAP, USES_LAZY_LOADING } from './consts'
import SvgIcon, { type IconProps } from './SvgIcon'

type Props = IconProps & { icon: string }

/**
 * @deprecated Please import Icon/Icon* components instead
 */
const DeprecatedIcon: FC<Props> = ({ icon, ...props }) => {
  let IconComponent: FC<IconProps>
  if (!(icon in ICON_TO_COMPONENT_MAP)) {
    logger.error(`Icon path not found: ${icon}`)
    IconComponent = ICON_TO_COMPONENT_MAP.circleInfo
  } else {
    // @ts-ignore
    IconComponent = ICON_TO_COMPONENT_MAP[icon]
  }

  if (!USES_LAZY_LOADING) {
    return <IconComponent {...props} />
  }

  return (
    <Suspense fallback={<SvgIcon {...props} path="" />}>
      <IconComponent {...props} />
    </Suspense>
  )
}

export const ICONS = Array.from(Object.keys(ICON_TO_COMPONENT_MAP))
export default DeprecatedIcon
