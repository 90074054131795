import { IconStar } from '@redteclab/icons'
import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const IconStarEmpty: FC<IconProps> = props => (
  <SvgIcon {...props} component={IconStar} />
)

export default IconStarEmpty
