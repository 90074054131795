import assert from 'shared/utils/assert'

import BackendAPIService from './BackendAPIService'

const API_VERSION = 'v3'

class ContentService extends BackendAPIService {
  constructor(props) {
    super(props)

    this.apiVersion = API_VERSION
  }

  /**
   * @param {string} block
   * @return {string}
   */
  getBlockUrl(block) {
    return `/cacheable/${this.language}/content/${this.apiVersion}/${this.tenantPath}/blocks/${block}`
  }

  getNodeTreeUrl(nodeSlug) {
    return `/cacheable/${this.language}/content/${this.apiVersion}/${this.tenantPath}/nodes/${nodeSlug}`
  }

  getRouteUrl(path) {
    return `/cacheable/${this.language}/content/${this.apiVersion}/${this.tenantPath}/route/${path}`
  }

  getPageUrl(path) {
    return `/cacheable/${this.language}/content/${this.apiVersion}/${this.tenantPath}/page/${path}`
  }

  getNodesByCodeUrl(code) {
    return `/cacheable/${this.language}/content/${this.apiVersion}/${this.tenantPath}/nodes-by-code/${code}`
  }

  getGatewayPathByCodeUrl(code) {
    return `/cacheable/${this.language}/content/${this.apiVersion}/${this.tenantPath}/category-url-path-by-code/${code}`
  }

  async fetchNodeTree(nodeSlug) {
    assert(nodeSlug, 'ContentService.fetchNodeTree: please provide "nodeSlug"')
    this.logger.debug(`Fetching category node tree ${nodeSlug}`, 'dataFetching')
    const response = await this.get(this.getNodeTreeUrl(nodeSlug))
    this.logger.debug(`Category node tree ${nodeSlug} fetched!`, 'dataFetching')

    return response
  }

  /**
   * @param {string} path
   * @return {Promise<CmsResponse>}
   */
  async fetchRoute(path) {
    assert(path, 'ContentService.fetchRoute: please provide "path"')
    this.logger.debug(`Fetching route data ${path}`, 'dataFetching')

    const response = await this.get(this.getRouteUrl(encodeURIComponent(path)))
    this.logger.debug(`Route data ${path} fetched!`, 'dataFetching')

    return response
  }

  async fetchPage(path) {
    assert(path, 'ContentService.fetchPage: please provide "path"')
    this.logger.debug(`Fetching page data ${path}`, 'dataFetching')

    const response = await this.get(this.getPageUrl(path))
    this.logger.debug(`Page data ${path} fetched!`, 'dataFetching')

    return response
  }

  /**
   * @return {Promise<import('axios').AxiosResponse<import('@redteclab/api/clients/content-management-system').FockBlock>>}
   */
  async fetchTopLevelMenu() {
    const response = await this.get(this.getBlockUrl('top-level-menu'))
    this.logger.debug(`Top level menu data fetched!`, 'dataFetching')

    return response
  }

  /**
   * @return {Promise<import('axios').AxiosResponse<import('@redteclab/api/clients/content-management-system').FockBlock>>}
   */
  async fetchFooter() {
    const response = await this.get(this.getBlockUrl('footer'))
    this.logger.debug(`Footer data fetched!`, 'dataFetching')

    return response
  }

  async fetchCategoryByCode(code) {
    //fallback for the homepage
    if (code === 'all') {
      return {}
    }
    const response = await this.get(this.getNodesByCodeUrl(code))
    this.logger.debug(`Category data fetched!`, 'dataFetching')

    return response
  }

  /**
   * https://jira.shop-apotheke.com/browse/WSAWA-3383
   * Process the new endpoint category-url-path-by-code. Needed for the case, when the products
   * have redirect to the category in the API response. If the category has a redirect to another category,
   * the url of the end redirect will be taken as the product redirect.
   */
  fetchCategoryPathByCode = async categoryCode => {
    try {
      const { data } = await this.get(
        this.getGatewayPathByCodeUrl(categoryCode)
      )

      return data.urlPath
    } catch (e) {
      this.logger.debug(
        `No path for product redirect recieved for category ${categoryCode}!`,
        'dataFetching'
      )
    }
    return null
  }

  async fetchAllSubcategoriesCodes(code) {
    try {
      const { data } = (await this.fetchCategoryByCode(code)) || {}
      const { down } = data

      return down?.map(category => category.code)
    } catch (e) {
      this.logger.debug(`No category data recieved ${code}!`, 'dataFetching')
    }
    return []
  }
}

export default ContentService
