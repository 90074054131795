// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

import { OrderDocumentPositionERx } from './orderDocumentPositionERx'
import {
  OrderDocumentPositionERxV1,
  OrderDocumentsERxV1,
} from './ordersResponseTypes'

export class OrderDocumentsERx extends Model {
  public readonly type: string
  public readonly documentPositions: OrderDocumentPositionERx[]

  constructor(src: OrderDocumentsERxV1) {
    super(src)
    this.type = src.type
    this.documentPositions = Array.isArray(src.documentPositions)
      ? src.documentPositions.map(
          (documentPosition: OrderDocumentPositionERxV1) =>
            new OrderDocumentPositionERx(documentPosition)
        )
      : []
  }
}
