import { TYPE_PDP_TOP } from 'shared/consts'

interface WidInterface {
  mp: number
  sae: number
}

interface AidInterface {
  mp: string
  sae: string
}

export type CrossSellWidgetType = {
  [key: string]: number
}

const getWid = (
  isMpCrossSell: boolean,
  wid: WidInterface | string | number | null
) => {
  if (!wid) {
    return null
  } else {
    if (typeof wid === 'string') {
      wid = JSON.parse(wid)
    }
    if (typeof wid === 'object') {
      return Number(isMpCrossSell ? wid?.mp : wid?.sae)
    }
  }

  return Number(wid)
}

const getAid = (isMpCrossSell: boolean, aid: AidInterface | string) => {
  if (typeof aid === 'object') {
    return isMpCrossSell ? aid.mp : aid.sae
  }
  return aid
}

const getIsBuyTogetherWidget = (
  wid: number,
  widgets: CrossSellWidgetType
): boolean => widgets[TYPE_PDP_TOP] === wid

export { getWid, getAid, getIsBuyTogetherWidget }
