export const loadingIndicator = ({
  preserveWidth,
  size,
  color,
  thickness,
  label,
}) => `
<span style="display: inline-block; width: ${preserveWidth}; height: ${size}">
  <span
    class="a-LoadingIndicator"
    style="width: ${size}; height: ${size}; color: ${color}"
    role="progressbar"
  >
    <svg viewBox="22 22 44 44">
      <circle
        cx="44"
        cy="44"
        r="20.2"
        fill="none"
        stroke-width=${thickness}
        aria-label=${label}
      ></circle>
    </svg>
  </span>
</span>
`
