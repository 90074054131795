import type { ICookies, IOptions } from 'cookies'
import type { CookieAttributes, CookiesStatic } from 'js-cookie'
import { v4 as uuid } from 'uuid'

import {
  AD_SERVER_BROWSER_ID_CONVERSION_COOKIE,
  AD_SERVER_BROWSER_ID_TARGETING_COOKIE,
  AD_SERVER_MEDIA_CODE_SEARCH_PARAM,
} from 'shared/consts/adServer'
import {
  AD_SERVER,
  AD_SERVER_BANNER,
  AD_SERVER_CROSSSELL_PRODUCT,
  AD_SERVER_SEARCH_PRODUCT,
  type ExperimentName,
  NON_DEFAULT_AD_SERVER_VARIANTS,
} from 'shared/experiments/consts'
import { getExperiment } from 'shared/experiments/utils/experiments'
import logger from 'shared/services/logger'
import { isCookieConsent } from 'shared/utils/cookieConsent'
import { filterValues } from 'shared/utils/objectUtils'
import type {
  AdServerMetadata,
  AdServerMetadataContent,
  AdServerMetadataCookie,
  AdServerRequestUser,
  AdServerTrackingUrlsWithPzn,
  AdUserIdentity,
  Placement,
} from 'types/adServerTypes'
import type { SearchHit } from 'types/search'
import { type Experiment } from 'types/shopConfig'

export type ServerCookie = ICookies
export type ServerCookieOptions = Omit<IOptions, 'expires' | 'sameSite'>

export type ClientCookie = CookiesStatic
export type ClientCookieOptions = Omit<CookieAttributes, 'sameSite'>

export const defaultCookieOptions: ServerCookieOptions | ClientCookieOptions = {
  path: '/',
  httpOnly: false,
}

const TTL = 90

const getClientCookieOptions = (): ClientCookieOptions => {
  return {
    ...defaultCookieOptions,
    expires: TTL,
  }
}

const getServerCookieOptions = (): ServerCookieOptions => {
  const multiplier: number = 24 * 60 * 60 * 1000
  return {
    ...defaultCookieOptions,
    maxAge: TTL * multiplier,
  }
}

export const getCookieOptions = (
  cookieName: string,
  onServer: boolean
): ClientCookieOptions | ServerCookieOptions => {
  return onServer ? getServerCookieOptions() : getClientCookieOptions()
}

const isExperimentEnabled = (
  experiments: Experiment[],
  experimentName: ExperimentName
): boolean => {
  const adServerExperiment: Experiment = getExperiment(
    experimentName,
    experiments
  )
  const adServerFeatureFlagVariant = adServerExperiment.variant

  return (
    NON_DEFAULT_AD_SERVER_VARIANTS.includes(
      adServerFeatureFlagVariant as string
    ) && adServerExperiment.isEnabled
  )
}

// checks if ad-server experiment variant is non-default, supported variant
export const isAdServerEnabled = (experiments: Experiment[] = []) =>
  isExperimentEnabled(experiments, AD_SERVER)

export const isAdServerSearchExperimentEnabled = (
  experiments: Experiment[] = []
) =>
  isAdServerEnabled(experiments) &&
  isExperimentEnabled(experiments, AD_SERVER_SEARCH_PRODUCT)

export const isAdServerCrossSellExperimentEnabled = (
  experiments: Experiment[] = []
) =>
  isAdServerEnabled(experiments) &&
  isExperimentEnabled(experiments, AD_SERVER_CROSSSELL_PRODUCT)

export const isAdServerBannerExperimentEnabled = (
  experiments: Experiment[] = []
) =>
  isAdServerEnabled(experiments) &&
  isExperimentEnabled(experiments, AD_SERVER_BANNER)

export const getTargetingKeywordsFromSearchParams = (
  queryParams: string | URLSearchParams
): Array<string> => {
  const searchParams =
    queryParams instanceof URLSearchParams
      ? queryParams
      : new URLSearchParams(queryParams)

  const queryKey = Array.from(searchParams.keys()).find(
    key => key.toLowerCase() === AD_SERVER_MEDIA_CODE_SEARCH_PARAM
  )

  if (!queryKey) {
    return []
  }

  return searchParams.get(queryKey)?.split(',').filter(Boolean) ?? []
}

export const getSetAdUserIdentity = (cookies: ServerCookie | ClientCookie) => {
  const onServer: boolean = 'request' in cookies

  let browserIdConversion = cookies.get(AD_SERVER_BROWSER_ID_CONVERSION_COOKIE)
  if (!browserIdConversion) {
    cookies.set(
      AD_SERVER_BROWSER_ID_CONVERSION_COOKIE,
      uuid(),
      getCookieOptions(AD_SERVER_BROWSER_ID_CONVERSION_COOKIE, onServer)
    )
    browserIdConversion = cookies.get(AD_SERVER_BROWSER_ID_CONVERSION_COOKIE)
  }

  let browserIdTargeting
  if (isCookieConsent(cookies)) {
    browserIdTargeting = cookies.get(AD_SERVER_BROWSER_ID_TARGETING_COOKIE)
    if (!browserIdTargeting) {
      cookies.set(
        AD_SERVER_BROWSER_ID_TARGETING_COOKIE,
        uuid(),
        getCookieOptions(AD_SERVER_BROWSER_ID_TARGETING_COOKIE, onServer)
      )
      // make sure that we set properly cookie for targeting browser id
      browserIdTargeting = cookies.get(AD_SERVER_BROWSER_ID_TARGETING_COOKIE)
    }
  }

  return {
    browserIdConversion,
    browserIdTargeting,
  }
}

export const extractTrackingUrlsFromMetadata = (
  metadata: AdServerMetadata
): AdServerTrackingUrlsWithPzn | Record<string, never> => {
  try {
    return Object.fromEntries(
      Object.entries(metadata).map(([pzn, metadataContent]) => {
        const { trackingUrls } = metadataContent || {}
        return [pzn, trackingUrls]
      })
    )
  } catch (e) {
    logger.error(
      `Failed to extract tracking urls from the ad-server metadata: ${e}`
    )
    return {}
  }
}

export const extractKevelIdsAndClickUrlFromMetadataContent = (
  metadataContent: AdServerMetadataContent
):
  | AdServerMetadataCookie
  | Pick<AdServerMetadataCookie, 'trackingUrls'>
  | undefined => {
  const {
    campaignId,
    creativeId,
    flightId,
    trackingUrls: { clickUrl } = {},
  } = metadataContent

  if (!clickUrl) {
    logger.error(`Failed to extract the click url from metadataContent`)
    return
  } else if (!campaignId || !creativeId || !flightId) {
    logger.error('Failed to extract the ids from metadataContent')
    return { trackingUrls: { clickUrl } }
  } else {
    return {
      campaignId,
      flightId,
      creativeId,
      trackingUrls: {
        clickUrl,
      },
    }
  }
}

export const getMetadataContentFromPlacement = (
  placement: Placement
): AdServerMetadataContent => {
  const {
    impressionUrl,
    clickUrl,
    events,
    flightId,
    creativeId,
    campaignId,
    customData,
  } = placement
  return {
    trackingUrls: {
      impressionUrl,
      clickUrl,
      events,
    },
    flightId,
    creativeId,
    campaignId,
    customData,
  }
}

export const extractJParamFromTrackingUrl = (
  url: string
): string[] | string | null => {
  try {
    const parsedUrl = new URL(url)
    const searchParams = new URLSearchParams(parsedUrl.searchParams)

    return searchParams.get('j')
  } catch (e) {
    logger.error(`Failed to extract j param the tracking url: ${e}`)
    return null
  }
}

export const extractTsParamFromTrackingUrl = (
  url: string
): string[] | string | null => {
  try {
    const parsedUrl = new URL(url)
    const searchParams = new URLSearchParams(parsedUrl.searchParams)

    return searchParams.get('ts')
  } catch (e) {
    logger.error(`Failed to extract ts param the tracking url: ${e}`)
    return null
  }
}

export const extractHostAndPathFromTrackingUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url)
    const { origin, pathname } = parsedUrl
    return origin + pathname
  } catch (e) {
    logger.error(`Failed to extract host and path from the tracking url: ${e}`)
    return null
  }
}

export const reorderHitsBySponsoredProduct = (
  hits: SearchHit[]
): SearchHit[] => {
  const reorderedHits: SearchHit[] = []
  const restHits: SearchHit[] = []

  hits.forEach(hit => {
    if (hit.position && hit.position <= hits.length) {
      reorderedHits[hit.position - 1] = hit
    } else {
      restHits.push(hit)
    }
  })

  restHits.forEach(hit => {
    let index = 0

    while (reorderedHits[index]) {
      index += 1
    }

    reorderedHits[index] = hit
  })

  return reorderedHits
}

export const mapAdServerIdsToAcronymIds = (
  user: AdUserIdentity
): AdServerRequestUser => {
  return filterValues(Boolean, {
    asbidc: user.browserIdConversion,
    asbidt: user.browserIdTargeting,
    asuid: user.userId,
  })
}
