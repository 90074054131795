import { DEBUG, INFO, stdSerializers, WARN } from 'browser-bunyan'

import ColorizedStream from './ColorizedStream'
import JsonStream from './JsonStream'
import StreamWrapper from './StreamWrapper'

/**
 * Build environment dependent logging config.
 */
const config = () => {
  const env = process.env.LOG_ENV || process.env.APP_ENV

  switch (env) {
    case 'production':
    case 'reference':
      return {
        name: 'rtl',
        streams: [
          {
            level: WARN,
            stream: new StreamWrapper(new JsonStream()),
          },
        ],
        serializers: stdSerializers,
      }
    case 'verbose':
      return {
        name: 'rtl',
        streams: [
          {
            level: DEBUG,
            stream: new StreamWrapper(new ColorizedStream()),
          },
        ],
        serializers: stdSerializers,
      }
    default:
      return {
        name: 'rtl',
        streams: [
          {
            level: INFO,
            stream: new StreamWrapper(new ColorizedStream()),
          },
        ],
        serializers: stdSerializers,
      }
  }
}

export default config
