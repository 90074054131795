// @ts-nocheck
import {
  parseBoolStringAttribute,
  stringifyBoolStringAttribute,
} from 'shared/utils/boolStringAttribute'

/**
 * Useful for attributes such as aria-hidden or aria-expanded
 *
 * Note that aria-hidden="false" is technically valid, but not well
 * supported, so we remove attribute instead of setting to "false"
 * Source: https://www.w3.org/TR/wai-aria-1.1/#h-note-43
 */
function toggleBoolStringAttribute(
  element: HTMLElement,
  attrName: string,
  flag: boolean = null
): void {
  if (!element) return

  let newValue: boolean
  if (flag != null) {
    newValue = flag
  } else {
    const oldValue: boolean = parseBoolStringAttribute(
      attrName,
      element.getAttribute(attrName)
    )
    newValue = !oldValue
  }

  const newStringValue: string = stringifyBoolStringAttribute(
    attrName,
    newValue
  )
  if (newStringValue == null) {
    element.removeAttribute(attrName)
  } else {
    element.setAttribute(attrName, newStringValue)
  }
}

export default toggleBoolStringAttribute
