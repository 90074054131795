// @ts-nocheck
import deepmerge from 'deepmerge'
import type { Reducer } from 'redux'

import { CartState } from 'types/redux'

import { CartActionTypes } from './actions'
import preloadedCartState from './preloadedState'
import {
  ADD_ERROR_NOTIFICATION_ACTION,
  ADD_VOUCHER_ERROR,
  ADD_VOUCHER_REQUEST,
  ADD_VOUCHER_SUCCESS,
  CART_SHIPPING_BOX_UNMOUNTED,
  DELETE_CART_ITEMS,
  OPTIMISTIC_UPDATE_COLLECTIVE_ORDER,
  OPTIMISTIC_UPDATE_PRESCRIPTION_FOLLOWS,
  RESET_VOUCHER_ERROR,
  RESET_VOUCHER_SUCCESS,
  SET_COLLECTIVE_ORDER,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_VOUCHER_ERROR,
  UPDATE_REDPOINTS_ERROR,
  UPDATE_REDPOINTS_REQUEST,
  UPDATE_REDPOINTS_SUCCESS,
} from './types.consts'

// REDUCER: modify state according to dispatched actions
const reducer: Reducer<CartState> = (
  state: CartState = preloadedCartState,
  action: CartActionTypes
): CartState => {
  switch (action.type) {
    case UPDATE_CART_SUCCESS:
      return {
        ...state,
        showSuccess:
          state.data.overdrafts.length > 0 &&
          action.payload.overdrafts.length === 0,
        data: action.payload,
      }
    case OPTIMISTIC_UPDATE_PRESCRIPTION_FOLLOWS:
      return {
        ...state,
        data: {
          ...state.data,
          prescriptionFollows: action.payload,
        },
      }
    case OPTIMISTIC_UPDATE_COLLECTIVE_ORDER:
      return {
        ...state,
        data: {
          ...state.data,
          collectiveOrder: action.payload,
        },
      }
    case SET_COLLECTIVE_ORDER:
      return {
        ...state,
        collectiveOrder: action.payload,
      }
    case ADD_ERROR_NOTIFICATION_ACTION:
      return deepmerge(state, {
        redPointError: action.payload,
      })
    case ADD_VOUCHER_REQUEST:
      return deepmerge(state, {
        voucherLoad: true,
      })
    case CART_SHIPPING_BOX_UNMOUNTED:
      return deepmerge(state, {
        addressBoxUnmounted: action.payload,
      })
    case ADD_VOUCHER_SUCCESS:
      return deepmerge(state, {
        voucherLoad: false,
        voucherAccepted: true,
        voucherError: null,
      })
    case ADD_VOUCHER_ERROR:
      return deepmerge(state, {
        voucherLoad: false,
        voucherError: action.payload,
      })
    case RESET_VOUCHER_SUCCESS:
      return deepmerge(state, {
        voucherAccepted: false,
      })
    case RESET_VOUCHER_ERROR:
      return deepmerge(state, {
        voucherLoad: false,
        voucherError: null,
      })
    case UPDATE_REDPOINTS_REQUEST:
      return deepmerge(state, {
        redPointsLoad: true,
        redPointsAccepted: false,
      })
    case UPDATE_CART_VOUCHER_ERROR:
      return deepmerge(state, {
        voucherError: action.payload.voucherError,
        promotionErrorMessage: action.payload.promotionErrorMessage,
      })
    case UPDATE_REDPOINTS_SUCCESS:
      return deepmerge(state, {
        redPointsLoad: false,
        redPointsAccepted: true,
      })
    case UPDATE_REDPOINTS_ERROR:
      return deepmerge(state, {
        redPointsLoad: false,
      })
    case DELETE_CART_ITEMS:
      return {
        ...state,
        offers: action.offers,
      }
    default:
      return state
  }
}
export default reducer
