export const VISIBLE_COUNT_DESKTOP_DEFAULT = 6
export const VISIBLE_COUNT_DESKTOP_SLIM = 3

export const VISIBLE_COUNT_TABLET_DEFAULT = 3.5
export const VISIBLE_COUNT_TABLET_WIDE = 4
export const VISIBLE_COUNT_MOBILE_DEFAULT = 2.25

export const VISIBLE_COUNT_BRANDS_DEFAULT = 4

export const DEFAULT_NUMBER_OF_VISIBLE_ITEM = 3
export const MAX_VISIBLE_ITEM_FOR_MULTILINE_SLIDER = 8
export const MAX_VISIBLE_ITEM_FOR_STICKY_SLIDER = 6

export const CROSSSELL_ITEMS_OVERALL_COUNT_DEFAULT = 20
export const CROSSSELL_ITEMS_OVERALL_COUNT_HUGE = 50
