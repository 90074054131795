import { parseBoolStringAttribute } from 'shared/utils/boolStringAttribute'
import toggleBoolStringAttr from 'views/utils/toggleBoolStringAttribute'

import { ARIA_PRESSED } from './consts'

export default () => {
  const passwordButtons = [
    ...document.querySelectorAll('[data-clientside-hook~="passwordReveal"]'),
  ]

  return passwordButtons
    .map(passwordButton => {
      const passwordInputElement = passwordButton
        .closest('[data-clientside-hook~="formControlContainer"]')
        .querySelector('input[type="text"],input[type="password"]')

      if (!passwordInputElement) {
        return false
      }
      const inputType = passwordInputElement.type
      let isVisible = inputType === 'text'

      const toggleIcon = () => {
        const currentPressedValue = Boolean(
          parseBoolStringAttribute(
            ARIA_PRESSED,
            passwordButton.getAttribute(ARIA_PRESSED)
          )
        )
        toggleBoolStringAttr(passwordButton, ARIA_PRESSED, !currentPressedValue)
      }

      const togglePasswordVisibility = () => {
        passwordInputElement.type = isVisible ? 'password' : 'text'
        passwordInputElement.focus()
        isVisible = !isVisible
        toggleIcon()
      }

      passwordButton.addEventListener('click', togglePasswordVisibility)
      return passwordInputElement
    })
    .filter(Boolean)
}
