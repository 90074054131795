import { DEFAULT_VARIANT_NAME, DIRECT_CHANNEL } from 'shared/experiments/consts'
import { DEFAULT_EXPERIMENT } from 'shared/experiments/consts/experiments'
import type { ExperimentName } from 'shared/experiments/consts/types'
import store from 'shared/store'
import type { Experiment } from 'types/shopConfig'
import { getExperimentsToActivate } from 'views/assets/scripts/optimizely'
import buildClientAPI from 'views/providers/clientAPI'
import { usePageProperties } from 'views/utils/shopAndRuntimeConfigUtils'

export const activateExperiment = (name: ExperimentName) => {
  const { clientFockService } = buildClientAPI()

  return clientFockService.activateExperiments([name])
}

/**
 * Takes the experiment id and returns true if it is enabled and rendered in DOM or listed in MANUALLY_ACTIVATED.
 */

export const isExperimentEnabled = (nameToFind: ExperimentName): boolean => {
  const experimentsToActivate = getExperimentsToActivate({
    includeExperimentsWithPreventActivation: true,
  })

  return experimentsToActivate.some(name => name === nameToFind)
}

export const isExperimentActive = (
  name: ExperimentName,
  experiments: Experiment[]
): boolean => {
  return experiments.some(
    experiment =>
      experiment.name === name && experiment.variant !== DEFAULT_VARIANT_NAME
  )
}

export const isAnyExperimentActive = (
  names: ExperimentName[],
  experiments: Experiment[]
): boolean =>
  experiments.some(
    ({ name, variant }) =>
      variant !== DEFAULT_VARIANT_NAME && names.includes(name)
  )

export const getExperiments = (
  names: ExperimentName[] = [],
  experiments: Experiment[] = []
): Experiment[] => experiments.filter(({ name }) => names.includes(name))

export const getExperiment = (
  name: ExperimentName,
  experiments: Experiment[] = []
): Experiment => {
  const experiment = experiments.find(
    ({ name: experimentName }) => experimentName === name
  )
  return experiment || ({ ...DEFAULT_EXPERIMENT, name } as Experiment)
}

export const getPricingStrategyExperimentValue = (
  experimentVariant: string
): string => {
  const customExpas: Record<string, string> = {
    default: DIRECT_CHANNEL,
    v1: 'tsta',
    v2: 'tstb',
    v3: 'tstc',
    v4: 'tstd',
  }

  if (!experimentVariant || !customExpas[experimentVariant]) {
    return DIRECT_CHANNEL
  }

  return customExpas[experimentVariant]
}

export const getExperimentOnClient = (
  experimentName: ExperimentName
): Experiment => {
  const {
    pageProperties: { experiments: experimentsList },
  } = store.getPublicRuntimeConfig()

  const experiment =
    experimentsList.find(({ name }) => name === experimentName) ||
    DEFAULT_EXPERIMENT

  return { ...experiment, name: experimentName } as Experiment
}
export const useGetExperiment = (
  experimentName: ExperimentName
): Experiment => {
  const { experiments: experimentsList = [] } = usePageProperties()

  const experiment =
    experimentsList.find(({ name }) => name === experimentName) ||
    DEFAULT_EXPERIMENT

  return { ...experiment, name: experimentName } as Experiment
}
