import { subscribe } from 'shared/store'
import { RESET_SELECT_VALUES, UPDATE_SELECT } from 'shared/store/ducks/events'
import { toggleOverflow } from 'views/utils/preventFromScrolling'
import toggleBoolStringAttr from 'views/utils/toggleBoolStringAttribute'

import { ARIA_DISABLED, ARIA_EXPANDED, ARIA_SELECTED } from './consts'

const SELECT_DROP_DOWN = '[data-clientside-hook~="select-drop-down"]'
const SELECT_ITEM = '[data-clientside-hook~="select-item"]'
const SELECT_CLOSE_BUTTON = '[data-clientside-hook~="select-close-button"]'
const SELECT_SELECTED_ITEM = '[data-clientside-hook~="select-selected-item"]'
const ITEM_HIGHLIGHTED_CLASSNAME = 'm-Select__item--highlighted'
const ITEM_SELECTED_CLASSNAME = 'm-Select__item--selected'
const IS_OPEN_CLASSNAME = 'is-open'
const IS_ACTIVE_CLASSNAME = 'is-active'
const NO_TRANSFORM_CLASS = 'u-no-transform@small-tiny'
const MODAL_CONTENT_CLASSNAME = 'm-Modal__content'

export const SELECT_ELEMENT = '[data-clientside-hook~="select"]'
export const SELECT_CONTAINER = '[data-clientside-hook~="select-container"]'
export const SELECT_REPLACEMENT = '[data-clientside-hook~="select-replace"]'
export const IS_DISABLED_CLASSNAME = 'is-disabled'

const INTERACTIVE_KEYS = [
  'Escape',
  'Esc',
  'Enter',
  'ArrowUp',
  'ArrowDown',
  'PageUp',
  'PageDown',
  'Up',
  'Down',
]

export const mountSelect = container => {
  const [bodyElement] = document.getElementsByTagName('body')

  const selectElements = [...container.querySelectorAll(SELECT_ELEMENT)]
  let activeContainer

  const onNavKeyPressed = (target, key) => {
    if (activeContainer !== target) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      showDropDown(target)
    }

    const arrowKeyPressed =
      key === 'ArrowUp' || key === 'Up' || key === 'ArrowDown' || key === 'Down'
    const isUpKey = key === 'ArrowUp' || key === 'Up' || key === 'PageUp'
    const items = [...target.querySelectorAll(SELECT_ITEM)]
    const focused = items.find(item =>
      item.classList.contains(ITEM_HIGHLIGHTED_CLASSNAME)
    )

    const itemToBeHighlighted = isUpKey ? items[0] : items[items.length - 1]
    if (focused === itemToBeHighlighted) {
      return
    }

    if (arrowKeyPressed) {
      const currentIndex = items.findIndex(item => item === focused)
      items[isUpKey ? currentIndex - 1 : currentIndex + 1].dispatchEvent(
        new Event('mouseover')
      )
    } else {
      itemToBeHighlighted.dispatchEvent(new Event('mouseover'))
    }
  }

  const onEnterKeyPressed = target => {
    if (activeContainer !== target) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      showDropDown(target)
      return
    }

    const items = [...target.querySelectorAll(SELECT_ITEM)]
    const selected = items.find(item =>
      item.classList.contains(ITEM_SELECTED_CLASSNAME)
    )
    const focused = items.find(item =>
      item.classList.contains(ITEM_HIGHLIGHTED_CLASSNAME)
    )

    if (selected === focused) {
      return
    }

    focused.click()
  }

  const keyDownEvents = event => {
    const { target, key } = event

    if (!INTERACTIVE_KEYS.includes(key)) {
      return
    }
    event.stopImmediatePropagation()
    event.preventDefault()

    switch (key) {
      case 'ArrowUp':
      case 'ArrowDown':
      case 'PageUp':
      case 'PageDown':
      case 'Up':
      case 'Down':
        onNavKeyPressed(target, key)
        break
      case 'Enter':
        onEnterKeyPressed(target)
        break
      default:
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        hideDropDown()
    }
  }

  const clickOnDocument = event => {
    const { target } = event

    if (!target.closest(SELECT_CONTAINER)) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      hideDropDown()
    }
  }

  const togglePreventScrolling = (isEnabled = false) => {
    toggleOverflow({
      parent: bodyElement,
      isEnabled,
      onlySmall: true,
    })
  }

  const handleModalInModal = (selectContainer, hideTitle) => {
    const modalContent = selectContainer?.closest(`.${MODAL_CONTENT_CLASSNAME}`)

    if (!modalContent) {
      return
    }

    if (hideTitle) {
      modalContent.classList.add(NO_TRANSFORM_CLASS)
    } else {
      modalContent.classList.remove(NO_TRANSFORM_CLASS)
    }
  }

  const hideDropDown = (selectContainer = activeContainer) => {
    if (!selectContainer) {
      return
    }

    const dropDown = selectContainer.querySelector(SELECT_DROP_DOWN)

    selectContainer.classList.remove(IS_OPEN_CLASSNAME)
    dropDown.classList.remove(IS_ACTIVE_CLASSNAME)
    toggleBoolStringAttr(dropDown, ARIA_EXPANDED, false)
    togglePreventScrolling(false)
    handleModalInModal(selectContainer, false)

    activeContainer = null

    document.removeEventListener('click', clickOnDocument)
  }

  const showDropDown = selectContainer => {
    const dropDown = selectContainer.querySelector(SELECT_DROP_DOWN)

    selectContainer.classList.add(IS_OPEN_CLASSNAME)
    dropDown.classList.add(IS_ACTIVE_CLASSNAME)

    toggleBoolStringAttr(dropDown, ARIA_EXPANDED, true)

    if (activeContainer !== selectContainer) {
      hideDropDown(activeContainer)
    }
    togglePreventScrolling(true)
    handleModalInModal(selectContainer, true)

    activeContainer = selectContainer

    document.addEventListener('click', clickOnDocument)
  }

  const toggleDropDown = (dropDown, selectReplacement) => {
    if (dropDown.getAttribute(ARIA_EXPANDED) === 'true') {
      hideDropDown(selectReplacement)
    } else {
      showDropDown(selectReplacement)
    }
  }

  return selectElements.map(selectElement => {
    const selectContainer = selectElement.closest(SELECT_CONTAINER)
    const selectReplacement = selectContainer.querySelector(SELECT_REPLACEMENT)

    selectReplacement.addEventListener('blur', ({ target }) => {
      if (!target.closest(SELECT_CONTAINER)) {
        hideDropDown(selectReplacement)
      }
    })

    const dropDown = selectContainer.querySelector(SELECT_DROP_DOWN)
    const items = [...selectContainer.querySelectorAll(SELECT_ITEM)]
    const closeButton = selectContainer.querySelector(SELECT_CLOSE_BUTTON)
    const selectedItem = selectContainer.querySelector(SELECT_SELECTED_ITEM)
    let currentSelected = items.find(
      item => item?.dataset?.value === selectedItem?.dataset?.value
    )

    selectContainer.dataset.clientsideHook = 'select-container'

    const updateSelectedValue = item => {
      const {
        innerText: text,
        dataset: { value, upid },
      } = item
      toggleBoolStringAttr(selectedItem, 'data-value', value)
      selectedItem.textContent = text
      selectedItem.dataset.upid = upid

      toggleBoolStringAttr(currentSelected, ARIA_SELECTED, false)
      currentSelected.classList.remove(ITEM_HIGHLIGHTED_CLASSNAME)
      currentSelected.classList.remove(ITEM_SELECTED_CLASSNAME)

      toggleBoolStringAttr(item, ARIA_SELECTED, true)
      item.classList.add(ITEM_HIGHLIGHTED_CLASSNAME)
      item.classList.add(ITEM_SELECTED_CLASSNAME)

      currentSelected = item

      selectElement.value = value
    }

    selectedItem.parentNode.addEventListener(
      'click',
      event => {
        if (selectReplacement.classList.contains(IS_DISABLED_CLASSNAME)) {
          return
        }

        toggleDropDown(dropDown, selectReplacement)
        event.stopImmediatePropagation()
      },
      true
    )

    selectReplacement.addEventListener('keydown', keyDownEvents, true)

    closeButton.addEventListener(
      'click',
      event => {
        event.stopImmediatePropagation()
        hideDropDown()
      },
      true
    )

    const onItemMouseOver = event => {
      const { target } = event
      const item = target.closest(SELECT_ITEM)

      items.forEach(i => i.classList.remove(ITEM_HIGHLIGHTED_CLASSNAME))
      item.classList.add(ITEM_HIGHLIGHTED_CLASSNAME)
    }

    const onItemClick = event => {
      const { target } = event
      const item = target.closest(SELECT_ITEM)

      if (item.getAttribute(ARIA_DISABLED) === 'true') {
        return
      }

      hideDropDown(selectReplacement)

      // Prevent from updating if user selects already selected item
      if (item.getAttribute(ARIA_SELECTED) === 'true') {
        return
      }

      updateSelectedValue(item)
      selectElement.dispatchEvent(new Event('change'))
    }

    items.forEach(item => {
      item.addEventListener('mouseover', onItemMouseOver)
      item.addEventListener('click', onItemClick)
    })

    subscribe.after(UPDATE_SELECT, payload => {
      const { select, value } = payload

      if (selectElement === select) {
        const item = items.find(({ dataset }) => value === dataset.value)

        updateSelectedValue(item)
      }
    })

    subscribe.after(RESET_SELECT_VALUES, payload => {
      const { selects } = payload

      if (selects.find(select => selectElement === select)) {
        const item = items.find(
          ({ dataset }) => selectElement.value === dataset.value
        )

        updateSelectedValue(item)
      }
    })

    return selectReplacement
  })
}

export default (container = document) => {
  mountSelect(container)
}
