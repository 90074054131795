import toggleBoolStringAttr from 'views/utils/toggleBoolStringAttribute'

import { ARIA_HIDDEN } from './consts'

/**
 * copyLink
 * ========
 *
 * Allows to enable automatic copy to clipboard from <textarea> and <input type=text> tags
 * on click on a specific clickable element
 * All four tag within the container are MANDATORY, in case all required elements are not
 * found it will not consider the element
 *
 * @example
<div data-clientside-hook="copyField sss"> // -- KEY_TAG
  ...
    <input type="button" value="press me" data-clientside-hook="copyField__button"/> // - BUTTON
    <input type="text" data-clientside-hook="copyField__value"/> // ------------ INPUTorTEXTAREA
    <div data-clientside-hook="copyField__text></div> // ----------------------------- ARIA_TEXT
    <div data-clientside-hook="copyField__success></div> // ----------------------- ARIA_SUCCESS
  ..
</div>
 *
 * Notice that inside the container the four required tags can be found at any
 * level within the KEY_TAG.
 *
 * At click on the BUTTON it:
 * - copies the content from INPUTorTEXTAREA into the clipboard
 * - shows ARIA_SUCCESS hides ARIA_TEXT
 * - after 3 seconds hides ARIA_SUCCESS shows ARIA_TEXT
 *
 * @returns {Array}
 * of all enabled KEY_TAG containers
 */
export default () => {
  const copyFieldElements = [
    ...document.querySelectorAll('[data-clientside-hook~="copyField"]'),
  ]
  return copyFieldElements
    .map(copyFieldElement => {
      const buttonElement = copyFieldElement.querySelector(
        '[data-clientside-hook~="copyField__button"]'
      )
      const valueElement = copyFieldElement.querySelector(
        '[data-clientside-hook~="copyField__value"]'
      )
      /** @type HTMLElement */
      const textElement = copyFieldElement.querySelector(
        '[data-clientside-hook~="copyField__text"]'
      )
      /** @type HTMLElement */
      const successElement = copyFieldElement.querySelector(
        '[data-clientside-hook~="copyField__success"]'
      )

      if (!buttonElement || !valueElement || !textElement || !successElement) {
        return null
      }

      const toggler = success => {
        toggleBoolStringAttr(textElement, ARIA_HIDDEN, success)
        toggleBoolStringAttr(successElement, ARIA_HIDDEN, !success)
      }
      const showSuccess = () => toggler(true)
      const showText = () => toggler(false)

      const copyToClipboard = event => {
        valueElement.select()
        document.execCommand('copy')
        event.target.focus()
      }

      let timeout = null

      const copyValue = event => {
        clearTimeout(timeout)
        event.preventDefault()
        copyToClipboard(event)
        showSuccess()
        // reset after 3sec
        timeout = setTimeout(showText, 3000)
      }

      buttonElement.addEventListener('click', copyValue)

      return copyFieldElement
    })
    .filter(Boolean)
}
