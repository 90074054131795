import {
  TENANT_AT,
  TENANT_BE,
  TENANT_CH,
  TENANT_COM,
  TENANT_FR,
  TENANT_IT,
} from 'shared/consts/tenants'
import logoAtDesktop from 'views/assets/images/logos/logo-AT-desktop.svg'
import logoAtMobile from 'views/assets/images/logos/logo-AT-mobile.svg'
import logoBeDesktop from 'views/assets/images/logos/logo-BE-desktop.svg'
import logoBeMobile from 'views/assets/images/logos/logo-BE-mobile.svg'
import logoChDesktop from 'views/assets/images/logos/logo-CH-desktop.svg'
import logoChMobile from 'views/assets/images/logos/logo-CH-mobile.svg'
import logoComDesktop from 'views/assets/images/logos/logo-COM-desktop.svg'
import logoComMobile from 'views/assets/images/logos/logo-COM-mobile.svg'
import logoFrDesktop from 'views/assets/images/logos/logo-FR-desktop.svg'
import logoFrMobile from 'views/assets/images/logos/logo-FR-mobile.svg'
import logoIconRedcare from 'views/assets/images/logos/logo-icon-redcare.svg'
import logoItRebranding from 'views/assets/images/logos/logo-IT-rebranding.svg'
import logoRebrandingBe from 'views/assets/images/logos/logo-rebranding-be.svg'
import logoRebrandingChDeDesktop from 'views/assets/images/logos/logo-rebranding-ch-de-desktop.svg'
import logoRebrandingChDeMobile from 'views/assets/images/logos/logo-rebranding-ch-de-mobile.svg'
import logoRebrandingCom from 'views/assets/images/logos/logo-rebranding-com.svg'
import logoRebrandingFrDesktop from 'views/assets/images/logos/logo-rebranding-fr-desktop.svg'
import logoRebrandingFrMobile from 'views/assets/images/logos/logo-rebranding-fr-mobile.svg'

export const MOBILE = 'mobile'
export const DESKTOP = 'desktop'
export const MOBILE_REBRANDING = 'mobileRebranding'
export const DESKTOP_REBRANDING = 'desktopRebranding'
export const MOBILE_ERX_MANAGER = 'mobileErxManager'
export const DESKTOP_ERX_MANAGER = 'desktopErxManager'

export const SHOP_LOGOS = {
  [TENANT_COM]: {
    [MOBILE]: { src: logoComMobile, width: 179, height: 22 },
    [DESKTOP]: { src: logoComDesktop, width: 236, height: 28 },
    [MOBILE_REBRANDING]: {
      src: logoRebrandingCom,
      width: 140,
      height: 22,
    },
    [DESKTOP_REBRANDING]: {
      src: logoRebrandingCom,
      width: 260,
      height: 28,
    },
    [MOBILE_ERX_MANAGER]: {
      src: logoIconRedcare,
      width: 36,
      height: 36,
    },
    [DESKTOP_ERX_MANAGER]: {
      src: logoRebrandingCom,
      width: 260,
      height: 28,
    },
  },
  [TENANT_AT]: {
    [MOBILE]: { src: logoAtMobile, width: 179, height: 22 },
    [DESKTOP]: { src: logoAtDesktop, width: 236, height: 28 },
    [MOBILE_REBRANDING]: {
      src: logoRebrandingCom,
      width: 179,
      height: 22,
    },
    [DESKTOP_REBRANDING]: {
      src: logoRebrandingCom,
      width: 236,
      height: 28,
    },
  },
  [TENANT_IT]: {
    [MOBILE]: {
      src: logoItRebranding,
      width: 148,
      height: 45,
    },
    [DESKTOP]: { src: logoItRebranding, width: 197, height: 45 },
  },
  [TENANT_FR]: {
    [MOBILE]: { src: logoFrMobile, width: 188, height: 70 },
    [DESKTOP]: { src: logoFrDesktop, width: 188, height: 70 },
    [MOBILE_REBRANDING]: {
      src: logoRebrandingFrMobile,
      width: 179,
      height: 44,
    },
    [DESKTOP_REBRANDING]: {
      src: logoRebrandingFrDesktop,
      width: 236,
      height: 28,
    },
  },
  [TENANT_BE]: {
    [MOBILE]: { src: logoBeMobile, width: 188, height: 70 },
    [DESKTOP]: { src: logoBeDesktop, width: 188, height: 70 },
    [MOBILE_REBRANDING]: {
      src: logoRebrandingBe,
      width: 179,
      height: 22,
    },
    [DESKTOP_REBRANDING]: {
      src: logoRebrandingBe,
      width: 236,
      height: 28,
    },
  },
  [TENANT_CH]: {
    [MOBILE]: { src: logoChMobile, width: 110, height: 43 },
    [DESKTOP]: { src: logoChDesktop, width: 180, height: 70 },
    [MOBILE_REBRANDING]: {
      src: logoRebrandingChDeMobile,
      width: 179,
      height: 44,
    },
    [DESKTOP_REBRANDING]: {
      src: logoRebrandingChDeDesktop,
      width: 236,
      height: 28,
    },
  },
}
SHOP_LOGOS.default = SHOP_LOGOS[TENANT_COM]
