import { SEARCH_BOX_MOBILE_WRAPPER_MODAL_ID } from 'views/consts'

export const SELECTOR_CONTAINER = '.o-SearchBox'
export const SELECTOR_DESKTOP_CONTAINER = '#h-SearchBox'
export const SELECTOR_MOBILE_CONTAINER = `#${SEARCH_BOX_MOBILE_WRAPPER_MODAL_ID}`

export const SELECTOR_DESKTOP_FORM = `${SELECTOR_DESKTOP_CONTAINER} form`
export const SELECTOR_DESKTOP_INPUT = `${SELECTOR_DESKTOP_CONTAINER} input`
export const SELECTOR_DESKTOP_PQSL_WRAPPER =
  '[data-clientside-hook~="pqsl_wrapper-desktop"]'

export const SELECTOR_MOBILE_INPUT =
  '[data-clientside-hook~="search__input--mobile"]'

export const SELECTOR_DUMMY_SEARCH_BOX =
  '[data-clientside-hook~="h-SearchBox__trigger--mobile"]'
export const SELECTOR_DUMMY_SEARCH_BOX_INPUT = `${SELECTOR_DUMMY_SEARCH_BOX} input[type=search]`

export const SELECTOR_MOBILE_SEARCH_ICON =
  '[data-clientside-hook="h-SearchBox__trigger__searchIcon"]'

// Min number of characters to trigger the fetching of suggested search results
export const TYPEAHEAD_MIN_QUERY_LEN = 2
