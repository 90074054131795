// @ts-nocheck
import { PRELOADED_STATE_KEY } from 'shared/store/helper/consts'
import { IState } from 'types/redux'

/**
 * builds the preloaded state
 *
 * the preloaded state is exposed as array of individual state objects.
 * individual state objects, as the page and esi tags can't add values to the same object but must
 * expose their own part of the state.
 * In order to get the final state, we reduce this array to a single object and merge all
 * first-level properties so they contain the data from all state objects
 *
 * @return {Object} preloaded state
 */
const getPreloadedState = (): IState => {
  const preloadedState = global[PRELOADED_STATE_KEY] || []

  const state: IState = preloadedState.reduce(
    (acc, partialState) =>
      Object.keys(partialState).reduce((_, key) => {
        if (acc[key]) {
          acc[key] = { ...acc[key], ...partialState[key] }
        } else {
          acc[key] = partialState[key]
        }
        return acc
      }, {}),
    {}
  )

  return state
}

export default getPreloadedState
