import { Model } from 'shared/services/api/models/Model'

import type { OrderLineItemV2 } from './ordersResponseTypes'

export class OrderInfoLineItem extends Model {
  public canOpenIncident: boolean
  public canRefund: boolean
  public hasIncident: boolean
  public offerId: string
  public upid: string
  public orderLineState: string
  public quantity: number
  public orderLineId: string
  public productTitle?: string
  public productImage?: string
  public packagingQuantity?: string
  public packagingUnit?: string
  public pharmaForm?: string

  constructor(src: OrderLineItemV2) {
    super(src)

    this.canOpenIncident = src.canOpenIncident
    this.canRefund = src.returnAvailable
    this.hasIncident = src.hasIncident
    this.offerId = src.offerId
    this.upid = src.upid
    this.orderLineState = src.status
    this.quantity = src.quantity
    this.orderLineId = src.orderLineId
  }
}
