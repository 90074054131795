// @ts-nocheck
import { type LetterboxCustomerV1 } from '@redteclab/api/clients/bully'

import { Model } from 'shared/services/api/models/Model'

export class LetterboxDocumentCustomer extends Model {
  public readonly lastName: string
  public readonly firstName: string
  public readonly dateOfBirth: string

  constructor(src: LetterboxCustomerV1) {
    super(src, { allowEmpty: false })
    this.lastName = src.lastname
    this.firstName = src.firstname
    this.dateOfBirth = src.date_of_birth
  }
}
