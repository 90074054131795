export const POSITION_FIRST = 'first'
export const POSITION_MIDDLE = 'middle'
export const POSITION_LAST = 'last'
export const POSITION_SINGLE = 'single'

export const POSITION_TYPES = [
  POSITION_FIRST,
  POSITION_LAST,
  POSITION_MIDDLE,
  POSITION_SINGLE,
]
export const HIDE_ON_INIT_ATTR = 'data-hide-on-init'
