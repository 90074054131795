import logger from 'shared/services/logger'
import normalizeFormField from 'shared/utils/normalizeFormField'
import { HIDDEN_CLASS } from 'views/assets/scripts/consts'
import buildClientAPI from 'views/providers/clientAPI'

export default () => {
  const { contactService, clientFockService } = buildClientAPI()
  const hasSuccess = /success/
  const CALLBACK = 'callback'
  const MESSAGE = 'message'
  const FORM = 'Form'
  const SUCCESS = 'Success'
  const ERROR = 'Error'

  const SELECTORS = {
    // product consultation callback form and notification selectors
    [CALLBACK]: {
      [FORM]: '[data-clientside-hook~="productConsultationCallbackForm"]',
      [SUCCESS]: '[data-clientside-hook~="productConsultationCallbackSuccess"]',
      [ERROR]: '[data-clientside-hook~="productConsultationCallbackError"]',
    },
    // product consultation message form and notification selectors
    [MESSAGE]: {
      [FORM]: '[data-clientside-hook~="productConsultationMessageForm"]',
      [SUCCESS]: '[data-clientside-hook~="productConsultationMessageSuccess"]',
      [ERROR]: '[data-clientside-hook~="productConsultationMessageError"]',
    },
  }

  const createElements = selectors =>
    Object.entries(selectors).reduce(
      (acc, [key, selector]) => ({
        ...acc,
        [key]: document.querySelector(selector),
      }),
      {}
    )

  const elements = {
    [CALLBACK]: createElements(SELECTORS[CALLBACK]),
    [MESSAGE]: createElements(SELECTORS[MESSAGE]),
  }

  const setVisibility = (element, visible) => {
    element.classList[visible ? 'remove' : 'add'](HIDDEN_CLASS)
  }

  const collectFormValues = form =>
    [...form.elements]
      .filter(element => element.value.length)
      .reduce(
        (result, element) => ({
          ...result,
          [element.name]: normalizeFormField(element),
        }),
        {}
      )

  const showNotification = ({ data = '', type, formElement }) => {
    if (hasSuccess.test(data)) {
      setVisibility(formElement, false)
      setVisibility(elements[type][ERROR], false)
      setVisibility(elements[type][SUCCESS], true)
    } else {
      setVisibility(elements[type][ERROR], true)
    }
  }

  const isFormSubmitting = {
    [CALLBACK]: false,
    [MESSAGE]: false,
  }

  const onSubmit = (type, service) => async e => {
    e.preventDefault()

    if (isFormSubmitting[type]) {
      return
    }

    const formElement = e.target

    if (!formElement.checkValidity()) {
      return
    }

    isFormSubmitting[type] = true

    let data

    try {
      const response = await service.call(
        type === CALLBACK ? clientFockService : contactService,
        {
          params: collectFormValues(formElement),
        }
      )

      data = type === CALLBACK ? response.data : 'success'
    } catch (error) {
      logger.error('Error in product consultation form submission', { error })
      // error is handled by showNotification below
      data = error.response.data?.invalidParams
    }

    showNotification({ data, type, formElement })

    isFormSubmitting[type] = false
  }

  if (elements[MESSAGE][FORM]) {
    elements[MESSAGE][FORM].addEventListener(
      'submit',
      onSubmit(MESSAGE, contactService.sendContactRequest)
    )
  }

  if (elements[CALLBACK][FORM]) {
    elements[CALLBACK][FORM].addEventListener(
      'submit',
      onSubmit(
        CALLBACK,
        clientFockService.submitProductConsultationCallbackForm // callback stays on LMS, use contactService.sendCallbackRequest to transfer it to ContactService
      )
    )
  }
}
