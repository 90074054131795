import { PAGE_TYPE_SEARCH_RESULTS } from 'shared/consts'
import { SERP_LOAD_MORE } from 'shared/experiments/consts'
import { isExperimentEnabled } from 'shared/experiments/utils/experiments'
import store from 'shared/store'
import { isMobile } from 'shared/utils/deviceClassUtils'
import throttle from 'shared/utils/throttle'
import { scrollToTop as scrollToTopSmoothly } from 'views/assets/scripts/scrollToTop'
import {
  SELECTOR_DUMMY_SEARCH_BOX,
  SELECTOR_DUMMY_SEARCH_BOX_INPUT,
} from 'views/containers/browser/SearchBoxContainer/consts'

const SCROLL_TRESHOLD_PX = 350
const THROTTLE_INTERVAL_MS = 150
const ACTIVE_CLASSNAME = 'o-HeaderSmall--mobile-active'
const BUTTON_SELECTOR = '.o-HeaderSmall--mobile'
const TOP_BANNER_CLOSE_ICON_SELECTOR = '#close-nc-banner'
const BUTTON_MARGIN_PX = 10

let mobileDummySearchBoxInput: HTMLElement | null
let mobileDummySearchBox: HTMLElement | null

const isEnvValid = () => {
  const publicRuntimeConfig = store.getState()?.publicRuntimeConfig
  const { deviceClass } = publicRuntimeConfig?.publicConfig || {}
  const { pageType } = publicRuntimeConfig?.pageProperties || {}

  return (
    isExperimentEnabled(SERP_LOAD_MORE) &&
    isMobile(deviceClass) &&
    pageType === PAGE_TYPE_SEARCH_RESULTS
  )
}

const hideElement = (element: HTMLElement) =>
  element.classList.remove(ACTIVE_CLASSNAME)

const showElement = (element: HTMLElement) =>
  element.classList.add(ACTIVE_CLASSNAME)

const getTopBannerHeight = () =>
  document.querySelector<HTMLElement>('.nc-banner')?.clientHeight || 0

const setElementTopOffset = (element: HTMLElement) => {
  if (!mobileDummySearchBoxInput || !mobileDummySearchBox) {
    return
  }

  const offset =
    BUTTON_MARGIN_PX +
    mobileDummySearchBoxInput.clientHeight +
    mobileDummySearchBox.clientHeight +
    getTopBannerHeight()

  if (!isNaN(offset)) {
    element.setAttribute('style', `top: ${offset}px`)
  }
}

const handlePageScroll = (element: HTMLElement) => {
  let previousScrollY = 0

  return () => {
    if (previousScrollY === window.scrollY) {
      return
    }

    // top of the page
    if (window.scrollY <= SCROLL_TRESHOLD_PX) {
      hideElement(element)
    } else if (previousScrollY < window.scrollY) {
      // scrolling down
      hideElement(element)
    } else {
      // scrolling up
      setElementTopOffset(element)
      showElement(element)
    }

    previousScrollY = window.scrollY
  }
}

const bindButtonEvents = (element: HTMLElement) => {
  element.addEventListener('click', () => scrollToTopSmoothly())
}

const bindPageEvents = (element: HTMLElement) =>
  document.addEventListener(
    'scroll',
    throttle(handlePageScroll(element), THROTTLE_INTERVAL_MS)
  )

/**
 * Wait until injected with GTM, bind handlers
 */
const bindTopBannerEvents = (element: HTMLElement) => {
  setTimeout(
    () =>
      document
        .querySelector(TOP_BANNER_CLOSE_ICON_SELECTOR)
        ?.addEventListener('click', () => {
          // rAF to fire right after the ongoing animation (banner hiding)
          requestAnimationFrame(() => setElementTopOffset(element))
        }),
    2000
  )
}

const scrollToTopMobile = () => {
  const scrollToTopButton =
    document.querySelector<HTMLButtonElement>(BUTTON_SELECTOR)

  if (!scrollToTopButton || !isEnvValid()) {
    return
  }

  mobileDummySearchBoxInput = document.querySelector<HTMLElement>(
    SELECTOR_DUMMY_SEARCH_BOX_INPUT
  )
  mobileDummySearchBox = document.querySelector<HTMLElement>(
    SELECTOR_DUMMY_SEARCH_BOX
  )

  bindButtonEvents(scrollToTopButton)
  bindTopBannerEvents(scrollToTopButton)
  bindPageEvents(scrollToTopButton)
}

export default scrollToTopMobile
