import { ARIA_HIDDEN } from 'views/assets/scripts/consts'

/**
 * Note that aria-hidden="false" is technically valid, but not well
 * supported, so we remove attribute instead of setting to "false"
 * Source: https://www.w3.org/TR/wai-aria-1.1/#h-note-43
 */

/**
 * @param {string} attrName Required because of special treatment for aria-hidden
 * @param {boolean|string} value
 * @return {boolean|undefined}
 */
export const parseBoolStringAttribute = (attrName, value) => {
  if (value == null) {
    return undefined
  }

  let boolValue
  if (typeof value === 'boolean') {
    boolValue = value
  } else {
    const lowerCasedValue = String(value).toLowerCase()
    if (lowerCasedValue === 'true') {
      boolValue = true
    } else if (lowerCasedValue === 'false') {
      boolValue = false
    } else {
      boolValue = undefined
    }
  }

  if (boolValue === false && attrName === ARIA_HIDDEN) {
    boolValue = undefined
  }

  return boolValue
}

/**
 * @param {string} attrName Required because of special treatment for aria-hidden
 * @param {boolean|string} value
 * @return {string|undefined}
 */
export const stringifyBoolStringAttribute = (attrName, value) => {
  const boolValue = parseBoolStringAttribute(attrName, value)
  if (boolValue == null) {
    return undefined
  }
  return String(boolValue)
}
