import { BULLY_AUTH_HEADER_NAME } from 'shared/consts/authCookie'
import {
  getPlainAuthCookie,
  setAuthCookie,
} from 'shared/utils/authCookieHandling'

// @ts-ignore
const refreshAuthCookie = (ctx, newAuthToken?: string) => {
  const currentToken = getPlainAuthCookie(ctx.cookies)

  if (
    newAuthToken &&
    typeof newAuthToken === 'string' &&
    currentToken !== newAuthToken
  ) {
    setAuthCookie(ctx.cookies, newAuthToken)
    return
  }

  if (!ctx.response) {
    ctx.logger.info('No response has been found. Skipping cookie update')
    return
  }

  const rtlToken = ctx.response.get(BULLY_AUTH_HEADER_NAME)

  if (rtlToken && typeof rtlToken === 'string' && rtlToken !== currentToken) {
    setAuthCookie(ctx.cookies, rtlToken)
  }
}

export default refreshAuthCookie
