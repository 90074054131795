import assert from 'shared/utils/assert'

export const extractTenantAndEnvironment = tenantAndEnv => {
  assert(typeof tenantAndEnv === 'string', 'tenantAndEnv should be a string')
  assert(tenantAndEnv.length > 0, 'tenantAndEnv should not be empty string')
  const [tenant, coreShopEnvironment = 'prod'] = tenantAndEnv.split('-')
  return { tenant, coreShopEnvironment }
}

export const createTenantPath = (tenant, coreShopEnvironment) => {
  return coreShopEnvironment === 'prod'
    ? tenant
    : `${tenant}-${coreShopEnvironment}`
}

export const applyRouteParams = (urlFormat, params) => {
  let url = urlFormat
  Object.entries(params).forEach(([name, value]) => {
    url = url.replace(`:${name}`, value)
  })
  return url
}
