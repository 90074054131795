import classNames from 'clsx'
import { bool, node, oneOf, string } from 'prop-types'

import { stringifyBoolStringAttribute } from 'shared/utils/boolStringAttribute'
import { filterValues } from 'shared/utils/objectUtils'
import { ARIA_HIDDEN } from 'views/assets/scripts/consts'
import { FormConsumer } from 'views/components/atoms/Form'
import countTruthy from 'views/utils/countTruthy'
import { buttonTypes } from 'views/utils/propTypes/buttonTypes'

import {
  BUTTON_ON_SUBMIT_ANIMATION_STYLES,
  BUTTON_STYLE_TO_CLASS_NAME_MAP,
  BUTTON_STYLES,
} from './consts'
import { applyLegacyPropAliases } from './helpers'

import './Button.scss'

const Button = props => {
  const {
    children,
    // eslint-disable-next-line react/prop-types
    suppressHydrationWarning,
    isClientsideControlled,
    isReactControlled,
    name,
    inline,
    small,
    rounded,
    roundedLeft,
    roundedRight,
    roundedCorners,
    flex,
    reverse,
    inlineDesktopOnly,
    style,
    isVisible,
    ...restProps
  } = props

  /** @type {object} */
  const componentProps = applyLegacyPropAliases(restProps)

  componentProps.className = classNames(
    'a-Button',
    componentProps.className,
    BUTTON_STYLE_TO_CLASS_NAME_MAP[style],
    {
      'a-Button--inline': inline,
      'a-Button--small': small,
      'a-Button--rounded-left': roundedLeft && rounded,
      'a-Button--rounded-right': roundedRight && rounded,
      'a-Button--rounded-none': !rounded,
      'a-Button--rounded-corners': roundedCorners,
      'a-Button--flex': flex,
      'a-Button--reverse': reverse,
      'a-Button--inline@medium': inlineDesktopOnly,
      'a-Button--hidden': !isVisible,
      'a-Button--disabled': componentProps.disabled && componentProps.href,
    }
  )

  const Component = componentProps.href ? 'a' : 'button'

  componentProps[ARIA_HIDDEN] = stringifyBoolStringAttribute(
    ARIA_HIDDEN,
    componentProps[ARIA_HIDDEN]
  )

  if (componentProps.disabled === false) {
    delete componentProps.disabled
  }

  if (componentProps.target === '_blank' && componentProps.rel == null) {
    componentProps.rel = 'noopener noreferrer'
  }

  const nonNullishProps = filterValues(value => value != null, componentProps)

  return (
    <FormConsumer>
      {formName => (
        <Component
          name={name}
          data-qa-id={`${formName}-${name}`}
          {...nonNullishProps}
        >
          {children}
        </Component>
      )}
    </FormConsumer>
  )
}

Button.propTypes = {
  children: node,
  name: string,
  className: string,
  disabled: bool,
  inline: bool,
  href: string,
  type: buttonTypes,
  onClick: props => {
    const {
      href,
      onClick,
      type,
      isClientsideControlled,
      disabled,
      isReactControlled,
    } = props

    if (
      !isReactControlled &&
      countTruthy([href, onClick, isClientsideControlled]) > 1 &&
      (type !== 'button' || href)
    ) {
      return new Error(
        'Button: can have just one of "href", "onClick", or "isClientsideControlled" properties.'
      )
    }

    if (
      !isClientsideControlled &&
      countTruthy([href, onClick, type, disabled]) === 0
    ) {
      return new Error(
        'Button: must have one of "href", "onClick", "type=submit", or "isClientsideControlled" properties.'
      )
    }

    if (onClick != null && typeof onClick !== 'function') {
      return new Error(
        `Button: "onClick" property must be a function, got ${typeof onClick}`
      )
    }

    return null
  },
  expanded: bool,
  pressed: bool,
  small: bool,
  'data-qa-id': string,
  ariaControls: string,
  ariaHaspopup: bool,
  ariaDescribedby: string,
  isClientsideControlled: bool,
  isReactControlled: bool,
  'data-clientside-hook': string,
  'data-analytics': string,
  'data-transition-out-class': string,
  'data-dismissed-class': string,
  title: string,
  rounded: bool,
  roundedRight: bool,
  roundedLeft: bool,
  roundedCorners: bool,
  flex: bool,
  scope: string,
  ariaPressed: string,
  target: oneOf(['_self', '_blank']),
  reverse: bool,
  ariaHidden: string,
  inlineDesktopOnly: bool,
  isVisible: bool,
  form: string,
  id: string,
  style: oneOf(BUTTON_STYLES),
  value: string,
  'data-submit-styles': oneOf(Object.values(BUTTON_ON_SUBMIT_ANIMATION_STYLES)),
}

Button.defaultProps = {
  className: '',
  disabled: false,
  inline: null,
  href: null,
  type: null,
  onClick: null,
  expanded: null,
  pressed: null,
  small: null,
  'data-qa-id': undefined,
  ariaControls: null,
  ariaHaspopup: null,
  ariaDescribedby: null,
  isClientsideControlled: false,
  isReactControlled: false,
  'data-clientside-hook': null,
  'data-analytics': null,
  'data-transition-out-class': null,
  'data-dismissed-class': null,
  title: null,
  rounded: true,
  roundedRight: false,
  roundedLeft: false,
  roundedCorners: false,
  flex: false,
  scope: null,
  ariaHidden: null,
  target: null,
  reverse: false,
  ariaPressed: null,
  inlineDesktopOnly: false,
  form: null,
  id: null,
  style: null,
  isVisible: true,
  name: undefined,
  value: undefined,
  'data-submit-styles': BUTTON_ON_SUBMIT_ANIMATION_STYLES.standard,
}

export default Button
