import { type AxiosResponse } from 'axios'

import { type ServiceParameters } from 'shared/services/api/commonTypes'
import {
  type ShippingControllerResponseV1,
  type ShippingThreshold,
  type ShippingThresholdResponseV1,
} from 'types/api/shipping'

import BackendAPIService from './BackendAPIService'

const API_VERSION = 'v1'

interface IShippingService {
  fetchShippingOptions: (
    id: string
  ) => Promise<AxiosResponse<ShippingControllerResponseV1>>
}

class ShippingService extends BackendAPIService implements IShippingService {
  constructor(props: ServiceParameters) {
    super(props)

    this.apiVersion = API_VERSION
  }

  public getFetchUrlById = (id: string): string =>
    `/carriers/${this.apiVersion}/${this.tenantPath}/${id}`

  public getFetchUrl = (): string =>
    `/carriers/${this.apiVersion}/${this.tenantPath}/`

  public getFetchFreeShippingThresholdUrl = (): string =>
    `${this.getFetchUrl()}freeShippingThresholds`

  public async fetchShippingOptionsByOrderDraftId(
    id: string
  ): Promise<AxiosResponse<ShippingControllerResponseV1>> {
    const response: AxiosResponse<ShippingControllerResponseV1> =
      await this.get(this.getFetchUrlById(id))
    this.logger.debug(`SHIPSe fetch carriers!`, 'dataFetching')
    return response
  }

  public async fetchShippingOptions(): Promise<
    AxiosResponse<ShippingControllerResponseV1>
  > {
    const response: AxiosResponse<ShippingControllerResponseV1> =
      await this.get(this.getFetchUrl())
    this.logger.debug(`SHIPSe fetch carriers!`, 'dataFetching')
    return response
  }

  public async fetchFreeShippingThreshold(): Promise<ShippingThreshold[]> {
    const response: AxiosResponse<ShippingThresholdResponseV1> = await this.get(
      this.getFetchFreeShippingThresholdUrl()
    )
    return response.data.freeShippingThresholds
  }
}

export default ShippingService
