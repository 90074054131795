import classNames from 'clsx'
import { bool } from 'prop-types'

import Button from 'views/components/atoms/Button'

import './ButtonSmall.scss'

const ButtonSmall = props => {
  const { children, primary, small, noRestricted, ...rest } = props

  const classname = classNames('m-ButtonSmall', {
    'm-ButtonSmall--blue': primary,
    'm-ButtonSmall--small': small,
    'm-ButtonSmall--no-restricted': noRestricted,
  })

  return (
    <Button {...rest} className={classname}>
      {children}
    </Button>
  )
}

ButtonSmall.propTypes = {
  primary: bool,
  small: bool,
  noRestricted: bool,
  ...Button.propTypes,
}

ButtonSmall.defaultProps = {
  primary: false,
  small: false,
  noRestricted: false,
  ...Button.defaultProps,
}

export default ButtonSmall
