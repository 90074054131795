import assert from 'shared/utils/assert'

import BackendAPIService from './BackendAPIService'
import type { ServiceParameters } from './commonTypes'
import type {
  ICreateReturnLabelRequestBody,
  IReturnLabel,
  IReturnReasonResponse,
} from './models/returnLabel'

const config = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
}

const API_VERSION = 'v1'

class ReturnLabelService extends BackendAPIService {
  constructor(props: ServiceParameters) {
    super(props)

    this.apiVersion = API_VERSION
  }

  getReturnLabelUrl(subOrderId: string) {
    return `returnlabel/${this.apiVersion}/${this.tenantPath}/${subOrderId}`
  }

  getReturnLabelUrlV2(orderId: string, subOrderId: string) {
    return `returnlabel/v2/${this.tenantPath}/${orderId}/${subOrderId}`
  }

  getReturnLabelUrlV3(orderId: string, subOrderId: string) {
    return `returnlabel/v3/${this.tenantPath}/${orderId}/${subOrderId}`
  }

  async getReturnReasons() {
    const { data } = await this.get<IReturnReasonResponse>(
      `returnlabel/${this.apiVersion}/${this.tenantPath}/returnreason/reasons`,
      config
    )

    return data
  }

  async getReturnLabel(subOrderId: string): Promise<IReturnLabel> {
    assert(
      subOrderId,
      'ReturnLabelService.getReturnLabelDoc: please provide "subOrderId"'
    )

    const { data } = await this.get<IReturnLabel>(
      this.getReturnLabelUrl(subOrderId),
      config
    )

    return data
  }

  async createReturnLabel(
    orderId: string,
    subOrderId: string,
    body: ICreateReturnLabelRequestBody
  ): Promise<unknown> {
    assert(
      orderId,
      'ReturnLabelService.createReturnLabel: please provide "orderId"'
    )

    assert(
      subOrderId,
      'ReturnLabelService.createReturnLabel: please provide "subOrderId"'
    )

    assert(
      body.suborderItems,
      'ReturnLabelService.createReturnLabel: please provide "suborderItems"'
    )

    const { data } = await this.post(
      this.getReturnLabelUrlV3(orderId, subOrderId),
      body,
      config
    )

    return data
  }
}

export default ReturnLabelService
