import { isEmpty } from 'shared/utils/objectUtils'

interface ModelOptions {
  allowEmpty: boolean
}

export abstract class Model {
  constructor(src: unknown, options: ModelOptions = { allowEmpty: true }) {
    if ((!src || isEmpty(src)) && !options.allowEmpty) {
      throw new Error(`${this.constructor.name}: source data can't be empty`)
    }
  }
}
