import { PAGE_TYPE_SEARCH_RESULTS } from 'shared/consts'
import store from 'shared/store'

export default () => {
  document.addEventListener('click', function (event) {
    const preQueryBrands = event.target.closest(
      '[data-clientside-hook~="SearchBoxListSuggestBrandLink"]'
    )
    const suggestRedirects = event.target.closest(
      '.o-SearchBox__search-suggest-lp-redirect .m-TextWithIcon'
    )
    const suggestCategories = event.target.closest(
      '.o-SearchBox__search-suggest__category-suggestion'
    )
    const crosssellWidget = event.target.closest('[data-crosssell-id]')

    if (
      (preQueryBrands || suggestRedirects || suggestCategories) &&
      !sessionStorage.getItem('searchInteracted')
    ) {
      sessionStorage.setItem('searchInteracted', 'true')
    }

    if (crosssellWidget && !sessionStorage.getItem('cswInteracted')) {
      const clickableElement = event.target.closest('button, a')
      if (clickableElement) {
        sessionStorage.setItem('cswInteracted', 'true')
      }
    }
  })

  const publicRuntimeConfig = store.getState()?.publicRuntimeConfig
  const { pageType = '' } = publicRuntimeConfig?.pageProperties || {}

  if (
    pageType === PAGE_TYPE_SEARCH_RESULTS &&
    !sessionStorage.getItem('searchInteracted')
  ) {
    sessionStorage.setItem('searchInteracted', 'true')
  }
}
