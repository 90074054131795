// @ts-nocheck
import axios, {
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios'
import deepmerge from 'deepmerge'
import type { Context } from 'koa'

import {
  API_TIMEOUT_MS,
  API_VERSION,
  BAD_REQUEST,
  FORBIDDEN,
  INTERNAL_SERVER_ERROR,
  NOT_FOUND,
  SERVICE_UNAVAILABLE,
  UNAUTHORIZED,
} from 'shared/consts'
import { BULLY_AUTH_HEADER_NAME } from 'shared/consts/authCookie'
import { IS_BROWSER, IS_DEVELOPMENT } from 'shared/consts/env'
import { ERROR_SENSITIVE_LOGIN_EXPIRED } from 'shared/consts/errorCodes'
import { X_FEATURE } from 'shared/consts/httpHeaders'
import AdServerNetworkError from 'shared/errors/AdServerNetworkError'
import BackendError from 'shared/errors/BackendError'
import BadRequestError from 'shared/errors/BadRequestError'
import DataLogError from 'shared/errors/DataLogError'
import ForbiddenError from 'shared/errors/ForbiddenError'
import InternalServerError from 'shared/errors/InternalServerError'
import NetworkError from 'shared/errors/NetworkError'
import NotFoundError from 'shared/errors/NotFoundError'
import ServiceUnavailableError from 'shared/errors/ServiceUnavailableError'
import { SoftLoginExpiredError } from 'shared/errors/SoftLoginExpiredError'
import TimeoutError from 'shared/errors/TimeoutError'
import UnauthorizedError from 'shared/errors/UnauthorizedError'
import loggerService, { Logger } from 'shared/services/logger'
import { getMountPointsInstance } from 'shared/store-checkout/duck/common'
import assert from 'shared/utils/assert'
import { pick } from 'shared/utils/objectUtils'
import refreshAuthCookie from 'shared/utils/refreshAuthCookie'

import type { Headers } from './commonTypes/IService'
import type { ServiceParameters } from './commonTypes/ServiceParameters'
import { addHeadersByExperiments } from './helpers/headers'

const CODE_TO_EXCEPTION = {
  [INTERNAL_SERVER_ERROR]: InternalServerError,
  [SERVICE_UNAVAILABLE]: ServiceUnavailableError,
  [BAD_REQUEST]: BadRequestError,
  [UNAUTHORIZED]: UnauthorizedError,
  [FORBIDDEN]: ForbiddenError,
  [NOT_FOUND]: NotFoundError,
}

const stringifyRequest: (
  request: AxiosRequestConfig | AxiosResponse
) => string = (request: AxiosRequestConfig | AxiosResponse): string =>
  JSON.stringify(
    pick(request, ['headers', 'method', 'baseURL', 'url', 'params', 'data']),
    null,
    2
  )

abstract class APIService {
  protected baseURL: string
  protected apiVersion: string
  protected featureBranch: string
  protected timeout: number
  protected client: AxiosInstance
  protected logger: Logger

  constructor({
    apiVersion = API_VERSION,
    authToken,
    baseURL,
    locale,
    contentTypeJSON = false,
    timeout = API_TIMEOUT_MS,
    experiments,
    featureBranch,
    logger = loggerService,
    needsAuth,
    adServerApiUrl,
    responseInterceptor: customResponseInterceptor = null,
  }: ServiceParameters) {
    assert(baseURL, 'APIService: no "baseURL" option provided')
    assert(logger, 'APIService: no "logger" option provided')
    const params: AxiosRequestConfig = {
      baseURL,
      timeout,
    }

    const headers: Record<string, unknown> = {}

    if (needsAuth && authToken) {
      headers.Authorization = authToken
    }

    if (locale) {
      headers['Accept-Language'] = locale
    }

    if (contentTypeJSON) {
      headers.Accept = 'application/json'
      headers['Content-Type'] = 'application/json'
    }

    addHeadersByExperiments(headers, experiments)

    if (Object.keys(headers).length > 0) {
      params.headers = headers
    }

    const client: AxiosInstance = axios.create(params)

    this.baseURL = baseURL
    this.apiVersion = apiVersion
    this.client = client
    this.logger = logger

    if (featureBranch) {
      this.featureBranch = featureBranch
    }

    const handleRequest = (request: AxiosRequestConfig): AxiosRequestConfig => {
      if (IS_DEVELOPMENT) {
        logger.debug(stringifyRequest(request), 'api:request')
      }
      return request
    }

    const handleResponse = (response: AxiosResponse): AxiosResponse => {
      if (IS_DEVELOPMENT) {
        logger.debug(stringifyRequest(response), 'api:response')
      }
      return response
    }

    this.client.interceptors.request.use(handleRequest)
    this.client.interceptors.response.use(handleResponse, error => {
      // There are some cases when we get 404 and we don't what to log the errors since is an expected behaviour
      const isErrorLoggingEnabled = !(
        error?.config?.isErrorLoggingSkipped &&
        error?.config?.isErrorLoggingSkipped(error.response)
      )

      const { message, config } = error
      const isTimoutExceeded =
        /\btimeout\b.*\bexceeded\b|\bexceeded\b.*\btimeout\b/gi.test(message)

      const url = config?.url
      const isAdServerNetworkError =
        adServerApiUrl && url?.includes(adServerApiUrl)

      if (isAdServerNetworkError) {
        return Promise.reject(new AdServerNetworkError(error))
      }

      if (isTimoutExceeded) {
        isErrorLoggingEnabled &&
          logger.error(`Timeout error intercepted: ${error.config?.url}`, {
            error: new DataLogError(error),
          })

        return Promise.reject(new TimeoutError(error))
      }

      const isSoftLoginExpired =
        ERROR_SENSITIVE_LOGIN_EXPIRED === error.response?.data?.message

      if (isSoftLoginExpired) {
        const invalidParams = error.response.data?.invalidParams

        if (!invalidParams) {
          logger.error(
            'invalidParams key is not defined on soft login expired error'
          )
        }

        const email = invalidParams?.find(
          invalidParam => invalidParam.key === 'email'
        )

        if (!email) {
          logger.error('Email was not defined on soft login expired error')
        }

        if (IS_BROWSER) {
          // eslint-disable-next-line no-restricted-globals
          const { location } = window
          const { pathname, search } = location

          return location.assign(
            getMountPointsInstance().getLogin({
              email: email?.field,
              forceLogin: true,
              redirect: `${pathname}${search}`,
            })
          )
        }

        return Promise.reject(
          new SoftLoginExpiredError(error.response.data.message, email?.field)
        )
      }

      const isNetworkError = /\bnetwork\b.*\berror\b/gi.test(message)
      if (isNetworkError) {
        isErrorLoggingEnabled &&
          logger.error(`Network error intercepted: ${error.config?.url}`, {
            error: new DataLogError(error),
          })
        return Promise.reject(new NetworkError(error))
      }

      const statusCode = error.code || error.response?.status
      const regExp = new RegExp(`\\b${statusCode}\\b`, 'gi')

      if (
        Object.keys(CODE_TO_EXCEPTION).includes(statusCode.toString()) ||
        regExp.test(message)
      ) {
        const Exception = CODE_TO_EXCEPTION[statusCode]

        if (Exception) {
          isErrorLoggingEnabled &&
            logger.error(`${statusCode} Error: ${error.config?.url}`, {
              error: new DataLogError(error),
            })
          return Promise.reject(new Exception(error))
        }
      }

      return Promise.reject(new BackendError(error))
    })

    if (typeof customResponseInterceptor === 'function') {
      this.client.interceptors.response.use(customResponseInterceptor)
    }
  }

  protected async request<T>(
    config: AxiosRequestConfig,
    ctx: Context = null
  ): Promise<AxiosResponse<T>> {
    const result: AxiosResponse<T> = await this.client.request<T>(
      deepmerge(config, this.prepareConfigFromContext(ctx))
    )

    if (ctx) {
      refreshAuthCookie(ctx, result.headers?.[BULLY_AUTH_HEADER_NAME])
    }

    return result
  }

  private prepareConfigFromContext(
    ctx: Context
  ): AxiosRequestConfig | Record<string, never> {
    const headers: Headers = {}

    if (this.featureBranch) {
      headers[X_FEATURE] = this.featureBranch
    }

    const authorization = ctx?.state?.authToken
    if (authorization) {
      headers.Authorization = authorization
    }

    if (Object.keys(headers).length === 0) {
      return headers
    }

    return { headers }
  }

  protected async get<T>(
    path: string,
    options: AxiosRequestConfig | null = null,
    ctx: Context = null
  ): Promise<AxiosResponse<T>> {
    assert(path, 'APIService.get(): Please provide a path.')

    return this.request<T>(
      {
        method: 'GET',
        url: path,
        ...options,
      },
      ctx
    )
  }

  protected async post<B, T>(
    path: string,
    payload: B,
    options: AxiosRequestConfig = null,
    ctx: Context = null
  ): Promise<AxiosResponse<T>> {
    assert(path, 'APIService.post(): Please provide a path.')

    return this.request(
      {
        method: 'POST',
        url: path,
        data: payload,
        ...options,
      },
      ctx
    )
  }

  protected sendBeacon<P>(path: string, payload: P): void {
    assert(path, 'APIService.sendBeacon(): Please provide a path.')
    assert(payload, 'APIService.sendBeacon(): Please provide a payload.')

    const blob: Blob = new Blob([JSON.stringify(payload)], {
      type: 'application/json;charset=UTF-8',
    }) // the blob is used to add the content type

    // eslint-disable-next-line no-restricted-globals
    navigator?.sendBeacon?.(this.baseURL + path, blob)
  }

  protected async put<P, T>(
    path: string,
    payload: P,
    options: AxiosRequestConfig | null = null,
    ctx: Context = null
  ): Promise<AxiosResponse<T>> {
    assert(path, 'APIService.put(): Please provide a path.')
    assert(payload, 'APIService.put(): Please provide a payload.')

    return this.request(
      {
        method: 'PUT',
        url: path,
        data: payload,
        ...options,
      },
      ctx
    )
  }

  protected async patch<P, T>(
    path: string,
    payload: P,
    options: AxiosRequestConfig = null,
    ctx: Context = null
  ): Promise<AxiosResponse<T>> {
    assert(path, 'APIService.patch(): Please provide a path.')
    assert(payload, 'APIService.patch(): Please provide a payload.')

    return this.request(
      {
        method: 'PATCH',
        url: path,
        data: payload,
        ...options,
      },
      ctx
    )
  }

  protected async delete<T>(
    path: string,
    options: AxiosRequestConfig = null,
    ctx: Context = null
  ): Promise<AxiosResponse<T>> {
    assert(path, 'APIService.delete(): Please provide a path.')

    return this.request(
      {
        method: 'DELETE',
        url: path,
        ...options,
      },
      ctx
    )
  }
}

export default APIService
