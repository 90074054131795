// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

import { FlagItem } from './notepadResponseTypes'

export class NotepadProductFlagPharmaObl extends Model {
  public active!: boolean
  public label!: string

  constructor(src: FlagItem) {
    super(src)
    if (src) {
      this.active = src.active
      this.label = src.label
    }
  }
}
