import intersectionObserver from 'views/utils/intersectionObserver'
import toggleBoolStringAttr from 'views/utils/toggleBoolStringAttribute'

import { ARIA_CONTROLS, ARIA_EXPANDED, ARIA_HIDDEN } from './consts'

const SELECTOR_TOOLTIP_BUTTON = '[data-clientside-hook~="tooltip__button"]'
const SELECTOR_TOOLTIP_IN_VIEW_VISIBLITY = '.m-Tooltip--visible-in-view'

const TOOLTIP_VISIBILITY_DELAY = 1000

const isElHidden = el => {
  return el.getAttribute(ARIA_HIDDEN) === 'true'
}

const getDissmissedTooltipKey = id => 'dismissed:' + id

/**
 *
 * @param {HTMLElement} tooltipText
 */
const setTooltipDismissed = tooltipText => {
  if (tooltipText.dataset.dissmissible) {
    sessionStorage.setItem(getDissmissedTooltipKey(tooltipText.id), '1')
  }
}

const handleTooltipBtn = () => {
  let rootElement = document.querySelector('.root')
  if (!rootElement) {
    rootElement = document.body
  }
  rootElement.addEventListener('click', event => {
    const tooltipButton = event.target.closest(SELECTOR_TOOLTIP_BUTTON)

    if (tooltipButton) {
      const tooltipText = document.querySelector(
        `#${tooltipButton.getAttribute(ARIA_CONTROLS)}`
      )
      const setVisibility = isTooltipHidden => {
        toggleBoolStringAttr(tooltipButton, ARIA_EXPANDED, !isTooltipHidden)
        toggleBoolStringAttr(tooltipText, ARIA_HIDDEN, isTooltipHidden)
      }

      const hideTooltip = () => {
        const isTooltipHidden = true
        setVisibility(isTooltipHidden)
        rootElement.removeEventListener('click', hideTooltip)
      }

      let isHidden = isElHidden(tooltipText)

      // If tooltip is visible, hide it
      if (!isHidden) {
        hideTooltip()
        setTooltipDismissed(tooltipText)
      } else {
        // if tooltip is hidden, show it and add event listener to hide when user clicks outside
        setVisibility(!isHidden)
        rootElement.addEventListener('click', hideTooltip)
      }
    }
    return tooltipButton
  })
  return rootElement
}

/** Show tooltip after a second delay when it is in view */
const handleTooltipVisibilityInView = () => {
  const tooltips = document.querySelectorAll(SELECTOR_TOOLTIP_IN_VIEW_VISIBLITY)
  return Array.from(tooltips, tooltip => {
    let isHidden = isElHidden(tooltip)

    if (!isHidden) {
      isHidden = true
      toggleBoolStringAttr(tooltip, ARIA_HIDDEN, isHidden)
    }

    intersectionObserver({
      callback: () => {
        const isDismissed = sessionStorage.getItem(
          getDissmissedTooltipKey(tooltip.id)
        )
        if (isHidden && !isDismissed) {
          setTimeout(() => {
            isHidden = false
            toggleBoolStringAttr(tooltip, ARIA_HIDDEN, isHidden)
          }, TOOLTIP_VISIBILITY_DELAY)
        }
      },
      targetElements: tooltip,
      options: {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      },
    })
    return tooltip
  })
}

export default () => {
  return [handleTooltipBtn(), ...handleTooltipVisibilityInView()]
}
