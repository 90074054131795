import {
  type ExperimentName,
  type TenantBasedExperiment,
} from 'shared/experiments/consts'
import {
  getExperiment,
  useGetExperiment,
} from 'shared/experiments/utils/experiments'
import store from 'shared/store'
import { isMobile } from 'shared/utils/deviceClassUtils'
import { type Experiment, type Tenant } from 'types/shopConfig'
import { usePublicConfig } from 'views/utils/shopAndRuntimeConfigUtils'

export const isObjKey = <T extends object>(
  key: PropertyKey,
  obj: T
): key is keyof T => key in obj

const getExperimentNameString = (
  experimentName: TenantBasedExperiment,
  tenant: Tenant,
  deviceClass: string
) => {
  const experimentTenantName = experimentName[tenant]
  if (!experimentTenantName) {
    return ''
  }

  if (typeof experimentTenantName === 'string') {
    return isMobile(deviceClass)
      ? `${experimentTenantName}-m`
      : experimentTenantName
  }

  return (
    experimentTenantName[isMobile(deviceClass) ? 'mobile' : 'desktop'] || ''
  )
}

// Only for server side scripts
export const useGetExperimentNamePerTenant = (
  experimentName: TenantBasedExperiment
): ExperimentName => {
  const { tenant, deviceClass } = usePublicConfig()

  return getExperimentNameString(experimentName, tenant, deviceClass)
}

export const getExperimentNamePerTenant = (
  experimentName: TenantBasedExperiment,
  tenant: Tenant,
  deviceClass: string
): ExperimentName => {
  return getExperimentNameString(experimentName, tenant, deviceClass)
}

export const useGetExperimentPerTenant = (
  experimentName: TenantBasedExperiment
): Experiment => {
  const experimentNameString = useGetExperimentNamePerTenant(experimentName)

  return useGetExperiment(experimentNameString)
}

export const getExperimentPerTenantOnClient = (
  experimentName: TenantBasedExperiment
): Experiment => {
  const publicRuntimeConfig = store.getPublicRuntimeConfig()
  const { tenant, deviceClass } = publicRuntimeConfig?.publicConfig || {}
  const { experiments = [] } = publicRuntimeConfig?.pageProperties || {}

  const experimentNameString = getExperimentNamePerTenant(
    experimentName,
    tenant,
    deviceClass
  )

  return getExperiment(experimentNameString, experiments)
}
