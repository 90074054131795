// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'
import MountPoints from 'shared/utils/mountPoints'

import { OrderResponse } from './orderResponse'
import { OrderResponseV1, OrdersResponseV1 } from './ordersResponseTypes'

export class OrdersResponse extends Model {
  public readonly itemsPerPage: number
  public readonly orders: OrderResponse[]
  public readonly page: number
  public readonly totalItems: number
  public readonly totalPages: number

  constructor(src: OrdersResponseV1) {
    super(src)
    this.itemsPerPage = src.itemsPerPage
    this.orders = Array.isArray(src.orders)
      ? src.orders.map((order: OrderResponseV1) => new OrderResponse(order))
      : []
    this.page = src.page
    this.totalItems = src.totalItems
    this.totalPages = src.totalPages
  }

  public buildOrdersDocumentsURLs(mountPoints: MountPoints) {
    this.orders.forEach(o => o.buildOrderDocumentsURLs(mountPoints))
  }
}
