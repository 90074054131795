import {
  EXPERIMENT_VARIATION,
  RETAIL_MEDIA_AD_SERVER_GLOO_ENVIRONMENT,
  RETAIL_MEDIA_ALGOLIA_WRAPPER,
  RETAIL_MEDIA_CROSS_SELL_SERVICE,
  RETAIL_MEDIA_SEARCH_BOOSTER,
} from 'shared/experiments/consts'
import { getExperiment } from 'shared/experiments/utils/experiments'
import { isAdServerCrossSellExperimentEnabled } from 'shared/utils/adServerUtils'
import {
  type CrossSellWidgetType,
  getIsBuyTogetherWidget,
} from 'shared/utils/crosssell'
import { type Experiment } from 'types/shopConfig'

export const isRetailMediaCrossSellServiceEnabled = ({
  adServerCrossSellWidgetIds,
  experiments,
  widgets,
  wid,
}: {
  adServerCrossSellWidgetIds: number[]
  wid: string
  experiments: Experiment[]
  widgets: CrossSellWidgetType
}): boolean => {
  const experiment = getExperiment(RETAIL_MEDIA_CROSS_SELL_SERVICE, experiments)
  const isBuyTogetherWidget = getIsBuyTogetherWidget(Number(wid), widgets)
  return (
    !isBuyTogetherWidget &&
    Boolean(
      experiment.isEnabled && experiment.variant === EXPERIMENT_VARIATION.V1
    ) &&
    // Fetch the widget from cross-sell proxy service only for specific wid
    adServerCrossSellWidgetIds.includes(Number(wid))
  )
}

export const isRetailMediaAdServerEnabled = ({
  widgets,
  experiments,
  wid,
  adServerCrossSellWidgetIds,
}: {
  widgets: CrossSellWidgetType
  experiments: Experiment[]
  wid: string
  adServerCrossSellWidgetIds: number[]
}): boolean => {
  const isBuyTogetherWidget = getIsBuyTogetherWidget(Number(wid), widgets)

  // Field defined on BuyTogether x-sell to determine the type of x-sell.
  // We don't want any Ad-server changes happening in BuyTogethers atm.
  return (
    !isBuyTogetherWidget &&
    isAdServerCrossSellExperimentEnabled(experiments) &&
    // Fetch the widget from ad-server api only for specific wid
    adServerCrossSellWidgetIds.includes(Number(wid))
  )
}

export const isRetailMediaSearchBoosterEnabled = (
  experiments: Experiment[] = []
) => {
  const experiment = getExperiment(RETAIL_MEDIA_SEARCH_BOOSTER, experiments)
  return Boolean(
    experiment.isEnabled && experiment.variant === EXPERIMENT_VARIATION.V1
  )
}

export const isRetailMediaAlgoliaWrapperEnabled = (
  experiments: Experiment[] = []
) => {
  const experiment = getExperiment(RETAIL_MEDIA_ALGOLIA_WRAPPER, experiments)

  return Boolean(
    experiment.isEnabled && experiment.variant === EXPERIMENT_VARIATION.V1
  )
}

export const isRetailMediaAdServerGlooEnvEnabled = (
  experiments: Experiment[] = []
) => {
  const experiment = getExperiment(
    RETAIL_MEDIA_AD_SERVER_GLOO_ENVIRONMENT,
    experiments
  )

  return Boolean(
    experiment.isEnabled && experiment.variant === EXPERIMENT_VARIATION.V1
  )
}
