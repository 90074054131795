import { createLogger } from 'browser-bunyan'

import { IS_NODE } from 'shared/consts/env'

import config from './config'

/**
 * Logger class
 */
export class Logger {
  constructor() {
    this.logger = createLogger(config())
    this.errorCallbacks = []
  }

  /**
   * log an error
   */
  error(message, errorData) {
    const { error = null, ctx = null } = errorData || {}

    this.logger.error({
      message,
      error,
      ctx,
    })

    const err = error || new Error(message)
    this.errorCallbacks.forEach(cb => cb(err, ctx))

    // send client error to kibana
    if (!IS_NODE) {
      // eslint-disable-next-line no-restricted-globals
      window?.globalThis?.elasticApm?.captureError(err)
    }
  }

  addErrorCallback(cb) {
    this.errorCallbacks.push(cb)
  }

  /**
   * log a warning
   */
  warn(message, { error = null, ctx = null } = {}) {
    this.logger.warn({
      message,
      error,
      ctx,
    })
  }

  /**
   * log an info message
   */
  info(message, { error = null, ctx = null } = {}) {
    this.logger.info({
      message,
      error,
      ctx,
    })
  }

  /**
   * log a debug message
   */
  debug(message, namespace) {
    this.logger.debug({
      message,
      namespace: namespace || null,
    })
  }

  /**
   * close instance and stream
   */
  close() {
    this.logger.close()
  }
}

export default new Logger()
