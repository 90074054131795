// @ts-nocheck
import { AxiosResponse } from 'axios'

import getErrorMessage from 'shared/utils/getErrorMessage'

interface FetchOrFallbackArguments {
  fetchCall: Promise<AxiosResponse>
  fallback: unknown
  ctx: {
    logger: {
      error: (message: string, data: { error: Error | string }) => void
    }
  }
}

const fetchOrFallback = async ({
  fetchCall,
  fallback,
  ctx,
}: FetchOrFallbackArguments) => {
  try {
    const { data } = await fetchCall

    return data
  } catch (error) {
    ctx.logger.error(getErrorMessage(error), { error })

    return fallback
  }
}

export default fetchOrFallback
