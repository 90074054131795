// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

import { OrderDocumentPricePerUnitV1 } from './ordersResponseTypes'

export class OrderDocumentPricePerUnit extends Model {
  public readonly formatted: string

  constructor(src: OrderDocumentPricePerUnitV1) {
    super(src)
    this.formatted = src.formatted
  }
}
