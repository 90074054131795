import { PAGE_TYPE_CART } from 'shared/consts'
import {
  FLYOUT_BANNER_CLICK,
  FLYOUT_BANNER_CLOSE_BUTTON_CLICK,
  OWN_BRAND_PRODUCT_ADDTOCART,
} from 'shared/experiments/browser/advertisingFlyoutBanner/const'
import {
  AD_FLYOUT_BANNER,
  EXPERIMENT_VARIATION,
} from 'shared/experiments/consts'
import { getExperimentOnClient } from 'shared/experiments/utils/experiments'
import trackOptimizelyEvent from 'shared/experiments/utils/trackOptimizelyEvent'
import store from 'shared/store'

export default () => {
  const { isEnabled, variant } = getExperimentOnClient(AD_FLYOUT_BANNER)
  const publicRuntimeConfig = store.getState()?.publicRuntimeConfig
  const { pageType } = publicRuntimeConfig?.pageProperties || {}

  if (
    !isEnabled ||
    variant === EXPERIMENT_VARIATION.DEFAULT ||
    pageType === PAGE_TYPE_CART
  ) {
    return
  }

  const bannerButton = <HTMLElement>(
    document.querySelector('[name="ad-flyout-banner-button"]')
  )
  const banner = <HTMLElement>(
    document.querySelector('.o-AdvertisingFlyoutBanner__bannerContainer')
  )

  if (isEnabled && bannerButton && banner) {
    if (sessionStorage.getItem(AD_FLYOUT_BANNER) !== 'true') {
      setTimeout(() => {
        bannerButton.classList.remove('u-hidden')

        setTimeout(() => {
          // Remove hidden class from the banner element after another 15 seconds
          banner.classList.remove('u-hidden')
          bannerButton.classList.add('u-hidden')

          setTimeout(() => {
            // Hide the banner element after another 15 seconds
            banner.classList.add('u-hidden')
            bannerButton.classList.remove('u-hidden')
          }, 15000)
        }, 15000)
        sessionStorage.setItem(AD_FLYOUT_BANNER, 'true')
      }, 15000)
    } else {
      bannerButton.classList.remove('u-hidden')
    }

    bannerButton.addEventListener('click', () => {
      banner.classList.remove('u-hidden')
      bannerButton.classList.add('u-hidden')
    })
    banner.addEventListener('click', event => {
      const element = <HTMLElement>event.target

      if (element.closest('.o-AdvertisingFlyoutBanner__closeIcon')) {
        banner.classList.add('u-hidden')
        bannerButton.classList.remove('u-hidden')

        trackOptimizelyEvent(FLYOUT_BANNER_CLOSE_BUTTON_CLICK)
      } else if (element.closest('.o-AdvertisingFlyoutBanner__bannerImage')) {
        trackOptimizelyEvent(FLYOUT_BANNER_CLICK)
      }
    })

    if (
      window.location.pathname + window.location.search ===
      '/search.htm?b=1&q=redcare'
    ) {
      const ownBrandsAddToCartButton = <HTMLElement>(
        document.querySelector('.o-SearchProductListItem__ProductAction')
      )

      ownBrandsAddToCartButton.addEventListener('click', () => {
        trackOptimizelyEvent(OWN_BRAND_PRODUCT_ADDTOCART)
      })
    }
  }
}
