import {
  EXPERIMENT_VARIATION,
  ONE_TIME_PASSWORD_FAKE_DOOR_TEST,
  ONE_TIME_PASSWORD_INTERESTED_EVENT,
} from 'shared/experiments/consts'
import { getExperimentOnClient } from 'shared/experiments/utils/experiments'
import trackEvent from 'shared/experiments/utils/trackOptimizelyEvent'

const hideOneTimePasswordLink = () => {
  const oneTimePasswordPasswordFakeDoorLink = document.getElementById(
    'oneTimePasswordPasswordFakeDoorLink'
  )
  oneTimePasswordPasswordFakeDoorLink?.classList?.add(
    'a-loginFormOneTimePasswordDisplayItemNone'
  )
}
const displayOneTimePasswordFakeDoorDescription = () => {
  const oneTimePasswordPasswordFakeDoorDescription = document.getElementById(
    'oneTimePasswordPasswordFakeDoorDescription'
  )
  oneTimePasswordPasswordFakeDoorDescription?.classList?.remove(
    'a-loginFormOneTimePasswordDisplayItemNone'
  )
  oneTimePasswordPasswordFakeDoorDescription?.classList?.add(
    'a-loginFormOneTimePasswordLinkDescription'
  )
}
const oneTimePasswordInterestTracking = () => {
  document
    .getElementById('oneTimePasswordPasswordFakeDoorLinkButton')
    ?.addEventListener('click', event => {
      event.preventDefault()
      hideOneTimePasswordLink()
      displayOneTimePasswordFakeDoorDescription()
      trackEvent(ONE_TIME_PASSWORD_INTERESTED_EVENT)
    })
}
export default () => {
  const { isEnabled, variant } = getExperimentOnClient(
    ONE_TIME_PASSWORD_FAKE_DOOR_TEST
  )
  if (isEnabled && variant === EXPERIMENT_VARIATION.V1) {
    oneTimePasswordInterestTracking()
  }
}
