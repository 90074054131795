// @ts-nocheck
import { Context } from 'koa'
import { stringify } from 'qs'

import {
  OrderNowMerchantDetailsResponse,
  OrderNowMerchantDetailsV1,
  OrderResponse,
  OrderResponseV1,
  OrdersResponse,
  OrdersResponseV1,
} from 'shared/services/api/models/orders'
import { PREFETCH_ORDERS_AMOUNT } from 'views/consts/orders'

import BackendAPIService from './BackendAPIService'
import { ServiceParameters } from './commonTypes/ServiceParameters'

export interface FetchOrdersListParams {
  page: number
  itemsPerPage: number
}

const defaultOrdersListParams: FetchOrdersListParams = {
  page: 0,
  itemsPerPage: PREFETCH_ORDERS_AMOUNT,
}

interface IOrdersService {
  getOrdersListURL: (params: FetchOrdersListParams) => string
  getOrderDetailsURL: (orderId: string) => string
  getNowMerchantDetailsURL: (sellerId: string) => string
  getOrdersController: (params: Record<string, unknown>) => string
  fetchOrdersList: (
    ctx: Context,
    params: FetchOrdersListParams
  ) => Promise<OrdersResponse>
  fetchOrder: (ctx: Context, orderId: string) => Promise<OrderResponse>
  // fetchAllCustomerOrders is used by checkout
  fetchAllCustomerOrders: (
    page: number,
    itemsPerPage: number
  ) => Promise<object>
  fetchNowMerchantDetails: (
    sellerId: string
  ) => Promise<OrderNowMerchantDetailsResponse>
  cancelOrder: (suborderId: string) => Promise<null>
  cancelOrderWithOrderNumber: (
    orderId: string,
    suborderId: string
  ) => Promise<null>
}

class OrdersService extends BackendAPIService implements IOrdersService {
  constructor(params: ServiceParameters) {
    super({ apiVersion: 'v1', ...params })
  }

  public getOrdersListURL(
    params: FetchOrdersListParams = defaultOrdersListParams
  ): string {
    return `/account/v2/${this.tenantPath}/${this.language}/orders?${stringify(
      params
    )}`
  }

  public getOrderDetailsURL(orderId: string): string {
    return `/account/v2/${this.tenantPath}/${this.language}/orders/${orderId}`
  }

  public getOrdersController(params: FetchOrdersListParams): string {
    return `/order/${this.apiVersion}/${this.tenantPath}/orders/?${stringify(
      params
    )}`
  }

  public getNowMerchantDetailsURL(sellerId: string): string {
    return `/now/v3/${this.tenantPath}/nowMerchant/${sellerId}`
  }
  public getNowV4MerchantDetailsURL(sellerId: string): string {
    return `/now/v4/${this.tenantPath}/${this.language}/nowMerchant/${sellerId}`
  }

  public async fetchOrdersList(
    ctx: Context,
    params: FetchOrdersListParams = defaultOrdersListParams
  ): Promise<OrdersResponse> {
    this.logger.debug(
      `Orders service is fetching orders list data!`,
      'dataFetching'
    )
    const { data }: { data: OrdersResponseV1 } = await this.get(
      this.getOrdersListURL(params),
      null,
      ctx
    )
    this.logger.debug(
      `Orders service fetched orders list data!`,
      'dataFetching'
    )
    return new OrdersResponse(data)
  }

  public async fetchOrder(
    ctx: Context,
    orderId: string
  ): Promise<OrderResponse> {
    this.logger.debug(
      `Orders service is fetching single order data!`,
      'dataFetching'
    )

    const { data }: { data: OrderResponseV1 } = await this.get(
      this.getOrderDetailsURL(orderId),
      {
        // pass optimizely cookie to backend
        headers: {
          Cookie: `feature-flag-cookie=${ctx.cookies.get(
            'feature-flag-cookie'
          )}`,
        },
      },
      ctx
    )

    return new OrderResponse(data)
  }

  public fetchAllCustomerOrders(page = 0, itemsPerPage = 1): Promise<object> {
    return this.get(
      this.getOrdersController({
        page,
        itemsPerPage,
      })
    )
  }

  public async fetchNowMerchantDetails(
    sellerId: string
  ): Promise<OrderNowMerchantDetailsResponse> {
    this.logger.debug(
      `Orders service is fetching NOW! seller details`,
      'dataFetching'
    )
    const {
      data: { merchant },
    }: { data: { merchant: OrderNowMerchantDetailsV1 } } = await this.get(
      this.getNowMerchantDetailsURL(sellerId)
    )
    return new OrderNowMerchantDetailsResponse(merchant)
  }

  public async fetchNowV4MerchantDetails(
    sellerId: string
  ): Promise<OrderNowMerchantDetailsResponse> {
    this.logger.debug(
      `Orders service is fetching NOW! seller details`,
      'dataFetching'
    )
    const {
      data: { merchant },
    }: { data: { merchant: OrderNowMerchantDetailsV1 } } = await this.get(
      this.getNowV4MerchantDetailsURL(sellerId)
    )
    return new OrderNowMerchantDetailsResponse(merchant)
  }

  public async cancelOrder(suborderId) {
    await this.post(
      `/order/${this.apiVersion}/${this.tenantPath}/subOrders/${suborderId}/cancellation`,
      {}
    )
  }

  public async cancelOrderWithOrderNumber(orderId, suborderId) {
    await this.post(
      `/order/${this.apiVersion}/${this.tenantPath}/orders/${orderId}/subOrders/${suborderId}/cancellation`,
      {}
    )
  }
}

export default OrdersService
