// @ts-nocheck
// Using recursion, so can’t keep nice order
/* eslint-disable @typescript-eslint/no-use-before-define */

interface JSONSchemaValidatorLightOptions {
  schema: Schema
  banUnknownProperties?: boolean
}

type ValidationResult = { valid: boolean; error?: string }

type Path = Array<string | number>

type TypeScalar = 'string' | 'number' | 'boolean'

type SchemaScalar = {
  type: TypeScalar
}

type SchemaArray = {
  type: 'array'
  items: Schema
}

type SchemaObject = {
  type: 'object'
  required?: Array<string>
  properties?: {
    [name: string]: Schema
  }
  patternProperties?: {
    [regExp: string]: Schema
  }
}

type SchemaWithType = SchemaScalar | SchemaArray | SchemaObject

type SchemaAnyOf = {
  anyOf: Array<Schema>
}

export type Schema = TypeScalar | SchemaWithType | SchemaAnyOf

const validateAnyOf = (
  banUnknownProperties: boolean,
  path: Path,
  schema: SchemaAnyOf,
  node: unknown
): ValidationResult => {
  const results = schema.anyOf.map(subSchema =>
    validateNode(banUnknownProperties, path, subSchema, node)
  )

  const invalidResults = results.filter(r => !r.valid)
  if (invalidResults.length === results.length) {
    const pathLabel = getPathLabel(path)
    const messages = invalidResults.map(r => r.error).join('; ')
    return {
      valid: false,
      error: `Value under '${pathLabel}' failed all validations: ${messages}`,
    }
  }

  return { valid: true }
}

const getPathLabel = (path: Path): string => {
  if (path.length === 0) {
    return '(root)'
  }
  return path.map(n => (typeof n === 'number' ? `[${n}]` : `.${n}`)).join('')
}

const validateScalar = (
  path: Path,
  type: string,
  value: unknown
): ValidationResult => {
  let valueType: string = typeof value
  let valid: boolean = valueType === type

  if (valid && valueType === 'number' && isNaN(value)) {
    valid = false
    valueType = 'Not–a–Number'
  }

  if (!valid && valueType === 'string') {
    switch (type) {
      case 'number': {
        valid = value.trim() !== '' && !isNaN(+value)
        break
      }
      case 'boolean':
        valid = ['true', 'false'].includes((value as string).toLowerCase())
        break
    }
    if (!valid) {
      const pathLabel = getPathLabel(path)
      return {
        valid: false,
        error: `Failed to cast string to ${type} under ${pathLabel}: ${value}`,
      }
    }
  }

  if (valid) {
    return { valid: true }
  } else {
    const pathLabel = getPathLabel(path)
    return {
      valid: false,
      error: `Expected ${type} under ${pathLabel}, but got ${valueType}`,
    }
  }
}

const validateArray = (
  banUnknownProperties: boolean,
  path: Path,
  schema: SchemaArray,
  node: unknown
): ValidationResult => {
  if (!Array.isArray(node)) {
    const pathLabel = getPathLabel(path)
    return { valid: false, error: `Got non–array under ${pathLabel}` }
  }

  const results = (node as Array<unknown>).map((value, idx) =>
    validateNode(banUnknownProperties, path.concat(idx), schema.items, value)
  )

  return results.find(r => !r.valid) || { valid: true }
}

const validateObjectRequired = (
  path: Path,
  names: SchemaObject['required'],
  obj: object
): ValidationResult => {
  if (!names) {
    return { valid: true }
  }

  const missingNames = names.filter(name => !(name in obj))

  if (missingNames.length) {
    const pathLabel = getPathLabel(path)
    const missingNamesJoined = missingNames.join(', ')
    return {
      valid: false,
      error: `Missing required properties under ${pathLabel}: ${missingNamesJoined}`,
    }
  }

  return { valid: true }
}

const validateObjectProperties = (
  banUnknownProperties: boolean,
  path: Path,
  schema: SchemaObject,
  obj: object
): ValidationResult => {
  const results = Object.entries(obj).map(([name, value]) => {
    if (name in (schema?.properties || [])) {
      return validateNode(
        banUnknownProperties,
        [...path, name],
        schema.properties![name],
        value
      )
    } else if (schema.patternProperties) {
      const matchingResults = Object.entries(schema.patternProperties)
        .filter(([regExpString]) => new RegExp(regExpString).test(name))
        .map(([, subSchema]) =>
          validateNode(banUnknownProperties, [...path, name], subSchema, value)
        )
      return matchingResults.find(r => !r.valid) || { valid: true }
    } else if (banUnknownProperties) {
      const pathLabel = getPathLabel(path)
      return {
        valid: false,
        error: `Unexpected extraneous property '${name}' under ${pathLabel}`,
      }
    } else {
      return { valid: true }
    }
  })

  return results.find(r => !r.valid) || { valid: true }
}

const validateObject = (
  banUnknownProperties: boolean,
  path: Path,
  schema: SchemaObject,
  node: unknown
): ValidationResult => {
  let result: ValidationResult

  if (Object.prototype.toString.apply(node) !== '[object Object]') {
    const pathLabel = getPathLabel(path)
    result = { valid: false, error: `Got non–object under ${pathLabel}` }
  } else {
    const obj: object = node

    result = validateObjectRequired(path, schema.required, obj)
    if (result.valid) {
      result = validateObjectProperties(banUnknownProperties, path, schema, obj)
    }
  }

  return result
}

const validateNode = (
  banUnknownProperties: boolean,
  path: Path,
  schema: Schema | string,
  node: unknown
): ValidationResult => {
  let result: ValidationResult

  if (typeof schema === 'string') {
    result = validateNode(
      banUnknownProperties,
      path,
      { type: schema } as Schema,
      node
    )
  } else if ((schema as SchemaAnyOf).anyOf) {
    result = validateAnyOf(
      banUnknownProperties,
      path,
      schema as SchemaAnyOf,
      node
    )
  } else {
    const schemaWithType = schema as SchemaWithType

    switch (schemaWithType.type) {
      case 'string':
      case 'number':
      case 'boolean':
        result = validateScalar(path, schemaWithType.type, node)
        break
      case 'array':
        result = validateArray(
          banUnknownProperties,
          path,
          schemaWithType as SchemaArray,
          node
        )
        break
      case 'object':
        result = validateObject(
          banUnknownProperties,
          path,
          schemaWithType as SchemaObject,
          node
        )
        break
      default: {
        // @ts-ignore
        const type = schemaWithType.type
        throw new Error(`Unsupported schema node type: ${type}`)
      }
    }
  }

  return result
}

export default (
  options: JSONSchemaValidatorLightOptions,
  input: unknown
): ValidationResult => {
  return validateNode(
    options.banUnknownProperties || false,
    [],
    options.schema,
    input
  )
}
