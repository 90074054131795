import { Model } from 'shared/services/api/models/Model'

// @ts-ignore
import { type NotepadProductOfferAdditionalInfoDiscountV1 } from './notepadResponseTypes'

export class NotepadProductOfferPriceAdditionalInfoDiscount extends Model {
  public percentage: number

  constructor(src: NotepadProductOfferAdditionalInfoDiscountV1) {
    super(src)
    this.percentage = src.percentage
  }
}
