import store from 'shared/store'
import loadCatalogs from 'views/containers/browser/utils/loadCatalogs'

export default async showOffcanvas => {
  const containerSelector = '#h_OffCanvas'
  const triggerSelectorClick = '[data-clientside-hook~="h-OffCanvas-trigger"]'
  const triggerElementClick = document.querySelector(triggerSelectorClick)

  // TODO: for now we just instanciate either the mobile search or the desktop search
  // maye we can change this to directly instanciate both?
  const loadOffCanvas = async () => {
    const container = document.querySelector(containerSelector)
    const {
      publicConfig: { locale },
    } = store.getPublicRuntimeConfig()

    const i18n = await loadCatalogs(locale)
    const hydrateOffCanvasContainer = await import(
      /* webpackChunkName: "OffCanvasContainerWithHydration", webpackPrefetch: true */
      './OffCanvasContainerWithHydration'
    )
    hydrateOffCanvasContainer.default({
      container,
      i18n,
    })

    triggerElementClick.removeEventListener('click', loadOffCanvas)
  }

  if (triggerElementClick) {
    triggerElementClick.addEventListener('click', loadOffCanvas)
  }

  if (document.querySelector(containerSelector)?.matches(':target')) {
    document
      .querySelector('[data-clientside-hook~="menu"]')
      .setAttribute('aria-hidden', 'true')
    showOffcanvas()
    loadOffCanvas()
  }

  if (window.location.hash === containerSelector) {
    window.location.hash = ''
  }
}
