// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

export interface NotepadEntryV2 {
  customerId: string
  tenant: string
  upid: string
  updated: string
  created: string
}

export class NotepadEntry extends Model {
  public customerId: string
  public tenant: string
  public upid: string
  public updated: string
  public created: string
  constructor(src: NotepadEntry) {
    super(src)
    this.customerId = src.customerId
    this.tenant = src.tenant
    this.upid = src.upid
    this.created = src.created
    this.updated = src.updated
  }
}
