class ConsoleRawStream {
  constructor(con = console) {
    this.console = con
  }

  // noinspection JSMethodCanBeStatic
  write(level, msg) {
    switch (level.toUpperCase()) {
      case 'DEBUG':
        this.console.debug(msg)
        break
      case 'INFO':
        this.console.info(msg)
        break
      case 'WARN':
        this.console.warn(msg)
        break
      case 'ERROR':
        this.console.error(msg)
        break
      default:
        this.console.log(msg)
        break
    }
  }
}

export default ConsoleRawStream
