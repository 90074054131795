import { specialLetter } from 'views/consts/specialLetters'
import { latinLettersRegex } from 'views/consts/validation'

export const upperCaseFirst = string => {
  if (string.trim() === '') {
    return ''
  }

  const [first, ...rest] = string
  return `${first.toUpperCase()}${rest.join('')}`
}

export const stringToInt = value =>
  value && !Number.isNaN(Number(value)) ? parseInt(value, 10) : null

/**
 * Remove all empty lines from beginning of text and changes multi empty lines for two break lines
 * @param source - source text with multi new lines to be processed
 * @returns output with stripped multi new lines
 */
export const removeEmptyLines = source => {
  const newLinesRegex = /(\r\n|\r|\n)/g
  const newLinesAfterFirstDivRegex = /^(<div>+(<br( \/)?>\s{0,})*)/g
  const newLinesOnBeginRegex = /^(<br( \/)?>\s{0,})*/g
  const multiNewLinesRegex = /(<br( \/)?>\s*){3,}/gi

  return source
    ?.replace(newLinesRegex, '<br>') // replace new lines to br tags
    .replace(newLinesAfterFirstDivRegex, '<div>') // remove all unnecessary new lines after first div element
    .replace(newLinesOnBeginRegex, '') // remove unnecessary all new lines on begin source
    .replace(multiNewLinesRegex, '<br><br>') // remove all new lines above two side by side
}

/**
 * Makes all urls starting with http or https in text clickable
 * @param source - text to linkify
 * @returns text with clickable links
 */
export const linkify = source =>
  source.replace(
    /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim,
    '<a href="$1" target="_blank">$1</a>'
  )

export const removeNonLatinLetters = source =>
  source.replace(latinLettersRegex, specialLetter)
