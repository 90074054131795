// @ts-nocheck
import {
  AllowedOrdersForRefundResponseV1,
  Model,
} from 'shared/services/api/models'

export class OrdersForRefundItemResponse extends Model {
  public commercialOrderId: string
  public orderId: string
  public returnAvailable: boolean

  constructor(src: AllowedOrdersForRefundResponseV1) {
    super(src)
    this.commercialOrderId = src.commercialOrderId
    this.orderId = src.orderId
    this.returnAvailable = src.returnAvailable
  }
}
