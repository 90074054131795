import { subscribe } from 'shared/store'
import { CHANGE_SLIDER_INDEX } from 'shared/store/ducks/events'
import { ARIA_HIDDEN } from 'views/assets/scripts/consts'
import toggleBoolStringAttr from 'views/utils/toggleBoolStringAttribute'

import { VIDEO_SELECTOR, VIDEO_THUMBNAIL_SELECTOR } from './MediaGallery/consts'

export default () => {
  const videoElements = [
    ...document.querySelectorAll(
      '[data-clientside-hook~="videoWithThumbnail"]'
    ),
  ]
  let activeVideoElement = null

  const pause = videoElement => {
    const video = videoElement.querySelector(VIDEO_SELECTOR)
    const thumbnail = videoElement.querySelector(VIDEO_THUMBNAIL_SELECTOR)

    toggleBoolStringAttr(thumbnail, ARIA_HIDDEN, false)
    video.pause()
    video.load()

    activeVideoElement = null
  }

  const play = videoElement => {
    if (activeVideoElement) {
      pause(activeVideoElement)
    }

    const video = videoElement.querySelector(VIDEO_SELECTOR)
    const thumbnail = videoElement.querySelector(VIDEO_THUMBNAIL_SELECTOR)

    toggleBoolStringAttr(thumbnail, ARIA_HIDDEN, true)
    video.play()

    activeVideoElement = videoElement
  }

  subscribe.after(CHANGE_SLIDER_INDEX, payload => {
    const { containerId } = payload
    const sliderElement = document.querySelector(
      `[data-slider-id~="${containerId}"]`
    )
    if (
      sliderElement &&
      activeVideoElement &&
      sliderElement.contains(activeVideoElement)
    ) {
      pause(activeVideoElement)
    }
  })

  return videoElements.map(videoElement => {
    const thumbnail = videoElement.querySelector(VIDEO_THUMBNAIL_SELECTOR)

    thumbnail.addEventListener('click', () => play(videoElement))

    return videoElement
  })
}
