/**
 * @link https://github.com/RedTecLab/varnish-plus/blob/master/varnish-devicedetect/devicedetect.vcl
 */

export const DEVICE_CLASS_DESKTOP = 'pc'
export const DEVICE_CLASS_TABLET = 'tablet'
export const DEVICE_CLASS_MOBILE = 'mobile'
export const DEVICE_CLASS_BOT = 'bot'
export const DEVICE_CLASS_MOBILE_BOT = 'mobile-bot'

export const DEVICE_CLASS = [
  DEVICE_CLASS_BOT,
  DEVICE_CLASS_MOBILE,
  DEVICE_CLASS_TABLET,
  DEVICE_CLASS_DESKTOP,
  DEVICE_CLASS_MOBILE_BOT,
]
