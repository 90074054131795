const normalizePhoneValue = phone => {
  return phone
    ?.trim()
    .replace(/^\+/, '00')
    .replace(/[^\d]+/g, '')
}

const normalizeFormField = element => {
  if (element.type === 'tel') {
    return normalizePhoneValue(element.value)
  }

  return element.value
}

export default normalizeFormField
