export const AVERAGE_RATING_DEFAULT_VALUES = {
  min: 0,
  max: 5,
}

export const PRICE_DEFAULT_VALUES = {
  min: 0,
  max: 1e10,
}

export const FACETS_LIMIT = 10000

export const SHIPPING_OPTIONS_FILTER_NAME = 'shippingOptions'

// SERP filters on top
export const FLYOUT_ID = 'FlyoutFilterOverlay'

export const FILTER_ID = 'product-filter'

export const SORT_BY_ID = 'product-sort-by'
