import { type SellerV1 } from '@redteclab/api/clients/bully'

import assert from 'shared/utils/assert'

import BackendAPIService from './BackendAPIService'

class SellerService extends BackendAPIService {
  getSellerDataPathCached(id: string) {
    return `/cacheable/seller/v1/${this.tenantPath}/${id}`
  }
  getSellerDataPath(id: string) {
    return `/seller/v1/${this.tenantPath}/${id}`
  }

  getSellerEvaluationsPath(id: string) {
    return `${this.getSellerDataPathCached(id)}/evaluations`
  }

  getSellerCanEvaluateOrderPath(
    id: string,
    orderId: string,
    subOrderId: string
  ) {
    return `${this.getSellerDataPath(
      id
    )}/canEvaluateOrder/${orderId}/${subOrderId}`
  }

  getSellerEvaluateOrderPath(id: string, orderId: string, subOrderId: string) {
    return `${this.getSellerDataPathCached(
      id
    )}/evaluation/${orderId}/${subOrderId}`
  }

  async fetchSellerData(id: string) {
    assert(id, 'sellerService.fetchSellerData: id missing')
    this.logger.debug(`Fetching seller ${id}`, 'dataFetching')
    const { data } = await this.get<SellerV1>(this.getSellerDataPathCached(id))
    this.logger.debug(`Seller ${id} fetched!`, 'dataFetching')
    return data
  }

  async fetchEvaluations(id: string) {
    assert(id, 'sellerService.fetchSellerData: id missing')
    this.logger.debug(`Fetching seller evaluations ${id}`, 'dataFetching')
    const { data } = await this.get(this.getSellerEvaluationsPath(id))
    this.logger.debug(`Seller evaluations ${id} fetched!`, 'dataFetching')
    return data
  }

  async canEvaluate(id: string, orderId: string, subOrderId: string) {
    assert(id, 'sellerService.canEvaluate: sellerId missing')
    assert(subOrderId, 'sellerService.canEvaluate: subOrderId missing')
    this.logger.debug(
      `Fetching authorization for evaluating order ${subOrderId} from seller ${id}`,
      'dataFetching'
    )
    const { data } = await this.get(
      this.getSellerCanEvaluateOrderPath(id, orderId, subOrderId)
    )
    this.logger.debug(
      `Fetched authorization for evaluating order ${subOrderId} from seller ${id}`,
      'dataFetching'
    )
    return data
  }

  async createEvaluation(
    sellerId: string,
    orderId: string,
    subOrderId: string,
    payload: { grade: number; comment: string }
  ) {
    assert(sellerId, 'sellerService.createEvaluation: sellerId missing')
    assert(subOrderId, 'sellerService.createEvaluation: subOrderId missing')
    assert(payload, 'sellerService.createEvaluation: payload missing')
    this.logger.debug(
      `Creating evaluating for order ${subOrderId} from seller ${sellerId}`,
      'dataCreation'
    )
    const response = await this.post(
      this.getSellerEvaluateOrderPath(sellerId, orderId, subOrderId),
      payload
    )
    this.logger.debug(
      `Created evaluating for order ${subOrderId} from seller ${sellerId}`,
      'dataCreation'
    )
    return response
  }

  async fetchSellerEvaluations(id: string, offset: number, limit = 5) {
    assert(id, 'sellerService.fetchSellerEvaluations: id missing')
    this.logger.debug(`Fetching sellerEvaluations ${id}`, 'dataFetching')
    const { data } = await this.get(
      `${this.getSellerDataPath(
        id
      )}/evaluations?offset=${offset}&limit=${limit}`
    )
    this.logger.debug(`SellerEvaluations ${id} fetched!`, 'dataFetching')
    return data
  }
}

export default SellerService
