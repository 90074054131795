import {
  TENANT_AT,
  TENANT_BE,
  TENANT_CH,
  TENANT_COM,
  TENANT_FR,
  TENANT_IT,
} from 'shared/consts/tenants'

export const STATIC_URL_BASE_COM = 'https://static.shop-apotheke.com'
export const STATIC_URL_BASE_AT = 'https://static.shop-apotheke.at'
export const STATIC_URL_BASE_CH = 'https://static.redcare-apotheke.ch'
export const STATIC_URL_BASE_FR = 'https://static.redcare-pharmacie.fr'
export const STATIC_URL_BASE_IT = 'https://static.redcare.it'
export const STATIC_URL_BASE_BE = 'https://static.redcare-apotheke.ch'

export const NEW_CDN_STATIC_URL_BASE_COM = 'https://cdn.shop-apotheke.com'
export const NEW_CDN_STATIC_URL_BASE_AT = 'https://cdn.shop-apotheke.at'
export const NEW_CDN_STATIC_URL_BASE_FR = 'https://cdn.redcare-pharmacie.fr'
export const NEW_CDN_STATIC_URL_BASE_CH = 'https://cdn.shop-apotheke.ch'
export const NEW_CDN_STATIC_URL_BASE_IT = 'https://cdn.redcare.it'
export const NEW_CDN_STATIC_URL_BASE_BE = 'https://cdn.farmaline.be'

export const STATIC_URL_MAP = {
  [TENANT_COM]: STATIC_URL_BASE_COM,
  [TENANT_AT]: STATIC_URL_BASE_AT,
  [TENANT_CH]: STATIC_URL_BASE_CH,
  [TENANT_FR]: STATIC_URL_BASE_FR,
  [TENANT_IT]: STATIC_URL_BASE_IT,
  [TENANT_BE]: STATIC_URL_BASE_BE,
}
export const NEW_CDN_STATIC_URL_MAP = {
  [TENANT_COM]: NEW_CDN_STATIC_URL_BASE_COM,
  [TENANT_AT]: NEW_CDN_STATIC_URL_BASE_AT,
  [TENANT_CH]: NEW_CDN_STATIC_URL_BASE_CH,
  [TENANT_FR]: NEW_CDN_STATIC_URL_BASE_FR,
  [TENANT_IT]: NEW_CDN_STATIC_URL_BASE_IT,
  [TENANT_BE]: NEW_CDN_STATIC_URL_BASE_BE,
}

export const MEDICATION_PLAN_TEST_URL = 'https://med.mediawaves.de'
export const MEDICATION_PLAN_DEV_URL =
  'https://med-shared-services-dev.redteclab.de'
export const MEDICATION_PLAN_QS_URL =
  'https://med-shared-services-qs.redteclab.de'
export const MEDICATION_PLAN_PROD_URL = 'https://med.shop-apotheke.com'
