import deepmerge from 'deepmerge'

export * from './cycleSafe'

type FilterFn<T = unknown> = (value: T) => boolean

type FilterEntryFn<T> = (
  entry: [keyof T, ValueOf<T>],
  index?: number,
  array?: T[]
) => boolean

export const filterEntries = <T = AbstractRecord>(
  filter: FilterEntryFn<T>,
  object: T
): Partial<T> =>
  // Not to mess with the .filter typed signatures in the global namespace,
  // `mapper` gets typed arguments anyway
  // @ts-ignore
  Object.fromEntries(Object.entries(object).filter(filter))

export const filterKeys = <T = AbstractRecord>(
  filter: FilterFn<keyof T>,
  object: T
): Partial<T> => filterEntries(([key]) => filter(<keyof T>key), object)

export const filterValues = <T extends AbstractRecord>(
  filter: FilterFn<ValueOf<T>>,
  object: T
): Partial<T> => filterEntries(([, value]) => filter(value), object)

export const omit = <T = AbstractRecord, K extends keyof T = keyof T>(
  object: T,
  keys: readonly K[] = []
) => filterKeys<T>(key => !keys.includes(<K>key), object) as Omit<T, K>

export const pick = <T extends AbstractRecord, K extends keyof T>(
  object: T,
  keys: readonly K[] = []
) => filterKeys(key => keys.includes(<K>key), object) as Pick<T, K>

export const isObject = <T = GenericRecord>(value: unknown): value is T =>
  typeof value === 'object' && !Array.isArray(value)

export const mapEntries = <T extends AbstractRecord, TResult = AbstractRecord>(
  mapper: (entry: [keyof T, ValueOf<T>], idx?: number) => unknown,
  object: T
): T & TResult =>
  // Not to mess with the .map typed signatures in the global namespace,
  // `mapper` gets typed arguments anyway
  // @ts-ignore
  Object.fromEntries(Object.entries(object).map(mapper))

export const mapKeys = <T extends AbstractRecord, TResult = AbstractRecord>(
  mapper: (key: keyof T, idx?: number) => PropertyKey,
  object: T
) => {
  return mapEntries(
    ([key, value], idx) => [mapper(key, idx), value],
    object
  ) as T & TResult
}

export const mapValues = <T extends AbstractRecord, TResult = AbstractRecord>(
  mapper: (value: ValueOf<T>, idx?: number) => unknown,
  object: T
) => {
  return mapEntries(
    ([key, value], idx) => [key, mapper(value, idx)],
    object
  ) as T & TResult
}

/**
 * Tests if object or array is empty, null or undefined
 */
export const isEmpty = <T = AbstractRecord | unknown[] | null>(
  object: T
): object is T => {
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      return false
    }
  }

  return true
}

const sortByFieldAscending =
  (field: string) =>
  (currObj: GenericRecord<string>, nextObj: GenericRecord<string>): number => {
    // For variants possible scenarios are:
    //      unitQuantityString = '80'
    //      OR
    //      unitQuantityString = '3x0.5 ml'
    // quite handy that `parseInt("3x0.5 mg") // => 3`
    // that means that we don't have to do fancy stuff to
    // figure out value/type of the field, at least for now.
    const currentVal = parseInt(currObj[field], 10)
    const nextVal = parseInt(nextObj[field], 10)

    return currentVal - nextVal
  }

export const sort = (
  collection: GenericRecord<string>[],
  field: string
): GenericRecord<string>[] => {
  if (!Array.isArray(collection) || !field) {
    return collection
  }

  return [...collection].sort(sortByFieldAscending(field))
}

const defaultCompareStringFn = (a: string, b: string): -1 | 0 | 1 => {
  if (a === b) {
    return 0
  }

  return a < b ? -1 : 1
}

export const sortByKey = <T = GenericRecord>(
  object: T,
  compare: typeof defaultCompareStringFn = defaultCompareStringFn
) =>
  Object.fromEntries(
    Object.entries(object).sort(([a], [b]) => compare(a, b))
  ) as T

export const isPrimitiveValue = (
  value: unknown
): value is number | string | boolean | null => {
  return (
    value == null ||
    typeof value !== 'object' ||
    // See https://developer.mozilla.org/en-US/docs/Glossary/Primitive#Primitive_wrapper_objects_in_JavaScript
    // Note that BigInt and Symbol are not constructable, so there’s no
    // point in listing them here
    value instanceof String ||
    value instanceof Number ||
    value instanceof Boolean
  )
}

export const walkOverPropsRecursively = <T = GenericRecord | unknown[]>(
  args: {
    shouldTransform?: (input: unknown) => boolean
    transform: (prop: unknown) => unknown
  },
  input: T
): T => {
  const shouldTransform = args.shouldTransform || isPrimitiveValue
  if (shouldTransform(input)) {
    return args.transform(input) as T
  }

  if (isPrimitiveValue(input)) {
    return input
  }

  if (Array.isArray(input)) {
    return input.map(val => walkOverPropsRecursively(args, val)) as unknown as T
  }

  return Object.fromEntries(
    Object.entries(input).map(([key, value]) => [
      key,
      walkOverPropsRecursively(args, value),
    ])
  ) as T
}

export const getObjectPrimitiveValues = <T = unknown>(
  object: AbstractRecord,
  filterByKeys: (key: string, v: unknown) => unknown = (key, val) => val
): T[] => {
  if (!object || !isObject(object)) {
    return []
  }

  const values = Object.entries(object).map(([key, val]) =>
    isPrimitiveValue(val)
      ? filterByKeys(key, val)
      : getObjectPrimitiveValues(<AbstractRecord>val, filterByKeys)
  )

  return values.flat() as T[]
}

export const getPathByValue = (
  obj: GenericRecord,
  value: unknown,
  path?: string
): string | null => {
  let resultPath

  for (const key in obj) {
    if (obj[key]) {
      const objectValue = obj[key]

      if (objectValue === value) {
        return path ? `${path}.${key}` : key
      } else if (isObject(objectValue)) {
        resultPath = getPathByValue(
          objectValue,
          value,
          path ? `${path}.${key}` : key
        )
      }

      if (resultPath) {
        return resultPath
      }
    }
  }

  return null
}

export const getValueByPath = <T = unknown>(
  object: GenericRecord<T>,
  path = ''
): unknown => {
  let result = object

  path.split('.').forEach(key => {
    //@ts-ignore it is OK since var is overridden many times
    result = result?.[key]
  })

  return result
}

export const setValueByPath = <T = GenericRecord>({
  obj,
  path,
  value,
}: {
  obj: T
  path: string
  value: unknown
}): T => {
  const pathArr = path.split('.')
  const objectToMerge: GenericRecord = {}
  const lastKey = pathArr.pop()

  let ref = objectToMerge
  pathArr.forEach(key => {
    ref[key] = {}
    //@ts-ignore it is OK since var is overridden many times
    ref = ref[key]
  })

  if (lastKey) {
    ref[lastKey] = value
  }

  return deepmerge<T>(obj, <T>objectToMerge)
}

// Simplify handling of arrays with IDs in redux by converting them to object
// which have the ids as properties under which the rest of the elements lie.
// This way we can exploit the fact, that javascript objects can only
// have one property of a certain name and we don't have to check
// the store if an array with id X exists etc.
export const objectifyArrayWithIds = <T extends GenericRecord>(
  arrayWithIds: T[]
) => {
  const finalObject: GenericRecord<T> = {}

  arrayWithIds?.forEach(element => {
    const id = element.id
    if (id && typeof id === 'string') {
      finalObject[id] = { ...element }
    }
  })

  return finalObject
}
