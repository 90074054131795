import type { NotepadV2ProductEntryList } from '@redteclab/api/clients/bully'

import { Model } from 'shared/services/api/models/Model'

import { NotepadEntryWithProduct } from './notepadEntryWithProduct'

export class NotepadEntriesResponse extends Model {
  public entries: NotepadEntryWithProduct[]
  public totalCount: number
  public limit?: number
  public page: number
  constructor(src: NotepadV2ProductEntryList) {
    super(src)
    this.entries = src.entries?.length
      ? src.entries.map(entry => new NotepadEntryWithProduct(entry))
      : []
    this.limit = src.limit
    this.page = src.page
    this.totalCount = src.totalCount
  }
}
