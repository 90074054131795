// ########## Status ##########
export const MAILING_SUBSCRIPTION_STATUS_UNSUBSCRIBED = 'unsubscribed'
export const MAILING_SUBSCRIPTION_STATUS_NEW = 'new'
export const MAILING_SUBSCRIPTION_STATUS_SUBSCRIBED = 'subscribed'
export const MAILING_SUBSCRIPTION_STATUS_OPTIN_WAITING = 'optin_waiting'

// ########## Type ##########
export const MAILING_SUBSCRIPTION_TYPE_NEWSLETTER = 'newsletter'
export const MAILING_SUBSCRIPTION_TYPE_REDPOINTS = 'redPoints'

export const REGISTRATION_ORIGIN = 'fock'

export enum SUBSCRIPTION_TYPE {
  NEWSLETTER = 'newsletter',
  REDPOINTS = 'redPoints',
}

export enum SUBSCRIPTION_STATUS {
  UNSUBSCRIBED = 'unsubscribed',
  NEW = 'new',
  SUBSCRIBED = 'subscribed',
  VERIFIED_SUBSCRIBED = 'verified_subscribed',
  OPTIN_AWAITING = 'optin_awaiting',
}

export enum SUBSCRIPTION_ACTION {
  UNSUBSCRIBE = 'unsubscribe',
}
