// data layer events
export const RETURN_MODAL_OPEN_EVENT = 'returnModalOpen'
export const RETURN_MODAL_CLOSE_EVENT = 'returnModalClose'
export const RETURN_LABEL_CREATED_EVENT = 'returnLabelCreated'

export const RETURN_LABEL_CREATE_DATA_KEY = 'return-label-create-data'
export const RETURN_LABEL_CREATE_ORDER_MINIMUM = 25

export const isBelowThresholdValue = (total: number) =>
  total < RETURN_LABEL_CREATE_ORDER_MINIMUM
