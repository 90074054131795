import toggleBoolStringAttr from 'views/utils/toggleBoolStringAttribute'

import { ARIA_HIDDEN } from './consts'

const TABS_SELECTOR = '[data-clientside-hook~="tabs"]'
const TABS_HEADER_SELECTOR = '[data-clientside-hook~="tabsHeader"]'
const TABS_CONTENT_SELECTOR = '[data-clientside-hook~="tabsContent"]'
const ACTIVE_CLASS = 'o-Tabs__tab--active'

export default () => {
  const tabs = [...document.querySelectorAll(TABS_SELECTOR)]

  return tabs.map(tab => {
    const { tabId } = tab.dataset
    const headers = [
      ...document.querySelectorAll(
        `${TABS_HEADER_SELECTOR}[data-tab-id=${tabId}]`
      ),
    ]
    const contents = [
      ...document.querySelectorAll(
        `${TABS_CONTENT_SELECTOR}[data-tab-id=${tabId}]`
      ),
    ]

    const onClick = target => {
      const {
        dataset: { index },
      } = target

      headers.forEach(header => {
        header.classList.remove(ACTIVE_CLASS)
      })

      target.classList.add(ACTIVE_CLASS)

      contents.forEach(content => {
        toggleBoolStringAttr(content, ARIA_HIDDEN, true)
      })

      const activeContent = contents.find(el => el.dataset.index === index)

      toggleBoolStringAttr(activeContent, ARIA_HIDDEN, false)
    }

    headers.forEach(header => {
      header.addEventListener('click', () => onClick(header))
    })

    return tab
  })
}
