import { Trans } from '@lingui/react'

import IconCross from 'views/components/atoms/Icon/IconCross'
import TextWithIcon from 'views/components/molecules/TextWithIcon'

const ModalCloseButton = ({
  closeButtonText = /*i18n*/ 'general.link.label.close',
  dataQaId,
}: {
  closeButtonText: string
  dataQaId?: string
}) => {
  return (
    <TextWithIcon icon={<IconCross />} iconClassName="m-Modal__close-icon">
      <span
        data-qa-id={dataQaId}
        className="u-visuallyhidden@small-tiny m-Modal__close-text"
      >
        <Trans id={closeButtonText} />
      </span>
    </TextWithIcon>
  )
}

export default ModalCloseButton
