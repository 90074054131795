// @ts-nocheck
import store from 'shared/store'
import { formatPathWithLanguage } from 'shared/utils/url'
import buildClientAPI from 'views/providers/clientAPI'

const MINICART_BADGE_SELECTORS = [
  '[data-clientside-hook~="Menubar__cart-badge"]',
  '[data-clientside-hook~="SmallHeader__cart-badge"]',
]

const MINICART_LINK_SELECTORS = [
  '[data-clientside-hook~="Menubar__cart-link"]',
  '[data-clientside-hook~="SmallHeader__cart-link"]',
]

/**
 * fail-save (for not found element) querying and setting of props
 *
 * @param {string} selector querySelector for the desired element
 * @param {object} props key/value pairs of props to set
 */
const selectAndSet = (selector, props) => {
  const element = document.querySelector(selector)
  if (element) {
    Object.keys(props).forEach(key => {
      element[key] = props[key]
    })
  }
}

export default async () => {
  const {
    publicConfig: { language, tenant },
  } = store.getPublicRuntimeConfig()

  const formatPath = formatPathWithLanguage(tenant)(language)

  const { cartServiceV3 } = buildClientAPI()
  const miniCart = await cartServiceV3.fetchMiniCart()

  const cartQuantity = miniCart?.data?.entriesInCart || 0

  MINICART_BADGE_SELECTORS.forEach(selector => {
    selectAndSet(selector, { textContent: cartQuantity })
  })

  MINICART_LINK_SELECTORS.forEach(selector => {
    selectAndSet(selector, {
      href: formatPath('/nx/cart/'),
    })
  })
}
