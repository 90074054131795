import { combineReducers } from 'redux'

import cart from 'shared/store/ducks/cart/cartReducer'
import componentInitialState from 'shared/store/ducks/componentInitialState'
import debug from 'shared/store/ducks/debug'
import events from 'shared/store/ducks/events'
import nowData from 'shared/store/ducks/now/nowReducer'
import publicRuntimeConfig from 'shared/store/ducks/publicRuntimeConfig'
import shopConfig from 'shared/store/ducks/shopConfig'
import userSession from 'shared/store/ducks/userSession'
import {
  CART_KEY,
  COMPONENT_INITIAL_STATE_KEY,
  DEBUG_KEY,
  NOW_DATA_KEY,
  PUBLIC_RUNTIME_CONFIG_KEY,
  SHOP_CONFIG_KEY,
} from 'shared/store/helper/consts'

const keyed = {
  // add other reducers (can override the default ones)
  [DEBUG_KEY]: debug,
  userSession,
  [COMPONENT_INITIAL_STATE_KEY]: componentInitialState,
  [PUBLIC_RUNTIME_CONFIG_KEY]: publicRuntimeConfig,
  [SHOP_CONFIG_KEY]: shopConfig,
  events,
  [CART_KEY]: cart,
  [NOW_DATA_KEY]: nowData,
}

const reducer = combineReducers(keyed)

export default reducer

export { keyed } // TODO: Remove once checkout is dropped
