// @ts-nocheck
import deepmerge from 'deepmerge'

import { IS_JEST, IS_STORYBOOK } from 'shared/consts/env'
// If you need some of those available pls import in this module and later export them from this module
// globals, utils, general vars
import breakpoints from 'views/assets/stylesheets/exports/utils/_breakpoints.scss'
import colors from 'views/assets/stylesheets/exports/utils/_colors.scss'
import content from 'views/assets/stylesheets/exports/utils/_content.scss'
import timings from 'views/assets/stylesheets/exports/utils/_timings.scss'
import typography from 'views/assets/stylesheets/exports/utils/_typography.scss'
// import spacing from 'views/assets/stylesheets/exports/utils/_spacing.scss'
// import borders from 'views/assets/stylesheets/exports/utils/_borders.scss'

const sassVars = {
  colors,
  breakpoints,
  timings,
  typography,
  // spacing,
  // borders,
  content,
}

// Jest does not have SCSS files loading set up, so all imported
// variables will be undefined. We’ll mock some of them.
const mocks = {
  breakpoints: {
    tiny: 'screen and (min-width: 21em)',
    small: 'screen and (min-width: 30em)',
    medium: 'screen and (min-width: 45em)',
    large: 'screen and (min-width: 64em)',
    'small-tiny': 'screen and (max-width: 44.9375em)',
    print: 'print',
    touch: '(hover: none) and (pointer: coarse)',
    ie: 'all and (-ms-high-contrast: none), (-ms-high-contrast: active)',
  },
  typography: {
    'font-size-base': '14px',
  },
  content: {
    'content-max-width': '72em',
  },
}

Object.entries(sassVars).forEach(([key, value]) => {
  let newValue = value
  if (
    IS_JEST &&
    (!value ||
      // Check if identity-obj-proxy is in place
      value.locals === 'locals')
  ) {
    newValue = mocks[key]
  } else if (!IS_STORYBOOK) {
    newValue = newValue.locals
  }
  // Fall back to empty object.
  // That’s mostly for non–mocked things in jest
  sassVars[key] = newValue || {}
})

// Remove quotes around media query strings
sassVars.breakpoints = Object.fromEntries(
  Object.entries(sassVars.breakpoints).map(([key, value]: [string, string]) => [
    key,
    value.replace(/"|'/g, ''),
  ])
)

// Avoid accidental mutations
Object.keys(sassVars).forEach(key => {
  sassVars[key] = Object.freeze(deepmerge({}, sassVars[key]))
})

export default sassVars
