import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const PATH =
  'm50 0c-27.661 0-50 22.339-50 50 0 27.661 22.339 50 50 50 27.661 0 50-22.339 50-50 0-27.661-22.414-50-50-50zm0 89.955c-22.114 0-39.955-17.916-39.955-39.955 0-22.039 17.841-40.03 39.955-40.03 22.114 0 39.955 17.916 39.955 39.955 0 22.039-17.916 39.955-39.955 39.955zm5.0225-42.504v22.489c0 2.7736-2.2489 5.0225-5.0225 5.0225-2.7736 0-5.0225-2.2489-5.0225-5.0225v-22.489c0-2.7736 2.2489-5.0225 5.0225-5.0225 2.7736 0 5.0225 2.2489 5.0225 5.0225zm0-17.466c0 2.7736-2.2489 5.0225-5.0225 5.0225-2.7736 0-5.0225-2.2489-5.0225-5.0225 0-2.7736 2.2489-5.0225 5.0225-5.0225 2.7736 0 5.0225 2.2489 5.0225 5.0225z'

const IconCircleInfoOutline: FC<IconProps> = props => (
  <SvgIcon {...props} path={PATH} viewBox="0 0 100 100" />
)

export default IconCircleInfoOutline
