import { v4 as uuidv4 } from 'uuid'

import assert from 'shared/utils/assert'

import BackendAPIService from './BackendAPIService'
import type { ServiceParameters } from './commonTypes'
import type {
  ICancellationReasonResponse,
  ICreatePartialCancellationRequestBody,
} from './models/cancellation'

const config = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
}

const API_VERSION = 'v1'

class CancellationService extends BackendAPIService {
  constructor(props: ServiceParameters) {
    super(props)

    this.apiVersion = API_VERSION
  }

  getPartialCancellationEndpoint(orderId: string, subOrderId: string) {
    return `/cancellation/${this.apiVersion}/${this.tenantPath}/orders/${orderId}/subOrders/${subOrderId}/cancel/partial`
  }

  async getSingleItemCancellationReasons() {
    const { data } = await this.get<ICancellationReasonResponse>(
      `cancellation/${this.apiVersion}/${this.tenantPath}/cancel/reasons`,
      config
    )

    return data
  }

  async createPartialCancellationRequest(
    orderId: string,
    subOrderId: string,
    body: ICreatePartialCancellationRequestBody
  ): Promise<unknown> {
    assert(
      orderId,
      'CancellationService.createPartialCancellationRequest: please provide "orderId"'
    )

    assert(
      subOrderId,
      'CancellationService.createPartialCancellationRequest: please provide "subOrderId"'
    )

    assert(
      body.suborderItems,
      'CancellationService.createPartialCancellationRequest: please provide "suborderItems"'
    )

    const debugConfig = {
      headers: {
        ...config.headers,
        'x-debug-cancellation': uuidv4(),
      },
    }

    const { data } = await this.post(
      this.getPartialCancellationEndpoint(orderId, subOrderId),
      body,
      debugConfig
    )

    return data
  }
}

export default CancellationService
