import debounce from 'views/utils/debounce'
import intersectionObserver from 'views/utils/intersectionObserver'

const IFRAME_SELECTOR = '[data-clientside-hook~="iframe"]'

export const resizeIframe = target => {
  target.style.height = 'auto'
  target.style.height = `${target.contentWindow.document.body.scrollHeight}px`
}

export default () => {
  return [...document.querySelectorAll(IFRAME_SELECTOR)].forEach(iframe => {
    const loadIframe = () => {
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document
      // Check if loading is complete
      if (iframeDoc.readyState === 'complete') {
        resizeIframe(iframe)
      } else {
        iframe.contentWindow.onload = () => resizeIframe(iframe)
      }
    }

    intersectionObserver({
      callback: () => {
        loadIframe()
      },
      targetElements: iframe,
    })

    window.addEventListener(
      'resize',
      debounce(() => {
        resizeIframe(iframe)
      })
    )
  })
}
