// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

import { OrderPickupStationV1 } from './ordersResponseTypes'

export class OrderPickupStation extends Model {
  public readonly id: string
  public readonly name: string
  public readonly type: string
  public readonly postNumber: string

  constructor(src: OrderPickupStationV1) {
    super(src)
    this.id = src.id
    this.name = src.name
    this.type = src.type
    this.postNumber = src.postNumber
  }
}
