import deepmerge from 'deepmerge'

export const LOAD_LAST_ACTIVITY = 'LOAD_LAST_ACTIVITY'
export const LOAD_PRODUCT_RATINGS_CONTAINER = 'LOAD_PRODUCT_RATINGS_CONTAINER'
export const CREATE_SLIDER = 'CREATE_SLIDER'
export const CHANGE_SLIDER_INDEX = 'CHANGE_SLIDER_INDEX'
export const INIT_SLIDER = 'INIT_SLIDER'
export const BIND_MODAL = 'BIND_MODAL'
export const REFRESH_MODALS = 'REFRESH_MODALS'
export const SHOW_MODAL = 'SHOW_MODAL'
export const MODAL_SHOWN = 'MODAL_SHOWN'
export const SHOW_MODAL_DECLARATIVE = 'SHOW_MODAL_DECLARATIVE'
export const CLEAR_SHOW_MODAL = 'CLEAR_SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const CLEAR_HIDE_MODAL = 'CLEAR_HIDE_MODAL'
export const DESTROY_MODAL = 'DESTROY_MODAL'
export const CHARGE_SEARCH_PARAMS = 'CHARGE_SEARCH_PARAMS'
export const CLOSE_OFFCANVAS = 'CLOSE_OFFCANVAS'
export const PRODUCT_FILTER_CHANGED = 'PRODUCT_FILTER_CHANGED'
export const PRODUCT_FILTER_RESET = 'PRODUCT_FILTER_RESET'
export const SEARCH_RESULTS_UPDATED = 'SEARCH_RESULTS_UPDATED'
export const ADAPT_SLIDER_ARROWS = 'ADAPT_SLIDER_ARROWS'
export const HITS_PER_PAGE_FILTER_CHANGED = 'HITS_PER_PAGE_FILTER_CHANGED'
export const INIT_CROSSSELL = 'INIT_CROSSSELL'
export const SET_PRODUCT_URL = 'SET_PRODUCT_URL'
export const EXPANDER_INIT = 'EXPANDER_INIT'
export const UPDATE_SELECT = 'UPDATE_SELECT'
export const RESET_SELECT_VALUES = 'RESET_SELECT_VALUES'
export const SCROLL_TO_TOP = 'SCROLL_TO_TOP'
export const LOCK_UI = 'LOCK_UI'
export const UNLOCK_UI = 'UNLOCK_UI'
export const SEARCH_RESULTS_HITS_UPDATED = 'SEARCH_RESULTS_HITS_UPDATED'
export const QUERY_UPDATED = 'QUERY_UPDATED'
export const SET_OFFER_TYPE = 'SET_OFFER_TYPE'
export const CONTACT_SELLER_MESSAGE_MODAL_UPDATE =
  'CONTACT_SELLER_MESSAGE_MODAL_UPDATE'
export const SPECIAL_SALE_OFFERS_MODAL_SHOWN = 'SPECIAL_SALE_OFFERS_MODAL_SHOWN'
export const SPECIAL_SALE_OFFERS_CROSSSELL_INIT =
  'SPECIAL_SALE_OFFERS_CROSSSELL_INIT'

// NB Remove when CRO-280 is finished TODO find out why is still used
export const INIT_XSELL_TOP_SLIDER = 'INIT_XSELL_TOP_SLIDER'

// NB Remove when CRO-596 is finished
export const INIT_XSELL_MOBILE_COMPACT_SLIDER =
  'INIT_XSELL_MOBILE_COMPACT_SLIDER'

// actions
export const loadLastActivityAction = () => ({
  type: LOAD_LAST_ACTIVITY,
})

export const loadProductRatingsContainerAction = props => ({
  type: LOAD_PRODUCT_RATINGS_CONTAINER,
  props,
})

export const createSliderAction = ({ containerId, slider }) => ({
  type: CREATE_SLIDER,
  containerId,
  slider,
})

export const changeSliderIndex = ({ containerId }) => ({
  type: CHANGE_SLIDER_INDEX,
  containerId,
})

export const initSliderAction = () => ({
  type: INIT_SLIDER,
})

export const bindModalAction = ({ selector }) => ({
  type: BIND_MODAL,
  selector,
})

export const showModalAction = modalName => ({
  type: SHOW_MODAL,
  modalName,
})

export const showModalDeclarativeAction = modalName => ({
  type: SHOW_MODAL_DECLARATIVE,
  modalName,
})

export const clearShowModalAction = () => ({
  type: CLEAR_SHOW_MODAL,
})

export const destroyModalAction = modalName => ({
  type: DESTROY_MODAL,
  modalName,
})

export const modalShownAction = modalName => ({
  type: MODAL_SHOWN,
  modalName,
})

export const hideModalAction = modalName => ({
  type: HIDE_MODAL,
  modalName,
})

export const clearHideModalAction = () => ({
  type: CLEAR_HIDE_MODAL,
})

export const chargeSearchParamsAction = ({ selector }) => ({
  type: CHARGE_SEARCH_PARAMS,
  selector,
})

export const closeOffCanvasAction = () => ({
  type: CLOSE_OFFCANVAS,
})

export const adaptSliderArrows = ({ delay }) => ({
  type: ADAPT_SLIDER_ARROWS,
  delay,
})

export const setProductUrl = ({ productUrl }) => ({
  type: SET_PRODUCT_URL,
  productUrl,
})

export const updatePrescriptionType = ({ select, value }) => ({
  type: UPDATE_SELECT,
  isPrescriptionList: true,
  select,
  value,
})

export const setOfferType = ({ offerType }) => ({
  type: SET_OFFER_TYPE,
  offerType,
})

// reducer
const reducer = (state = {}, action = {}) => {
  switch (action.type) {
    case SHOW_MODAL_DECLARATIVE:
      return deepmerge(state, {
        showModal: action.modalName,
      })
    case HIDE_MODAL:
      return deepmerge(state, {
        hideModal: action.modalName,
      })
    case CLEAR_SHOW_MODAL:
      return deepmerge(state, {
        showModal: '',
      })
    case CLEAR_HIDE_MODAL:
      return deepmerge(state, {
        hideModal: '',
      })
    case SET_PRODUCT_URL:
      return {
        ...state,
        productUrl: action.productUrl,
      }
    case SET_OFFER_TYPE:
      return {
        ...state,
        offerType: action.offerType,
      }
    case SEARCH_RESULTS_UPDATED:
      return {
        ...state,
        searchState: action.searchState,
      }
    case CONTACT_SELLER_MESSAGE_MODAL_UPDATE:
      return {
        ...state,
        messageSeller: action.props,
      }
    default:
      return state
  }
}
export default reducer
