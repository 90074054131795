import { removeCycles } from 'shared/utils/objectUtils'

import ConsoleRawStream from './ConsoleRawStream'

class JsonStream extends ConsoleRawStream {
  write(rec) {
    let json
    if (process.env.NODE_ENV === 'development') {
      json = JSON.stringify(removeCycles(rec), null, 2)
    } else {
      json = JSON.stringify(removeCycles(rec))
    }
    super.write(rec.level, json)
  }
}

export default JsonStream
