import classNames from 'clsx'
import { bool, func, node, string } from 'prop-types'

import ErrorMessage from 'views/components/atoms/ErrorMessage'
import Input from 'views/components/atoms/Input'
import { ERROR_CLASSNAME } from 'views/consts'

import './InputCheckboxOrRadio.scss'

const InputCheckboxOrRadio = props => {
  const {
    errorMessage,
    children,
    checked,
    defaultChecked,
    bold,
    required,
    ...rest
  } = props
  const classname = classNames('m-InputCheckboxOrRadio', {
    [ERROR_CLASSNAME]: errorMessage,
    'm-InputCheckboxOrRadio--checkbox': props.type === 'checkbox',
    'm-InputCheckboxOrRadio--radio': props.type === 'radio',
  })
  const labelClassName = classNames(
    'm-InputCheckboxOrRadio__label a-h3 u-no-margin',
    {
      'u-font-weight--normal': !bold,
      'u-font-weight--bold': bold,
    }
  )

  // Component should have either `value` or `defaultValue` set.
  // Default `null` is also value, so it cannot be passed as is.
  const checkedValues = { checked, defaultChecked }
  if (checked === null) {
    delete checkedValues.checked
  } else if (defaultChecked === null) {
    delete checkedValues.defaultChecked
  } else {
    throw new Error(
      'Component should not have both `value` and `defaultValue` set'
    )
  }

  const shouldRenderErrorMessage = errorMessage || props.type === 'checkbox'

  return (
    <label className={classname} htmlFor={props.id}>
      <Input
        className="m-InputCheckboxOrRadio__input"
        required={required || undefined}
        {...rest}
        {...checkedValues}
      />
      <span className={labelClassName}>
        {children}
        {required && <>*</>}
      </span>
      {shouldRenderErrorMessage && <ErrorMessage text={errorMessage} />}
    </label>
  )
}

InputCheckboxOrRadio.propTypes = {
  children: node.isRequired,
  checked: bool,
  defaultChecked: bool,
  value: string,
  id: string.isRequired,
  name: string.isRequired,
  disabled: bool,
  errorMessage: string,
  type: string,
  bold: bool,
  required: bool,
  register: func,
  onChange: func,
}

InputCheckboxOrRadio.defaultProps = {
  checked: null,
  defaultChecked: null,
  disabled: false,
  errorMessage: '',
  type: 'checkbox',
  value: 'off',
  bold: false,
  required: false,
  register: null,
  onChange: null,
}

export default InputCheckboxOrRadio
