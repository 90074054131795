export const USERNAME_FIELD = 'username'
export const PASSWORD_FIELD = 'password'

export const SALUTATION_FIELD = 'salutation'
export const FIRSTNAME_FIELD = 'firstName'
export const LASTNAME_FIELD = 'lastName'
export const COMPANY_NAME_FIELD = 'companyName'
export const VAT_NUMBER_EXEMPTION_FIELD = 'vatExemption'
export const VAT_NUMBER_FIELD = 'vatNumber'
export const EMAIL_FIELD = 'email'
export const OTP_FIELD = 'otp'
export const BIRTHDAY_DAY_FIELD = 'birthdayDay'
export const BIRTHDAY_MONTH_FIELD = 'birthdayMonth'
export const BIRTHDAY_YEAR_FIELD = 'birthdayYear'
export const NEWSLETTER_ACCEPTED_FIELD = 'newsletterAccepted'
export const TOS_ACCEPTED_FIELD = 'tosAccepted'
export const PREFERRED_LANGUAGE_FIELD = 'preferredLanguage'

export const TARGET_FEATURE_CODE_FIELD = 'targetFeatureCode'
export const TARGET_PRODUCT_CODE_FIELD = 'targetProductCode'
export const TARGET_ANCHOR_FIELD = 'targetAnchor'
export const TARGET_PAGE_CODE_FIELD = 'targetPageCode'
export const TARGET_PAGE_COMMAND_FIELD = 'targetPageCommand'

export const LOGIN_REGISTER_ACTION_FIELD = 'action'
export const LOGIN_FORM_ACTION_FIELD_VALUE = 'loginForm'
export const REGISTER_FORM_ACTION_FIELD_VALUE = 'registerForm'
export const OTP_REQUEST_FORM_ACTION_FIELD_VALUE = 'otpRequestForm'
export const OTP_INPUT_FORM_ACTION_FIELD_VALUE = 'otpInputForm'
export const REGISTER_FORM_CUSTOMER_TYPE_FIELD = 'customerType'
export const REGISTER_CUSTOMER_TYPE_PROFESSIONAL_FIELD_VALUE = 'professional'

// Medication Plan date constants
export const MP_TAKE_FROM_DAY = 'medicationTakeFromDay'
export const MP_TAKE_FROM_MONTH = 'medicationTakeFromMonth'
export const MP_TAKE_FROM_YEAR = 'medicationTakeFromYear'
export const MP_TAKE_TO_DAY = 'medicationTakeToDay'
export const MP_TAKE_TO_MONTH = 'medicationTakeToMonth'
export const MP_TAKE_TO_YEAR = 'medicationTakeToYear'
