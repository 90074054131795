// This file is used by deprecated <Icon /> component. New preferred way
// is to import 'Icon/Icon*' components instead, but Icon/index is kept
// for backwards compatibility while we refactor things.
//
// Lazy loading icons makes them appear only after a moment, so we do
// it only for less popular ones. We can switch more and more to lazy
// loading as we refactor things.
//
// Note that this does not apply to server–side rendered icons. For
// those see ./consts.client.ts which does not do any lazy loading

import { lazy } from 'react'

import IconERxPhone from 'views/components/atoms/Icon/IconERxPhone'

import IconArrowBreadcrumb from './IconArrowBreadcrumb'
import IconArrowLeft from './IconArrowLeft'
import IconArrowRight from './IconArrowRight'
import IconBin from './IconBin'
import IconCaretDown from './IconCaretDown'
import IconCaretLeft from './IconCaretLeft'
import IconCaretRight from './IconCaretRight'
import IconCaretUp from './IconCaretUp'
import IconCart from './IconCart'
import IconCircleHealth from './IconCircleHealth'
import IconCircleInfo from './IconCircleInfo'
import IconCirclePercent from './IconCirclePercent'
import IconCross from './IconCross'
import IconEye from './IconEye'
import IconEyeCross from './IconEyeCross'
import IconNew from './IconNew'
import IconOds from './IconOds'
import IconRedeemRecipe from './IconRedeemRecipe'
import IconRedpoints from './IconRedpoints'
import IconRefresh from './IconRefresh'
import IconSearch from './IconSearch'
import IconSendEnvelope from './IconSendEnvelope'
import IconStarEmpty from './IconStarEmpty'
import IconStarFilled from './IconStarFilled'
import IconTick from './IconTick'

export const USES_LAZY_LOADING = true

export const ICON_TO_COMPONENT_MAP = {
  arrowBreadcrumb: IconArrowBreadcrumb,
  arrowLeft: IconArrowLeft,
  arrowRight: IconArrowRight,
  babyBottle: lazy(
    () => import(/* webpackChunkName: "icon-babyBottle" */ './IconBabyBottle')
  ),
  barcode: lazy(
    () => import(/* webpackChunkName: "icon-barcode" */ './IconBarcode')
  ),
  beaker: lazy(
    () => import(/* webpackChunkName: "icon-beaker" */ './IconBeaker')
  ),
  bin: IconBin,
  blog: lazy(() => import(/* webpackChunkName: "icon-blog" */ './IconBlog')),
  calendar: lazy(
    () => import(/* webpackChunkName: "icon-calendar" */ './IconCalendar')
  ),
  caretDown: IconCaretDown,
  caretLeft: IconCaretLeft,
  caretRight: IconCaretRight,
  caretUp: IconCaretUp,
  cart: IconCart,
  certificate: lazy(
    () => import(/* webpackChunkName: "icon-certificate" */ './IconCertificate')
  ),
  chat: lazy(() => import(/* webpackChunkName: "icon-chat" */ './IconChat')),
  circleCross: lazy(
    () => import(/* webpackChunkName: "icon-circleCross" */ './IconCircleCross')
  ),
  circleExclamation: lazy(
    () =>
      import(
        /* webpackChunkName: "icon-circleExclamation" */ './IconCircleExclamation'
      )
  ),
  circleHealth: IconCircleHealth,
  circleInfo: IconCircleInfo,
  circlePercent: IconCirclePercent,
  circlePlay: lazy(
    () => import(/* webpackChunkName: "icon-circlePlay" */ './IconCirclePlay')
  ),
  circleStarFilled: lazy(
    () =>
      import(
        /* webpackChunkName: "icon-circleStarFilled" */ './IconCircleStarFilled'
      )
  ),
  circleStar: lazy(
    () => import(/* webpackChunkName: "icon-circleStar" */ './IconCircleStar')
  ),
  circleTick: lazy(
    () => import(/* webpackChunkName: "icon-circleTick" */ './IconCircleTick')
  ),
  clock: lazy(() => import(/* webpackChunkName: "icon-clock" */ './IconClock')),
  copy: lazy(() => import(/* webpackChunkName: "icon-copy" */ './IconCopy')),
  cosmetic: lazy(
    () => import(/* webpackChunkName: "icon-cosmetic" */ './IconCosmetic')
  ),
  cross: IconCross,
  delivery: lazy(
    () => import(/* webpackChunkName: "icon-delivery" */ './IconDelivery')
  ),
  dispenser: lazy(
    () => import(/* webpackChunkName: "icon-dispenser" */ './IconDispenser')
  ),
  documentAdd: lazy(
    () => import(/* webpackChunkName: "icon-documentAdd" */ './IconDocumentAdd')
  ),
  documentChecklist: lazy(
    () =>
      import(
        /* webpackChunkName: "icon-documentChecklist" */ './IconDocumentChecklist'
      )
  ),
  documentInsert: lazy(
    () =>
      import(
        /* webpackChunkName: "icon-documentInsert" */ './IconDocumentInsert'
      )
  ),
  document: lazy(
    () => import(/* webpackChunkName: "icon-document" */ './IconDocument')
  ),
  documentMultimedia: lazy(
    () =>
      import(
        /* webpackChunkName: "icon-documentMultimedia" */ './IconDocumentMultimedia'
      )
  ),
  download: lazy(
    () => import(/* webpackChunkName: "icon-download" */ './IconDownload')
  ),
  emailArrow: lazy(
    () => import(/* webpackChunkName: "icon-emailArrow" */ './IconEmailArrow')
  ),
  email: lazy(() => import(/* webpackChunkName: "icon-email" */ './IconEmail')),
  equals: lazy(
    () => import(/* webpackChunkName: "icon-equals" */ './IconEquals')
  ),
  euro: lazy(() => import(/* webpackChunkName: "icon-euro" */ './IconEuro')),
  eyeCross: IconEyeCross,
  eye: IconEye,
  eRxPhone: IconERxPhone,
  facebook: lazy(
    () => import(/* webpackChunkName: "icon-facebook" */ './IconFacebook')
  ),
  gift: lazy(() => import(/* webpackChunkName: "icon-gift" */ './IconGift')),
  hamburger: lazy(
    () => import(/* webpackChunkName: "icon-hamburger" */ './IconHamburger')
  ),
  healthCross: lazy(
    () => import(/* webpackChunkName: "icon-healthCross" */ './IconHealthCross')
  ),
  heartFilled: lazy(
    () => import(/* webpackChunkName: "icon-heartFilled" */ './IconHeartFilled')
  ),
  heart: lazy(() => import(/* webpackChunkName: "icon-heart" */ './IconHeart')),
  home: lazy(() => import(/* webpackChunkName: "icon-home" */ './IconHome')),
  homeTo: lazy(
    () => import(/* webpackChunkName: "icon-homeTo" */ './IconHomeTo')
  ),
  leaf: lazy(() => import(/* webpackChunkName: "icon-leaf" */ './IconLeaf')),
  locationHome: lazy(
    () =>
      import(/* webpackChunkName: "icon-locationHome" */ './IconLocationHome')
  ),
  location: lazy(
    () => import(/* webpackChunkName: "icon-location" */ './IconLocation')
  ),
  locationMap: lazy(
    () => import(/* webpackChunkName: "icon-locationMap" */ './IconLocationMap')
  ),
  lock: lazy(() => import(/* webpackChunkName: "icon-lock" */ './IconLock')),
  mail: lazy(() => import(/* webpackChunkName: "icon-mail" */ './IconMail')),
  mask: lazy(() => import(/* webpackChunkName: "icon-mask" */ './IconMask')),
  message: lazy(
    () => import(/* webpackChunkName: "icon-message" */ './IconMessage')
  ),
  minus: lazy(() => import(/* webpackChunkName: "icon-minus" */ './IconMinus')),
  new: IconNew,
  notification: lazy(
    () =>
      import(/* webpackChunkName: "icon-notification" */ './IconNotification')
  ),
  ods: IconOds,
  packageList: lazy(
    () => import(/* webpackChunkName: "icon-packageList" */ './IconPackageList')
  ),
  packagePrice: lazy(
    () =>
      import(/* webpackChunkName: "icon-packagePrice" */ './IconPackagePrice')
  ),
  paw: lazy(() => import(/* webpackChunkName: "icon-paw" */ './IconPaw')),
  pdf: lazy(() => import(/* webpackChunkName: "icon-pdf" */ './IconPdf')),
  pencil: lazy(
    () => import(/* webpackChunkName: "icon-pencil" */ './IconPencil')
  ),
  piggyBank: lazy(
    () => import(/* webpackChunkName: "icon-piggyBank" */ './IconPiggyBank')
  ),
  pills: lazy(() => import(/* webpackChunkName: "icon-pills" */ './IconPills')),
  pinterest: lazy(
    () => import(/* webpackChunkName: "icon-pinterest" */ './IconPinterest')
  ),
  plus: lazy(() => import(/* webpackChunkName: "icon-plus" */ './IconPlus')),
  prescriptionBonus: lazy(
    () =>
      import(
        /* webpackChunkName: "icon-prescriptionBonus" */ './IconPrescriptionBonus'
      )
  ),
  printer: lazy(
    () => import(/* webpackChunkName: "icon-printer" */ './IconPrinter')
  ),
  prize: lazy(() => import(/* webpackChunkName: "icon-prize" */ './IconPrize')),
  profile: lazy(
    () => import(/* webpackChunkName: "icon-profile" */ './IconProfile')
  ),
  pzn: lazy(() => import(/* webpackChunkName: "icon-pzn" */ './IconPzn')),
  redeemRecipe: IconRedeemRecipe,
  redpoints: IconRedpoints,
  refresh: IconRefresh,
  rxVoucher: lazy(
    () => import(/* webpackChunkName: "icon-rxVoucher" */ './IconRxVoucher')
  ),
  safety: lazy(
    () => import(/* webpackChunkName: "icon-safety" */ './IconSafety')
  ),
  search: IconSearch,
  sendEnvelope: IconSendEnvelope,
  shareAlt: lazy(
    () => import(/* webpackChunkName: "icon-shareAlt" */ './IconShareAlt')
  ),
  share: lazy(() => import(/* webpackChunkName: "icon-share" */ './IconShare')),
  smartphone: lazy(
    () => import(/* webpackChunkName: "icon-smartphone" */ './IconSmartphone')
  ),
  smiley: lazy(
    () => import(/* webpackChunkName: "icon-smiley" */ './IconSmiley')
  ),
  starEmpty: IconStarEmpty,
  starFilled: IconStarFilled,
  sustainability: lazy(
    () =>
      import(
        /* webpackChunkName: "icon-sustainability" */ './IconSustainability'
      )
  ),
  target: lazy(
    () => import(/* webpackChunkName: "icon-target" */ './IconTarget')
  ),
  telephone: lazy(
    () => import(/* webpackChunkName: "icon-telephone" */ './IconTelephone')
  ),
  thumbsUp: lazy(
    () => import(/* webpackChunkName: "icon-thumbsUp" */ './IconThumbsUp')
  ),
  tick: IconTick,
  twitter: lazy(
    () => import(/* webpackChunkName: "icon-twitter" */ './IconTwitter')
  ),
  whatsapp: lazy(
    () => import(/* webpackChunkName: "icon-whatsapp" */ './IconWhatsapp')
  ),
  youtube: lazy(
    () => import(/* webpackChunkName: "icon-youtube" */ './IconYoutube')
  ),
  nowPin: lazy(
    () => import(/* webpackChunkName: "icon-nowPin" */ './IconNowPin')
  ),
}
