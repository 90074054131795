import { Model } from 'shared/services/api/models/Model'

// @ts-ignore
import { type NotepadProductOfferSellerV1 } from './notepadResponseTypes'

export class NotepadProductOfferSeller extends Model {
  public displayName: string
  public sellerGrade: number
  public sellerId: string
  public sellerRatingCount: number

  constructor(src: NotepadProductOfferSellerV1) {
    super(src)
    this.displayName = src.displayName
    this.sellerGrade = src.sellerGrade
    this.sellerRatingCount = src.sellerRatingCount
    this.sellerId = src.sellerId
  }
}
