// @ts-nocheck
import { AxiosResponse } from 'axios'
import type { Dispatch } from 'redux'

import * as CHECKOUT from 'shared/consts/checkout'
import { DOCUMENT_TYPE } from 'shared/consts/checkout'
import { SubOrder } from 'shared/store-checkout/types/orderDraft'
import { IState } from 'shared/store-checkout/types/redux'
import {
  SelectedShippingOption,
  ShippingOption,
} from 'shared/store-checkout/types/shipment'
import { isEmpty } from 'shared/utils/objectUtils'
import { ShippingControllerResponseV1, ShippingItem } from 'types/api/shipping'
import buildClientAPI from 'views/providers/clientAPI'

import {
  addCheckoutErrorAction,
  getOrderDraftId,
  getSubOrder,
  getSubOrders,
} from './common'
import { SET_SHIPPING_OPTION_INFO, updateOrderDraftAction } from './orderDraft'

// ---------------------------- ACTION TYPES -------------------------

// use typeof instead otherwise reducer can't infer
export const FETCH_SHIPPING_OPTIONS_REQUEST = 'FETCH_SHIPPING_OPTIONS_REQUEST'
export const FETCH_SHIPPING_OPTIONS_SUCCESS = 'FETCH_SHIPPING_OPTIONS_SUCCESS'

// ---------------------------- SELECTORS -------------------------

export const getTotalAmount = (state: IState): number => {
  return state?.checkout?.orderDraft?.orderMasterData?.totalAmount
}

export const getTotalAmountDocuments = (state: IState): number => {
  try {
    return parseFloat(
      state.checkout.orderDraft.orderMasterData.totalAmountDocuments
    )
  } catch {
    return 0
  }
}
export const getTotalAmountDiscounts = (state: IState): number => {
  return state?.checkout?.orderDraft?.orderMasterData?.totalAmountDiscounts || 0
}

export const getSelectedTotalShippingCosts = (state: IState): number => {
  try {
    const {
      checkout: { selectedShippingOptions },
    } = state

    const shippingSelections = Object.values(selectedShippingOptions)

    let shippingCostsTotal = 0
    shippingSelections.forEach(subOrder => {
      shippingCostsTotal += parseFloat(subOrder.costs)
    })

    return shippingCostsTotal || 0
  } catch {
    return 0
  }
}

// not used?
export const getTotalShippingCostsByCarrier = (state: IState): number => {
  try {
    const subOrders = state?.checkout?.orderDraft?.subOrders || []
    let shippingCostsTotal = 0

    subOrders.forEach(seller => {
      shippingCostsTotal += seller.carrier.costs
    })

    if (Number.isNaN(shippingCostsTotal)) {
      shippingCostsTotal =
        getTotalAmount(state) - getTotalAmountDocuments(state)
    }

    return shippingCostsTotal || 0
  } catch {
    return 0
  }
}

export const getShippingOptions = (state: IState): ShippingOption[] => {
  try {
    const {
      checkout: { shippingOptions },
    } = state

    return shippingOptions || []
  } catch (e) {
    return []
  }
}

export const getSelectedShippingOptions = (
  state: IState
): SelectedShippingOption[] => {
  try {
    const {
      checkout: { selectedShippingOptions },
    } = state

    return selectedShippingOptions || []
  } catch (e) {
    return []
  }
}

// TODO: check if the returns are correct here, can be string or Carrier
export const getSelectedShippingOptionId = (
  state: IState,
  subOrderId: string
): string => {
  try {
    const subOrder: SubOrder = getSubOrder(state, subOrderId)
    const { carrier } = subOrder
    const {
      checkout: { selectedShippingOptions },
    } = state

    if (!isEmpty(carrier)) {
      return carrier.identifier
    } else if (
      !isEmpty(selectedShippingOptions) &&
      selectedShippingOptions[subOrderId]
    ) {
      return selectedShippingOptions[subOrderId].identifier
    }
    return ''
  } catch (e) {
    return ''
  }
}

export const hasMarketPlaceOrders = (state: IState): boolean => {
  try {
    const subOrders = getSubOrders(state)

    return subOrders.some(
      subOrder => subOrder.sellerId !== CHECKOUT.SA_SELLER_ID
    )
  } catch (e) {
    return false
  }
}

export const hasPrescriptionOrders = (state: IState): boolean => {
  try {
    const subOrders = getSubOrders(state)

    return subOrders.some(subOrder =>
      subOrder.orderDocuments.some(({ type }) =>
        [
          DOCUMENT_TYPE.PRIVATE_PRESCRIPTION,
          DOCUMENT_TYPE.FREE_PRESCRIPTION,
          DOCUMENT_TYPE.INSURANCE_PRESCRIPTION,
        ].includes(type)
      )
    )
  } catch (e) {
    return false
  }
}

export const getTotalShippingCosts = (state: IState): number =>
  state?.checkout?.orderDraft?.orderMasterData?.totalAmountShippingCost || 0

// ---------------------------- ACTIONS -------------------------

export const fetchShippingOptionsSuccessAction = (
  shippingOptions: ShippingItem[]
) => {
  return {
    type: FETCH_SHIPPING_OPTIONS_SUCCESS,
    shippingOptions,
  }
}

export const fetchShippingOptionsRequest = () => {
  return {
    type: FETCH_SHIPPING_OPTIONS_REQUEST,
  }
}

// just for redux dev-tools, does not reduce
export const setShippingOptionSuccessAction = ({
  subOrderId,
  shippingOptionId,
  shippingCosts,
  displayName,
}: {
  subOrderId: string
  shippingOptionId: string
  shippingCosts: string
  displayName: string
}) => {
  return {
    type: SET_SHIPPING_OPTION_INFO,
    subOrderId,
    shippingOptionId,
    shippingCosts,
    displayName,
  }
}

// ---------------------------- THUNKS -------------------------

export const setShippingOptionAction = ({
  subOrderId,
  shippingOptionId,
  shippingCosts,
}: {
  subOrderId: string
  shippingOptionId: string
  shippingCosts: string
}): ((dispatch: Dispatch, getState: () => IState) => Promise<void>) => {
  return async (dispatch: Dispatch, getState: () => IState) => {
    try {
      const { checkoutService } = buildClientAPI()
      const orderDraftId: string = getOrderDraftId(getState())
      const response = await checkoutService.updateShippingOption({
        orderDraftId,
        subOrderId,
        shippingOptionId,
        shippingCosts,
      })
      const { data: orderDraft } = response
      dispatch(
        setShippingOptionSuccessAction({
          subOrderId,
          shippingOptionId,
          shippingCosts,
        })
      )
      dispatch(updateOrderDraftAction(orderDraft, 'setShippingOptionAction'))
    } catch (e) {
      dispatch(addCheckoutErrorAction(`setShippingOptionAction: ${e.message}`))
    }
  }
}
export const fetchShippingOptionsAction = (): ((
  dispatch: Dispatch,
  getState: () => IState
) => Promise<void>) => {
  return async (dispatch: Dispatch, getState: () => IState) => {
    dispatch(fetchShippingOptionsRequest())
    try {
      const orderDraftId: string = getOrderDraftId(getState())
      const { shippingService } = buildClientAPI()
      const response: AxiosResponse<ShippingControllerResponseV1> =
        await shippingService.fetchShippingOptionsByOrderDraftId(orderDraftId)
      const {
        data: { carriers: shippingOptions },
      } = response
      dispatch(fetchShippingOptionsSuccessAction(shippingOptions))
    } catch (e) {
      dispatch(
        addCheckoutErrorAction(`fetchShippingOptionsAction: ${e.message}`)
      )
    }
  }
}
