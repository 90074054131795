// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

import { NotepadProductOfferPriceAdditionalInfoDiscount } from './notepadProductOfferDiscount'
import {
  NotepadProductOfferAdditionalInfoV1,
  type Redpoint,
} from './notepadResponseTypes'

export class NotepadProductOfferPriceAdditionalInfo extends Model {
  public netPrice: number
  public discount: NotepadProductOfferPriceAdditionalInfoDiscount
  public redPoints: Redpoint

  constructor(src: NotepadProductOfferAdditionalInfoV1) {
    super(src)
    this.netPrice = src.netPrice
    // Some products have no discount in QS, breaking the page. This prevents it.
    this.discount = new NotepadProductOfferPriceAdditionalInfoDiscount(
      src.discount ? src.discount : { percentage: 0 }
    )
    this.redPoints = src.redPoints
  }
}
