// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

import { OrderDocumentPosition } from './orderDocumentPosition'
import {
  OrderDocumentPositionV1,
  OrderDocumentsV1,
} from './ordersResponseTypes'

export class OrderDocuments extends Model {
  public readonly type: string
  public readonly documentPositions: OrderDocumentPosition[]

  constructor(src: OrderDocumentsV1) {
    super(src)
    this.type = src.type
    this.documentPositions = Array.isArray(src.documentPositions)
      ? src.documentPositions.map(
          (documentPosition: OrderDocumentPositionV1) =>
            new OrderDocumentPosition(documentPosition)
        )
      : []
  }
}
