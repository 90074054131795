import {
  CSW_ARROW_CLICK,
  CSW_CLICK_ADD_TO_CART,
  CSW_PRODUCT_CLICK,
  CSW_SHOW_ALL_CLICK,
  CSW_SHOW_MORE_CLICK,
} from 'shared/experiments/consts/events'
import { event as e } from 'shared/experiments/utils/event'
import trackEvent from 'shared/experiments/utils/trackOptimizelyEvent'

interface TrackCswSliderProps {
  shouldTrackWithWidgetIds?: boolean
  shouldTrackArrowClick?: boolean
  shouldTrackShowMoreClick?: boolean
  shouldTrackAddToCartClick?: boolean
  shouldTrackShowAllClick?: boolean
}

export const trackCswSlider = ({
  shouldTrackWithWidgetIds,
  shouldTrackArrowClick,
  shouldTrackShowMoreClick,
  shouldTrackAddToCartClick,
  shouldTrackShowAllClick,
}: TrackCswSliderProps): void => {
  const xSellWidgets = document.querySelectorAll(
    '[data-clientside-api-call~="fetchCrossSell"]'
  )

  const cswTrackingEventListener = (event: Event) => {
    const target = <HTMLElement>event.target
    const crossellWidget = target.closest('.crosssell-widget')
    let crosssellId: string | null = ''

    if (crossellWidget) {
      crosssellId = crossellWidget?.getAttribute('data-crosssell-id')
    }

    if (
      target.closest(
        '[data-clientside-hook~="slider__item"] .o-ProductSliderItem__main'
      )
    ) {
      let TRACK_CSW_PRODUCT_CLICK_NAME = `${CSW_PRODUCT_CLICK}`
      if (shouldTrackWithWidgetIds) {
        TRACK_CSW_PRODUCT_CLICK_NAME = `${CSW_PRODUCT_CLICK} ${crosssellId}`
        trackEvent(e`${TRACK_CSW_PRODUCT_CLICK_NAME}`)
      } else {
        trackEvent(e`${TRACK_CSW_PRODUCT_CLICK_NAME}`)
      }
    }

    if (shouldTrackArrowClick) {
      if (target.closest('[data-clientside-hook~="adaptable__arrows"]')) {
        let TRACK_ARROW_EVENT_NAME = `${CSW_ARROW_CLICK}`
        if (shouldTrackWithWidgetIds) {
          TRACK_ARROW_EVENT_NAME = `${CSW_ARROW_CLICK} ${crosssellId}`
          trackEvent(e`${TRACK_ARROW_EVENT_NAME}`)
        } else {
          trackEvent(e`${TRACK_ARROW_EVENT_NAME}`)
        }
      }
    }
    if (shouldTrackShowMoreClick) {
      if (
        target.closest('[data-clientside-hook~="showMoreButton"]') ||
        target.closest('[data-qa-id~="form-show-more-products-button"]')
      ) {
        let TRACK_SHOW_MORE_BUTTON_NAME = `${CSW_SHOW_MORE_CLICK}`
        if (shouldTrackWithWidgetIds) {
          TRACK_SHOW_MORE_BUTTON_NAME = `${CSW_SHOW_MORE_CLICK} ${crosssellId}`
          trackEvent(e`${TRACK_SHOW_MORE_BUTTON_NAME}`)
        } else {
          trackEvent(e`${TRACK_SHOW_MORE_BUTTON_NAME}`)
        }
      }
    }
    if (shouldTrackAddToCartClick) {
      if (target.closest('.o-ProductSliderItem__add-to-cart')) {
        let TRACK_ADD_TO_CART_NAME = `${CSW_CLICK_ADD_TO_CART}`
        if (shouldTrackWithWidgetIds) {
          TRACK_ADD_TO_CART_NAME = `${CSW_CLICK_ADD_TO_CART} ${crosssellId}`
          trackEvent(e`${TRACK_ADD_TO_CART_NAME}`)
        } else {
          trackEvent(e`${TRACK_ADD_TO_CART_NAME}`)
        }
      }
    }
    if (shouldTrackShowAllClick) {
      if (target.closest('[data-clientside-hook~="slider__header"] a')) {
        let TRACK_SHOW_ALL_NAME = `${CSW_SHOW_ALL_CLICK}`
        if (shouldTrackWithWidgetIds) {
          TRACK_SHOW_ALL_NAME = `${CSW_SHOW_ALL_CLICK} ${crosssellId}`
          trackEvent(e`${TRACK_SHOW_ALL_NAME}`)
        } else {
          trackEvent(e`${TRACK_SHOW_ALL_NAME}`)
        }
      }
    }
  }

  xSellWidgets?.forEach(xSellWidget => {
    xSellWidget?.removeEventListener('click', cswTrackingEventListener)
    xSellWidget?.addEventListener('click', cswTrackingEventListener)
  })
}
