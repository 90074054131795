import { subscribe } from 'shared/store'
import { CLOSE_OFFCANVAS } from 'shared/store/ducks/events'
import { BREAKPOINT_SMALL_TINY } from 'views/consts'
import toggleBoolStringAttr from 'views/utils/toggleBoolStringAttribute'

import {
  ARIA_HIDDEN,
  LAYOUT_SELECTOR,
  NO_OVERFLOW_CLASS,
  OVERLAY_SELECTOR,
} from './consts'

export default () => {
  const layout = document.querySelector(LAYOUT_SELECTOR)
  const menuButtons = [
    ...document.querySelectorAll(
      '[data-clientside-hook~="menu-button-slideover"]'
    ),
  ]
  const menu = document.querySelector(
    '[data-clientside-hook~="menu-slideover"]'
  )
  const overlay = document.querySelector(OVERLAY_SELECTOR)

  const isVisible = () => menu?.getAttribute(ARIA_HIDDEN) !== 'true'

  const onOverlayClickHandler = event => {
    if (menu && !menu.contains(event.target) && isVisible()) {
      hide()
    }
  }

  const hide = (element = null) => {
    if (layout) {
      document.body.classList.remove(NO_OVERFLOW_CLASS)
    }

    if (menu) {
      toggleBoolStringAttr(menu, ARIA_HIDDEN, true)
    }

    if (overlay) {
      toggleBoolStringAttr(overlay, ARIA_HIDDEN, true)
      overlay.removeEventListener('click', onOverlayClickHandler)
    }

    element?.focus()
  }

  const show = () => {
    document.body.classList.add(NO_OVERFLOW_CLASS)

    if (menu) {
      toggleBoolStringAttr(menu, ARIA_HIDDEN, false)
      menu.focus()
    }

    if (overlay) {
      toggleBoolStringAttr(overlay, ARIA_HIDDEN, false)
      overlay.addEventListener('click', onOverlayClickHandler)
    }
  }

  const menuMediaQuery = window.matchMedia(BREAKPOINT_SMALL_TINY)
  menuMediaQuery.addEventListener('change', () => {
    menuMediaQuery.matches && hide()
  })

  subscribe.after(CLOSE_OFFCANVAS, () => {
    hide()
  })

  return {
    buttons: menuButtons
      .map(menuButton => {
        menuButton.addEventListener('click', event => {
          event.preventDefault()
          ;(isVisible() ? hide : show)(menuButton)
        })

        return menuButton
      })
      .filter(Boolean),
    menu,
    layout,
    consts: {
      MENU_VISIBILTY_ATTRIBUTE: ARIA_HIDDEN,
      BODY_NO_OVERLFOW_CLASS: NO_OVERFLOW_CLASS,
      BREAKPOINT_SMALL_TINY,
    },
    isVisible,
    show,
  }
}
