import { type ServiceParameters } from 'shared/services/api/commonTypes'

import BackendAPIService from './BackendAPIService'
import type { VatValidationResponseV1 } from './models/vatValidation/vatValidation'

interface IVatValidationService {
  getVatValidationURL: (tenant: string, vatNumber: string) => string
  validateVatNumber: (
    tenant: string,
    vatNumber: string
  ) => Promise<VatValidationResponseV1>
}

class VatValidationService
  extends BackendAPIService
  implements IVatValidationService
{
  constructor(params: ServiceParameters) {
    super({ apiVersion: 'v1', ...params })
  }

  public getVatValidationURL(tenant: string, vatNumber: string): string {
    return `/vat/${this.apiVersion}/${tenant}/validate?vatNumber=${vatNumber}`
  }

  async validateVatNumber(
    tenant: string,
    vatNumber: string
  ): Promise<VatValidationResponseV1> {
    const { data }: { data: VatValidationResponseV1 } = await this.get(
      this.getVatValidationURL(tenant, vatNumber),
      {
        headers: {
          'Accept-Language': 'de-DE',
        },
      }
    )
    return data
  }
}

export default VatValidationService
