const TEST_SELENIUM_IP = '89.1.87.140'
const SEVENUM_IP = '89.20.86.35'
const VERKSTEDT_VPN = '78.46.163.49'
const FARMALINE_LODE_FASTRE_HOME = '84.193.231.219'
const FARMALINE_KRISTOF_TORFS_HOME = '81.82.197.76'
const FARMALINE_TONGEREN_OFFICE = [
  '81.246.30.97',
  '81.246.30.98',
  '81.246.30.99',
  '81.246.30.100',
  '81.246.30.101',
  '81.246.30.102',
]

export const ALLOWED_IPS = [
  TEST_SELENIUM_IP,
  SEVENUM_IP,
  VERKSTEDT_VPN,
  FARMALINE_LODE_FASTRE_HOME,
  FARMALINE_KRISTOF_TORFS_HOME,
].concat(FARMALINE_TONGEREN_OFFICE)
