import {
  ABO_LABEL,
  PAGE_TYPE_CATEGORY,
  PAGE_TYPE_SEARCH_RESULTS,
} from 'shared/consts'
import { DISJUNCTIVE_FACETS } from 'shared/consts/algolia'
import { isMobile } from 'shared/utils/deviceClassUtils'
import type { PageType } from 'types/cmsTypes'
import {
  type DisjunctiveFacet,
  type DisplayableFacetName,
  type SearchResults,
  type SearchState,
} from 'types/search'
import type { DeviceClass } from 'types/shopConfig'

import {
  CUSTOMLY_HANDLED_FILTERS,
  EMPTY_SEARCH_RESULTS,
  HIDDEN_FILTERS,
  PAGE_SEARCHING_STATE_EMERGENCY_TIMEOUT,
  PAGE_SEARCHING_STATE_TIMEOUT,
} from './consts'

export const shouldDisplayShippingOptions = (
  searchResults: SearchResults = EMPTY_SEARCH_RESULTS
): boolean => {
  const filters = searchResults?.disjunctiveFacets || []

  // check if there are any products that have `NOW` label (nowProductGroup)
  const nowProductGroup = filters.find(({ name }) => name === 'nowProductGroup')
  const hasNowOption = Boolean(nowProductGroup?.data?.sameday_nextday)

  return hasNowOption
}

export const shouldDisplayRatingsFilter = (
  searchResults: SearchResults = EMPTY_SEARCH_RESULTS
): boolean => {
  const filters = searchResults?.disjunctiveFacets || []

  const ratingsData = filters.find(({ name }) => name === 'averageRating')
  const { max } = ratingsData?.stats || {}

  return max > 0
}

/**
 * Check, whether the filterAttributes has something else than ignored options
 */
const hasFilterAttributes = (searchResults: SearchResults): boolean => {
  const filters = searchResults?.disjunctiveFacets || []
  const filterAttributesData =
    filters.find(({ name }) => name === 'filterAttributes')?.data || {}
  const options = Object.keys(filterAttributesData)

  return Boolean(options.filter(option => option !== ABO_LABEL).length)
}

export const normalizeFilters = (
  filters: DisjunctiveFacet[]
): DisjunctiveFacet[] => {
  return filters.filter(({ name }) => !CUSTOMLY_HANDLED_FILTERS.includes(name))
}

export const getRefinedShippingOptionsCount = (
  searchState: SearchState = {}
): number => {
  const { toggle } = searchState

  return [toggle?.filterAttributes, toggle?.nowProductGroup].filter(Boolean)
    .length
}

export const isCategoriesFilterRefined = (
  searchState: SearchState = {}
): boolean => {
  const { menu = {} } = searchState

  return (
    Boolean(menu) && Object.entries(menu).some(([, value]) => Boolean(value))
  )
}

export const getFilters = (
  searchResults: SearchResults = EMPTY_SEARCH_RESULTS
): DisjunctiveFacet[] => {
  const filters = searchResults?.disjunctiveFacets || []
  const prescriptionMedicineIndex = filters.findIndex(
    ({ name }) => name === 'prescriptionMedicine'
  )

  if (
    prescriptionMedicineIndex !== -1 &&
    Object.keys(filters[prescriptionMedicineIndex].data).length !== 2
  ) {
    // prescriptionMedicine filter is displayed only when there are 2 options (true/false)
    filters.splice(prescriptionMedicineIndex, 1)
  }

  return filters
}

export const getDisplayedFiltersNames = (
  searchResults: SearchResults = EMPTY_SEARCH_RESULTS
): Set<DisplayableFacetName> => {
  const filters = getFilters(searchResults)
  const names: DisplayableFacetName[] = normalizeFilters(filters)
    .filter(({ name }) => DISJUNCTIVE_FACETS.includes(name))
    .map(item => item.name)
    // Added manually: it is not present in the facets
    .concat('averageRating')

  const extra: DisplayableFacetName[] = []
  if (hasFilterAttributes(searchResults)) {
    extra.push('filterAttributes')
  }
  if (shouldDisplayShippingOptions(searchResults)) {
    extra.push('shippingOptions')
  }

  return new Set(names.concat(extra))
}

/**
 * Check if there are any filters on the filter panel that are usually hidden
 */
export const hasActiveHiddenFilters = (
  refinementList: Partial<Record<DisplayableFacetName, unknown>> = {}
): boolean =>
  Object.keys(refinementList).some((filterName: string) =>
    HIDDEN_FILTERS.includes(<DisplayableFacetName>filterName)
  )

type HandleSearchingStateInDomArguments = { isSearching: boolean }
type HandleSearchingStateFunction = (
  args: HandleSearchingStateInDomArguments
) => void

/**
 * The util uses IIFE to lock the searchingResetTimeout in the private scope
 */
export const handleSearchingStateInDom: HandleSearchingStateFunction = (() => {
  let searchingResetTimeout: ReturnType<typeof setTimeout>

  const lock = () => {
    document.body.style.cursor = 'wait'
  }
  const reset = () => {
    document.body.style.cursor = 'default'
  }

  const funcWithContext: HandleSearchingStateFunction = ({
    isSearching,
  }: HandleSearchingStateInDomArguments) => {
    clearTimeout(searchingResetTimeout)

    if (isSearching) {
      lock()
    }

    // Smoothly switch off the searching state
    // or disable it forcefully after a long time if something is broken
    searchingResetTimeout = setTimeout(
      reset,
      isSearching
        ? PAGE_SEARCHING_STATE_EMERGENCY_TIMEOUT
        : PAGE_SEARCHING_STATE_TIMEOUT
    )
  }

  return funcWithContext
})()

/**
 * A minimal delay added because an immediate callback (`refine`) call freezes the UI
 */
export const withSearchStateHandler = (callback: CallableFunction): number => {
  handleSearchingStateInDom({ isSearching: true })

  return window.setTimeout(() => callback(), 200)
}

/**
 * Get a value which is always within a given [min, max] range
 */
export const getMiddleValue = (
  min: number,
  value: number,
  max: number
): number => {
  return [min, value, max].sort((a, b) => a - b)[1]
}

export const shouldRenderNewLayout = ({
  deviceClass,
  pageType,
}: {
  deviceClass: DeviceClass
  pageType: PageType
}) =>
  !isMobile(deviceClass) &&
  (pageType === PAGE_TYPE_SEARCH_RESULTS || pageType === PAGE_TYPE_CATEGORY)
