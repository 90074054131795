import { SUBSTITUTION_STATUS } from 'shared/consts'
import { Model } from 'shared/services/api/models/Model'
import { getPlaceholderImage } from 'shared/utils/imageUtils'
import type { PrescriptionProductV1 } from 'types/api/prescription'

import type {
  ActiveSubstance,
  MedicationType,
  OrderDocumentPositionERxV1,
} from './ordersResponseTypes'

export class OrderDocumentPositionERx extends Model {
  public readonly availability: string
  public readonly lineNum: number
  public readonly pzn?: string
  public readonly itemName: string
  public readonly quantity: number
  public readonly salesPrice: number
  public readonly lineAmount: number
  public readonly packagingQuantity: number
  public readonly pharmaForm: string
  public readonly thumbnailURL: string
  public readonly medicationType: MedicationType
  public readonly packagingUnit: string
  public readonly prescriptionIssuedTo: string
  public readonly prescriptionDate: string
  public readonly hashedToken: string
  public readonly copaymentFree: boolean
  public readonly isProductOnRecord: boolean
  public readonly activeSubstance?: ActiveSubstance
  public readonly substitutionStatus?: SUBSTITUTION_STATUS
  public readonly originalPrescriptionProduct?: PrescriptionProductV1
  public readonly originalPrescriptionPzn?: string
  public readonly subtitle?: string
  public readonly prescriptionBonus?: number

  constructor(src: OrderDocumentPositionERxV1) {
    super(src)
    this.availability = src.availability
    this.lineNum = src.lineNum
    this.pzn = src.pzn
    this.itemName = src.itemName
    this.quantity = src.quantity
    this.salesPrice = src.salesPrice
    // TODO: Remove the percentage rounding when it is done by the service
    this.lineAmount = src.lineAmount
    this.packagingUnit = src.packagingUnit
    this.packagingQuantity = src.packagingQuantity
    this.pharmaForm = src.pharmaForm
    this.thumbnailURL = getPlaceholderImage(true)
    this.medicationType = src.medicationType
    this.prescriptionIssuedTo = src.prescriptionIssuedTo
    this.prescriptionDate = src.prescriptionDate
    this.hashedToken = src.hashedToken
    this.copaymentFree = src.copaymentFree
    this.isProductOnRecord = src.isProductOnRecord
    this.activeSubstance = src.activeSubstance
    this.substitutionStatus = src.substitutionStatus
    this.originalPrescriptionProduct = src.originalPrescriptionProduct
    this.originalPrescriptionPzn = src.originalPrescriptionPzn
    this.prescriptionBonus = src.prescriptionBonus
  }
}
