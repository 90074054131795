// @ts-nocheck
import { LetterboxDocument } from 'shared/services/api/models/letterbox'
import { Model } from 'shared/services/api/models/Model'
import { OrderStatus } from 'views/components/organisms/OrderDetailsContent/OrderStatus/const'
import { isNowPickupOrder } from 'views/components/utils/isNowPickupOrder'

import { OrderBillingAddress } from './orderBillingAddress'
import { OrderCarrier } from './orderCarrier'
import { OrderDeliveryAddress } from './orderDeliveryAddress'
import { OrderDiscount } from './orderDiscount'
import { OrderDocuments } from './orderDocuments'
import { OrderDocumentsERx } from './orderDocumentsERx'
import { OrderNowDocumentV2 } from './orderNowDocument'
import { OrderPayment } from './orderPayment'
import {
  type Cancellation,
  OrderDiscountV1,
  OrderDocumentsERxV1,
  OrderDocumentsV1,
  type OrderResponseReturnLabel,
  SuborderV1,
} from './ordersResponseTypes'

const SELLER_SHOP_APOTHEKE = 'shop-apotheke.com'

export class Suborder extends Model {
  public readonly applicableCredit?: number
  public readonly billingAddress: OrderBillingAddress
  public readonly carrier: OrderCarrier
  public readonly deliveryAddress: OrderDeliveryAddress
  public readonly discounts: OrderDiscount[]
  public readonly isSellerShopApotheke: boolean
  public readonly documentsToDownload: LetterboxDocument[]
  public readonly nowDocumentsToDownload: OrderNowDocumentV2[]
  public readonly orderDocuments: OrderDocuments[]
  public readonly orderDocumentsERx?: OrderDocumentsERx[]
  public readonly orderType: string
  public readonly payment: OrderPayment
  public readonly seller: string
  public readonly sellerId: string
  public readonly sellerName: string
  public readonly shippedDate: string
  public readonly status: string
  public readonly subOrderId: string
  public readonly totalAmount: number
  public readonly totalAmountDocuments: number
  public readonly totalAmountPrescriptionBonus: number
  public readonly totalAmountRedpoints: number
  public readonly returnable: boolean
  public readonly returnLabel: OrderResponseReturnLabel
  public readonly cancellable: boolean
  public readonly cancellationStatus: string
  public readonly cancellations?: Cancellation[]
  public readonly subAmountWithLineDiscounts?: number

  constructor(src: SuborderV1) {
    super(src)
    this.applicableCredit = src.applicableCredit
    if (src.billingAddress) {
      this.billingAddress = new OrderBillingAddress(src.billingAddress)
    }
    if (src.carrier) {
      this.carrier = new OrderCarrier(src.carrier)
    }
    if (src.deliveryAddress) {
      this.deliveryAddress = new OrderDeliveryAddress(src.deliveryAddress)
    }
    this.discounts = Array.isArray(src.discounts)
      ? src.discounts.map(
          (discount: OrderDiscountV1) => new OrderDiscount(discount)
        )
      : []
    this.documentsToDownload = src.documentsToDownload
      ? src.documentsToDownload.map(document => new LetterboxDocument(document))
      : []
    if (src.nowDocumentsToDownload) {
      this.nowDocumentsToDownload = src.nowDocumentsToDownload
    }
    this.orderDocuments = Array.isArray(src.orderDocuments)
      ? src.orderDocuments.map(
          (orderDocument: OrderDocumentsV1) => new OrderDocuments(orderDocument)
        )
      : []
    this.orderDocumentsERx = Array.isArray(src.orderDocumentsERx)
      ? src.orderDocumentsERx.map(
          (orderDocumentERx: OrderDocumentsERxV1) =>
            new OrderDocumentsERx(orderDocumentERx)
        )
      : []
    this.orderType = src.orderType
    if (src.payment) {
      this.payment = new OrderPayment(src.payment)
    }
    this.sellerId = src.sellerId
    this.sellerName = src.sellerName
    this.shippedDate = src.shippedDate
    // TODO: remove when bully returns translation key instead of status:
    this.status = src.status.includes('account.orders.status')
      ? src.status
      : `account.orders.status.${src.status}`
    this.subOrderId = src.subOrderId
    this.totalAmount = src.totalAmount
    this.totalAmountDocuments = src.totalAmountDocuments
    this.totalAmountPrescriptionBonus = src.totalAmountPrescriptionBonus
    this.totalAmountRedpoints = src.totalAmountRedpoints
    this.subAmountWithLineDiscounts = src.subAmountWithLineDiscounts

    this.isSellerShopApotheke =
      this.sellerId === SELLER_SHOP_APOTHEKE ||
      this.sellerName === SELLER_SHOP_APOTHEKE
    this.seller = this.sellerName
    this.status = this.getStatus()
    this.returnable = src.returnable
    this.cancellable = src.cancellable
    this.cancellationStatus = src.cancellationStatus
    this.returnLabel = src.returnLabel
    this.cancellations = src.cancellations
  }

  getStatus(): string {
    if (isNowPickupOrder(this)) {
      if (this.status === OrderStatus.DISPATCHED) {
        return OrderStatus.READY_FOR_PICKUP
      } else if (this.status === OrderStatus.CLOSED) {
        return OrderStatus.COMPLETED
      }
    }

    return this.status
  }
}
