import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const PATH = `M70.29254,31.64465c0,0.63818-0.5174,1.15558-1.15552,1.15558c-0.63818,0-1.15558-0.5174-1.15558-1.15558
c0-0.63824,0.5174-1.15552,1.15558-1.15552C69.77515,30.48913,70.29254,31.0064,70.29254,31.64465z M62.96637,30.48913
c-0.63812,0-1.15552,0.51727-1.15552,1.15552c0,0.63818,0.5174,1.15558,1.15552,1.15558c0.63824,0,1.15558-0.5174,1.15558-1.15558
C64.12195,31.0064,63.60461,30.48913,62.96637,30.48913z M60.67737,50.37499h-6.12183c-0.32489,0-0.58826-0.26331-0.58826-0.5882
v-6.12177c0-0.32495-0.26337-0.58826-0.58826-0.58826h-6.66907c-0.32489,0-0.5882,0.26331-0.5882,0.58826v6.12177
c0,0.32489-0.26337,0.5882-0.58826,0.5882h-6.12231c-0.32483,0-0.58826,0.26343-0.58826,0.58826v6.66937
c0,0.32489,0.26343,0.5882,0.58826,0.5882h6.12231c0.32489,0,0.58826,0.26337,0.58826,0.58826v6.12183
c0,0.32483,0.26331,0.5882,0.5882,0.5882h6.66907c0.32489,0,0.58826-0.26337,0.58826-0.5882v-6.12183
c0-0.32489,0.26337-0.58826,0.58826-0.58826h6.12183c0.32483,0,0.5882-0.26331,0.5882-0.5882v-6.66937
C61.26556,50.63842,61.0022,50.37499,60.67737,50.37499z M100,49.78722c0,27.61426-22.38574,50-50,50c-27.6142,0-50-22.38574-50-50
s22.3858-50,50-50C77.61426-0.21278,100,22.17297,100,49.78722z M75.79803,37.40179c0-0.32483-0.26337-0.5882-0.5882-0.5882
H24.91553c-0.34521,0-0.625,0.27979-0.625,0.625v31.0271c0,1.94916,1.58014,3.52942,3.52936,3.52942h44.44873
c1.94928,0,3.52942-1.58026,3.52942-3.52942V37.40179z M75.79803,31.64489c0-1.94922-1.58014-3.52936-3.52942-3.52936H27.81988
c-1.94922,0-3.52936,1.58014-3.52936,3.52936v2.7511c0,0.32489,0.26337,0.58826,0.5882,0.58826h50.33112
c0.32483,0,0.5882-0.26337,0.5882-0.58826V31.64489z`

const IconOds: FC<IconProps> = props => (
  <SvgIcon {...props} path={PATH} viewBox="0 0 100 100" />
)

export default IconOds
