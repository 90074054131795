import classNames from 'clsx'
import { node } from 'prop-types'

import Button from 'views/components/atoms/Button'
import ButtonSecondary from 'views/components/molecules/ButtonSecondary'
import ButtonTransparent from 'views/components/molecules/ButtonTransparent'

import './ButtonWithIcon.scss'

const ButtonWithIcon = ({
  component,
  icon,
  children,
  inline,
  className,
  ...rest
}) => {
  let ButtonComponent

  switch (component) {
    case 'Button':
      ButtonComponent = Button
      break
    case 'ButtonSecondary':
      ButtonComponent = ButtonSecondary
      break
    case 'ButtonTransparent':
      ButtonComponent = ButtonTransparent
      break
    default:
      ButtonComponent = Button
  }

  const buttonClassNames = classNames('m-ButtonWithIcon', className, {
    'm-ButtonWithIcon--inline': inline,
  })

  return (
    <ButtonComponent {...rest} className={buttonClassNames}>
      {icon}
      <span className="u-visuallyhidden">{children}</span>
    </ButtonComponent>
  )
}

ButtonWithIcon.propTypes = {
  ...Button.propTypes,
  icon: node.isRequired,
  children: node,
}

ButtonWithIcon.defaultProps = {
  ...Button.defaultProps,
  children: null,
}

export default ButtonWithIcon
