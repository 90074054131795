import { IconCaretLeft as FigmaIconCaretLeft } from '@redteclab/icons'
import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const IconCaretLeft: FC<IconProps> = props => (
  <SvgIcon {...props} component={FigmaIconCaretLeft} />
)

export default IconCaretLeft
