import { PAGE_TYPE_SEARCH_RESULTS } from 'shared/consts'
import {
  REMOVE_PACKSIZE_AND_CTA_FROM_SERP,
  SERP_PRODUCT_DETAIL_CLICK,
} from 'shared/experiments/consts'
import {
  activateExperiment,
  getExperimentOnClient,
} from 'shared/experiments/utils/experiments'
import { getExperimentNamePerTenant } from 'shared/experiments/utils/tenantBasedExperimentsUtils'
import { bindDynamicEventsTracking } from 'shared/experiments/utils/tracking'
import store from 'shared/store'

export default () => {
  const { pageType } =
    store.getState()?.publicRuntimeConfig.pageProperties || {}

  const { tenant, deviceClass } =
    store.getState()?.publicRuntimeConfig.publicConfig || {}

  const removePacksizeAndCtaFromSerp = getExperimentNamePerTenant(
    REMOVE_PACKSIZE_AND_CTA_FROM_SERP,
    tenant,
    deviceClass
  )

  const { isEnabled } = getExperimentOnClient(removePacksizeAndCtaFromSerp)

  if (!isEnabled || pageType !== PAGE_TYPE_SEARCH_RESULTS) return

  const trackEvents = () => {
    bindDynamicEventsTracking([
      {
        parentElementSelector:
          '[data-clientside-hook~="algoliaSearchListContainer"]',
        selector:
          '.o-SearchProductListItem__image, .o-SearchProductListItem__content__header__top, .o-SearchProductListItem__selectableSection > a, .o-SearchProductListItem__content__body > a',
        trackingEvent: SERP_PRODUCT_DETAIL_CLICK,
      },
    ])
  }

  void activateExperiment(removePacksizeAndCtaFromSerp)
  trackEvents()
}
