// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

import { OrderBillingAddressV1 } from './ordersResponseTypes'

export class OrderBillingAddress extends Model {
  public readonly id: string
  public readonly street: string
  public readonly streetNo: string
  public readonly letterBox: string
  public readonly extension: string
  public readonly city: string
  public readonly zip: string
  public readonly countryCode: string
  public readonly doorCode: string
  public readonly type: string

  constructor(src: OrderBillingAddressV1) {
    super(src)
    this.city = src.city
    this.countryCode = src.countryCode
    this.doorCode = src.doorCode
    this.extension = src.extension
    this.id = src.id
    this.street = src.street
    this.streetNo = src.streetNo
    this.letterBox = src.letterBox
    this.type = src.type
    this.zip = src.zip
  }
}
