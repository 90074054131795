import {
  PAGE_TYPE_CATEGORY,
  PAGE_TYPE_HOMEPAGE,
  PAGE_TYPE_SEARCH_RESULTS,
  PAGES_WITH_NOW_GLOBAL_BAR,
} from 'shared/consts'
import {
  type ExperimentName,
  NOW_CATEGORY_GB_FUNCTIONALITY,
  NOW_CATEGORY_GLB_CLICK,
  NOW_CATEGORY_GLB_MODAL_SUBMIT,
  NOW_HOMEPAGE_GB_FUNCTIONALITY,
  NOW_HOMEPAGE_GLB_CLICK,
  NOW_HOMEPAGE_GLB_MODAL_SUBMIT,
  NOW_SERP_GB_FUNCTIONALITY,
  NOW_SERP_GLB_CLICK,
  NOW_SERP_GLB_MODAL_SUBMIT,
  type TrackingEventName,
} from 'shared/experiments/consts'
import { event } from 'shared/experiments/utils/event'
import { isExperimentActive } from 'shared/experiments/utils/experiments'
import { isObjKey } from 'shared/experiments/utils/tenantBasedExperimentsUtils'
import { type PageType } from 'types/cmsTypes'
import { type Experiment } from 'types/shopConfig'
import buildClientAPI from 'views/providers/clientAPI'

export type PagesWithNowGlobalBar = {
  [key: string]: string
}

const threeMinutesInMs = 3 * 60 * 1000
let asid: string | null = null
let expirationDate: Date | null = null

const fetchAsid = async () => {
  if (expirationDate && new Date().getTime() < expirationDate.getTime()) {
    return
  }

  try {
    asid = (await buildClientAPI().addressSuggestService.register()).data
      .success.asid

    expirationDate = new Date(new Date().getTime() + threeMinutesInMs)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Cannot register to AddressService: ', e)
  }
}

const fetchCityByZipCode: (zip: string) => Promise<string> = async (
  zip: string
) => {
  if (!zip) {
    return ''
  }
  try {
    await fetchAsid()
    return (await buildClientAPI().addressSuggestService.fetchCity(asid, zip))
      ?.data?.cities[0]
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Cannot fetch cities from AddressService: ', e)
    return ''
  }
}

export const getExperimentNameForPageType: (
  pageType: string
) => ExperimentName | undefined = pageType => {
  let experiment: ExperimentName | undefined

  switch (pageType) {
    case PAGE_TYPE_HOMEPAGE:
      experiment = NOW_HOMEPAGE_GB_FUNCTIONALITY
      break
    case PAGE_TYPE_SEARCH_RESULTS:
      experiment = NOW_SERP_GB_FUNCTIONALITY
      break
    case PAGE_TYPE_CATEGORY:
      experiment = NOW_CATEGORY_GB_FUNCTIONALITY
      break
    default:
      experiment = ''
  }

  return experiment
}

const shouldDisplayNowGlobalBar: (
  pageType: string,
  experiments: Experiment[]
) => boolean = (pageType, experiments) => {
  const experiment = getExperimentNameForPageType(pageType)
  const pagesWithNowGlobalBar =
    PAGES_WITH_NOW_GLOBAL_BAR as PagesWithNowGlobalBar
  if (!experiment) {
    return false
  }

  return (
    isExperimentActive(experiment, experiments) &&
    isObjKey(experiment, pagesWithNowGlobalBar) &&
    pagesWithNowGlobalBar[experiment] === pageType
  )
}

const nowGlobalBarClickEvent: (
  pageType: PageType
) => TrackingEventName = pageType => {
  switch (pageType) {
    case PAGE_TYPE_CATEGORY:
      return NOW_CATEGORY_GLB_CLICK
    case PAGE_TYPE_SEARCH_RESULTS:
      return NOW_SERP_GLB_CLICK
    case PAGE_TYPE_HOMEPAGE:
      return NOW_HOMEPAGE_GLB_CLICK
    default:
      return event``
  }
}

const nowGlobalBarModalSubmitEvent: (
  pageType: PageType
) => TrackingEventName = pageType => {
  switch (pageType) {
    case PAGE_TYPE_CATEGORY:
      return NOW_CATEGORY_GLB_MODAL_SUBMIT
    case PAGE_TYPE_SEARCH_RESULTS:
      return NOW_SERP_GLB_MODAL_SUBMIT
    case PAGE_TYPE_HOMEPAGE:
      return NOW_HOMEPAGE_GLB_MODAL_SUBMIT
    default:
      return event``
  }
}

export {
  fetchCityByZipCode,
  shouldDisplayNowGlobalBar,
  nowGlobalBarClickEvent,
  nowGlobalBarModalSubmitEvent,
}
