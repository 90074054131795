/* Babel's useBuiltins unfortunately doesn't include any DOM polyfills.
 * That means that we are on our own... :(
 */
import 'new-event-polyfill'
import 'custom-event-polyfill'
import closest from 'views/assets/scripts/polyfills/closest'
import stringPolyfills from 'views/assets/scripts/polyfills/String'

export default () => {
  closest()
  stringPolyfills()
}
