export const PRODUCT_STATUS_AV = 'av'
export const PRODUCT_STATUS_NL = 'nl'
export const PRODUCT_STATUS_NA = 'na'
export const PRODUCT_STATUS_GRATIS = 'gratis'
export const PRODUCT_STATUS_AVAILABLE = ''

export const FULL_PRODUCT_STATUS_AVAILABLE = 'available'
export const FULL_PRODUCT_STATUS_DELIVERY_3 = 'delivery3'
export const FULL_PRODUCT_STATUS_DELIVERY_5 = 'delivery5'
export const FULL_PRODUCT_STATUS_DELIVERY_5_7 = 'delivery5_7'
export const FULL_PRODUCT_STATUS_DELIVERY_7 = 'delivery7'
export const FULL_PRODUCT_STATUS_DELIVERY_10 = 'delivery10'
export const FULL_PRODUCT_STATUS_DELIVERY_20 = 'delivery20'

export const FULL_PRODUCT_STATUS_OUT_OF_TRADE = 'outOfTrade'
export const FULL_PRODUCT_STATUS_CURRENTLY_NOT_AVAILABLE =
  'currentlyNotAvailable'
export const FULL_PRODUCT_STATUS_NOT_AVAILABLE = 'notAvailable'
export const FULL_PRODUCT_STATUS_NOT_IN_CATALOG = 'notInCatalog'
export const FULL_PRODUCT_STATUS_UNAVAILABLE = 'unavailable'
export const FULL_PRODUCT_STATUS_AVAILABLE_REPLACEMENT = 'availableReplacement'
export const FULL_PRODUCT_STATUS_GRATIS = 'gratis'
export const FULL_PRODUCT_NOT_IN_ASSORTMENT = 'notInAssortment'

const PRODUCT_NOT_ON_STOCK_DELIVERY_STATUS = 'product.notOnStock.delivery'
export const DELIVERY_3_DAYS = `${PRODUCT_NOT_ON_STOCK_DELIVERY_STATUS}.3`
export const DELIVERY_3_5_DAYS = `${PRODUCT_NOT_ON_STOCK_DELIVERY_STATUS}.3_5`
export const DELIVERY_5_DAYS = `${PRODUCT_NOT_ON_STOCK_DELIVERY_STATUS}.5`
export const DELIVERY_5_7_DAYS = `${PRODUCT_NOT_ON_STOCK_DELIVERY_STATUS}.5_7`
export const DELIVERY_7_10_DAYS = `${PRODUCT_NOT_ON_STOCK_DELIVERY_STATUS}.7_10`
export const DELIVERY_10_14_DAYS = `${PRODUCT_NOT_ON_STOCK_DELIVERY_STATUS}.10_14`
export const DELIVERY_20_30_DAYS = `${PRODUCT_NOT_ON_STOCK_DELIVERY_STATUS}.20_30`
