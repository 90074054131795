import logger from 'shared/services/logger'

class Storage {
  storage = null

  constructor(storageAPI) {
    if (typeof global[storageAPI] !== 'undefined') {
      this.storage = global[storageAPI]
    }
  }

  isAvailable = () => {
    if (!this.storage) {
      return false
    }

    const key = 'tmpKey'
    const value = 'tmpValue'
    try {
      this.storage.setItem(key, value)
      if (this.storage.getItem(key) !== value) {
        return false
      }
      this.storage.removeItem(key)
      return true
    } catch (e) {
      logger.info(e.message)
      return false
    }
  }

  /**
   * as it is it can actually store anything, also no params
   * since the key is treated as a Symbol
   */
  set = (key, value) => this.storage?.setItem(key, JSON.stringify(value))

  /**
   * get Item does not throws exceptions
   * may throw an exception on JSON.parse
   * let it go
   *
   * but remember to be able to read a direct primitive stored by 3rd party
   */
  get = key => {
    const value = this.storage?.getItem(key)

    try {
      return JSON.parse(value)
    } catch (e) {
      return value
    }
  }

  remove = key => this.storage?.removeItem(key)

  key = index => this.storage?.key(index)

  length = () => this.storage?.length

  clear = () => {
    if (this.storage) {
      this.storage.clear()
      return this.storage.length === 0
    }

    return false
  }

  isEmpty = () => !this.storage || this.storage.length === 0
}

export default Storage
/**
 * TODO:
 * - add keys(), key(index), size()
 * - add validation
 * - handle key edge cases
 */
