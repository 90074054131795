// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

import { ThreadAttachment, ThreadAttachmentV1 } from './ThreadAttachment'
import { ThreadParticipant, ThreadParticipantV1 } from './ThreadParticipant'

export interface ThreadMessageV1 {
  attachments?: ThreadAttachmentV1[]
  body: string
  dateCreated: string
  from: ThreadParticipantV1
  id: string
  to: ThreadParticipantV1[]
}

export class ThreadMessage extends Model {
  public readonly attachments: ThreadAttachment[]
  public body: string
  public dateCreated: string
  public from: ThreadParticipant
  public id: string
  public to: ThreadParticipant[]

  constructor(src: ThreadMessageV1) {
    super(src)
    this.attachments = src.attachments?.length
      ? src.attachments.map(
          (attachment: ThreadAttachmentV1) => new ThreadAttachment(attachment)
        )
      : []
    this.body = src.body
    this.dateCreated = src.dateCreated
    this.from = new ThreadParticipant(src.from)
    this.id = src.id
    this.to = src.to?.length
      ? src.to.map(
          (recipient: ThreadParticipantV1) => new ThreadParticipant(recipient)
        )
      : []
  }
}
