export const PAGE_TYPE_ACCOUNT = 'account'
export const PAGE_TYPE_ACCOUNT_NEWSLETTER = 'account_newsletter'
export const PAGE_TYPE_ACCOUNT_REDPOINTS = 'account_redpoints'
export const PAGE_TYPE_BILLING_ADDRESS = 'billing_address'
export const PAGE_TYPE_BRAND_PAGE = 'marken'
export const PAGE_TYPE_BRANDSHOPS = 'markenshops'
export const PAGE_TYPE_CART = 'cart'
export const PAGE_TYPE_CART_INTERMEDIATE = 'cartintermediate'
export const PAGE_TYPE_CATEGORY = 'category'
export const PAGE_TYPE_CHANGE_PASSWORD = 'change_password'
export const PAGE_TYPE_CONTACT = 'contact'
export const PAGE_TYPE_RESET_PASSWORD = 'resetPassword'
export const PAGE_TYPE_CHANGE_DATE_OF_BIRTH = 'changeDateOfBirth'
export const PAGE_TYPE_CHANGE_EMAIL = 'changeEmail'
export const PAGE_TYPE_CHANGE_EMAIL_CONFIRMATION = 'changeEmailConfirmation'
export const PAGE_TYPE_CHECKOUT = 'checkout'
export const PAGE_TYPE_CONFIRMATION = 'confirmation'
export const PAGE_TYPE_CONTENT = 'content'
export const PAGE_TYPE_DELIVERYTRACKING = 'deliverytracking'
export const PAGE_TYPE_DISCLAIMER = 'disclaimer'
export const PAGE_TYPE_ERROR = 'error'
export const PAGE_TYPE_FAQ = 'faq'
export const PAGE_TYPE_FORGOT_PASSWORD = 'forgot-password'
export const PAGE_TYPE_HOMEPAGE = 'homepage'
export const PAGE_TYPE_INCIDENT = 'incident'
export const PAGE_TYPE_INCIDENT_CONFIRMATION = 'incident_confirmation'
export const PAGE_TYPE_IMPRESSUM = 'impressum'
export const PAGE_TYPE_LEGACY = 'legacy'
export const PAGE_TYPE_LOGIN = 'login'
export const PAGE_TYPE_MARKETING = 'marketing'
export const PAGE_TYPE_MEDCOACH = 'medcoach'
export const PAGE_TYPE_MEDICATION_PLAN = 'medication-plan'
export const PAGE_TYPE_MOBILE_APP = 'mobile_app'
export const PAGE_TYPE_MY_THERAPY_MS = 'my-therapy-ms'
export const PAGE_TYPE_MY_THERAPY_APP = 'my-therapy-app'
export const PAGE_TYPE_MY_THERAPY_OVERVIEW = 'my-therapy-overview'
export const PAGE_TYPE_MY_THERAPY_PSORIASIS = 'my-therapy-psoriasis'
export const PAGE_TYPE_MY_THERAPY_RHEUMA = 'my-therapy-rheuma'
export const PAGE_TYPE_MY_THERAPY_ASTHMA = 'my-therapy-asthma'
export const PAGE_TYPE_MY_THERAPY_HIV = 'my-therapy-hiv'
export const PAGE_TYPE_MY_THERAPY_DIABETES = 'my-therapy-diabetes'
export const PAGE_TYPE_NEW_MARKETPLACE_COLLECTION = 'new_marketplace_collection'
export const PAGE_TYPE_NEWSLETTER = 'newsletter'
export const PAGE_TYPE_NEWSLETTER_CONFIRMATION = 'newsletter-confirmation'
export const PAGE_TYPE_NEWSLETTER_UNSUBSCRIPTION = 'newsletter-unsubscription'
export const PAGE_TYPE_NOTEPAD = 'notepad'
export const PAGE_TYPE_OFFERS = 'offers'
export const PAGE_TYPE_ONLINEDOCTORSERVICE = 'service_od'
export const PAGE_TYPE_ORDER_DETAILS = 'order_details'
export const PAGE_TYPE_ORDER_OVERVIEW = 'order_overview'
export const PAGE_TYPE_ORDERPRINT = 'orderprint'
export const PAGE_TYPE_ORDERS_LIST = 'orders_list'
export const PAGE_TYPE_PRODUCT = 'product'
export const PAGE_TYPE_PRIVACY_SECURITY = 'privacy'
export const PAGE_TYPE_REDEEM_RECIPE = 'redeem_recipe'
export const PAGE_TYPE_REDEEM_E_RECIPE = 'redeem_e_recipe'
export const PAGE_TYPE_REDEEM_RECIPE_FUNNEL = 'redeem_recipe_funnel'
export const PAGE_TYPE_REDPOINTS = 'redpoints'
export const PAGE_TYPE_REFUND = 'refund'
export const PAGE_TYPE_RETURN_LABEL_CONFIRMATION = 'return_label_confirmation'
export const PAGE_TYPE_RETURN_LABEL_CUSTOMER_CARE = 'return_label_customer_care'
export const PAGE_TYPE_SAME_DAY_DELIVERY = 'same_day_delivery'
export const PAGE_TYPE_SEARCH_RESULTS = 'search'
export const PAGE_TYPE_SELLER_RATING = 'seller_rating'
export const PAGE_TYPE_SELLER_PRODUCTS = 'seller_products'
export const PAGE_TYPE_SHIPPING_ADDRESS = 'shipping_address'
export const PAGE_TYPE_SWITCH = 'switchpage'
export const PAGE_TYPE_THREAD_DETAILS = 'thread_details'
export const PAGE_TYPE_THREADS_OVERVIEW = 'threads_overview'
export const PAGE_TYPE_TERMS_OF_SERVICE = 'terms_of_service'
export const PAGE_TYPE_TOPIC_LEXICON = 'topic_lexicon'
export const PAGE_TYPE_TOPIC_SHOP = 'topic_shop'
export const PAGE_TYPE_TOPIC_SHOW = 'topic_show'
export const PAGE_TYPE_UNKNOWN = 'page_type_unknown'
export const PAGE_TYPE_WEB_VIEWS_THREAD_NEW = 'webviews_new_thread'
export const PAGE_TYPE_BRANCHPREVIEW = 'branch_preview'
export const PAGE_TYPE_EAV_CATALOGUE = 'eav-catalogue'
export const PAGE_TYPE_USER_CREDIT = 'credit'
export const PAGE_TYPE_MAGAZIN = 'magazin'
export const PAGE_TYPE_FITNESS_TRENDS = 'fitness-trends'
export const PAGE_TYPE_WELCOME = 'welcome'
export const PAGE_TYPE_WOHLFUEHLERLEBNISSE = 'wohlfuehlerlebnisse'

export const ORDER_PAGES = [
  PAGE_TYPE_ORDERS_LIST,
  PAGE_TYPE_ORDER_OVERVIEW,
  PAGE_TYPE_ORDER_DETAILS,
  PAGE_TYPE_CONFIRMATION,
]

export const MESSAGES_PAGES = [
  PAGE_TYPE_THREADS_OVERVIEW,
  PAGE_TYPE_THREAD_DETAILS,
]

export const ACCOUNT_PAGES = [
  PAGE_TYPE_ACCOUNT,
  PAGE_TYPE_LOGIN,
  PAGE_TYPE_ACCOUNT_NEWSLETTER,
  PAGE_TYPE_ACCOUNT_REDPOINTS,
  PAGE_TYPE_BILLING_ADDRESS,
  PAGE_TYPE_CHANGE_EMAIL,
  PAGE_TYPE_CHANGE_DATE_OF_BIRTH,
  PAGE_TYPE_MEDICATION_PLAN,
  PAGE_TYPE_CHANGE_PASSWORD,
  PAGE_TYPE_SHIPPING_ADDRESS,
  PAGE_TYPE_NOTEPAD,
  PAGE_TYPE_REFUND,
  PAGE_TYPE_INCIDENT,
  PAGE_TYPE_EAV_CATALOGUE,
  PAGE_TYPE_USER_CREDIT,
  ...MESSAGES_PAGES,
  ...ORDER_PAGES,
]

export const CART_PAGES = [PAGE_TYPE_CART, PAGE_TYPE_CART_INTERMEDIATE]

export const MY_THERAPY_PAGES = [
  PAGE_TYPE_MY_THERAPY_MS,
  PAGE_TYPE_MY_THERAPY_APP,
  PAGE_TYPE_MY_THERAPY_PSORIASIS,
  PAGE_TYPE_MY_THERAPY_ASTHMA,
  PAGE_TYPE_MY_THERAPY_HIV,
  PAGE_TYPE_MY_THERAPY_RHEUMA,
  PAGE_TYPE_MY_THERAPY_DIABETES,
]

export const PAGE_TYPES = [
  PAGE_TYPE_BRAND_PAGE,
  PAGE_TYPE_BRANDSHOPS,
  PAGE_TYPE_CATEGORY,
  PAGE_TYPE_CHECKOUT,
  PAGE_TYPE_CONTACT,
  PAGE_TYPE_CONTENT,
  PAGE_TYPE_DELIVERYTRACKING,
  PAGE_TYPE_ERROR,
  PAGE_TYPE_HOMEPAGE,
  PAGE_TYPE_LEGACY,
  PAGE_TYPE_MARKETING,
  PAGE_TYPE_MEDCOACH,
  PAGE_TYPE_NEW_MARKETPLACE_COLLECTION,
  PAGE_TYPE_NEWSLETTER,
  PAGE_TYPE_NEWSLETTER_CONFIRMATION,
  PAGE_TYPE_NEWSLETTER_UNSUBSCRIPTION,
  PAGE_TYPE_OFFERS,
  PAGE_TYPE_ONLINEDOCTORSERVICE,
  PAGE_TYPE_ORDERPRINT,
  PAGE_TYPE_PRODUCT,
  PAGE_TYPE_REDEEM_E_RECIPE,
  PAGE_TYPE_REDEEM_RECIPE,
  PAGE_TYPE_REDEEM_RECIPE_FUNNEL,
  PAGE_TYPE_REDPOINTS,
  PAGE_TYPE_SEARCH_RESULTS,
  PAGE_TYPE_SWITCH,
  PAGE_TYPE_CHANGE_DATE_OF_BIRTH,
  PAGE_TYPE_CHANGE_EMAIL,
  PAGE_TYPE_CHANGE_EMAIL_CONFIRMATION,
  PAGE_TYPE_CHANGE_PASSWORD,
  PAGE_TYPE_RESET_PASSWORD,
  PAGE_TYPE_TOPIC_LEXICON,
  PAGE_TYPE_TOPIC_SHOP,
  PAGE_TYPE_TOPIC_SHOW,
  PAGE_TYPE_UNKNOWN,
  PAGE_TYPE_WEB_VIEWS_THREAD_NEW,
  PAGE_TYPE_BRANCHPREVIEW,
  PAGE_TYPE_MOBILE_APP,
  PAGE_TYPE_SAME_DAY_DELIVERY,
  PAGE_TYPE_SELLER_PRODUCTS,
  PAGE_TYPE_WELCOME,
  ...ACCOUNT_PAGES,
  ...CART_PAGES,
  ...MY_THERAPY_PAGES,
]

export const PAGES_WITHOUT_SEARCHBOX = [
  PAGE_TYPE_NOTEPAD, // WSBLUES-45 setting.
  PAGE_TYPE_PRODUCT,
  PAGE_TYPE_ACCOUNT,
  PAGE_TYPE_CART,
  PAGE_TYPE_CART_INTERMEDIATE,
  PAGE_TYPE_CHANGE_DATE_OF_BIRTH,
  PAGE_TYPE_CHANGE_EMAIL,
  PAGE_TYPE_CHANGE_EMAIL_CONFIRMATION,
  PAGE_TYPE_CHANGE_PASSWORD,
  PAGE_TYPE_LOGIN,
  PAGE_TYPE_BILLING_ADDRESS,
  PAGE_TYPE_SHIPPING_ADDRESS,
  PAGE_TYPE_MEDICATION_PLAN,
  PAGE_TYPE_ACCOUNT_NEWSLETTER,
  PAGE_TYPE_ORDERS_LIST,
  PAGE_TYPE_ORDER_OVERVIEW,
  PAGE_TYPE_ORDER_DETAILS,
  PAGE_TYPE_SAME_DAY_DELIVERY,
  PAGE_TYPE_EAV_CATALOGUE,
  PAGE_TYPE_USER_CREDIT,
  ...MY_THERAPY_PAGES,
  ...MESSAGES_PAGES,
]

// ADDING NEW PAGE TO THE LIST WILL AUTOMATICALLY LOAD
// mountNowGlobalBar.js SCRIPT WHEN PAGE IS OPENED and
// EXPERIMENT (in position of a key) IS ENABLED
export const PAGES_WITH_NOW_GLOBAL_BAR = {
  'wstra-5031': PAGE_TYPE_SEARCH_RESULTS,
  'wstra-5032': PAGE_TYPE_HOMEPAGE,
  'wstra-5033': PAGE_TYPE_CATEGORY,
}
