import store, { subscribe } from 'shared/store'
import { BIND_MODAL, HIDE_MODAL, MODAL_SHOWN } from 'shared/store/ducks/events'

const CONTAINERS_SELECTOR = '[data-clientside-hook~="youtubeVideo"]'
const IFRAME_SELECTOR = '[data-clientside-hook~="youtubeVideo__iframe"]'
const BUTTON_SELECTOR = '[data-clientside-hook~="youtubeVideo__button"]'

export default () => {
  /** @type NodeListOf<HTMLElement> */
  const containers = document.querySelectorAll(CONTAINERS_SELECTOR)
  return Array.from(containers).map(container => {
    /** @type HTMLButtonElement */
    const button = container.querySelector(BUTTON_SELECTOR)
    /** @type HTMLIFrameElement */
    const iframe = container.querySelector(IFRAME_SELECTOR)
    const {
      dataset: { src },
    } = iframe
    const {
      dataset: { id },
    } = container

    button.removeAttribute('href')
    button.setAttribute('data-a11y-dialog-show', id)

    store.dispatch({
      type: BIND_MODAL,
      selector: CONTAINERS_SELECTOR,
    })

    subscribe.before(MODAL_SHOWN, ({ modalName }) => {
      if (modalName === id) {
        iframe.src = `${src}&autoplay=1`
      }
    })

    subscribe.after(HIDE_MODAL, ({ modalName }) => {
      if (modalName === id) {
        iframe.src = src
      }
    })

    return container
  })
}
