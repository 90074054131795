import type { DisplayableFacetName, SearchResults } from 'types/search'

export const FILTER_ID = 'product-filter'

/**
 * Some filters have an extra logic. `shippingOptions` consists of two other facets:
 * filterAttributes/ABO and nowProductGroup/sameday_nextday.
 * Thus, these two original facets are not shown, but produce custom `shippingOptions` filter
 * (NB: filterAttributes is shown, but it doesn't display ABO option)
 */
export const CUSTOMLY_HANDLED_FILTERS: DisplayableFacetName[] = [
  'nowProductGroup',
  'filterAttributes',
]

/**
 * These filters are hidden by default
 */
export const HIDDEN_FILTERS: DisplayableFacetName[] = [
  'packSize',
  'brandIntern',
  'primaryCategory',
  'shippingOptions',
  'potency',
  'filterAttributes',
  'manufacturer',
  'prescriptionMedicine',
  'activeSubstances',
  'hair_type_multi',
  'skin_type_multi',
  'uv_protection',
  'application_areas',
  'lens_types',
  'absorbency',
  'book_language',
  'authors',
  'animal_species',
  'schuessler_salts',
  'voltage',
  'life_stage',
  'variety',
  'special_needs',
  'certificates',
  'recommended_age',
  'hair_color',
  'hair_length',
  'haircut',
  'head_circumference',
  'publisher',
  'divisibility',
  'sports',
  'discountInPercent',
  'texture',
  'product_type_filter',
  'mineral_and_trace_elements',
  'vitamins',
]

//@ts-ignore
export const EMPTY_SEARCH_RESULTS: SearchResults = {
  disjunctiveFacets: [],
}

export const FILTERS_DEBOUNCE_TIME = 200
export const FILTER_SEARCH_OPTIONS = 10
export const PAGE_SEARCHING_STATE_EMERGENCY_TIMEOUT = 3000 // 3 sec
export const PAGE_SEARCHING_STATE_TIMEOUT = 300 // 0.3 sec

export const FILTER_FLYOUT_CLOSE = 'FILTER_FLYOUT_CLOSE'
export const FILTER_FLYOUT_OPEN = 'FILTER_FLYOUT_OPEN'
export const PRODUCT_FILTER_CHANGED_SFX = 'PRODUCT_FILTER_CHANGED_'
export const PRODUCT_FILTER_RESET_SFX = 'PRODUCT_FILTER_RESET_'
export const PRODUCT_FILTER_RESET = 'PRODUCT_FILTER_RESET'

// `Sort By` is displayed as a filter, but it is not available in the search results,
// so manual calculation needed
export const EXTRA_FILTERS_NUMBER = 1
