import { HITS_PER_PAGE_PRODUCT_FILTER } from 'shared/consts/algolia'
import store, { subscribe } from 'shared/store'
import { HITS_PER_PAGE_FILTER_CHANGED } from 'shared/store/ducks/events'
import createA11Dialog from 'views/utils/createA11Dialog'
import toggleBoolStringAttr from 'views/utils/toggleBoolStringAttribute'

import { ARIA_HIDDEN } from './consts'
import {
  getSearchStateFromStore,
  hideProductFilterModalHeader,
  showProductFilterModalHeader,
  toggleParentModalOverflow,
} from './refinementList'

const SELECTOR_MODAL = '[data-clientside-hook~="modal"]'
const SELECTOR_HITS_PER_PAGE = '[data-clientside-hook~="hitsPerPage"]'
const SELECTOR_CLOSE_MODAL_BUTTON =
  '[data-clientside-hook~="hitsPerPage__close-modal"]'
const SELECTOR_CONFIRM_SELECTION_BUTTON =
  '[data-clientside-hook~="hitsPerPage__confirm-selection"]'
const SELECTOR_CANCEL_SELECTION_BUTTON =
  '[data-clientside-hook~="hitsPerPage__cancel-selection"]'
const SELECTOR_PRODUCT_FILTER_MODAL_HEADER =
  '[data-clientside-hook~="productFilterModal__header"]'
const SELECTOR_PRODUCT_FILTER_MODAL_DIALOG =
  '[data-clientside-hook~="productFilterModal__content"]'

export default () => {
  const hitsPerPageContainer = document.querySelector(SELECTOR_HITS_PER_PAGE)
  if (!hitsPerPageContainer) {
    return null
  }

  const productFilterModalHeader = document.querySelector(
    SELECTOR_PRODUCT_FILTER_MODAL_HEADER
  )
  const parentModal = document.querySelector(
    SELECTOR_PRODUCT_FILTER_MODAL_DIALOG
  )
  const modal = createA11Dialog({
    element: hitsPerPageContainer.querySelector(SELECTOR_MODAL),
  })
  const closeButtons = [
    ...hitsPerPageContainer.querySelectorAll(SELECTOR_CLOSE_MODAL_BUTTON),
  ]
  const confirmSelectionButton = hitsPerPageContainer.querySelector(
    SELECTOR_CONFIRM_SELECTION_BUTTON
  )
  const cancelSelectionButton = hitsPerPageContainer.querySelector(
    SELECTOR_CANCEL_SELECTION_BUTTON
  )
  const searchState = getSearchStateFromStore()
  let defaultValue = searchState?.hitsPerPage
    ? parseInt(searchState.hitsPerPage, 10)
    : HITS_PER_PAGE_PRODUCT_FILTER
  let currentValue
  let refineFn

  subscribe.after(HITS_PER_PAGE_FILTER_CHANGED, payload => {
    const { refine, currentRefinement, defaultRefinement } = payload

    currentValue = currentRefinement

    if (refine) {
      refineFn = refine
    }

    if (defaultRefinement) {
      defaultValue = defaultRefinement
    }

    if (confirmSelectionButton.getAttribute(ARIA_HIDDEN) === 'true') {
      closeButtons.forEach(closeButton =>
        toggleBoolStringAttr(closeButton, ARIA_HIDDEN, true)
      )
      toggleBoolStringAttr(confirmSelectionButton, ARIA_HIDDEN, false)
      toggleBoolStringAttr(cancelSelectionButton, ARIA_HIDDEN, false)
    }
  })

  modal.on('show', () => {
    hideProductFilterModalHeader(productFilterModalHeader)
    toggleParentModalOverflow(parentModal, true)
    toggleBoolStringAttr(confirmSelectionButton, ARIA_HIDDEN, true)
    toggleBoolStringAttr(cancelSelectionButton, ARIA_HIDDEN, true)
    closeButtons.forEach(closeButton =>
      toggleBoolStringAttr(closeButton, ARIA_HIDDEN, false)
    )
  })

  modal.on('hide', () => {
    toggleParentModalOverflow(parentModal, false)
  })

  cancelSelectionButton.addEventListener('click', event => {
    event.preventDefault()

    store.dispatch({
      type: HITS_PER_PAGE_FILTER_CHANGED,
      currentRefinement: defaultValue,
    })

    modal.hide()
    showProductFilterModalHeader(productFilterModalHeader)
  })

  confirmSelectionButton.addEventListener('click', event => {
    event.preventDefault()

    if (refineFn && currentValue) {
      refineFn(currentValue)
      defaultValue = currentValue
    }

    modal.hide()
    showProductFilterModalHeader(productFilterModalHeader)
  })

  closeButtons.forEach(closeButton =>
    closeButton.addEventListener('click', event => {
      event.preventDefault()
      modal.hide()
      showProductFilterModalHeader(productFilterModalHeader)
    })
  )

  return hitsPerPageContainer
}
