// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

import { NotepadProductOfferBasePriceV1 } from './notepadResponseTypes'

export class NotepadProductOfferBasePrice extends Model {
  public amount: number
  public currency: string
  public unit: string
  public value: number

  constructor(src: NotepadProductOfferBasePriceV1) {
    super(src)
    this.amount = src.amount
    this.currency = src.currency
    this.unit = src.unit
    this.value = src.value
  }
}
