export const SEARCH_TYPE_INTERN = 'interne'
export const SEARCH_TYPE_EXTERN = 'externe'
export const SEARCH_TYPE_TEASER = 'teaser'

export const SITE_TYPE_HOME = 'home'

export const SITE_TYPE_ACCOUNT_ORDERS = 'account_orders'
export const SITE_TYPE_ACCOUNT_OVERVIEW = 'account_overview'
export const SITE_TYPE_BRAND_PAGE = 'brands'
export const SITE_TYPE_CONTACT = 'content_contact'
export const SITE_TYPE_DISCLAIMER = 'content_disclaimer'
export const SITE_TYPE_FAQ = 'content_faq'
export const SITE_TYPE_IMPRESSUM = 'content_imprint'
export const SITE_TYPE_NEWSLETTER = 'newsletter'
export const SITE_TYPE_PRIVACY_SECURITY = 'content_privacy'
export const SITE_TYPE_TERMS_OF_SERVICE = 'content_tac'

export const CONTENT_TYPE_LOGIN = 'Anmeldung'
export const CONTENT_TYPE_HOMEPAGE = 'Startseite'
export const CONTENT_TYPE_DELIVERYTRACKING = 'Paketverfolgung'
export const CONTENT_TYPE_ONLINEDOCTORSERVICE = 'Service/Online-Arzt-Service'
export const CONTENT_TYPE_REDEEM_RECIPE = 'Service/Rezept-Einlösen'
export const CONTENT_TYPE_REDEEM_E_RECIPE = 'Service/eRX-Info'
export const CONTENT_TYPE_REDEEM_RECIPE_FUNNEL = 'Service/Rezept-Overview'
export const CONTENT_TYPE_BRAND_PAGE = 'Marken'
export const CONTENT_TYPE_FAQ = 'Service/Häufig gestellte Fragen'
export const CONTENT_TYPE_IMPRESSUM = 'Service/Impressum'
export const CONTENT_TYPE_CATEGORY_PAGE = 'Kategorien'
export const CONTENT_TYPE_BRANDSHOP_PAGE = 'Markenshops'
export const CONTENT_TYPE_THEMESHOP_PAGE = 'Themenshops'
export const CONTENT_TYPE_LEXICON_PAGE = 'Medikamenten-Lexikon'
export const CONTENT_TYPE_SYMPTOME_LEXICON_PAGE = 'Symptome-Lexikon'
export const CONTENT_TYPE_THERAPIE_LEXICON_PAGE = 'Therapie-Lexikon'
export const CONTENT_TYPE_MANUFACTURER_PAGE = 'Hersteller'
export const CONTENT_TYPE_LANDING_PAGE = 'Landingpages'
export const CONTENT_TYPE_CONTACT = 'Service/Kontakt'
export const CONTENT_TYPE_DISCLAIMER = 'Service/Haftungsausschluss'
export const CONTENT_TYPE_PRIVACY_SECURITY = 'Service/Datenschutz'
export const CONTENT_TYPE_TERMS_OF_SERVICE = 'Service/AGB'
export const CONTENT_TYPE_NEWSLETTER = 'Newsletter/Ihr persönlicher Newsletter'
export const CONTENT_TYPE_CONFIRMATION = 'Confirmation'
export const CONTENT_TYPE_NEWSLETTER_CONFIRMATION = 'Newsletter Confirmation'
export const CONTENT_TYPE_NEWSLETTER_UNSUBSCRIPTION = 'Newsletter Abmeldung'
export const CONTENT_TYPE_NEW_MARKETPLACE_COLLECTION =
  'Landingpages/Marketplace'

export const CONTENT_TYPE_ORDER_DETAILS =
  'Mein Konto/Meine Bestellhistorie Details'
export const CONTENT_TYPE_ACCOUNT_OVERVIEW = 'Mein Konto/Ihre Übersicht'
export const CONTENT_TYPE_ORDERS = 'Mein Konto/Meine Bestellhistorie'
export const CONTENT_TYPE_ORDER_OVERVIEW =
  'Mein Konto/Meine Bestellhistorie Bestellung'

export const CONTENT_TYPE_MESSAGE_OVERVIEW = 'Meine Nachrichten'
export const CONTENT_TYPE_MESSAGE_THREAD = 'Meine Nachricht'
export const CONTENT_TYPE_NOTEPAD_PAGE = 'Notepad'
export const CONTENT_TYPE_BILLING_ADDRESS = 'Billing Address'
export const CONTENT_TYPE_SHIPPING_ADDRESS = 'Shipping Address'

export const CONTENT_TYPE_ACCOUNT_NEWSLETTER =
  'Account Newsletter/Ihr persönlicher Newsletter'

export const CONTENT_TYPE_RETURN_LABEL_CONFIRMATION =
  'Return-label Confirmation/Rücksendeetikett Bestätigung'

export const CONTENT_TYPE_INCIDENT_CONFIRMATION = 'Incident Confirmation'

export const MAP_AREA_TO_NAVIGATION_CONTENT = {
  'categories': CONTENT_TYPE_CATEGORY_PAGE,
  'brands': CONTENT_TYPE_BRAND_PAGE,
  'brandshop': CONTENT_TYPE_BRANDSHOP_PAGE,
  'themeshop': CONTENT_TYPE_THEMESHOP_PAGE,
  'manufacturer': CONTENT_TYPE_MANUFACTURER_PAGE,
  'landingpages': CONTENT_TYPE_LANDING_PAGE,
  'lexicon': CONTENT_TYPE_LEXICON_PAGE,
  'symptome-lexikon': CONTENT_TYPE_SYMPTOME_LEXICON_PAGE,
  'therapie-lexikon': CONTENT_TYPE_THERAPIE_LEXICON_PAGE,
}
