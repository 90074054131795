import { type NowSellerDataForCart } from '@redteclab/api/clients/bully'

import { type ServiceParameters } from 'shared/services/api/commonTypes'
import { type NowDataStateProduct } from 'types/redux'

import BackendAPIService from './BackendAPIService'

type PayloadBasic = {
  zipCode: string
  products?: GenericRecord[]
}

class NowService extends BackendAPIService {
  private readonly basePath: string
  protected apiVersion = 'v4'

  constructor(params: ServiceParameters) {
    super(params)

    this.basePath = `/now/${this.apiVersion}/${this.tenantPath}/${this.language}`
  }

  public getBaseURL(): string {
    return `${this.basePath}/basicCheck`
  }

  public async fetchBasicData(
    payload: PayloadBasic
  ): Promise<NowSellerDataForCart> {
    const { data } = await this.post<PayloadBasic, NowSellerDataForCart>(
      this.getBaseURL(),
      payload
    )

    return data
  }

  public filterEligibleItemList(products: NowDataStateProduct[]): string[] {
    return products
      ? products
          .filter(item => {
            return item.nowProduct
          })
          .map(item => {
            return item.upid
          })
      : []
  }
}

export default NowService
