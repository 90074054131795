import {
  PRESCRIPTION_TYPE_FREE,
  PRESCRIPTION_TYPE_INSURANCE,
  PRESCRIPTION_TYPE_PRIVAT,
} from 'shared/consts'
import {
  type CartControllerCartEntryItemV2,
  type CartControllerGroupEntryV2,
  type CartControllerResponseV2,
  type CartControllerSellerGroupV2,
} from 'types/api/cart'

const getItemsFromCart = (
  cartData: CartControllerResponseV2
): CartControllerCartEntryItemV2[] => {
  const sellerGroups: CartControllerSellerGroupV2[] = cartData.sellerGroups

  const groupEntries: CartControllerGroupEntryV2[] = sellerGroups.flatMap(
    s => s.groupEntries
  )

  const items: CartControllerCartEntryItemV2[] = groupEntries.flatMap(
    ge => ge.items
  )

  return items
}

export const getProductIdsFromCart = (
  cartData: CartControllerResponseV2
): string[] => {
  const products: CartControllerCartEntryItemV2[] = getItemsFromCart(cartData)

  return [
    ...new Set(
      products.map((item: CartControllerCartEntryItemV2) => item.productId)
    ),
  ]
}

export const getUpidsFromCart = (
  cartData: CartControllerResponseV2
): string[] => {
  const products: CartControllerCartEntryItemV2[] = getItemsFromCart(cartData)

  return Array.from(
    new Set<string>(
      products
        .map((item: CartControllerCartEntryItemV2) => item.upid)
        .filter((upid): upid is string => Boolean(upid))
    )
  )
}

export const hasCartRxProducts = (cartData: CartControllerResponseV2) => {
  const products: CartControllerCartEntryItemV2[] = getItemsFromCart(cartData)

  // TODO: Use isRx property to find rx product after it's fixed in the cart service
  return products.some((item: CartControllerCartEntryItemV2) =>
    [
      PRESCRIPTION_TYPE_INSURANCE,
      PRESCRIPTION_TYPE_FREE,
      PRESCRIPTION_TYPE_PRIVAT,
    ].includes(item.prescriptionType)
  )
}
