import logger from 'shared/services/logger'
import store from 'shared/store'
import buildClientAPI from 'views/providers/clientAPI'

const updateCustomerPreferredLanguage = async (language: string) => {
  const { customerDataService } = buildClientAPI()
  await customerDataService.changePreferredLanguage(language)
}

export default (language: string) => {
  const userSession = store.getState()?.userSession
  if (userSession?.isLoggedIn) {
    updateCustomerPreferredLanguage(language).catch(() => {
      logger.error('Unable to update customer preferred language')
    })
  }
}
