import type { ClientCookie, ServerCookie } from 'shared/utils/adServerUtils'

const CONSENT_COOKIE_NAME = 'usercentrics_consent'
const AD_STORAGE = 'ad_storage'

export function isCookieConsent(cookies: ClientCookie | ServerCookie): boolean {
  const cookie = cookies.get(CONSENT_COOKIE_NAME)

  if (typeof cookie !== 'string') {
    return false
  }

  try {
    const consent = JSON.parse(cookie)[AD_STORAGE]

    return consent === true || consent === 1
  } catch (error) {
    return false
  }
}
