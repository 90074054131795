interface EanValidatorResult {
  valid: boolean
  ean: string
}

class ValidatorClass {
  regExpValidEan = /^(\d{13})$/
  ean = ''
  res: EanValidatorResult = {
    valid: false,
    ean: '',
  }

  validate(ean: string) {
    this.ean = ean

    return this.strategy()
  }

  strategy() {
    let matches = false

    if (this.ean) {
      matches = Boolean(this.ean.match(this.regExpValidEan))
    }

    this.res.valid = matches
    this.res.ean = matches ? this.ean : ''

    return this.res
  }
}

export const validator = new ValidatorClass()
export const validate = (ean: string) => validator.validate(ean)
