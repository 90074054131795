import type { NotepadV2ProductEntry } from '@redteclab/api/clients/bully'

import { NotepadEntry } from './notepadEntry'
import { NotepadProduct } from './notepadProduct'

export class NotepadEntryWithProduct extends NotepadEntry {
  product: NotepadProduct
  constructor(src: NotepadV2ProductEntry) {
    super(src)
    this.product = new NotepadProduct(src.product)
  }
}
