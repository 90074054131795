import { IconWarning } from '@redteclab/icons'
import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const IconErrorWarning: FC<IconProps> = props => (
  <SvgIcon {...props} component={IconWarning} />
)

export default IconErrorWarning
