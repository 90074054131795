/**
 * Product templates
 * OTC: no prescriptions
 * Rx: mendatory prescriptions
 * OTx: optional prescriptions
 */
export const PRODUCT_TEMPLATES = {
  OTC: 'OTC',
  RX: 'Rx',
  OTX: 'OTx',
}

/**
 * List of all possible product template values (used for e.g. propType validation)
 */
export const AVAILABLE_PRODUCT_TEMPLATES = [
  PRODUCT_TEMPLATES.RX,
  PRODUCT_TEMPLATES.OTX,
  PRODUCT_TEMPLATES.OTC,
]

/**
 * PrescriptionTypes definitions and as a collection
 * TODO: Types should not be mixing sections and prescriptions typed if they are just needed to Divide / Group things, needs refactoring
 */
export const PRESCRIPTION_TYPE_NO_PRESCRIPTION = 'noPrescription'
export const PRESCRIPTION_TYPE_INSURANCE = 'insurancePrescription'
export const PRESCRIPTION_TYPE_FREE = 'freePrescription'
export const PRESCRIPTION_TYPE_PRIVAT = 'privatePrescription'
export const PRESCRIPTION_TYPE_ODS = 'odsSection'
export const PRESCRIPTION_TYPE_ERX = 'electronicPrescription'
export const PRESCRIPTION_TYPE_GRATIS = 'bonusSection'

// TODO Use prescription.ts for that
export const PRESCRIPTION_TYPES = {
  NO_PRESCRIPTION: PRESCRIPTION_TYPE_NO_PRESCRIPTION,
  INSURANCE: PRESCRIPTION_TYPE_INSURANCE,
  FREE: PRESCRIPTION_TYPE_FREE,
  PRIVAT: PRESCRIPTION_TYPE_PRIVAT,
  ODS: PRESCRIPTION_TYPE_ODS,
  ERX: PRESCRIPTION_TYPE_ERX,
  GRATIS: PRESCRIPTION_TYPE_GRATIS,
}

/**
 * PricesTypes available in Price objetcs comming from Bully
 */
export const PRICE_TYPES = {
  LIST: 'list',
  RETAIL: 'retail',
  INSURANCE: PRESCRIPTION_TYPE_INSURANCE,
  FREE: PRESCRIPTION_TYPE_FREE,
  PRIVAT: PRESCRIPTION_TYPE_PRIVAT,
}

/**
 * Mapping of PrescriptionTypes to PriceTypes
 */
export const PRESCRIPTION_TYPE_TO_PRICE_TYPE = {
  [PRESCRIPTION_TYPE_NO_PRESCRIPTION]: PRICE_TYPES.RETAIL,
  [PRESCRIPTION_TYPE_INSURANCE]: PRICE_TYPES.INSURANCE,
  [PRESCRIPTION_TYPE_FREE]: PRICE_TYPES.FREE,
  [PRESCRIPTION_TYPE_PRIVAT]: PRICE_TYPES.PRIVAT,
}

/**
 * Available prescriptionTypes for a productTemplate
 */
export const AVAILABLE_PRESCRIPTION_TYPES = {
  ALL: [
    PRESCRIPTION_TYPE_NO_PRESCRIPTION,
    PRESCRIPTION_TYPE_INSURANCE,
    PRESCRIPTION_TYPE_FREE,
    PRESCRIPTION_TYPE_PRIVAT,
  ],
  [PRODUCT_TEMPLATES.OTC]: [PRESCRIPTION_TYPE_NO_PRESCRIPTION],
  [PRODUCT_TEMPLATES.OTX]: [
    PRESCRIPTION_TYPE_NO_PRESCRIPTION,
    PRESCRIPTION_TYPE_INSURANCE,
    PRESCRIPTION_TYPE_FREE,
    PRESCRIPTION_TYPE_PRIVAT,
  ],
  [PRODUCT_TEMPLATES.RX]: [
    PRESCRIPTION_TYPE_INSURANCE,
    PRESCRIPTION_TYPE_FREE,
    PRESCRIPTION_TYPE_PRIVAT,
  ],
}

/**
 * Default selected prescriptiontype for a productTemplate
 */
export const DEFAULT_PRESCRIPTION_TYPE = {
  [PRODUCT_TEMPLATES.RX]: PRESCRIPTION_TYPE_INSURANCE,
  [PRODUCT_TEMPLATES.OTX]: PRESCRIPTION_TYPE_NO_PRESCRIPTION,
  [PRODUCT_TEMPLATES.OTC]: PRESCRIPTION_TYPE_NO_PRESCRIPTION,
}

/**
 * PrescriptionTypes which get free shipping
 */
export const FREE_SHIPPING_PRESCRIPTION_TYPES = [
  PRESCRIPTION_TYPE_PRIVAT,
  PRESCRIPTION_TYPE_FREE,
  PRESCRIPTION_TYPE_INSURANCE,
]

/**
 * PrescriptionTypes which are displayed in OTC mode
 * (e.g. with RedPoints Bubble)
 * TODO: should be removed and replaced with normal checks
 */
export const OTC_MODES = [PRESCRIPTION_TYPE_NO_PRESCRIPTION]
