// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

import {
  NewThreadMessageAttachment,
  NewThreadMessageAttachmentV1,
} from './NewThreadMessageAttachment'
import { ThreadParticipant, ThreadParticipantV1 } from './ThreadParticipant'

export interface NewThreadMessageBodyV1 {
  files: NewThreadMessageAttachmentV1[]
  message: string
  threadId: string
  to: ThreadParticipantV1[]
}

export class NewThreadMessageBody extends Model {
  public files: NewThreadMessageAttachment[]
  public body: string
  public threadId: string
  public to: ThreadParticipant[]

  constructor(src: NewThreadMessageBodyV1) {
    super(src)
    this.files = src.files?.length
      ? src.files.map(
          (file: NewThreadMessageAttachmentV1) =>
            new NewThreadMessageAttachment(file)
        )
      : []
    this.body = src.message
    this.threadId = src.threadId
    this.to = src.to?.length
      ? src.to.map(
          (recipient: ThreadParticipantV1) => new ThreadParticipant(recipient)
        )
      : []
  }
}
