// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

import { type OrderMasterDataV1 } from './ordersResponseTypes'

export class OrderMasterData extends Model {
  public readonly applicableCredit?: number
  public readonly currency: string
  public readonly language: string
  public readonly orderDate: string
  public readonly orderNumber: string
  public readonly prescriptionFollows: boolean
  public readonly totalAmount: number
  public readonly totalAmountDiscountRedpoints: number
  public readonly totalAmountDiscountVoucher: number
  public readonly totalAmountDiscounts: number
  public readonly totalAmountDocuments: number
  public readonly totalAmountPrescriptionBonus: number
  public readonly totalAmountPrescriptionBonusDiscount: number
  public readonly totalAmountPrescriptionBonusToAccount: number
  public readonly totalAmountRedeemedRedpoints: number
  public readonly totalAmountRedpoints: number
  public readonly totalAmountShippingCost: number
  public readonly zeroAmountOrder: boolean
  public readonly totalAmountDocumentsWithLineDiscounts?: number

  constructor(src: OrderMasterDataV1) {
    super(src)
    this.applicableCredit = src.applicableCredit
    this.currency = src.currency
    this.language = src.language
    this.orderDate = src.orderDate
    this.orderNumber = src.orderNumber
    this.prescriptionFollows = Boolean(src.prescriptionFollows)
    this.totalAmount = src.totalAmount
    this.totalAmountDiscountRedpoints = src.totalAmountDiscountRedpoints
    this.totalAmountDiscountVoucher = src.totalAmountDiscountVoucher
    this.totalAmountDiscounts = src.totalAmountDiscounts
    this.totalAmountDocuments = src.totalAmountDocuments
    this.totalAmountPrescriptionBonus = src.totalAmountPrescriptionBonus
    this.totalAmountPrescriptionBonusDiscount =
      src.totalAmountPrescriptionBonusDiscount
    this.totalAmountPrescriptionBonusToAccount =
      src.totalAmountPrescriptionBonusToAccount
    this.totalAmountRedeemedRedpoints = src.totalAmountRedeemedRedpoints
    this.totalAmountRedpoints = src.totalAmountRedpoints
    this.totalAmountShippingCost = src.totalAmountShippingCost
    this.zeroAmountOrder = Boolean(src.zeroAmountOrder)
    this.totalAmountDocumentsWithLineDiscounts =
      src.totalAmountDocumentsWithLineDiscounts
  }
}
