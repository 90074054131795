// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

import { OrderNowMerchantDetailsV1 } from './ordersResponseTypes'

export class OrderNowMerchantDetailsResponse extends Model {
  public readonly logoUrl: string
  public readonly merchantName: string
  public readonly merchantNameLegalEntity: string
  public readonly street: string
  public readonly streetNo: string
  public readonly zipCode: string
  public readonly city: string
  public readonly supportEmail: string
  public readonly supportPhone: string
  public readonly supportPhoneHint: string
  public readonly termsUrl: string
  public readonly privacyUrl: string
  public readonly cancellationPolicyUrl: string
  public readonly imprintUrl: string
  public readonly pharmacyRegisterUrl: string
  public readonly pharmacyRegisterLogoUrl: string
  public readonly veterinaryRegisterUrl: string
  public readonly veterinaryRegisterLogoUrl: string
  public readonly openingHours: array

  constructor(src: OrderNowMerchantDetailsV1) {
    super(src)
    this.logoUrl = src.logoUrl
    this.merchantName = src.merchantName
    this.merchantNameLegalEntity = src.merchantNameLegalEntity
    this.street = src.street
    this.streetNo = src.streetNo
    this.zipCode = src.zipCode
    this.city = src.city
    this.supportEmail = src.supportEmail
    this.supportPhone = src.supportPhone
    this.supportPhoneHint = src.supportPhoneHint
    this.termsUrl = src.termsUrl
    this.privacyUrl = src.privacyUrl
    this.cancellationPolicyUrl = src.cancellationPolicyUrl
    this.imprintUrl = src.imprintUrl
    this.pharmacyRegisterUrl = src.pharmacyRegisterUrl
    this.pharmacyRegisterLogoUrl = src.pharmacyRegisterLogoUrl
    this.veterinaryRegisterUrl = src.veterinaryRegisterUrl
    this.veterinaryRegisterLogoUrl = src.veterinaryRegisterLogoUrl
    this.openingHours = src.openingHours
  }
}
