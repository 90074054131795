import {
  PAGE_TYPE_ACCOUNT,
  PAGE_TYPE_CART,
  PAGE_TYPE_CHECKOUT,
  PAGE_TYPE_LOGIN,
} from 'shared/consts'

export const NEWSLETTER_MODAL_ID = 'NewsletterModal'
export const blacklistedPages = [
  PAGE_TYPE_ACCOUNT,
  PAGE_TYPE_CART,
  PAGE_TYPE_CHECKOUT,
  PAGE_TYPE_LOGIN,
]
