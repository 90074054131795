import { PAGE_TYPE_ORDER_DETAILS, PAGE_TYPE_ORDERS_LIST } from 'shared/consts'
import {
  ACCOUNT_OVERVIEW_DOWNLOAD_INVOICE,
  ACCOUNT_OVERVIEW_HELP_WITH_MY_ORDER,
  ACCOUNT_OVERVIEW_LAST_ORDER_DETAIL,
  HOME_ONE_ACCOUNT,
} from 'shared/experiments/consts'
import {
  activateExperiment,
  getExperiment,
} from 'shared/experiments/utils/experiments'
import { bindDynamicEventsTracking } from 'shared/experiments/utils/tracking'
import store from 'shared/store'

export default () => {
  const publicRuntimeConfig = store.getState()?.publicRuntimeConfig
  const { experiments = [], pageType = '' } =
    publicRuntimeConfig?.pageProperties || {}

  const { isEnabled } = getExperiment(HOME_ONE_ACCOUNT, experiments)
  const pagesToLoad = [PAGE_TYPE_ORDER_DETAILS, PAGE_TYPE_ORDERS_LIST]
  if (!pagesToLoad.includes(pageType) || !isEnabled) {
    return
  }
  void activateExperiment(HOME_ONE_ACCOUNT)

  const accountOverviewEventsAndApplyTracking = () =>
    bindDynamicEventsTracking([
      {
        parentElementSelector:
          '[data-qa-id="document-invoice-link"], [data-qa-id="OrderOverviewItem__invoicePdfLink-link"]',
        selector:
          '[data-qa-id="OrderOverviewItem__invoicePdfLink-link"], .a-link--underline',
        trackingEvent: ACCOUNT_OVERVIEW_DOWNLOAD_INVOICE,
      },
      {
        parentElementSelector: '[data-qa-id="order-help-link"]',
        selector: '[data-qa-id="order-help-link"]',
        trackingEvent: ACCOUNT_OVERVIEW_HELP_WITH_MY_ORDER,
      },
      {
        parentElementSelector:
          '[data-qa-id="order-list-item-1"] + .o-OrdersListItem__link',
        selector: '.o-OrdersListItem__link',
        trackingEvent: ACCOUNT_OVERVIEW_LAST_ORDER_DETAIL,
      },
    ])

  accountOverviewEventsAndApplyTracking()
}
