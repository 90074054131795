const AD_SLOT_PLACEHOLDER_SELECTOR =
  '[data-clientside-hook~="adSlotPlaceholder"]'
const AD_SLOT_PLACEHOLDER_REMOVE_TIMEOUT_MS = 7000

export default () => {
  function removeAdSlotPlaceholders() {
    const adSlotPlaceholders = Array.from(
      document.querySelectorAll(AD_SLOT_PLACEHOLDER_SELECTOR)
    )

    adSlotPlaceholders.forEach(e => {
      e.remove()
    })
  }

  setTimeout(removeAdSlotPlaceholders, AD_SLOT_PLACEHOLDER_REMOVE_TIMEOUT_MS)
}
