import { INTERNAL_SERVER_ERROR } from 'shared/consts/codes'

import BackendError from './BackendError'

class InternalServerError extends BackendError {
  constructor(messageWithData) {
    super(messageWithData)
    this.status = INTERNAL_SERVER_ERROR
  }
}

export default InternalServerError
