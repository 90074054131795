import type { Tenant } from 'types/shopConfig'

export const getTenantWithEnv = (
  tenant: Tenant,
  coreShopEnvironment: string
) => {
  return coreShopEnvironment === 'prod'
    ? tenant
    : `${tenant}-${coreShopEnvironment}`
}
