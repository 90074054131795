const ADD_COMPONENT_INITIAL_STATE = 'ADD_COMPONENT_INITIAL_STATE'

export const addComponentInitialState = payload => {
  return {
    type: ADD_COMPONENT_INITIAL_STATE,
    payload,
  }
}

const reducer = (state = {}, action = { type: null }) => {
  switch (action.type) {
    case ADD_COMPONENT_INITIAL_STATE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default reducer
