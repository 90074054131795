import { bool, func, number, oneOfType, string } from 'prop-types'

import { FormConsumer } from 'views/components/atoms/Form'

const Input = props => {
  const {
    'data-qa-id': dataQa,
    disableAutoCompleteAndFriends,
    register,
    ...rest
  } = props

  let restProps = rest

  if (disableAutoCompleteAndFriends) {
    restProps = {
      ...restProps,
      autoCapitalize: 'off',
      autoComplete: 'off',
      autoCorrect: 'off',
      spellCheck: 'false',
    }
  }

  return (
    <FormConsumer>
      {formName => (
        <input
          data-qa-id={dataQa || `${formName}-${props.name}`}
          {...restProps}
          ref={register}
        />
      )}
    </FormConsumer>
  )
}

Input.propTypes = {
  autoFocus: bool,
  className: string,
  'data-qa-id': string,
  defaultValue: oneOfType([string, number]),
  disableAutoCompleteAndFriends: bool,
  hidden: bool,
  name: string.isRequired,
  onChange: func,
  onKeyUp: func,
  placeholder: string,
  register: func,
  type: string,
  value: oneOfType([string, number]),
}

export default Input
