// @ts-nocheck
import isCycleSafe from './isCycleSafe'

function removeCyclesTransform(
  input: unknown,
  {
    refs,
    path,
  }: { refs: Map<string, Record<string, unknown>>; path: string } = {
    refs: new Map(),
    path: '',
  }
): unknown {
  if (isCycleSafe(input)) {
    return input
  }

  if (refs.has(input)) {
    return `<Reference to ${refs.get(input) || '/'}>`
  }
  refs.set(input, path)

  return Object.fromEntries(
    Object.entries(input).map(([key, value]) => [
      key,
      removeCyclesTransform(value, { refs, path: `${path}/${key}` }),
    ])
  )
}

function removeCycles(input: unknown): unknown {
  return removeCyclesTransform(input)
}

export default removeCycles
