import type { TrackingEventName } from 'shared/experiments/consts/types'

const MAX_LENGTH = 64
const RE_VALID_NAME = /^[\w\d_\s-.]+$/i

/**
 * Validation requirements and error message are copied from Optimizely dashboard.
 *
 * @example event`MY OPTIMIZELY EVENT`
 * @throws if event name is invalid
 */
export const event = (
  strings: TemplateStringsArray,
  ...vars: string[] | TrackingEventName[]
): TrackingEventName => {
  const chunks = strings.reduce((acc: string[], cur: string, index: number) => {
    return acc.concat(`${cur}${vars[index] ?? ''}`)
  }, [])

  const name = chunks.join('')
  if (name.length <= MAX_LENGTH && RE_VALID_NAME.test(name)) {
    return name as unknown as TrackingEventName
  }

  throw Error(
    `"${name}" event must be no longer than 64 characters and consist of only alphanumeric characters, hyphens, underscores, spaces, and periods`
  )
}
