import {
  ALGOLIA_NEURAL_SEARCH,
  SEARCH_BOX_SUGGESTED_PRODUCT_CLICK,
  SERP_PRODUCT_DETAIL_CLICK,
} from 'shared/experiments/consts'
import {
  activateExperiment,
  getExperimentOnClient,
} from 'shared/experiments/utils/experiments'
import trackOptimizelyEvent from 'shared/experiments/utils/trackOptimizelyEvent'

export default () => {
  const experiment = getExperimentOnClient(ALGOLIA_NEURAL_SEARCH)

  if (!experiment.isEnabled) {
    return
  }

  document.addEventListener('click', (e: MouseEvent) => {
    const target = e.target as HTMLElement

    if (target.closest('[data-clientside-hook="SearchBoxListItemLink"]')) {
      trackOptimizelyEvent(SEARCH_BOX_SUGGESTED_PRODUCT_CLICK)
    }

    if (target.closest('[data-clientside-hook~="productDetailButton"]')) {
      trackOptimizelyEvent(SERP_PRODUCT_DETAIL_CLICK)
    }

    if (
      target.closest('[data-clientside-hook="h-SearchBox__trigger--mobile"]') ||
      target.closest('.o-SearchBox')
    ) {
      // if user clicks on search box and types something, activate experiment
      if (target.tagName === 'INPUT') {
        target.addEventListener(
          'input',
          () => {
            const isActivated = sessionStorage.getItem(ALGOLIA_NEURAL_SEARCH)
            if (!isActivated) {
              sessionStorage.setItem(ALGOLIA_NEURAL_SEARCH, 'true')
              void activateExperiment(ALGOLIA_NEURAL_SEARCH)
            }
          },
          { once: true }
        )
      }
    }
  })
}
