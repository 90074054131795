export const VISIBLE_ORDER_IMAGES_COUNT = {
  TINY: 2,
  SMALL: 3,
}

export const VISIBLE_ORDERS_COUNT = 5
export const FREE_TEXT = 'FREE_TEXT'

// should always be multiple of VISIBLE_ORDERS_COUNT
export const PREFETCH_ORDERS_AMOUNT = 20
export const DOCUMENT_TYPES = {
  INVOICE: 'invoice',
  CUSTOMER_INFO: 'custinformation',
  CREDIT_NOTE: 'creditnote',
  PRESCRIPTION_COPY: 'prescriptioncopy',
  OTHER: 'other',
}

// TODO unify with app/shared/consts/prescription.ts. Bully has to be updated first
export const INVOICE_PRESCRIPTION_TYPES = {
  OTC: 'none',
  PUBLIC: 'insurance_prescription', // GKV
  PRIVATE: 'private_prescription', // PKV
}

export const ORDER_TYPES = {
  STANDARD: 'standard',
  SUBSCRIPTION: 'subscription',
}
