import { IconArrowLeft as FigmaIconArrowLeft } from '@redteclab/icons'
import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const IconArrowLeft: FC<IconProps> = props => (
  <SvgIcon {...props} component={FigmaIconArrowLeft} />
)

export default IconArrowLeft
