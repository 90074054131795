import equal from 'fast-deep-equal'
import { useSelector } from 'react-redux'

import type { PageProperties } from 'types/pageProperties'
import type { PublicConfig, ShopAndRuntimeConfigState } from 'types/shopConfig'

// Utils are used within FC, so it is fine to use `useContext` here
export const usePublicConfig = (): PublicConfig => {
  return useSelector(
    (state: ShopAndRuntimeConfigState) =>
      state.publicRuntimeConfig.publicConfig,
    equal
  )
}

export const usePageProperties = (): PageProperties => {
  return useSelector(
    (state: ShopAndRuntimeConfigState) =>
      state.publicRuntimeConfig.pageProperties,
    equal
  )
}
