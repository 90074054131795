export default () => {
  const HASH = window.location.hash
  const SELECTOR_ANCHOR_CONTAINER = 'anchorContainer'
  const SELECTOR_EXPANDER_BUTTON = 'expander__button'
  /** @type HTMLElement[] */
  const anchorsContainers = [
    ...document.querySelectorAll(
      `[data-clientside-hook~="${SELECTOR_ANCHOR_CONTAINER}"]`
    ),
  ]
  const anchorsLinks = [
    ...document.querySelectorAll('[data-clientside-hook~="anchorLink"]'),
  ]

  const containers = anchorsContainers.map(anchorsContainer => {
    if (HASH) {
      const hashParts = HASH.slice(1).split('&')
      const search = hashParts.find(hashPart =>
        anchorsContainer.querySelector(`[data-id~="${hashPart}"]`)
      )
      const anchor = search
        ? anchorsContainer.querySelector(`[data-id~="${search}"]`)
        : false

      if (anchor) {
        const isExpander = anchorsContainer.dataset.expander === 'true'
        const behavior = anchorsContainer.dataset.behavior

        // TODO: we should use other mq - preferably one from available breakpoints in `_breakpoints.scss`
        if (isExpander && window.matchMedia('(max-width: 45em)').matches) {
          const parent = anchorsContainer.parentElement
          const expanderButtonElement = parent.querySelector(
            `[data-clientside-hook~="${SELECTOR_EXPANDER_BUTTON}"]`
          )
          if (expanderButtonElement) {
            setTimeout(() => {
              anchor.scrollIntoView({ behavior })
              expanderButtonElement.click()
            }, 1000)
          }
        } else {
          setTimeout(() => {
            anchor.scrollIntoView({ behavior })
          }, 1000)
        }
      }
    }

    return anchorsContainer
  })

  const links = anchorsLinks.map(anchorsLink => {
    const getProperAnchor = mobileView => {
      let anchor = null
      const anchors = [
        ...document.querySelectorAll(
          `[data-clientside-hook~="${SELECTOR_ANCHOR_CONTAINER}"] > [data-id~="${anchorsLink
            .getAttribute('href')
            .slice(1)}"]`
        ),
      ]

      if (anchors) {
        for (let i = 0; i < anchors.length; i += 1) {
          if (
            anchors[i].parentElement.dataset.expander ===
            (mobileView ? 'true' : 'false')
          ) {
            anchor = anchors[i]
            break
          }
        }

        if (!anchor) {
          anchor = anchors.shift()
        }
      }

      return anchor
    }

    const onClick = () => {
      const anchor = getProperAnchor(
        window.matchMedia('(max-width: 45em)').matches
      )

      if (anchor) {
        const anchorContainer = anchor.parentElement
        const isExpander = anchorContainer.dataset.expander === 'true'
        const behavior = anchorContainer.dataset.behavior

        if (isExpander) {
          /** @type HTMLElement|undefined */
          const expanderButtonElement =
            anchorContainer.parentElement.querySelector(
              `[data-clientside-hook~="${SELECTOR_EXPANDER_BUTTON}"]`
            )
          if (expanderButtonElement) {
            expanderButtonElement.click()
          }
        }
        anchor.scrollIntoView({ behavior })
      }
    }

    anchorsLink.addEventListener('click', onClick)

    return anchorsLink
  })

  return {
    containers,
    links,
  }
}
