import { BODY_HOVER_CLASS } from './consts'

export default () => {
  const watchForHover = () => {
    let hasHoverClass = true
    let lastTouchTime = 0

    const container = document.body

    const enableHover = () => {
      // filter emulated events coming from touch events
      if (Number(new Date()) - lastTouchTime < 500) {
        return
      }
      if (hasHoverClass) {
        return
      }

      container.classList.add(BODY_HOVER_CLASS)
      hasHoverClass = true
    }

    const disableHover = () => {
      if (!hasHoverClass) {
        return
      }

      container.classList.remove(BODY_HOVER_CLASS)
      hasHoverClass = false
    }

    const updateLastTouchTime = () => {
      lastTouchTime = Number(new Date())
    }

    document.addEventListener('touchstart', updateLastTouchTime, true)
    document.addEventListener('touchstart', disableHover, true)
    document.addEventListener('mousemove', enableHover, true)

    enableHover()
  }

  watchForHover()
}
