import { RETURN_LABEL_CREATE_DATA_KEY } from 'shared/consts/order/returnLabel'

export const handleReturnLabelCreateEvent = () => {
  const payload = localStorage.getItem(RETURN_LABEL_CREATE_DATA_KEY)
  if (payload && window && window.dataLayer) {
    const parsedPayload = JSON.parse(payload)

    window.dataLayer.push({
      event: parsedPayload.event,
      payload: parsedPayload,
    })
  }
  localStorage.removeItem(RETURN_LABEL_CREATE_DATA_KEY)
}
