import { IconEyeClose } from '@redteclab/icons'
import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const IconEyeCross: FC<IconProps> = props => (
  <SvgIcon {...props} component={IconEyeClose} />
)

export default IconEyeCross
