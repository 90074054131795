import logger from 'shared/services/logger'

import Storage from './storage'

class AlgoliaUserService {
  localStorage = new Storage('localStorage')
  sessionStorage = new Storage('sessionStorage')
  STORAGE_KEYS = {
    algoliaToken: 'algoliaToken',
    searchAbTestInfo: 'searchAbTestInfo',
  }

  isLocalStorageEnabled = false

  constructor() {
    this.isLocalStorageEnabled = this.localStorage.isAvailable()
    if (!this.isLocalStorageEnabled) {
      logger.info('AlgoliaUserService: LocalStorage is not available')
    }
  }

  getToken = () => {
    if (!this.isLocalStorageEnabled) {
      return null
    }
    const token = this.localStorage.get(this.STORAGE_KEYS.algoliaToken)
    return token && token.length > 0 ? token : null
  }

  setSearchAbTestInfo = value => {
    try {
      this.sessionStorage.set(this.STORAGE_KEYS.searchAbTestInfo, value)
    } catch (e) {
      logger.info(e)
    }
  }

  clearLocalStorage = () => {
    if (this.isLocalStorageEnabled) {
      ;[...Object.keys(this.STORAGE_KEYS)].forEach(key => {
        this.localStorage.set(this.STORAGE_KEYS[key], [])
      })
    }
  }
}

export default new AlgoliaUserService()
