// @ts-nocheck
import { Model } from 'shared/services/api/models/Model'

import { MessagingThread, MessagingThreadV1 } from './MessagingThread'
import { ThreadSubject, ThreadSubjectV1 } from './ThreadSubject'
import { ThreadSubjectsResponseV1 } from './ThreadSubjectsResponse'

export interface MessagingThreadsListV1 {
  data: MessagingThreadV1[]
  nextPageToken?: string
  prevPageToken?: string
}

export class MessagingThreadsList extends Model {
  public readonly data: MessagingThread[]
  public readonly nextPageToken: string
  public readonly prevPageToken: string
  public readonly threadSubjects: ThreadSubject[]

  constructor(
    src: MessagingThreadsListV1,
    subjects: ThreadSubjectsResponseV1 = null
  ) {
    super(src)
    this.data = src.data?.length
      ? src.data.map((thread: MessagingThreadV1) => new MessagingThread(thread))
      : []
    this.nextPageToken = src.nextPageToken
    this.prevPageToken = src.prevPageToken
    if (subjects?.reasons?.length) {
      this.threadSubjects = subjects.reasons.map(
        (subject: ThreadSubjectV1) => new ThreadSubject(subject)
      )
    }
  }

  get threads(): MessagingThread[] {
    return this.data
  }
}
