// @ts-nocheck
export interface MailingSubscriptionResponseV1 {
  id: string
  tenant: string
  subscriptionType: string
  customerNumber: string
  firstName: string
  lastName: string
  salutation: string
  dateOfBirth: string
  emailAddress: string
  subscriptionRegistrationChannel: string
  subscriptionRegistrationDate: string
  subscriptionStatus: string
  language: string
}

export class MailingSubscription {
  public customerNumber: string
  public firstName: string
  public id: string
  public lastName: string
  public subscriptionStatus: string
  public subscriptionType: string
  public subscriptionRegistrationChannel: string
  constructor(src: MailingSubscriptionResponseV1) {
    if (src) {
      this.customerNumber = src.customerNumber
      this.firstName = src.firstName
      this.id = src.id
      this.lastName = src.lastName
      this.subscriptionStatus = src.subscriptionStatus
      this.subscriptionType = src.subscriptionType
      this.subscriptionRegistrationChannel = src.subscriptionRegistrationChannel
    }
  }
}
