// @ts-nocheck
import {
  MailingSubscription,
  MailingSubscriptionResponseV1,
} from './mailingSubscription'
import { Model } from './Model'

const NEWSLETTER_MAILING_SUBSCRIPTION_TYPE = 'newsletter'
const SUBSCRIBED_SUBSCRIPTION_STATUS = 'subscribed'

export interface CustomerDataResponseV1 {
  customerNumber: string
  firstName: string
  email: string
  isEavCustomer: boolean
  lastName: string
  mailingSubscriptions: MailingSubscriptionResponseV1[]
  phoneNumber: string
  redPointsMembership: string
  salutation: string
  dateOfBirth?: string
  taxNumber: string
  eligibleCredit: number
  customerType: string
  customerTypeProfessional: {
    companyName: string
    vatExemption: boolean
    vatNumber: string
  }
  emailValidated?: string
}

export class CustomerData extends Model {
  public readonly customerNumber: string
  public readonly firstName: string
  public readonly isEavCustomer: boolean
  public readonly isNewsletterMember: boolean
  public readonly isRedpointsMember: boolean
  public readonly lastName: string
  public readonly email: string
  public readonly mailingSubscriptions: MailingSubscription[]
  public readonly phoneNumber: string
  public readonly salutation: string
  public readonly dateOfBirth?: string
  public readonly taxNumber: string
  public readonly eligibleCredit?: number
  public readonly customerType?: string
  public readonly customerTypeProfessional?: {
    companyName: string
    vatExemption: boolean
    vatNumber: string
  }
  public readonly emailValidated?: string

  constructor(src: CustomerDataResponseV1) {
    super(src)
    this.customerNumber = src.customerNumber
    this.firstName = src.firstName
    this.email = src.email
    this.eligibleCredit = src.eligibleCredit
    this.isEavCustomer = src.isEavCustomer
    this.lastName = src.lastName
    this.mailingSubscriptions = Array.isArray(src.mailingSubscriptions)
      ? src.mailingSubscriptions.map(
          (subscription: MailingSubscriptionResponseV1) =>
            new MailingSubscription(subscription)
        )
      : []
    this.dateOfBirth = src.dateOfBirth
    this.phoneNumber = src.phoneNumber
    this.salutation = src.salutation
    this.taxNumber = src.taxNumber

    this.isNewsletterMember =
      this.getNewsletterMembership()?.subscriptionStatus ===
      SUBSCRIBED_SUBSCRIPTION_STATUS
    this.isRedpointsMember =
      src.redPointsMembership?.toLowerCase() === SUBSCRIBED_SUBSCRIPTION_STATUS

    this.customerType = src.customerType
    this.customerTypeProfessional = src.customerTypeProfessional
    this.emailValidated = src.emailValidated
  }

  private getNewsletterMembership(): MailingSubscription {
    return this.mailingSubscriptions.find(
      subscription =>
        subscription.subscriptionType === NEWSLETTER_MAILING_SUBSCRIPTION_TYPE
    )
  }
}
