import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const PATH =
  'M45,0 C20.3142857,0 0,20.3142857 0,45 C0,69.9428571 20.3142857,90 45,90 C69.6857143,90 90,69.9428571 90,45 C90,20.3142857 69.9428571,0 45,0 Z M34.3820225,56.6292135 L28.3146067,56.6292135 L18.9606742,40.9253139 L18.9606742,40.9253139 L18.9606742,56.6292135 L14.1573034,56.6292135 L14.1573034,34.3820225 L20.4775281,34.3820225 L29.5786517,49.8241904 L29.5786517,49.8241904 L29.5786517,34.3820225 L34.3820225,34.3820225 L34.3820225,56.6292135 Z M54.6067416,56.6292135 L39.4382022,56.6292135 L39.4382022,34.3820225 L54.1011236,34.3820225 L54.1011236,38.8314607 L44.241573,38.8314607 L44.241573,43.0191672 L53.5955056,43.0191672 L53.5955056,47.4686054 L44.241573,47.4686054 L44.241573,51.9180436 L54.6067416,51.9180436 L54.6067416,56.6292135 Z M76.6011236,47.6791941 C76.6011236,48.9577683 76.3483146,50.2363425 76.0955056,51.2592019 C75.5898876,52.2820612 75.0842697,53.3049206 74.3258427,54.0720651 C73.5674157,54.8392096 72.5561798,55.6063541 71.5449438,55.862069 C70.5337079,56.3734986 69.0168539,56.6292135 67.752809,56.6292135 C66.2359551,56.6292135 64.9719101,56.3734986 63.7078652,55.862069 C62.4438202,55.3506393 61.6853933,54.8392096 60.9269663,54.0720651 C60.1685393,53.3049206 59.6629213,52.2820612 59.1573034,51.2592019 C58.6516854,50.2363425 58.6516854,48.9577683 58.6516854,47.6791941 L58.6516854,34.3820225 L63.4550562,34.3820225 L63.4550562,47.4234793 C63.4550562,48.1906238 63.4550562,48.7020535 63.7078652,49.2134831 C63.9606742,49.7249128 64.2134831,50.2363425 64.7191011,50.7477722 C65.2247191,51.2592019 65.4775281,51.5149167 65.9831461,51.7706315 C66.488764,52.0263464 66.994382,52.0263464 67.752809,52.0263464 C68.511236,52.0263464 69.0168539,52.0263464 69.5224719,51.7706315 C70.0280899,51.5149167 70.5337079,51.2592019 70.7865169,50.7477722 C71.2921348,50.2363425 71.5449438,49.7249128 71.7977528,49.2134831 C72.0505618,48.7020535 72.0505618,47.934909 72.0505618,47.4234793 L72.0505618,34.3820225 L76.8539326,34.3820225 L76.8539326,47.6791941 L76.6011236,47.6791941 Z'

const IconNew: FC<IconProps> = props => (
  <SvgIcon {...props} path={PATH} viewBox="0 0 100 100" />
)

export default IconNew
