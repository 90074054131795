import {
  DEFAULT_WIDGETS,
  PAGE_TYPE_HOMEPAGE,
  TYPE_PRODUCTS_SOLD_PER_COUNTRY_EXPERIMENT,
} from 'shared/consts'
import {
  CSW_ARROW_CLICK,
  CSW_PRODUCT_CLICK,
  CSW_SHOW_ALL_CLICK,
  EXPERIMENT_VARIATION,
  HOME_PAGE_PRODUCTS_SOLD_PER_COUNTRY_EXPERIMENT,
} from 'shared/experiments/consts'
import { activateExperiment } from 'shared/experiments/utils/experiments'
import { getExperimentPerTenantOnClient } from 'shared/experiments/utils/tenantBasedExperimentsUtils'
import trackOptimizelyEvent from 'shared/experiments/utils/trackOptimizelyEvent'
import store from 'shared/store'
import isElementEnteredViewport from 'views/utils/isElementEnteredViewport'

export default () => {
  const publicRuntimeConfig = store.getState()?.publicRuntimeConfig
  const { pageType = '' } = publicRuntimeConfig?.pageProperties || {}
  const { tenant } = publicRuntimeConfig?.publicConfig || {}

  const { isEnabled, variant, name } = getExperimentPerTenantOnClient(
    HOME_PAGE_PRODUCTS_SOLD_PER_COUNTRY_EXPERIMENT
  )

  if (!isEnabled || pageType !== PAGE_TYPE_HOMEPAGE) return

  const homePageProductsSoldPerCountryExperimentActivated =
    window.sessionStorage.getItem(name)

  const widgetContainer = document.querySelector(
    `.wid-${DEFAULT_WIDGETS[tenant][TYPE_PRODUCTS_SOLD_PER_COUNTRY_EXPERIMENT]}-${variant}`
  )

  const handleScroll = () => {
    const activationPoint = document.querySelectorAll(
      '.o-CmsContent__ComponentLegacyCategorySmall'
    )[
      document.querySelectorAll('.o-CmsContent__ComponentLegacyCategorySmall')
        .length - 1
    ]

    if (
      !homePageProductsSoldPerCountryExperimentActivated &&
      activationPoint &&
      isElementEnteredViewport(activationPoint)
    ) {
      void activateExperiment(name)
      window.sessionStorage.setItem(name, 'true')

      window.removeEventListener('scroll', handleScroll)
    }
  }
  // first call is needed in case user is already at the position of the csw when the first load happens
  handleScroll()
  window.addEventListener('scroll', handleScroll)

  if (widgetContainer && variant !== EXPERIMENT_VARIATION.DEFAULT) {
    widgetContainer.addEventListener('click', event => {
      const element = <HTMLElement>event.target

      if (element.closest('[data-clientside-hook~="productDetailButton"]')) {
        trackOptimizelyEvent(CSW_PRODUCT_CLICK)
      } else if (
        element.closest('[data-clientside-hook~="adaptable__arrows"]')
      ) {
        trackOptimizelyEvent(CSW_ARROW_CLICK)
      } else if (
        element.closest('[data-clientside-hook~="slider__header"] a')
      ) {
        trackOptimizelyEvent(CSW_SHOW_ALL_CLICK)
      }
    })
  }
}
