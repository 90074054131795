// @ts-nocheck
import * as CHECKOUT from 'shared/consts/checkout'
import store from 'shared/store'
import { OrderDraft, SubOrder } from 'shared/store-checkout/types/orderDraft'
import { IState } from 'shared/store-checkout/types/redux'
import { Carrier } from 'shared/store-checkout/types/shipment'
import MountPointsClass from 'shared/utils/mountPoints'

export const CHECKOUT_ERROR = 'CHECKOUT_ERROR'
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP'
export const RESTORE_PERSISTED_STATE = 'RESTORE_PERSISTED_STATE'
export const SHOW_ORDER_SERVICE_UNAVAILABLE_ERROR =
  'SHOW_ORDER_SERVICE_UNAVAILABLE_ERROR'

// ---------------------------- ACTIONS -------------------------

export const addCheckoutErrorAction = (error: string) => {
  return {
    type: CHECKOUT_ERROR,
    error,
  }
}

export const setCurrentStepAction = (stepNo: number) => {
  return {
    type: SET_CURRENT_STEP,
    stepNo,
  }
}

export const showOrderServiceUnavailableErrorAction = (
  showOrderServiceUnavailableError: boolean
) => ({
  type: SHOW_ORDER_SERVICE_UNAVAILABLE_ERROR,
  showOrderServiceUnavailableError,
})
// ---------------------------- SELECTORS -------------------------

export const getSubOrder = (
  state: IState,
  subOrderId: string | null = null
): SubOrder | null => {
  try {
    const {
      checkout: {
        orderDraft: { subOrders },
      },
    } = state
    let subOrderById
    if (subOrderId) {
      subOrderById = subOrders.find(
        subOrder => subOrder.subOrderId === subOrderId
      )
      if (subOrderById) {
        return subOrderById
      }
    }
    // if no subOrderId was provided and only 1 subOrder exists
    // we assume this is a SA only order and return the only subOrder
    if (subOrders.length === 1) {
      return subOrders[0]
    }

    return null
  } catch (e) {
    return null
  }
}

export const getSubOrders = (state: IState): SubOrder[] => {
  try {
    const {
      checkout: {
        orderDraft: { subOrders },
      },
    } = state

    return subOrders || []
  } catch (e) {
    return []
  }
}

export const getOrderId = (order: { data: OrderDraft }): string => {
  try {
    const {
      data: {
        orderMasterData: { orderNumber },
      },
    } = order

    return orderNumber || ''
  } catch (e) {
    return ''
  }
}

export const getOrderDraftId = (state: IState): string => {
  try {
    const {
      checkout: {
        orderDraft: { id },
      },
    } = state

    return id || ''
  } catch (e) {
    return ''
  }
}

export const getMountPointsInstance = (): MountPointsClass => {
  const {
    publicConfig: { tenant, language, mountPoints },
    pageProperties: { coreShopEnvironment },
  } = store.getPublicRuntimeConfig()

  return new MountPointsClass({
    mountPoints,
    tenant,
    language,
    coreShopEnvironment,
  })
}

export const getCurrentStep = (state: IState): number => {
  try {
    const {
      checkout: { currentStep },
    } = state

    return currentStep || 1
  } catch (e) {
    return 1
  }
}

export const getCustomerId = (state: IState): string | null => {
  try {
    const {
      checkout: {
        orderDraft: {
          customerMasterData: { customerNumber },
        },
      },
    } = state

    return customerNumber
  } catch (e) {
    return null
  }
}

// not imported anywhere
export const getSASubOrderId = (state: IState): string => {
  try {
    const {
      checkout: {
        orderDraft: { subOrders },
      },
    } = state
    const shopApothekeSubOrder = subOrders.find(
      subOrder => subOrder.sellerId === 'shop-apotheke.com'
    )
    if (shopApothekeSubOrder) {
      return shopApothekeSubOrder.subOrderId
    }
    // if no subOrderId was provided and only 1 subOrder exists
    // we assume this is a SA only order and return the only subOrder
    else if (subOrders.length === 1) {
      return subOrders[0].subOrderId
    }

    return null
  } catch (e) {
    return null
  }
}

export const getSubOrderCarrier = (
  state: IState,
  subOrderId: string = CHECKOUT.SA_SELLER_ID
): Carrier | Record<string, never> => {
  const subOrder = getSubOrder(state, subOrderId)
  const firstSubOrder = state?.checkout?.orderDraft?.subOrders[0]

  return subOrder?.carrier || firstSubOrder?.carrier || {}
}

export const showOrderServiceUnavailableErrorSelector = (
  state: IState
): boolean => state.checkout?.showOrderServiceUnavailableError || false
