import { IconStarFilled as Icon } from '@redteclab/icons'
import { type FC } from 'react'

import SvgIcon, { type IconProps } from './SvgIcon'

const IconStarFilled: FC<IconProps> = props => (
  <SvgIcon {...props} component={Icon} />
)

export default IconStarFilled
